import React, { useState, useEffect } from "react";
import Profile from "../../../Assets/img/Profile.jpg";

function Blasting() {
  return (
    <div className="">
      <div className="overflow-x-auto overflow-y-auto md:h-80">
        <table className="w-full text-sm text-left text-gray-500 mx-3">
          <thead className="text-sm text-gray-400">
            <tr>
              <th scope="col" className="py-3 mx-5 w-10">
                <input
                  type="checkbox"
                  id=""
                  className="rounded focus:ring-transparent"
                />
              </th>
              <th scope="col" className="py-3 mx-5 w-32">
                Customer
              </th>
              <th scope="col" className="py-3 mx-5 w-32">
                Phone
              </th>
              <th scope="col" className="py-3 mx-5 w-32">
                Broadcast
              </th>
              <th scope="col" className="py-3 mx-5 w-32">
                CS CRM
              </th>
              <th scope="col" className="py-3 mx-5 w-32">
                Status
              </th>
              <th scope="col" className="py-3 mx-5 w-32">
                Sent Date
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="bg-white border-b">
              <td className="py-4">
                <input
                  type="checkbox"
                  id=""
                  className="rounded focus:ring-transparent"
                />
              </td>
              <td className="py-3"><a href="/overview-customer">Aspen Rosser</a></td>
              <td className="py-3">62821213131313</td>
              <td className="py-3">FU H+1</td>
              <td className="py-3">Maren Schleifer</td>
              <td className="py-3">Success</td>
              <td className="py-3">26/03/2022 08:40</td>
            </tr>
            <tr className="bg-white border-b">
              <td className="py-4">
                <input
                  type="checkbox"
                  id=""
                  className="rounded focus:ring-transparent"
                />
              </td>
              <td className="py-3"><a href="/overview-customer">Aspen Rosser</a></td>
              <td className="py-3">62821213131313</td>
              <td className="py-3">FU H+1</td>
              <td className="py-3">Maren Schleifer</td>
              <td className="py-3">pending</td>
              <td className="py-3">26/03/2022 08:40</td>
            </tr>
            <tr className="bg-white border-b">
              <td className="py-4">
                <input
                  type="checkbox"
                  id=""
                  className="rounded focus:ring-transparent"
                />
              </td>
              <td className="py-3"><a href="/overview-customer">Aspen Rosser</a></td>
              <td className="py-3">62821213131313</td>
              <td className="py-3">FU H+1</td>
              <td className="py-3">Maren Schleifer</td>
              <td className="py-3">Not Sent</td>
              <td className="py-3">26/03/2022 08:40</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Blasting;
