import React from "react";
import ReactDOM from "react-dom";
import { Multiselect } from "multiselect-react-dropdown";
// import "./styles.css";
// import "../node_modules/bootstrap/dist/css/bootstrap.min.css";

class MultiselectReact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      plainArray: ["Option 1", "Option 2", "Option 3", "Option 4", "Option 5"],
    };
    this.addItem = this.addItem.bind(this);
  }
  addItem() {
    this.selectedValues.push({ key: "Option 3", cat: "Group 1" });
  }

  render() {
    const { plainArray } = this.state;
    return (
      <div className="App">
        <Multiselect placeholder={"Select CS  (Multiple)"} showArrow={true} options={plainArray} isObject={false} />
      </div>
    );
  }
}

export default MultiselectReact;
