export default [
    {
      id: 1,
      team: "CRM MP & CRM Acquisition ",
      promoName: "Promo Ongkir",
      nominal: "Up to Rp. 45.000",
      product: "All Products",
      minPurchase: "Rp. 120.000",
      link: "/dashboard/view-customer",
    },
    {
      id: 2,
      team: "CRM MP & CRM Acquisition ",
      promoName: "Promo Ongkir",
      nominal: "Up to Rp. 45.000",
      product: "All Products",
      minPurchase: "Rp. 120.000",
      link: "/dashboard/view-customer",
    },
    {
      id: 3,
      team: "CRM MP & CRM Acquisition ",
      promoName: "Promo Ongkir",
      nominal: "Up to Rp. 45.000",
      product: "All Products",
      minPurchase: "Rp. 120.000",
      link: "/dashboard/view-customer",
    },
    {
      id: 4,
      team: "CRM MP & CRM Acquisition ",
      promoName: "Promo Ongkir",
      nominal: "Up to Rp. 45.000",
      product: "All Products",
      minPurchase: "Rp. 120.000",
      link: "/dashboard/view-customer",
    },
    {
      id: 5,
      team: "CRM MP & CRM Acquisition ",
      promoName: "Promo Ongkir",
      nominal: "Up to Rp. 45.000",
      product: "All Products",
      minPurchase: "Rp. 120.000",
      link: "/dashboard/view-customer",
    },
    {
      id: 6,
      team: "CRM MP & CRM Acquisition ",
      promoName: "Promo Ongkir",
      nominal: "Up to Rp. 45.000",
      product: "All Products",
      minPurchase: "Rp. 120.000",
      link: "/dashboard/view-customer",
    },
    {
      id: 7,
      team: "CRM MP & CRM Acquisition ",
      promoName: "Promo Ongkir",
      nominal: "Up to Rp. 45.000",
      product: "All Products",
      minPurchase: "Rp. 120.000",
      link: "/dashboard/view-customer",
    },
  ];