import api from "../apiClient";


export function getAllCS(params) {
  // console.log("hello world ", params)
  return api.axiosClient.get("/customer/getCS?", {
    params: params
  });
}

export function getCustomerById(id) {
  return api.axiosClient.get(`/customer/getCS/${id}`);
}

export function getCustomerAddressById(id) {
  return api.axiosClient.get(`/customerAddres/getId/${id}`);
}

export function createCS(data) {
  return api.axiosClient.post("/customer/createCS", data);
}

export function updateCustomer(phone, data) {
  return api.axiosClient.put(`/customer/updateCS/${phone}`, data);
}
export function createCsAddress(data) {
  return api.axiosClient.post("/customerAddres/createCsAddress", data);
}
export function updateCustomerAddress(id, data) {
  return api.axiosClient.put(`/customerAddres/updateCsAddress/${id}`, data);
}

export function uploadCustomer(data) {
  return api.axiosClient.post("/customer/upload", data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
}

export function getCs(){
  return api.axiosClient.get("/getCustomerService");
}

