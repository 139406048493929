import React, { useState } from "react";
import Navbar from "../Components/Navbar/Navbar";
import AddCS from "../Components/Partials/Modal/AddCS";
import CardCS from "../Components/Partials/Card/CardCS";

const state = {
  labels: [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sept",
    "Ockt",
    "Nov",
    "Des",
  ],
  datasets: [
    {
      label: "Name_Product",
      backgroundColor: "rgba(75,192,192,1)",
      borderColor: "rgba(0,0,0,1)",
      borderWidth: 1,
      data: [65, 59, 80, 81, 56, 77, 44, 84, 100, 91, 10, 15],
    },
    {
      label: "Name_Product",
      backgroundColor: "rgba(237,76,92,1)",
      borderColor: "rgba(0,0,0,1)",
      borderWidth: 1,
      data: [65, 59, 80, 81, 56, 77, 44, 84, 100, 91, 10, 15],
    },
  ],
};

function CustomerService() {
  const [count_lead, setCountLead] = useState("");
  return (
    <div>
      <Navbar />
      <div className="px-10 py-5">
        <div className="mt-20">
          <div className="flex flex-col md:flex-row justify-between items-start md:items-center gap-3">
            <div className="flex flex-col">
              <h1 className="text-lg font-semibold">Customer Service CRM</h1>
              <h1 className="text-gray-400 text-sm font-medium flex flex-row items-center gap-2">
                <a href="dashboard" className="hover:text-gray-600">
                  Dashboard
                </a>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-chevron-right"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                  />
                </svg>
                <a href="#" className="hover:text-gray-600">
                  CS CRM
                </a>
              </h1>
            </div>
            <div className="flex flex-col md:flex-row gap-2">
              <AddCS />
              <div className="flex flex-row rounded-lg bg-gray-50 border border-gray-300 mt-1">
                <input
                  type="text"
                  id="table-search"
                  className="appearance-none bg-transparent border-none w-full text-gray-700 leading-tight focus:outline-none focus:ring-transparent w-64"
                  placeholder="Search"
                />
                <div className="flex items-center pr-3 pointer-events-none">
                  <svg
                    className="w-5 h-5 text-gray-500 dark:text-gray-400"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </div>
              </div>
            </div>
          </div>
          <div className="grid grid-rows md:grid-cols-1 lg:grid-cols-3 lg:grid-flow-row gap-5 justify-center items-start md:items-center py-5">
            <CardCS
              NameCS={"Karina 1"}
              Email={"karina1@gmail.com"}
              NoHp={"081320012345"}
              Product={"Freshmag"}
              CS={"Karina 1"}
            />
            <CardCS
              NameCS={"Karina 2"}
              Email={"karina2@gmail.com"}
              NoHp={"081320012345"}
              Product={"Rube"}
              CS={"Karina 2"}
            />
            <CardCS
              NameCS={"Karina 3"}
              Email={"karina3@gmail.com"}
              NoHp={"081320012345"}
              Product={"Etawaku"}
              CS={"Karina 3"}
            />
            <CardCS
              NameCS={"Karina 4"}
              Email={"karina4@gmail.com"}
              NoHp={"081320012345"}
              Product={"Freshmag"}
              CS={"Karina 4"}
            />
            <CardCS
              NameCS={"Karina 5"}
              Email={"karina5@gmail.com"}
              NoHp={"081320012345"}
              Product={"Freshmag"}
              CS={"Karina 5"}
            />
            <CardCS
              NameCS={"Karina 6"}
              Email={"karina6@gmail.com"}
              NoHp={"081320012345"}
              Product={"Freshmag"}
              CS={"Karina 6"}
            />
            <CardCS
              NameCS={"Karina 7"}
              Email={"karina7@gmail.com"}
              NoHp={"081320012345"}
              Product={"Freshmag"}
              CS={"Karina 7"}
            />
            <CardCS
              NameCS={"Karina 8"}
              Email={"karina8@gmail.com"}
              NoHp={"081320012345"}
              Product={"Freshmag"}
              CS={"Karina 8"}
            />
            <CardCS
              NameCS={"Karina 9"}
              Email={"karina9@gmail.com"}
              NoHp={"081320012345"}
              Product={"Freshmag"}
              CS={"Karina 9"}
            />
            <CardCS
              NameCS={"Karina 10"}
              Email={"karina10@gmail.com"}
              NoHp={"081320012345"}
              Product={"Freshmag"}
              CS={"Karina 10"}
            />
            <CardCS
              NameCS={"Karina 11"}
              Email={"karina11@gmail.com"}
              NoHp={"081320012345"}
              Product={"Freshmag"}
              CS={"Karina 11"}
            />
            <CardCS
              NameCS={"Karina 12"}
              Email={"karina@gmail.com"}
              NoHp={"081320012345"}
              Product={"Freshmag"}
              CS={"Karina 12"}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default CustomerService;
