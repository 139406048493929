import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Fb from "../../Assets/img/LandingPage/Fb.png";
import Lg from "../../Assets/img/LandingPage/Lg.png";
import Li from "../../Assets/img/LandingPage/Li.png";
import Tk from "../../Assets/img/LandingPage/Tk.png";
import Tw from "../../Assets/img/LandingPage/Tw.png";
import Wa from "../../Assets/img/LandingPage/Wa.png";
import success from "../../Assets/img/register.gif";
import { contactUs } from "../../Network/Lib/contact";

function Contact() {
    const [name, setName] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [note, setNote] = useState("");
    const [msg, setMsg] = useState("");
    const [hidden, sethidden] = useState(false);
    const navigate = useNavigate();
   

    const Contact = async (e) => {
        e.preventDefault();
    
        contactUs({
            name: name,
            companyName: companyName,
            email: email,
            phone: phone,
            note: note,
        })
          .then((response) => {
            console.log(response);
            sethidden(true);
            // navigate("/");
            setTimeout(() => {
              navigate("/");
            }, 1000);
          })
          .catch((e) => {
            console.log(e.response);
            if (e.response) {
              setMsg(e.response.data.message);
            }
          });
      };

    
   
    
    return (
        <div>
            {hidden ? (
                <div className="h-screen flex flex-col justify-center items-center">
                <img src={success} className="w-96" />
                <h1 className="text-2xl font-bold text-blue-2">Congratulation !</h1>
                <p className="text-gray-500" s>
                    Your account has been created successfully
                </p>
                </div>
            ) : (
            <div className="bg-white mt-20">
                {msg != "" ? (
                <div
                    className="p-3 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800"
                    role="alert"
                >
                    {msg}
                </div>
                ) : null}
                {/* section1 */}
                <div className="bg-white h-full px-24 py-10">
                    <div className="flex justify-center">
                        <h1 className="text-4xl font-bold">Contact Us</h1>
                    </div>
                    <div className="py-10">
                        <form onSubmit={Contact} className="box">
                            <div className="grid lg:grid-cols-12 grid-cols-6 gap-x-4 gap-y-2">
                                <div className="col-span-6">
                                    <div class="mb-6">
                                        <label for="name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Name</label>
                                        <input type="text" id="name" class="bg-gray-50 shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light" placeholder="Your Name"
                                        onChange={(e) => setName(e.target.value)}/>
                                    </div>
                                </div>
                                <div className="col-span-6">
                                    <div class="mb-6">
                                        <label for="name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Company Name</label>
                                        <input type="text" id="name" class="bg-gray-50 shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light" placeholder="Company Name"
                                        onChange={(e) => setCompanyName(e.target.value)}/>
                                    </div>
                                </div>
                                <div className="col-span-6">
                                    <div class="mb-6">
                                        <label for="name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Email</label>
                                        <input type="text" id="name" class="bg-gray-50 shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light" placeholder="Email"
                                        onChange={(e) => setEmail(e.target.value)}/>
                                    </div>
                                </div>
                                <div className="col-span-6">
                                    <div class="mb-6">
                                        <label for="name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Phone</label>
                                        <input type="text" id="name" class="bg-gray-50 shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light" placeholder="Phone"
                                        onChange={(e) => setPhone(e.target.value)}/>
                                    </div>
                                </div>

                            </div>
                            <div className="mb-6">
                            
                                <label for="message" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">What do you need?</label>
                                <textarea id="message" rows="4" class="bg-gray-50 block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Write here..."
                                onChange={(e) => setNote(e.target.value)}></textarea>

                            </div>
                            <div>
                                <button className="px-5 py-2.5 text-white bg-crm-4 hover:bg-crm-2 rounded-lg">
                                    Submit
                                </button>
                            </div>
                        </form>
                        

                    </div>
                
                </div>
                {/* section1 */}
                {/* section6 */}
                <div className="h-full pb-24 pt-2 mx-40">
                    <div className="flex justify-center">
                        <h1 className="text-4xl font-bold">Find us</h1>
                    </div>
                    <div className="grid md:grid-cols-12 grid-cols-6 gap-10">
                        <div className="col-span-4">
                            <a>
                                <div className="bg-white w-full h-20 rounded-lg p-5 shadow-lg flex gap-2 items-center hover:bg-gray-50">
                                    <img src={Li}/>
                                    <div className="flex flex-col">
                                        <h1 className="text-[#0288D1] font-semibold">Linkedin</h1>
                                        <h1 className="text-[#0288D1]">Croya CRM Official</h1>

                                    </div>

                                </div>
                            </a>

                        </div>
                        <div className="col-span-4">
                            <a>
                                <div className="bg-white w-full h-20 rounded-lg p-5 shadow-lg flex gap-2 items-center hover:bg-gray-50">
                                    <img src={Wa}/>
                                    <div className="flex flex-col">
                                        <h1 className="text-[#40C351] font-semibold">WhatsApp</h1>
                                        <h1 className="text-[#40C351]">Croya CRM Official</h1>

                                    </div>

                                </div>
                            </a>
                        </div>
                        <div className="col-span-4">
                            <a>
                                <div className="bg-white w-full h-20 rounded-lg p-5 shadow-lg flex gap-2 items-center hover:bg-gray-50">
                                    <img src={Tk}/>
                                    <div className="flex flex-col">
                                        <h1 className="text-[#212121] font-semibold">TikTok</h1>
                                        <h1 className="text-[#212121]">Croya CRM Official</h1>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div className="col-span-4">
                            <a>
                                <div className="bg-white w-full h-20 rounded-lg p-5 shadow-lg flex gap-2 items-center hover:bg-gray-50">
                                    <img src={Fb}/>
                                    <div className="flex flex-col">
                                        <h1 className="text-[#039BE5] font-semibold">Facebook</h1>
                                        <h1 className="text-[#039BE5]">Croya CRM Official</h1>

                                    </div>

                                </div>
                            </a>

                        </div>
                        <div className="col-span-4">
                            <a>
                                <div className="bg-white w-full h-20 rounded-lg p-5 shadow-lg flex gap-2 items-center hover:bg-gray-50">
                                    <img src={Lg}/>
                                    <div className="flex flex-col">
                                        <h1 className="text-transparent bg-clip-text bg-gradient-to-br from-[#4928F4] to-[#FF1744] font-semibold">Instagram</h1>
                                        <h1 className="text-transparent bg-clip-text bg-gradient-to-br from-[#4928F4] to-[#FF1744]">Croya CRM Official</h1>

                                    </div>

                                </div>
                            </a>
                        </div>
                        <div className="col-span-4">
                            <a>
                                <div className="bg-white w-full h-20 rounded-lg p-5 shadow-lg flex gap-2 items-center hover:bg-gray-50">
                                    <img src={Tw}/>
                                    <div className="flex flex-col">
                                        <h1 className="text-[#03A9F4] font-semibold">Twitter</h1>
                                        <h1 className="text-[#03A9F4]">Croya CRM Official</h1>

                                    </div>

                                </div>
                            </a>

                        </div>
                    </div>
                </div>
                {/* section6 */}   
            </div>
            )}

        </div>
    );
}

export default Contact;










{/* section1 */}
{/* <div
  className="h-auto bg-cover bg-center py-20 md:px-36 px-10"
  style={{ backgroundImage: `url(${bg})` }}
>
  <div className="mt-40 flex flex-col items-left gap-5">
    <h1 className="text-center md:w-1/2 text-3xl font-bold">
      Mari bertumbuh lebih cepat bersama kami
    </h1>
    <p className="text-center md:w-1/2">
      Croya tidak hanya menawarkan solusi CRM, namun kami juga memiliki
      ekosistem tambahan yang bisa membantu bisnis anda untuk tumbuh lebih
      cepat. Anda bisa mengukur kinerja tiap individu di tim anda dan
      menghubungkannya dengan perhitungan bonus lewat platform afiliasi
      HRIS kami yaitu Humanusia.id. Anda bisa menggabungkan proses bisnis
      advertiser, sales, CRM dan pengiriman dalam satu proses via platform
      afiliasi kami Banyumax.id. Dan anda juga bisa menyewa tim Customer
      Service dan CRM external via layanan afiliasi kami yaitu Verdant
      Natural.
    </p>
  </div>
  <div className="grid grid-cols-3 gap-16 mt-10 mx-10">
    <div className="bg-white rounded-lg shadow-md p-5 grid grid-cols-6 items-center">
      <img src={hris} className="w-10" />
      <p className="col-span-5 text-[#D90000]">
        Integrated Human Resource Information System
      </p>
    </div>
    <div className="bg-white rounded-lg shadow-md p-5 grid grid-cols-6 items-center">
      <img src={byx} className="w-10" />
      <p className="col-span-5 text-[#20559A]">
        Integrated Sales Lead and Logistic{" "}
      </p>
    </div>
    <div className="bg-white rounded-lg shadow-md p-5 grid grid-cols-6 items-center">
      <img src={verdant} className="w-10" />
      <p className="col-span-5">
        External Customer Service and Affiliate{" "}
      </p>
    </div>
  </div>
</div> */}
{/* section2 */}
{/* <div className="md:pl-28 px-10 py-20 bg-[#EAEFEB]">
  <div className="grid md:grid-cols-2 grid-cols-1 items-center">
    <div className="space-y-3">
      <h1>Contact Us</h1>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2014.8675616597943!2d109.26028653161082!3d-7.42719800209527!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e655fcb4e28fba1%3A0x6f5a4f82723e89f6!2sPT%20Powerkerto!5e0!3m2!1sid!2sid!4v1667616453832!5m2!1sid!2sid"
        width={600}
        height={200}
      ></iframe>
      <div className="flex flex-row gap-2">
        <LocationOnIcon></LocationOnIcon>
        <p>
          Gg. Satria II, Sokayasa, RT04/RW01, Kec. Purwokerto Timur,
          Kabupaten Banyumas, Jawa Tengah 53146
        </p>
      </div>
      <div className="flex flex-row gap-2">
        <PhoneIcon></PhoneIcon>
        <p>083766863242</p>
      </div>
      <div className="flex flex-row gap-2">
        <MailIcon></MailIcon>
        <p>info.crm@gmail.com</p>
      </div>
    </div>
    <div className="space-y-5">
      <input
        className="p-2 rounded-lg w-full bg-[#F4FFFB] border-0"
        type="text"
        placeholder="Name"
      />
      <input
        className="p-2 rounded-lg w-full bg-[#F4FFFB] border-0"
        type="text"
        placeholder="Email"
      />
      <textarea
        className="p-2 rounded-lg w-full bg-[#F4FFFB] border-0"
        type="text"
        placeholder="Your Message"
        rows={5}
      ></textarea>
      <button className="py-3 px-5 bg-green-400 text-white rounded-lg">Send</button>
    </div>
  </div>
</div> */}