import React, { useState, useEffect } from "react";
import { createBroadcast } from "../../../Network/Lib/broadcast";
import { getAllTemplate, getOne } from "../../../Network/Lib/template";
import { useNavigate } from "react-router-dom";
import RepeaterLeadSource from "../Input/RepeaterLeadSource";
import { OpenInNew } from "@mui/icons-material";
import { getAllProduct } from "../../../Network/Lib/product";

function AddBroadcast({ filteredCustomer, filter }) {
  const navigate = useNavigate();
  const [showInput, setShowInput] = useState(false);
  const [name, setName] = useState([]);
  const [templateList, setTemplateList] = useState([]);
  const [message, setMessage] = useState("");
  const [textMessage, setTextMessage] = useState("");
  const [templateName, setTemplateName] = useState([]);
  const [time, setTime] = useState([]);
  const [date, setDate] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState();

  const [product, setProduct] = useState([])
  
  const onSelectTemplate = async (e) => {
    if (e.target.value != "create") {
      setSelectedTemplate(e.target.value);
      const id = e.target.value;
      await getOne(id)
        .then((response) => {
          console.log(response.data.data);
          setTemplateName(response.data.data.name);
          setTextMessage(JSON.parse(response.data.data.content.replace(/\\/g, "")).text);
          setMessage(response.data.data)
        })
        .catch((e) => { });
    } else {
      window.open("/create-template", "_blank");
    }
  };

  console.log("hasil filter:", filteredCustomer);
  console.log("params filter:", filter);
  var phone = [];
  filteredCustomer&&filteredCustomer.map((val) => phone.push(parseInt(val.phone)));

  const postData = async () => {
    const filteredCustomerPhone = filteredCustomer.map(
      (customer) => customer.phone
    );

    const form = {
      name: name,
      phone: phone,
      templateId: selectedTemplate,
      templateName: templateName,
      templateMessage: textMessage,
      date: date,
      time: time,
      filter: filter,
      variabel: dataVariable
    };
    console.log(form);
    try {
      const response = await createBroadcast(form).then((response) => {
        console.log(response);
        navigate("/broadcast");
      });
      console.log(response).catch((e) => {
        console.log(e.response);
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllTemplate().then((response) => {
      setTemplateList(response.data.data);
      console.log("template list", response.data.data);
    });
    getAllProduct()
      .then((response) => {
        setProduct(response.data.data);
        console.log("product", response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [filteredCustomer]);

  const iniList = []
  const [variable, setVariable] = useState([])
  const data = message ? (JSON.stringify(JSON.parse(message?.content?.replace(/\\/g)).text).match(new RegExp("{{", "g")) || []).length : 0
  const dataVariable = Array(data).fill({ "text": "" })
  for (let index = 1; index < data + 1; index++) {
    // for (let index = 0; index < data; index++) {
    // var hasilIni
    const handleChangeHasil = (i, e) => {
      dataVariable[i] = { "text": e }
      console.log(i, e)
    }
    iniList.push(

      <select select
        className="bg-white border border-white rounded-lg shadow-lg text-[#54DAA1] text-sm focus:ring-transparent focus:border-white block p-2.5"
        onChange={(e) => {
          // setVariable(e.target.value)
          // dataVariable[index - 1].text = (e.target.value)
          dataVariable[index - 1] = { "text": e.target.value }
        }}
      >
        <option hidden>Select Variable ({index})</option>
        <option value="customer.name">
          Customer Name ({index})
        </option>
        <option value="customer.phone">
          Customer Phone ({index})
        </option>
        <option value="customer.poin">
          Customer Poin ({index})
        </option>
        <option disabled>
          -- Product Name --
        </option>
        {product && product.map((val, i) => (
          <option key={i} value={`product.name.${val.id}`}>
            {val.name} ({index})
          </option>
        ))}
        <option disabled>
          -- Product Price --
        </option>
        {product && product.map((val, i) => (
          <option key={i} value={`product.price.${val.id}`}>
            {val.name} {val.price} ({index})
          </option>
        ))}
      </select>
      // <NestedDropdown hasil={setIniHasil} change={handleChangeHasil(index, iniHasil)}>
      //   {/* {dataVariable[index] = { "text": index+1 }} */}
      //   {/* {console.log("hasil",index,iniHasil)} */}
      //   {/* {console.log(iniHasil)} */}
      // </NestedDropdown>
    )
  }
  console.log("variable", dataVariable)

  return (
    <>
      <button
        type="button"
        className="bg-green-400 hover:bg-green-500 text-white rounded-lg px-3 py-2 font-medium flex flex-row gap-3 flex items-center"
        onClick={() => setShowInput(true)}
      >
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.33331 4.66663V13.8333C6.33331 14.0543 6.24552 14.2663 6.08924 14.4225C5.93295 14.5788 5.72099 14.6666 5.49998 14.6666H4.66665C4.44563 14.6666 4.23367 14.5788 4.07739 14.4225C3.92111 14.2663 3.83331 14.0543 3.83331 13.8333V9.66663M13 4.66663C13.663 4.66663 14.2989 4.93002 14.7677 5.39886C15.2366 5.8677 15.5 6.50358 15.5 7.16663C15.5 7.82967 15.2366 8.46555 14.7677 8.93439C14.2989 9.40323 13.663 9.66663 13 9.66663V4.66663Z"
            stroke="white"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M8 4.66664L11.77 1.52498C11.8795 1.43373 12.0128 1.3756 12.1542 1.3574C12.2956 1.33919 12.4393 1.36167 12.5684 1.42219C12.6975 1.48271 12.8066 1.57876 12.8831 1.6991C12.9595 1.81944 13.0001 1.95908 13 2.10164V12.2316C13.0001 12.3742 12.9595 12.5138 12.8831 12.6342C12.8066 12.7545 12.6975 12.8506 12.5684 12.9111C12.4393 12.9716 12.2956 12.9941 12.1542 12.9759C12.0128 12.9577 11.8795 12.8996 11.77 12.8083L8 9.66664H1.33333C1.11232 9.66664 0.900358 9.57885 0.744078 9.42257C0.587797 9.26629 0.5 9.05432 0.5 8.83331V5.49998C0.5 5.27896 0.587797 5.067 0.744078 4.91072C0.900358 4.75444 1.11232 4.66664 1.33333 4.66664H8Z"
            stroke="white"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>

        <span>Broadcast</span>
      </button>
      {showInput ? (
        <>
          <div className="bg-black bg-opacity-75 overflow-y-auto overflow-x-hidden fixed flex justify-center top-0 left-0 right-0 bottom-0 z-50 w-full md:inset-0 h-modal md:h-full">
            {/* <div className="relative w-full max-w-2xl h-full md:h-auto"> */}
            {/* ini yang ditambahin */}
            <div className="relative fixed w-full max-w-2xl h-full md:h-auto my-5">
              {/* ini yang ditambahin */}
              <div className="relative bg-[#F5F8FA] rounded-lg shadow dark:bg-gray-700">
                <div className="flex justify-between items-start p-4 rounded-t border-b dark:border-gray-600">
                  <div className="flex flex-col">
                    <h3 className="text-xl font-semibold dark:text-white">
                      Create Broadcast
                    </h3>
                    <p className="text-sm font-medium text-green-400">
                      {filteredCustomer != null ? filteredCustomer.length : 0}{" "}
                      Customer Added
                    </p>
                  </div>
                  <button
                    type="button"
                    className="bg-transparent hover:bg-gray-100 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                    onClick={() => setShowInput(false)}
                  >
                    <svg
                      className="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </button>
                </div>
                <div className="p-6 space-y-6 items-center">
                  <div className="">
                    <label
                      className="block tracking-wide text-gray-400 text-sm font-semibold mb-2"
                      for="grid-state"
                    >
                      Broadcast Name
                    </label>
                    <input
                      type="text"
                      className="border border-white text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full"
                      placeholder=""
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                  <div className="">
                    <label
                      className="block tracking-wide text-gray-400 text-sm font-semibold mb-2"
                      for="grid-state"
                    >
                      Set Send Time
                    </label>
                    <div className="flex flex-row gap-5">
                      <input
                        type="date"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block"
                        placeholder=""
                        onChange={(e) => setDate(e.target.value)}
                      />
                      <input
                        type="time"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block"
                        placeholder=""
                        onChange={(e) => setTime(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="">
                    <label
                      className="block tracking-wide text-gray-400 text-sm font-semibold mb-2"
                      for="grid-state"
                    >
                      Template
                    </label>
                    <div className="relative">
                      <select
                        className="block appearance-none w-full bg-white border border-white text-gray-400 font-medium py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-white"
                        value={selectedTemplate}
                        onChange={onSelectTemplate}
                      >
                        <option hidden>Select Template</option>
                        <option value="create" className="text-blue-600">
                          Add Template
                        </option>
                        {templateList &&
                          templateList.map(function (value, index) {
                            return (
                              <>
                                <option key={index} value={value.id}>
                                  {value.name} ({value.language})
                                </option>
                              </>
                            );
                          })}
                      </select>
                      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"></div>
                    </div>
                  </div>
                  <div className="border-2 rounded-lg my-3 p-3">
                    {message ?
                      <>
                        <div className="border-2 rounded-lg my-3 p-3 w-2/5">
                          {message.headerContent ?
                            (JSON.parse(message.headerContent.replace(/\\/g)).type == "HEADER" ?
                              <div className="flex justify-center bg-gray-300 py-5">
                                <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" fill="white" class="bi bi-card-image" viewBox="0 0 16 16">
                                  <path d="M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                                  <path d="M1.5 2A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13zm13 1a.5.5 0 0 1 .5.5v6l-3.775-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12v.54A.505.505 0 0 1 1 12.5v-9a.5.5 0 0 1 .5-.5h13z" />
                                </svg>
                              </div>
                              : null)
                            : null}
                          <p className="my-2">
                            {message.content ? JSON.parse(message.content.replace(/\\/g)).text : null}
                          </p>
                          {message.footer ? (
                            JSON.parse(message.footer.replace(/\\/g)).type == "FOOTER" ?
                              <p className="text-gray-400 my-2">{(JSON.parse(message.footer.replace(/\\/g)).text)}</p>
                              : null)
                            : null}
                          {message.buttons ?
                            (JSON.parse(message.buttons.replace(/\\/g))?.type == "BUTTONS" ?
                              <a href={JSON.parse(message.buttons.replace(/\\/g))?.buttons[0]?.url} target="_blank" className="flex justify-center text-blue-500 py-5 border-t space-x-3">
                                <OpenInNew />
                                <span>
                                  {(JSON.parse(message.buttons.replace(/\\/g))?.buttons[0]?.text)}
                                </span>
                              </a>
                              // console.log(JSON.parse(message.buttons.replace(/\\/g)))
                              : null)
                            : null}
                          {/* <p>{JSON.stringify(message)}</p> */}
                        </div>
                        <div className="grid grid-cols-4 gap-3 my-3">
                          {iniList}
                        </div>
                      </> : null
                    }
                  </div>
                  <div className="mt-10 flex flex-row justify-end">
                    <button
                      className="px-3 rounded-xl text-gray-400 hover:text-gray-600 font-semibold"
                      onClick={() => setShowInput(false)}
                    >
                      Cancel
                    </button>
                    <button
                      className="py-2 px-5 rounded-md text-white bg-green-400 hover:bg-green-500 font-semibold ml-2"
                      onClick={postData}
                    >
                      Create Broadcast
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
}

export default AddBroadcast;
