import "aos/dist/aos.css";
import React, { useEffect, useState } from "react";
import SidebarProduct from "../../Components/Sidebar/SidebarProduct";
import ChartOmzet from "../../Components/Partials/Chart/Product/ChartOmzet";
import ChartClosing from "../../Components/Partials/Chart/Product/ChartClosing";
import ChartBottle from "../../Components/Partials/Chart/Product/ChartBottle";
import ChartRatioBottle from "../../Components/Partials/Chart/Product/ChartRatioBottle";
import ChartClosingRate from "../../Components/Partials/Chart/Product/ChartClosingRate";
import ChartCrossSelling from "../../Components/Partials/Chart/Product/ChartCrossSelling";
import ChartTotalDatabase from "../../Components/Partials/Chart/Product/ChartTotalDatabase";
import ChartCustomerSatisfaction from "../../Components/Partials/Chart/Product/ChartCustomerSatisfaction";
import ChartCustomerComplain from "../../Components/Partials/Chart/Product/ChartCustomerComplain";
import Profile from "../../Assets/img/Profile.jpg";
import { useLocation, useNavigate } from "react-router-dom";
import { showProduct } from "../../Network/Lib/product";
import { logout } from "../../Network/Lib/auth";

function DetailProduct() {
  const location = useLocation();
  const [navSideBar, setNavSideBar] = useState(false);
  const [refreshApi, setRefreshApi] = useState(false);
  const [product, setProduct] = useState([]);
  const navigate = useNavigate();

  const id = location.state.id
  useEffect(() => {
    showProduct(id)
    .then((response) => {
      setProduct(response.data)
      console.log(response.data)
    })
    setRefreshApi(false)
  }, [refreshApi, id])
  

  const signOut =()=>{
    logout();
    navigate('/login')
  }

  return (
    <>
      <div className="bg-gradient-to-r from-[#54DAA1] to-[#2DCE89]">
        <div className="relative">
          <aside
            className={
              navSideBar === true
                ? "w-24 h-screen p-5 fixed top-0 left-0 right-0 z-10"
                : "w-3/12 h-screen p-5 fixed top-0 left-0 right-0 z-10"
            }
            aria-label="Sidebar"
          >
            <button
              className="absolute top-72 right-1 w-10 h-10 bg-gradient-to-r from-[#54DAA1] to-[#2DCE89] rounded-full shadow-lg flex justify-center items-center"
              onClick={() => setNavSideBar(!navSideBar)}
            >
              {navSideBar ? (
                <svg
                  width="9"
                  height="16"
                  viewBox="0 0 9 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 14.3333L7.66667 7.66668L1 1.00001"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              ) : (
                <svg
                  width="10"
                  height="16"
                  viewBox="0 0 10 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.33325 14.6667L1.66659 7.99999L8.33325 1.33332"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              )}
            </button>
            <SidebarProduct navSideBar={navSideBar} />
          </aside>
        </div>
        <div
          className={
            navSideBar === true
              ? "ml-24 h-72 w-11/12 p-5 space-y-5"
              : "ml-auto h-72 w-9/12 p-5 space-y-5"
          }
        >
          {/* Header */}
          <div className="flex flex-row justify-between items-center">
            <div className="text-white space-y-2">
              <div className="flex flex-row space-x-3">
                <p className="text-sm">Pages</p>
                <p className="text-sm">/</p>
                <a href="/product" className="text-sm">
                  Product
                </a>
                <p className="text-sm">/</p>
                <a href="/product" className="text-sm">
                  Detail Product
                </a>
              </div>
              <p className="font-semibold">Detail Product</p>
            </div>
            <div className="flex flex-row items-center gap-5">
              <a href="#" className="flex items-center gap-2">
                <svg
                  width="21"
                  height="20"
                  viewBox="0 0 21 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.4938 2.52235C12.7336 1.70165 11.6719 1.24969 10.5 1.24969C9.32191 1.24969 8.25668 1.69891 7.50004 2.51454C6.73519 3.33915 6.36254 4.45985 6.45004 5.67001C6.62348 8.05751 8.44027 9.99969 10.5 9.99969C12.5598 9.99969 14.3735 8.0579 14.5496 5.67079C14.6383 4.47157 14.2633 3.35321 13.4938 2.52235Z"
                    fill="white"
                  />
                  <path
                    d="M17.3752 18.7488H3.62522C3.44525 18.7511 3.26701 18.7133 3.10349 18.6381C2.93997 18.5629 2.79526 18.4522 2.67991 18.314C2.426 18.0105 2.32366 17.596 2.39944 17.1769C2.72913 15.348 3.75804 13.8117 5.37522 12.7332C6.81194 11.7757 8.63186 11.2488 10.5002 11.2488C12.3686 11.2488 14.1885 11.7761 15.6252 12.7332C17.2424 13.8113 18.2713 15.3476 18.601 17.1765C18.6768 17.5957 18.5744 18.0101 18.3205 18.3136C18.2052 18.4519 18.0605 18.5626 17.897 18.6379C17.7335 18.7132 17.5552 18.7511 17.3752 18.7488Z"
                    fill="white"
                  />
                </svg>
                <p className="text-white">{localStorage.getItem("nameUser")}</p>
              </a>
              <button 
              onClick={signOut} 
              className="bg-white p-3 rounded-lg">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.5625 2.8125H2.1875V17.8125H6.5625M13.4375 5.9375L17.8125 10.3125L13.4375 14.6875M7.1875 10.3125H17.8125"
                      stroke="#EF4444"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
            </div>
          </div>
          {/* start: Main */}
          <div className="grid grid-cols-12 gap-5">
            <div className="col-span-12">
              <div className="bg-white shadow-lg h-full rounded-lg p-6 space-y-5">
                <div className="flex flex-row justify-between">
                  <div className="flex items-center justify-center gap-3">
                    <a href="/product">
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.11261 0.862514L0.862615 7.11251C0.748814 7.23139 0.659608 7.37157 0.600115 7.52501C0.475092 7.82934 0.475092 8.17069 0.600115 8.47501C0.659608 8.62845 0.748814 8.76863 0.862615 8.88751L7.11261 15.1375C7.22916 15.2541 7.36753 15.3465 7.5198 15.4096C7.67208 15.4727 7.83529 15.5051 8.00011 15.5051C8.33299 15.5051 8.65224 15.3729 8.88761 15.1375C9.12299 14.9021 9.25523 14.5829 9.25523 14.25C9.25523 13.9171 9.12299 13.5979 8.88761 13.3625L4.76261 9.25001H14.2501C14.5816 9.25001 14.8996 9.11832 15.134 8.8839C15.3684 8.64948 15.5001 8.33153 15.5001 8.00001C15.5001 7.66849 15.3684 7.35055 15.134 7.11613C14.8996 6.88171 14.5816 6.75001 14.2501 6.75001H4.76261L8.88761 2.63751C9.00478 2.52131 9.09777 2.38306 9.16123 2.23073C9.22469 2.07841 9.25736 1.91503 9.25736 1.75001C9.25736 1.585 9.22469 1.42162 9.16123 1.26929C9.09777 1.11697 9.00478 0.978718 8.88761 0.862514C8.77141 0.745353 8.63316 0.652359 8.48084 0.588898C8.32851 0.525437 8.16513 0.492765 8.00011 0.492765C7.8351 0.492765 7.67172 0.525437 7.51939 0.588898C7.36707 0.652359 7.22882 0.745353 7.11261 0.862514Z"
                          fill="#A1A1AA"
                        />
                      </svg>
                    </a>
                    <h1 className="text-lg font-bold">Detail Product</h1>
                  </div>
                </div>

                <div className="shadow-md rounded-lg p-5">
                  <div className="flex flex-row justify-between gap-5 mb-5">
                    <div className="flex flex-col md:flex-row gap-5">
                      <img
                        src={product.image}
                        alt="qr"
                        className="rounded-md w-24 h-24"
                      />
                      <div className="flex flex-col gap-3 ">
                        <p className="font-bold text-lg">{product.name}</p>
                        <div className="">
                          <div className="flex flex-row gap-5">
                            <div className="border-dashed border-2 py-1 px-3 rounded-lg">
                              <p className="font-bold text-lg text-start text-black">
                                {product.code}
                              </p>
                              <p className="font-medium text-start text-gray-400">
                                SKU
                              </p>
                            </div>
                            <div className="border-dashed border-2 py-1 px-3 rounded-lg">
                              <p className="font-bold text-lg text-start text-black">
                                Rp {parseInt(product.price).toLocaleString()}
                              </p>
                              <p className="font-medium text-start text-gray-400">
                                Price
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="grid md:grid-cols-2 space-y-5">
                  <ChartOmzet />
                  <ChartClosing />
                  <ChartBottle />
                  <ChartRatioBottle />
                  <ChartClosingRate />
                  <ChartCrossSelling />
                  <ChartTotalDatabase />
                  <ChartCustomerSatisfaction />
                  <ChartCustomerComplain />
                </div>
              </div>
            </div>
          </div>

          {/* end: Main */}
        </div>
      </div>
    </>
  );
}

export default DetailProduct;
