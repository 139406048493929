import React, { useState } from "react";
import RepeaterLeadSource from "../Input/RepeaterLeadSource";
import resend from "../../../Assets/img/resend.png";

function Resend() {
  const [showInput, setShowInput] = useState(false);

  return (
    <>
      <button
        type="button"
        className="bg-[#ED4C5C] hover:bg-[#CC414F] text-white rounded-full px-3 py-2 font-medium flex flex-row gap-3 flex items-center"
        onClick={() => setShowInput(true)}
      >
        <svg
          width="24"
          height="25"
          viewBox="0 0 24 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M20 4.5H6C4.897 4.5 4 5.397 4 6.5V11.5H6V8.5L12.4 13.3C12.5732 13.4297 12.7837 13.4998 13 13.4998C13.2163 13.4998 13.4268 13.4297 13.6 13.3L20 8.5V17.5H12V19.5H20C21.103 19.5 22 18.603 22 17.5V6.5C22 5.397 21.103 4.5 20 4.5ZM13 11.25L6.666 6.5H19.334L13 11.25Z"
            fill="white"
          />
          <path
            d="M2 12.5H9V14.5H2V12.5ZM4 15.5H10V17.5H4V15.5ZM7 18.5H11V20.5H7V18.5Z"
            fill="white"
          />
        </svg>

        <span>Resend</span>
      </button>
      {showInput ? (
        <>
          <div className="bg-black bg-opacity-75 overflow-y-auto overflow-x-hidden fixed flex justify-center top-0 left-0 right-0 bottom-0 z-50 w-full md:inset-0 h-modal md:h-full">
            {/* <div className="relative w-full max-w-2xl h-full md:h-auto"> */}
            {/* ini yang ditambahin */}
            <div className="fixed w-full max-w-xl md:h-auto mt-32">
              {/* ini yang ditambahin */}
              <div className="relative bg-[#F5F8FA] rounded-lg shadow dark:bg-gray-700">
                <div className="p-6 space-y-6 items-center">
                  <div className="flex flex-row justify-between items-start gap-5">
                    <div className="flex flex-row gap-5">
                      <img src={resend} className="w-100" />
                      <div className="flex flex-col">
                        <h1 className="font-bold">Resend Unsent Message?</h1>
                        <p className="font-semibold text-gray-400">
                          Resend only for unsent message
                        </p>
                        <div className="mt-10 flex flex-row justify-start gap-3">
                          <button className="text-sm py-2 px-5 rounded-xl text-white bg-green-400 hover:bg-green-500 font-semibold">
                            Yes
                          </button>
                          <button
                            className="text-sm px-3 rounded-xl text-gray-400 hover:text-gray-600 bg-gray-100 hover:bg-gray-200 font-semibold"
                            onClick={() => setShowInput(false)}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                    <button
                      type="button"
                      className="bg-transparent hover:bg-gray-100 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                      onClick={() => setShowInput(false)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="#A1A5B7"
                        className="bi bi-x-circle"
                        viewBox="0 0 16 16"
                      >
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
}

export default Resend;
