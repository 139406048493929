import React, { Component, useState } from "react";
import { Bar } from "react-chartjs-2";
import monthly from "../../../../Data/Product/bottle/monthly";
import yearly from "../../../../Data/Product/bottle/yearly";
import Q1 from "../../../../Data/Product/bottle/Q1";
import closingPerTeam from "../../../../Data/Reporting/closingPerTeam";
import semester from "../../../../Data/Product/bottle/semester";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function ChartBottle() {
  const getInputValue = (event) => {
    // show the user input value to console
    const userValue = event.target.value;
    if (userValue === "mon") {
      const year = document.getElementById("2");
      year.setAttribute("hidden", true);
      const quar = document.getElementById("3");
      quar.setAttribute("hidden", true);
      const sem = document.getElementById("4");
      sem.setAttribute("hidden", true);
      const mon = document.getElementById("1");
      mon.removeAttribute("hidden");
      console.log("mon");
    } else if (userValue === "year") {
      const mon = document.getElementById("1");
      mon.setAttribute("hidden", true);
      const quar = document.getElementById("3");
      quar.setAttribute("hidden", true);
      const sem = document.getElementById("4");
      sem.setAttribute("hidden", true);
      const year = document.getElementById("2");
      year.removeAttribute("hidden");
      console.log("year");
    } else if (userValue === "quar") {
      const year = document.getElementById("2");
      year.setAttribute("hidden", true);
      const mon = document.getElementById("1");
      mon.setAttribute("hidden", true);
      const sem = document.getElementById("4");
      sem.setAttribute("hidden", true);
      const quar = document.getElementById("3");
      quar.removeAttribute("hidden");
      console.log("quar");
    } else if (userValue === "sem") {
      const year = document.getElementById("2");
      year.setAttribute("hidden", true);
      const quar = document.getElementById("3");
      quar.setAttribute("hidden", true);
      const mon = document.getElementById("1");
      mon.setAttribute("hidden", true);
      const sem = document.getElementById("4");
      sem.removeAttribute("hidden");
      console.log("sem");
    }

    console.log(userValue);
  };

  const [value, setvalue] = React.useState("Q1");
  const handleChange = (event) => {
    setvalue(event.target.value);
  };
  return (
    <>
      <div className="shadow-md rounded-lg m-1">
        <div className="flex flex-row justify-between px-10 mt-5">
          <div>
            <h1 className="font-bold text-lg">Bottle</h1>
            <h1 className="text-gray-400">Total Bottle 5.000.000</h1>
          </div>

          <div className="flex flex-col items-end">
            <div className="relative w-fit">
              <select
                onChange={getInputValue}
                className="block appearance-none w-full bg-gray-100 border border-gray-200 text-xs text-gray-700 py-2 px-2 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              >
                <option value="mon">Monthly</option>
                <option value="year">Yearly</option>
                <option value="quar">Quarter</option>
                <option value="sem">Semester</option>
              </select>
              {/* <ul className="grid gap-6 w-full md:grid-cols-2">
                <li>
                  <input
                    type="radio"
                    id="hosting-small"
                    name="hosting"
                    value="hosting-small"
                    className="hidden peer"
                    required
                  />
                  <label
                    for="hosting-small"
                    className="inline-flex justify-between items-center p-2 text-gray-500 bg-white rounded-lg border border-gray-200 cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-600 hover:bg-gray-100 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700"
                  >
                      <div className="w-full text-lg font-semibold">0-50 MB</div>
                  </label>
                </li>
                <li>
                  <input
                    type="radio"
                    id="hosting-big"
                    name="hosting"
                    value="hosting-big"
                    className="hidden peer"
                  />
                  <label
                    for="hosting-big"
                    className="inline-flex justify-between items-center p-2 text-gray-500 bg-white rounded-lg border border-gray-200 cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-600 hover:bg-gray-100 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700"
                  >
                      <div className="w-full text-lg font-semibold">
                        500-1000 MB
                      </div>
                  </label>
                </li>
              </ul> */}
              {/* quarter */}
              {/* <div className="flex flex-row gap-2 m-2">
                <div>
                  <label
                    htmlFor="Q1"
                    className="bg-gray-100 hover:bg-[#4FC29B] text-sm text-gray-400 hover:text-white rounded-md p-2 peer-checked:border-[#4FC29B] peer-checked:bg-[#4FC29B]"
                  >
                    Q1
                  </label>
                  <input
                    type="radio"
                    value="Q1"
                    // id="Q1"
                    name="Q"
                    checked={value === "Q1"}
                    onChange={handleChange}
                    // className="hidden peer"
                  />
                </div>
                <div>
                  <label
                    htmlFor="Q2"
                    className="bg-gray-100 hover:bg-[#4FC29B] text-sm text-gray-400 hover:text-white rounded-md p-2 peer-checked:border-[#4FC29B] peer-checked:bg-[#4FC29B]"
                  >
                    Q2
                  </label>
                  <input
                    type="radio"
                    value="Q2"
                    // id="Q2"
                    name="Q"
                    checked={value === "Q2"}
                    onChange={handleChange}
                    // className="hidden peer"
                  />
                </div>
                <div>
                  <label
                    htmlFor="Q3"
                    className="bg-gray-100 hover:bg-[#4FC29B] text-sm text-gray-400 hover:text-white rounded-md p-2 peer-checked:border-[#4FC29B] peer-checked:bg-[#4FC29B]"
                  >
                    Q3
                  </label>
                  <input
                    type="radio"
                    value="Q3"
                    // id="Q3"
                    name="Q"
                    checked={value === "Q3"}
                    onChange={handleChange}
                    // className="hidden peer"
                  />
                </div>
              </div> */}
              {/* quarter */}
            </div>
            {/* <div className="flex flex-col md:flex-row md:items-center items-start mx-5">
              <input
                type={"date"}
                className="text-xs border-t-transparent border-r-transparent border-l-transparent"
              />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-dash"
                viewBox="0 0 16 16"
              >
                <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z" />
              </svg>
              <input
                type={"date"}
                className="text-xs border-t-transparent border-r-transparent border-l-transparent"
              />
            </div> */}
          </div>
        </div>
        <div className="border-b mt-5"></div>
        <div id="1" className="px-16 py-10">
          <Bar
            options={{
              plugins: {
                title: {
                  display: true,
                },
              },
              responsive: true,
              scales: {
                x: {
                  stacked: true,
                },
                y: {
                  stacked: true,
                },
              },
            }}
            data={monthly}
          />
        </div>
        <div hidden id="2" className="px-16 py-10">
          <Bar
            options={{
              plugins: {
                title: {
                  display: true,
                },
              },
              responsive: true,
              scales: {
                x: {
                  stacked: true,
                },
                y: {
                  stacked: true,
                },
              },
            }}
            data={yearly}
          />
        </div>
        <div hidden id="3" className="px-16 py-10">
          <Bar
            options={{
              plugins: {
                title: {
                  display: true,
                },
              },
              responsive: true,
              scales: {
                x: {
                  stacked: true,
                },
                y: {
                  stacked: true,
                },
              },
            }}
            data={Q1}
          />
        </div>
        <div hidden id="4" className="px-16 py-10">
          <Bar
            options={{
              plugins: {
                title: {
                  display: true,
                },
              },
              responsive: true,
              scales: {
                x: {
                  stacked: true,
                },
                y: {
                  stacked: true,
                },
              },
            }}
            data={semester}
          />
        </div>
        {/* <div className="px-16 py-10">
          <Bar
            options={{
              plugins: {
                title: {
                  display: true,
                },
              },
              responsive: true,
              scales: {
                x: {
                  stacked: true,
                },
                y: {
                  stacked: true,
                },
              },
            }}
            data={bottle}
          />
        </div> */}
      </div>
    </>
  );
}

export default ChartBottle;
