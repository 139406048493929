import React, { useState, useEffect } from "react";
import Profile from "../../../Assets/img/Profile.jpg";
import peomotionData from "../../../Data/promotion";
import Table from "../../Table/Promotion/body";

function TablePromotion() {
  const [promotion] = useState([...peomotionData]);
  return (
    <div className="">
      <Table data={promotion} rowsPerPage={10} />
    </div>
    // <div className="">
    //   <div className="overflow-x-auto md:h-80">
    //     <table className="w-full text-sm text-left text-gray-500">
    //       <thead className="text-sm text-gray-400">
    //         <tr>
    //           <th scope="col" className="px-6 py-3 w-32">
    //             Customer
    //           </th>
    //           <th scope="col" className="px-6 py-3 w-32">
    //             No. WhatsApp
    //           </th>
    //           <th scope="col" className="px-6 py-3 w-32">
    //             Email
    //           </th>
    //           <th scope="col" className="px-6 py-3 w-32">
    //             Level
    //           </th>
    //           <th scope="col" className="px-6 py-3 w-32">
    //             Buying Date
    //           </th>
    //           <th scope="col" className="px-6 py-3 w-32">
    //             Action
    //           </th>
    //         </tr>
    //       </thead>
    //       <tbody>
    //         <tr className="bg-white border-b border-[#F5F8FA] border-2">
    //           <td className="px-6 py-4">Abdan S</td>
    //           <td className="px-6 py-4">62821213131313</td>
    //           <td className="px-6 py-4">user@gmail.com</td>
    //           <td className="px-6 py-4">
    //             <div className="bg-[#E9FFF7] p-1 text-center text-[#4FC29B] rounded">
    //               Repeat Order 1
    //             </div>
    //           </td>
    //           <td className="px-6 py-4">16/06/2022</td>
    //           <td className="px-6 py-4">
    //             <a
    //               href="/dashboard/view-customer"
    //               className="bg-gray-100 hover:bg-gray-200 py-1 px-3 rounded"
    //             >
    //               View
    //             </a>
    //           </td>
    //         </tr>
    //         <tr className="bg-white border-b border-[#F5F8FA] border-2">
    //           <td className="px-6 py-4">Abdan S</td>
    //           <td className="px-6 py-4">62821213131313</td>
    //           <td className="px-6 py-4">user@gmail.com</td>
    //           <td className="px-6 py-4">
    //             <div className="bg-[#EAF7FF] p-1 text-center text-[#009EF7] rounded">
    //               Reseller
    //             </div>
    //           </td>
    //           <td className="px-6 py-4">16/06/2022</td>
    //           <td className="px-6 py-4">
    //             <a
    //               href="/dashboard/view-customer"
    //               className="bg-gray-100 hover:bg-gray-200 py-1 px-3 rounded"
    //             >
    //               View
    //             </a>
    //           </td>
    //         </tr>
    //         <tr className="bg-white border-b border-[#F5F8FA] border-2">
    //           <td className="px-6 py-4">Abdan S</td>
    //           <td className="px-6 py-4">62821213131313</td>
    //           <td className="px-6 py-4">user@gmail.com</td>
    //           <td className="px-6 py-4">
    //             <div className="bg-[#FFE9EB] p-1 text-center text-[#ED4C5C] rounded">
    //               Customer Pasif
    //             </div>
    //           </td>
    //           <td className="px-6 py-4">16/06/2022</td>
    //           <td className="px-6 py-4">
    //             <a
    //               href="/dashboard/view-customer"
    //               className="bg-gray-100 hover:bg-gray-200 py-1 px-3 rounded"
    //             >
    //               View
    //             </a>
    //           </td>
    //         </tr>
    //       </tbody>
    //     </table>
    //   </div>
    //   <div className="flex flex-col items-center">
    //     <div className="inline-flex mt-2 xs:mt-0">
    //       <button className="py-2 px-4 mr-2 text-sm font-medium text-white bg-gray-800 rounded-xl hover:bg-gray-900 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
    //         1
    //       </button>
    //       <button className="py-2 px-4 mr-2 text-sm font-medium border-2 text-black bg-white rounded-xl hover:bg-white dark:bg-white dark:border-black dark:text-gray-400 dark:hover:bg-gray-500 dark:hover:text-white">
    //         2
    //       </button>
    //       <button className="py-2 px-4 mr-2 text-sm font-medium border-2 text-black bg-white rounded-xl hover:bg-white dark:bg-white dark:border-black dark:text-gray-400 dark:hover:bg-gray-500 dark:hover:text-white">
    //         3
    //       </button>
    //       <button className="py-2 px-4 mr-2 text-sm font-medium border-2 text-black bg-white rounded-xl hover:bg-white dark:bg-white dark:border-black dark:text-gray-400 dark:hover:bg-gray-500 dark:hover:text-white">
    //         4
    //       </button>
    //       <button className="py-2 px-4 mr-2 text-sm font-medium border-2 text-black bg-white rounded-xl hover:bg-white dark:bg-white dark:border-black dark:text-gray-400 dark:hover:bg-gray-500 dark:hover:text-white">
    //         5
    //       </button>
    //     </div>
    //   </div>
    // </div>
  );
}

export default TablePromotion;
