import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../Assets/img/logo.png";

function ForgotPassword() {
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const ForgotPassword = async (e) => {
    e.preventDefault();

    ForgotPassword()
      // .then((response) => {
        // console.log(response);
        setShow(true);
        // navigate("/");
        setTimeout(() => {
          navigate("/new-password");
        }, 3000);
      // })
  };
  return (
    <div className="flex flex-col md:flex-row">
      <div className="bg-fg-img h-96 bg-cover md:w-1/2 md:h-screen md:block hidden"></div>
      <div className="md:p-14 p-5 md:w-1/2 flex flex-col items-center gap-10">
        <div className="self-end">
          <img className="md:h-28 h-20" src={logo} />
        </div>
        <div className="w-1/2">
          <div className="space-y-2">
            <h1 className="text-2xl font-bold">Forgot Password</h1>
            <p className="text-gray-400 text-sm">
              We will send link to set your new password, please check your
              email box
            </p>
          </div>
          <form onSubmit={ForgotPassword} className="box">
            <div className="space-y-3">
              <div className="relative">
                <input
                  type="email"
                  id="email"
                  className="block rounded-t-lg px-2.5 pb-2.5 pt-5 w-full text-sm text-gray-900 border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                />
                <label
                  htmlFor="email"
                  className="absolute flex gap-3 items-center text-sm text-gray-400 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-2.5 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4"
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.35713 4.28564H18.6428C19.3544 4.2856 20.039 4.55783 20.5562 5.04651C21.0734 5.53518 21.384 6.20324 21.4243 6.91364L21.4286 7.07136V16.9285C21.4286 17.64 21.1564 18.3246 20.6677 18.8418C20.179 19.359 19.511 19.6696 18.8006 19.7099L18.6428 19.7142H5.35713C4.64558 19.7143 3.96099 19.442 3.44378 18.9534C2.92658 18.4647 2.61598 17.7966 2.5757 17.0862L2.57141 16.9285V7.07136C2.57136 6.35981 2.8436 5.67522 3.33227 5.15802C3.82094 4.64082 4.48901 4.33021 5.19941 4.28993L5.35713 4.28564H18.6428H5.35713ZM20.1428 9.34707L12.2966 13.4271C12.2206 13.4666 12.1374 13.4905 12.052 13.4974C11.9667 13.5043 11.8808 13.4941 11.7994 13.4674L11.7034 13.4271L3.85713 9.34707V16.9285C3.85714 17.305 3.9987 17.6676 4.25371 17.9446C4.50872 18.2215 4.85852 18.3924 5.2337 18.4234L5.35713 18.4285H18.6428C19.0194 18.4285 19.3822 18.2868 19.6592 18.0316C19.9361 17.7764 20.1069 17.4264 20.1377 17.0511L20.1428 16.9285V9.34707V9.34707ZM18.6428 5.57136H5.35713C4.98068 5.57137 4.618 5.71293 4.34108 5.96794C4.06416 6.22295 3.89324 6.57276 3.86227 6.94793L3.85713 7.07136V7.89764L12 12.1319L20.1428 7.89764V7.07136C20.1428 6.69477 20.0011 6.33198 19.7459 6.05504C19.4908 5.7781 19.1407 5.60727 18.7654 5.5765L18.6428 5.57136Z"
                      fill="#A1A1AA"
                    />
                  </svg>
                  Email
                </label>
              </div>
              <button
                className="text-white bg-gradient-to-r from-green-1 to-green-2 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-3 text-center mr-2 mb-2 w-full"
              >
                Send
              </button>
            </div>
          </form>
        </div>

        <div
          className={show ? "block p-4 mb-4 mt-20 text-sm bg-white rounded-lg dark:bg-white dark:text-black flex gap-3 shadow-lg":"hidden p-4 mb-4 mt-20 text-sm bg-white rounded-lg dark:bg-white dark:text-black flex gap-3 shadow-lg"}
          role="alert"
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10 18.75C14.8325 18.75 18.75 14.8325 18.75 10C18.75 5.16751 14.8325 1.25 10 1.25C5.16751 1.25 1.25 5.16751 1.25 10C1.25 14.8325 5.16751 18.75 10 18.75ZM5.625 10.2581L6.50813 9.375L8.75 11.6163L13.4906 6.875L14.375 7.75937L8.75 13.3837L5.625 10.2581Z"
              fill="#00A870"
            />
          </svg>
          Password reset link has been sent to your email
        </div>
        {/* <div className='flex flex-col'>
                    <h1 className='font-bold text-xl text-center pb-10'>
                        Customer Relationship Management
                    </h1>
                    <div>
                        <h1 className='font-bold text-2xl'>Hello,</h1>
                        <h1 className='text-red-600 font-bold text-2xl'>Welcome Back!</h1>
                        <div className='py-10 flex flex-col'>
                            <form onSubmit={Auth} className='box'>
                                <p className='has-text-centered'>{msg}</p>
                                <Input height={'mb-5'} title={'Username'} icon={<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"></path>} placeholder={'Username'} type={'text'} value={username} onChange={(e) => setUsername(e.target.value)}/>
                                <Input title={'Password'} icon={<path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z'></path>} placeholder={'Password'} type={'password'} value={password} onChange={(e) => setPassword(e.target.value)}/>
                                <Link to='/' className='flex justify-end p-2 font-semibold text-gray-500 text-sm hover:text-blue-600'>Forget Password?</Link>
                                <div className='mt-4'>
                                    <button className="text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-3 text-center mr-2 mb-2 w-full">Login</button>
                                </div>
                                <h1 className='text-center p-2 font-semibold text-gray-500 text-sm'>Don’t have an account? <Link to='/create-account' className='text-blue-600'> Sign Up</Link></h1>
                            </form>
                        </div>
                    </div>
                    <h1 className='font-bold text-gray-500 text-center'>Powered By <span className='text-cyan-500'>POWER<span className='text-black'>KERTO</span></span></h1>
                </div> */}
      </div>
    </div>
  );
}

export default ForgotPassword;
