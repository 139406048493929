import axios from "axios";
import api from "../apiClient";
import { BASE_URL_B } from "../../config";
const config = {
  headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
};
export function getWhatsappBarcode(phone, name) {
  return axios.get(`${BASE_URL_B}whatsapp-barcode?phone=${phone}&name=${name}`, config)
}
export function sendMessage(data) {
  return axios.post(`${BASE_URL_B}whatsapp/send-message`, data, config)
}
export function sendMediaMessage(data) {
  return axios.post(`${BASE_URL_B}whatsapp/send-media-message`, data, config)
}
export function deleteCache(id, description) {
  return axios.delete(`${BASE_URL_B}whatsapp/delete-cache`)
}
export function getListMessage(phone, query) {
  return api.axiosClient.get(`contact-wa-unofficial?numberCs=${phone}&search=${query}`)
}
export function getMessage(phone, numberCs) {
  return api.axiosClient.get(`showMessage-wa-unofficial/${phone}?numberCs=${numberCs}`)
}
export function getSession() {
  return api.axiosClient.get(`/getSession`)
}
export function createSession(data) {
  return api.axiosClient.post(`/createSession`, data)
}
export function deleteSessions(id) {
  return api.axiosClient.delete(`/deleteSession/${id}`)
}
export function testSession(phone) {
  return axios.get(`${BASE_URL_B}testSession?sender=${phone}`, config)
}
export function getTestimoni(id) {
  return api.axiosClient.get(`getTestimoni/${id}`)
}
export function createTestimoni(data) {
  return api.axiosClient.post(`/testimoni/create`, data)
}
