import axios from "axios";
import Cookies from "universal-cookie";
import { getToken } from "./Lib/auth";
import { BASE_URL_A } from "../config";

const urlApi = BASE_URL_A

const cookies = new Cookies();

const axiosClient = axios.create({
  baseURL: urlApi,
  headers: {
    Accept: "application/json",
    // "Set-Cookie": cookies.get("refreshToken"),
  },
  withCredentials: true,
});

axiosClient.interceptors.request.use((config) => {
  config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
  return config;
});

// axiosClient.interceptors.request.use((config) => {
//   config.headers.Cookie = cookies.get("refreshToken");
//   return config;
// });

// axiosClient.interceptors.response.use(
//   (response) => {
//     return response;
//   },
//   async (error) => {
//     const originalRequest = error.config;
//     if (error.response !== null) {
//       if (error.response.status === 403 && !originalRequest._retry) {
//         originalRequest._retry = true;
//         try {
//           const accessToken = await getToken();
//           localStorage.setItem("token", accessToken.data.accessToken);
//           originalRequest.headers.Authorization = `token ${localStorage.getItem(
//             "token"
//           )}`;
//           return axiosClient(originalRequest);
//         } catch (error) {
//           return Promise.reject(error);
//         }
//       }
//     }
//     return Promise.reject(error);
//   }
// );

const api = {
  axiosClient,
  urlApi,
};

export default api;
