import axios from "axios";
import api from "../apiClient";
// import token from "../../../backend/resources/static/assets/token/token.txt"

export function getMessage(phone) {
  return api.axiosClient.get(`/showMessage/${phone}`);
}
export function getEmoji() {
  return api.axiosClient.get(`/getEmoji`);
}
export function sendMessage(data) {
  return api.axiosClient.post(`/sendTextMessage`, data);
}
export function sendMedia(data) {
  return api.axiosClient.post(`/sendMediaMessage`, data,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
}
export function sendLocation(data) {
  return api.axiosClient.post(`/sendLocation`, data);
}
export function sendReaction(data) {
  return api.axiosClient.post(`/sendReactionMessage`, data);
}

export function getListMessage(){
  return api.axiosClient.get('/getNameByPhone')
}

// export function getRetrieveMediaURL(id, token) {
//   const urlApi = 'https://graph.facebook.com/'
//   const client = axios.create({
//     baseURL: urlApi,
//     // headers: {
//     //   Accept: "application/json",
//     //   // "Set-Cookie": cookies.get("refreshToken"),
//     // }
//   });

//   client.interceptors.request.use((config) => {
//     config.headers.Authorization = `Bearer ${token}`;
//     return config;
//   });
//   const api2 = {
//     client,
//     urlApi,
//   };

//   return api2.client.get(`v16.0/${id}?phone_number_id=102287409450538`);
// }