import React, { Component, useState, useEffect } from "react";
import Sidebar from "../../Components/Sidebar/SidebarPoint";
import "aos/dist/aos.css";
import AddPoint from "../../Components/Partials/Modal/AddPoint";
// import people from "../../Assets/img/product.png";
import { PencilIcon, TrashIcon } from "@heroicons/react/solid";
import Delete from "../../Assets/img/Delete-data.png";
import { getProducts } from "../../Network/Lib/boProduct";
import { pointGet } from "../../Network/Lib/point";
import {
  pointDelete,
  pointUpdate
} from "../../Network/Lib/point";
import { useNavigate } from "react-router-dom";
import { logout } from "../../Network/Lib/auth";
import CurrencyFormat from "react-currency-format";
import defaultProduct from "../../Assets/img/default-product.png";

function Point() {
  const [navSideBar, setNavSideBar] = useState(false);
  const [refreshApi, setRefreshApi] = useState(false);

  const [showDelete, setShowDelete] = useState(false);
  const [showEditRule, setShowEditRule] = useState(false);
 

  const [point, setPoint] = useState([]);
  const [selectedPoin, setSelectedPoin] = useState();
  
  const navigate = useNavigate();

  useEffect(() => {
   
    pointGet()
      .then((response) => {
        setPoint(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
    setRefreshApi(false);
  }, [refreshApi]);

  const onFinishedCreate = async () => {
    setRefreshApi(true);
  };

  const onDelete = async () => {
    await pointDelete(point[selectedPoin].id).then((response) => {
      setRefreshApi(true);
    });
  };

  const [editPoinId, setEditPoinId] = useState();
  const [editMinPrice, setEditMinPrice] = useState();
  const [editMaxPrice, setEditMaxPrice] = useState();
  const [editPoint, setEditPoint] = useState();
  const [editIsUnlimited, setEditIsUnlimited] = useState(false);

  const handleOnChange = () => {
    setEditIsUnlimited(!editIsUnlimited);
  };
  const onPoin = (index) =>{
    const poin = point[index];
    setEditPoinId(poin.id);
    setEditMinPrice(poin.minPrice);
    setEditMaxPrice(poin.maxPrice);
    setEditPoint(poin.point);
    setEditIsUnlimited(poin.isUnlimited)

    // console.log(poin)
  }
  const onUpdatePoin = async () => {
    const form = {
      minPrice : editMinPrice,
      maxPrice : editMaxPrice,
      point : editPoint,
      isUnlimited : editIsUnlimited,
    };
    console.log(form);
    await pointUpdate(editPoinId, form).then((response) => {
      // setEditProductId("");
      // setEditProductName("");
      // setEditProductCode("");
      // setEditProductPrice(0);
      // setEditProductImage();
      setRefreshApi(true);
      setShowEditRule(false);
    });
  };

  const signOut = () => {
    logout();
    navigate("/login");
  };

  return (
    <>
      <div className="bg-gradient-to-r from-[#54DAA1] to-[#2DCE89]">
        <div className="relative">
          <aside
            className={
              navSideBar === true
                ? "w-24 h-screen p-5 fixed top-0 left-0 right-0 z-10"
                : "w-3/12 h-screen p-5 fixed top-0 left-0 right-0 z-10"
            }
            aria-label="Sidebar"
          >
            <button
              className="absolute top-72 right-1 w-10 h-10 bg-gradient-to-r from-[#54DAA1] to-[#2DCE89] rounded-full shadow-lg flex justify-center items-center"
              onClick={() => setNavSideBar(!navSideBar)}
            >
              {navSideBar ? (
                <svg
                  width="9"
                  height="16"
                  viewBox="0 0 9 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 14.3333L7.66667 7.66668L1 1.00001"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              ) : (
                <svg
                  width="10"
                  height="16"
                  viewBox="0 0 10 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.33325 14.6667L1.66659 7.99999L8.33325 1.33332"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              )}
            </button>
            <Sidebar navSideBar={navSideBar} />
          </aside>
        </div>
        <div
          className={
            navSideBar === true
              ? "ml-24 h-72 w-11/12 p-5 space-y-5"
              : "ml-auto h-72 w-9/12 p-5 space-y-5"
          }
        >
          {/* Header */}
          <div className="flex flex-row justify-between items-center">
            <div className="text-white space-y-2">
              <p className="text-sm">Pages / Point</p>
              <p className="font-semibold">Point</p>
            </div>
            <div className="flex flex-row items-center gap-5">
              <a href="#" className="flex items-center gap-2">
                <svg
                  width="21"
                  height="20"
                  viewBox="0 0 21 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.4938 2.52235C12.7336 1.70165 11.6719 1.24969 10.5 1.24969C9.32191 1.24969 8.25668 1.69891 7.50004 2.51454C6.73519 3.33915 6.36254 4.45985 6.45004 5.67001C6.62348 8.05751 8.44027 9.99969 10.5 9.99969C12.5598 9.99969 14.3735 8.0579 14.5496 5.67079C14.6383 4.47157 14.2633 3.35321 13.4938 2.52235Z"
                    fill="white"
                  />
                  <path
                    d="M17.3752 18.7488H3.62522C3.44525 18.7511 3.26701 18.7133 3.10349 18.6381C2.93997 18.5629 2.79526 18.4522 2.67991 18.314C2.426 18.0105 2.32366 17.596 2.39944 17.1769C2.72913 15.348 3.75804 13.8117 5.37522 12.7332C6.81194 11.7757 8.63186 11.2488 10.5002 11.2488C12.3686 11.2488 14.1885 11.7761 15.6252 12.7332C17.2424 13.8113 18.2713 15.3476 18.601 17.1765C18.6768 17.5957 18.5744 18.0101 18.3205 18.3136C18.2052 18.4519 18.0605 18.5626 17.897 18.6379C17.7335 18.7132 17.5552 18.7511 17.3752 18.7488Z"
                    fill="white"
                  />
                </svg>
                <p className="text-white">{localStorage.getItem("nameUser")}</p>
              </a>
              <button onClick={signOut} className="bg-white p-3 rounded-lg">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.5625 2.8125H2.1875V17.8125H6.5625M13.4375 5.9375L17.8125 10.3125L13.4375 14.6875M7.1875 10.3125H17.8125"
                    stroke="#EF4444"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            </div>
          </div>

        
          {/* start: Main */}
          <div className="pb-5">
            <div className="bg-white shadow-lg rounded-lg p-6">
              <div className="flex flex-col md:flex-row justify-between items-start md:items-center gap-3">
                <div className="flex flex-col">
                    <h1 className="text-lg font-semibold">Point Setting</h1>
                    <h1 className="text-base text-gray-400 py-2">You can set the points to be awarded to customer here.</h1>

                </div>
                <AddPoint onFinishCreate={onFinishedCreate}/>
              </div>
              <div className="mt-5">
                <div className="overflow-x-auto">
                  <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-400 uppercase dark:text-gray-400 border-b">
                      <tr>
                        <th scope="col" className="py-3 px-6">
                            RULE
                        </th>
                        <th scope="col" className="py-3 px-20">
                            POINT
                        </th>
                       
                        <th scope="col" className="py-3 px-6">
                          
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {point != null ? (
                        point.map((poin, index) => {
                          return (
                            <>
                              <tr className="bg-white border-b" >
                              
                              
                                  <td className="py-4 px-6">
                                      {poin.maxPrice != null
                                        ? 
                                        <h1 className="text-sm font-semibold text-black">{parseInt(poin.minPrice).toLocaleString()} - {parseInt(poin.maxPrice).toLocaleString()}</h1>
                                        : 
                                        <h1 className="text-sm font-semibold text-black">{parseInt(poin.minPrice).toLocaleString()}</h1>
                                      }
                                  </td>
                                  <td className="py-4 px-20">
                                      <h1 className="text-sm font-semibold text-black">{parseInt(poin.point).toLocaleString()} Points</h1>
                                  </td>
                                  <td className="py-4 px-6">
                                    <div className="flex flex-row justify-end gap-3">
                                      <button
                                        className="px-6 py-2 flex gap-2 rounded-lg text-transparent bg-clip-text bg-gradient-to-r from-[#2DCECC] to-[#2DCE89]"
                                        onClick={() => {
                                          onPoin(index);
                                          setShowEditRule(true);
                                        }}
                                      >
                                        
                                        
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path d="M1 3H17V5H1V3ZM1 9H12V11H1V9ZM1 15H8V17H1V15ZM18.8 11.9L19.8 10.8C19.8984 10.69 19.9528 10.5476 19.9528 10.4C19.9528 10.2524 19.8984 10.11 19.8 10L18 8.2C17.89 8.10161 17.7476 8.04721 17.6 8.04721C17.4524 8.04721 17.31 8.10161 17.2 8.2L16.2 9.2L18.8 11.9ZM15.5 9.9L10 15.3V18H12.6L18.2 12.5L15.5 9.8V9.9Z" fill="url(#paint0_linear_4989_12708)"/>
                                          <defs>
                                          <linearGradient id="paint0_linear_4989_12708" x1="19.9528" y1="3" x2="1.15949" y2="18.1972" gradientUnits="userSpaceOnUse">
                                          <stop stop-color="#2DCECC"/>
                                          <stop offset="0.661458" stop-color="#2DCE89"/>
                                          </linearGradient>
                                          </defs>
                                          </svg>
                                          
                                        Edit
                                      </button>
                                      <a
                                        href="#"
                                        className="px-6 py-2 flex gap-2 rounded-lg text-red-500 "
                                        onClick={() => {
                                          setSelectedPoin(index);
                                          // console.log(index.id);
                                          setShowDelete(true);
                                        }}
                                      >
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path d="M5.83203 17.5001C5.3737 17.5001 4.98148 17.3371 4.65536 17.011C4.3287 16.6843 4.16536 16.2918 4.16536 15.8335V5.00012C3.92925 5.00012 3.7312 4.9204 3.5712 4.76096C3.41175 4.60096 3.33203 4.4029 3.33203 4.16679C3.33203 3.93068 3.41175 3.73262 3.5712 3.57262C3.7312 3.41318 3.92925 3.33346 4.16536 3.33346H7.4987C7.4987 3.09734 7.5787 2.89929 7.7387 2.73929C7.89814 2.57984 8.09592 2.50012 8.33203 2.50012H11.6654C11.9015 2.50012 12.0995 2.57984 12.2595 2.73929C12.419 2.89929 12.4987 3.09734 12.4987 3.33346H15.832C16.0681 3.33346 16.2659 3.41318 16.4254 3.57262C16.5854 3.73262 16.6654 3.93068 16.6654 4.16679C16.6654 4.4029 16.5854 4.60096 16.4254 4.76096C16.2659 4.9204 16.0681 5.00012 15.832 5.00012V15.8335C15.832 16.2918 15.669 16.6843 15.3429 17.011C15.0162 17.3371 14.6237 17.5001 14.1654 17.5001H5.83203ZM7.4987 13.3335C7.4987 13.5696 7.5787 13.7673 7.7387 13.9268C7.89814 14.0868 8.09592 14.1668 8.33203 14.1668C8.56814 14.1668 8.7662 14.0868 8.9262 13.9268C9.08564 13.7673 9.16536 13.5696 9.16536 13.3335V7.50012C9.16536 7.26401 9.08564 7.06596 8.9262 6.90595C8.7662 6.74651 8.56814 6.66679 8.33203 6.66679C8.09592 6.66679 7.89814 6.74651 7.7387 6.90595C7.5787 7.06596 7.4987 7.26401 7.4987 7.50012V13.3335ZM10.832 13.3335C10.832 13.5696 10.912 13.7673 11.072 13.9268C11.2315 14.0868 11.4293 14.1668 11.6654 14.1668C11.9015 14.1668 12.0995 14.0868 12.2595 13.9268C12.419 13.7673 12.4987 13.5696 12.4987 13.3335V7.50012C12.4987 7.26401 12.419 7.06596 12.2595 6.90595C12.0995 6.74651 11.9015 6.66679 11.6654 6.66679C11.4293 6.66679 11.2315 6.74651 11.072 6.90595C10.912 7.06596 10.832 7.26401 10.832 7.50012V13.3335Z" fill="#DC2626"/>
                                          </svg>
                                      Delete
    
                                      </a>
                                    </div>
                                  </td>
                              </tr>
                            </>
  
                            );

                          })
                        ) : (
                          <tr className="bg-white">
                            <td
                              colSpan={5}
                              className="bg-gray-50 py-10 text-center"
                            >
                              Data Not Found
                            </td>
                          </tr>
                        )}

                      
                   
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          {/* end: Main */}
        </div>
        {/* Modal */}
        {showEditRule ? (
          <>
          <div className="bg-black bg-opacity-75 overflow-y-auto overflow-x-hidden fixed flex justify-center top-0 left-0 right-0 bottom-0 z-50 w-full md:inset-0 h-modal md:h-full">
            {/* <div className="relative w-full max-w-2xl h-full md:h-auto"> */}
            {/* ini yang ditambahin */}
            <div className="relative fixed w-full max-w-2xl h-full md:h-auto my-5">
              {/* ini yang ditambahin */}
              <div className="relative bg-[#F5F8FA] rounded-lg shadow dark:bg-gray-700">
                <div className="flex justify-between items-start p-6 dark:border-gray-600">
                  <h3 className="text-xl font-semibold px-4">Edit Rule</h3>
                 
                </div>
                {/* {msg != "" ? (
                  <div
                    className="p-3 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800 my-3"
                    role="alert"
                  >
                    <ul class="list-disc list-inside">
                      {msg.map((val) => (
                        <li>{val} Can't be empty</li>
                      ))}
                    </ul>
                  </div>
                ) : null} */}
                <div className="p-6 space-y-6 items-center">
                  
                <div className="lg:px-10 px-5">
                  <label className="block mb-2 text-sm font-medium text-gray-900">
                  Rule
                  </label>
                  <div className="grid lg:grid-cols-12 grid-cols-8 lg:gap-5 gap-2">
                    <div className="lg:col-span-4 col-span-3">
                      <div className="">
                      <CurrencyFormat
                          type="text"
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-green-500 focus:border-green-500 block w-full"
                          placeholder="Min. order"
                          thousandSeparator={true}
                          value={editMinPrice}
                          onValueChange={(values) => {
                            const { formattedValue, value } = values;
                            setEditMinPrice(value);
                          }}
                        
                        />
                      </div>
                    </div>
                    <div className="lg:col-span-1 col-span-2 text-center">
                      <div className="py-2">
                        <label className="block mb-2 text-sm font-medium text-gray-900">
                         -
                        </label>
                       
                      </div>
                    </div>
                    <div className="lg:col-span-4 col-span-3">
                      <div className="">
                       
                        <CurrencyFormat
                          type="text"
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full"
                          placeholder="Max. order"
                          thousandSeparator={true}
                          value={editMaxPrice ?? 0}
                          onValueChange={(values) => {
                            const { formattedValue, value } = values;
                            setEditMaxPrice(value);
                          }}
                        
                        />
                      </div>
                    </div>
                    <div className="col-span-3">
                      <div className="py-2">
                        <div className="flex items-center mb-4">
                            <input id="default-checkbox" type="checkbox" 
                            // value={editIsUnlimited}
                            checked={editIsUnlimited}
                            onChange={handleOnChange} 
                            className="w-4 h-4 text-green-600 bg-gray-100 border-gray-300 rounded focus:ring-green-500 dark:focus:ring-green-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                            <label for="default-checkbox" className="ml-2 text-sm font-medium text-green-500 dark:text-gray-300">Unlimited</label>
                        </div>
                        
                      </div>
                    </div>


                  </div>

                </div>
                <div className="lg:px-10 px-5">
                  <div className="">
                      <label className="block mb-2 text-sm font-medium text-gray-900">
                      Points
                      </label>
                       
                       <CurrencyFormat
                         type="text"
                         className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full"
                         placeholder=""
                         thousandSeparator={true}
                         value={editPoint}
                         onValueChange={(values) => {
                           const { formattedValue, value } = values;
                           setEditPoint(value);
                         }}
                       
                       />
                     </div>

                </div>
                
                  <div className="mt-10 flex flex-row justify-end gap-3">
                    <button
                      className="py-2 px-6 text-sm rounded-xl bg-gray-100 hover:bg-gray-200 text-gray-400 hover:text-gray-600"
                      onClick={() => setShowEditRule(false)}
                    >
                      Cancel
                    </button>
                    <button
                      onClick={onUpdatePoin}
                      className="py-2 px-6 text-sm rounded-xl bg-green-500 hover:bg-green-600 text-white"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
        ) : null}
        {showDelete ? (
          <>
            <div className="bg-black bg-opacity-75 overflow-y-auto overflow-x-hidden fixed flex justify-center top-0 left-0 right-0 bottom-0 z-50 w-full md:inset-0 md:h-full">
              {/* <div className="relative w-full max-w-2xl h-full md:h-auto"> */}
              {/* ini yang ditambahin */}
              <div className="fixed w-full max-w-xl md:h-auto mt-40">
                {/* ini yang ditambahin */}
                <div className="relative bg-[#F5F8FA] rounded-lg shadow dark:bg-gray-700">
                  <div className="p-6 space-y-6 items-center">
                    <div className="flex flex-row justify-between items-start gap-5">
                      <div className="flex flex-row gap-5">
                        <img src={Delete} className="w-100 object-cover" />
                        <div className="flex flex-col">
                          <h1 className="font-bold">Delete Rule?</h1>
                          <p className="font-semibold text-gray-400">
                            Data will be permanently deleted
                          </p>
                          <div className="mt-10 flex flex-row justify-start gap-3">
                            <button
                              onClick={() => {
                                onDelete();
                                setShowDelete(false);
                              }}
                              className="text-sm py-2 px-5 rounded-xl text-white bg-green-400 hover:bg-green-500 font-semibold"
                            >
                              Yes
                            </button>
                            <button
                              className="text-sm px-3 rounded-xl text-gray-400 hover:text-gray-600 bg-gray-100 hover:bg-gray-200 font-semibold"
                              onClick={() => setShowDelete(false)}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                      <button
                        type="button"
                        className="bg-transparent hover:bg-gray-100 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                        onClick={() => setShowDelete(false)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="#A1A5B7"
                          className="bi bi-x-circle"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                          <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : null}
       
      </div>
    </>
  );
}

export default Point;
