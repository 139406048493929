export default [
    {
      id: 1,
      broadcast: "Broadcast FU H+1",
      customer: "140 Customer",
      template: "FU Blast Resi",
      csCRM: "Ashlynn Westervelt",
      date: "17/06/2022 15:30",
      view: "/View-Broadcast",
      edit: "/edit-broadcast",
      success: "100",
      onProgress: "30",
      unsent: "10"
    },
    {
      id: 2,
      broadcast: "Broadcast FU H+1",
      customer: "140 Customer",
      template: "FU Blast Resi",
      csCRM: "Ashlynn Westervelt",
      date: "17/06/2022 15:30",
      view: "/View-Broadcast",
      edit: "/edit-broadcast",
      success: "100",
      onProgress: "30",
      unsent: "10"
    },
    {
      id: 3,
      broadcast: "Broadcast FU H+1",
      customer: "140 Customer",
      template: "FU Blast Resi",
      csCRM: "Ashlynn Westervelt",
      date: "17/06/2022 15:30",
      view: "/View-Broadcast",
      edit: "/edit-broadcast",
      success: "100",
      onProgress: "30",
      unsent: "10"
    },
    {
      id: 4,
      broadcast: "Broadcast FU H+1",
      customer: "140 Customer",
      template: "FU Blast Resi",
      csCRM: "Ashlynn Westervelt",
      date: "17/06/2022 15:30",
      view: "/View-Broadcast",
      edit: "/edit-broadcast",
      success: "100",
      onProgress: "30",
      unsent: "10"
    },
    {
      id: 5,
      broadcast: "Broadcast FU H+1",
      customer: "140 Customer",
      template: "FU Blast Resi",
      csCRM: "Ashlynn Westervelt",
      date: "17/06/2022 15:30",
      view: "/View-Broadcast",
      edit: "/edit-broadcast",
      success: "100",
      onProgress: "30",
      unsent: "10"
    },
    {
      id: 6,
      broadcast: "Broadcast FU H+1",
      customer: "140 Customer",
      template: "FU Blast Resi",
      csCRM: "Ashlynn Westervelt",
      date: "17/06/2022 15:30",
      view: "/View-Broadcast",
      edit: "/edit-broadcast",
      success: "100",
      onProgress: "30",
      unsent: "10"
    },
    {
      id: 7,
      broadcast: "Broadcast FU H+1",
      customer: "140 Customer",
      template: "FU Blast Resi",
      csCRM: "Ashlynn Westervelt",
      date: "17/06/2022 15:30",
      view: "/View-Broadcast",
      edit: "/edit-broadcast",
      success: "100",
      onProgress: "30",
      unsent: "10"
    },
    {
      id: 8,
      broadcast: "Broadcast FU H+1",
      customer: "140 Customer",
      template: "FU Blast Resi",
      csCRM: "Ashlynn Westervelt",
      date: "17/06/2022 15:30",
      view: "/View-Broadcast",
      edit: "/edit-broadcast",
      success: "100",
      onProgress: "30",
      unsent: "10"
    },
    {
      id: 9,
      broadcast: "Broadcast FU H+1",
      customer: "140 Customer",
      template: "FU Blast Resi",
      csCRM: "Ashlynn Westervelt",
      date: "17/06/2022 15:30",
      view: "/View-Broadcast",
      edit: "/edit-broadcast",
      success: "100",
      onProgress: "30",
      unsent: "10"
    },
    {
      id: 10,
      broadcast: "Broadcast FU H+1",
      customer: "140 Customer",
      template: "FU Blast Resi",
      csCRM: "Ashlynn Westervelt",
      date: "17/06/2022 15:30",
      view: "/View-Broadcast",
      edit: "/edit-broadcast",
      success: "100",
      onProgress: "30",
      unsent: "10"
    },
    {
      id: 11,
      broadcast: "Broadcast FU H+1",
      customer: "140 Customer",
      template: "FU Blast Resi",
      csCRM: "Ashlynn Westervelt",
      date: "17/06/2022 15:30",
      view: "/View-Broadcast",
      edit: "/edit-broadcast",
      success: "100",
      onProgress: "30",
      unsent: "10"
    },
    {
      id: 12,
      broadcast: "Broadcast FU H+1",
      customer: "140 Customer",
      template: "FU Blast Resi",
      csCRM: "Ashlynn Westervelt",
      date: "17/06/2022 15:30",
      view: "/View-Broadcast",
      edit: "/edit-broadcast",
      success: "100",
      onProgress: "30",
      unsent: "10"
    },
  ];