import api from "../apiClient";

export function getBroadcast() {
  return api.axiosClient.get("/broadcast/limit=250&page=1");
}
export function viewBroadcast(id,filter,page,limit) {
  return api.axiosClient.get(`broadcast/viewBroadcast/${id}?${filter}${page}${limit}`);
}
// broadcast/viewBroadcast/b7902e4e-dc38-474f-a5f9-275a5f30b500?filter=pending&page=1&limit=10

export function getBroadcastById(id, page,limit) {
  return api.axiosClient.get(`/broadcast/${id}&limit=${limit}&page=${page}`);
}

export function getStatisticBroadcast(id) {
  return api.axiosClient.get(`/boradcast/getStatistic/${id}`);
}

export function createBroadcast(data){
  return api.axiosClient.post('/broadcast/create', data)
}
export function updateBroadcast(id,data){
  return api.axiosClient.put(`/broadcast/update/${id}`, data)
}
export function deleteBroadcast(id){
  return api.axiosClient.delete(`/broadcast/delete/${id}`)
}
