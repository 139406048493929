import React, { useEffect, useState } from "react";
import { getLeveling } from "../../../Network/Lib/leveling";
import { getAllProduct } from "../../../Network/Lib/product";
import {
  getAllCity,
  getAllProvince,
  getAllSubdis,
} from "../../../Network/Lib/rajaOngkir";
import { getWarehouse } from "../../../Network/Lib/warehouse";
// import RepeaterLeadSource from "../Input/RepeaterLeadSource";

function FilterDataCustomer({ onFilterCustomer }) {
  const [showInput, setShowInput] = useState(false);
  const [refreshAPI, setRefreshApi] = useState(false);

  const [genderFilter, setGenderFilter] = useState();
  const [maritalFilter, setMaritalFilter] = useState();

  const [provinceList, setProvinceList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [subdistrictList, setSubdistrictList] = useState([]);

  const [selectedProvince, setSelectedProvince] = useState();
  const [selectedCity, setSelectedCity] = useState();
  const [selectedSubdis, setSelectedSubdis] = useState();

  const [productFilter, setProductFilter] = useState();

  const [crmProducts, setCrmProducts] = useState([]);

  const [dataLeveling, setDataLeveling] = useState([]);
  const [selectedLeveling, setSelectedLeveling] = useState();

  const [dataWarehouse, setDataWarehouse] = useState([]);
  const [selectedWarehouse, setSelectedWarehouse] = useState();

  useEffect(() => {
    getAllProvince().then((response) => {
      setProvinceList(response.data.data);
    });
    getAllProduct().then((response) => {
      // console.log(response.data.data)
      setCrmProducts(response.data.data);
    });
    getLeveling().then((response) => {
      // console.log(response.data.data);
      setDataLeveling(response.data.data);
    });
    getWarehouse().then((response) => {
      // console.log(response.data.data);
      setDataWarehouse(response.data.data);
    });
    setRefreshApi(false);
  }, [refreshAPI]);

  const onSubmitFilter = () => {
    let params = {};
    if (genderFilter) {
      params.gender = genderFilter;
    }
    if (maritalFilter) {
      params.marital_status = maritalFilter;
    }
    if (selectedProvince) {
      params.province_id = selectedProvince;
    }
    if (selectedCity) {
      params.city_id = selectedCity;
    }
    if (selectedSubdis) {
      params.subdistrict_id = selectedSubdis;
    }
    if (productFilter) {
      params.product_id = productFilter;
    }
    if (selectedLeveling) {
      params.leveling_id = selectedLeveling;
    }
    if (selectedWarehouse) {
      params.warehouse_id = selectedWarehouse;
    }

    onFilterCustomer(params);
    setShowInput(false);
  };

  const onClearFilter = () => {
    setGenderFilter(undefined);
    setMaritalFilter(undefined);
    // selectedLeveling(undefined);
    // selectedWarehouse(undefined);
    onFilterCustomer({});
    setShowInput(false);
  };

  const onSelectProvince = async (e) => {
    setSelectedProvince(e.target.value);
    await getAllCity(e.target.value)
      .then((response) => {
        setCityList(response.data.data);
      })
      .catch((e) => {});
  };

  const onSelectCity = async (e) => {
    setSelectedCity(e.target.value);
    await getAllSubdis(e.target.value)
      .then((response) => {
        setSubdistrictList(response.data.data);
      })
      .catch((e) => {});
  };

  const onSelectSubdis = async (e) => {
    setSelectedSubdis(e.target.value);
  };

  return (
    <>
      <button
        type="button"
        className="bg-white border border-[#22C55E] hover:bg-gray-100 text-[#22C55E] rounded-lg px-3 py-2 font-medium flex flex-row gap-3 flex items-center"
        onClick={() => setShowInput(true)}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth="2"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z"
          />
        </svg>

        <span>Filter Data</span>
      </button>
      {showInput ? (
        <>
          <div className="bg-black bg-opacity-75 overflow-y-auto overflow-x-hidden fixed flex justify-center top-0 left-0 right-0 bottom-0 z-50 w-full md:inset-0 h-modal md:h-full">
            {/* <div className="relative w-full max-w-2xl h-full md:h-auto"> */}
            {/* ini yang ditambahin */}
            <div className="relative w-full max-w-6xl h-full md:h-auto mt-10">
              {/* ini yang ditambahin */}
              <div className="relative bg-[#F5F8FA] rounded-lg shadow dark:bg-gray-700">
                <div className="flex justify-between items-start p-4 rounded-t border-b dark:border-gray-600">
                  <div className="flex flex-col gap-1">
                    <h3 className="text-xl font-semibold dark:text-white">
                      Filter Data Customer
                    </h3>
                    <p className="text-sm font-medium text-gray-400">
                      Customize your filtering
                    </p>
                  </div>
                  <div className="flex flex-col justify-between space-y-5">
                    <button
                      type="button"
                      className="bg-transparent hover:bg-gray-100 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                      onClick={() => setShowInput(false)}
                    >
                      <svg
                        className="w-5 h-5"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                    </button>
                    <button
                      className="text-xs text-rose-600 hover:text-rose-800 font-semibold"
                      onClick={() => onClearFilter()}
                    >
                      Clear Filter
                    </button>
                  </div>
                </div>
                <div className="p-6 space-y-6 items-center">
                  <div className="flex flex-row justify-center flex-wrap gap-5">
                    <div className="relative w-48">
                      <select
                        className="block appearance-none w-full bg-white border border-white text-gray-700 text-sm py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-white"
                        onChange={(e) => setGenderFilter(e.target.value)}
                        value={genderFilter}
                      >
                        <option hidden>Gender</option>
                        <option value="Female">Female</option>
                        <option value="Male">Male</option>
                      </select>
                      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"></div>
                    </div>
                    <div className="relative w-48">
                      <select
                        className="block appearance-none w-full bg-white border border-white text-gray-700 text-sm py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-white"
                        onChange={(e) => setMaritalFilter(e.target.value)}
                        value={maritalFilter}
                      >
                        <option hidden>Martial Status</option>
                        <option value="Single">Single</option>
                        <option value="Married">Married</option>
                        <option value="Divorce">Divorce</option>
                      </select>
                      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"></div>
                    </div>
                    <div className="relative w-48">
                      <select
                        className="block appearance-none w-full bg-white border border-white text-gray-700 text-sm py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-white"
                        onChange={onSelectProvince}
                        value={selectedProvince}
                      >
                        <option hidden>Province</option>
                        {provinceList &&
                          provinceList.map(function (value, index) {
                            return (
                              <>
                                <option key={index} value={value.province_id}>
                                  Provinsi {value.province}
                                </option>
                              </>
                            );
                          })}
                      </select>
                      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"></div>
                    </div>
                    <div className="relative w-48">
                      <select
                        className="block appearance-none w-full bg-white border border-white text-gray-700 text-sm py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-white"
                        onChange={onSelectCity}
                        value={selectedCity}
                      >
                        <option hidden>City</option>
                        {cityList &&
                          cityList.map(function (value, index) {
                            return (
                              <>
                                <option key={index} value={value.city_id}>
                                  {value.type} {value.city_name}
                                </option>
                              </>
                            );
                          })}
                      </select>
                      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"></div>
                    </div>
                    <div className="relative w-48">
                      <select
                        className="block appearance-none w-full bg-white border border-white text-gray-700 text-sm py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-white"
                        onChange={onSelectSubdis}
                        value={selectedSubdis}
                      >
                        <option hidden>Subdistrict</option>
                        {subdistrictList &&
                          subdistrictList.map(function (value, index) {
                            return (
                              <>
                                <option
                                  key={index}
                                  value={value.subdistrict_id}
                                >
                                  {value.subdistrict_name}
                                </option>
                              </>
                            );
                          })}
                      </select>
                      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"></div>
                    </div>
                    <div className="relative w-48">
                      <select
                        className="block appearance-none w-full bg-white border border-white text-gray-700 text-sm py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-white"
                        onChange={(e) => setSelectedLeveling(e.target.value)}
                        value={selectedLeveling}
                      >
                        <option hidden>Level</option>
                        {dataLeveling &&
                          dataLeveling.map(function (val, index) {
                            return (
                              <>
                                <option key={index} value={val.id}>
                                  {val.levelingName}
                                </option>
                              </>
                            );
                          })}
                      </select>
                      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"></div>
                    </div>
                    <div className="relative w-48">
                      <select className="block appearance-none w-full bg-white border border-white text-gray-700 text-sm py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-white">
                        <option hidden>Payment Method</option>
                        <option>1</option>
                        <option>2</option>
                      </select>
                      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"></div>
                    </div>
                    <div className="relative w-48">
                      <select
                        className="block appearance-none w-full bg-white border border-white text-gray-700 text-sm py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-white"
                        onChange={(e) => setSelectedWarehouse(e.target.value)}
                        value={selectedWarehouse}
                      >
                        <option hidden>Warehouse</option>
                        {dataWarehouse &&
                          dataWarehouse.map(function (val, index) {
                            return (
                              <>
                                <option key={index} value={val.id}>
                                  {val.warehouseName}
                                </option>
                              </>
                            );
                          })}
                      </select>
                      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"></div>
                    </div>
                    <div className="relative w-48">
                      <select className="block appearance-none w-full bg-white border border-white text-gray-700 text-sm py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-white">
                        <option hidden>CS Acquisition Name</option>
                        <option>1</option>
                        <option>2</option>
                      </select>
                      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"></div>
                    </div>
                    <div className="relative w-48">
                      <select className="block appearance-none w-full bg-white border border-white text-gray-700 text-sm py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-white">
                        <option hidden>Campaign</option>
                        <option>1</option>
                        <option>2</option>
                      </select>
                      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"></div>
                    </div>
                    <div className="relative w-48">
                      <select className="block appearance-none w-full bg-white border border-white text-gray-700 text-sm py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-white">
                        <option hidden>Promo</option>
                        <option>1</option>
                        <option>2</option>
                      </select>
                      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"></div>
                    </div>
                    <div className="relative w-48">
                      <select
                        className="block appearance-none w-full bg-white border border-white text-gray-700 text-sm py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-white"
                        onChange={(e) => setProductFilter(e.target.value)}
                        value={productFilter}
                      >
                        <option hidden>Product</option>
                        {crmProducts &&
                          crmProducts.map(function (product, index) {
                            return (
                              <>
                                <option key={index} value={product.id}>
                                  {product.name}
                                </option>
                              </>
                            );
                          })}
                      </select>
                      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"></div>
                    </div>
                    <div className="relative w-48">
                      <input
                        type="date"
                        className="block appearance-none w-full bg-white border border-white text-gray-700 text-sm py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-white"
                      />
                      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"></div>
                    </div>
                    <div className="relative w-48">
                      <select className="block appearance-none w-full bg-white border border-white text-gray-700 text-sm py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-white">
                        <option hidden>Courier</option>
                        <option>1</option>
                        <option>2</option>
                      </select>
                      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"></div>
                    </div>
                  </div>
                  <div className="mt-10 flex flex-row justify-end">
                    <button
                      className="px-3 rounded-xl text-gray-400 hover:text-gray-600 font-semibold"
                      onClick={() => setShowInput(false)}
                    >
                      Cancel
                    </button>
                    <button
                      className="py-2 px-5 rounded-md text-white bg-green-400 hover:bg-green-500 font-semibold ml-2"
                      onClick={() => onSubmitFilter()}
                    >
                      Apply
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
}

export default FilterDataCustomer;
