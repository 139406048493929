import "aos/dist/aos.css";
import React, { useState, useEffect } from "react";
import AddCS from "../../Components/Partials/Modal/AddCS";
import SidebarComunication from "../../Components/Sidebar/SidebarComunication";
import CardTemplate from "../../Components/Partials/Card/CardTemplate";
import arrowLeft from "../../Assets/icon/comunication/arrowLeft.svg";
import { getAllTemplate, getOne, getTemplatePagination } from "../../Network/Lib/template";
import { createBroadcast } from "../../Network/Lib/broadcast";
import { Link, useNavigate } from "react-router-dom";
import { logout } from "../../Network/Lib/auth";
import FilterDataCustomer from "../../Components/Partials/Modal/FilterDataCustomer";
import { getAllCS } from "../../Network/Lib/customer";
import { ImageOutlined, OpenInNew } from "@mui/icons-material";
import Select from "react-select";
import { getAllProduct } from "../../Network/Lib/product";

function NestedDropdown({ hasil, jumlah, change }) {
  const [dropdown, setDropdown] = useState(false);
  const [valueDropdown, setValueDropdown] = useState()
  const [nested, setNested] = useState(false)

  const handleChange = (e) => {
    setDropdown(false);
    setValueDropdown("1")
  }
  return (
    <div>
      <button
        onClick={() => setDropdown(!dropdown)}
        className="text-gray-800 bg-white border-2 hover:bg-gray-50 apperace-none font-medium rounded-lg text-sm px-4 py-2.5 w-40" type="button">
        <div className='flex items-center justify-between'>
          <h1>{valueDropdown ? valueDropdown : "select"}</h1>
          <svg className="w-4 h-4 ml-2" aria-hidden="true" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path></svg>

        </div>
      </button>

      {dropdown ? (
        <div className="z-10 absolute w-48 bg-white divide-y divide-gray-100 rounded-lg shadow ">
          <ul className="text-sm text-gray-700 " aria-labelledby="dropdownRadioButton">
            <li onClick={() => { setDropdown(false); setValueDropdown("customer.name") }} className="flex items-center w-full px-3 py-2 rounded hover:bg-gray-50">
              <label htmlFor="default-radio-1" className="w-full ml-2 cursor-pointer text-sm font-medium text-gray-900">Customer Name {jumlah}</label>
            </li>
            <li onClick={() => { setDropdown(false); setValueDropdown("customer.phone") }} className="flex items-center w-full px-3 py-2 rounded hover:bg-gray-50">
              <label htmlFor="default-radio-2" className="w-full ml-2 cursor-pointer text-sm font-medium text-gray-900">Customer Phone {jumlah}</label>
            </li>
            <li onClick={() => { setDropdown(false); setValueDropdown("customer.point") }} className="flex items-center w-full px-3 py-2 rounded hover:bg-gray-50">
              <label htmlFor="default-radio-2" className="w-full ml-2 cursor-pointer text-sm font-medium text-gray-900">Customer Point {jumlah}</label>
            </li>
            <li onClick={() => { setNested(!nested) }} className="relative flex items-center w-full px-3 py-2 rounded hover:bg-gray-50">
              <label htmlFor="default-radio-3" className="w-full ml-2 cursor-pointer text-sm font-medium text-gray-900">nested {jumlah}</label>
              {nested ?
                <div className="absolute -right-20 bg-gray-100 p-5 rounded-lg ">
                  <p onClick={() => { setDropdown(false); setValueDropdown("nested 1"); hasil(1); change() }}>hehe</p>
                  <p onClick={() => { setDropdown(false); setValueDropdown("nested 2"); hasil(2); change() }}>hehe</p>
                  <p onClick={() => { setDropdown(false); setValueDropdown("nested 3"); hasil(3); change() }}>hehe</p>
                </div> : null}
            </li>
            <li onClick={() => { setDropdown(false); setValueDropdown("4") }} className="flex items-center w-full px-3 py-2 rounded hover:bg-gray-50">
              <label htmlFor="default-radio-3" className="w-full ml-2 cursor-pointer text-sm font-medium text-gray-900">Rating {jumlah}</label>
            </li>
            <li onClick={() => { setDropdown(false); setValueDropdown("5") }} className="flex items-center w-full px-3 py-2 rounded hover:bg-gray-50">
              <label htmlFor="default-radio-3" className="w-full ml-2 cursor-pointer text-sm font-medium text-gray-900">Rating {jumlah}</label>
            </li>
          </ul>
        </div>
      ) : (null)}

    </div>
  )
}

function CreatedBroadcast() {
  const navigate = useNavigate();
  const [navSideBar, setNavSideBar] = useState(false);
  const [showInput, setShowInput] = useState(false);
  const [name, setName] = useState("");
  const [templateList, setTemplateList] = useState("");
  const [message, setMessage] = useState("");
  const [textMessage, setTextMessage] = useState("");
  const [templateName, setTemplateName] = useState("");
  const [time, setTime] = useState("");
  const [date, setDate] = useState("");
  // const [phone, setPhone] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState();
  const [filter, setFilter] = useState([]);
  const [resFilter, setResFilter] = useState();
  const [filteredCustomer, setFilteredCustomer] = useState([]);
  const [msg, setMsg] = useState("");

  const [product, setProduct] = useState([])

  const onSelectTemplate = async (e) => {
    if (e.target.value != "create") {
      setSelectedTemplate(e.target.value);
      const id = e.target.value;
      await getOne(id)
        .then((response) => {
          console.log(response.data.data);
          setTemplateName(response.data.data.name);
          setTextMessage(JSON.parse(response.data.data.content.replace(/\\/g, "")).text);
          setMessage(response.data.data)
        })
        .catch((e) => { });
    } else {
      window.open("/create-template", "_blank");
    }
  };
  console.log("message", message)

  useEffect(() => {
    getAllTemplate().then((response) => {
      console.log("template", response.data.data)
      setTemplateList(response.data.data);
    });
    getAllCS(filter).then((response) => {
      onFilteredCustomer(response.data.data);
      setResFilter(response.data.filter)
      console.log("hasil", response.data.filter)
    });
    getAllProduct()
      .then((response) => {
        setProduct(response.data.data);
        console.log("product", response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [filter]);

  const onFilteredCustomer = (data) => {
    if (data != null) {
      setFilteredCustomer(data);
      // console.log("filter",data)
    } else {
      setFilteredCustomer([]);
    }
    // console.log(data)
  };

  // console.log("hasil Filter ", filteredCustomer);
  // console.log("Filter ", filter)
  var phone = [];
  filteredCustomer&&filteredCustomer.map((val) => phone.push(parseInt(val.phone)));

  console.log(selectedTemplate);

  const postData = async () => {
    const err = [];
    if (name == "") {
      err.push("Name");
    }
    if (name == "") {
      err.push("Phone");
    }
    // if (message == "") {
    //   err.push("Template");
    // }
    if (date == "") {
      err.push("Date");
    }
    if (time == "") {
      err.push("Time");
    }
    if (err.length > 0) {
      setMsg(err);
    } else {
      const form = {
        // userId: "332769c7-f02c-4141-8bc4-84c5c3890403",
        name: name,
        phone: phone,
        templateId: selectedTemplate,
        templateName: templateName,
        templateMessage: textMessage,
        date: date,
        time: time,
        filter: filter,
        variabel: dataVariable
      };
      console.log("form", form);
      try {
        const response = await createBroadcast(form)
          .then((response) => {
            navigate("/broadcast");
          })
          .catch((e) => {
            if (e.response) {
              setMsg(e.response.data.message);
            }
          });
      } catch (error) {
        console.log(error);
      }
    }
  };



  const [iniHasil, setIniHasil] = useState()
  // console.log(iniHasil)
  // variable

  const iniList = []
  const [variable, setVariable] = useState([])
  const data = message ? (JSON.stringify(JSON.parse(message?.content?.replace(/\\/g)).text).match(new RegExp("{{", "g")) || []).length : 0
  const dataVariable = Array(data).fill({ "text": "" })
  for (let index = 1; index < data + 1; index++) {
    // for (let index = 0; index < data; index++) {
    // var hasilIni
    const handleChangeHasil = (i, e) => {
      dataVariable[i] = { "text": e }
      console.log(i, e)
    }
    iniList.push(

      <select select
        className="bg-white border border-white rounded-lg shadow-lg text-[#54DAA1] text-sm focus:ring-transparent focus:border-white block p-2.5"
        onChange={(e) => {
          // setVariable(e.target.value)
          // dataVariable[index - 1].text = (e.target.value)
          dataVariable[index - 1] = { "text": e.target.value }
        }}
      >
        <option hidden>Select Variable ({index})</option>
        <option value="customer.name">
          Customer Name ({index})
        </option>
        <option value="customer.phone">
          Customer Phone ({index})
        </option>
        <option value="customer.poin">
          Customer Poin ({index})
        </option>
        <option disabled>
          -- Product Name --
        </option>
        {product && product.map((val, i) => (
          <option key={i} value={`product.name.${val.id}`}>
            {val.name} ({index})
          </option>
        ))}
        <option disabled>
          -- Product Price --
        </option>
        {product && product.map((val, i) => (
          <option key={i} value={`product.price.${val.id}`}>
            {val.name} {val.price} ({index})
          </option>
        ))}
      </select>
      // <NestedDropdown hasil={setIniHasil} change={handleChangeHasil(index, iniHasil)}>
      //   {/* {dataVariable[index] = { "text": index+1 }} */}
      //   {/* {console.log("hasil",index,iniHasil)} */}
      //   {/* {console.log(iniHasil)} */}
      // </NestedDropdown>
    )
  }
  console.log("variable", dataVariable)
  const signOut = () => {
    logout();
    navigate("/login");
  };


  // group select
  const [state, setState] = useState({ selectedOption: null })
  const handleChange = (selectedOption) => {
    console.log(selectedOption)
    setState({ selectedOption: selectedOption });
  };
  const colourOptions = [
    { value: "ocean", label: "Ocean", color: "#00B8D9" },
    { value: "blue", label: "Blue", color: "#0052CC" },
    { value: "purple", label: "Purple", color: "#5243AA" },
    { value: "red", label: "Red", color: "#FF5630" },
    { value: "orange", label: "Orange", color: "#FF8B00" },
    { value: "yellow", label: "Yellow", color: "#FFC400" },
    { value: "green", label: "Green", color: "#36B37E" },
    { value: "forest", label: "Forest", color: "#00875A" },
    { value: "slate", label: "Slate", color: "#253858" },
    { value: "silver", label: "Silver", color: "#666666" }
  ];

  const flavourOptions = [
    { value: "vanilla", label: "Vanilla", rating: "safe" },
    { value: "chocolate", label: "Chocolate", rating: "good" },
    { value: "strawberry", label: "Strawberry", rating: "wild" }
  ];
  const groupedOptions = [
    {
      label: "Colours",
      options: colourOptions
    },
    {
      label: "Flavours",
      options: flavourOptions
    },
    {
      label: "Name",
      options: [
        { value: "vanilla", label: "Vanilla", rating: "safe" },
      ]
    }
  ];
  const { selectedOption } = state;

  return (
    <>
      <div className="bg-gradient-to-r from-[#54DAA1] to-[#2DCE89]">
        <div className="relative">
          <aside
            className={
              navSideBar === true
                ? "w-24 h-screen p-5 fixed top-0 left-0 right-0 z-10"
                : "w-3/12 h-screen p-5 fixed top-0 left-0 right-0 z-10"
            }
            aria-label="Sidebar"
          >
            <button
              className="absolute bottom-96 right-1 w-10 h-10 bg-gradient-to-r from-[#54DAA1] to-[#2DCE89] rounded-full shadow-lg flex justify-center items-center"
              onClick={() => setNavSideBar(!navSideBar)}
            >
              {navSideBar ? (
                <svg
                  width="9"
                  height="16"
                  viewBox="0 0 9 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 14.3333L7.66667 7.66668L1 1.00001"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              ) : (
                <svg
                  width="10"
                  height="16"
                  viewBox="0 0 10 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.33325 14.6667L1.66659 7.99999L8.33325 1.33332"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              )}
            </button>
            <SidebarComunication navSideBar={navSideBar} />
          </aside>
        </div>
        <div
          className={
            navSideBar === true
              ? "ml-24 h-72 w-11/12 p-5 space-y-5"
              : "ml-auto h-72 w-9/12 p-5 space-y-5"
          }
        >
          {/* Header */}
          <div className="flex flex-row justify-between items-center">
            <div className="text-white space-y-2">
              <div className="flex flex-row space-x-3">
                <p className="text-sm">Pages</p>
                <p className="text-sm">/</p>
                <a href="/communication" className="text-sm">
                  Communication
                </a>
                <p className="text-sm">/</p>
                <a href="/Broadcast" className="text-sm">
                  Broadcast
                </a>
              </div>
              <p className="font-semibold">Broadcast</p>
            </div>
            <div className="flex flex-row items-center gap-5">
              <a href="#" className="flex items-center gap-2">
                <svg
                  width="21"
                  height="20"
                  viewBox="0 0 21 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.4938 2.52235C12.7336 1.70165 11.6719 1.24969 10.5 1.24969C9.32191 1.24969 8.25668 1.69891 7.50004 2.51454C6.73519 3.33915 6.36254 4.45985 6.45004 5.67001C6.62348 8.05751 8.44027 9.99969 10.5 9.99969C12.5598 9.99969 14.3735 8.0579 14.5496 5.67079C14.6383 4.47157 14.2633 3.35321 13.4938 2.52235Z"
                    fill="white"
                  />
                  <path
                    d="M17.3752 18.7488H3.62522C3.44525 18.7511 3.26701 18.7133 3.10349 18.6381C2.93997 18.5629 2.79526 18.4522 2.67991 18.314C2.426 18.0105 2.32366 17.596 2.39944 17.1769C2.72913 15.348 3.75804 13.8117 5.37522 12.7332C6.81194 11.7757 8.63186 11.2488 10.5002 11.2488C12.3686 11.2488 14.1885 11.7761 15.6252 12.7332C17.2424 13.8113 18.2713 15.3476 18.601 17.1765C18.6768 17.5957 18.5744 18.0101 18.3205 18.3136C18.2052 18.4519 18.0605 18.5626 17.897 18.6379C17.7335 18.7132 17.5552 18.7511 17.3752 18.7488Z"
                    fill="white"
                  />
                </svg>
                <p className="text-white">{localStorage.getItem("nameUser")}</p>
              </a>
              <button onClick={signOut} className="bg-white p-3 rounded-lg">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.5625 2.8125H2.1875V17.8125H6.5625M13.4375 5.9375L17.8125 10.3125L13.4375 14.6875M7.1875 10.3125H17.8125"
                    stroke="#EF4444"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            </div>
          </div>
          {/* start: Main */}

          <div className="grid grid-cols-12 gap-5">
            <div className="col-span-12">
              <div className="bg-white shadow-lg h-full rounded-lg p-10">
                <div className="flex flex-row justify-between my-2">
                  <div className="flex items-center justify-center gap-3">
                    <a href="/Broadcast">
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.11261 0.862514L0.862615 7.11251C0.748814 7.23139 0.659608 7.37157 0.600115 7.52501C0.475092 7.82934 0.475092 8.17069 0.600115 8.47501C0.659608 8.62845 0.748814 8.76863 0.862615 8.88751L7.11261 15.1375C7.22916 15.2541 7.36753 15.3465 7.5198 15.4096C7.67208 15.4727 7.83529 15.5051 8.00011 15.5051C8.33299 15.5051 8.65224 15.3729 8.88761 15.1375C9.12299 14.9021 9.25523 14.5829 9.25523 14.25C9.25523 13.9171 9.12299 13.5979 8.88761 13.3625L4.76261 9.25001H14.2501C14.5816 9.25001 14.8996 9.11832 15.134 8.8839C15.3684 8.64948 15.5001 8.33153 15.5001 8.00001C15.5001 7.66849 15.3684 7.35055 15.134 7.11613C14.8996 6.88171 14.5816 6.75001 14.2501 6.75001H4.76261L8.88761 2.63751C9.00478 2.52131 9.09777 2.38306 9.16123 2.23073C9.22469 2.07841 9.25736 1.91503 9.25736 1.75001C9.25736 1.585 9.22469 1.42162 9.16123 1.26929C9.09777 1.11697 9.00478 0.978718 8.88761 0.862514C8.77141 0.745353 8.63316 0.652359 8.48084 0.588898C8.32851 0.525437 8.16513 0.492765 8.00011 0.492765C7.8351 0.492765 7.67172 0.525437 7.51939 0.588898C7.36707 0.652359 7.22882 0.745353 7.11261 0.862514Z"
                          fill="#A1A1AA"
                        />
                      </svg>
                    </a>
                    <h1 className="text-lg font-bold">Broadcast</h1>
                  </div>
                </div>
                <div className="w-full">
                  <div>
                    {/*begin: FORM DATA BROADCAST*/}
                    <div className="py-5">
                      <div className="bg-[#F5F8FA] rounded-lg px-10 py-10">
                        <div className="space-y-5">
                          {msg != "" ? (
                            <div
                              className="p-3 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800 my-3"
                              role="alert"
                            >
                              <ul class="list-disc list-inside">
                                {msg && msg.map((val) => (
                                  <li>{val} Can't be empty</li>
                                ))}
                              </ul>
                            </div>
                          ) : null}
                          <div className="col-span-6">
                            <span className="text-sm font-bold text-gray-400">
                              Broadcast Name
                            </span>
                            <div className="mt-2">
                              <input
                                type="text"
                                className="bg-white rounded-lg border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                onChange={(e) => setName(e.target.value)}
                              />
                            </div>
                          </div>

                          <div>
                            <span className="text-sm font-bold text-gray-400">
                              Set Send Time
                            </span>
                            <div className="grid md:grid-cols-12 grid-cols-3 gap-3">
                              <div className="col-span-3">
                                <div className="mt-2">
                                  <input
                                    type="date"
                                    className="bg-white rounded-lg border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                    onChange={(e) => setDate(e.target.value)}
                                  />
                                </div>
                              </div>
                              <div className="col-span-3 ">
                                <div className="mt-2">
                                  <input
                                    type="time"
                                    className="bg-white rounded-lg border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                    onChange={(e) => setTime(e.target.value)}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-span-6">
                            <span className="text-sm font-bold text-gray-400">
                              Customer
                            </span>
                            <div className="mt-2">
                              {/* <input
                                type="text"
                                placeholder="Filter Data Customer"
                                className="bg-white rounded-lg border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                onClick={() => setShowInput(true)}
                              /> */}
                              <FilterDataCustomer
                                onFilterCustomer={setFilter}
                              />
                            </div>
                            {/* {filteredCustomer.map((val, index) => ( */}
                            <p className="flex text-sm text-green-400">
                              {filteredCustomer.length} Customer Added (
                              {Object.keys(filter).map((val) => (
                                <span>{val}; </span>
                              ))}
                              )
                            </p>
                            {/* ))} */}
                          </div>
                          <div className="col-span-6">
                            <span className="text-sm font-bold text-gray-400">
                              Template
                            </span>
                            <div className="mt-2">
                              <select
                                placeholder="Select Template"
                                className="bg-white rounded-lg border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                value={selectedTemplate}
                                onChange={onSelectTemplate}
                              >
                                <option hidden>Select Template</option>
                                <option
                                  value="create"
                                  className="text-blue-600"
                                >
                                  Add Template
                                </option>
                                {templateList &&
                                  templateList.map(function (value, index) {
                                    return (
                                      <>
                                        <option key={index} value={value.id}>
                                          {value.name} ({value.language})
                                        </option>
                                      </>
                                    );
                                  })}
                              </select>
                            </div>
                          </div>
                          {message ?
                            <>
                              <div className="border-2 rounded-lg my-3 p-3 w-2/5">
                                {message.headerContent ?
                                  (JSON.parse(message.headerContent.replace(/\\/g)).type == "HEADER" ?
                                    <div className="flex justify-center bg-gray-300 py-5">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" fill="white" class="bi bi-card-image" viewBox="0 0 16 16">
                                        <path d="M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                                        <path d="M1.5 2A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13zm13 1a.5.5 0 0 1 .5.5v6l-3.775-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12v.54A.505.505 0 0 1 1 12.5v-9a.5.5 0 0 1 .5-.5h13z" />
                                      </svg>
                                    </div>
                                    : null)
                                  : null}
                                <p className="my-2">
                                  {message.content ? JSON.parse(message.content.replace(/\\/g)).text : null}
                                </p>
                                {message.footer ? (
                                  JSON.parse(message.footer.replace(/\\/g)).type == "FOOTER" ?
                                    <p className="text-gray-400 my-2">{(JSON.parse(message.footer.replace(/\\/g)).text)}</p>
                                    : null)
                                  : null}
                                {message.buttons ?
                                  (JSON.parse(message.buttons.replace(/\\/g))?.type == "BUTTONS" ?
                                    <a href={JSON.parse(message.buttons.replace(/\\/g))?.buttons[0]?.url} target="_blank" className="flex justify-center text-blue-500 py-5 border-t space-x-3">
                                      <OpenInNew />
                                      <span>
                                        {(JSON.parse(message.buttons.replace(/\\/g))?.buttons[0]?.text)}
                                      </span>
                                    </a>
                                    // console.log(JSON.parse(message.buttons.replace(/\\/g)))
                                    : null)
                                  : null}
                                {/* <p>{JSON.stringify(message)}</p> */}
                              </div>
                              <div className="grid grid-cols-4 gap-3 my-3">
                                {iniList}
                              </div>
                            </> : null
                          }
                        </div>
                      </div>
                    </div>
                    {/*end: FORM DATA BROADCAST*/}

                    {/* <Select
                      value={selectedOption}
                      onChange={handleChange}
                      options={groupedOptions}
                    /> */}

                    {/*bagin: Button Save */}
                    <div className="flex flex-row justify-center md:justify-end gap-4 py-10">
                      <a
                        href="/broadcast"
                        className="text-gray-400 rounded-lg bg-gray-200 hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-200 font-medium text-sm px-5 py-2.5 text-center"
                      >
                        Cancel
                      </a>
                      <button
                        type="submit"
                        className="text-white rounded-lg bg-[#4FC29B] hover:bg-[#4FC29B] focus:ring-4 focus:outline-none focus:ring-[#4FC29B] font-medium text-sm px-5 py-2.5 text-center"
                        onClick={postData}
                      >
                        Save
                      </button>
                    </div>
                    {/*end: Button Save */}
                  </div>
                  {showInput ? (
                    <>
                      <div className="bg-black bg-opacity-75 overflow-y-auto overflow-x-hidden fixed flex justify-center top-0 left-0 right-0 bottom-0 z-50 w-full md:inset-0 h-modal md:h-full">
                        {/* <div className="relative w-full max-w-2xl h-full md:h-auto"> */}
                        {/* ini yang ditambahin */}
                        <div className="relative w-full max-w-6xl h-full md:h-auto mt-10">
                          {/* ini yang ditambahin */}
                          <div className="relative bg-[#F5F8FA] rounded-lg shadow dark:bg-gray-700">
                            <div className="flex justify-between items-start p-4 rounded-t border-b dark:border-gray-600">
                              <div className="flex flex-col gap-1">
                                <h3 className="text-xl font-semibold dark:text-white">
                                  Filter Data Customer
                                </h3>
                                <p className="text-sm font-medium text-gray-400">
                                  Customize your filtering
                                </p>
                              </div>
                              <div className="flex flex-col justify-between space-y-5">
                                <button
                                  type="button"
                                  className="bg-transparent hover:bg-gray-100 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                                  onClick={() => setShowInput(false)}
                                >
                                  <svg
                                    className="w-5 h-5"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                      clipRule="evenodd"
                                    ></path>
                                  </svg>
                                </button>
                                <a
                                  href="#"
                                  className="text-xs text-rose-600 hover:text-rose-800 font-semibold"
                                >
                                  Clear Filter
                                </a>
                              </div>
                            </div>
                            <div className="p-6 space-y-6 items-center">
                              <div className="flex flex-row justify-center flex-wrap gap-5">
                                <div className="relative w-48">
                                  <select className="block appearance-none w-full bg-white border border-white text-gray-700 text-sm py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-white">
                                    <option hidden>Gender</option>
                                    <option>Female</option>
                                    <option>Male</option>
                                  </select>
                                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"></div>
                                </div>
                                <div className="relative w-48">
                                  <select className="block appearance-none w-full bg-white border border-white text-gray-700 text-sm py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-white">
                                    <option hidden>Martial Status</option>
                                    <option>1</option>
                                    <option>2</option>
                                  </select>
                                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"></div>
                                </div>
                                <div className="relative w-48">
                                  <select className="block appearance-none w-full bg-white border border-white text-gray-700 text-sm py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-white">
                                    <option hidden>Subdistrict</option>
                                    <option>1</option>
                                    <option>2</option>
                                  </select>
                                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"></div>
                                </div>
                                <div className="relative w-48">
                                  <select className="block appearance-none w-full bg-white border border-white text-gray-700 text-sm py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-white">
                                    <option hidden>District</option>
                                    <option>1</option>
                                    <option>2</option>
                                  </select>
                                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"></div>
                                </div>
                                <div className="relative w-48">
                                  <select className="block appearance-none w-full bg-white border border-white text-gray-700 text-sm py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-white">
                                    <option hidden>City</option>
                                    <option>1</option>
                                    <option>2</option>
                                  </select>
                                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"></div>
                                </div>
                                <div className="relative w-48">
                                  <select className="block appearance-none w-full bg-white border border-white text-gray-700 text-sm py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-white">
                                    <option hidden>Province</option>
                                    <option>1</option>
                                    <option>2</option>
                                  </select>
                                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"></div>
                                </div>
                                <div className="relative w-48">
                                  <select className="block appearance-none w-full bg-white border border-white text-gray-700 text-sm py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-white">
                                    <option hidden>Level</option>
                                    <option>1</option>
                                    <option>2</option>
                                  </select>
                                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"></div>
                                </div>
                                <div className="relative w-48">
                                  <select className="block appearance-none w-full bg-white border border-white text-gray-700 text-sm py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-white">
                                    <option hidden>Payment Method</option>
                                    <option>1</option>
                                    <option>2</option>
                                  </select>
                                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"></div>
                                </div>
                                <div className="relative w-48">
                                  <select className="block appearance-none w-full bg-white border border-white text-gray-700 text-sm py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-white">
                                    <option hidden>Warehouse</option>
                                    <option>1</option>
                                    <option>2</option>
                                  </select>
                                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"></div>
                                </div>
                                <div className="relative w-48">
                                  <select className="block appearance-none w-full bg-white border border-white text-gray-700 text-sm py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-white">
                                    <option hidden>CS Acquisition Name</option>
                                    <option>1</option>
                                    <option>2</option>
                                  </select>
                                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"></div>
                                </div>
                                <div className="relative w-48">
                                  <select className="block appearance-none w-full bg-white border border-white text-gray-700 text-sm py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-white">
                                    <option hidden>Campaign</option>
                                    <option>1</option>
                                    <option>2</option>
                                  </select>
                                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"></div>
                                </div>
                                <div className="relative w-48">
                                  <select className="block appearance-none w-full bg-white border border-white text-gray-700 text-sm py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-white">
                                    <option hidden>Promo</option>
                                    <option>1</option>
                                    <option>2</option>
                                  </select>
                                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"></div>
                                </div>
                                <div className="relative w-48">
                                  <select className="block appearance-none w-full bg-white border border-white text-gray-700 text-sm py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-white">
                                    <option hidden>Product</option>
                                    <option>1</option>
                                    <option>2</option>
                                  </select>
                                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"></div>
                                </div>
                                <div className="relative w-48">
                                  <select className="block appearance-none w-full bg-white border border-white text-gray-700 text-sm py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-white">
                                    <option hidden>Buying Date</option>
                                    <option>1</option>
                                    <option>2</option>
                                  </select>
                                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"></div>
                                </div>
                                <div className="relative w-48">
                                  <select className="block appearance-none w-full bg-white border border-white text-gray-700 text-sm py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-white">
                                    <option hidden>Courier</option>
                                    <option>1</option>
                                    <option>2</option>
                                  </select>
                                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"></div>
                                </div>
                              </div>
                              <div className="mt-10 flex flex-row justify-end">
                                <button
                                  className="px-3 rounded-xl text-gray-400 hover:text-gray-600 font-semibold"
                                  onClick={() => setShowInput(false)}
                                >
                                  Cancel
                                </button>
                                <button className="py-2 px-5 rounded-md text-white bg-green-400 hover:bg-green-500 font-semibold ml-2">
                                  Apply
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : null}
                </div>
              </div>
            </div>
          </div>

          {/* end: Main */}
        </div>
      </div >
    </>
  );
}

export default CreatedBroadcast;
