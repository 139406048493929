import React, { Component, useState, useEffect } from "react";
import CardCommunication from "../../Components/Partials/Card/CardCommunication";
import Sidebar from "../../Components/Sidebar/Sidebar";
import SidebarComunication from "../../Components/Sidebar/SidebarComunication";
import whatsapp from "../../Assets/icon/comunication/whatsapp.svg";
import arrowUp from "../../Assets/icon/comunication/arrowUp.svg";
import docsTemplate from "../../Assets/icon/comunication/docsTemplate.svg";
import loyalityRewards from "../../Assets/icon/comunication/loyalityRewards.svg";
import AOS from "aos";
import "aos/dist/aos.css";
import { countLeads } from "../../Network/Lib/boLead";
import AddCS from "../../Components/Partials/Modal/AddCS";
import CardWhatsapp from "../../Components/Partials/Card/CardWhatsapp";
import { logout } from "../../Network/Lib/auth";
import { useNavigate } from "react-router-dom";

function Communication() {
  const navigate = useNavigate();
  const [navSideBar, setNavSideBar] = useState(false);
  
  const signOut =()=>{
    logout();
    navigate('/login')
  }
  return (
    <>
      <div className="bg-gradient-to-r from-[#54DAA1] to-[#2DCE89]">
        <div className="relative">
          <aside
            className={
              navSideBar === true
                ? "w-24 h-screen p-5 fixed top-0 left-0 right-0 z-10"
                : "w-3/12 h-screen p-5 fixed top-0 left-0 right-0 z-10"
            }
            aria-label="Sidebar"
          >
            <button
              className="absolute top-72 right-1 w-10 h-10 bg-gradient-to-r from-[#54DAA1] to-[#2DCE89] rounded-full shadow-lg flex justify-center items-center"
              onClick={() => setNavSideBar(!navSideBar)}
            >
              {navSideBar ? (
                <svg
                  width="9"
                  height="16"
                  viewBox="0 0 9 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 14.3333L7.66667 7.66668L1 1.00001"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              ) : (
                <svg
                  width="10"
                  height="16"
                  viewBox="0 0 10 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.33325 14.6667L1.66659 7.99999L8.33325 1.33332"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              )}
            </button>
            <SidebarComunication navSideBar={navSideBar} />
          </aside>
        </div>
        <div
          className={
            navSideBar === true
              ? "ml-24 h-72 w-11/12 p-5 space-y-5"
              : "ml-auto h-72 w-9/12 p-5 space-y-5"
          }
        >
          {/* Header */}
          <div className="flex flex-row justify-between items-center">
            <div className="text-white space-y-2">
              <div className="flex flex-row space-x-3">
                <p className="text-sm">Pages</p>
                <p className="text-sm">/</p>
                <a href="/communication" className="text-sm">
                  Communication
                </a>
              </div>
              <p className="font-semibold">Communication</p>
            </div>
            <div className="flex flex-row items-center gap-5">
              <a href="#" className="flex items-center gap-2">
                <svg
                  width="21"
                  height="20"
                  viewBox="0 0 21 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.4938 2.52235C12.7336 1.70165 11.6719 1.24969 10.5 1.24969C9.32191 1.24969 8.25668 1.69891 7.50004 2.51454C6.73519 3.33915 6.36254 4.45985 6.45004 5.67001C6.62348 8.05751 8.44027 9.99969 10.5 9.99969C12.5598 9.99969 14.3735 8.0579 14.5496 5.67079C14.6383 4.47157 14.2633 3.35321 13.4938 2.52235Z"
                    fill="white"
                  />
                  <path
                    d="M17.3752 18.7488H3.62522C3.44525 18.7511 3.26701 18.7133 3.10349 18.6381C2.93997 18.5629 2.79526 18.4522 2.67991 18.314C2.426 18.0105 2.32366 17.596 2.39944 17.1769C2.72913 15.348 3.75804 13.8117 5.37522 12.7332C6.81194 11.7757 8.63186 11.2488 10.5002 11.2488C12.3686 11.2488 14.1885 11.7761 15.6252 12.7332C17.2424 13.8113 18.2713 15.3476 18.601 17.1765C18.6768 17.5957 18.5744 18.0101 18.3205 18.3136C18.2052 18.4519 18.0605 18.5626 17.897 18.6379C17.7335 18.7132 17.5552 18.7511 17.3752 18.7488Z"
                    fill="white"
                  />
                </svg>
                <p className="text-white">{localStorage.getItem("nameUser")}</p>
              </a>
              <button onClick={signOut} className="bg-white p-3 rounded-lg">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.5625 2.8125H2.1875V17.8125H6.5625M13.4375 5.9375L17.8125 10.3125L13.4375 14.6875M7.1875 10.3125H17.8125"
                      stroke="#EF4444"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
            </div>
          </div>
          {/* start: Main */}
          <div className="grid lg:grid-cols-12 grid-cols-6 gap-5">
            <div className="lg:col-span-3 col-span-6">
              <CardCommunication
                href="/template"
                icon={docsTemplate}
                title={"TEMPLATE"}
                subtitle={"12"}
              />
            </div>
            <div className="lg:col-span-3 col-span-6">
              <CardCommunication
                href="/Broadcast"
                icon={whatsapp}
                title={"BROADCAST"}
                subtitle={"3"}
              />
            </div>
            <div className="lg:col-span-3 col-span-6">
              <CardCommunication
                href="/leveling"
                icon={arrowUp}
                title={"LEVELING"}
                subtitle={"10"}
              />
            </div>
            <div className="lg:col-span-3 col-span-6">
              <CardCommunication
                href="/loyalty"
                icon={loyalityRewards}
                title={"LOYALITY PORGRAM"}
                subtitle={"4"}
              />
            </div>
          </div>
          <div className="bg-white shadow-lg rounded-lg p-6">
            <div className="flex flex-row justify-between">
              <h1 className="text-lg ">Customer Service CRM</h1>
              <div className="flex flex-row gap-3">
                <a
                  href="/archive"
                  className="hover:bg-orange-500 border border-orange-500 text-orange-500 hover:text-white text-sm rounded-lg px-3 py-2 font-medium flex flex-row gap-3 flex items-center"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M20.25 7.5l-.625 10.632a2.25 2.25 0 01-2.247 2.118H6.622a2.25 2.25 0 01-2.247-2.118L3.75 7.5m8.25 3v6.75m0 0l-3-3m3 3l3-3M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z"
                    />
                  </svg>

                  <span>Archive</span>
                </a>
                <div className="flex flex-row rounded-lg bg-gray-50 border border-gray-300 mt-1">
                  <div className="flex items-center pl-3 pointer-events-none">
                    <svg
                      className="w-5 h-5 text-gray-500 dark:text-gray-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </div>
                  <input
                    type="text"
                    id="table-search"
                    className="appearance-none bg-transparent border-none w-full text-gray-700 leading-tight focus:outline-none focus:ring-transparent w-64"
                    placeholder="Search"
                  />
                </div>
                <AddCS />
              </div>
            </div>
            <div className="flex flex-wrap justify-around gap-5 mt-3">
              <CardWhatsapp
                names={"Zall"}
                phone={"+6281245527645"}
                statusWa={"Connected"}
              />
              <CardWhatsapp
                names={"Zall"}
                phone={"+6281245527645"}
                statusWa={"Connected"}
              />
              <CardWhatsapp
                names={"Zall"}
                phone={"+6281245527645"}
                statusWa={"Connected"}
              />
              <CardWhatsapp
                names={"Zall"}
                phone={"+6281245527645"}
                statusWa={"Connected"}
              />
            </div>
          </div>

          {/* end: Main */}
        </div>
      </div>
    </>
  );
}

export default Communication;
