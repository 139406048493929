import React from "react";
import { Route, Routes } from "react-router-dom";

import "./index.css";
// import Dashboard from "./Pages/Dashboard";
import Login from "./Pages/Login";
import Register from "./Pages/Register";
// import RoomChat from "./Pages/R  oomChat";
import Reporting from "./Pages/Reporting";
import CustomerService from "./Pages/CustomerService";
// import ViewCustomer from "./Pages/ViewCustomer";
// import Product from "./Pages/Product";
import AddSingleProduct from "./Pages/AddSingleProduct";
import AddUploadProduct from "./Pages/AddUploadProduct";
import AddWhatsapp from "./Pages/AddWhatsapp";
// import OverviewCustomerService from "./Pages/OverviewCustomerService";
// import Template from "./Pages/Template";
// import Leveling from "./Pages/Leveling";
// import Loyalty from "./Pages/Loyalty";
// import DetailLeveling from "./Pages/DetailLeveling";
import OverviewCustomer from "./Pages/OverviewCustomer";
// import Communication from "./Pages/Communication";
// import OverviewLoyalty from "./Pages/OverviewLoyalty";
import DetailBlasting from "./Pages/DetailBlasting";
import AllCustomer from "./Pages/AllCustomer";
// import AddSingleCostumer from "./Pages/AddSingleCostumer";
// import BroadcastDashboard from "./Pages/BroadcastDashboard";
// import CreatedBroadcast from "./Pages/CreatedBroadcast";
// import EditBroadcast from "./Pages/EditBroadcast";
// import ViewBroadcast from "./Pages/ViewBroadcast";
// import ViewTemplate from "./Pages/ViewTemplate";
// import CreateTemplate from "./Pages/CreateTemplate";
import ReportingSpv from "./Pages/ReportingSpv";
// import ReportingSpv2 from "./Pages/ReportingSpv2";
import OverviewProduct from "./Pages/OverviewProduct";
import Promotion from "./Pages/Promotion";

// import EditTemplate from "./Pages/EditTemplate";
import Dashboard from "./Pages/new/Dashboard";
import Communication from "./Pages/new/Communication";
// import Template from "./Pages/new/Template";
// import CreateTemplate from "./Pages/new/CreateTemplate";
import EditTemplate from "./Pages/new/EditTemplate";
import AddSingleCustomer from "./Pages/new/AddSingleCustomer";
// import ViewTemplate from "./Pages/new/ViewTemplate";
import BroadcastDashboard from "./Pages/new/BroadcastDashboard";
import EditProfileCustomer from "./Pages/new/EditProfileCustomer";
import ViewCustomer from "./Pages/new/ViewCustomer";
import CreatedBroadcast from "./Pages/new/CreatedBroadcast";
import EditBroadcast from "./Pages/new/EditBroadcast";
import ViewBroadcast from "./Pages/new/ViewBroadcast";
import RoomChat from "./Pages/new/RoomChat";
import Leveling from "./Pages/new/Leveling";
import Product from "./Pages/new/Product";
import DetailLeveling from "./Pages/new/DetailLeveling";
import Loyalty from "./Pages/new/Loyalty";
import OverviewLoyalty from "./Pages/new/OverviewLoyalty";
import DetailProduct from "./Pages/new/DetailProduct";
import Point from "./Pages/new/Point";
import ReportingSpv2 from "./Pages/new/ReportingSpv2";
import OverviewCustomerService from "./Pages/new/OverviewCustomerService";
import AddWhatsApp from "./Pages/new/AddWhatsApp";
import Archive from "./Pages/new/Archive";
import Revenue from "./Pages/new/Revenue";
import SalesByDistrict from "./Pages/new/SalesByDistrict";
import SalesByAge from "./Pages/new/SalesByAge";
import SalesByMaritalStatus from "./Pages/new/SalesByMaritalStatus";
import SalesByGender from "./Pages/new/SalesByGender";
import EditSingleCustomer from "./Pages/new/EditSingleCustomer";
import AddClosing from "./Pages/new/AddClosing";
import ForgotPassword from "./Pages/ForgotPassword";
import SetNewPassword from "./Pages/SetNewPassword";
import TemplateLp from "./Pages/LandingPage/Template";
import Features from "./Pages/LandingPage/Features";
import FeaturesSmartBroadcast from "./Pages/LandingPage/FeaturesSmartBroadcast";
import Pricing from "./Pages/LandingPage/Pricing";
import Contact from "./Pages/LandingPage/Contact";
import PrivacyPolice from "./Pages/LandingPage/PrivacyPolice";
import TermService from "./Pages/LandingPage/TermService";
import About from "./Pages/LandingPage/About";
import AddOrder from "./Pages/new/AddOrder";
import Promo from "./Pages/new/Promo";
import Team from "./Pages/new/Team";
import Warehouse from "./Pages/new/Warehouse";
import DetailOrder from "./Pages/new/DetailOrder";
import EditDetailOrder from "./Pages/new/EditDetailOrder";
import Member from "./Pages/new/Member";
import Template from "./Pages/new/Template/Template";
import ViewTemplate from "./Pages/new/Template/ViewTemplate";
import CreateTemplate from "./Pages/new/Template/CreateTemplate";
import Home from "./Pages/NewLandingPage";
import MyDevice from "./Pages/new/Unofficial/MyDevice";
import SendMessage from "./Pages/new/Unofficial/SendMessage";
import RoomChatUnofficial from "./Pages/new/Unofficial/RoomChatUnofficial";
import Blog from "./Pages/NewLandingPage/blog";
import DetailBlog from "./Pages/NewLandingPage/DetailBlog";
import Broadcast from "./Pages/new/Unofficial/Broadcast/Broadcast";
import CreatedBroadcastUn from "./Pages/new/Unofficial/Broadcast/CreatedBroadcastUn";
import MessageHistory from "./Pages/new/Unofficial/Broadcast/MessageHistory";
import Sitemap from "./sitemap";

function App() {
  return (
    <>
      <div className="font-poppins">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/detail/:slug" element={<DetailBlog />} />
          {/* <Route path="/" element={<TemplateLp />}>
            <Route index element={<Home />} />
            <Route path="/features" element={<Features />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact-us" element={<Contact/>}/>
            <Route path="/privacy-police" element={<PrivacyPolice/>}/>
            <Route path="/term-of-service" element={<TermService/>}/>
            <Route path="/smart-broadcast" element={<FeaturesSmartBroadcast/>}/>
          </Route> */}
          <Route path="/term-of-service" element={<TermService />} />
          <Route path="/privacy-police" element={<PrivacyPolice />} />
          <Route path="/login" element={<Login />} />
          <Route path="/create-account" element={<Register />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/new-password" element={<SetNewPassword />} />
          {/* <Route path="/" element={<Login />}/> */}

          {/* Dashboard */}
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/addSingleCustumer" element={<AddSingleCustomer />} />
          <Route path="/editSingleCustumer/:id" element={<EditSingleCustomer />} />
          <Route
            path="/edit-profile-costumer"
            element={<EditProfileCustomer />}
          />
          <Route path="/view-customer/:id" element={<ViewCustomer />} />
          <Route path="/add-order/:id" element={<AddOrder />} />
          <Route path="/revenue" element={<Revenue />} />
          <Route path="/SalesByDistrict" element={<SalesByDistrict />} />
          <Route path="/SalesByAge" element={<SalesByAge />} />
          <Route
            path="/SalesByMaritalStatus"
            element={<SalesByMaritalStatus />}
          />
          <Route path="/SalesByGender" element={<SalesByGender />} />
          <Route path="/warehouse" element={<Warehouse />} />
          {/* Order */}
          <Route path="/detail-order/:id" element={<DetailOrder />} />
          <Route path="/edit-detail-order" element={<EditDetailOrder />} />
          {/* Order */}


          {/* Promo */}
          <Route path="/promo" element={<Promo />} />

          {/* Team */}
          <Route path="/team" element={<Team />} />
          <Route path="/member" element={<Member />} />

          {/* Product */}
          <Route path="/product" element={<Product />} />
          <Route path="/detail-product" element={<DetailProduct />} />

          {/* Dashboard */}
          {/* <Route path="/dashboard" element={<Dashboard />} /> */}
          {/* <Route path="/dashboard/view-customer" element={<ViewCustomer />} /> */}
          {/* <Route path="/addSingleCoustumer" element={<AddSingleCostumer />} /> */}
          {/* <Route path="/edit-profile-costumer" element={<EditProfileCostumer />} /> */}

          {/* Communication */}
          <Route path="/Broadcast" element={<BroadcastDashboard />} />
          <Route path="/Created-Broadcast" element={<CreatedBroadcast />} />
          <Route path="/View-Broadcast/:id" element={<ViewBroadcast />} />
          <Route path="/Edit-Broadcast/:id" element={<EditBroadcast />} />

          <Route path="/View-Template/:id" element={<ViewTemplate />} />
          <Route path="/Create-Template" element={<CreateTemplate />} />
          <Route path="/Edit-Template" element={<EditTemplate />} />

          <Route path="/detail-blasting" element={<DetailBlasting />} />

          <Route path="/archive" element={<Archive />} />

          {/*Chat */}
          <Route path="/crm-chat" element={<RoomChat />} />
          <Route path="/add-closing" element={<AddClosing />} />

          {/*Reporting */}
          {/* <Route path="/reporting" element={<Reporting />} /> */}
          {/* <Route path="/reporting-spv" element={<ReportingSpv />}/> */}
          <Route path="/reporting" element={<ReportingSpv2 />} />

          <Route path="/cs" element={<CustomerService />} />
          <Route path="/overview-cs" element={<OverviewCustomerService />} />

          <Route path="/Add-WhatsApp" element={<AddWhatsApp />} />

          {/* Product */}
          {/* <Route path="/product" element={<Product />} /> */}
          {/* <Route path="/addSingleProduct" element={<AddSingleProduct />} />
          <Route path="/addUploadProduct" element={<AddUploadProduct />} />
          <Route path="/overview-product" element={<OverviewProduct />} /> */}
          {/* Promotion */}
          <Route path="/promotion" element={<Promotion />} />

          <Route path="/addWhatsapp" element={<AddWhatsapp />} />
          <Route path="/template" element={<Template />} />
          <Route path="/communication" element={<Communication />} />
          <Route path="/all-customer" element={<AllCustomer />} />
          <Route path="/loyalty" element={<Loyalty />} />
          <Route path="/overview-loyalty" element={<OverviewLoyalty />} />
          <Route path="/leveling" element={<Leveling />} />
          <Route path="/detail-leveling" element={<DetailLeveling />} />
          <Route path="/overview-customer" element={<OverviewCustomer />} />

          {/* Coustumer */}
          {/* <Route path="/add-single-customer" element={<AddSingleCostumer />} /> */}
          {/* <Route path="/edit-profile-customer" element={<EditProfileCostumer />} /> */}

          {/* Point */}
          <Route path="/point" element={<Point />} />
          {/* Wa Unofficial */}
          <Route path="/mydevice" element={<MyDevice />} />
          <Route path="/send-message/:session/:name" element={<SendMessage />} />
          <Route path="/crm-chat-un/:session/:csPhone" element={<RoomChatUnofficial />} />
          {/* Broadcast Unofficial */}
          <Route path="/broadcast-un" element={<Broadcast />} />
          <Route path="/created-broadcast-un/:nameSession/:sender" element={<CreatedBroadcastUn />} />
          <Route path="/message-history/:id" element={<MessageHistory />} />
          <Route path="/sitemap" element={<Sitemap />} />

        </Routes>

      </div>
    </>
  );
}

export default App;
