import api from "../apiClient";

export function createOrder(data) {
  return api.axiosClient.post("/order/createOrder", data);
}

export function createOrderDetail(data) {
  return api.axiosClient.post("/orderDetail/createDetail", data);
}

export function getOrderById(id) {
  return api.axiosClient.get(`/orderDetail/getDetail/${id}`);
}


