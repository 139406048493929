import React, { useState } from "react";
import RepeaterComplaint from "../Input/RepeaterComplaint";
import RepeaterLeadSource from "../Input/RepeaterLeadSource";

function AddComplaint() {
  const [showInput, setShowInput] = useState(false);

  return (
    <>
      <button
        type="button"
        className="bg-green-400 hover:bg-green-500 text-white rounded-full px-3 py-2 font-medium text-sm flex flex-row gap-3 flex items-center"
        onClick={() => setShowInput(true)}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="14"
          height="14"
          fill="currentColor"
          className="bi bi-plus-lg"
          viewBox="0 0 16 16"
        >
          <path
            fillRule="evenodd"
            d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"
          />
        </svg>

        <span>Add Complaint</span>
      </button>
      {showInput ? (
        <>
          <div className="bg-black bg-opacity-75 overflow-y-auto overflow-x-hidden fixed flex justify-center top-0 left-0 right-0 bottom-0 z-50 w-full md:inset-0 h-modal md:h-full">
            <div className="relative p-4 w-full max-w-2xl h-full md:h-auto">
              <div className="relative bg-[#F5F8FA] rounded-lg shadow dark:bg-gray-700">
                {/* header */}
                <div className="flex justify-between items-center p-5 rounded-t border-b dark:border-gray-600">
                  <h3 className="text-xl font-medium text-gray-900 dark:text-white">
                    Add Complaint
                  </h3>
                  <button
                    type="button"
                    className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                    onClick={() => setShowInput(false)}
                  >
                    <svg
                      className="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </button>
                </div>
                {/* body */}
                <div className="p-6 space-y-6 items-center">
                  <div className="">
                    <label
                      className="block tracking-wide text-gray-400 text-sm font-semibold mb-2"
                      for="grid-state"
                    >
                      Select Product
                    </label>
                    <div className="relative">
                      <select className="block appearance-none w-full bg-white border border-white text-gray-400 font-medium py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-white">
                        <option hidden>Select Product</option>
                        <option>1</option>
                        <option>2</option>
                      </select>
                      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"></div>
                    </div>
                  </div>
                  <div className="">
                    <label
                      className="block tracking-wide text-gray-400 text-sm font-semibold mb-2"
                      for="grid-state"
                    >
                      Select Criteria Complaint
                    </label>
                    <div className="flex flex-row justify-start gap-5">
                      <div className="flex items-center">
                        <input
                          id="complaint-option-1"
                          type="radio"
                          name="complaint"
                          value="SOFT COMPLAINT"
                          className="w-4 h-4 border-gray-300 focus:ring-2 focus:ring-blue-300 dark:focus:ring-blue-600 dark:focus:bg-blue-600 dark:bg-gray-700 dark:border-gray-600"
                        />
                        <label
                          for="complaint-option-1"
                          className="block ml-2 text-sm font-medium text-gray-400 dark:text-gray-300"
                        >
                          SOFT COMPLAINT
                        </label>
                      </div>

                      <div className="flex items-center">
                        <input
                          id="complaint-option-2"
                          type="radio"
                          name="complaint"
                          value="HARD COMPLAINT"
                          className="w-4 h-4 border-gray-300 focus:ring-2 focus:ring-blue-300 dark:focus:ring-blue-600 dark:bg-gray-700 dark:border-gray-600"
                        />
                        <label
                          for="complaint-option-2"
                          className="block ml-2 text-sm font-medium text-gray-400 dark:text-gray-300"
                        >
                          HARD COMPLAINT
                        </label>
                      </div>
                    </div>
                  </div>
                  <RepeaterComplaint />
                </div>
                {/* footer */}
                <div className="flex items-center justify-end p-6 space-x-2 rounded-b border-t border-gray-200 dark:border-gray-600">
                  <button
                    className="px-3 rounded-xl text-gray-400 hover:text-gray-600 font-semibold"
                    onClick={() => setShowInput(false)}
                  >
                    Cancel
                  </button>
                  <button className="py-2 px-5 rounded-md text-white bg-green-400 hover:bg-green-500 font-semibold ml-2">
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
}

export default AddComplaint;
