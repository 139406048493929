import React, { useState, useEffect } from "react";
import Profile from "../../../Assets/img/Profile.jpg";
import useCollapse from "react-collapsed";
import { getBroadcast } from "../../../Network/Lib/broadcast";
import { getOrderById } from "../../../Network/Lib/order";
import { useParams } from "react-router-dom";
import dataa from "../../../Assets/svg/Data.svg";
import { Link } from "react-router-dom";
import moment from "moment/moment";

function TableOrderHistory() {
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse();
  // const [customer] = useState([...OrderHistory]);
  return (
    <div className="">
      <Table />
    </div>
  );
}
function Section(props) {
  const config = {
    defaultExpanded: props.defaultExpanded || false,
    collapsedHeight: props.collapsedHeight || 0,
  };
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse(config);
  return (
    <>
      <tr className="bg-white">
        <td className="px-6 py-4">
          <div className="flex flex-row gap-3">
            <a>
              <div
                className="bg-green-400 hover:bg-green-500 text-white rounded-full p-1"
                {...getToggleProps()}
              >
                {isExpanded ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-chevron-up"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-chevron-down"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                    />
                  </svg>
                )}
              </div>
            </a>
            <p>{moment(props.buyingDate).format('L')}</p>
          </div>
        </td>
        <td className="px-6 py-4">
          <ul className="list-disc">
            {props.orderDetails.map((val, index) => (
              <li>{val.product.name}</li>
            ))}
          </ul>
        </td>
        <td className="px-6 py-4">
          <ul className="list-disc">
            {props.orderDetails.map((val, index) => (
              <li>{val.qty}</li>
            ))}
          </ul>
        </td>
        <td className="px-6 py-4">{props.payMethod}</td>
        <td className="px-6 py-4">{props.warehouse}</td>
        <td className="px-6 py-4">{props.resi}</td>
        <td className="px-6 py-4">{props.courier}</td>
        <td className="px-6 py-4">{props.note}</td>
        <td className="px-6 py-4">
          <div>
            <div className="flex flex-col">
              <Link
                to={`/detail-order/${props.id}`}
                className="flex flex-row justify-center items-center text-white rounded-lg bg-white hover:bg-gray-50 focus:ring-4 focus:outline-none focus:ring-:bg-gray-50 font-medium text-sm px-2 py-3 text-center"
              >
                <img src={dataa} className="w-6 h-6" />
              </Link>
            </div>
          </div>
        </td>
      </tr>
      <tr
        className="bg-white border-b-2 border-[#F5F8FA]"
        {...getCollapseProps()}
      >
        {props.children}
      </tr>
    </>
  );
}

const Table = () => {
  const [page, setPage] = useState(1);
  const { id } = useParams();
  // const { slice, range } = useTable(data, page, rowsPerPage);
  const [data, setData] = useState([]);
  // const [pagination, setPagination] = useState({
  //   first_page: 0,
  //   page_links: [],
  //   last_page: 0,
  // });

  useEffect(() => {
    getOrderById(id).then((response) => {
      console.log(response.data.data, 'saas');
      setData(response.data.data);
      // console.log(response.data.data[0].order_details[0].product?.name);
      // setPagination({
      //   first_page: 1,
      //   page_links: response.data.links.slice(
      //     1,
      //     response.data.links.length - 1
      //   ),
      //   last_page: response.data.last_page,
      // });
    });
  }, []);

  return (
    <>
      <div className="overflow-x-auto md:h-screen">
        <table className="w-full text-sm text-left text-gray-500">
          <thead className="text-sm text-gray-400">
            <tr className="text-sm text-gray-400 bg-gray-100">
              <th scope="col" className="px-6 py-3 w-52">
                Buying Date
              </th>
              <th scope="col" className="px-6 py-3 w-52">
                Product
              </th>
              <th scope="col" className="px-6 py-3 w-52">
                Quantity
              </th>
              <th scope="col" className="px-6 py-3 w-52">
                Payment Method
              </th>
              <th scope="col" className="px-6 py-3 w-52">
                Warehouse
              </th>
              <th scope="col" className="px-6 py-3 w-52">
                Resi
              </th>
              <th scope="col" className="px-6 py-3 w-52">
                Courier
              </th>
              <th scope="col" className="px-6 py-3 w-72">
                Note
              </th>
              <th scope="col" className="px-6 py-3 w-72">
                Detail
              </th>
            </tr>
          </thead>
          {data &&
            data.map((el, index) => (
              <tbody>
                <Section
                  key={index}
                  id={id}
                  buyingDate={el.buyingDate}
                  orderDetails={el.order_details}
                  // quantity={el.order_details[0]?.qty}
                  payMethod={el.paymentMethod}
                  warehouse={el.warehouse.warehouseName}
                  resi={el.resi}
                  note={el.note}
                  courier={el.courir}
                >
                  <td className="px-6">
                    <div>
                      <div className="flex flex-col">
                        <p className="text-xs bg-black text-white rounded w-fit px-1">
                          CS Acquisition Name
                        </p>
                        <p className="bg-gray-200 text-sm rounded w-fit px-1">
                          {el.csId}
                        </p>
                      </div>
                    </div>
                  </td>
                  <td className="px-6">
                    <div>
                      <div className="flex flex-col">
                        <p className="text-xs bg-black text-white rounded w-fit px-1">
                          CS Acquisition Phone
                        </p>
                        <p className="bg-gray-200 text-sm rounded w-fit px-1">
                          {el.csPhone}
                        </p>
                      </div>
                    </div>
                  </td>
                  <td className="px-6" colSpan={2}>
                    <div>
                      <div className="flex flex-col">
                        <p className="text-xs bg-black text-white rounded w-fit px-1">
                          Campaign
                        </p>
                        <p className="bg-gray-200 text-sm rounded w-fit px-1">
                          {/* {el.campaign} */}
                        </p>
                      </div>
                    </div>
                  </td>
                  <td className="px-6" colSpan={2}>
                    <div>
                      <div className="flex flex-col">
                        <p className="text-xs bg-black text-white rounded w-fit px-1">
                          Promo
                        </p>
                        <p className="bg-gray-200 text-sm rounded w-fit px-1">
                          {/* {el.promo} */}
                        </p>
                      </div>
                    </div>
                  </td>
                  <td className="px-6" colSpan={2}>
                    <div>
                      <div className="flex flex-col">
                        <p className="text-xs bg-black text-white rounded w-fit px-1">
                          Buying Reason
                        </p>
                        <p className="bg-gray-200 text-sm rounded w-fit px-1">
                          {/* {el.buyingReason} */}
                        </p>
                      </div>
                    </div>
                  </td>
                </Section>
              </tbody>
            ))}
        </table>
      </div>
      <div className="flex flex-row justify-center">
        {/* <TableFooter
          range={range}
          slice={slice}
          setPage={setPage}
          page={page}
        /> */}
      </div>
    </>
  );
};

export default TableOrderHistory;
