import React, { Component, useState, useEffect } from "react";
import Sidebar from "../../Components/Sidebar/Sidebar";
import "aos/dist/aos.css";
import { logout } from "../../Network/Lib/auth";
import { useNavigate } from "react-router-dom";

function EditProfileCustomer() {
  const navigate = useNavigate();
  const [navSideBar, setNavSideBar] = useState(false);
  
  const signOut =()=>{
    logout();
    navigate('/login')
  }
  return (
    <>
      <div className="bg-gradient-to-r from-[#54DAA1] to-[#2DCE89]">
        <div className="relative">
          <aside
            className={
              navSideBar === true
                ? "w-24 h-screen p-5 fixed top-0 left-0 right-0 z-10"
                : "w-3/12 h-screen p-5 fixed top-0 left-0 right-0 z-10"
            }
            aria-label="Sidebar"
          >
            <button
              className="absolute top-72 right-1 w-10 h-10 bg-gradient-to-r from-[#54DAA1] to-[#2DCE89] rounded-full shadow-lg flex justify-center items-center"
              onClick={() => setNavSideBar(!navSideBar)}
            >
              {navSideBar ? (
                <svg
                  width="9"
                  height="16"
                  viewBox="0 0 9 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 14.3333L7.66667 7.66668L1 1.00001"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              ) : (
                <svg
                  width="10"
                  height="16"
                  viewBox="0 0 10 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.33325 14.6667L1.66659 7.99999L8.33325 1.33332"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              )}
            </button>
            <Sidebar navSideBar={navSideBar} />
          </aside>
        </div>
        <div
          className={
            navSideBar === true
              ? "ml-24 h-72 w-11/12 p-5 space-y-5"
              : "ml-auto h-72 w-9/12 p-5 space-y-5"
          }
        >
          {/* Header */}
          <div className="flex flex-row justify-between items-center">
            <div className="text-white space-y-2">
              <div className="flex flex-row space-x-3">
                <p className="text-sm">Pages</p>
                <p className="text-sm">/</p>
                <a href="/Dashboard" className="text-sm">
                  Dashboard
                </a>
                <p className="text-sm">/</p>
                <a href="/view-customer" className="text-sm">
                  View Customer
                </a>
                <p className="text-sm">/</p>
                <a href="#" className="text-sm">
                  Edit Profile Customer
                </a>
              </div>
              <p className="font-semibold">Edit Profile Customer</p>
            </div>
            <div className="flex flex-row items-center gap-5">
              <a href="#" className="flex items-center gap-2">
                <svg
                  width="21"
                  height="20"
                  viewBox="0 0 21 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.4938 2.52235C12.7336 1.70165 11.6719 1.24969 10.5 1.24969C9.32191 1.24969 8.25668 1.69891 7.50004 2.51454C6.73519 3.33915 6.36254 4.45985 6.45004 5.67001C6.62348 8.05751 8.44027 9.99969 10.5 9.99969C12.5598 9.99969 14.3735 8.0579 14.5496 5.67079C14.6383 4.47157 14.2633 3.35321 13.4938 2.52235Z"
                    fill="white"
                  />
                  <path
                    d="M17.3752 18.7488H3.62522C3.44525 18.7511 3.26701 18.7133 3.10349 18.6381C2.93997 18.5629 2.79526 18.4522 2.67991 18.314C2.426 18.0105 2.32366 17.596 2.39944 17.1769C2.72913 15.348 3.75804 13.8117 5.37522 12.7332C6.81194 11.7757 8.63186 11.2488 10.5002 11.2488C12.3686 11.2488 14.1885 11.7761 15.6252 12.7332C17.2424 13.8113 18.2713 15.3476 18.601 17.1765C18.6768 17.5957 18.5744 18.0101 18.3205 18.3136C18.2052 18.4519 18.0605 18.5626 17.897 18.6379C17.7335 18.7132 17.5552 18.7511 17.3752 18.7488Z"
                    fill="white"
                  />
                </svg>
                <p className="text-white">{localStorage.getItem("nameUser")}</p>
              </a>
              <button onClick={signOut} className="bg-white p-3 rounded-lg">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.5625 2.8125H2.1875V17.8125H6.5625M13.4375 5.9375L17.8125 10.3125L13.4375 14.6875M7.1875 10.3125H17.8125"
                      stroke="#EF4444"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
            </div>
          </div>
          {/* start: Main */}
          <div className="pb-5">
            <div className="bg-white shadow-lg rounded-lg p-6">
              <div className="flex flex-row justify-between">
                <div className="flex items-center justify-center gap-3">
                  <h1 className="text-lg font-bold">Edit Profile Customer</h1>
                </div>
              </div>
              {/* start: Data Customer */}
              <div className="bg-gray-50 rounded-lg p-5 my-5">
                <div className="py-2">
                  <span className="font-medium text-lg">
                    Edit Data Customer
                  </span>
                </div>
                <div className="grid grid-cols-6 md:grid-cols-12 gap-5 mt-5">
                  <div className="col-span-6">
                    <span className="text-sm font-bold text-gray-400">
                      Customer Name
                    </span>
                    <div className="mt-3.5">
                      <input
                        type="text"
                        className="bg-white rounded-lg border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      />
                    </div>
                  </div>
                  <div className="col-span-6">
                    <span className="text-sm font-bold text-gray-400">
                      Phone
                    </span>
                    <div className="mt-3.5">
                      <input
                        type="text"
                        className="bg-white rounded-lg border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      />
                    </div>
                  </div>
                  <div className="col-span-6">
                    <span className="text-sm font-bold text-gray-400">
                      Email
                    </span>
                    <div className="mt-3.5">
                      <input
                        type="text"
                        className="bg-white rounded-lg border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      />
                    </div>
                  </div>
                  <div className="col-span-6">
                    <span className="text-sm font-bold text-gray-400">
                      Gender
                    </span>
                    <div className="mt-3.5">
                      <select
                        placeholder="Select Gender ( Male; Female )"
                        className="bg-white rounded-lg border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      >
                        <option hidden>Select gender</option>
                        <option>Male</option>
                        <option>Female</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-span-6">
                    <span className="text-sm font-bold text-gray-400">Age</span>
                    <div className="mt-3.5">
                      <input
                        type="number"
                        className="bg-white rounded-lg border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                        min={0}
                      />
                    </div>
                  </div>
                  <div className="col-span-6">
                    <span className="text-sm font-bold text-gray-400">
                      Martial Status
                    </span>
                    <div className="mt-3.5">
                      <select
                        placeholder="Select Martial Status ( Single; Married; Divorced )"
                        className="bg-white rounded-lg border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      >
                        <option hidden>Select Martial Status</option>
                        <option>Single</option>
                        <option>Married</option>
                        <option>Divorced</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-span-6">
                    <span className="text-sm font-bold text-gray-400">
                      Address
                    </span>
                    <div className="mt-3.5">
                      <input
                        type="text"
                        className="bg-white rounded-lg border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      />
                    </div>
                  </div>
                  <div className="col-span-6">
                    <span className="text-sm font-bold text-gray-400">
                      Subdistrict
                    </span>
                    <div className="mt-3.5">
                      <select
                        placeholder="Select Subdistrict"
                        className="bg-white rounded-lg border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      >
                        <option hidden>Select Subdistrict</option>
                        <option>Nusawungu</option>
                        <option>Purwokerto Timur</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-span-6">
                    <span className="text-sm font-bold text-gray-400">
                      District
                    </span>
                    <div className="mt-3.5">
                      <select
                        placeholder="Select District"
                        className="bg-white rounded-lg border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      >
                        <option hidden>Select District</option>
                        <option>Kabupaten Cilacap</option>
                        <option>Kabupaten Banyumas</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-span-6">
                    <span className="text-sm font-bold text-gray-400">
                      City
                    </span>
                    <div className="mt-3.5">
                      <select
                        placeholder="Select City"
                        className="bg-white rounded-lg border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      >
                        <option hidden>Select City</option>
                        <option>Jakarta Barat</option>
                        <option>Jakarta Selatan</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-span-6">
                    <span className="text-sm font-bold text-gray-400">
                      Province
                    </span>
                    <div className="mt-3.5">
                      <select
                        placeholder="Select Province"
                        className="bg-white rounded-lg border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      >
                        <option hidden>Select Province</option>
                        <option>Jawa Tengah</option>
                        <option>Jawa Barat</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-span-6">
                    <span className="text-sm font-bold text-gray-400">
                      Level
                    </span>
                    <div className="mt-3.5">
                      <select
                        placeholder="Select Level ( Repeat Order; Reseller; Customer Pasif )"
                        className="bg-white rounded-lg border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      >
                        <option hidden>Select Level</option>
                        <option>Repeat Order</option>
                        <option>Reseller</option>
                        <option>Customer Pasif</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              {/* end: Data Customer */}
              {/*bagin: Button Save */}
              <div className="flex flex-row justify-center md:justify-end gap-5 py-5">
                <a
                  href="/view-customer"
                  className="text-gray-400 rounded-lg bg-gray-100 hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-200 bg-gray-200 font-medium text-sm px-5 py-2.5 text-center"
                >
                  Cancel
                </a>
                <button
                  type="submit"
                  className="text-white rounded-lg bg-[#4FC29B] hover:bg-[#4FC29B] focus:ring-4 focus:outline-none focus:ring-[#4FC29B] font-medium text-sm px-5 py-2.5 text-center"
                >
                  Save Change
                </button>
              </div>
              {/*end: Button Save */}
            </div>
          </div>
          {/* end: Main */}
        </div>
      </div>
    </>
  );
}

export default EditProfileCustomer;
