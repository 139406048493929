import React,{ Component, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { getOmset, getRevenue, getDistrict, detailDistrict } from "../../../Network/Lib/dashboard";


// 
function CardSalesByDistrict() {
  const [districtData, setDistrictData] = useState([]);


  const fetchApi = async () => {
    const districtData = await getDistrict();
    console.log("district: ", districtData.data.data);
    setDistrictData(districtData.data.data);

  };

  useEffect(() => {
    fetchApi();
  }, []);
  return (
    <div className="p-6 max-w-md h-full bg-white rounded-lg shadow-md dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
      <div className="flex flex-row justify-between">
        <h1 className="font-bold text-lg">Sales By District</h1>
        <Link to="/SalesByDistrict">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.01001 2C4.08001 2 2.51001 3.57 2.51001 5.5C2.51001 7.08 3.57001 8.403 5.01001 8.837V15.997C5.00901 16.176 5.03701 17.778 6.18401 18.928C6.89201 19.64 7.84001 20 9.00001 20V22L13 19L9.00001 16V18C7.17701 18 7.01601 16.466 7.01001 16V8.837C8.45001 8.403 9.51001 7.08 9.51001 5.5C9.51001 3.57 7.93901 2 6.01001 2ZM6.01001 7C5.18301 7 4.51001 6.327 4.51001 5.5C4.51001 4.673 5.18301 4 6.01001 4C6.83701 4 7.51001 4.673 7.51001 5.5C7.51001 6.327 6.83701 7 6.01001 7ZM19.01 15.163V7.997C19.005 6.391 17.933 4 15 4V2L11 5L15 8V6C16.829 6 17.001 7.539 17.01 8V15.163C15.57 15.597 14.51 16.92 14.51 18.5C14.51 20.43 16.08 22 18.01 22C19.94 22 21.51 20.43 21.51 18.5C21.51 16.92 20.45 15.597 19.01 15.163ZM18.01 20C17.183 20 16.51 19.327 16.51 18.5C16.51 17.673 17.183 17 18.01 17C18.837 17 19.51 17.673 19.51 18.5C19.51 19.327 18.837 20 18.01 20Z"
              fill="#333333"
            />
          </svg>
        </Link>
      </div>
      <div>
        <div className="overflow-x-auto overflow-x-auto relative">
          <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            {/* <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                          <th scope="col" className="py-3 px-6">
                            Product name
                          </th>
                          <th scope="col" className="py-3 px-6">
                            Color
                          </th>
                          <th scope="col" className="py-3 px-6">
                            Category
                          </th>
                          <th scope="col" className="py-3 px-6">
                            Price
                          </th>
                        </tr>
                      </thead> */}
            <tbody>
                {districtData != null ? (
                    districtData?.map((data, i) => {
                    return [
                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                      <td className="p-4 whitespace-nowrap dark:text-white">
                        <div className="flex flex-col gap-1">
                          <p className="text-xs font-thin text-gray-400">District</p>
                          <p>{data.city}</p>
                        </div>
                      </td>
                      <td className="p-4">
                        <div className="flex flex-col gap-1">
                          <p className="text-xs font-thin text-gray-400">Sales</p>
                          <p>{data.totalSales}</p>
                        </div>
                      </td>
                      <td className="p-4">
                        <div className="flex flex-col gap-1">
                          <p className="text-xs font-thin text-gray-400">
                            Percentage
                          </p>
                          <p>{data.percent}%</p>
                        </div>
                      </td>
                    </tr>
                        ];
                      })
                    ) : (
                    
                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                     <td scope="row" colSpan={4} className="py-4 px-6 text-center bg-gray-50">
                        Data Not Found
                      </td>
                   </tr>
                  )}

              {/* <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                <td className="p-4 whitespace-nowrap dark:text-white">
                  <div className="flex flex-col gap-1">
                    <p className="text-xs font-thin text-gray-400">District</p>
                    <p>Pemalang</p>
                  </div>
                </td>
                <td className="p-4">
                  <div className="flex flex-col gap-1">
                    <p className="text-xs font-thin text-gray-400">Sales</p>
                    <p>10.000</p>
                  </div>
                </td>
                <td className="p-4">
                  <div className="flex flex-col gap-1">
                    <p className="text-xs font-thin text-gray-400">
                      Percentage
                    </p>
                    <p>40%</p>
                  </div>
                </td>
              </tr>
              <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                <td className="p-4 whitespace-nowrap dark:text-white">
                  <div className="flex flex-col gap-1">
                    <p className="text-xs font-thin text-gray-400">District</p>
                    <p>Banyumas</p>
                  </div>
                </td>
                <td className="p-4">
                  <div className="flex flex-col gap-1">
                    <p className="text-xs font-thin text-gray-400">Sales</p>
                    <p>10.000</p>
                  </div>
                </td>
                <td className="p-4">
                  <div className="flex flex-col gap-1">
                    <p className="text-xs font-thin text-gray-400">
                      Percentage
                    </p>
                    <p>40%</p>
                  </div>
                </td>
              </tr>
              <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                <td className="p-4 whitespace-nowrap dark:text-white">
                  <div className="flex flex-col gap-1">
                    <p className="text-xs font-thin text-gray-400">District</p>
                    <p>Brebes</p>
                  </div>
                </td>
                <td className="p-4">
                  <div className="flex flex-col gap-1">
                    <p className="text-xs font-thin text-gray-400">Sales</p>
                    <p>10.000</p>
                  </div>
                </td>
                <td className="p-4">
                  <div className="flex flex-col gap-1">
                    <p className="text-xs font-thin text-gray-400">
                      Percentage
                    </p>
                    <p>40%</p>
                  </div>
                </td>
              </tr>
              <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                <td className="p-4 whitespace-nowrap dark:text-white">
                  <div className="flex flex-col gap-1">
                    <p className="text-xs font-thin text-gray-400">District</p>
                    <p>Cilacap</p>
                  </div>
                </td>
                <td className="p-4">
                  <div className="flex flex-col gap-1">
                    <p className="text-xs font-thin text-gray-400">Sales</p>
                    <p>10.000</p>
                  </div>
                </td>
                <td className="p-4">
                  <div className="flex flex-col gap-1">
                    <p className="text-xs font-thin text-gray-400">
                      Percentage
                    </p>
                    <p>40%</p>
                  </div>
                </td>
              </tr>
              <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                <td className="p-4 whitespace-nowrap dark:text-white">
                  <div className="flex flex-col gap-1">
                    <p className="text-xs font-thin text-gray-400">District</p>
                    <p>Others</p>
                  </div>
                </td>
                <td className="p-4">
                  <div className="flex flex-col gap-1">
                    <p className="text-xs font-thin text-gray-400">Sales</p>
                    <p>10.000</p>
                  </div>
                </td>
                <td className="p-4">
                  <div className="flex flex-col gap-1">
                    <p className="text-xs font-thin text-gray-400">
                      Percentage
                    </p>
                    <p>40%</p>
                  </div>
                </td>
              </tr> */}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default CardSalesByDistrict;
