const totalDatabase = {
  labels: [
    "Jan",
    "Feb",
    "March",
    "April",
    "May",
    "June",
    "July",
    "Augst",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ],
  datasets: [
    {
      label: "Sudah Closing",
      backgroundColor: "#39F11B",
      borderWidth: 1,
      data: [65, 59, 80, 81, 56, 77],
    },
    {
      label: "Belum Closing",
      backgroundColor: "#F1E91B",
      borderWidth: 1,
      data: [65, 59, 80, 81, 56, 77],
    },
    {
      label: "Sudah Closing tapi pasif > 3 bulan",
      backgroundColor: "#F11B1B",
      borderWidth: 1,
      data: [65, 59, 80, 81, 56, 77],
    },
  ],
};

export default totalDatabase;
