const statusPaket = {
  labels: ["Sun", "Mon", "Tu", "Wed", "Thu", "Fri", "Stu", "Sun", "Mon", "Tu", "Wed", "Thu",],
  datasets: [
    {
      fill: true,
      label: "CRM TikTok",
      data: [65, 59, 80, 81, 56, 77, 44, 84, 100, 91, 10, 15],
      borderColor: "rgb(255, 255, 0)",
      backgroundColor: "rgba(255, 255, 78, 0.8)",
    },
  ],
};

export default statusPaket;
