import React, { useEffect, useState } from 'react'
import LogoCrm from "../../Assets/img/LogoCrm.png";
import { Link, useParams } from 'react-router-dom';
import { Box, Drawer } from '@mui/material';
import dataBlog from '../../Data/dataBlog';
import { getDetailBlog } from '../../Network/Lib/blog';
import { BASE_URL_A } from '../../config';
import {Parser} from 'html-to-react';
import {Helmet} from "react-helmet";



function DetailBlog() {
  const { slug } = useParams()
  const pathname = window.location.pathname;
  const [state, setState] = useState(false);
  const [data, setData] = useState([]);

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <ul className="flex flex-col p-2 mt-2 bg-white rounded-lg lg:flex-row lg:space-x-8 lg:mt-0 lg:text-sm lg:font-medium lg:border-0 lg:bg-white dark:bg-gray-800 lg:dark:bg-gray-900 dark:border-gray-700">
        <li>
          {pathname === "/" ? (
            <button
              className="block py-2 pr-4 pl-3 text-[#003049] rounded hover:bg-gray-100 lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent"
              aria-current="page"
            >
              Home
            </button>
          ) : (
            <button
              className="block py-2 pr-4 pl-3 text-[#A8A8A8] rounded hover:bg-gray-100 lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent"
              aria-current="page"
            >
              Home
            </button>
          )}
        </li>
        <li>
          {pathname === "/" ? (
            <button
              className="block py-2 pr-4 pl-3 text-[#003049] rounded hover:bg-gray-100 lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent"
              aria-current="page"
            >
              Testimoni
            </button>
          ) : (
            <button
              onClick=''
              className="block py-2 pr-4 pl-3 text-[#A8A8A8] rounded hover:bg-gray-100 lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent"
              aria-current="page"
            >
              Testimoni
            </button>
          )}
        </li>
        <li>
          {pathname === "/" ? (
            <button
              className="block py-2 pr-4 pl-3 text-[#003049] rounded hover:bg-gray-100 lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent"
              aria-current="page"
            >
              Harga
            </button>
          ) : (
            <button
              onClick=''
              className="block py-2 pr-4 pl-3 text-[#A8A8A8] rounded hover:bg-gray-100 lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent"
              aria-current="page"
            >
              Harga
            </button>
          )}
        </li>
        <li>
          {pathname === "/" ? (
            <button
              className="block py-2 pr-4 pl-3 text-[#003049] rounded hover:bg-gray-100 lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent"
              aria-current="page"
            >
              Hubungi Kami
            </button>
          ) : (
            <button
              onClick=''
              className="block py-2 pr-4 pl-3 text-[#A8A8A8] rounded hover:bg-gray-100 lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent"
              aria-current="page"
            >
              Hubungi Kami
            </button>
          )}
        </li>
        <li>
          {pathname === "/" ? (
            <button
              className="block py-2 pr-4 pl-3 text-[#003049] rounded hover:bg-gray-100 lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent"
              aria-current="page"
            >
              Mitra
            </button>
          ) : (
            <button
              onClick=''
              className="block py-2 pr-4 pl-3 text-[#A8A8A8] rounded hover:bg-gray-100 lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent"
              aria-current="page"
            >
              Mitra
            </button>
          )}
        </li>
        <li>
          {pathname === "/" ? (
            <button
              className="block py-2 pr-4 pl-3 text-[#003049] rounded hover:bg-gray-100 lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent"
              aria-current="page"
            >
              Blog
            </button>
          ) : (
            <button
              onClick=''
              className="block py-2 pr-4 pl-3 text-[#A8A8A8] rounded hover:bg-gray-100 lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent"
              aria-current="page"
            >
              Blog
            </button>
          )}
        </li>
        <div className="h-0.5 w-1/2 bg-gray-200"></div>

        {/* <li>
          {pathname === "/login" ? (
            <a
              href="/login"
              className="block py-2 pr-4 pl-3 text-[#003049] rounded hover:bg-gray-100 lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent"
              aria-current="page"
            >
              Login
            </a>
          ) : (
            <a
              href="/login"
              className="block py-2 pr-4 pl-3 text-[#A8A8A8] rounded hover:bg-gray-100 lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent"
              aria-current="page"
            >
              Login
            </a>
          )}
        </li> */}
        {/* <li>
          <Link
            to="/create-account"
            type="button"
            className="text-crm-2 font-semibold bg-[#54DAA1] px-5 hover:text-[#2DCE89] focus:ring-0 focus:ring-transparent rounded-lg text-sm py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
          >
            Sign Up
          </Link>
        </li> */}
        <li>
          <Link
            to="/login"
            type="button"
            className="flex items-center justify-between text-white font-semibold bg-crm-4 px-5 hover:text-[#2DCE89] focus:ring-0 focus:ring-transparent rounded-xl text-sm py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
          >
            Login
            {/* <img src={arrowRight}/> */}
          </Link>
        </li>
      </ul>
    </Box>
  );

  const getDetail = async () => {
    await getDetailBlog(slug).then((response) => {
      console.log('detail',response.data.data)
      setData(response.data.data)
    })
  }

  useEffect(() => {
    getDetail()
  }, [])
  return (
    <>
    <div className="application">
        <Helmet>
            <meta charSet="utf-8" />
            <title>{data.title}</title>
            <link rel="canonical" href="https://croya.id/" />
        </Helmet>
        ...
    </div>
      {/* Navbar */}
      <div className="fixed top-0 left-0 right-0 z-10">
        <nav className="bg-white lg:shadow-md shadow-md px-2 sm:px-20 py-2 rounded dark:bg-gray-900">
          <div className="flex flex-wrap justify-between items-center mx-5">
            <a to="#" className="flex items-center">
              {/* <img src={logo} className="w-20" alt="Logo" /> */}
              <img src={LogoCrm} className="w-[134px] h-[57px]" alt="Logo" />
            </a>
            <button
              data-collapse-toggle="navbar-default"
              type="button"
              className="inline-flex items-center p-2 ml-3 text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-0 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
              aria-controls="navbar-default"
              aria-expanded="false"
              onClick={toggleDrawer("right", true)}
            >
              <span className="sr-only">Open main menu</span>
              <svg
                className="w-6 h-6"
                aria-hidden="true"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </button>
            <Drawer
              anchor={"right"}
              open={state["right"]}
              onClose={toggleDrawer("right", false)}
            >
              {list("right")}
            </Drawer>
            <div
              className="hidden w-full lg:block lg:w-auto"
              id="navbar-default"
            >
              <ul className="flex flex-col items-center p-2 mt-2 bg-transparent rounded-lg lg:flex-row lg:space-x-2 lg:mt-0 lg:text-sm lg:font-medium dark:bg-gray-800 lg:dark:bg-gray-900 dark:border-gray-700">
                <li>
                  {pathname === "/#home" ? (
                    <a
                      href="/#home"
                      className="block text-ijo-def font-medium mx-5 hover:text-crm-4"
                      aria-current="page"
                    >
                      Home
                    </a>
                  ) : (
                    <a
                      href="/#home"
                      className="block text-crm-2 font-medium mx-5 hover:text-crm-4"
                      aria-current="page"
                    >
                      Home
                    </a>
                  )}
                </li>
                <li>
                  {pathname === "/#testimoni" ? (
                    <a
                      href="/#testimoni"
                      className="block text-ijo-def font-medium mx-5 hover:text-crm-4"
                      aria-current="page"
                    >
                      Testimoni
                    </a>
                  ) : (
                    <a
                      href="/#testimoni"
                      className="block text-crm-2 font-medium mx-5 hover:text-crm-4"
                      aria-current="page"
                    >
                      Testimoni
                    </a>
                  )}
                </li>
                <li>
                  {pathname === "/#hargaPaket" ? (
                    <a
                      href="/#hargaPaket"
                      className="block text-ijo-def font-medium mx-5 hover:text-crm-4"
                      aria-current="page"
                    >
                      Harga
                    </a>
                  ) : (
                    <a
                      href="/#hargaPaket"
                      className="block text-crm-2 font-medium mx-5 hover:text-crm-4"
                      aria-current="page"
                    >
                      Harga
                    </a>
                  )}
                </li>
                <li>
                  {pathname === "/#hubungiKami" ? (
                    <a
                      href="/#hubungiKami"
                      className="block text-ijo-def font-medium mx-5 hover:text-crm-4"
                      aria-current="page"
                    >
                      Hubungi Kami
                    </a>
                  ) : (
                    <a
                      href="/#hubungiKami"
                      className="block text-crm-2 font-medium mx-5 hover:text-crm-4"
                      aria-current="page"
                    >
                      Hubungi Kami
                    </a>
                  )}
                </li>
                <li>
                  {pathname === "/#mitra" ? (
                    <a
                      href="/#mitra"
                      className="block text-ijo-def font-medium mx-5 hover:text-crm-4"
                      aria-current="page"
                    >
                      Mitra
                    </a>
                  ) : (
                    <a
                      href="/#mitra"
                      className="block text-crm-2 font-medium mx-5 hover:text-crm-4"
                      aria-current="page"
                    >
                      Mitra
                    </a>
                  )}
                </li>
                <li>
                  {pathname === "/blog" ? (
                    <a
                      href="/blog"
                      className="block text-ijo-def font-medium mx-5 hover:text-crm-4"
                      aria-current="page"
                    >
                      Blog
                    </a>
                  ) : (
                    <a
                      href="/blog"
                      className="block text-crm-2 font-medium mx-5 hover:text-crm-4"
                      aria-current="page"
                    >
                      Blog
                    </a>
                  )}
                </li>
              </ul>
            </div>
            <div className="hidden w-full lg:block lg:w-auto">
              <Link
                to="/login"
                type="button"
                className="flex items-center gap-3 text-white font-medium ml-10 bg-gradient-to-tr from-[#2DB2CE] to-[#54DAA1] hover:text-white focus:ring-0 focus:ring-transparent rounded-full text-sm px-6 py-2.5 focus:outline-none"
              >
                Masuk
              </Link>
            </div>
          </div>
        </nav>
      </div>
      {/* Navbar */}
      {/* Main */}
      <div className='bg-white pt-[130px] pb-20 md:px-[70px] px-[35px]'>
        <h1 className='text-4xl font-bold text-center mb-11'>{data.title}</h1>
        <div className='space-y-2.5'>
          {/* Card */}
          <img src={`${BASE_URL_A}${data.image}`} className='w-full object-cover rounded-xl' />
          <p className='text-gray-500 text-xl'>{Parser().parse(data.content)}</p>
        </div>
      </div>
      {/* Main */}
    </>
  )
}

export default DetailBlog