import React, { useState } from "react";
import Delete from "../../../Assets/img/Delete-data.png";
import Restore from "../../../Assets/img/restore.png";

function CardArchive({ name, phone }) {
  const [showRestore, setShowRestore] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  return (
    <div className="shadow-md p-5 rounded w-full">
      <div className="flex flex-row justify-start items-center gap-3">
        {/* <img src={Profile} alt="profile" className="rounded-full w-24 h-24" /> */}
        <div className="flex flex-row gap-5">
          <div className="flex flex-col gap-2">
            <p className="font-semibold text-base">{name}</p>
            <p className="text-gray-400 text-sm font-medium">{phone}</p>
            <p className="text-orange-400 text-sm font-semibold">Archive</p>
            <div className="flex flex-row gap-3">
              <button
                className="flex flex-row text-white hover:text-white bg-orange-400 hover:bg-orange-600 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm text-center py-2 px-3 items-center"
                type="button"
                onClick={() => setShowRestore(true)}
              >
                <svg
                  width="15"
                  height="12"
                  viewBox="0 0 15 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.16699 0C6.57569 0 5.04957 0.632141 3.92435 1.75736C2.79913 2.88258 2.16699 4.4087 2.16699 6H0.166992L2.76033 8.59333L2.80699 8.68667L5.50033 6H3.50033C3.50033 3.42 5.58699 1.33333 8.16699 1.33333C10.747 1.33333 12.8337 3.42 12.8337 6C12.8337 8.58 10.747 10.6667 8.16699 10.6667C6.88033 10.6667 5.71366 10.14 4.87366 9.29333L3.92699 10.24C4.4826 10.7987 5.14332 11.2418 5.87103 11.5439C6.59875 11.846 7.37907 12.001 8.16699 12C9.75829 12 11.2844 11.3679 12.4096 10.2426C13.5349 9.11742 14.167 7.5913 14.167 6C14.167 4.4087 13.5349 2.88258 12.4096 1.75736C11.2844 0.632141 9.75829 2.37122e-08 8.16699 0ZM7.50033 3.33333V6.66667L10.3537 8.36L10.8337 7.55333L8.50033 6.16667V3.33333H7.50033Z"
                    fill="white"
                  />
                </svg>
                <span className="ml-2">Restore</span>
              </button>
              <button
                className="flex flex-row text-white hover:text-white bg-gray-900 hover:bg-black focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm text-center py-2 px-3 items-center"
                type="button"
                onClick={() => setShowDelete(true)}
              >
                <svg
                  width="12"
                  height="14"
                  viewBox="0 0 12 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.625 1.875H3.5C3.56875 1.875 3.625 1.81875 3.625 1.75V1.875H8.375V1.75C8.375 1.81875 8.43125 1.875 8.5 1.875H8.375V3H9.5V1.75C9.5 1.19844 9.05156 0.75 8.5 0.75H3.5C2.94844 0.75 2.5 1.19844 2.5 1.75V3H3.625V1.875ZM11.5 3H0.5C0.223437 3 0 3.22344 0 3.5V4C0 4.06875 0.05625 4.125 0.125 4.125H1.06875L1.45469 12.2969C1.47969 12.8297 1.92031 13.25 2.45312 13.25H9.54688C10.0813 13.25 10.5203 12.8313 10.5453 12.2969L10.9312 4.125H11.875C11.9438 4.125 12 4.06875 12 4V3.5C12 3.22344 11.7766 3 11.5 3ZM9.42656 12.125H2.57344L2.19531 4.125H9.80469L9.42656 12.125Z"
                    fill="white"
                  />
                </svg>

                <span className="ml-2">Delete</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      {showRestore ? (
        <>
          <div className="bg-black bg-opacity-75 overflow-y-auto overflow-x-hidden fixed flex justify-center top-0 left-0 right-0 bottom-0 z-50 w-full md:inset-0 md:h-full">
            {/* <div className="relative w-full max-w-2xl h-full md:h-auto"> */}
            {/* ini yang ditambahin */}
            <div className="fixed w-full max-w-xl md:h-auto mt-40">
              {/* ini yang ditambahin */}
              <div className="relative bg-[#F5F8FA] rounded-lg shadow dark:bg-gray-700">
                <div className="p-6 space-y-6 items-center">
                  <div className="flex flex-row justify-between items-start gap-5">
                    <div className="flex flex-row gap-5">
                      <img src={Restore} className="w-100 object-cover" />
                      <div className="flex flex-col">
                        <h1 className="font-bold">Restore WhatsApp?</h1>
                        <p className="font-semibold text-gray-400">
                          WhatsApp will be move to CS CRM
                        </p>
                        <div className="mt-10 flex flex-row justify-start gap-3">
                          <button className="text-sm py-2 px-5 rounded-xl text-white bg-green-400 hover:bg-green-500 font-semibold">
                            Yes
                          </button>
                          <button
                            className="text-sm px-3 rounded-xl text-gray-400 hover:text-gray-600 bg-gray-100 hover:bg-gray-200 font-semibold"
                            onClick={() => setShowRestore(false)}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                    <button
                      type="button"
                      className="bg-transparent hover:bg-gray-100 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                      onClick={() => setShowRestore(false)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="#A1A5B7"
                        className="bi bi-x-circle"
                        viewBox="0 0 16 16"
                      >
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
      {showDelete ? (
        <>
          <div className="bg-black bg-opacity-75 overflow-y-auto overflow-x-hidden fixed flex justify-center top-0 left-0 right-0 bottom-0 z-50 w-full md:inset-0 md:h-full">
            {/* <div className="relative w-full max-w-2xl h-full md:h-auto"> */}
            {/* ini yang ditambahin */}
            <div className="fixed w-full max-w-xl md:h-auto mt-40">
              {/* ini yang ditambahin */}
              <div className="relative bg-[#F5F8FA] rounded-lg shadow dark:bg-gray-700">
                <div className="p-6 space-y-6 items-center">
                  <div className="flex flex-row justify-between items-start gap-5">
                    <div className="flex flex-row gap-5">
                      <img src={Delete} className="w-100 object-cover" />
                      <div className="flex flex-col">
                        <h1 className="font-bold">Delete WhatsApp?</h1>
                        <p className="font-semibold text-gray-400">
                          WhatsApp will be permanently deleted
                        </p>
                        <div className="mt-10 flex flex-row justify-start gap-3">
                          <button className="text-sm py-2 px-5 rounded-xl text-white bg-green-400 hover:bg-green-500 font-semibold">
                            Yes
                          </button>
                          <button
                            className="text-sm px-3 rounded-xl text-gray-400 hover:text-gray-600 bg-gray-100 hover:bg-gray-200 font-semibold"
                            onClick={() => setShowDelete(false)}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                    <button
                      type="button"
                      className="bg-transparent hover:bg-gray-100 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                      onClick={() => setShowDelete(false)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="#A1A5B7"
                        className="bi bi-x-circle"
                        viewBox="0 0 16 16"
                      >
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
}

export default CardArchive;
