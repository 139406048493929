import React, { useState, useEffect } from "react";
import Profile from "../../../Assets/img/Profile.jpg";

function TableAllCustomer() {
  return (
    <div className="">
      <div className="overflow-x-auto md:h-80">
        <table className="w-full text-sm text-left text-gray-500">
          <thead className="text-sm text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3 w-32">
                Customer
              </th>
              <th scope="col" className="px-6 py-3 w-32">
                No. WhatsApp
              </th>
              <th scope="col" className="px-6 py-3 w-32">
                Email
              </th>
              <th scope="col" className="px-6 py-3 w-32">
                Level
              </th>
              <th scope="col" className="px-6 py-3 w-32">
                CS CRM
              </th>
              <th scope="col" className="px-6 py-3 w-32">
                CS Akuisisi
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="bg-white border-b">
              <td className="px-6 py-4">
                <div className="flex flex-row gap-3">
                  <a href="/crm-chat" className="text-green-400">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-whatsapp"
                      viewBox="0 0 16 16"
                    >
                      <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z" />
                    </svg>
                  </a>
                  <a href="/crm-chat" className="text-green-400">
                    <svg
                      width="22"
                      height="16"
                      viewBox="0 0 25 19"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_1712_414)">
                        <path
                          d="M6.1655 18.0663V8.76132L3.28972 6.12128L0.710938 4.65625V16.4242C0.710938 17.3328 1.44453 18.0663 2.34734 18.0663H6.1655Z"
                          fill="#4285F4"
                        />
                        <path
                          d="M19.2559 18.0662H23.074C23.9795 18.0662 24.7104 17.33 24.7104 16.4241V4.65625L21.7895 6.33427L19.2559 8.76122V18.0662Z"
                          fill="#34A853"
                        />
                        <path
                          d="M6.16475 8.76196L5.77344 5.12623L6.16475 1.64648L12.7102 6.57264L19.2556 1.64648L19.6933 4.93836L19.2556 8.76196L12.7102 13.6881L6.16475 8.76196Z"
                          fill="#EA4335"
                        />
                        <path
                          d="M19.2559 1.64666V8.76214L24.7104 4.65707V2.46766C24.7104 0.437045 22.4004 -0.720551 20.7832 0.497253L19.2559 1.64666Z"
                          fill="#FBBC04"
                        />
                        <path
                          d="M0.710938 4.65711L3.21959 6.54521L6.1655 8.76218V1.64671L4.63812 0.497296C3.01812 -0.720602 0.710938 0.437088 0.710938 2.46761V4.65702V4.65711Z"
                          fill="#C5221F"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_1712_414">
                          <rect
                            width="24"
                            height="18.1565"
                            fill="white"
                            transform="translate(0.710938)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </a>
                  <a href="/overview-customer">Abdan S</a>
                </div>
              </td>
              <td className="px-6 py-4">62821213131313</td>
              <td className="px-6 py-4">user@gmail.com</td>
              <td className="px-6 py-4">New Customer</td>
              <td className="px-6 py-4">Skylar Workman</td>
              <td className="px-6 py-4">Lindsey Botosh</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default TableAllCustomer;
