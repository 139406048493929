import React from "react";
import MultiselectReact from "./MultiSelectSearch";

class RepeaterPRoduct extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formValues: [{ button: "", action: "" }],
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(i, e) {
    let formValues = this.state.formValues;
    formValues[i][e.target.name] = e.target.value;
    this.setState({ formValues });
  }

  addFormFields() {
    this.setState({
      formValues: [...this.state.formValues, { button: "", action: "" }],
    });
  }

  removeFormFields(i) {
    let formValues = this.state.formValues;
    formValues.splice(i, 1);
    this.setState({ formValues });
  }

  handleSubmit(event) {
    event.preventDefault();
    alert(JSON.stringify(this.state.formValues));
  }

  render() {
    return (
      <>
        <form onSubmit={this.handleSubmit}>
          {this.state.formValues.map((element, index) => (
            <>
            <div className="grid grid-cols-6 gap-2">
              <div className="col-span-5 grid grid-cols-2 gap-5 mt-3" key={index}>
                <div className="">
                  <span className="text-sm font-bold text-gray-400">
                    Product
                  </span>
                  <div className="mt-2.5">
                    <select
                      placeholder="Select Gender ( Male; Female )"
                      className="bg-white rounded-lg border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    >
                      <option selected>Select Product</option>
                      <option>Select Product</option>
                      <option>Select Product</option>
                    </select>
                  </div>
                </div>
                <div className="">
                  <span className="text-sm font-bold text-gray-400">
                    Quantitiy
                  </span>
                  <div className="mt-2.5">
                    <input
                      type="text"
                      className="bg-white rounded-lg border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    />
                  </div>
                </div>
              </div>
              <div className="col-span-1">
                {index ? (
                  <>
                    <button
                      type="button"
                      className="bg-red-500 hover:bg-red-700 text-white font-bold px-2 mt-14 rounded"
                      onClick={() => this.removeFormFields(index)}
                    >
                      X
                    </button>
                  </>
                ) : null}
              </div>
              </div>
            </>
          ))}
          <div className="button-section mt-5">
            <button
              className="bg-[#E7FFF7] text-[#4FC29B] rounded py-2 px-3 text-dark"
              type="button"
              onClick={() => this.addFormFields()}
            >
              + Add Product
            </button>
          </div>
        </form>
      </>
    );
  }
}
export default RepeaterPRoduct;
