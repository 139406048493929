import React, { useState, Fragment } from "react";
import { Link } from "react-router-dom";
import { Menu, Transition } from "@headlessui/react";
import { UserAddIcon, PlusIcon } from "@heroicons/react/solid";
import Profile from "../../../Assets/img/Profile.jpg";
import { createProduct } from "../../../Network/Lib/product";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { uploadCustomer } from "../../../Network/Lib/customer";
import template from "../../../Assets/file/template_customer.xlsx";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function AddCustomerDashboard({onFinishCreate}) {
  const [showAddImportCustomer, setShowAddImportCustomer] = useState(false);
  const [customerFile, setCustomerFile] = useState();
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const onSubmitAddCustomer = async () => {
    var formData = new FormData();
    formData.append("file", customerFile);
    await uploadCustomer(formData)
      .then((response) => {
        setShowAddImportCustomer(false);
        console.log(response);
        onFinishCreate();
      })
      .catch((e) => {
        console.log(e.response);
      });
  };

  return (
    <>
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button className="inline-flex justify-center w-full rounded-md shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-400 hover:bg-gray-100 focus:outline-none">
            <UserAddIcon className="mr-4 h-5 w-5" aria-hidden="true" />
            Add Customer
          </Menu.Button>
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="py-1">
              <Menu.Item>
                {({ active }) => (
                  <Link
                    to="/addSingleCustumer"
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "block px-4 py-2 text-sm"
                    )}
                  >
                    <div className="inline-flex justify-center content-center text-gray-400">
                      <PlusIcon className="mr-4 h-5 w-5" aria-hidden="true" />
                      Add Single Cust.
                    </div>
                  </Link>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <Link
                    to="#"
                    onClick={() => setShowAddImportCustomer(true)}
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "block px-4 py-2 text-sm"
                    )}
                  >
                    <div className="inline-flex justify-center content-center text-gray-400">
                      <PlusIcon className="mr-4 h-5 w-5" aria-hidden="true" />
                      Add Multiple Cust.
                    </div>
                  </Link>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
      {showAddImportCustomer ? (
        <>
          <div className="bg-black bg-opacity-75 overflow-y-auto overflow-x-hidden fixed flex justify-center top-0 left-0 right-0 bottom-0 z-50 w-full md:inset-0 h-modal md:h-full">
            {/* <div className="relative w-full max-w-2xl h-full md:h-auto"> */}
            {/* ini yang ditambahin */}
            <div className="fixed w-full max-w-3xl md:h-auto mt-10">
              {/* ini yang ditambahin */}
              <div className="relative bg-[#F5F8FA] rounded-lg shadow dark:bg-gray-700">
                <div className="flex justify-between items-start p-4 rounded-t border-b dark:border-gray-600">
                  <h3 className="text-xl font-semibold dark:text-white">
                    Add Multiple Customer
                  </h3>
                  <button
                    type="button"
                    className="bg-transparent hover:bg-gray-100 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                    onClick={() => setShowAddImportCustomer(false)}
                  >
                    <svg
                      className="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </button>
                </div>
                {/* <form
                  id="uploadForm"
                  action={onSubmitAddCustomer}
                  role="form"
                  method="post"
                  enctype="multipart/form-data"
                > */}
                <div className="p-6 space-y-6 items-center">
                  <div className="px-10">
                    <input
                      id="fileCustomer"
                      type="file"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full"
                      onChange={(e) => {
                        setCustomerFile(e.target.files[0]);
                      }}
                    />
                  </div>
                  <div className="px-10 text-gray-800">
                    <p>
                      1.{" "}
                      <a
                        href={template}
                        download
                        className="text-blue-400 hover:text-blue-500 font-bold"
                      >
                        Download Template.
                      </a>
                    </p>
                    <p>
                      2. Prepare a CSV or Excel file of your template using our
                      template.
                    </p>
                    <p>
                      3. Upload the CSV or XLS file from step 1 you’ve filled to
                      adding multiple template to list.
                    </p>
                  </div>
                  <div className="mt-10 flex flex-row justify-end">
                    <button
                      className="px-3 rounded-xl text-gray-400 hover:text-gray-600 font-semibold"
                      onClick={() => setShowAddImportCustomer(false)}
                    >
                      Cancel
                    </button>
                    <button
                      className="py-2 px-3 rounded-md text-white bg-green-400 hover:bg-green-500 font-semibold ml-2"
                      onClick={onSubmitAddCustomer}
                    >
                      Upload File
                    </button>
                    {/* <input type="submit" value="Upload" className="py-2 px-3 rounded-md text-white bg-green-400 hover:bg-green-500 font-semibold ml-2"/> */}
                  </div>
                </div>
                {/* </form> */}
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
}

export default AddCustomerDashboard;
