import React, { useState } from "react";
import Navbar from "../Components/Navbar/Navbar";
import AddProduct from "../Components/Partials/Modal/AddProduct";
import CardLeveling from "../Components/Partials/Card/CardLeveling";
import TableLeveling from "../Components/Partials/Table/TableLeveling";
import AddCustomer from "../Components/Partials/Modal/AddCustomer";
import BroadcastLeveling from "../Components/Partials/Modal/BroadcastLeveling";
import TableAllCustomer from "../Components/Partials/Table/TableAllCustomer";

function AllCustomer() {
  const [count_lead, setCountLead] = useState("");
  return (
    <div>
      <Navbar />
      <div className="px-10 py-20">
        <div className="p-10 space-y-6 shadow-lg rounded-md">
          <div className="flex flex-col md:flex-row justify-between items-start md:items-center">
            <div className="flex flex-col">
              <h1 className="text-xl font-bold">All Customer</h1>
              <h1 className="text-gray-500 font-semibold">10.000 data</h1>
            </div>
            <div className="flex flex-col md:flex-row items-start md:items-center gap-2">
              <AddCustomer />
              <div className="flex flex-row rounded-lg bg-gray-50 border border-gray-300 mt-1">
                <input
                  type="text"
                  id="table-search"
                  className="appearance-none bg-transparent border-none w-full text-gray-700 leading-tight focus:outline-none focus:ring-transparent w-64"
                  placeholder="Search"
                />
                <div className="flex items-center pr-3 pointer-events-none">
                  <svg
                    className="w-5 h-5 text-gray-500 dark:text-gray-400"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </div>
              </div>
            </div>
          </div>
          <TableAllCustomer />
        </div>
      </div>
    </div>
  );
}

export default AllCustomer;
