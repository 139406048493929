import React, { useState, Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { BadgeCheckIcon, PlusIcon } from "@heroicons/react/solid";
import Profile from "../../../Assets/img/Profile.jpg";
import { createProduct } from "../../../Network/Lib/product";
import { useNavigate } from "react-router-dom";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function CreatePromo() {
  const [showSinglePromo, setShowSinglePromo] = useState(false);

  const [productName, setProductName] = useState("");
  const [productCode, setProductCode] = useState("");
  const [productPrice, setProductPrice] = useState(0);

  const navigate = useNavigate();

  const onSubmitAddProduct = async () => {
    const product = {
      name: productName,
      code: productCode,
      price: productPrice,
      image: "this image",
    };

    await createProduct(product)
      .then((response) => {
        setProductName("");
        setProductCode("");
        setProductPrice(0);
        navigate("/product");
      })
      .catch((e) => {
        console.log(e.response);
      });

    // console.log(product)
  };

  return (
    <>
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button className="inline-flex justify-center w-full rounded-md shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-400 hover:bg-gray-100 focus:outline-none">
            <BadgeCheckIcon className="mr-2 h-5 w-5" aria-hidden="true" />
            Create Promo
          </Menu.Button>
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="py-1">
              <Menu.Item>
                {({ active }) => (
                  <a
                    href="#"
                    onClick={() => setShowSinglePromo(true)}
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "block px-4 py-2 text-sm"
                    )}
                  >
                    <div className="inline-flex justify-center content-center text-gray-400">
                      Create Single Promo
                    </div>
                  </a>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <a
                    href="#"
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "block px-4 py-2 text-sm"
                    )}
                  >
                    <div className="inline-flex justify-center content-center text-gray-400">
                      Create Multipple Promo
                    </div>
                  </a>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
      {showSinglePromo ? (
        <>
          <div className="bg-black bg-opacity-75 overflow-y-auto overflow-x-hidden fixed flex justify-center top-0 left-0 right-0 bottom-0 z-50 w-full md:inset-0 h-modal md:h-full">
            {/* <div className="relative w-full max-w-2xl h-full md:h-auto"> */}
            {/* ini yang ditambahin */}
            <div className="fixed inset-y-0 md:right-0 z-50 w-full max-w-2xl h-full md:h-auto overflow-y-auto md:w-1/3">
              {/* ini yang ditambahin */}
              <div className="relative bg-white min-h-screen rounded-lg shadow dark:bg-gray-700">
                <div className="bg-green-400 flex justify-between items-start p-4 rounded-t border-b dark:border-gray-600">
                  <h3 className="text-xl font-semibold text-white dark:text-white">
                    Ceate Single Promotion
                  </h3>
                  <button
                    type="button"
                    className="text-white bg-transparent hover:bg-green-600 hover:text-white rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                    onClick={() => setShowSinglePromo(false)}
                  >
                    <svg
                      className="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </button>
                </div>
                <div className="p-6 space-y-6 items-center">
                  <div className="">
                    <label
                      className="block tracking-wide text-gray-700 text-sm font-bold mb-2"
                      for="grid-state"
                    >
                      Team
                    </label>
                    <div className="relative">
                      <select
                        className="block appearance-none w-full bg-gray-100 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="grid-state"
                      >
                        <option hidden>Select Team</option>
                        <option>Team 1</option>
                        <option>Team 2</option>
                      </select>
                      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"></div>
                    </div>
                  </div>
                  <div className="">
                    <label
                      className="block tracking-wide text-gray-700 text-sm font-bold mb-2"
                      for="grid-state"
                    >
                      Promo Name
                    </label>
                    <input
                      type="text"
                      className="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full"
                      placeholder=""
                    />
                  </div>
                  <div className="">
                    <label
                      className="block tracking-wide text-gray-700 text-sm font-bold mb-2"
                      for="grid-state"
                    >
                      Promo Terms
                    </label>
                    <textarea
                      className="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full"
                      placeholder=""
                    ></textarea>
                  </div>
                  <div className="">
                    <label
                      className="block tracking-wide text-gray-700 text-sm font-bold mb-2"
                      for="grid-state"
                    >
                      Nominal Promo
                    </label>
                    <input
                      type="text"
                      className="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full"
                      placeholder=""
                    />
                  </div>
                  <div className="">
                    <label
                      className="block tracking-wide text-gray-700 text-sm font-bold mb-2"
                      for="grid-state"
                    >
                      Product
                    </label>
                    <div className="relative">
                      <select
                        className="block appearance-none w-full bg-gray-100 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="grid-state"
                      >
                        <option hidden>Select Product</option>
                        <option>Product 1</option>
                        <option>Product 2</option>
                      </select>
                      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"></div>
                    </div>
                  </div>
                  <div className="">
                    <label
                      className="block tracking-wide text-gray-700 text-sm font-bold mb-2"
                      for="grid-state"
                    >
                      Min. Purchase
                    </label>
                    <input
                      type="text"
                      className="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full"
                      placeholder=""
                    />
                  </div>
                  <div className="">
                    <label
                      className="block tracking-wide text-gray-700 text-sm font-bold mb-2"
                      for="grid-state"
                    >
                      TAG
                    </label>
                    <input
                      type="text"
                      className="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full"
                      placeholder=""
                    />
                  </div>
                  <div className="">
                    <label
                      className="block tracking-wide text-gray-700 text-sm font-bold mb-2"
                      for="grid-state"
                    >
                      Timeline
                    </label>
                    <input
                      type="text"
                      className="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full"
                      placeholder=""
                    />
                  </div>
                  <div className="">
                    <label
                      className="block tracking-wide text-gray-700 text-sm font-bold mb-2"
                      for="grid-state"
                    >
                      Promo Unit Price
                    </label>
                    <input
                      type="text"
                      className="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full"
                      placeholder=""
                    />
                  </div>
                  <div className="mt-10 flex flex-row justify-end">
                    <button
                      className="px-3 rounded-xl text-gray-400 hover:text-gray-600 font-semibold"
                      onClick={() => setShowSinglePromo(false)}
                    >
                      Cancel
                    </button>
                    <button className="py-2 px-3 rounded-xl text-green-400 hover:text-green-600 font-semibold ml-2">
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
}

export default CreatePromo;
