import React, { Component, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Sidebar from "../../Components/Sidebar/Sidebar";
import "aos/dist/aos.css";
import AddProduct from "../../Components/Partials/Modal/AddProduct";
import people from "../../Assets/img/product.png";
import { PencilIcon, TrashIcon } from "@heroicons/react/solid";
import Delete from "../../Assets/img/Delete-data.png";
import { getProducts } from "../../Network/Lib/boProduct";
import { getAllProduct, deleteProduct } from "../../Network/Lib/product";
import ChartRevenue from "../../Components/Partials/Chart/New/ChartRevenue";
import DetailChartRevenue from "../../Components/Partials/Chart/New/DetailChartRevenue";
import { logout } from "../../Network/Lib/auth";
import { detailRevenue, getOmsetProduct, getRevenue } from "../../Network/Lib/dashboard";

function Revenue() {
  const navigate = useNavigate();
  const [navSideBar, setNavSideBar] = useState(false);

  const [showDelete, setShowDelete] = useState(false);
  const [showEditProduct, setShowEditProduct] = useState(false);
  const [showImage, setShowImage] = useState(false);
  const [imageUrl, setImageUrl] = useState("");

  const [bmxProducts, setBmxProducts] = useState([]);
  const [crmProducts, setCrmProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState("daily");

  const [product, setProduct] = useState([]);
  const [omsetProducts, setOmsetProducts] = useState([]);
  const [precetanceProducts, setPrecetanceProducts] = useState([]);

  const [chekProduct, setChekProduct] = useState(false);

  // const handleOnChange = () => {
  //   setChekProduct(!chekProduct);
  // };

  const [selectedCheck, setSelectedCheck] = useState([]);

  useEffect(() => {
    fetchApi();
    // selectProduk();
  }, []);

  const onFiltereProduct = (data) => {
    setProduct(data);
  };



  const fetchApi = async () => {
    const product = await getOmsetProduct();
    console.log('product', product.data.data);
    setProduct(product.data.data);
  };

  const selectProduk = async () => {
    const product = await getOmsetProduct();
    // setOmsetProducts(product.data.data);
    console.log('product', selectedProduct);
  };

  const getProductRevenue = async (selected_filter, selected_product) => {
    const productOmset = await detailRevenue(selected_filter, selected_product)
    console.log(productOmset.data.data, 'detailRevenue')
    setOmsetProducts(productOmset.data.data)
    setPrecetanceProducts(productOmset.data)
    console.log(productOmset.data, 'pre')
  } 

  useEffect(() => {
    // console.log(selectedFilter, "ue-filter");
    // console.log(selectedProduct, "ue-product");
    if(selectedProduct.length > 0) {
      getProductRevenue(selectedFilter, selectedProduct.join(','));
    }else {
      getProductRevenue(selectedFilter, "null");
    }

  }, [selectedFilter, selectedProduct])

  const signOut = () => {
    logout();
    navigate("/login");
  };

  return (
    <>
      <div className="bg-gradient-to-r from-[#54DAA1] to-[#2DCE89]">
        <div className="relative">
          <aside
            className={
              navSideBar === true
                ? "w-24 h-screen p-5 fixed top-0 left-0 right-0 z-10"
                : "w-3/12 h-screen p-5 fixed top-0 left-0 right-0 z-10"
            }
            aria-label="Sidebar"
          >
            <button
              className="absolute top-72 right-1 w-10 h-10 bg-gradient-to-r from-[#54DAA1] to-[#2DCE89] rounded-full shadow-lg flex justify-center items-center"
              onClick={() => setNavSideBar(!navSideBar)}
            >
              {navSideBar ? (
                <svg
                  width="9"
                  height="16"
                  viewBox="0 0 9 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 14.3333L7.66667 7.66668L1 1.00001"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              ) : (
                <svg
                  width="10"
                  height="16"
                  viewBox="0 0 10 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.33325 14.6667L1.66659 7.99999L8.33325 1.33332"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              )}
            </button>
            <Sidebar navSideBar={navSideBar} />
          </aside>
        </div>
        <div
          className={
            navSideBar === true
              ? "ml-24 h-72 w-11/12 p-5 space-y-5"
              : "ml-auto h-72 w-9/12 p-5 space-y-5"
          }
        >
          {/* Header */}
          <div className="flex flex-row justify-between items-center">
            <div className="text-white space-y-2">
              <div className="flex flex-row space-x-3">
                <p className="text-sm">Pages</p>
                <p className="text-sm">/</p>
                <Link to="/Dashboard" className="text-sm">
                  Dashboard
                </Link>
                <p className="text-sm">/</p>
                <Link to="#" className="text-sm">
                  Revenue
                </Link>
              </div>
              <p className="font-semibold">Revenue</p>
            </div>
            <div className="flex flex-row items-center gap-5">
              <Link to="#" className="flex items-center gap-2">
                <svg
                  width="21"
                  height="20"
                  viewBox="0 0 21 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.4938 2.52235C12.7336 1.70165 11.6719 1.24969 10.5 1.24969C9.32191 1.24969 8.25668 1.69891 7.50004 2.51454C6.73519 3.33915 6.36254 4.45985 6.45004 5.67001C6.62348 8.05751 8.44027 9.99969 10.5 9.99969C12.5598 9.99969 14.3735 8.0579 14.5496 5.67079C14.6383 4.47157 14.2633 3.35321 13.4938 2.52235Z"
                    fill="white"
                  />
                  <path
                    d="M17.3752 18.7488H3.62522C3.44525 18.7511 3.26701 18.7133 3.10349 18.6381C2.93997 18.5629 2.79526 18.4522 2.67991 18.314C2.426 18.0105 2.32366 17.596 2.39944 17.1769C2.72913 15.348 3.75804 13.8117 5.37522 12.7332C6.81194 11.7757 8.63186 11.2488 10.5002 11.2488C12.3686 11.2488 14.1885 11.7761 15.6252 12.7332C17.2424 13.8113 18.2713 15.3476 18.601 17.1765C18.6768 17.5957 18.5744 18.0101 18.3205 18.3136C18.2052 18.4519 18.0605 18.5626 17.897 18.6379C17.7335 18.7132 17.5552 18.7511 17.3752 18.7488Z"
                    fill="white"
                  />
                </svg>
                <p className="text-white">{localStorage.getItem("nameUser")}</p>
              </Link>
              <button onClick={signOut} className="bg-white p-3 rounded-lg">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.5625 2.8125H2.1875V17.8125H6.5625M13.4375 5.9375L17.8125 10.3125L13.4375 14.6875M7.1875 10.3125H17.8125"
                    stroke="#EF4444"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            </div>
          </div>

          {/* start: Main */}
          <div className="pb-5 space-y-5">
            <div className="bg-white shadow-lg rounded-lg p-6">
              <div className="flex flex-col md:flex-row justify-between items-start md:items-center gap-3">
                <h1 className="text-lg font-semibold">Product</h1>
              </div>
              <div className="mt-5">
                <div className="overflow-x-auto h-72">
                  <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-600 uppercase dark:text-gray-400">
                      <tr>
                        <th scope="col" className="py-3 px-6">
                          product
                        </th>
                        <th scope="col" className="py-3 px-6">
                          Code
                        </th>
                        <th scope="col" className="py-3 px-6">
                          price
                        </th>
                        <th scope="col" className="py-3 px-6">
                          income
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {product != null ? (
                        product?.map((data, i) => {
                          return [
                            <tr className="bg-white" key={data.productId}>
                              <td scope="row" className="py-4 px-6">
                                <div className="flex flex-row gap-5 items-center">
                                  <input
                                    name={data.productId}
                                    type="checkbox"
                                    value={data.productId}
                                    // checked={chekProduct}
                                    onChange={async (e) => {
                                      let currentSelect = selectedProduct;
                                      if(e.target.checked) {
                                        currentSelect.push(e.target.name);
                                      }else {
                                        currentSelect = currentSelect.filter((data) => data != e.target.name);
                                      }
                                      let newSelect = [...currentSelect];
                                      setSelectedProduct(newSelect);
                                    }} 
                                    // id={data.id}
                                    className="w-5 h-5 text-blue-600 bg-gray-100 rounded-full border-gray-300 focus:ring-white dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                  />
                                  <img
                                    src={data.productImage}
                                    className="w-12 h-12"
                                    alt={data.productName}
                                  />
                                  <p>{data.productName}</p>
                                </div>
                              </td>
                              <td className="py-4 px-6">{data.productCode}</td>
                              <td className="py-4 px-6">
                                Rp.{" "}
                                {data.productPrice
                                  ? parseInt(data.productPrice).toLocaleString()
                                  : 0}
                              </td>
                              <td className="py-4 px-6">
                                Rp{" "}
                                {data.totalOmset != 0
                                  ? parseInt(data.totalOmset).toLocaleString()
                                  : 0}
                              </td>
                            </tr>,
                          ];
                        })
                      ) : (
                        <tr className="bg-white">
                          <td scope="row" colSpan={4} className="py-4 px-6 text-center bg-gray-50">
                            Data Not Found
                          </td>
                        </tr>
                      )}

                      {/* <tr className="bg-white">
                        <td scope="row" className="py-4 px-6">
                          <div className="flex flex-row gap-5 items-center">
                            <input
                              type="checkbox"
                              value=""
                              className="w-5 h-5 text-blue-600 bg-gray-100 rounded-full border-gray-300 focus:ring-white dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            />
                            <img
                              src={people}
                              className="w-12 h-12"
                              alt="product"
                            />
                            <p>gizidat</p>
                          </div>
                        </td>
                        <td className="py-4 px-6">GEN-1</td>
                        <td className="py-4 px-6 uppercase">Rp. 130.000</td>
                        <td className="py-4 px-6">Rp 1.000.000</td>
                      </tr>
                      <tr className="bg-white">
                        <td scope="row" className="py-4 px-6">
                          <div className="flex flex-row gap-5 items-center">
                            <input
                              type="checkbox"
                              value=""
                              className="w-5 h-5 text-blue-600 bg-gray-100 rounded-full border-gray-300 focus:ring-white dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            />
                            <img
                              src={people}
                              className="w-12 h-12"
                              alt="product"
                            />
                            <p>gizidat</p>
                          </div>
                        </td>
                        <td className="py-4 px-6">GEN-1</td>
                        <td className="py-4 px-6 uppercase">Rp. 130.000</td>
                        <td className="py-4 px-6">Rp 1.000.000</td>
                      </tr>
                      <tr className="bg-white">
                        <td scope="row" className="py-4 px-6">
                          <div className="flex flex-row gap-5 items-center">
                            <input
                              type="checkbox"
                              value=""
                              className="w-5 h-5 text-blue-600 bg-gray-100 rounded-full border-gray-300 focus:ring-white dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            />
                            <img
                              src={people}
                              className="w-12 h-12"
                              alt="product"
                            />
                            <p>gizidat</p>
                          </div>
                        </td>
                        <td className="py-4 px-6">GEN-1</td>
                        <td className="py-4 px-6 uppercase">Rp. 130.000</td>
                        <td className="py-4 px-6">Rp 1.000.000</td>
                      </tr>
                      <tr className="bg-white">
                        <td scope="row" className="py-4 px-6">
                          <div className="flex flex-row gap-5 items-center">
                            <input
                              type="checkbox"
                              value=""
                              className="w-5 h-5 text-blue-600 bg-gray-100 rounded-full border-gray-300 focus:ring-white dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            />
                            <img
                              src={people}
                              className="w-12 h-12"
                              alt="product"
                            />
                            <p>gizidat</p>
                          </div>
                        </td>
                        <td className="py-4 px-6">GEN-1</td>
                        <td className="py-4 px-6 uppercase">Rp. 130.000</td>
                        <td className="py-4 px-6">Rp 1.000.000</td>
                      </tr>
                      <tr className="bg-white">
                        <td scope="row" className="py-4 px-6">
                          <div className="flex flex-row gap-5 items-center">
                            <input
                              type="checkbox"
                              value=""
                              className="w-5 h-5 text-blue-600 bg-gray-100 rounded-full border-gray-300 focus:ring-white dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            />
                            <img
                              src={people}
                              className="w-12 h-12"
                              alt="product"
                            />
                            <p>gizidat</p>
                          </div>
                        </td>
                        <td className="py-4 px-6">GEN-1</td>
                        <td className="py-4 px-6 uppercase">Rp. 130.000</td>
                        <td className="py-4 px-6">Rp 1.000.000</td>
                      </tr>
                      <tr className="bg-white">
                        <td scope="row" className="py-4 px-6">
                          <div className="flex flex-row gap-5 items-center">
                            <input
                              type="checkbox"
                              value=""
                              className="w-5 h-5 text-blue-600 bg-gray-100 rounded-full border-gray-300 focus:ring-white dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            />
                            <img
                              src={people}
                              className="w-12 h-12"
                              alt="product"
                            />
                            <p>gizidat</p>
                          </div>
                        </td>
                        <td className="py-4 px-6">GEN-1</td>
                        <td className="py-4 px-6 uppercase">Rp. 130.000</td>
                        <td className="py-4 px-6">Rp 1.000.000</td>
                      </tr>
                      <tr className="bg-white">
                        <td scope="row" className="py-4 px-6">
                          <div className="flex flex-row gap-5 items-center">
                            <input
                              type="checkbox"
                              value=""
                              className="w-5 h-5 text-blue-600 bg-gray-100 rounded-full border-gray-300 focus:ring-white dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            />
                            <img
                              src={people}
                              className="w-12 h-12"
                              alt="product"
                            />
                            <p>gizidat</p>
                          </div>
                        </td>
                        <td className="py-4 px-6">GEN-1</td>
                        <td className="py-4 px-6 uppercase">Rp. 130.000</td>
                        <td className="py-4 px-6">Rp 1.000.000</td>
                      </tr>
                      <tr className="bg-white">
                        <td scope="row" className="py-4 px-6">
                          <div className="flex flex-row gap-5 items-center">
                            <input
                              type="checkbox"
                              value=""
                              className="w-5 h-5 text-blue-600 bg-gray-100 rounded-full border-gray-300 focus:ring-white dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            />
                            <img
                              src={people}
                              className="w-12 h-12"
                              alt="product"
                            />
                            <p>gizidat</p>
                          </div>
                        </td>
                        <td className="py-4 px-6">GEN-1</td>
                        <td className="py-4 px-6 uppercase">Rp. 130.000</td>
                        <td className="py-4 px-6">Rp 1.000.000</td>
                      </tr> */}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="grid grid-cols-5 gap-2">
              <div className="col-span-3 bg-white shadow-lg rounded-lg p-6">
                <DetailChartRevenue 
                  onFiltereProduct={setSelectedProduct}
                  setSelectedFilter={setSelectedFilter}
                  omsetData={omsetProducts}
                  />
              </div>
              <div className="col-span-2 bg-white shadow-lg rounded-lg p-6">
                <div className="flex flex-col justify-between h-full">
                  <div className="flex flex-row justify-between ">
                    <h1 className="font-bold text-lg">Income</h1>
                    <div className="bg-[#22C55E] rounded-lg p-3 font-bold">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="white"
                        className="bi bi-arrow-up"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fillRule="evenodd"
                          d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"
                        />
                      </svg>
                    </div>
                  </div>
                  {selectedProduct.length > 0 ? (
                    <h1 className="font-bold text-7xl">{omsetProducts.reduce((total, current) => total + current, 0)/1000} K</h1>
                    
                  ) : 
                  (<h1 className="font-bold text-7xl">{omsetProducts.reduce((total, current) => total + current, 0)/1000} K</h1>)
                  }
                  {selectedProduct.length > 0 ? (
                    <h4 className="font-bold bg-[#DCFCE7] text-green-500 w-fit p-1 rounded-lg">
                   {precetanceProducts.percentase}
                    </h4>
                    
                  ) : 
                  ( <h4 className="font-bold bg-[#DCFCE7] text-green-500 w-fit p-1 rounded-lg">
                  {precetanceProducts.percentase}
                  </h4>
                  )
                  }
                
                  {/* <h4 className="font-bold bg-[#DCFCE7] text-green-500 w-fit p-1 rounded-lg">
                    + 4.3 %
                  </h4> */}
                </div>
              </div>
            </div>
          </div>
          {/* end: Main */}
        </div>
        {/* Modal */}
        {showEditProduct ? (
          <>
            <div className="bg-black bg-opacity-75 overflow-y-auto overflow-x-hidden fixed flex justify-center top-0 left-0 right-0 bottom-0 z-50 w-full md:inset-0 h-modal md:h-full">
              {/* <div className="relative w-full max-w-2xl h-full md:h-auto"> */}
              {/* ini yang ditambahin */}
              <div className="relative w-full max-w-md h-full md:h-auto mt-10">
                {/* ini yang ditambahin */}
                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                  <div className="bg-green-400 flex justify-between items-start p-4 rounded-t border-b dark:border-gray-600">
                    <h3 className="text-xl font-semibold text-white dark:text-white">
                      Edit Product
                    </h3>
                    <button
                      type="button"
                      className="text-white bg-transparent hover:bg-green-600 hover:text-white rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                      onClick={() => setShowEditProduct(false)}
                    >
                      <svg
                        className="w-5 h-5"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                    </button>
                  </div>
                  <div className="p-6 space-y-6 items-center">
                    <div className="px-10">
                      <label className="block mb-2 text-sm font-medium text-gray-900">
                        Product Name
                      </label>
                      <input
                        type="text"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full"
                        placeholder="Product Name"
                        value={crmProducts[selectedProduct].name}
                      />
                    </div>
                    <div className="px-10">
                      <label className="block mb-2 text-sm font-medium text-gray-900">
                        SKU
                      </label>
                      <input
                        type="text"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full"
                        placeholder="Ex. ETA"
                        value={crmProducts[selectedProduct].sku}
                      />
                    </div>
                    <div className="px-10">
                      <label className="block mb-2 text-sm font-medium text-gray-900">
                        Price
                      </label>
                      <input
                        type="text"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full"
                        placeholder="IDR"
                        value={crmProducts[selectedProduct].price}
                      />
                    </div>
                    <div className="px-10">
                      <label className="block mb-2 text-sm font-medium text-gray-900">
                        Image
                      </label>
                      <input
                        type="file"
                        className="bg-gray-100 border border-white text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full"
                      />
                    </div>
                    <div className="mt-10 flex flex-row justify-end gap-3">
                      <button
                        className="py-2 px-6 text-sm rounded-xl bg-gray-100 hover:bg-gray-200 text-gray-400 hover:text-gray-600"
                        onClick={() => setShowEditProduct(false)}
                      >
                        Cancel
                      </button>
                      <button className="py-2 px-6 text-sm rounded-xl bg-green-500 hover:bg-green-600 text-white">
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : null}
        {showDelete ? (
          <>
            <div className="bg-black bg-opacity-75 overflow-y-auto overflow-x-hidden fixed flex justify-center top-0 left-0 right-0 bottom-0 z-50 w-full md:inset-0 md:h-full">
              {/* <div className="relative w-full max-w-2xl h-full md:h-auto"> */}
              {/* ini yang ditambahin */}
              <div className="fixed w-full max-w-xl md:h-auto mt-40">
                {/* ini yang ditambahin */}
                <div className="relative bg-[#F5F8FA] rounded-lg shadow dark:bg-gray-700">
                  <div className="p-6 space-y-6 items-center">
                    <div className="flex flex-row justify-between items-start gap-5">
                      <div className="flex flex-row gap-5">
                        <img src={Delete} className="w-100 object-cover" />
                        <div className="flex flex-col">
                          <h1 className="font-bold">
                            Delete This Customer Service?
                          </h1>
                          <p className="font-semibold text-gray-400">
                            Data will be permanently deleted
                          </p>
                          <div className="mt-10 flex flex-row justify-start gap-3">
                            <button className="text-sm py-2 px-5 rounded-xl text-white bg-green-400 hover:bg-green-500 font-semibold">
                              Yes
                            </button>
                            <button
                              className="text-sm px-3 rounded-xl text-gray-400 hover:text-gray-600 bg-gray-100 hover:bg-gray-200 font-semibold"
                              onClick={() => setShowDelete(false)}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                      <button
                        type="button"
                        className="bg-transparent hover:bg-gray-100 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                        onClick={() => setShowDelete(false)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="#A1A5B7"
                          className="bi bi-x-circle"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                          <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : null}
        {showImage ? (
          <>
            <div
              onClick={() => setShowImage(false)}
              className="bg-black bg-opacity-75 overflow-y-auto overflow-x-hidden fixed flex justify-center top-0 left-0 right-0 bottom-0 z-50 w-full md:inset-0 h-full md:h-full"
            >
              {/* <div className="relative w-full max-w-2xl h-full md:h-auto"> */}
              {/* ini yang ditambahin */}
              <div className="relative w-full max-w-md h-full md:h-auto mt-10">
                {/* ini yang ditambahin */}
                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                  <div className="p-6 space-y-6 flex justify-center items-center">
                    <img
                      src={imageUrl}
                      className="md:w-80 h-auto max-w-screen w-auto"
                      alt="product"
                    />
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : null}
      </div>
    </>
  );
}

export default Revenue;
