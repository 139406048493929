import React, { Component, useState, useEffect, Fragment } from "react";
import { Link, useNavigate } from "react-router-dom";
import "aos/dist/aos.css";
import { logout } from "../../../Network/Lib/auth";
import AddDevice from "../../../Components/Partials/Modal/AddDevice";
import { createSession, deleteSessions, getSession, getWhatsappBarcode } from "../../../Network/Lib/whatsapp";
import SidebarWaUn from "../../../Components/Sidebar/SidebarWaUn";
import ICLoading from "../../../Assets/icon/loading.gif";
import AddIcon from "@mui/icons-material/Add";

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

function MyDevice() {
    const navigate = useNavigate();
    const [refreshApi, setRefreshApi] = useState(false);
    const [navSideBar, setNavSideBar] = useState(false);
    const [count, setCount] = useState('')
    const [dataSession, setDataSession] = useState([])
    const [showInput, setShowInput] = useState(false);
    const [barcode, setBarcode] = useState('')
    const [loading, setLoading] = useState(false)
    const [ready, setReady] = useState(false)
    const [showAdd, setShowAdd] = useState(false);
    const [sessionName, setSessionName] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')

    const handelSubmit = async () => {
        const form = {
            "phone": phoneNumber,
            "name": sessionName
        }

        await createSession(form)
            .then(() => {
                // Redirecting to home page after creation done
                setRefreshApi(true)
                setShowAdd(false)
                console.log('new Session added.')
            }).catch((err) => {
                console.log(err)
            });
    }

    const onFinishedCreate = async () => {
        setRefreshApi(true);
    };

    const signOut = () => {
        logout();
        navigate("/login");
    };
    const scanBarcode = async (phone, name) => {
        setLoading(true)
        try {
            getWhatsappBarcode(phone, name)
                .then(async (response) => {
                    console.log(response)
                    if (response.data.data == 'whtasapp ready, tunggu 30 detik') {
                        setLoading(false)
                        setReady(true)
                        setTimeout(() => {
                            setLoading(false);
                            setShowInput(false)
                        }, 30000);
                    } else {
                        setBarcode(response.data)
                        setLoading(false)
                    }
                })
                .catch((e) => {
                    if (e.response) {
                        console.log(e.response.data.message);
                    }
                });
        } catch (error) {
            console.log(error);
        }
    };
    const deleteCache = async () => {

        try {
            const response = await deleteCache()
                .then(async (response) => {
                    console.log(response)
                })
                .catch((e) => {
                    if (e.response) {
                        console.log(e.response.data.message);
                    }
                });
        } catch (error) {
            console.log(error);
        }
    };
    const deleteSession = async (id) => {
        await deleteSessions(id).then(() => {
            setRefreshApi(true);
            console.log('session deleted.')
        }).catch((e) => {
            console.log(e);
        });
    };
    const session = async () => {
        await getSession()
            .then((data) => {
                // ketika Rest API sukses, simpan data dari response ke dalam state lokal
                console.log(data.data.data)
                setDataSession(data.data.data);
                setCount(data.data.count)
            })
            .catch((err) => {
                if (err.name === "AbortError") {
                    console.log("fetch aborted.");
                }
            });
    }
    useEffect(() => {
        session()
        setRefreshApi(false)
    }, [refreshApi]);
    return (
        <>
            <div className="bg-gradient-to-r from-[#54DAA1] to-[#2DCE89]">
                <div className="relative">
                    <aside
                        className={
                            navSideBar === true
                                ? "w-24 h-screen p-5 fixed top-0 left-0 right-0 z-10"
                                : "w-3/12 h-screen p-5 fixed top-0 left-0 right-0 z-10"
                        }
                        aria-label="Sidebar"
                    >
                        <button
                            className="absolute top-72 right-1 w-10 h-10 bg-gradient-to-r from-[#54DAA1] to-[#2DCE89] rounded-full shadow-lg flex justify-center items-center"
                            onClick={() => setNavSideBar(!navSideBar)}
                        >
                            {navSideBar ? (
                                <svg
                                    width="9"
                                    height="16"
                                    viewBox="0 0 9 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M1 14.3333L7.66667 7.66668L1 1.00001"
                                        stroke="white"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                            ) : (
                                <svg
                                    width="10"
                                    height="16"
                                    viewBox="0 0 10 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M8.33325 14.6667L1.66659 7.99999L8.33325 1.33332"
                                        stroke="white"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                            )}
                        </button>
                        <SidebarWaUn navSideBar={navSideBar} />
                    </aside>
                </div>
                <div
                    className={
                        navSideBar === true
                            ? "ml-24 h-72 w-11/12 p-5 space-y-5"
                            : "ml-auto h-72 w-9/12 p-5 space-y-5"
                    }
                >
                    {/* Header */}
                    <div className="flex flex-row justify-between items-center">
                        <div className="text-white space-y-2">
                            <div className="flex flex-row space-x-3">
                                <p className="text-sm">Pages</p>
                                <p className="text-sm">/</p>
                                <p className="text-sm">WhatsApp</p>
                                <p className="text-sm">/</p>
                                <p className="text-sm">My Device</p>
                            </div>
                            <p className="font-semibold">My Device</p>
                        </div>
                        <div className="flex flex-row items-center gap-5">
                            <Link to="#" className="flex items-center gap-2">
                                <svg
                                    width="21"
                                    height="20"
                                    viewBox="0 0 21 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M13.4938 2.52235C12.7336 1.70165 11.6719 1.24969 10.5 1.24969C9.32191 1.24969 8.25668 1.69891 7.50004 2.51454C6.73519 3.33915 6.36254 4.45985 6.45004 5.67001C6.62348 8.05751 8.44027 9.99969 10.5 9.99969C12.5598 9.99969 14.3735 8.0579 14.5496 5.67079C14.6383 4.47157 14.2633 3.35321 13.4938 2.52235Z"
                                        fill="white"
                                    />
                                    <path
                                        d="M17.3752 18.7488H3.62522C3.44525 18.7511 3.26701 18.7133 3.10349 18.6381C2.93997 18.5629 2.79526 18.4522 2.67991 18.314C2.426 18.0105 2.32366 17.596 2.39944 17.1769C2.72913 15.348 3.75804 13.8117 5.37522 12.7332C6.81194 11.7757 8.63186 11.2488 10.5002 11.2488C12.3686 11.2488 14.1885 11.7761 15.6252 12.7332C17.2424 13.8113 18.2713 15.3476 18.601 17.1765C18.6768 17.5957 18.5744 18.0101 18.3205 18.3136C18.2052 18.4519 18.0605 18.5626 17.897 18.6379C17.7335 18.7132 17.5552 18.7511 17.3752 18.7488Z"
                                        fill="white"
                                    />
                                </svg>
                                <p className="text-white">{localStorage.getItem("nameUser")}</p>
                            </Link>
                            <button onClick={signOut} className="bg-white p-3 rounded-lg">
                                <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M6.5625 2.8125H2.1875V17.8125H6.5625M13.4375 5.9375L17.8125 10.3125L13.4375 14.6875M7.1875 10.3125H17.8125"
                                        stroke="#EF4444"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                            </button>
                        </div>
                    </div>
                    {/* start: Main */}
                    <div className="w-full">
                        <div className="">
                            <div className="bg-white min-h-screen rounded-lg px-10 py-10 sh shadow-lg">
                                <div className="flex flex-col md:flex-row justify-between ">
                                    <div className="py-2 flex flex-col">
                                        <span className="font-semibold text-lg">My Device</span>
                                        <span className="text-sm text-gray-400">
                                            {count}/3 Session Used
                                        </span>
                                    </div>
                                    <div className="flex flex-row justify-center">
                                        <div className="ml-5">
                                            {count < 3 ?
                                                <button
                                                    type="button"
                                                    className="bg-green-400 hover:bg-green-500 text-white rounded-lg px-3 py-2 font-medium flex flex-row gap-3 flex items-center"
                                                    onClick={() => setShowAdd(true)}
                                                >
                                                    <AddIcon />
                                                    <span>Add Device</span>
                                                </button>
                                                : null}
                                        </div>
                                    </div>
                                </div>

                                <div className="mt-5">
                                    <div className="overflow-x-auto md:h-screen">
                                        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                                            <thead className="text-xs text-gray-400 uppercase dark:text-gray-400 ">
                                                <tr className="border-b">
                                                    <th scope="col" className="py-3 uppercase w-52">
                                                        Session Name
                                                    </th>
                                                    <th scope="col" className="py-3 uppercase w-52">
                                                        WhatsApp Number
                                                    </th>
                                                    <th scope="col" className="py-3 uppercase w-52">
                                                        Time Delay
                                                    </th>
                                                    <th scope="col" className="py-3 uppercase w-72">
                                                        Status
                                                    </th>
                                                    <th scope="col" className="py-3 uppercase w-52">
                                                        action
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {dataSession.map((data, index) => (
                                                    <tr className="" key={index}>
                                                        <td scope="col" className="py-3 uppercase w-52">
                                                            {data?.name}
                                                        </td>
                                                        <td scope="col" className="py-3 uppercase w-52">
                                                            {data?.phone}
                                                        </td>
                                                        <td scope="col" className="py-3 uppercase w-52">
                                                            1 second
                                                        </td>
                                                        <td scope="col" className="py-3 uppercase w-72">
                                                            {data?.ready ? 'Connected' : 'Initiated'}
                                                        </td>
                                                        <td scope="col" className="py-3 w-52">
                                                            <div className="flex gap-2">
                                                                <Link
                                                                    to={`/created-broadcast-un/${data?.name}/${data?.phone}`}
                                                                    // type="button"
                                                                    className="outline outline-1 outline-green-400 hover:outline-green-500 text-green-500 text-xs rounded-lg px-3 py-2 font-medium flex flex-row gap-3 items-center"
                                                                // onClick={() => setShowInput(true)}
                                                                >
                                                                    <svg
                                                                        width="16"
                                                                        height="16"
                                                                        viewBox="0 0 16 16"
                                                                        fill="#54DAA1"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                    >
                                                                        <path
                                                                            d="M6.33331 4.66663V13.8333C6.33331 14.0543 6.24552 14.2663 6.08924 14.4225C5.93295 14.5788 5.72099 14.6666 5.49998 14.6666H4.66665C4.44563 14.6666 4.23367 14.5788 4.07739 14.4225C3.92111 14.2663 3.83331 14.0543 3.83331 13.8333V9.66663M13 4.66663C13.663 4.66663 14.2989 4.93002 14.7677 5.39886C15.2366 5.8677 15.5 6.50358 15.5 7.16663C15.5 7.82967 15.2366 8.46555 14.7677 8.93439C14.2989 9.40323 13.663 9.66663 13 9.66663V4.66663Z"
                                                                            stroke="#54DAA1"
                                                                            strokeLinecap="round"
                                                                            strokeLinejoin="round"
                                                                        />
                                                                        <path
                                                                            d="M8 4.66664L11.77 1.52498C11.8795 1.43373 12.0128 1.3756 12.1542 1.3574C12.2956 1.33919 12.4393 1.36167 12.5684 1.42219C12.6975 1.48271 12.8066 1.57876 12.8831 1.6991C12.9595 1.81944 13.0001 1.95908 13 2.10164V12.2316C13.0001 12.3742 12.9595 12.5138 12.8831 12.6342C12.8066 12.7545 12.6975 12.8506 12.5684 12.9111C12.4393 12.9716 12.2956 12.9941 12.1542 12.9759C12.0128 12.9577 11.8795 12.8996 11.77 12.8083L8 9.66664H1.33333C1.11232 9.66664 0.900358 9.57885 0.744078 9.42257C0.587797 9.26629 0.5 9.05432 0.5 8.83331V5.49998C0.5 5.27896 0.587797 5.067 0.744078 4.91072C0.900358 4.75444 1.11232 4.66664 1.33333 4.66664H8Z"
                                                                            stroke="#54DAA1"
                                                                            strokeLinecap="round"
                                                                            strokeLinejoin="round"
                                                                        />
                                                                    </svg>

                                                                    <span>Broadcast</span>
                                                                </Link>
                                                                <Link
                                                                    to={`/crm-chat-un/${data?.name}/${data?.phone}`}
                                                                    // type="button"
                                                                    className="outline outline-1 outline-green-400 hover:outline-green-500 text-green-500 text-xs rounded-lg px-3 py-2 font-medium flex flex-row gap-3 items-center"
                                                                // onClick={() => setShowInput(true)}
                                                                >
                                                                    <div>
                                                                        <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M5.08333 14.0001L8 17.3334L10.9167 14.0001H13.8333C14.7525 14.0001 15.5 13.2526 15.5 12.3334V2.33344C15.5 1.41428 14.7525 0.666779 13.8333 0.666779H2.16667C1.2475 0.666779 0.5 1.41428 0.5 2.33344V12.3334C0.5 13.2526 1.2475 14.0001 2.16667 14.0001H5.08333ZM3.83333 4.83344H12.1667V6.50011H3.83333V4.83344ZM3.83333 8.16678H9.66667V9.83344H3.83333V8.16678Z" fill="url(#paint0_linear_314_7918)" />
                                                                            <defs>
                                                                                <linearGradient id="paint0_linear_314_7918" x1="15.5" y1="0.666779" x2="-2.97157" y2="11.3064" gradientUnits="userSpaceOnUse">
                                                                                    <stop stop-color="#54DAA1" />
                                                                                    <stop offset="1" stop-color="#2DB2CE" />
                                                                                </linearGradient>
                                                                            </defs>
                                                                        </svg>
                                                                    </div>

                                                                    <span>Message</span>
                                                                </Link>
                                                                <button
                                                                    type="button"
                                                                    className="bg-green-400 hover:bg-green-500 text-white text-xs rounded-lg px-3 py-2 font-medium flex flex-row gap-3 items-center"
                                                                    onClick={() => {
                                                                        scanBarcode(data?.phone, data?.name)
                                                                        setShowInput(true)
                                                                    }}
                                                                >
                                                                    <div>
                                                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M3.33464 3.33335H6.6763V1.66669H1.66797V6.66669H3.33464V3.33335ZM3.33464 13.3334H1.66797V18.3334H6.6763V16.6667H3.33464V13.3334ZM16.668 16.6667H13.3346V18.3334H18.3346V13.3334H16.668V16.6667ZM13.3346 3.33335H16.668V6.66669H18.3346V1.66669H13.3346V3.33335Z" fill="white" />
                                                                            <path d="M4.16797 9.16669H9.16797V4.16669H4.16797V9.16669ZM5.83464 5.83335H7.5013V7.50002H5.83464V5.83335ZM4.16797 15.8334H9.16797V10.8334H4.16797V15.8334ZM5.83464 12.5H7.5013V14.1667H5.83464V12.5ZM15.8346 4.16669H10.8346V9.16669H15.8346V4.16669ZM14.168 7.50002H12.5013V5.83335H14.168V7.50002ZM10.843 10.8334H12.5096V12.5H10.843V10.8334ZM12.5096 12.5H14.1763V14.1667H12.5096V12.5ZM14.1763 14.1667H15.843V15.8334H14.1763V14.1667ZM14.1763 10.8334H15.843V12.5H14.1763V10.8334Z" fill="white" />
                                                                        </svg>

                                                                    </div>
                                                                    <span>Scan</span>
                                                                </button>
                                                                <Link
                                                                    to={`/send-message/${data?.phone}/${data?.name}`}
                                                                    // type="button"
                                                                    className="bg-green-400 hover:bg-green-500 text-white text-xs rounded-lg px-3 py-2 font-medium flex flex-row gap-3 items-center"
                                                                // onClick={() => setShowInput(true)}
                                                                >
                                                                    <span>Send Message</span>
                                                                </Link>
                                                                <button
                                                                    type="button"
                                                                    className="bg-red-400 hover:bg-red-500 text-white text-xs rounded-lg px-3 py-2 font-medium flex flex-row gap-3 items-center"
                                                                    onClick={() => deleteSession(data?.id)}
                                                                >
                                                                    <div>
                                                                        <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M2.83203 15.5C2.3737 15.5 1.98148 15.3369 1.65536 15.0108C1.3287 14.6842 1.16536 14.2917 1.16536 13.8333V3C0.929253 3 0.731198 2.92028 0.571198 2.76083C0.411754 2.60083 0.332031 2.40278 0.332031 2.16667C0.332031 1.93056 0.411754 1.7325 0.571198 1.5725C0.731198 1.41306 0.929253 1.33333 1.16536 1.33333H4.4987C4.4987 1.09722 4.5787 0.899167 4.7387 0.739167C4.89814 0.579722 5.09592 0.5 5.33203 0.5H8.66536C8.90147 0.5 9.09953 0.579722 9.25953 0.739167C9.41897 0.899167 9.4987 1.09722 9.4987 1.33333H12.832C13.0681 1.33333 13.2659 1.41306 13.4254 1.5725C13.5854 1.7325 13.6654 1.93056 13.6654 2.16667C13.6654 2.40278 13.5854 2.60083 13.4254 2.76083C13.2659 2.92028 13.0681 3 12.832 3V13.8333C12.832 14.2917 12.669 14.6842 12.3429 15.0108C12.0162 15.3369 11.6237 15.5 11.1654 15.5H2.83203ZM4.4987 11.3333C4.4987 11.5694 4.5787 11.7672 4.7387 11.9267C4.89814 12.0867 5.09592 12.1667 5.33203 12.1667C5.56814 12.1667 5.7662 12.0867 5.9262 11.9267C6.08564 11.7672 6.16536 11.5694 6.16536 11.3333V5.5C6.16536 5.26389 6.08564 5.06583 5.9262 4.90583C5.7662 4.74639 5.56814 4.66667 5.33203 4.66667C5.09592 4.66667 4.89814 4.74639 4.7387 4.90583C4.5787 5.06583 4.4987 5.26389 4.4987 5.5V11.3333ZM7.83203 11.3333C7.83203 11.5694 7.91203 11.7672 8.07203 11.9267C8.23147 12.0867 8.42925 12.1667 8.66536 12.1667C8.90147 12.1667 9.09953 12.0867 9.25953 11.9267C9.41897 11.7672 9.4987 11.5694 9.4987 11.3333V5.5C9.4987 5.26389 9.41897 5.06583 9.25953 4.90583C9.09953 4.74639 8.90147 4.66667 8.66536 4.66667C8.42925 4.66667 8.23147 4.74639 8.07203 4.90583C7.91203 5.06583 7.83203 5.26389 7.83203 5.5V11.3333Z" fill="white" />
                                                                        </svg>
                                                                    </div>
                                                                    <span>Delete Device</span>
                                                                </button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* end: Main */}
                </div>

                {showInput ? (
                    <>
                        <div className="bg-black bg-opacity-75 overflow-y-auto overflow-x-hidden fixed flex justify-center top-0 left-0 right-0 bottom-0 z-50 w-full md:inset-0 h-modal md:h-full">
                            {/* <div className="relative w-full max-w-2xl h-full md:h-auto"> */}
                            {/* ini yang ditambahin */}
                            <div className="relative fixed w-full max-w-2xl h-full md:h-auto my-5">
                                {/* ini yang ditambahin */}
                                <div className="relative bg-[#F5F8FA] rounded-lg shadow dark:bg-gray-700">
                                    <div className="flex justify-between items-start p-4 rounded-t border-b dark:border-gray-600">
                                        <div className="flex flex-col">
                                            <h3 className="text-xl font-semibold dark:text-white">
                                                Scan Device
                                            </h3>
                                        </div>
                                        <button
                                            type="button"
                                            className="bg-transparent hover:bg-gray-100 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                                            onClick={() => setShowInput(false)}
                                        >
                                            <svg
                                                className="w-5 h-5"
                                                fill="currentColor"
                                                viewBox="0 0 20 20"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    fillRule="evenodd"
                                                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                                    clipRule="evenodd"
                                                ></path>
                                            </svg>
                                        </button>
                                    </div>
                                    <div className="p-6 space-y-6 items-center flex justify-between">
                                        <div>
                                            <h1 className="text-xl my-5">To use WhatsApp</h1>
                                            <div className="flex flex-row items-center gap-5 my-3">
                                                <div className="bg-ijo-def p-2 rounded-lg">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="16"
                                                        height="16"
                                                        fill="white"
                                                        className="bi bi-whatsapp"
                                                        viewBox="0 0 16 16"
                                                    >
                                                        <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z" />
                                                    </svg>
                                                </div>
                                                <p>1. Open WhatsApp on your phone</p>
                                            </div>
                                            <div className="flex flex-row items-center gap-5 my-3">
                                                <div className="bg-ijo-def p-2 rounded-lg">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="16"
                                                        height="16"
                                                        fill="white"
                                                        className="bi bi-three-dots-vertical"
                                                        viewBox="0 0 16 16"
                                                    >
                                                        <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                                                    </svg>
                                                </div>
                                                <p>2. Tap menu or setting, Select Linked Device</p>
                                            </div>
                                            <div className="flex flex-row items-center gap-5 my-3">
                                                <div className="bg-ijo-def p-2 rounded-lg">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="16"
                                                        height="16"
                                                        fill="white"
                                                        className="bi bi-camera"
                                                        viewBox="0 0 16 16"
                                                    >
                                                        <path d="M15 12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h1.172a3 3 0 0 0 2.12-.879l.83-.828A1 1 0 0 1 6.827 3h2.344a1 1 0 0 1 .707.293l.828.828A3 3 0 0 0 12.828 5H14a1 1 0 0 1 1 1v6zM2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2z" />
                                                        <path d="M8 11a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5zm0 1a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7zM3 6.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z" />
                                                    </svg>
                                                </div>

                                                <p>3. Point your phone to this screen to capture the code</p>
                                            </div>
                                        </div>
                                        <div>
                                            {loading ?
                                                <img src={ICLoading}></img>
                                                :
                                                (ready ?
                                                    <div className="bg-crm-4 py-5">
                                                        <p className="text-center text-white font-bold">whtasapp ready, tunggu 30 detik</p>
                                                    </div>
                                                    : <img src={`data:image/jpeg;base64,${barcode}`}></img>)
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                ) : null}
                {showAdd ? (
                    <>
                        <div className="bg-black bg-opacity-75 overflow-y-auto overflow-x-hidden fixed flex justify-center top-0 left-0 right-0 bottom-0 z-50 w-full md:inset-0 h-modal md:h-full">
                            {/* <div className="relative w-full max-w-2xl h-full md:h-auto"> */}
                            {/* ini yang ditambahin */}
                            <div className="relative fixed w-full max-w-2xl h-full md:h-auto my-5">
                                {/* ini yang ditambahin */}
                                <div className="relative bg-[#F5F8FA] rounded-lg shadow dark:bg-gray-700">
                                    <div className="flex justify-between items-start p-4 rounded-t border-b dark:border-gray-600">
                                        <div className="flex flex-col">
                                            <h3 className="text-xl font-semibold dark:text-white">
                                                Add Device
                                            </h3>
                                        </div>
                                        <button
                                            type="button"
                                            className="bg-transparent hover:bg-gray-100 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                                            onClick={() => setShowAdd(false)}
                                        >
                                            <svg
                                                className="w-5 h-5"
                                                fill="currentColor"
                                                viewBox="0 0 20 20"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    fillRule="evenodd"
                                                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                                    clipRule="evenodd"
                                                ></path>
                                            </svg>
                                        </button>
                                    </div>
                                    <div className="p-6 space-y-6 items-center">
                                        <div>
                                            <div className="">
                                                <label
                                                    className="block text-[#A1A5B7] font-semibold text-sm mb-2"
                                                    for="grid-state"
                                                >
                                                    Session Name
                                                </label>
                                                <input
                                                    type="text"
                                                    className="bg-white border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full"
                                                    onChange={(e) => setSessionName(e.target.value)}
                                                />
                                            </div>
                                            <div className="">
                                                <label
                                                    className="block text-[#A1A5B7] font-semibold text-sm mb-2"
                                                    for="grid-state"
                                                >
                                                    WhatsApp Number
                                                </label>
                                                <input
                                                    type="number"
                                                    className="bg-white border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full"
                                                    onChange={(e) => setPhoneNumber(e.target.value)}
                                                />
                                            </div>
                                        </div>

                                        <div className="mt-10 flex flex-row justify-end">
                                            <button
                                                className="px-3 rounded-xl text-gray-400 hover:text-gray-600 font-semibold"
                                                onClick={() => setShowAdd(false)}
                                            >
                                                Cancel
                                            </button>
                                            <button
                                                className="py-2 px-6 rounded-md text-white bg-green-400 hover:bg-green-500 font-semibold ml-2"
                                                onClick={handelSubmit}
                                            >
                                                Save
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                ) : null}
            </div>
        </>
    );
}

export default MyDevice;
