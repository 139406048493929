import { PlusIcon } from "@heroicons/react/solid";
import React, { useState } from "react";
import RepeaterLeadSource from "../Input/RepeaterLeadSource";

function AddLoyalty({}) {
  const [showInput, setShowInput] = useState(false);

  return (
    <>
      <button
        type="button"
        className="bg-gradient-to-r from-[#54DAA1] to-[#2DCE89] hover:bg-gradient-to-r from-[#54DAA1] to-[#2DCE89] text-white rounded-lg px-3 py-2 font-medium flex flex-row gap-3"
        onClick={() => setShowInput(true)}
      >
        <PlusIcon className="w-5 h-5"/>
        <span>Create Loyalty</span>
      </button>
      {showInput ? (
        <>
          <div className="bg-black bg-opacity-75 overflow-y-auto overflow-x-hidden fixed flex justify-center top-0 left-0 right-0 bottom-0 z-50 w-full md:inset-0 h-modal md:h-full">
            {/* <div className="relative w-full max-w-2xl h-full md:h-auto"> */}
            {/* ini yang ditambahin */}
            <div className="relative fixed w-full max-w-2xl h-full md:h-auto my-5">
              {/* ini yang ditambahin */}
              <div className="relative bg-[#F5F8FA] rounded-lg shadow dark:bg-gray-700">
                <div className="flex justify-between items-start p-4 dark:border-gray-600">
                  <h3 className="text-xl font-semibold">
                    Create Loyalty
                  </h3>
                  <button
                    type="button"
                    className="rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                    onClick={() => setShowInput(false)}
                  >
                    <svg
                      className="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </button>
                </div>
                <div className="p-6 space-y-6 items-center">
                  <div className="">
                    <label
                      className="block tracking-wide text-gray-700 text-sm font-bold mb-2"
                      for="grid-state"
                    >
                      Loyalty Name
                    </label>
                    <input
                      type="text"
                      className="border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full"
                      placeholder=""
                    />
                  </div>
                  <div className="">
                    <label
                      className="block tracking-wide text-gray-700 text-sm font-bold mb-2"
                      for="grid-state"
                    >
                      Leveling Conditions
                    </label>
                    <div className="flex flex-row justify-between gap-5">
                      <div className="basis-1/2">
                        <div className="relative">
                          <select
                            className="block appearance-none w-full border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id="grid-state"
                          >
                            <option hidden>From Level</option>
                            <option>CS 1</option>
                            <option>CS 2</option>
                          </select>
                          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"></div>
                        </div>
                      </div>
                      <div className="basis-1/2">
                        <div className="relative">
                          <select
                            className="block appearance-none w-full border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id="grid-state"
                          >
                            <option hidden>To Level</option>
                            <option>CS 1</option>
                            <option>CS 2</option>
                          </select>
                          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <label
                      className="block tracking-wide text-gray-700 text-sm font-bold mb-2"
                      for="grid-state"
                    >
                      Loyalty program to be earned (Advantages)
                    </label>
                    <textarea
                      type="text"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full"
                      placeholder=""
                      rows={10}
                    />
                  </div>
                  <div className="">
                    <label
                      className="block tracking-wide text-gray-700 text-sm font-bold mb-2"
                      for="grid-state"
                    >
                      Minimum point redemption
                    </label>
                    <input
                      type="text"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full"
                      placeholder=""
                    />
                  </div>
                  <div className="">
                    <label
                      className="block tracking-wide text-gray-700 text-sm font-bold mb-2"
                      for="grid-state"
                    >
                      Select Color
                    </label>
                    <input
                      type="color"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full"
                      placeholder=""
                    />
                  </div>

                  <div className="mt-10 flex flex-row justify-end">
                    <button
                      className="px-3 rounded-xl text-gray-400 hover:text-gray-600 font-semibold"
                      onClick={() => setShowInput(false)}
                    >
                      Cancel
                    </button>
                    <button className="py-2 px-5 rounded-md text-white bg-green-400 hover:bg-green-500 font-semibold ml-2">
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
}

export default AddLoyalty;
