const customerSatisfaction = {
  labels: [
    "Jan",
    "Feb",
    "March",
    "April",
    "May",
    "June",
    "July",
    "Augst",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ],
  datasets: [
    {
      label: "Sangat Puas",
      backgroundColor: "#1B8AF1",
      borderWidth: 1,
      data: [65, 59, 80, 81, 56, 77],
    },
    {
      label: "Puas",
      backgroundColor: "#1BF130",
      borderWidth: 1,
      data: [65, 59, 80, 81, 56, 77],
    },
    {
      label: "Tidak Puas",
      backgroundColor: "#F11B1B",
      borderWidth: 1,
      data: [65, 59, 80, 81, 56, 77],
    },
    {
      label: "Sangat Tidak Puas",
      backgroundColor: "#F19B1B",
      borderWidth: 1,
      data: [65, 59, 80, 81, 56, 77],
    },
  ],
};

export default customerSatisfaction;
