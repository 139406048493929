import React, { Component, useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import Sidebar from "../../Components/Sidebar/Sidebar";
import "aos/dist/aos.css";
import {
  getCustomerAddressById,
  getCustomerById,
  updateCustomer,
  updateCustomerAddress,
} from "../../Network/Lib/customer";
import {
  getAllCity,
  getAllProvince,
  getAllSubdis,
  getCity,
  getProvince,
  getSubdis,
} from "../../Network/Lib/rajaOngkir";
import { getLeveling } from "../../Network/Lib/leveling";
import { logout } from "../../Network/Lib/auth";

function EditSingleCustomer() {
  const navigate = useNavigate();
  const [navSideBar, setNavSideBar] = useState(false);
  const [data, setData] = useState([]);
  const [name, setName] = useState([]);
  const [phone, setPhone] = useState([]);
  const [email, setEmail] = useState([]);
  const [gender, setGender] = useState([]);
  const [birthdate, setBirthdate] = useState([]);
  const [maritalStatus, setMaritalStatus] = useState([]);
  const [latestPurchase, setLatestPurchase] = useState([]);
  const [levelId, setLevelId] = useState([]);
  const [levelName, setLevelName] = useState("");
  const [address, setAddress] = useState([]);

  const [provinceList, setProvinceList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [subdistrictList, setSubdistrictList] = useState([]);
  const [selectedProvince, setSelectedProvince] = useState();
  const [provinceName, setProvinceName] = useState();
  const [selectedCity, setSelectedCity] = useState();
  const [cityName, setCityName] = useState();
  const [selectedSubdis, setSelectedSubdis] = useState();
  const [subdistrictName, setSubdistrictName] = useState();

  const [leveling, setLeveling] = useState([]);

  const [msg, setMsg] = useState("");

  const onSelectProvince = async (e) => {
    setSelectedProvince(e.target.value);
    console.log(e.target.value)
    await getAllCity(e.target.value)
      .then((response) => {
        setCityList(response.data.data);
      })
      .catch((e) => {});
  };

  const onLoadProvince = async (e) => {
    // setSelectedProvince(e.target.value);
    // console.log("ini",e);
    await getAllCity(e)
      .then((response) => {
        setCityList(response.data.data);
      })
      .catch((e) => {});
  };

  const onSelectCity = async (e) => {
    setSelectedCity(e.target.value);
    await getAllSubdis(e.target.value)
      .then((response) => {
        setSubdistrictList(response.data.data);
      })
      .catch((e) => {});
  };

  const onLoadCity = async (e) => {
    console.log(e);
    await getAllSubdis(e)
      .then((response) => {
        setSubdistrictList(response.data.data);
      })
      .catch((e) => {});
  };

  const onSelectSubdis = async (e) => {
    setSelectedSubdis(e.target.value);
  };
 

  const { id } = useParams();
  // console.log(id)
  useEffect(() => {
    getAllProvince().then((response) => {
      setProvinceList(response.data.data);
    });
    getCustomerById(id).then((response) => {
      // console.log(response.data.data[0]);
      setName(response.data.data[0].name);
      setPhone(response.data.data[0].phone);
      setEmail(response.data.data[0].email);
      setGender(response.data.data[0].gender);
    //   var options = {
       
    //     month: 'numeric',
    //     day: 'numeric',
    //     year: 'numeric',
       
    // };
    //   var Data = new Date(response.data.data[0].birthdate);
    //   var date = Data.toLocaleDateString(options);

    // yyyy-MM-dd
    // const input = "2020-08-19"
    // const [year, month, day] =  response.data.data[0].birthdate.split('-')

    // dd/mm/yyyy
    // console.log(`${day}/${month}/${year}`
      setBirthdate(response.data.data[0].birthdate);
      //  console.log(response.data.data[0].birthdate);
      // setBirthdate(
      //   new Date(response.data.data[0].birthdate).toLocaleDateString(
      //     "id-ID"
      //   )
      // );
      setMaritalStatus(response.data.data[0].marital_status);
      setLatestPurchase(
        new Date(response.data.data[0].latest_purchase).toLocaleDateString(
          "id-ID"
        )
      );
      setLevelId(response.data.data[0].levelingId);
      setLevelName(response.data.data[0].leveling?.levelingName);
    });
    getCustomerAddressById(id).then((response) => {
      // console.log(response.data.data[0]);
      setAddress(response.data.data[0].address);
      setSelectedProvince(response.data.data[0].province_id);
      setSelectedSubdis(response.data.data[0].subdistrict_id);
      setSelectedCity(response.data.data[0].city_id);
      onLoadProvince(response.data.data[0].province_id);
      onLoadCity(response.data.data[0].city_id);
    });
    getProvince(selectedProvince).then((response) => {
      setProvinceName(response.data.data.province);
    });
    getCity(selectedCity).then((response) => {
      setCityName(response.data.data.city_name);
    });
    getSubdis(selectedSubdis).then((response) => {
      setSubdistrictName(response.data.data.subdistrict_name);
    });
    getLeveling().then((response) => {
      setLeveling(response.data.data);
      // console.log(response.data.data);
    });
  }, []);

  // console.log(name);
  // console.log(phone);
  // console.log(email);
  // console.log(gender);
  console.log(birthdate);
  // console.log(maritalStatus);
  // console.log(latestPurchase);
  // console.log(levelId);
  // console.log(levelName);
  // console.log(address);
  // console.log(selectedSubdis);
  // console.log(selectedCity);
  // console.log(selectedProvince);
  // console.log(levelName);

  const postData = async () => {
    const err = [];
    if (name == "") {
      err.push("Name");
    }
    if (birthdate == "") {
      err.push("Birthdate");
    }
    if (email == "") {
      err.push("Email");
    }
    if (gender == "") {
      err.push("Gender");
    }
    if (maritalStatus == "") {
      err.push("Marital Status");
    }
    if (levelName == "") {
      err.push("Level");
    }
    if (address == "") {
      err.push("Address");
    }
    if (selectedSubdis == "") {
      err.push("Subdistrict");
    }
    if (selectedCity == "") {
      err.push("City");
    }
    if (selectedProvince == "") {
      err.push("Province");
    }
    if (err.length > 0) {
      setMsg(err);
    } else {
      const form = {
        name: name,
        birthdate: birthdate,
        email: email,
        gender: gender,
        marital_status: maritalStatus,
        // latest_purchase: birthdate,
        levelingId: levelName,
      };
      console.log(form);
      try {
        const response = await updateCustomer(phone, form).then(
          async (response) => {
            const formAddress = {
              customerId: id,
              address: address,
              subdistrict_id: selectedSubdis,
              district_id: 0,
              city_id: selectedCity,
              province_id: selectedProvince,
            };
            console.log(formAddress);
            const responseAddress = await updateCustomerAddress(
              id,
              formAddress
            ).then((responseAddress) => {
              console.log(responseAddress);
              navigate(`/view-customer/${id}`);
            });
            // updateCustomerAddress(id, formAddress);
            // console.log(response);
            console.log(responseAddress);
          }
        );
        console.log(response).catch((e) => {
          console.log(e.response);
        });
      } catch (error) {}
    }
  };

  const signOut = () => {
    logout();
    navigate("/login");
  };
  return (
    <>
      <div className="bg-gradient-to-r from-[#54DAA1] to-[#2DCE89]">
        <div className="relative">
          <aside
            className={
              navSideBar === true
                ? "w-24 h-screen p-5 fixed top-0 left-0 right-0 z-10"
                : "w-3/12 h-screen p-5 fixed top-0 left-0 right-0 z-10"
            }
            aria-label="Sidebar"
          >
            <button
              className="absolute top-72 right-1 w-10 h-10 bg-gradient-to-r from-[#54DAA1] to-[#2DCE89] rounded-full shadow-lg flex justify-center items-center"
              onClick={() => setNavSideBar(!navSideBar)}
            >
              {navSideBar ? (
                <svg
                  width="9"
                  height="16"
                  viewBox="0 0 9 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 14.3333L7.66667 7.66668L1 1.00001"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              ) : (
                <svg
                  width="10"
                  height="16"
                  viewBox="0 0 10 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.33325 14.6667L1.66659 7.99999L8.33325 1.33332"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              )}
            </button>
            <Sidebar navSideBar={navSideBar} />
          </aside>
        </div>
        <div
          className={
            navSideBar === true
              ? "ml-24 h-72 w-11/12 p-5 space-y-5"
              : "ml-auto h-72 w-9/12 p-5 space-y-5"
          }
        >
          {/* Header */}
          <div className="flex flex-row justify-between items-center">
            <div className="text-white space-y-2">
              <div className="flex flex-row space-x-3">
                <p className="text-sm">Pages</p>
                <p className="text-sm">/</p>
                <Link to="/Dashboard" className="text-sm">
                  Dashboard
                </Link>
                <p className="text-sm">/</p>
                <Link to="#" className="text-sm">
                  Edit Single Customer
                </Link>
              </div>
              <p className="font-semibold">Edit Single Customer</p>
            </div>
            <div className="flex flex-row items-center gap-5">
              <a href="#" className="flex items-center gap-2">
                <svg
                  width="21"
                  height="20"
                  viewBox="0 0 21 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.4938 2.52235C12.7336 1.70165 11.6719 1.24969 10.5 1.24969C9.32191 1.24969 8.25668 1.69891 7.50004 2.51454C6.73519 3.33915 6.36254 4.45985 6.45004 5.67001C6.62348 8.05751 8.44027 9.99969 10.5 9.99969C12.5598 9.99969 14.3735 8.0579 14.5496 5.67079C14.6383 4.47157 14.2633 3.35321 13.4938 2.52235Z"
                    fill="white"
                  />
                  <path
                    d="M17.3752 18.7488H3.62522C3.44525 18.7511 3.26701 18.7133 3.10349 18.6381C2.93997 18.5629 2.79526 18.4522 2.67991 18.314C2.426 18.0105 2.32366 17.596 2.39944 17.1769C2.72913 15.348 3.75804 13.8117 5.37522 12.7332C6.81194 11.7757 8.63186 11.2488 10.5002 11.2488C12.3686 11.2488 14.1885 11.7761 15.6252 12.7332C17.2424 13.8113 18.2713 15.3476 18.601 17.1765C18.6768 17.5957 18.5744 18.0101 18.3205 18.3136C18.2052 18.4519 18.0605 18.5626 17.897 18.6379C17.7335 18.7132 17.5552 18.7511 17.3752 18.7488Z"
                    fill="white"
                  />
                </svg>
                <p className="text-white">{localStorage.getItem("nameUser")}</p>
              </a>
              <button onClick={signOut} className="bg-white p-3 rounded-lg">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.5625 2.8125H2.1875V17.8125H6.5625M13.4375 5.9375L17.8125 10.3125L13.4375 14.6875M7.1875 10.3125H17.8125"
                    stroke="#EF4444"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            </div>
          </div>
          {/* start: Main */}
          <div className="pb-5">
            <div className="bg-white shadow-lg rounded-lg p-6">
              <div className="flex flex-row justify-between">
                <div className="flex items-center justify-center gap-3">
                  <Link to="/dashboard">
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.11261 0.862514L0.862615 7.11251C0.748814 7.23139 0.659608 7.37157 0.600115 7.52501C0.475092 7.82934 0.475092 8.17069 0.600115 8.47501C0.659608 8.62845 0.748814 8.76863 0.862615 8.88751L7.11261 15.1375C7.22916 15.2541 7.36753 15.3465 7.5198 15.4096C7.67208 15.4727 7.83529 15.5051 8.00011 15.5051C8.33299 15.5051 8.65224 15.3729 8.88761 15.1375C9.12299 14.9021 9.25523 14.5829 9.25523 14.25C9.25523 13.9171 9.12299 13.5979 8.88761 13.3625L4.76261 9.25001H14.2501C14.5816 9.25001 14.8996 9.11832 15.134 8.8839C15.3684 8.64948 15.5001 8.33153 15.5001 8.00001C15.5001 7.66849 15.3684 7.35055 15.134 7.11613C14.8996 6.88171 14.5816 6.75001 14.2501 6.75001H4.76261L8.88761 2.63751C9.00478 2.52131 9.09777 2.38306 9.16123 2.23073C9.22469 2.07841 9.25736 1.91503 9.25736 1.75001C9.25736 1.585 9.22469 1.42162 9.16123 1.26929C9.09777 1.11697 9.00478 0.978718 8.88761 0.862514C8.77141 0.745353 8.63316 0.652359 8.48084 0.588898C8.32851 0.525437 8.16513 0.492765 8.00011 0.492765C7.8351 0.492765 7.67172 0.525437 7.51939 0.588898C7.36707 0.652359 7.22882 0.745353 7.11261 0.862514Z"
                        fill="#A1A1AA"
                      />
                    </svg>
                  </Link>
                  <h1 className="text-lg font-bold">Edit Single Customer</h1>
                </div>
              </div>
              {/* start: Data Customer */}
              <div className="bg-gray-50 rounded-lg p-5 my-5">
                <div className="py-2">
                  <span className="font-medium text-lg">Data Customer</span>
                </div>
                {msg != "" ? (
                  <div
                    className="p-3 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800 my-3"
                    role="alert"
                  >
                    <ul class="list-disc list-inside">
                      {msg.map((val) => (
                        <li>{val} Can't be empty</li>
                      ))}
                    </ul>
                  </div>
                ) : null}
                <div className="grid grid-cols-6 md:grid-cols-12 gap-5 mt-5">
                  <div className="col-span-6">
                    <span className="text-sm font-bold text-gray-400">
                      Customer Name
                    </span>
                    <div className="mt-3.5">
                      <input
                        type="text"
                        className="bg-white rounded-lg border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                        defaultValue={name}
                        onChange={(value) => setName(value.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-span-6">
                    <span className="text-sm font-bold text-gray-400">
                      Phone
                    </span>
                    <div className="mt-3.5">
                      <input
                        type="text"
                        className="bg-gray-100 rounded-lg border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                        defaultValue={phone}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="col-span-6">
                    <span className="text-sm font-bold text-gray-400">
                      Email
                    </span>
                    <div className="mt-3.5">
                      <input
                        type="text"
                        className="bg-white rounded-lg border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                        defaultValue={email}
                        onChange={(value) => setEmail(value.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-span-6">
                    <span className="text-sm font-bold text-gray-400">
                      Gender
                    </span>
                    <div className="mt-3.5">
                      <select
                        placeholder="Select Gender ( Male; Female )"
                        className="bg-white rounded-lg border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                        onChange={(e) => setGender(e.target.value)}
                      >
                        <option value={gender} hidden>
                          {gender === "" ? "Select Gender" : gender}
                        </option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-span-6">
                    <span className="text-sm font-bold text-gray-400">
                      Birthdate
                    </span>
                    <div className="mt-3.5">
                      <input
                        type="date"
                        className="bg-white rounded-lg border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                        // defaultValue={new Date(birthdate)
                        //   .toLocaleDateString("en-GB")
                        //   .split("/")
                        //   .reverse()
                        //   .join("-")}
                        value={new Date(birthdate)
                            .toLocaleDateString("en-GB")
                            .split("/")
                            .reverse()
                            .join("-")}
                        onChange={(e) => setBirthdate(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-span-6">
                    <span className="text-sm font-bold text-gray-400">
                      Martial Status
                    </span>
                    <div className="mt-3.5">
                      <select
                        placeholder="Select Martial Status ( Single; Married; Divorced )"
                        className="bg-white rounded-lg border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                        onChange={(e) => setMaritalStatus(e.target.value)}
                      >
                        <option value={maritalStatus} hidden>
                          {maritalStatus === ""
                            ? "Select Marital Status"
                            : maritalStatus}
                        </option>
                        <option value="Single">Single</option>
                        <option value="Married">Married</option>
                        <option value="Divorced">Divorced</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-span-6">
                    <span className="text-sm font-bold text-gray-400">
                      Address
                    </span>
                    <div className="mt-3.5">
                      <input
                        type="text"
                        className="bg-white rounded-lg border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                        defaultValue={address}
                        onChange={(value) => setAddress(value.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-span-6">
                    <span className="text-sm font-bold text-gray-400">
                      Province
                    </span>
                    <div className="mt-3.5">
                      <select
                        placeholder="Select Province"
                        className="bg-white rounded-lg border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                        value={selectedProvince}
                        onChange={onSelectProvince}
                      >
                        <option hidden>
                          {selectedProvince === ""
                            ? "Select Province"
                            : provinceName}
                        </option>
                        {provinceList.map(function (value, index) {
                          return (
                            <>
                              <option key={index} value={value.province_id}>
                                Provinsi {value.province}
                              </option>
                            </>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="col-span-6">
                    <span className="text-sm font-bold text-gray-400">
                      City
                    </span>
                    <div className="mt-3.5">
                      {cityList.length == 0 ? (
                        <select
                          placeholder="Select City"
                          className="bg-white rounded-lg border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                          value={selectedCity}
                          onChange={onSelectCity}
                          disabled
                        >
                          <option hidden>Select City</option>
                          {cityList.map(function (value, index) {
                            return (
                              <>
                                <option key={index} value={value.city_id}>
                                  {value.type} {value.city_name}
                                </option>
                              </>
                            );
                          })}
                        </select>
                      ) : (
                        <select
                          placeholder="Select City"
                          className="bg-white rounded-lg border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                          value={selectedCity}
                          onChange={onSelectCity}
                        >
                          <option hidden>Select City</option>
                          {cityList.map(function (value, index) {
                            return (
                              <>
                                <option key={index} value={value.city_id}>
                                  {value.type} {value.city_name}
                                </option>
                              </>
                            );
                          })}
                        </select>
                      )}
                    </div>
                  </div>
                  <div className="col-span-6">
                    <span className="text-sm font-bold text-gray-400">
                      Subdistrict
                    </span>
                    <div className="mt-3.5">
                      {subdistrictList.length == 0 ? (
                        <select
                          placeholder="Select Subdistrict"
                          className="bg-white rounded-lg border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                          value={selectedSubdis}
                          onChange={onSelectSubdis}
                          disabled
                        >
                          <option hidden>Select Subdistrict</option>
                          {subdistrictList.map(function (value, index) {
                            return (
                              <>
                                <option
                                  key={index}
                                  value={value.subdistrict_id}
                                >
                                  {value.subdistrict_name}
                                </option>
                              </>
                            );
                          })}
                        </select>
                      ) : (
                        <select
                          placeholder="Select Subdistrict"
                          className="bg-white rounded-lg border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                          value={selectedSubdis}
                          onChange={onSelectSubdis}
                        >
                          <option hidden>Select Subdistrict</option>
                          {subdistrictList.map(function (value, index) {
                            return (
                              <>
                                <option
                                  key={index}
                                  value={value.subdistrict_id}
                                >
                                  {value.subdistrict_name}
                                </option>
                              </>
                            );
                          })}
                        </select>
                      )}
                    </div>
                  </div>
                  <div className="col-span-6">
                    <span className="text-sm font-bold text-gray-400">
                      Level
                    </span>
                    <div className="mt-3.5">
                      <select
                        placeholder="Select Martial Status ( Single; Married; Divorced )"
                        className="bg-white rounded-lg border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                        value={levelName}
                        onChange={(e) => {
                          if (e.target.value == "create") {
                            window.open("/leveling", "_blank");
                          } else if (e.target.value == "new") {
                            setLevelName(null);
                          } else {
                            setLevelName(e.target.value);
                          }
                        }}
                      >
                        <option value={levelId} hidden>
                          {levelName != "" ? levelName : "New Customer"}
                        </option>
                        <option value="create" className="text-blue-600">
                          Create Leveling
                        </option>
                        <option value="new">New Customer</option>
                        {leveling?.map((val, index) => (
                          <option key={index} value={val.id}>
                            {val.levelingName}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              {/* end: Data Customer */}
              {/*bagin: Button Save */}
              <div className="flex flex-row justify-center md:justify-end gap-5 py-5">
                <a
                  href={`/view-customer/${id}`}
                  className="text-gray-400 rounded-lg bg-gray-100 hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-200 bg-gray-200 font-medium text-sm px-5 py-2.5 text-center"
                >
                  Cancel
                </a>
                <button
                  type="submit"
                  className="text-white rounded-lg bg-[#4FC29B] hover:bg-[#4FC29B] focus:ring-4 focus:outline-none focus:ring-[#4FC29B] font-medium text-sm px-5 py-2.5 text-center"
                  onClick={postData}
                >
                  Save Change
                </button>
              </div>
              {/*end: Button Save */}
            </div>
          </div>
          {/* end: Main */}
        </div>
      </div>
    </>
  );
}

export default EditSingleCustomer;
