import React from "react";
import Profile from "../../../Assets/img/Profile.jpg";
import ButtonCS from "../Button/ButtonCS";

function CardCS({ NameCS, Email, NoHp, Product, CS }) {
  return (
    <div className="shadow-md px-3 py-5 rounded w-96">
      <div className="flex flex-row items-center gap-3">
        <img src={Profile} alt="profile" className="rounded-full w-20 h-20 md:w-24 md:h-24" />
        <div className="flex flex-row gap-1 md:gap-2">
          <div className="flex flex-col gap-2">
            <p className="font-bold text-sm md:text-base">{NameCS}</p>
            <p className="text-gray-400 text-xs md:text-sm font-medium">{Email}</p>
            <p className="text-gray-400 text-xs md:text-sm font-medium">{NoHp}</p>
            <div className="flex flex-row gap-1 md:gap-2">
              <p className="bg-gray-100 py-1 px-3 text-xs md:text-sm font-medium text-center text-gray-400 rounded-lg w-20 md:w-24">
                {Product}
              </p>
              <p className="bg-gray-100 py-1 px-3 text-xs md:text-sm font-medium text-center text-gray-400 rounded-lg w-20 md:w-24">
                {CS}
              </p>
            </div>
          </div>
          <div>
            <ButtonCS/>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CardCS;
