import axios from 'axios';

const urlApi = 'https://api.pwkbackoffice.com/api/crm/'

const axiosClient = axios.create({
    baseURL: urlApi,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    },
});

const api = {
    axiosClient, 
    urlApi
}

export default api;