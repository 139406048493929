import React, { Component, useState } from "react";
import { Bar } from "react-chartjs-2";
import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import achievement from "../../../Data/Reporting/achievement";
import {
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@heroicons/react/solid";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import omzetTeam from "../../../Data/Reporting/omzetTeam";

{
  /*Vertical Bar Chart*/
}
// const state = {
//     labels: [
//       "Jan",
//       "Feb",
//       "Mar",
//       "Apr",
//       "May",
//       "Jun",
//       "Jul",
//       "Aug",
//       "Sept",
//       "Ockt",
//       "Nov",
//       "Des",
//     ],
//     datasets: [
//       {
//         label: "Name_Product",
//         backgroundColor: "rgba(75,192,192,1)",
//         borderColor: "rgba(0,0,0,1)",
//         borderWidth: 1,
//         data: [65, 59, 80, 81, 56, 77, 44, 84, 100, 91, 10, 15],
//       },
//       {
//         label: "Name_Product",
//         backgroundColor: "rgba(237,76,92,1)",
//         borderColor: "rgba(0,0,0,1)",
//         borderWidth: 1,
//         data: [65, 59, 80, 81, 56, 77, 44, 84, 100, 91, 10, 15],
//       },
//     ],
//   };
{
  /*Vertical Bar Chart*/
}

{
  /*Horizontal Bar Chart*/
}
//  const options_horizontal = {
//     indexAxis: 'y',
//         elements: {
//             bar: {
//             borderWidth: 2,
//             },
//         },
//         responsive: true,
//         plugins: {
//             legend: {
//             position: 'right',
//             },
//             title: {
//             display: true,
//             text: 'Chart.js Horizontal Bar Chart',
//             },
//         },
//   };

// const state_tree = {
//     labels: [
//         "Jan",
//         "Feb",
//         "Mar",
//         "Apr",
//         "May",
//         "Jun",
//         "Jul",
//         "Aug",
//         "Sept",
//         "Ockt",
//         "Nov",
//         "Des",
//       ],
//     datasets: [
//       {
//         label: 'Dataset 1',
//         data: [65, 59, 80, 81, 56, 77, 44, 84, 100, 91, 10, 15],
//         backgroundColor: 'rgb(255, 99, 132)',
//       },
//       {
//         label: 'Dataset 2',
//         data: [65, 59, 80, 81, 56, 77, 44, 84, 100, 91, 10, 15],
//         backgroundColor: 'rgb(75, 192, 192)',
//       },
//       {
//         label: 'Dataset 3',
//         data: [65, 59, 80, 81, 56, 77, 44, 84, 100, 91, 10, 15],
//         backgroundColor: 'rgb(53, 162, 235)',
//       },
//     ],
//   };
{
  /*Horizontal Bar Chart*/
}

{
  /*Stacked Bar Chart*/
}

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function ChartOmzet() {
  return (
    <>
      <div className="border-2 rounded-lg m-1">
        <div className="flex flex-row justify-between px-10 mt-5">
          <div>
            <h1 className="font-bold text-lg">Omzet Team</h1>
            <h1 className="text-gray-400">All Team</h1>
          </div>

          <div className="flex flex-col items-end">
            <div className="w-fit">
            <Menu as="div" className="relative inline-block text-left">
              <div>
                <Menu.Button className="inline-flex justify-center w-full rounded-md border border-[#F5F8FA] shadow-sm px-4 py-2 bg-[#F5F8FA] text-xs font-medium text-gray-700 hover:bg-[#F5F8FA] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-[#F5F8FA] focus:ring-[#F5F8FA]">
                  Select Team
                  <ChevronDownIcon
                    className="-mr-1 ml-2 h-5 w-5"
                    aria-hidden="true"
                  />
                </Menu.Button>
              </div>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="origin-top-right absolute right-0 mt-2 w-36 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          href="#"
                          className={classNames(
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700",
                            "block px-4 py-2 text-xs"
                          )}
                        >
                          All Team
                        </a>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          href="#"
                          className={classNames(
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700",
                            "block px-4 py-2 text-xs"
                          )}
                        >
                          Acq Facebook
                        </a>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          href="#"
                          className={classNames(
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700",
                            "block px-4 py-2 text-xs"
                          )}
                        >
                          Acq TikTok
                        </a>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          href="#"
                          className={classNames(
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700",
                            "block px-4 py-2 text-xs"
                          )}
                        >
                          Marketplace
                        </a>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          href="#"
                          className={classNames(
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700",
                            "block px-4 py-2 text-xs"
                          )}
                        >
                          Herbathos
                        </a>
                      )}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
            </div>
            <div className="flex flex-col md:flex-row md:items-center items-start mx-5">
              <input
                type={"date"}
                className="text-xs border-t-transparent border-r-transparent border-l-transparent"
              />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-dash"
                viewBox="0 0 16 16"
              >
                <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z" />
              </svg>
              <input
                type={"date"}
                className="text-xs border-t-transparent border-r-transparent border-l-transparent"
              />
            </div>
          </div>
        </div>
        <div className="border-b mt-5"></div>
        <div className="px-16 py-10">
          <Bar
            options={{
              plugins: {
                title: {
                  display: true,
                },
              },
              responsive: true,
              scales: {
                x: {
                  stacked: true,
                },
                y: {
                  stacked: true,
                },
              },
            }}
            data={omzetTeam}
          />
        </div>
      </div>
    </>
  );
}

export default ChartOmzet;
