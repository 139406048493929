import "aos/dist/aos.css";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getAllTemplate } from "../../../../Network/Lib/template";
import { getAllCS } from "../../../../Network/Lib/customer";
import { getAllProduct } from "../../../../Network/Lib/product";
import { logout } from "../../../../Network/Lib/auth";
import SidebarWaUn from "../../../../Components/Sidebar/SidebarWaUn";
import FilterDataCustomer from "../../../../Components/Partials/Modal/FilterDataCustomer";
import Select from 'react-select';
import { MultiSelect } from "react-multi-select-component";
import { createBroadcastUn, createCustomerUn } from "../../../../Network/Lib/broadcastUn";
import AddIcon from "@mui/icons-material/Add";

function CreatedBroadcastUn() {
  const navigate = useNavigate();
  const { nameSession, sender } = useParams()
  const [refreshApi, setRefreshApi] = useState(false);
  const [navSideBar, setNavSideBar] = useState(false);
  const [showInput, setShowInput] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [name, setName] = useState("");
  const [templateList, setTemplateList] = useState("");
  const [message, setMessage] = useState("");
  const [textMessage, setTextMessage] = useState("");
  const [templateName, setTemplateName] = useState("");
  const [time, setTime] = useState("");
  const [date, setDate] = useState("");
  const [phone, setPhone] = useState([]);
  const [selected, setSelected] = useState([])
  const [selectedTemplate, setSelectedTemplate] = useState();
  const [filter, setFilter] = useState([]);
  const [resFilter, setResFilter] = useState();
  const [filteredCustomer, setFilteredCustomer] = useState([]);
  const [msg, setMsg] = useState("");
  const [customer, setCustomer] = useState([])
  const [nameCustomer, setNameCustomer] = useState([])
  const [phoneCustomer, setPhoneCustomer] = useState([])
  const [image, setImage] = useState('')

  const [product, setProduct] = useState([])
  const [dataCustomer, setDataCustomer] = useState([]);
  const getCustomer = async () => {
    await getAllCS().then((response) => {
      if (response.data.data) {
        setDataCustomer(response.data.data)
        console.log('cs', response.data)
      } else {
        setDataCustomer([])
      }
    });
  }

  const getOptionCustomer = async () => {
    const res = await getAllCS()
    const data = res.data.data;

    const options = data && data.map(val => ({
      "value": val.phone,
      "label": val.name
    }))

    setCustomer(options)
  }
  const handleChange = (e) => {
    const data = []
    e && e.map(val => (
      data.push(val.value)
    ))
    setSelected(e)
    setPhone(data)
  }
  
  const getBase64 = (file) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
        setImage(reader.result);
        // console.log(reader.result);
    };
};
  const postData = async () => {
    const err = [];
    if (name == "") {
      err.push("Name");
    }
    if (phone == 0) {
      err.push("Phone");
    }
    if (date == "") {
      err.push("Date");
    }
    if (time == "") {
      err.push("Time");
    }
    if (err > 0) {
      setMsg(err);
    } else {
      const form = {
        name: name,
        phone: phone,
        message: message,
        sender: sender,
        date: date,
        time: time,
        nameSession: nameSession,
        image: image
      };
      console.log("form", form);
      try {
        await createBroadcastUn(form)
          .then((response) => {
            navigate("/myDevice");
            console.log(response)
          })
          .catch((e) => {
            console.log(e)
            // if (e.response) {
            //   setMsg(e.response.data.message);
            // }
          });
      } catch (error) {
        console.log(error);
      }
    }
  };
  const postDataCustomer = async () => {
    const err = [];
    if (nameCustomer == "") {
      err.push("Name");
    }
    if (phoneCustomer == "") {
      err.push("Phone");
    } else {
      const form = {
        name: nameCustomer,
        phone: phoneCustomer
      };
      console.log("form", form);
      try {
        await createCustomerUn(form)
          .then((response) => {
            setRefreshApi(true)
            setShowAdd(false)
            console.log(response)
          })
          .catch((e) => {
            console.log(e)
            // if (e.response) {
            //   setMsg(e.response.data.message);
            // }
          });
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    getCustomer()
    getOptionCustomer()
    setRefreshApi(false)
  }, [refreshApi]);

  const signOut = () => {
    logout();
    navigate("/login");
  };

  return (
    <>
      <div className="bg-gradient-to-r from-[#54DAA1] to-[#2DCE89]">
        <div className="relative">
          <aside
            className={
              navSideBar === true
                ? "w-24 h-screen p-5 fixed top-0 left-0 right-0 z-10"
                : "w-3/12 h-screen p-5 fixed top-0 left-0 right-0 z-10"
            }
            aria-label="Sidebar"
          >
            <button
              className="absolute bottom-96 right-1 w-10 h-10 bg-gradient-to-r from-[#54DAA1] to-[#2DCE89] rounded-full shadow-lg flex justify-center items-center"
              onClick={() => setNavSideBar(!navSideBar)}
            >
              {navSideBar ? (
                <svg
                  width="9"
                  height="16"
                  viewBox="0 0 9 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 14.3333L7.66667 7.66668L1 1.00001"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              ) : (
                <svg
                  width="10"
                  height="16"
                  viewBox="0 0 10 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.33325 14.6667L1.66659 7.99999L8.33325 1.33332"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              )}
            </button>
            <SidebarWaUn navSideBar={navSideBar} />
          </aside>
        </div>
        <div
          className={
            navSideBar === true
              ? "ml-24 h-72 w-11/12 p-5 space-y-5"
              : "ml-auto h-72 w-9/12 p-5 space-y-5"
          }
        >
          {/* Header */}
          <div className="flex flex-row justify-between items-center">
            <div className="text-white space-y-2">
              <div className="flex flex-row space-x-3">
                <p className="text-sm">Pages</p>
                <p className="text-sm">/</p>
                <a href="/communication" className="text-sm">
                  Communication
                </a>
                <p className="text-sm">/</p>
                <p className="text-sm">
                  Broadcast
                </p>
              </div>
              <p className="font-semibold">Broadcast</p>
            </div>
            <div className="flex flex-row items-center gap-5">
              <a href="#" className="flex items-center gap-2">
                <svg
                  width="21"
                  height="20"
                  viewBox="0 0 21 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.4938 2.52235C12.7336 1.70165 11.6719 1.24969 10.5 1.24969C9.32191 1.24969 8.25668 1.69891 7.50004 2.51454C6.73519 3.33915 6.36254 4.45985 6.45004 5.67001C6.62348 8.05751 8.44027 9.99969 10.5 9.99969C12.5598 9.99969 14.3735 8.0579 14.5496 5.67079C14.6383 4.47157 14.2633 3.35321 13.4938 2.52235Z"
                    fill="white"
                  />
                  <path
                    d="M17.3752 18.7488H3.62522C3.44525 18.7511 3.26701 18.7133 3.10349 18.6381C2.93997 18.5629 2.79526 18.4522 2.67991 18.314C2.426 18.0105 2.32366 17.596 2.39944 17.1769C2.72913 15.348 3.75804 13.8117 5.37522 12.7332C6.81194 11.7757 8.63186 11.2488 10.5002 11.2488C12.3686 11.2488 14.1885 11.7761 15.6252 12.7332C17.2424 13.8113 18.2713 15.3476 18.601 17.1765C18.6768 17.5957 18.5744 18.0101 18.3205 18.3136C18.2052 18.4519 18.0605 18.5626 17.897 18.6379C17.7335 18.7132 17.5552 18.7511 17.3752 18.7488Z"
                    fill="white"
                  />
                </svg>
                <p className="text-white">{localStorage.getItem("nameUser")}</p>
              </a>
              <button onClick={signOut} className="bg-white p-3 rounded-lg">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.5625 2.8125H2.1875V17.8125H6.5625M13.4375 5.9375L17.8125 10.3125L13.4375 14.6875M7.1875 10.3125H17.8125"
                    stroke="#EF4444"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            </div>
          </div>
          {/* start: Main */}

          <div className="grid grid-cols-12 gap-5">
            <div className="col-span-12">
              <div className="bg-white shadow-lg h-full rounded-lg p-10">
                <div className="flex flex-row justify-between my-2">
                  <div className="flex items-center justify-center gap-3">
                    <a href="/Broadcast-un">
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.11261 0.862514L0.862615 7.11251C0.748814 7.23139 0.659608 7.37157 0.600115 7.52501C0.475092 7.82934 0.475092 8.17069 0.600115 8.47501C0.659608 8.62845 0.748814 8.76863 0.862615 8.88751L7.11261 15.1375C7.22916 15.2541 7.36753 15.3465 7.5198 15.4096C7.67208 15.4727 7.83529 15.5051 8.00011 15.5051C8.33299 15.5051 8.65224 15.3729 8.88761 15.1375C9.12299 14.9021 9.25523 14.5829 9.25523 14.25C9.25523 13.9171 9.12299 13.5979 8.88761 13.3625L4.76261 9.25001H14.2501C14.5816 9.25001 14.8996 9.11832 15.134 8.8839C15.3684 8.64948 15.5001 8.33153 15.5001 8.00001C15.5001 7.66849 15.3684 7.35055 15.134 7.11613C14.8996 6.88171 14.5816 6.75001 14.2501 6.75001H4.76261L8.88761 2.63751C9.00478 2.52131 9.09777 2.38306 9.16123 2.23073C9.22469 2.07841 9.25736 1.91503 9.25736 1.75001C9.25736 1.585 9.22469 1.42162 9.16123 1.26929C9.09777 1.11697 9.00478 0.978718 8.88761 0.862514C8.77141 0.745353 8.63316 0.652359 8.48084 0.588898C8.32851 0.525437 8.16513 0.492765 8.00011 0.492765C7.8351 0.492765 7.67172 0.525437 7.51939 0.588898C7.36707 0.652359 7.22882 0.745353 7.11261 0.862514Z"
                          fill="#A1A1AA"
                        />
                      </svg>
                    </a>
                    <h1 className="text-lg font-bold">Broadcast</h1>
                  </div>
                </div>
                <div className="w-full">
                  <div>
                    {/*begin: FORM DATA BROADCAST*/}
                    <div className="py-5">
                      <div className="bg-[#F5F8FA] rounded-lg px-10 py-10">
                        <div className="space-y-5">
                          {msg != "" ? (
                            <div
                              className="p-3 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800 my-3"
                              role="alert"
                            >
                              <ul class="list-disc list-inside">
                                {msg && msg.map((val) => (
                                  <li>{val} Can't be empty</li>
                                ))}
                              </ul>
                            </div>
                          ) : null}
                          <div className="col-span-6">
                            <span className="text-sm font-bold text-gray-400">
                              Broadcast Name
                            </span>
                            <div className="mt-2">
                              <input
                                type="text"
                                className="bg-white rounded-lg border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                onChange={(e) => setName(e.target.value)}
                              />
                            </div>
                          </div>

                          <div>
                            <span className="text-sm font-bold text-gray-400">
                              Set Send Time
                            </span>
                            <div className="grid md:grid-cols-12 grid-cols-3 gap-3">
                              <div className="col-span-3">
                                <div className="mt-2">
                                  <input
                                    type="date"
                                    className="bg-white rounded-lg border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                    onChange={(e) => setDate(e.target.value)}
                                  />
                                </div>
                              </div>
                              <div className="col-span-3 ">
                                <div className="mt-2">
                                  <input
                                    type="time"
                                    className="bg-white rounded-lg border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                    onChange={(e) => setTime(e.target.value)}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-span-6 grid grid-cols-3 items-end gap-3">
                            {dataCustomer.length > 0 ?
                              <div className="col-span-2">
                                <p className="text-sm font-bold text-gray-400">
                                  Customer
                                </p>
                                <div className="mt-2">
                                  {/* <Select
                                  isMulti
                                  name="colors"
                                  value={customer.value}
                                  defaultValue={customer[1]}
                                  options={customer}
                                  onChange={(e) => handleChange(e)}
                                  className="basic-multi-select"
                                  classNamePrefix="select"
                                /> */}
                                  <MultiSelect
                                    options={customer}
                                    value={selected}
                                    onChange={(e) => handleChange(e)}
                                    labelledBy="Select"
                                  />
                                </div>
                              </div>
                              : null}
                            <div>
                              <button
                                type="button"
                                className="bg-green-400 hover:bg-green-500 text-white rounded-lg px-3 py-2 font-medium flex flex-row gap-3 flex items-center"
                                onClick={() => setShowAdd(true)}
                              >
                                <AddIcon />
                                <span>Add Customer</span>
                              </button>
                            </div>
                          </div>
                          <div className="col-span-6">
                            <span className="text-sm font-bold text-gray-400">
                              Image
                            </span>
                            <div className="mt-2">
                              <input
                                type="file"
                                className="bg-white rounded-lg border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full"
                                onChange={(e) => {
                                  // setProductImage(e.target.files[0]);
                                  getBase64(e.target.files[0]);
                                  // console.log(e.target.files[0]);
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-span-6">
                            <span className="text-sm font-bold text-gray-400">
                              Message
                            </span>
                            <div className="mt-2">
                              <textarea
                                className="bg-white rounded-lg border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                onChange={(e) => setMessage(e.target.value)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/*end: FORM DATA BROADCAST*/}

                    {/* <Select
                      value={selectedOption}
                      onChange={handleChange}
                      options={groupedOptions}
                    /> */}

                    {/*bagin: Button Save */}
                    <div className="flex flex-row justify-center md:justify-end gap-4 py-10">
                      <a
                        href="/broadcast-un"
                        className="text-gray-400 rounded-lg bg-gray-200 hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-200 font-medium text-sm px-5 py-2.5 text-center"
                      >
                        Cancel
                      </a>
                      <button
                        type="submit"
                        className="text-white rounded-lg bg-[#4FC29B] hover:bg-[#4FC29B] focus:ring-4 focus:outline-none focus:ring-[#4FC29B] font-medium text-sm px-5 py-2.5 text-center"
                        onClick={postData}
                      >
                        Save
                      </button>
                    </div>
                    {/*end: Button Save */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* end: Main */}
        </div>

        {showAdd ? (
          <>
            <div className="bg-black bg-opacity-75 overflow-y-auto overflow-x-hidden fixed flex justify-center top-0 left-0 right-0 bottom-0 z-50 w-full md:inset-0 h-modal md:h-full">
              {/* <div className="relative w-full max-w-2xl h-full md:h-auto"> */}
              {/* ini yang ditambahin */}
              <div className="relative fixed w-full max-w-2xl h-full md:h-auto my-5">
                {/* ini yang ditambahin */}
                <div className="relative bg-[#F5F8FA] rounded-lg shadow dark:bg-gray-700">
                  <div className="flex justify-between items-start p-4 rounded-t border-b dark:border-gray-600">
                    <div className="flex flex-col">
                      <h3 className="text-xl font-semibold dark:text-white">
                        Add Customer
                      </h3>
                    </div>
                    <button
                      type="button"
                      className="bg-transparent hover:bg-gray-100 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                      onClick={() => setShowAdd(false)}
                    >
                      <svg
                        className="w-5 h-5"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                    </button>
                  </div>
                  <div className="p-6 space-y-6 items-center">

                    {msg != "" ? (
                      <div
                        className="p-3 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800 my-3"
                        role="alert"
                      >
                        <ul class="list-disc list-inside">
                          {msg && msg.map((val) => (
                            <li>{val} Can't be empty</li>
                          ))}
                        </ul>
                      </div>
                    ) : null}

                    <div>
                      <div className="">
                        <label
                          className="block text-[#A1A5B7] font-semibold text-sm mb-2"
                          for="grid-state"
                        >
                          Name
                        </label>
                        <input
                          type="text"
                          className="bg-white border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full"
                          onChange={(e) => setNameCustomer(e.target.value)}
                        />
                      </div>
                      <div className="">
                        <label
                          className="block text-[#A1A5B7] font-semibold text-sm mb-2"
                          for="grid-state"
                        >
                          Phone
                        </label>
                        <input
                          type="number"
                          className="bg-white border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full"
                          onChange={(e) => setPhoneCustomer(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className="mt-10 flex flex-row justify-end">
                      <button
                        className="px-3 rounded-xl text-gray-400 hover:text-gray-600 font-semibold"
                        onClick={() => setShowAdd(false)}
                      >
                        Cancel
                      </button>
                      <button
                        className="py-2 px-6 rounded-md text-white bg-green-400 hover:bg-green-500 font-semibold ml-2"
                        onClick={postDataCustomer}
                      >
                        Add
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : null}
      </div >
    </>
  );
}

export default CreatedBroadcastUn;
