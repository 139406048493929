import React, { useState, useEffect } from "react";
import Profile from "../../../Assets/img/Profile.jpg";

function TableLoyalty() {
  return (
    <div className="">
      <div className="overflow-x-auto md:h-80">
        <table className="w-full text-sm text-left text-gray-500">
          <thead className="text-sm text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3 w-36">
              CS CRM
              </th>
              <th scope="col" className="px-6 py-3 w-32">
                Customer
              </th>
              <th scope="col" className="px-6 py-3 w-32">
                Product
              </th>
              <th scope="col" className="px-6 py-3 w-32">
                Leveling
              </th>
              <th scope="col" className="px-6 py-3 w-52">
                Date/Time
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="bg-white border-b">
              <td className="px-6 py-4">Rifan Tri Yulianto</td>
              <td className="px-6 py-4"><a href="/overview-customer">Nurhayatun</a></td>
              <td className="px-6 py-4">Freshmag</td>
              <td className="px-6 py-4">Repeat order 10</td>
              <td className="px-6 py-4">2022-01-12 09:59:27</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default TableLoyalty;
