import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../Assets/img/logo.png";

function SetNewPassword() {
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const NewPassword = async (e) => {
    e.preventDefault();

    NewPassword();
    // .then((response) => {
    // console.log(response);
    setShow(true);
    // navigate("/");
    setTimeout(() => {
      navigate("/");
    }, 3000);
    // })
  };
  return (
    <div className="flex flex-col md:flex-row">
      <div className="bg-fg-img h-96 bg-cover md:w-1/2 md:h-screen md:block hidden"></div>
      <div className="md:p-14 p-5 md:w-1/2 flex flex-col items-center gap-10">
        <div className="self-end">
          <img className="md:h-28 h-20" src={logo} />
        </div>
        <div className="w-1/2">
          <div className="space-y-2">
            <h1 className="text-2xl font-bold">Set New Password</h1>
            <p className="text-gray-400 text-sm">
              Please set your new password here
            </p>
          </div>
          <form onSubmit={NewPassword} className="box">
            <div className="space-y-3">
              <div className="relative">
                <input
                  type="password"
                  id="password"
                  className="block rounded-t-lg px-2.5 pb-2.5 pt-5 w-full text-sm text-gray-900 border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                />
                <label
                  for="password"
                  className="absolute flex gap-3 items-center text-sm text-gray-400 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-2.5 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4"
                >
                  <svg
                    width="16"
                    height="21"
                    viewBox="0 0 16 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13 6.5V5.5C13 2.743 10.757 0.5 8 0.5C5.243 0.5 3 2.743 3 5.5V8.5H2C0.897 8.5 0 9.397 0 10.5V18.5C0 19.603 0.897 20.5 2 20.5H14C15.103 20.5 16 19.603 16 18.5V10.5C16 9.397 15.103 8.5 14 8.5H5V5.5C5 3.846 6.346 2.5 8 2.5C9.654 2.5 11 3.846 11 5.5V6.5H13ZM14 10.5L14.002 18.5H2V10.5H14Z"
                      fill="#A1A1AA"
                    />
                  </svg>
                  New Password
                </label>
              </div>
              <div className="relative">
                <input
                  type="password"
                  id="password"
                  className="block rounded-t-lg px-2.5 pb-2.5 pt-5 w-full text-sm text-gray-900 border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                />
                <label
                  for="password"
                  className="absolute flex gap-3 items-center text-sm text-gray-400 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-2.5 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4"
                >
                  <svg
                    width="16"
                    height="21"
                    viewBox="0 0 16 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13 6.5V5.5C13 2.743 10.757 0.5 8 0.5C5.243 0.5 3 2.743 3 5.5V8.5H2C0.897 8.5 0 9.397 0 10.5V18.5C0 19.603 0.897 20.5 2 20.5H14C15.103 20.5 16 19.603 16 18.5V10.5C16 9.397 15.103 8.5 14 8.5H5V5.5C5 3.846 6.346 2.5 8 2.5C9.654 2.5 11 3.846 11 5.5V6.5H13ZM14 10.5L14.002 18.5H2V10.5H14Z"
                      fill="#A1A1AA"
                    />
                  </svg>
                  Confirm. New Password
                </label>
              </div>
              <button className="text-white bg-gradient-to-r from-green-1 to-green-2 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-3 text-center mr-2 mb-2 w-full">
                Save Password
              </button>
            </div>
          </form>
        </div>

        <div
          className={
            show
              ? "block p-4 mb-4 mt-20 text-sm bg-white rounded-lg dark:bg-white dark:text-black flex gap-3 shadow-lg"
              : "hidden p-4 mb-4 mt-20 text-sm bg-white rounded-lg dark:bg-white dark:text-black flex gap-3 shadow-lg"
          }
          role="alert"
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10 18.75C14.8325 18.75 18.75 14.8325 18.75 10C18.75 5.16751 14.8325 1.25 10 1.25C5.16751 1.25 1.25 5.16751 1.25 10C1.25 14.8325 5.16751 18.75 10 18.75ZM5.625 10.2581L6.50813 9.375L8.75 11.6163L13.4906 6.875L14.375 7.75937L8.75 13.3837L5.625 10.2581Z"
              fill="#00A870"
            />
          </svg>
          Password saved
        </div>
        {/* <div className='flex flex-col'>
                    <h1 className='font-bold text-xl text-center pb-10'>
                        Customer Relationship Management
                    </h1>
                    <div>
                        <h1 className='font-bold text-2xl'>Hello,</h1>
                        <h1 className='text-red-600 font-bold text-2xl'>Welcome Back!</h1>
                        <div className='py-10 flex flex-col'>
                            <form onSubmit={Auth} className='box'>
                                <p className='has-text-centered'>{msg}</p>
                                <Input height={'mb-5'} title={'Username'} icon={<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"></path>} placeholder={'Username'} type={'text'} value={username} onChange={(e) => setUsername(e.target.value)}/>
                                <Input title={'Password'} icon={<path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z'></path>} placeholder={'Password'} type={'password'} value={password} onChange={(e) => setPassword(e.target.value)}/>
                                <Link to='/' className='flex justify-end p-2 font-semibold text-gray-500 text-sm hover:text-blue-600'>Forget Password?</Link>
                                <div className='mt-4'>
                                    <button className="text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-3 text-center mr-2 mb-2 w-full">Login</button>
                                </div>
                                <h1 className='text-center p-2 font-semibold text-gray-500 text-sm'>Don’t have an account? <Link to='/create-account' className='text-blue-600'> Sign Up</Link></h1>
                            </form>
                        </div>
                    </div>
                    <h1 className='font-bold text-gray-500 text-center'>Powered By <span className='text-cyan-500'>POWER<span className='text-black'>KERTO</span></span></h1>
                </div> */}
      </div>
    </div>
  );
}

export default SetNewPassword;
