import { ArrowCircleRightOutlined, ArrowLeft, ArrowRight, ExpandLess, ExpandMore, PlayCircleOutline } from '@mui/icons-material';
import { Box, Drawer } from '@mui/material';
import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import logo from "../../Assets/img/logo.png";
import LogoCrm from "../../Assets/img/LogoCrm.png";
import bg from "../../Assets/img/LandingPage/bg-9.png";
import icon1 from "../../Assets/img/LandingPage/icon/1.png";
import icon2 from "../../Assets/img/LandingPage/icon/2.png";
import icon3 from "../../Assets/img/LandingPage/icon/3.png";
import icon4 from "../../Assets/img/LandingPage/icon/4.png";
import icon5 from "../../Assets/img/LandingPage/icon/5.png";
import icon6 from "../../Assets/img/LandingPage/icon/6.png";
import icon7 from "../../Assets/img/LandingPage/icon/7.png";
import icon8 from "../../Assets/img/LandingPage/icon/8.png";
import icon9 from "../../Assets/img/LandingPage/icon/9.png";
import lp1 from "../../Assets/svg/lp1.svg";
import lp2 from "../../Assets/svg/lp2.svg";
import Check1 from "../../Assets/svg/Check1.svg";
import mitra1 from "../../Assets/img/LandingPage/mitra/1.png";
import mitra2 from "../../Assets/img/LandingPage/mitra/2.png";
import mitra3 from "../../Assets/img/LandingPage/mitra/3.png";
import mitra4 from "../../Assets/img/LandingPage/mitra/4.png";
import mitra5 from "../../Assets/img/LandingPage/mitra/5.png";
import mitra6 from "../../Assets/img/LandingPage/mitra/6.png";
import logoWhite from "../../Assets/img/logo-white.png";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import CallIcon from '@mui/icons-material/Call';
import MailIcon from '@mui/icons-material/Mail';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';

function PrivacyPolice() {
    const pathname = window.location.pathname;
    // useEffect(() => {
    //   if (window.localStorage.getItem("users") != null) {
    //     window.location.href = "/";
    //   }
    // }, []);

    const [state, setState] = useState(false);

    return (
        <div>
          
            {/* Content */}
            {/* section1 */}
            <div className="h-full">
              <div className="px-20">
                <div className="flex flex-col justify-center text-center gap-5 w-full pt-[100px]">
                  <h1 className="text-5xl font-bold text-crm-1 text-center">
                  Privacy Policy
                  </h1>
                </div>
                <div className="flex flex-col py-10">
                  
                  <div className="mb-12">
                    <p className="text-gray-800 text-lg font-medium">
                    Welcome to Croya, a web application that provides a platform for customer relationship management. This privacy policy outlines how we collect, use, disclose, and protect the information of our users.
                    </p> 

                  </div>
                 
                  <div className="mb-12">
                    <p className="text-gray-800 text-xl font-bold">
                      Information Collected:
                    </p>
                    <p className="text-gray-800 text-lg font-medium">
                    We collect two types of information - personal information and non-personal information. Personal information includes your name, email address, phone number, and any other information you voluntarily provide during the registration process or while using our platform. We do not enforce the submission of this information, and it is entirely up to you to provide it. Non-personal information includes your IP address, browser type, operating system, and other technical information that does not identify you personally.
                    </p> 

                  </div>
                  <div className="mb-12">
                    <p className="text-gray-800 text-xl font-bold">
                      
                      Use of Information:
                    </p>
                    <p className="text-gray-800 text-lg font-medium">
                    We use the information collected to provide you with the services offered by our platform. We may also use the information to send you promotional emails or newsletters that may be of interest to you. However, we will not sell any personal information to third parties for marketing purposes.
                    </p> 

                  </div>
                  <div className="mb-12">
                    <p className="text-gray-800 text-xl font-bold">
                    Disclosure of Information:
                    </p>
                    <p className="text-gray-800 text-lg font-medium">
                    We may disclose your personal information to third-party service providers who assist us in operating our platform. We may also disclose your information to comply with legal requirements, such as a court order or subpoena.
                    </p> 
                  

                  </div>
                  <div className="mb-12">
                    <p className="text-gray-800 text-xl font-bold">
                    Security Measures:
                    </p>
                    <p className="text-gray-800 text-lg font-medium">
                    We take reasonable measures to protect the information we collect from unauthorized access, disclosure, or modification. We use industry-standard encryption and secure server infrastructure to protect your information.
                    </p> 

                  </div>
                  <div className="mb-12">
                    <p className="text-gray-800 text-xl font-bold">
                    Compliance with Consumer Data Protection Act:
                    </p>
                    <p className="text-gray-800 text-lg font-medium">
                    We are committed to complying with all applicable consumer data protection laws, including the Consumer Data Protection Act in each country where we operate. This means that we will handle your personal information in accordance with applicable laws and regulations
                    </p> 

                  </div>
                  <div className="mb-12">
                    <p className="text-gray-800 text-xl font-bold">
                    Data Retention:
                    </p>
                    <p className="text-gray-800 text-lg font-medium">
                    We will retain your personal information for as long as necessary to provide you with the services you requested or as required by law. We may also retain your information for analytical purposes to improve our services.
                    </p> 

                  </div>
                  <div className="mb-12">
                    <p className="text-gray-800 text-xl font-bold">
                    Cookies and Tracking Technologies:
                    </p>
                    <p className="text-gray-800 text-lg font-medium">
                    We use cookies and other tracking technologies to personalize your experience and track your usage of our platform. You can choose to disable cookies in your browser settings, but this may affect the functionality of our platform.
                    </p> 

                  </div>
                  <div className="mb-12">
                    <p className="text-gray-800 text-xl font-bold">
                    Children's Privacy:
                    </p>
                    <p className="text-gray-800 text-lg font-medium">
                    Our platform is not intended for use by children under the age of 13. We do not knowingly collect personal information from children under the age of 13. If we become aware that we have collected personal information from a child under the age of 13, we will take steps to delete the information as soon as possible.
                    </p> 

                  </div>
                  <div className="mb-12">
                    <p className="text-gray-800 text-xl font-bold">
                    Changes to Privacy Policy:
                    </p>
                    <p className="text-gray-800 text-lg font-medium">
                    We reserve the right to update this privacy policy from time to time. We will notify you of any changes by posting the new privacy policy on our platform. Your continued use of our platform after any changes to this privacy policy constitutes your acceptance of such changes.
                    </p> 

                  </div>
                  <div className="mb-12">
                    <p className="text-gray-800 text-xl font-bold">
                    Contact Us:
                    </p>
                    <p className="text-gray-800 text-lg font-medium">
                    If you have any questions or concerns about this privacy policy, please contact us at support@croya.com.
                    </p> 

                  </div>
        
                </div>
              </div>
            </div>
            {/* section1 */}
            <div className="border bg-crm-4 text-white">
                <div className="lg:mx-16 mx-8 grid lg:grid-cols-12 grid-cols-6 lg:gap-0 gap-5 my-20">
                    <div className="lg:col-span-3 col-span-6">
                        <img src={logoWhite} className="lg:h-36 h-30" />
                        {/* <div className="flex flex-row gap-3 items-center">
                <InstagramLogo className="h-5 w-5 text-blue-6"></InstagramLogo>
                <p className="font-bold">CRM</p>
              </div> */}
                        {/* <div className="flex flex-row gap-3 items-center">
                <InstagramLogo className="h-5 w-5 text-blue-6"></InstagramLogo>
                <p className="font-thin text-sm lg:pr-5">
                  Customer relationship management is an integrated information
                  system used to plan, schedule and control presales and
                  postsales activities.
                </p>
              </div> */}
                    </div>
                    <div className="lg:col-span-3 col-span-6">
                        <div className="flex flex-col gap-2">
                            <a className="font-bold">Office</a>
                            <a className="flex flex-row gap-2">
                                <LocationOnIcon></LocationOnIcon>
                                <p>
                                    Gg. Satria II, Sokayasa, RT04/RW01, Kec. Purwokerto Timur, Kabupaten Banyumas, Jawa Tengah 53146
                                </p>
                            </a>
                            <a className="flex flex-row gap-2">
                                <CallIcon></CallIcon>
                                <p>
                                    083766863242
                                </p>
                            </a>
                            <a className="flex flex-row gap-2">
                                <MailIcon></MailIcon>
                                <p>
                                    info.crm@gmail.com
                                </p>
                            </a>
                        </div>
                    </div>
                    <div className="lg:col-span-2 col-span-3">
                        <div className="flex flex-col gap-2">
                            <a className="font-bold">Features</a>
                            <a>Schedule Broadcast</a>
                            <a>Reporting and Tracking</a>
                            <a>Database Optimization</a>

                        </div>
                    </div>
                    <div className="lg:col-span-2 col-span-3">
                        <div className="flex flex-col gap-2">
                            <a className="font-bold">Company</a>
                            <a href="/about">About Us</a>
                            <a href="/privacy-police">Privacy Policy</a>
                            <a href="/term-of-service">Term of Service</a>
                            <a href="/contact-us">Contact Us</a>
                        </div>
                    </div>
                    <div className="lg:col-span-2 col-span-3">
                        <div className="flex flex-col gap-2">
                            <a className="font-bold">Social Media</a>
                            <div className="flex gap-4">
                                <LinkedInIcon></LinkedInIcon>
                                <InstagramIcon></InstagramIcon>
                                <FacebookIcon></FacebookIcon>
                                <TwitterIcon></TwitterIcon>

                            </div>
                        </div>
                    </div>

                </div>
                <div className="bg-blue-4 text-white text-center p-2">
                    © 2022 Customer Relationship Management
                </div>
            </div>
            {/* Content */}
        </div>
    )
}

export default PrivacyPolice













