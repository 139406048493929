import React, { useState } from "react";
import RepeaterLeadSource from "../Input/RepeaterLeadSource";

function AddCustomer({}) {
  const [showInput, setShowInput] = useState(false);

  return (
    <>
      <button
        type="button"
        className="bg-gray-100 hover:bg-gray-200 text-gray-400 rounded-lg px-3 py-2 font-medium flex flex-row gap-3 flex items-center"
        onClick={() => setShowInput(true)}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          className="bi bi-plus-lg"
          viewBox="0 0 16 16"
        >
          <path
            fillRule="evenodd"
            d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"
          />
        </svg>
        <span>Add Customer</span>
      </button>
      {showInput ? (
        <>
          <div className="bg-black bg-opacity-75 overflow-y-auto overflow-x-hidden fixed flex justify-center top-0 left-0 right-0 bottom-0 z-50 w-full md:inset-0 h-modal md:h-full">
            {/* <div className="relative w-full max-w-2xl h-full md:h-auto"> */}
            {/* ini yang ditambahin */}
            <div className="fixed inset-y-0 right-0 z-50 w-full max-w-2xl h-full md:h-auto overflow-y-auto w-1/3">
              {/* ini yang ditambahin */}
              <div className="relative bg-white min-h-screen rounded-lg shadow dark:bg-gray-700">
                <div className="bg-green-400 flex justify-between items-start p-4 rounded-t border-b dark:border-gray-600">
                  <h3 className="text-xl font-semibold text-white dark:text-white">
                    Add Customer
                  </h3>
                  <button
                    type="button"
                    className="text-white bg-transparent hover:bg-green-600 hover:text-white rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                    onClick={() => setShowInput(false)}
                  >
                    <svg
                      className="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </button>
                </div>
                <div className="p-6 space-y-6 items-center">
                  <div className="">
                    <label
                      className="block tracking-wide text-gray-700 text-sm font-bold mb-2"
                      for="grid-state"
                    >
                      Loyalty Name
                    </label>
                    <div className="relative">
                      <select
                        className="block appearance-none w-full bg-gray-100 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      >
                        <option>Select Customer Service CRM</option>
                        <option>CS 1</option>
                        <option>CS 2</option>
                      </select>
                      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"></div>
                    </div>
                  </div>
                  <div className="">
                    <label
                      className="block tracking-wide text-gray-700 text-sm font-bold mb-2"
                      for="grid-state"
                    >
                      Customer Name
                    </label>
                    <input
                      type="text"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full"
                      placeholder=""
                    />
                  </div>
                  <div className="">
                    <label
                      className="block tracking-wide text-gray-700 text-sm font-bold mb-2"
                      for="grid-state"
                    >
                      Customer Number
                    </label>
                    <input
                      type="text"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full"
                      placeholder=""
                    />
                  </div>
                  <div className="">
                    <label
                      className="block tracking-wide text-gray-700 text-sm font-bold mb-2"
                      for="grid-state"
                    >
                      Email
                    </label>
                    <input
                      type="email"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full"
                      placeholder=""
                    />
                  </div>
                  <div className="">
                    <label
                      className="block tracking-wide text-gray-700 text-sm font-bold mb-2"
                      for="grid-state"
                    >
                      Product
                    </label>
                    <div className="relative">
                      <select
                        className="block appearance-none w-full bg-gray-100 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      >
                        <option>Select Product</option>
                        <option>Product 1</option>
                        <option>Product 2</option>
                      </select>
                      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"></div>
                    </div>
                  </div>
                  <div className="">
                    <label
                      className="block tracking-wide text-gray-700 text-sm font-bold mb-2"
                      for="grid-state"
                    >
                      Level
                    </label>
                    <div className="relative">
                      <select
                        className="block appearance-none w-full bg-gray-100 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      >
                        <option>Select Level</option>
                        <option>Level 1</option>
                        <option>Level 2</option>
                      </select>
                      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"></div>
                    </div>
                  </div>
                  <div className="">
                    <label
                      className="block tracking-wide text-gray-700 text-sm font-bold mb-2"
                      for="grid-state"
                    >
                      Date
                    </label>
                    <input
                      type="date"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full"
                      placeholder=""
                    />
                  </div>

                  <div className="mt-10 flex flex-row justify-end">
                    <button
                      className="px-3 rounded-xl text-gray-400 hover:text-gray-600 font-semibold"
                      onClick={() => setShowInput(false)}
                    >
                      Cancel
                    </button>
                    <button className="py-2 px-3 rounded-xl text-green-400 hover:text-green-600 font-semibold ml-2">
                      Add Customer
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
}

export default AddCustomer;
