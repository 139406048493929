import React, { Component, useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import CardDashboad from "../../Components/Partials/Card/CardDashboard";
import Sidebar from "../../Components/Sidebar/Sidebar";
import people from "../../Assets/icon/dashboard/people.svg";
import basket from "../../Assets/icon/dashboard/basket.svg";
import ribbon from "../../Assets/icon/dashboard/ribbon.svg";
import cube from "../../Assets/icon/dashboard/cube.svg";
import ChartRevenue from "../../Components/Partials/Chart/New/ChartRevenue";
import CardSalesByDistrict from "../../Components/Partials/Card/CardSalesByDistrict";
import ChartSalesByAge from "../../Components/Partials/Chart/New/ChartSalesByAge";
import ChartSalesByMaritalStatus from "../../Components/Partials/Chart/New/ChartSalesByMaritalStatus";
import ChartSalesByGender from "../../Components/Partials/Chart/New/ChartSalesByGender";
import TableDataCustomer from "../../Components/Partials/Table/TableDataCustomer";
import AddCustomerDashboard from "../../Components/Partials/Modal/AddCustomerDashboard";
import FilterDataCustomer from "../../Components/Partials/Modal/FilterDataCustomer";
import AddBroadcast from "../../Components/Partials/Modal/AddBroadcast";
import AOS from "aos";
import "aos/dist/aos.css";
import { logout } from "../../Network/Lib/auth";
import "./app.css";
import { getClosing, getOmset, getOmsetProduct, getTotalOrder, getCountCs, getHeader } from "../../Network/Lib/dashboard";

function Dashboard() {
  const [navSideBar, setNavSideBar] = useState(false);
  const [refreshApi, setRefreshApi] = useState(false);
  const [filter, setFilter] = useState([]);
  const [filteredCustomer, setFilteredCustomer] = useState([]);

  const [omset, setOmset] = useState([]);
  const [omsetProduct, setOmsetProduct] = useState([]);
  const [closing, setClosing] = useState([]);
  const [totalOrder, setTotalOrder] = useState([]);
  const [countCs, setCountCs] = useState([]);

  const [dataHeader, setDataHeader] = useState([])
  // const fetchApi = async () => {
  //   const maried = await getMartial();
  //   const single = await getMartial();
  //   const divorced = await getMartial();
  //   console.log("martial: ", maried.data);
  //   // setmaleData(dailyData.data);
  //   // setLenght(dailyData.data);
  //   setMaried(maried.data.married)
  //   setSingle(single.data.single)
  //   setDivorced(divorced.data.divorced)
  // };

  // useEffect(() => {
  //   fetchApi();
  // }, []);

  const navigate = useNavigate();

  const onFilteredCustomer = (data) => {
    setFilteredCustomer(data);
  };


  console.log("hasil Filter ", filteredCustomer)
  console.log("params Filter ", filter)



  const [loading, setLoading] = useState(false);
  // const location = useLocation();
  // const state = location.state;

  const onFinishedCreate = async () => {
    setRefreshApi(true);
  };

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
    getHeader().then((response) => {
      console.log('header', response.data.data)
      setDataHeader(response.data.data)
    })
    getCountCs().then((response) => {
      // console.log("cs:  ", response.data.data);

      setCountCs(response.data.data);
    });
  }, [refreshApi]);

  const signOut = () => {
    logout();
    navigate('/login')
  }
  return (
    <>
      {loading ? (
        <div className="loader-container">
          <div className="spinner"></div>
        </div>
      ) : (
        <div className="bg-gradient-to-r from-[#54DAA1] to-[#2DCE89]">
          <div className="relative">
            <aside
              className={
                navSideBar === true
                  ? "w-24 h-screen p-5 fixed top-0 left-0 right-0 z-10"
                  : "w-3/12 h-screen p-5 fixed top-0 left-0 right-0 z-10"
              }
              aria-label="Sidebar"
            >
              <button
                className="absolute top-72 right-1 w-10 h-10 bg-gradient-to-r from-[#54DAA1] to-[#2DCE89] rounded-full shadow-lg flex justify-center items-center"
                onClick={() => setNavSideBar(!navSideBar)}
              >
                {navSideBar ? (
                  <svg
                    width="9"
                    height="16"
                    viewBox="0 0 9 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 14.3333L7.66667 7.66668L1 1.00001"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                ) : (
                  <svg
                    width="10"
                    height="16"
                    viewBox="0 0 10 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.33325 14.6667L1.66659 7.99999L8.33325 1.33332"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                )}
              </button>
              <Sidebar navSideBar={navSideBar} />
            </aside>
          </div>
          <div
            className={
              navSideBar === true
                ? "ml-24 h-72 w-11/12 p-5 space-y-5"
                : "ml-auto h-72 w-9/12 p-5 space-y-5"
            }
          >
            {/* Header */}
            <div className="flex flex-row justify-between items-center">
              <div className="text-white space-y-2">
                <div className="flex flex-row space-x-3">
                  <p className="text-sm">Pages</p>
                  <p className="text-sm">/</p>
                  <Link to="/dashboard" className="text-sm">
                    Dashboard
                  </Link>
                </div>
                <p className="font-semibold">Dashboard</p>
              </div>
              <div className="flex flex-row items-center gap-5">
                <Link to="#" className="flex items-center gap-2">
                  <svg
                    width="21"
                    height="20"
                    viewBox="0 0 21 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.4938 2.52235C12.7336 1.70165 11.6719 1.24969 10.5 1.24969C9.32191 1.24969 8.25668 1.69891 7.50004 2.51454C6.73519 3.33915 6.36254 4.45985 6.45004 5.67001C6.62348 8.05751 8.44027 9.99969 10.5 9.99969C12.5598 9.99969 14.3735 8.0579 14.5496 5.67079C14.6383 4.47157 14.2633 3.35321 13.4938 2.52235Z"
                      fill="white"
                    />
                    <path
                      d="M17.3752 18.7488H3.62522C3.44525 18.7511 3.26701 18.7133 3.10349 18.6381C2.93997 18.5629 2.79526 18.4522 2.67991 18.314C2.426 18.0105 2.32366 17.596 2.39944 17.1769C2.72913 15.348 3.75804 13.8117 5.37522 12.7332C6.81194 11.7757 8.63186 11.2488 10.5002 11.2488C12.3686 11.2488 14.1885 11.7761 15.6252 12.7332C17.2424 13.8113 18.2713 15.3476 18.601 17.1765C18.6768 17.5957 18.5744 18.0101 18.3205 18.3136C18.2052 18.4519 18.0605 18.5626 17.897 18.6379C17.7335 18.7132 17.5552 18.7511 17.3752 18.7488Z"
                      fill="white"
                    />
                  </svg>
                  <p className="text-white">{localStorage.getItem("nameUser")}</p>
                </Link>
                <button onClick={signOut} className="bg-white p-3 rounded-lg">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.5625 2.8125H2.1875V17.8125H6.5625M13.4375 5.9375L17.8125 10.3125L13.4375 14.6875M7.1875 10.3125H17.8125"
                      stroke="#EF4444"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
              </div>
            </div>
            {/* start: Main */}
            <div className="grid grid-cols-4 gap-5">
              <CardDashboad
                icon={people}
                title={"OMZET ALL TEAM"}
                subtitle={
                  dataHeader.omsetTeam != null ? (`Rp. ${parseInt(dataHeader.omsetTeam).toLocaleString()}`) : "Rp. 0"
                }
              />
              <CardDashboad
                icon={basket}
                title={"OMZET ALL PRODUCT"}
                subtitle={dataHeader.omsetProduct != null ? (`Rp. ${parseInt(dataHeader.omsetProduct).toLocaleString()}`) : "Rp. 0"}
              />
              <CardDashboad
                icon={ribbon}
                title={"TOTAL CLOSING"}
                subtitle={dataHeader.totalClossing != null ? (`${parseInt(dataHeader.totalClossing).toLocaleString()}`) : "0"}
              />
              <CardDashboad
                icon={cube}
                title={"TOTAL BOTTLE"}
                subtitle={dataHeader.totalBottle != null ? (`${parseInt(dataHeader.totalBottle).toLocaleString()}`) : "0"}
              />
            </div>
            <div className="grid grid-cols-3 gap-5">
              <div className="col-span-2">
                <ChartRevenue />
              </div>
              <div className="">
                <CardSalesByDistrict />
              </div>
            </div>
            <div className="grid grid-cols-3 gap-5">
              <ChartSalesByAge />
              <ChartSalesByMaritalStatus />
              <ChartSalesByGender />
            </div>
            <div className="pb-10">
              <div className="p-10 space-y-6 shadow-lg bg-white rounded-md">
                <div className="flex flex-col md:flex-row justify-between items-start md:items-center">
                  <div className="flex flex-col">
                    <h1 className="text-xl font-bold">Data Customer</h1>
                    <h1 className="text-gray-500 font-semibold">
                      {/* {count_lead.toLocaleString()}  */}{filteredCustomer != null ? filteredCustomer.length : 0} data

                    </h1>
                  </div>
                  <div className="flex flex-col md:flex-row items-start md:items-center gap-2">
                    <AddCustomerDashboard onFinishCreate={onFinishedCreate} />
                    <FilterDataCustomer onFilterCustomer={setFilter} />
                    <AddBroadcast filteredCustomer={filteredCustomer} filter={filter} />
                    <div className="flex flex-row rounded-lg bg-gray-50 border border-gray-300 mt-1">
                      <input
                        type="text"
                        id="table-search"
                        className="bg-transparent border-none text-gray-700 leading-tight focus:outline-none focus:ring-transparent"
                        placeholder="Search"
                      />
                      <div className="flex items-center pr-3 pointer-events-none">
                        <svg
                          className="w-5 h-5 text-gray-500 dark:text-gray-400"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
                <TableDataCustomer
                  filter={filter}
                  onFilteredCustomer={onFilteredCustomer}
                />
              </div>
              {/* end: Main */}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Dashboard;
