const semester = {
  labels: [
    "Jan",
    "Feb",
    "March",
    "April",
    "May",
    "June",
  ],
  datasets: [
    {
      fill: true,
      label: "bottle",
      data: [65, 59, 80, 81, 56, 77],
      borderColor: "rgb(0, 255, 0)",
      backgroundColor: ["rgba(79, 194, 155, 0.8)"],
    },
  ],
};

export default semester;
