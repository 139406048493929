import api from "../apiClient";

export function createProduct(data) {
  return api.axiosClient.post("product/store", data);
}

export function getAllProduct() {
  return api.axiosClient.get("/product");
}

export function countProduct(data) {
  return api.axiosClient.get("/countMyProduct");
}

export function deleteProduct(id) {
  return api.axiosClient.post("/product/delete", { id: id });
}
export function showProduct(id) {
  return api.axiosClient.post(`/product/show`, { id: id });
}

export function updateProduct(id, data) {
  return api.axiosClient.post(`/product/update/${id}`, data);
}
