import "aos/dist/aos.css";
import React, { useState } from "react";
import AddCS from "../../Components/Partials/Modal/AddCS";
import SidebarComunication from "../../Components/Sidebar/SidebarComunication";
import CardTemplate from "../../Components/Partials/Card/CardTemplate";
import arrowLeft from "../../Assets/icon/comunication/arrowLeft.svg";
import leveling from "../../Assets/icon/Leveling.png";
import leveling1 from "../../Assets/icon/Leveling-1.png";
import leveling2 from "../../Assets/icon/Leveling-2.png";
import leveling3 from "../../Assets/icon/Leveling-3.png";
import card from "../../Assets/svg/Card.svg";
import loyalty from "../../Assets/icon/Loyalty.png";
import AddTemplate from "../../Components/Partials/Modal/AddTemplate";
import AddLoyalty from "../../Components/Partials/Modal/AddLoyalty";
import TableLoyalty from "../../Components/Partials/Table/TableLoyalty";
import user from "../../Assets/svg/User.svg";
import circle from "../../Assets/svg/Circle.svg";
import Delete from "../../Assets/img/Delete-data.png";
import { logout } from "../../Network/Lib/auth";
import { useNavigate } from "react-router-dom";

function OverviewLoyalty() {
  const navigate = useNavigate();
  const [navSideBar, setNavSideBar] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showEditProduct, setShowEditProduct] = useState(false);

  
  const signOut =()=>{
    logout();
    navigate('/login')
  }
  return (
    <>
      <div className="bg-gradient-to-r from-[#54DAA1] to-[#2DCE89]">
        <div className="relative">
          <aside
            className={
              navSideBar === true
                ? "w-24 h-screen p-5 fixed top-0 left-0 right-0 z-10"
                : "w-3/12 h-screen p-5 fixed top-0 left-0 right-0 z-10"
            }
            aria-label="Sidebar"
          >
            <button
              className="absolute bottom-96 right-1 w-10 h-10 bg-gradient-to-r from-[#54DAA1] to-[#2DCE89] rounded-full shadow-lg flex justify-center items-center"
              onClick={() => setNavSideBar(!navSideBar)}
            >
              {navSideBar ? (
                <svg
                  width="9"
                  height="16"
                  viewBox="0 0 9 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 14.3333L7.66667 7.66668L1 1.00001"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              ) : (
                <svg
                  width="10"
                  height="16"
                  viewBox="0 0 10 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.33325 14.6667L1.66659 7.99999L8.33325 1.33332"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              )}
            </button>
            <SidebarComunication navSideBar={navSideBar} />
          </aside>
        </div>
        <div
          className={
            navSideBar === true
              ? "ml-24 h-72 w-11/12 p-5 space-y-5"
              : "ml-auto h-72 w-9/12 p-5 space-y-5"
          }
        >
          {/* Header */}
          <div className="flex flex-row justify-between items-center">
            <div className="text-white space-y-2">
              <div className="flex flex-row space-x-3">
                <p className="text-sm">Pages</p>
                <p className="text-sm">/</p>
                <a href="/communication" className="text-sm">
                  Communication
                </a>
                <p className="text-sm">/</p>
                <a href="/loyalty" className="text-sm">
                  Loyalty
                </a>
                <p className="text-sm">/</p>
                <a href="/overview-loyalty" className="text-sm">
                  Detail Loyalty
                </a>
              </div>
              <p className="font-semibold">Detail Loyalty</p>
            </div>
            <div className="flex flex-row items-center gap-5">
              <a href="#" className="flex items-center gap-2">
                <svg
                  width="21"
                  height="20"
                  viewBox="0 0 21 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.4938 2.52235C12.7336 1.70165 11.6719 1.24969 10.5 1.24969C9.32191 1.24969 8.25668 1.69891 7.50004 2.51454C6.73519 3.33915 6.36254 4.45985 6.45004 5.67001C6.62348 8.05751 8.44027 9.99969 10.5 9.99969C12.5598 9.99969 14.3735 8.0579 14.5496 5.67079C14.6383 4.47157 14.2633 3.35321 13.4938 2.52235Z"
                    fill="white"
                  />
                  <path
                    d="M17.3752 18.7488H3.62522C3.44525 18.7511 3.26701 18.7133 3.10349 18.6381C2.93997 18.5629 2.79526 18.4522 2.67991 18.314C2.426 18.0105 2.32366 17.596 2.39944 17.1769C2.72913 15.348 3.75804 13.8117 5.37522 12.7332C6.81194 11.7757 8.63186 11.2488 10.5002 11.2488C12.3686 11.2488 14.1885 11.7761 15.6252 12.7332C17.2424 13.8113 18.2713 15.3476 18.601 17.1765C18.6768 17.5957 18.5744 18.0101 18.3205 18.3136C18.2052 18.4519 18.0605 18.5626 17.897 18.6379C17.7335 18.7132 17.5552 18.7511 17.3752 18.7488Z"
                    fill="white"
                  />
                </svg>
                <p className="text-white">{localStorage.getItem("nameUser")}</p>
              </a>
              <button onClick={signOut} className="bg-white p-3 rounded-lg">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.5625 2.8125H2.1875V17.8125H6.5625M13.4375 5.9375L17.8125 10.3125L13.4375 14.6875M7.1875 10.3125H17.8125"
                      stroke="#EF4444"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
            </div>
          </div>
          {/* start: Main */}

          <div className="grid grid-cols-12 gap-5">
            <div className="col-span-12">
              <div className="bg-white shadow-lg h-full rounded-lg p-10">
                <div className="flex flex-row justify-between">
                  <div className="flex items-center justify-center gap-3">
                    <a href="/loyalty">
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.11261 0.862514L0.862615 7.11251C0.748814 7.23139 0.659608 7.37157 0.600115 7.52501C0.475092 7.82934 0.475092 8.17069 0.600115 8.47501C0.659608 8.62845 0.748814 8.76863 0.862615 8.88751L7.11261 15.1375C7.22916 15.2541 7.36753 15.3465 7.5198 15.4096C7.67208 15.4727 7.83529 15.5051 8.00011 15.5051C8.33299 15.5051 8.65224 15.3729 8.88761 15.1375C9.12299 14.9021 9.25523 14.5829 9.25523 14.25C9.25523 13.9171 9.12299 13.5979 8.88761 13.3625L4.76261 9.25001H14.2501C14.5816 9.25001 14.8996 9.11832 15.134 8.8839C15.3684 8.64948 15.5001 8.33153 15.5001 8.00001C15.5001 7.66849 15.3684 7.35055 15.134 7.11613C14.8996 6.88171 14.5816 6.75001 14.2501 6.75001H4.76261L8.88761 2.63751C9.00478 2.52131 9.09777 2.38306 9.16123 2.23073C9.22469 2.07841 9.25736 1.91503 9.25736 1.75001C9.25736 1.585 9.22469 1.42162 9.16123 1.26929C9.09777 1.11697 9.00478 0.978718 8.88761 0.862514C8.77141 0.745353 8.63316 0.652359 8.48084 0.588898C8.32851 0.525437 8.16513 0.492765 8.00011 0.492765C7.8351 0.492765 7.67172 0.525437 7.51939 0.588898C7.36707 0.652359 7.22882 0.745353 7.11261 0.862514Z"
                          fill="#A1A1AA"
                        />
                      </svg>
                    </a>
                    <h1 className="text-lg font-bold">Detail Loyalty</h1>
                  </div>
                </div>
                <div className="py-10">
                  <div className="shadow-md rounded-lg p-5 bg-[#5E6278] text-white">
                    <div className="flex flex-col gap-5 p-5">
                      <div className="flex flex-col md:flex-row justify-between gap-5">
                        <p className="font-bold text-xl">Member Card Silver</p>
                        <div className="flex flex-row items-start gap-5">
                          <a
                            href="#"
                            className="text-white hover:text-gray-400"
                            onClick={() => setShowEditProduct(true)}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              fill="currentColor"
                              className="bi bi-pencil-square"
                              viewBox="0 0 16 16"
                            >
                              <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                              <path
                                fillRule="evenodd"
                                d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                              />
                            </svg>
                          </a>
                          <a
                            href="#"
                            className="text-white hover:text-gray-400"
                            onClick={() => setShowDelete(true)}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              fill="currentColor"
                              className="bi bi-trash-fill"
                              viewBox="0 0 16 16"
                            >
                              <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z" />
                            </svg>
                          </a>
                        </div>
                      </div>
                      <div className="flex flex-col md:flex-row gap-5">
                        <div className="py-1 gap-3">
                          <p className="font-medium text-start">
                            Repeat Order 1 - Repeat Order 10
                          </p>
                          <div className="flex flex-row items-center gap-3">
                            <img src={user} />
                            <p className="font-medium text-start">
                              500 Customer
                            </p>
                          </div>
                          <div className="flex flex-row items-center gap-4">
                            <img src={circle} />
                            <p className="font-medium text-start">
                              50,000 Minimum Point Redemption
                            </p>
                          </div>
                        </div>
                        <div className="py-1 gap-3">
                          <div className="flex flex-col md:flex-row gap-3">
                            <p className="font-medium text-start">
                              Advantages :
                            </p>
                            <div>
                              <p className="font-medium text-start">
                                1. Voucher diskon harga 10%
                              </p>
                              <p className="font-medium text-start">
                                2. Request ekspedisi tercepat
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="py-10 flex flex-col md:flex-row justify-between items-start md:items-center">
                    <div className="flex flex-col">
                      <h1 className="text-xl font-bold">Customer Data</h1>
                      <h1 className="text-gray-500 font-semibold">100 Leads</h1>
                    </div>
                    <div className="flex flex-col md:flex-row items-start md:items-center gap-2">
                      <div className="flex flex-row rounded-lg bg-gray-50 border border-gray-300 mt-1">
                        <input
                          type="text"
                          id="table-search"
                          className="appearance-none bg-transparent border-none w-full text-gray-700 leading-tight focus:outline-none focus:ring-transparent w-64"
                          placeholder="Search"
                        />
                        <div className="flex items-center pr-3 pointer-events-none">
                          <svg
                            className="w-5 h-5 text-gray-500 dark:text-gray-400"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                              clipRule="evenodd"
                            ></path>
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                  <TableLoyalty />
                </div>

                {showEditProduct ? (
                  <>
                    <div className="bg-black bg-opacity-75 overflow-y-auto overflow-x-hidden fixed flex justify-center top-0 left-0 right-0 bottom-0 z-50 w-full md:inset-0 h-modal md:h-full">
                      {/* <div className="relative w-full max-w-2xl h-full md:h-auto"> */}
                      {/* ini yang ditambahin */}
                      <div className="relative fixed w-full max-w-2xl h-full md:h-auto my-5">
                        {/* ini yang ditambahin */}
                        <div className="relative bg-[#F5F8FA] rounded-lg shadow dark:bg-gray-700">
                          <div className="flex justify-between items-start p-4 dark:border-gray-600">
                            <h3 className="text-xl font-semibold">
                              Edit Loyalty
                            </h3>
                            <button
                              type="button"
                              className="rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                              onClick={() => setShowEditProduct(false)}
                            >
                              <svg
                                className="w-5 h-5"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                  clipRule="evenodd"
                                ></path>
                              </svg>
                            </button>
                          </div>
                          <div className="p-6 space-y-6 items-center">
                            <div className="">
                              <label
                                className="block tracking-wide text-gray-700 text-sm font-bold mb-2"
                                for="grid-state"
                              >
                                Loyalty Name
                              </label>
                              <input
                                type="text"
                                className="border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full"
                                placeholder=""
                              />
                            </div>
                            <div className="">
                              <label
                                className="block tracking-wide text-gray-700 text-sm font-bold mb-2"
                                for="grid-state"
                              >
                                Leveling Conditions
                              </label>
                              <div className="flex flex-row justify-between gap-5">
                                <div className="basis-1/2">
                                  <div className="relative">
                                    <select
                                      className="block appearance-none w-full border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                      id="grid-state"
                                    >
                                      <option hidden>From Level</option>
                                      <option>CS 1</option>
                                      <option>CS 2</option>
                                    </select>
                                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"></div>
                                  </div>
                                </div>
                                <div className="basis-1/2">
                                  <div className="relative">
                                    <select
                                      className="block appearance-none w-full border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                      id="grid-state"
                                    >
                                      <option hidden>To Level</option>
                                      <option>CS 1</option>
                                      <option>CS 2</option>
                                    </select>
                                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="">
                              <label
                                className="block tracking-wide text-gray-700 text-sm font-bold mb-2"
                                for="grid-state"
                              >
                                Loyalty program to be earned (Advantages)
                              </label>
                              <textarea
                                type="text"
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full"
                                placeholder=""
                                rows={10}
                              />
                            </div>
                            <div className="">
                              <label
                                className="block tracking-wide text-gray-700 text-sm font-bold mb-2"
                                for="grid-state"
                              >
                                Minimum point redemption
                              </label>
                              <input
                                type="text"
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full"
                                placeholder=""
                              />
                            </div>
                            <div className="">
                              <label
                                className="block tracking-wide text-gray-700 text-sm font-bold mb-2"
                                for="grid-state"
                              >
                                Select Color
                              </label>
                              <input
                                type="color"
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full"
                                placeholder=""
                              />
                            </div>

                            <div className="mt-10 flex flex-row justify-end">
                              <button
                                className="px-3 rounded-xl text-gray-400 hover:text-gray-600 font-semibold"
                                onClick={() => setShowEditProduct(false)}
                              >
                                Cancel
                              </button>
                              <button className="py-2 px-5 rounded-md text-white bg-green-400 hover:bg-green-500 font-semibold ml-2">
                                Save Change
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : null}
                {showDelete ? (
                  <>
                    <div className="bg-black bg-opacity-75 overflow-y-auto overflow-x-hidden fixed flex justify-center top-0 left-0 right-0 bottom-0 z-50 w-full md:inset-0 md:h-full">
                      {/* <div className="relative w-full max-w-2xl h-full md:h-auto"> */}
                      {/* ini yang ditambahin */}
                      <div className="fixed w-full max-w-xl md:h-auto mt-40">
                        {/* ini yang ditambahin */}
                        <div className="relative bg-[#F5F8FA] rounded-lg shadow dark:bg-gray-700">
                          <div className="p-6 space-y-6 items-center">
                            <div className="flex flex-row justify-between items-start gap-5">
                              <div className="flex flex-row gap-5">
                                <img
                                  src={Delete}
                                  className="w-100 object-cover"
                                />
                                <div className="flex flex-col">
                                  <h1 className="font-bold">Delete Loyalty?</h1>
                                  <p className="font-semibold text-gray-400">
                                    Loyalty will be permanently deleted
                                  </p>
                                  <div className="mt-10 flex flex-row justify-start gap-3">
                                    <button className="text-sm py-2 px-5 rounded-xl text-white bg-green-400 hover:bg-green-500 font-semibold">
                                      Yes
                                    </button>
                                    <button
                                      className="text-sm px-3 rounded-xl text-gray-400 hover:text-gray-600 bg-gray-100 hover:bg-gray-200 font-semibold"
                                      onClick={() => setShowDelete(false)}
                                    >
                                      Cancel
                                    </button>
                                  </div>
                                </div>
                              </div>
                              <button
                                type="button"
                                className="bg-transparent hover:bg-gray-100 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                                onClick={() => setShowDelete(false)}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  fill="#A1A5B7"
                                  className="bi bi-x-circle"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                </svg>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          </div>

          {/* end: Main */}
        </div>
      </div>
    </>
  );
}

export default OverviewLoyalty;
