import React, { Component, useState } from "react";
import { Bar, Line } from "react-chartjs-2";
import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import achievement from "../../../Data/Reporting/achievement";
import {
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@heroicons/react/solid";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import database from "../../../Data/Reporting/database";

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
  },
};

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function ChartDatabase() {
  return (
    <>
      <div className="border-2 rounded-lg m-1 space-y-5">
        <div className="flex flex-row justify-between px-10 mt-5">
          <h1 className="font-bold text-lg">Report Database</h1>
        </div>
        <div className="flex flex-col md:flex-row gap-3">
          <div className="mx-5">
            <div className="relative w-fit">
              <select className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
                <option>Report Testimoni</option>
                <option>Report Komplain</option>
                <option>Report Issue</option>
                <option>Report Cust Satisfaction</option>
                <option>Report Database Sudah di FU</option>
                <option>Report Database belum di FU</option>
              </select>
            </div>
          </div>
          <div className="mx-5">
            <div className="relative w-fit">
              <select className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
                <option hidden>Select Team</option>
                <option>Acq Facebook</option>
                <option>Acq TikTok</option>
                <option>Market Place</option>
                <option>Herbathos</option>
              </select>
            </div>
          </div>
          <div className="mx-5">
            <div className="relative w-fit">
              <select className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
                <option hidden>Select Product</option>
                <option>All Product</option>
                <option>Etawaku Platinum</option>
                <option>Gizidat</option>
                <option>Rube</option>
                <option>Freshmag</option>
                <option>Fresh Vision</option>
                <option>Nutriflakes</option>
                <option>Weight Herba</option>
                <option>Generos</option>
              </select>
            </div>
          </div>
          <div className="mx-5">
            <div className="relative w-fit">
              <select className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
                <option hidden>Select Member</option>
                <option>Silver</option>
                <option>Gold</option>
                <option>Platinum</option>
              </select>
            </div>
          </div>
          <div className="mx-5">
            <div className="relative w-fit">
              <select className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
                <option hidden>Select Database</option>
                <option>All database</option>
                <options>RO 1</options>
                <options>RO 2</options>
                <options>RO 3</options>
                <options>RO 4</options>
                <options>RO 5</options>
                <options>RO 6</options>
                <options>RO 7</options>
                <options>RO 8</options>
                <options>RO 9</options>
                <options>RO 10</options>
                <options>RO Next</options>
                <options>Reseller Low</options>
                <options>Reseller Middle</options>
                <options>Reseller High</options>
                <options>Sudah Closing</options>
                <options>Belum Closing</options>
                <options>Tidak Aktif >3 bulan</options>
              </select>
            </div>
          </div>
        </div>
        <div className="flex flex-col md:flex-row md:items-center items-start mx-5">
          <input
            type={"date"}
            className="border-t-transparent border-r-transparent border-l-transparent"
          />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-dash"
            viewBox="0 0 16 16"
          >
            <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z" />
          </svg>
          <input
            type={"date"}
            className="border-t-transparent border-r-transparent border-l-transparent"
          />
        </div>
        <div className="px-16">
          <Line options={options} data={database} />
        </div>
      </div>
    </>
  );
}

export default ChartDatabase;
