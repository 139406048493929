import axios from "axios";
import api from "../apiClient";
import { BASE_URL_B } from "../../config";
const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
};
export function createCustomerUn(data) {
    return axios.post(`${BASE_URL_B}create-customer-broadcast-unofficial`, data, config)
}
export function createBroadcastUn(data) {
    return axios.post(`${BASE_URL_B}createBlast`, data, config)
}
export function getBroadcastUn(query) {
    return axios.get(`${BASE_URL_B}get-blast-unofficial?search=${query}`, config)
}
export function getDetailBroadcastUn(id) {
    return axios.get(`${BASE_URL_B}detail-unofficial/${id}`, config)
}