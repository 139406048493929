import api from "../apiClient";

export function createTeam(data) {
  return api.axiosClient.post("/createTeam", data);
}
export function createTeamDetail(data) {
  return api.axiosClient.post("/createTeamDetail", data);
}
export function updateTeam(data, id) {
  return api.axiosClient.put(`/updateTeam/${id}`, data);
}
export function updateTeamDetail(data, id) {
  return api.axiosClient.put(`/updateTeamDetail/${id}`, data);
}
export function getTeamById(id){
  return api.axiosClient.get(`/getTeamId/${id}`)
}
export function getTeam(){
  return api.axiosClient.get('/getTeam/limit=50&page=1')
}
export function registerLeader(data) {
  return api.axiosClient.post("registerLeader", data);
}
export function registerSupervisor(data) {
  return api.axiosClient.post("registerSupervisor", data);
}
export function registerCS(data) {
  return api.axiosClient.post("registerCustomerService", data);
}
export function getLeader(){
  return api.axiosClient.get('/getLeader')
}
export function getSpv(){
  return api.axiosClient.get('/getspv')
}
export function getCS(){
  return api.axiosClient.get('/getCustomerService')
}
