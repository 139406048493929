import React, { useState } from "react";
import Navbar from "../Components/Navbar/Navbar";
import EditProfile from "../Components/Partials/Modal/EditProfile.js";
import Export from "../Components/Partials/Modal/ExportCustomerData.js";
import Profile from "../Assets/img/Profile.jpg";
import ChangePassword from "../Components/Partials/Modal/ChangePassword";
import TableCustomer from "../Components/Partials/Table/TableCustomer";

const state = {
  labels: [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sept",
    "Ockt",
    "Nov",
    "Des",
  ],
  datasets: [
    {
      label: "Name_Product",
      backgroundColor: "rgba(75,192,192,1)",
      borderColor: "rgba(0,0,0,1)",
      borderWidth: 1,
      data: [65, 59, 80, 81, 56, 77, 44, 84, 100, 91, 10, 15],
    },
    {
      label: "Name_Product",
      backgroundColor: "rgba(237,76,92,1)",
      borderColor: "rgba(0,0,0,1)",
      borderWidth: 1,
      data: [65, 59, 80, 81, 56, 77, 44, 84, 100, 91, 10, 15],
    },
  ],
};

function OverviewCustomer() {
  const [count_lead, setCountLead] = useState("");
  return (
    <div>
      <Navbar />
      <div className="px-10 py-20 space-y-5">
        <div className="flex flex-row justify-between items-center">
          <div className="flex flex-col">
            <h1 className="text-xl font-bold">Overview Customer</h1>
            <h1 className="text-gray-400 font-semibold flex flex-row items-center gap-2">
              Overview Customer
            </h1>
          </div>
        </div>
        <div className="shadow-md rounded-lg p-5">
          <div className="flex flex-row justify-between gap-5 mb-5">
            <div className="flex flex-col md:flex-row gap-5">
              <img src={Profile} alt="qr" className="rounded-full w-24 h-24" />
              <div className="flex flex-col gap-5 ">
                <p className="font-bold text-lg">Aspen Rosser</p>
                <div className="flex flex-col md:flex-row gap-5 md:items-center items-start">
                  <p className="flex flex-row gap-2 items-center text-gray-400 font-medium">
                    <span>
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M14.1673 5.00065C14.1673 7.30184 12.3018 9.16732 10.0007 9.16732C7.69946 9.16732 5.83398 7.30184 5.83398 5.00065C5.83398 2.69946 7.69946 0.833984 10.0007 0.833984C12.3018 0.833984 14.1673 2.69946 14.1673 5.00065Z"
                          fill="#5E6278"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M15.6817 11.7715C15.168 11.1759 14.2857 11.2066 13.5958 11.5842C12.5282 12.1686 11.3029 12.5008 10 12.5008C8.69711 12.5008 7.47182 12.1686 6.40421 11.5842C5.71431 11.2066 4.83201 11.1759 4.31834 11.7715C3.18519 13.0853 2.5 14.7964 2.5 16.6675V17.5008C2.5 18.4213 3.24619 19.1675 4.16667 19.1675H15.8333C16.7538 19.1675 17.5 18.4213 17.5 17.5008V16.6675C17.5 14.7964 16.8148 13.0853 15.6817 11.7715Z"
                          fill="#A1A5B7"
                        />
                      </svg>
                    </span>
                    <span>Member Card Silver</span>
                  </p>
                  <p className="flex flex-row gap-2 items-center text-gray-400 font-medium">
                    <span>
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M2.5 17.4996L3.875 14.3329C2.82305 12.8397 2.35222 11.0138 2.55087 9.19803C2.74952 7.38226 3.60399 5.70139 4.95392 4.47087C6.30386 3.24036 8.05646 2.54479 9.88282 2.51469C11.7092 2.4846 13.4837 3.12205 14.8735 4.30742C16.2632 5.49279 17.1726 7.14459 17.431 8.95283C17.6893 10.7611 17.2789 12.6015 16.2768 14.1286C15.2746 15.6557 13.7495 16.7646 11.9878 17.2472C10.2261 17.7298 8.34886 17.5528 6.70833 16.7496L2.5 17.4996Z"
                          stroke="#A1A5B7"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M7.5 8.33203C7.5 8.44254 7.5439 8.54852 7.62204 8.62666C7.70018 8.7048 7.80616 8.7487 7.91667 8.7487C8.02717 8.7487 8.13315 8.7048 8.21129 8.62666C8.28944 8.54852 8.33333 8.44254 8.33333 8.33203V7.4987C8.33333 7.38819 8.28944 7.28221 8.21129 7.20407C8.13315 7.12593 8.02717 7.08203 7.91667 7.08203C7.80616 7.08203 7.70018 7.12593 7.62204 7.20407C7.5439 7.28221 7.5 7.38819 7.5 7.4987V8.33203ZM7.5 8.33203C7.5 9.4371 7.93899 10.4969 8.72039 11.2783C9.50179 12.0597 10.5616 12.4987 11.6667 12.4987H12.5C12.6105 12.4987 12.7165 12.4548 12.7946 12.3767C12.8728 12.2985 12.9167 12.1925 12.9167 12.082C12.9167 11.9715 12.8728 11.8655 12.7946 11.7874C12.7165 11.7093 12.6105 11.6654 12.5 11.6654H11.6667C11.5562 11.6654 11.4502 11.7093 11.372 11.7874C11.2939 11.8655 11.25 11.9715 11.25 12.082C11.25 12.1925 11.2939 12.2985 11.372 12.3767C11.4502 12.4548 11.5562 12.4987 11.6667 12.4987"
                          stroke="#5E6278"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                    0895-6389-4771
                  </p>
                  <p className="flex flex-row gap-2 items-center text-gray-400 font-medium">
                    <span>
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M9.99935 3.33268C6.31745 3.33268 3.33268 6.31745 3.33268 9.99935C3.33268 13.6812 6.31745 16.666 9.99935 16.666C11.0652 16.666 12.0703 16.4165 12.9618 15.9735C13.374 15.7687 13.8741 15.9367 14.0789 16.3489C14.2838 16.761 14.1157 17.2612 13.7035 17.466C12.5868 18.021 11.3283 18.3327 9.99935 18.3327C5.39698 18.3327 1.66602 14.6017 1.66602 9.99935C1.66602 5.39698 5.39698 1.66602 9.99935 1.66602C14.6017 1.66602 18.3327 5.39698 18.3327 9.99935V10.0068C18.3327 10.2337 18.3329 11.1756 18.0295 12.0858C17.8747 12.5503 17.622 13.066 17.1962 13.4729C16.7505 13.8988 16.1545 14.166 15.416 14.166C14.9558 14.166 14.5827 13.7929 14.5827 13.3327C14.5827 12.8724 14.9558 12.4993 15.416 12.4993C15.7192 12.4993 15.9045 12.402 16.0447 12.268C16.2048 12.115 16.3428 11.8755 16.4484 11.5587C16.6624 10.9167 16.666 10.203 16.666 9.99935C16.666 6.31745 13.6812 3.33268 9.99935 3.33268Z"
                          fill="#A1A5B7"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M12.5007 10.0007C12.5007 11.3814 11.3814 12.5007 10.0007 12.5007C8.61994 12.5007 7.50065 11.3814 7.50065 10.0007C7.50065 8.61994 8.61994 7.50065 10.0007 7.50065C11.3814 7.50065 12.5007 8.61994 12.5007 10.0007ZM13.1261 12.7562C12.3626 13.6215 11.2454 14.1673 10.0007 14.1673C7.69946 14.1673 5.83398 12.3018 5.83398 10.0007C5.83398 7.69946 7.69946 5.83398 10.0007 5.83398C10.9387 5.83398 11.8043 6.14394 12.5007 6.66702C12.5008 6.20692 12.8739 5.83398 13.334 5.83398C13.7942 5.83398 14.1673 6.20708 14.1673 6.66732C14.1673 8.7949 14.1725 10.3465 14.407 11.3785C14.5217 11.8832 14.67 12.1538 14.8086 12.2955C14.919 12.4083 15.0809 12.5007 15.4173 12.5007C15.8776 12.5007 16.2507 12.8737 16.2507 13.334C16.2507 13.7942 15.8776 14.1673 15.4173 14.1673C14.7121 14.1673 14.0928 13.9471 13.6172 13.461C13.4149 13.2542 13.2546 13.0153 13.1261 12.7562Z"
                          fill="#5E6278"
                        />
                      </svg>
                    </span>
                    <span>aspenr@gmail.com</span>
                  </p>
                </div>
                <div className="">
                  <div className="flex flex-row gap-5">
                    <div className="border-dashed border-2 py-1 px-3 rounded-lg">
                      <p className="font-bold text-xl text-start text-black">
                        10
                      </p>
                      <p className="font-medium text-start text-gray-400">
                        Repeat Order
                      </p>
                    </div>
                    <div className="border-dashed border-2 py-1 px-3 rounded-lg">
                      <p className="font-bold text-xl text-start text-black">
                        50.000
                      </p>
                      <p className="font-medium text-start text-gray-400">
                        Points
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="shadow-md rounded-lg p-5">
          <div className="py-10 flex flex-col md:flex-row justify-between items-start md:items-center">
            <div className="flex flex-col">
              <h1 className="text-xl font-bold">Purchase History</h1>
              <h1 className="text-gray-500 font-semibold">10 Data</h1>
            </div>
          </div>
          <TableCustomer />
        </div>
      </div>
    </div>
  );
}

export default OverviewCustomer;
