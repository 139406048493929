import React, { Component, useState, useEffect, Fragment } from "react";
import { Link, useNavigate } from "react-router-dom";
import Sidebar from "../../Components/Sidebar/SidebarTeam";
import "aos/dist/aos.css";
import AddTeam from "../../Components/Partials/Modal/AddTeam";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { Menu, Transition } from "@headlessui/react";
import { logout } from "../../Network/Lib/auth";
import Select from "react-select";
import Delete from "../../Assets/img/Delete-data.png";
import {
  getCS,
  getLeader,
  getSpv,
  getTeam,
  getTeamById,
  updateTeam,
  updateTeamDetail,
} from "../../Network/Lib/team";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function Team() {
  const navigate = useNavigate();
  const [refreshApi, setRefreshApi] = useState(false);
  const [navSideBar, setNavSideBar] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showView, setShowView] = useState(false);
  const [showDelete, setShowDelete] = useState(false);

  const [team, setTeam] = useState([]);

  const [viewData, setViewData] = useState([]);

  const [msg, setMsg] = useState([]);

  const [editTeamId, setEditTeamId] = useState([]);
  const [editTeamName, setEditTeamName] = useState([]);
  const [selectLeader, onSelectLeader] = useState("");
  const [selectSpv, onSelectSpv] = useState("");
  const [selectCS, onSelectCS] = useState([]);
  const [leader, setLeader] = useState("");
  const [spv, setSpv] = useState("");
  const [cs, setCS] = useState("");

  const [detailIdLeader, setDetailIdLeader] = useState("");
  const [detailIdSpv, setDetailIdSpv] = useState("");
  const [detailIdCS, setDetailIdCS] = useState([]);

  const edit = (id) => {
    const editCS = [];
    const dataCS = [];
    getTeamById(id).then((response) => {
      console.log(response.data.data[0].team_details);
      setEditTeamId(response.data.data[0].id);
      setEditTeamName(response.data.data[0].teamName);
      const data = response.data.data[0].team_details;
      data.map(
        (val) => (
          val.role.name == "Leader"
            ? (onSelectLeader(val.userId), setDetailIdLeader(val.id))
            : null,
          val.role.name == "Supervisor"
            ? (onSelectSpv(val.userId), setDetailIdSpv(val.id))
            : null,
          val.role.name == "CustomerService"
            ? (editCS.push({ value: val.userId, label: val.user.name }),
              dataCS.push({
                id: val.id,
                value: val.userId,
                label: val.user.name,
              }))
            : null
        )
      );
    });
    onSelectCS(editCS);
    setDetailIdCS(dataCS);
  };

  // console.log("old", detailIdCS)
  // console.log("new", selectCS)

  const options = [];
  cs &&
    cs.map((val) => {
      // console.log(val.id);
      options.push({
        value: val.id,
        label: val.name,
      });
    });

  useEffect(() => {
    getTeam().then((response) => {
      setTeam(response.data.data);
      // console.log(response.data.data);
    });
    getLeader().then((response) => {
      setLeader(response.data.data);
      // console.log(response.data.data);
    });
    getSpv().then((response) => {
      setSpv(response.data.data);
      // console.log(response.data.data);
    });
    getCS().then((response) => {
      setCS(response.data.getCS);
      // console.log(response.data.getCS);
    });
  }, [refreshApi]);

  const onFinishedCreate = async () => {
    setRefreshApi(true);
  };

  const postData = async () => {
    const err = [];
    if (editTeamName == "") {
      err.push("Name Can't be empty");
    }
    if (err.length > 0) {
      setMsg(err);
    } else {
      const form = {
        teamName: editTeamName,
      };
      console.log(form);
      try {
        const form = {
          teamName: editTeamName,
        };
        const response = await updateTeam(form, editTeamId)
          .then(async (response) => {
            console.log(response);
            setRefreshApi(true);
            const formDetailLeader = {
              teamId: editTeamId,
              userId: selectLeader,
            };
            const formDetailSpv = {
              teamId: editTeamId,
              userId: selectSpv,
            };
            // console.log(formDetailLeader);
            // console.log("iddetailLeader",detailIdLeader);
            try {
              const responseLeader = await updateTeamDetail(
                formDetailLeader,
                detailIdLeader
              )
                .then(async (response) => {
                  console.log("leader", response.data.message);
                })
                .catch((e) => {
                  if (e.response) {
                    console.log(e.response.data.message);
                    err.push(e.response.data.message);
                    setMsg(err);
                  }
                });
              const responseSpv = await updateTeamDetail(
                formDetailSpv,
                detailIdSpv
              )
                .then(async (response) => {
                  console.log("Spv", response.data.message);
                })
                .catch((e) => {
                  if (e.response) {
                    console.log(e.response.data.message);
                    err.push(e.response.data.message);
                    setMsg(err);
                  }
                });
            } catch (error) {
              console.log(error);
            }
            try {
              selectCS.map(async (val, index) => {
                const formDetailCS = {
                  teamId: editTeamId,
                  userId: val.value,
                };
                console.log(formDetailCS);
                const responseCS = await updateTeamDetail(formDetailCS, detailIdCS[index].id)
                  .then(async (response) => {
                    console.log(`cs ${index}`, response.data.message);
                  })
                  .catch((e) => {
                    if (e.response) {
                      console.log(e.response.data.message);
                      err.push(e.response.data.message);
                      setMsg(err);
                    }
                  });
              });
            } catch (error) {
              console.log(error);
            }
            setRefreshApi(true);
            setShowEdit(false);
          })
          .catch((e) => {
            if (e.response) {
              console.log(e.response.data.message);
              err.push(e.response.data.message);
              setMsg(err);
            }
          });
      } catch (error) {
        console.log(error);
      }
    }
  };

  const signOut = () => {
    logout();
    navigate("/login");
  };

  return (
    <>
      <div className="bg-gradient-to-r from-[#54DAA1] to-[#2DCE89]">
        <div className="relative">
          <aside
            className={
              navSideBar === true
                ? "w-24 h-screen p-5 fixed top-0 left-0 right-0 z-10"
                : "w-3/12 h-screen p-5 fixed top-0 left-0 right-0 z-10"
            }
            aria-label="Sidebar"
          >
            <button
              className="absolute top-72 right-1 w-10 h-10 bg-gradient-to-r from-[#54DAA1] to-[#2DCE89] rounded-full shadow-lg flex justify-center items-center"
              onClick={() => setNavSideBar(!navSideBar)}
            >
              {navSideBar ? (
                <svg
                  width="9"
                  height="16"
                  viewBox="0 0 9 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 14.3333L7.66667 7.66668L1 1.00001"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              ) : (
                <svg
                  width="10"
                  height="16"
                  viewBox="0 0 10 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.33325 14.6667L1.66659 7.99999L8.33325 1.33332"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              )}
            </button>
            <Sidebar navSideBar={navSideBar} />
          </aside>
        </div>
        <div
          className={
            navSideBar === true
              ? "ml-24 h-72 w-11/12 p-5 space-y-5"
              : "ml-auto h-72 w-9/12 p-5 space-y-5"
          }
        >
          {/* Header */}
          <div className="flex flex-row justify-between items-center">
            <div className="text-white space-y-2">
              <div className="flex flex-row space-x-3">
                <p className="text-sm">Pages</p>
                <p className="text-sm">/</p>
                <Link to="/dashboard" className="text-sm">
                  Dashboard
                </Link>
              </div>
              <p className="font-semibold">List Team</p>
            </div>
            <div className="flex flex-row items-center gap-5">
              <Link to="#" className="flex items-center gap-2">
                <svg
                  width="21"
                  height="20"
                  viewBox="0 0 21 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.4938 2.52235C12.7336 1.70165 11.6719 1.24969 10.5 1.24969C9.32191 1.24969 8.25668 1.69891 7.50004 2.51454C6.73519 3.33915 6.36254 4.45985 6.45004 5.67001C6.62348 8.05751 8.44027 9.99969 10.5 9.99969C12.5598 9.99969 14.3735 8.0579 14.5496 5.67079C14.6383 4.47157 14.2633 3.35321 13.4938 2.52235Z"
                    fill="white"
                  />
                  <path
                    d="M17.3752 18.7488H3.62522C3.44525 18.7511 3.26701 18.7133 3.10349 18.6381C2.93997 18.5629 2.79526 18.4522 2.67991 18.314C2.426 18.0105 2.32366 17.596 2.39944 17.1769C2.72913 15.348 3.75804 13.8117 5.37522 12.7332C6.81194 11.7757 8.63186 11.2488 10.5002 11.2488C12.3686 11.2488 14.1885 11.7761 15.6252 12.7332C17.2424 13.8113 18.2713 15.3476 18.601 17.1765C18.6768 17.5957 18.5744 18.0101 18.3205 18.3136C18.2052 18.4519 18.0605 18.5626 17.897 18.6379C17.7335 18.7132 17.5552 18.7511 17.3752 18.7488Z"
                    fill="white"
                  />
                </svg>
                <p className="text-white">{localStorage.getItem("nameUser")}</p>
              </Link>
              <button onClick={signOut} className="bg-white p-3 rounded-lg">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.5625 2.8125H2.1875V17.8125H6.5625M13.4375 5.9375L17.8125 10.3125L13.4375 14.6875M7.1875 10.3125H17.8125"
                    stroke="#EF4444"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            </div>
          </div>
          {/* start: Main */}
          <div className="w-full">
            {/* TableTeam */}
            <div className="">
              <div className="bg-white min-h-screen rounded-lg px-10 py-10 sh shadow-lg">
                <div className="flex flex-col md:flex-row justify-between ">
                  <div className="py-2 flex flex-col">
                    <span className="font-semibold text-lg">List Team</span>
                    <span className="text-sm text-gray-400">
                      {team != null ? team.length : 0} data
                    </span>
                  </div>
                  <div className="flex flex-row justify-center">
                    <div className="">
                      <div className="flex flex-row rounded-lg bg-gray-50 border border-gray-300">
                        <input
                          type="text"
                          id="table-search"
                          className="bg-transparent border-none text-gray-700 leading-tight focus:outline-none focus:ring-transparent"
                          placeholder="Search"
                        />
                        <div className="flex items-center pr-3 pointer-events-none">
                          <svg
                            className="w-5 h-5 text-gray-500 dark:text-gray-400"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                              clipRule="evenodd"
                            ></path>
                          </svg>
                        </div>
                      </div>
                    </div>
                    {localStorage.getItem("role") == 'Supervisor' || localStorage.getItem("role") == 'CustomerService' ?
                      null :
                      <div className="ml-5">
                        <AddTeam onFinishCreate={onFinishedCreate} />
                      </div>
                    }
                  </div>
                </div>
                <div className="mt-5">
                  <div className="overflow-x-auto md:h-screen">
                    <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                      <thead className="text-xs text-gray-400 uppercase dark:text-gray-400 ">
                        <tr className="border-b">
                          <th scope="col" className="py-3 uppercase w-52">
                            Team Name
                          </th>
                          <th scope="col" className="py-3 uppercase w-52">
                            Supervisor
                          </th>
                          <th scope="col" className="py-3 uppercase w-52">
                            Leader
                          </th>
                          <th scope="col" className="py-3 uppercase w-72">
                            Customer Service
                          </th>
                          {localStorage.getItem("role") == 'Supervisor' || localStorage.getItem("role") == 'CustomerService' ?
                            null :
                            <th scope="col" className="py-3 uppercase w-52">
                              action
                            </th>
                          }
                        </tr>
                      </thead>
                      <tbody>
                        {team &&
                          team.map((val, index) => (
                            <tr className="border-b" key={index}>
                              <td className="py-4">{val.teamName}</td>
                              <td className="py-4">
                                {val.team_details.map((val) =>
                                  val.role.name == "Supervisor" ? (
                                    <p className="bg-blue-50 text-blue-600 rounded-lg px-2 w-fit">
                                      {val.user.name}
                                    </p>
                                  ) : null
                                )}
                              </td>
                              <td className="py-4">
                                {val.team_details.map((val) =>
                                  val.role.name == "Leader" ? (
                                    <p className="bg-fuchsia-50 text-fuchsia-600 rounded-lg px-2 w-fit">
                                      {val.user.name}
                                    </p>
                                  ) : null
                                )}
                              </td>
                              <td className="py-4">
                                <div className="flex flex-wrap items-center gap-3">
                                  {val.team_details.map((val) =>
                                    val.role.name == "CustomerService" ? (
                                      <p className="bg-green-50 text-green-600 rounded-lg px-2 w-fit">
                                        {val.user.name}
                                      </p>
                                    ) : null
                                  )}
                                </div>
                              </td>
                              {localStorage.getItem("role") == 'Supervisor' || localStorage.getItem("role") == 'CustomerService' ?
                                null :
                                <td className="py-4">
                                  <Menu
                                    as="div"
                                    className="relative inline-block text-left"
                                  >
                                    <div>
                                      <Menu.Button className="inline-flex justify-center w-full rounded-md border border-[#F5F8FA] shadow-sm px-4 py-2 bg-[#F5F8FA] text-sm font-medium text-gray-700 hover:bg-[#F5F8FA] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-[#F5F8FA] focus:ring-[#F5F8FA]">
                                        Action
                                        <ChevronDownIcon
                                          className="-mr-1 ml-2 h-5 w-5"
                                          aria-hidden="true"
                                        />
                                      </Menu.Button>
                                    </div>

                                    <Transition
                                      as={Fragment}
                                      enter="transition ease-out duration-100"
                                      enterFrom="transform opacity-0 scale-95"
                                      enterTo="transform opacity-100 scale-100"
                                      leave="transition ease-in duration-75"
                                      leaveFrom="transform opacity-100 scale-100"
                                      leaveTo="transform opacity-0 scale-95"
                                    >
                                      <Menu.Items className="origin-top-right z-10 absolute right-0 mt-2 w-20 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                        <div className="py-1">
                                          <Menu.Item>
                                            {({ active }) => (
                                              <div
                                                onClick={() => {
                                                  setViewData(val);
                                                  setShowView(true);
                                                }}
                                                className={classNames(
                                                  active
                                                    ? "bg-gray-100 text-gray-900 cursor-pointer"
                                                    : "text-gray-700",
                                                  "block px-4 py-2 text-sm"
                                                )}
                                              >
                                                View
                                              </div>
                                            )}
                                          </Menu.Item>
                                          <Menu.Item>
                                            {({ active }) => (
                                              <div
                                                onClick={() => {
                                                  edit(val.id);
                                                  setShowEdit(true);
                                                }}
                                                className={classNames(
                                                  active
                                                    ? "bg-gray-100 text-gray-900 cursor-pointer"
                                                    : "text-gray-700",
                                                  "block px-4 py-2 text-sm"
                                                )}
                                              >
                                                Edit
                                              </div>
                                            )}
                                          </Menu.Item>
                                          <Menu.Item>
                                            {({ active }) => (
                                              <a
                                                href="#"
                                                onClick={() =>
                                                  setShowDelete(true)
                                                }
                                                className={classNames(
                                                  active
                                                    ? "bg-gray-100 text-gray-900"
                                                    : "text-gray-700",
                                                  "block px-4 py-2 text-sm"
                                                )}
                                              >
                                                Delete
                                              </a>
                                            )}
                                          </Menu.Item>
                                        </div>
                                      </Menu.Items>
                                    </Transition>
                                  </Menu>
                                </td>
                              }
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            {/* TableTeam */}
          </div>

          {/* end: Main */}
        </div>
        {showEdit ? (
          <>
            <div className="bg-black bg-opacity-75 overflow-y-auto overflow-x-hidden fixed flex justify-center top-0 left-0 right-0 bottom-0 z-50 w-full md:inset-0 h-modal md:h-full">
              {/* <div className="relative w-full max-w-2xl h-full md:h-auto"> */}
              {/* ini yang ditambahin */}
              <div className="relative fixed w-full max-w-2xl h-full md:h-auto my-5">
                {/* ini yang ditambahin */}
                <div className="relative bg-[#F5F8FA] rounded-lg shadow dark:bg-gray-700">
                  <div className="flex justify-between items-start p-4 rounded-t border-b dark:border-gray-600">
                    <div className="flex flex-col">
                      <h3 className="text-xl font-semibold dark:text-white">
                        Add Team
                      </h3>
                    </div>
                    <button
                      type="button"
                      className="bg-transparent hover:bg-gray-100 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                      onClick={() => setShowEdit(false)}
                    >
                      <svg
                        className="w-5 h-5"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                    </button>
                  </div>
                  <div className="p-6 space-y-6 items-center">
                    {msg != "" ? (
                      <div
                        className="p-3 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800 my-3"
                        role="alert"
                      >
                        <ul class="list-disc list-inside">
                          {msg.map((val) => (
                            <li>{val}</li>
                          ))}
                        </ul>
                      </div>
                    ) : null}
                    <div className="">
                      <label
                        className="block text-gray-700 font-bold mb-2"
                        for="grid-state"
                      >
                        Team Name
                      </label>
                      <input
                        type="text"
                        className="bg-white border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full"
                        placeholder="Full Name"
                        value={editTeamName}
                        onChange={(e) => setEditTeamName(e.target.value)}
                      />
                    </div>
                    <div className="">
                      <label
                        className="block text-gray-700 font-bold mb-2"
                        for="grid-state"
                      >
                        Leader
                      </label>
                      <div className="relative">
                        <select
                          className="block appearance-none w-full bg-white border border-gray-300 text-gray-400 font-medium py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-300"
                          value={selectLeader}
                          onChange={(e) => onSelectLeader(e.target.value)}
                        >
                          <option hidden>Select Leader</option>
                          {leader &&
                            leader?.map((val, index) => (
                              <option value={val.id}>{val.name}</option>
                            ))}
                        </select>
                      </div>
                    </div>
                    <div className="">
                      <label
                        className="block text-gray-700 font-bold mb-2"
                        for="grid-state"
                      >
                        Supervisor
                      </label>
                      <div className="relative">
                        <select
                          className="block appearance-none w-full bg-white border border-gray-300 text-gray-400 font-medium py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-300"
                          value={selectSpv}
                          onChange={(e) => onSelectSpv(e.target.value)}
                        >
                          <option hidden>Select Supervisor</option>
                          {spv &&
                            spv?.map((val, index) => (
                              <option value={val.id}>{val.name}</option>
                            ))}
                        </select>
                      </div>
                    </div>
                    <div className="">
                      <label
                        className="block text-gray-700 font-bold mb-2"
                        for="grid-state"
                      >
                        Customer Service
                      </label>
                      <div className="relative">
                        <Select
                          options={options}
                          isMulti
                          name="colors"
                          defaultValue={selectCS}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          onChange={(e) => onSelectCS(e)}
                        />
                      </div>
                    </div>

                    <div className="mt-10 flex flex-row justify-end">
                      <button
                        className="px-3 rounded-xl text-gray-400 hover:text-gray-600 font-semibold"
                        onClick={() => setShowEdit(false)}
                      >
                        Cancel
                      </button>
                      <button
                        className="py-2 px-6 rounded-md text-white bg-green-400 hover:bg-green-500 font-semibold ml-2"
                        onClick={postData}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : null}
        {showView ? (
          <>
            <div className="bg-black bg-opacity-75 overflow-y-auto overflow-x-hidden fixed flex justify-center top-0 left-0 right-0 bottom-0 z-50 w-full md:inset-0 h-modal md:h-full">
              {/* <div className="relative w-full max-w-2xl h-full md:h-auto"> */}
              {/* ini yang ditambahin */}
              <div className="relative fixed w-full max-w-2xl h-full md:h-auto my-20">
                {/* ini yang ditambahin */}
                <div className="relative bg-[#F5F8FA] rounded-lg shadow dark:bg-gray-700">
                  <div className="flex justify-between items-start p-4 rounded-t border-b dark:border-gray-600">
                    <div className="flex flex-col">
                      <h3 className="text-xl font-semibold dark:text-white">
                        {viewData.teamName}
                      </h3>
                    </div>
                    <button
                      type="button"
                      className="bg-transparent hover:bg-gray-100 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                      onClick={() => setShowView(false)}
                    >
                      <svg
                        className="w-5 h-5"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                    </button>
                  </div>
                  <div className="p-6 space-y-6 items-center">
                    <div className="grid grid-cols-3">
                      <div className="">Leader</div>
                      <div className="col-span-2">
                        {viewData.team_details.map((viewData) =>
                          viewData.role.name == "Leader" ? (
                            <p className="bg-fuchsia-50 text-fuchsia-600 rounded-lg px-2 w-fit">
                              {viewData.user.name}
                            </p>
                          ) : null
                        )}
                      </div>
                    </div>
                    <div className="grid grid-cols-3">
                      <div className="">Supervisor</div>
                      <div className="col-span-2">
                        {viewData.team_details.map((viewData) =>
                          viewData.role.name == "Supervisor" ? (
                            <p className="bg-blue-50 text-blue-600 rounded-lg px-2 w-fit">
                              {viewData.user.name}
                            </p>
                          ) : null
                        )}
                      </div>
                    </div>
                    <div className="grid grid-cols-3">
                      <div className="">Customer Service</div>
                      <div className="col-span-2 flex flex-wrap gap-2">
                        {viewData.team_details.map((viewData) =>
                          viewData.role.name == "CustomerService" ? (
                            <p className="bg-green-50 text-green-600 rounded-lg px-2 w-fit">
                              {viewData.user.name}
                            </p>
                          ) : null
                        )}
                      </div>
                    </div>
                    <div className="grid grid-cols-3">
                      <div className="">Product</div>
                      <div className="col-span-2 font-bold text-gray-800">
                        Nama Product
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : null}
        {showDelete ? (
          <>
            <div className="bg-black bg-opacity-75 overflow-y-auto overflow-x-hidden fixed flex justify-center top-0 left-0 right-0 bottom-0 z-50 w-full md:inset-0 md:h-full">
              {/* <div className="relative w-full max-w-2xl h-full md:h-auto"> */}
              {/* ini yang ditambahin */}
              <div className="fixed w-full max-w-xl md:h-auto mt-40">
                {/* ini yang ditambahin */}
                <div className="relative bg-[#F5F8FA] rounded-lg shadow dark:bg-gray-700">
                  <div className="p-6 space-y-6 items-center">
                    <div className="flex flex-row justify-between items-start gap-5">
                      <div className="flex flex-row gap-5">
                        <img src={Delete} className="w-100 object-cover" />
                        <div className="flex flex-col">
                          <h1 className="font-bold">Delete Team?</h1>
                          <p className="font-semibold text-gray-400">
                            Data will be permanently deleted
                          </p>
                          <div className="mt-10 flex flex-row justify-start gap-3">
                            <button
                              // onClick={() => {
                              //   onConfirmDelete();
                              //   setShowDelete(false);
                              // }}
                              className="text-sm py-2 px-5 rounded-xl text-white bg-green-400 hover:bg-green-500 font-semibold"
                            >
                              Yes
                            </button>
                            <button
                              className="text-sm px-3 rounded-xl text-gray-400 hover:text-gray-600 bg-gray-100 hover:bg-gray-200 font-semibold"
                              onClick={() => setShowDelete(false)}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                      <button
                        type="button"
                        className="bg-transparent hover:bg-gray-100 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                        onClick={() => setShowDelete(false)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="#A1A5B7"
                          className="bi bi-x-circle"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                          <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : null}
      </div>
    </>
  );
}

export default Team;
