import "aos/dist/aos.css";
import React, { useEffect, useState } from "react";
// import SidebarComunication from "../../Components/Sidebar/SidebarComunication";
import TableLeveling from "../../Components/Partials/Table/TableLeveling";
import Sidebar from "../../Components/Sidebar/Sidebar";
import { Link, useNavigate, useParams } from "react-router-dom";
import dataa from "../../Assets/svg/Data.svg";
import pens from "../../Assets/svg/pens.svg";
import {
  getAllCity,
  getAllProvince,
  getAllSubdis,
} from "../../Network/Lib/rajaOngkir";
import { getAllProduct } from "../../Network/Lib/product";
import { getWarehouse } from "../../Network/Lib/warehouse";
import { logout } from "../../Network/Lib/auth";
import { getLeveling } from "../../Network/Lib/leveling";
// import
// pens.svg

function EditDetailOrder() {
  const [navSideBar, setNavSideBar] = useState(false);
  const [refreshAPI, setRefreshApi] = useState(false);
  const navigate = useNavigate();

  const { id } = useParams();
  console.log(id);

  const [data, setData] = useState([]);
  const [name, setName] = useState([]);
  const [phone, setPhone] = useState();
  const [birthdate, setBirthdate] = useState([]);
  const [email, setEmail] = useState([]);
  const [gender, setGender] = useState([]);
  const [marital_status, setMaritalStatus] = useState([]);
  const [latest_purchase, setLatestPurchase] = useState([]);
  const [level, setLevel] = useState([]);
  const [address, setAddress] = useState([]);

  const [provinceList, setProvinceList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [subdistrictList, setSubdistrictList] = useState([]);
  const [selectedProvince, setSelectedProvince] = useState();
  const [selectedCity, setSelectedCity] = useState();
  const [selectedSubdis, setSelectedSubdis] = useState();

  const [status, setStatus] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState();
  const [productList, setProductList] = useState([]);
  const [qty, setQty] = useState([]);
  const [payment, setPayment] = useState([]);
  const [selectedWarehouse, setSelectedWarehouse] = useState();
  const [warehouse, setWarehouse] = useState([]);
  const [csName, setCSName] = useState([]);
  const [csPhone, setCSPhone] = useState([]);
  const [campaign, setCampaign] = useState([]);
  const [promo, setPromo] = useState([]);
  const [resi, setResi] = useState([]);
  const [courier, setCourier] = useState([]);
  const [note, setNote] = useState([]);
  const [reason, setReason] = useState([]);

  const [msg, setMsg] = useState("");

  const [dataLeveling, setDataLeveling] = useState([])

  // console.log(name);
  // console.log(phone);
  // console.log(birthdate);
  // console.log(email);
  // console.log(gender);
  // console.log(marital_status);
  // console.log(latest_purchase);
  // console.log(level);
  // console.log(address);
  // console.log(selectedProvince);
  // console.log(selectedCity);
  // console.log(selectedSubdis);
  // console.log(selectedProduct);
  // console.log(status);
  // console.log(selectedProduct);
  // console.log(qty);
  // console.log(payment);
  // console.log(warehouse);
  // console.log(csName);
  // console.log(csPhone);
  // console.log(campaign);
  // console.log(promo);
  // console.log(resi);
  // console.log(courier);
  // console.log(note);
  // console.log(reason);

  // const postData = async () => {
  //   // const form = {
  //   //   name: name,
  //   //   phone: phone,
  //   //   birthdate: birthdate,
  //   //   email: email,
  //   //   gender: gender,
  //   //   marital_status: marital_status,
  //   //   // latest_purchase: birthdate,
  //   //   level: level,
  //   // };
  //   const formOrder = {
  //     // csId: "2b92c1d1-7322-46cf-a251-3f65ee9cd459",
  //     customerId: id,
  //     warehouseId: selectedWarehouse,
  //     status: status,
  //     // userId: "2b92c1d1-7322-46cf-a251-3f65ee9cd459",
  //   };
  //   console.log(formOrder);
  //   try {
  //     const response = await createOrder(formOrder)
  //       .then(async (response) => {
  //         // setName("");
  //         // setPhone("");
  //         // setBirthdate("");
  //         // setEmail("");
  //         // setGender("");
  //         // setMaritalStatus("");
  //         // setLatestPurchase("");
  //         // setLevel("");

  //         // console.log(response.data.data.id);
  //         // const id = response.data.data.id;
  //         // const formAddress = {
  //         //   customerId: id,
  //         //   address: address,
  //         //   subdistrict_id: selectedSubdis,
  //         //   district_id: 0,
  //         //   city_id: selectedCity,
  //         //   province_id: selectedProvince,
  //         // };
  //         // const formOrder = {
  //         //   csId: "2b92c1d1-7322-46cf-a251-3f65ee9cd459",
  //         //   customerId: id,
  //         //   status: status,
  //         //   userId: "2b92c1d1-7322-46cf-a251-3f65ee9cd459",
  //         // };
  //         // createCsAddress(formAddress);
  //         // console.log(formOrder);
  //         // const responseOrder = await createOrder(formOrder).then((responseOrder) => {
  //         // console.log(response.data.data.id);
  //         const orderId = response.data.data.id;
  //         product.formValues.map(async (val) => {
  //           const formOrderDetail = {
  //             orderId: orderId,
  //             productId: val.productId,
  //             qty: val.qty,
  //           };
  //           console.log(formOrderDetail);
  //           const response2 = await createOrderDetail(formOrderDetail)
  //             .then(async (response) => {
  //               console.log(response);
  //               // navigate(`/view-customer/${id}`);
  //             })
  //             .catch((e) => {
  //               if (e.response) {
  //                 setMsg("order Detail :" + e.response.data.message);
  //               }
  //             });
  //         });
  //         // });
  //         // console.log(response);
  //         // navigate(`/view-customer/${id}`);
  //       })
  //       .catch((e) => {
  //         if (e.response) {
  //           setMsg(e.response.data.message);
  //           console.log(e.response.data);
  //         }
  //       });
  //     // console.log(response).catch((e) => {
  //     //   console.log(e.response);
  //     // });
  //   } catch (error) {}
  // };

  useEffect(() => {
    getAllProvince().then((response) => {
      setProvinceList(response.data.data);
    });
    getAllProduct().then((response) => {
      setProductList(response.data.data);
      // console.log(response.data.data);
    });
    getWarehouse().then((response) => {
      console.log(response.data.data);
      setWarehouse(response.data.data);
    });
    getLeveling().then((response) => {
      console.log("leveling", response.data.data);
      setDataLeveling(response.data.data);
    });

    setRefreshApi(false);
  }, [refreshAPI]);

  const onSelectProvince = async (e) => {
    setSelectedProvince(e.target.value);
    await getAllCity(e.target.value)
      .then((response) => {
        setCityList(response.data.data);
      })
      .catch((e) => { });
  };

  const onSelectCity = async (e) => {
    setSelectedCity(e.target.value);
    await getAllSubdis(e.target.value)
      .then((response) => {
        setSubdistrictList(response.data.data);
      })
      .catch((e) => { });
  };

  const onSelectSubdis = async (e) => {
    setSelectedSubdis(e.target.value);
  };

  const onSelectProduct = async (e) => {
    setSelectedProduct(e.target.value);
    await getAllProduct(e.target.value)
      .then((response) => {
        setCityList(response.data.data);
      })
      .catch((e) => { });
  };

  const signOut = () => {
    logout();
    navigate("/login");
  };

  console.log(warehouse);

  const [product, setProduct] = useState({
    formValues: [{ qty: "", productId: "" }],
  });

  const handleChange = (i, e) => {
    let formValues = product.formValues;
    formValues[i][e.target.name] = e.target.value;
    setProduct({ formValues });
    // console.log(formValues)
  };

  const addFormFields = () => {
    setProduct({
      formValues: [...product.formValues, { qty: "", productId: "" }],
    });
  };

  const removeFormFields = (i) => {
    let formValues = product.formValues;
    formValues.splice(i, 1);
    setProduct({ formValues });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    alert(JSON.stringify(product.formValues));
  };

  console.log(product.formValues);
  return (
    <>
      <div className="bg-gradient-to-r from-[#54DAA1] to-[#2DCE89]">
        <div className="relative">
          <aside
            className={
              navSideBar === true
                ? "w-24 h-screen p-5 fixed top-0 left-0 right-0 z-10"
                : "w-3/12 h-screen p-5 fixed top-0 left-0 right-0 z-10"
            }
            aria-label="Sidebar"
          >
            <button
              className="absolute bottom-96 right-1 w-10 h-10 bg-gradient-to-r from-[#54DAA1] to-[#2DCE89] rounded-full shadow-lg flex justify-center items-center"
              onClick={() => setNavSideBar(!navSideBar)}
            >
              {navSideBar ? (
                <svg
                  width="9"
                  height="16"
                  viewBox="0 0 9 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 14.3333L7.66667 7.66668L1 1.00001"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              ) : (
                <svg
                  width="10"
                  height="16"
                  viewBox="0 0 10 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.33325 14.6667L1.66659 7.99999L8.33325 1.33332"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              )}
            </button>
            <Sidebar navSideBar={navSideBar} />
          </aside>
        </div>
        <div
          className={
            navSideBar === true
              ? "ml-24 h-72 w-11/12 p-5 space-y-5"
              : "ml-auto h-72 w-9/12 p-5 space-y-5"
          }
        >
          {/* Header */}
          <div className="flex flex-row justify-between items-center">
            <div className="text-white space-y-2">
              <div className="flex flex-row space-x-3">
                <p className="text-sm">Pages</p>
                <p className="text-sm">/</p>
                <a href="/dashboard" className="text-sm">
                  Dashboard
                </a>
                <p className="text-sm">/</p>
                <a href="/view-customer/:id" className="text-sm">
                  View Customer
                </a>
                <p className="text-sm">/</p>
                <a href="/detail-order" className="text-sm">
                  Edit Detail Order
                </a>
              </div>
              <p className="font-semibold">Edit Detail Order</p>
            </div>
            <div className="flex flex-row items-center gap-5">
              <a href="#" className="flex items-center gap-2">
                <svg
                  width="21"
                  height="20"
                  viewBox="0 0 21 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.4938 2.52235C12.7336 1.70165 11.6719 1.24969 10.5 1.24969C9.32191 1.24969 8.25668 1.69891 7.50004 2.51454C6.73519 3.33915 6.36254 4.45985 6.45004 5.67001C6.62348 8.05751 8.44027 9.99969 10.5 9.99969C12.5598 9.99969 14.3735 8.0579 14.5496 5.67079C14.6383 4.47157 14.2633 3.35321 13.4938 2.52235Z"
                    fill="white"
                  />
                  <path
                    d="M17.3752 18.7488H3.62522C3.44525 18.7511 3.26701 18.7133 3.10349 18.6381C2.93997 18.5629 2.79526 18.4522 2.67991 18.314C2.426 18.0105 2.32366 17.596 2.39944 17.1769C2.72913 15.348 3.75804 13.8117 5.37522 12.7332C6.81194 11.7757 8.63186 11.2488 10.5002 11.2488C12.3686 11.2488 14.1885 11.7761 15.6252 12.7332C17.2424 13.8113 18.2713 15.3476 18.601 17.1765C18.6768 17.5957 18.5744 18.0101 18.3205 18.3136C18.2052 18.4519 18.0605 18.5626 17.897 18.6379C17.7335 18.7132 17.5552 18.7511 17.3752 18.7488Z"
                    fill="white"
                  />
                </svg>
                <p className="text-white">{localStorage.getItem("nameUser")}</p>
              </a>
              <a href="#" className="bg-white p-3 rounded-lg">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.1907 13.3321C17.1259 13.2539 17.0622 13.1758 16.9997 13.1004C16.1403 12.061 15.6204 11.4336 15.6204 8.49104C15.6204 6.9676 15.2559 5.7176 14.5376 4.7801C14.0079 4.08752 13.2919 3.56213 12.3481 3.17385C12.336 3.1671 12.3251 3.15823 12.3161 3.14768C11.9766 2.01096 11.0477 1.24963 10.0001 1.24963C8.95242 1.24963 8.0239 2.01096 7.68445 3.14651C7.67539 3.15667 7.6647 3.16526 7.65281 3.1719C5.45046 4.07854 4.38015 5.81799 4.38015 8.48987C4.38015 11.4336 3.86101 12.061 3.00086 13.0992C2.93836 13.1746 2.87468 13.2512 2.80984 13.3309C2.64234 13.5329 2.53622 13.7786 2.50403 14.0391C2.47184 14.2995 2.51493 14.5637 2.6282 14.8004C2.86922 15.3082 3.38289 15.6235 3.96922 15.6235H16.0352C16.6188 15.6235 17.129 15.3086 17.3708 14.8031C17.4845 14.5664 17.528 14.302 17.4961 14.0412C17.4642 13.7805 17.3582 13.5344 17.1907 13.3321V13.3321Z"
                    fill="url(#paint0_linear_2897_3826)"
                  />
                  <path
                    d="M9.99977 18.7499C10.5642 18.7495 11.1181 18.5963 11.6025 18.3065C12.0869 18.0168 12.4839 17.6013 12.7513 17.1042C12.7639 17.0804 12.7701 17.0537 12.7694 17.0268C12.7686 16.9999 12.7609 16.9736 12.747 16.9505C12.7331 16.9274 12.7134 16.9083 12.69 16.8951C12.6665 16.8819 12.64 16.8749 12.613 16.8749H7.38727C7.36028 16.8748 7.33374 16.8817 7.31021 16.895C7.28669 16.9082 7.26698 16.9272 7.25302 16.9503C7.23905 16.9734 7.23131 16.9997 7.23053 17.0267C7.22976 17.0537 7.23598 17.0804 7.2486 17.1042C7.51599 17.6013 7.91291 18.0167 8.39727 18.3064C8.88163 18.5962 9.43537 18.7494 9.99977 18.7499Z"
                    fill="url(#paint1_linear_2897_3826)"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_2897_3826"
                      x1="17.5063"
                      y1="1.24963"
                      x2="0.489125"
                      y2="12.6247"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#2DCECC" />
                      <stop offset="1" stopColor="#2DCE89" />
                    </linearGradient>
                    <linearGradient
                      id="paint1_linear_2897_3826"
                      x1="12.7695"
                      y1="16.8749"
                      x2="10.7837"
                      y2="20.6293"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#2DCECC" />
                      <stop offset="1" stopColor="#2DCE89" />
                    </linearGradient>
                  </defs>
                </svg>
              </a>
            </div>
          </div>
          {/* start: Main */}

          <div className="grid grid-cols-12 gap-5">
            <div className="col-span-12">
              <div className="bg-white shadow-lg h-full rounded-lg p-10">
                <div className="flex flex-row justify-between">
                  <div className="flex items-center justify-center gap-3">
                    <h1 className="text-lg font-bold">Edit Detail Order</h1>
                  </div>
                </div>
                <div className="">
                  <div className="py-10 space-y-5">
                    {msg != "" ? (
                      <div
                        className="p-3 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800 my-3"
                        role="alert"
                      >
                        {msg}
                      </div>
                    ) : null}
                    <div className="grid md:grid-cols-5 grid-cols-2 gap-3 bg-gray-50 p-5 rounded-lg">
                      <div className="space-y-2">
                        <p className="text-sm">Buying Date</p>
                        <input
                          type="date"
                          className="w-full bg-white p-2 rounded-lg text-xs text-gray-400 focus:ring-transparent border-0"
                        />
                      </div>
                      <div className="space-y-2">
                        <p className="text-sm">Transaction Number</p>
                        <input
                          type="text"
                          className="w-full bg-white p-2 rounded-lg text-xs text-gray-400 focus:ring-transparent border-0"
                        />
                      </div>
                      <div className="space-y-2">
                        <p className="text-sm">Customer</p>
                        <input
                          type="text"
                          className="w-full bg-white p-2 rounded-lg text-xs text-gray-400 focus:ring-transparent border-0"
                          value={name}
                          readOnly
                        />
                      </div>
                      <div className="space-y-2">
                        <p className="text-sm">Status by Today</p>
                        <select
                          className="w-full bg-white p-2 rounded-lg text-xs text-gray-400 focus:ring-transparent border-0"
                          onChange={(e) => setStatus(e.target.value)}
                        >
                          <option hidden>Select Status</option>
                          <option value="processing">Paid</option>
                          <option value="closing">Unpai</option>
                        </select>
                      </div>
                      <div className="space-y-2">
                        <p className="text-sm">Memo</p>
                        <select className="w-full bg-white p-2 rounded-lg text-xs text-gray-400 focus:ring-transparent border-0">
                          <option hidden>Select Memo</option>
                          {dataLeveling && dataLeveling.map((val, index) =>
                            <option key={index} value={val.id}>{val.levelingName}</option>
                          )}
                        </select>
                      </div>
                    </div>
                    <div className="bg-gray-50 p-5 rounded-lg space-y-3">
                      <div className="space-y-3">
                        {product.formValues.map((element, index) => (
                          <>
                            <div className="grid grid-cols-5">
                              <div
                                className="col-span-4 grid md:grid-cols-4 gap-3"
                                key={index}
                              >
                                <div className="space-y-2">
                                  <p className="text-sm">Product</p>
                                  <select
                                    className="w-full bg-white p-2 rounded-lg text-xs text-gray-400 focus:ring-transparent border-0"
                                    name="productId"
                                    onChange={(e) => handleChange(index, e)}
                                    value={element.productId || ""}
                                  >
                                    <option hidden>Select Product</option>
                                    {productList &&
                                      productList.map(function (value, index) {
                                        return (
                                          <>
                                            <option key={index} value={value.id}>
                                              {value.name}
                                            </option>
                                          </>
                                        );
                                      })}
                                  </select>
                                </div>
                                <div className="space-y-2">
                                  <p className="text-sm">Quantity</p>
                                  <input
                                    type="number"
                                    name="qty"
                                    value={element.qty || ""}
                                    onChange={(e) => handleChange(index, e)}
                                    className="w-full bg-white p-2 rounded-lg text-xs text-gray-400 focus:ring-transparent border-0"
                                  />
                                </div>
                                <div className="space-y-2">
                                  <p className="text-sm">Unit</p>
                                  <p className="w-full bg-gray-100 p-2 rounded-lg text-xs text-gray-400">
                                    Box
                                  </p>
                                </div>
                                <div className="space-y-2">
                                  <p className="text-sm">Price</p>
                                  <p className="w-full bg-gray-100 p-2 rounded-lg text-xs text-gray-400">
                                    Rp 100.000
                                  </p>
                                </div>
                              </div>
                              <div className="col-span-1">
                                {index ? (
                                  <>
                                    <button
                                      type="button"
                                      className="bg-red-500 hover:bg-red-700 text-white font-bold px-2 mt-8 rounded"
                                      onClick={() => removeFormFields(index)}
                                    >
                                      X
                                    </button>
                                  </>
                                ) : null}
                              </div>
                            </div>
                          </>
                        ))}
                        <div className="button-section mt-5">
                          <button
                            className="bg-[#E7FFF7] text-[#4FC29B] rounded py-2 px-3 text-sm"
                            type="button"
                            onClick={() => addFormFields()}
                          >
                            + Add Product
                          </button>
                        </div>
                      </div>
                      <div className="grid md:grid-cols-5 grid-cols-2 gap-3 ">
                        <div className="space-y-2">
                          <p className="text-sm">Payment</p>
                          <select
                            placeholder="Select Payment Method"
                            className="w-full bg-white p-2 rounded-lg text-xs text-gray-400 focus:ring-transparent border-0"
                            onChange={(e) => setPayment(e.target.value)}
                          >
                            <option hidden>Select Payment Method</option>
                            <option value="transfer">Transfer</option>
                            <option value="cod">COD</option>
                          </select>
                        </div>
                        <div className="space-y-2">
                          <p className="text-sm">Note</p>
                          <input
                            type="text"
                            className="w-full bg-white p-2 rounded-lg text-xs text-gray-400 focus:ring-transparent border-0"
                          />
                        </div>
                        <div className="space-y-2">
                          <p className="text-sm">Buying Reason</p>
                          <input
                            type="text"
                            className="w-full bg-white p-2 rounded-lg text-xs text-gray-400 focus:ring-transparent border-0"
                          />
                        </div>
                        <div className="space-y-2">
                          <p className="text-sm">Promo</p>
                          <select className="w-full bg-white p-2 rounded-lg text-xs text-gray-400 focus:ring-transparent border-0">
                            <option hidden>Select Promo</option>
                            <option value="">Promo A</option>
                            <option value="">Promo B</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="grid md:grid-cols-5 grid-cols-2 gap-3 bg-gray-50 p-5 rounded-lg">
                      <div className="space-y-2">
                        <p className="text-sm">Customer Service Name</p>
                        <select className="w-full bg-white p-2 rounded-lg text-xs text-gray-400 focus:ring-transparent border-0">
                          <option hidden>Select Customer Service</option>
                          <option value="">CS 1</option>
                        </select>
                      </div>
                      <div className="space-y-2">
                        <p className="text-sm">Customer Service Phone</p>
                        <input
                          type="text"
                          className="w-full bg-white p-2 rounded-lg text-xs text-gray-400 focus:ring-transparent border-0"
                          placeholder="auto fill"
                          readonly
                        />
                      </div>
                      <div className="space-y-2">
                        <p className="text-sm">Channel</p>
                        <select className="w-full bg-white p-2 rounded-lg text-xs text-gray-400 focus:ring-transparent border-0">
                          <option hidden>Select Channel</option>
                          <option value="">Whatsapp</option>
                          <option value="">Marketplace</option>
                          <option value="">Other</option>
                        </select>
                      </div>
                      <div className="space-y-2">
                        <p className="text-sm">ADV</p>
                        <select className="w-full bg-white p-2 rounded-lg text-xs text-gray-400 focus:ring-transparent border-0">
                          <option hidden>Select ADV</option>
                          <option value="">CRM</option>
                          <option value="">Marketplace</option>
                        </select>
                      </div>
                      <div className="space-y-2">
                        <p className="text-sm">Category</p>
                        <select className="w-full bg-white p-2 rounded-lg text-xs text-gray-400 focus:ring-transparent border-0">
                          <option hidden>Select Category</option>
                          <option value="">Retail</option>
                          <option value="">CRM</option>
                        </select>
                      </div>
                    </div>
                    <div className="grid md:grid-cols-5 grid-cols-2 gap-3 bg-gray-50 p-5 rounded-lg">
                      <div className="space-y-2">
                        <p className="text-sm">Warehouse</p>
                        <select
                          placeholder="Select Warehouse"
                          className="w-full bg-white p-2 rounded-lg text-xs text-gray-400 focus:ring-transparent border-0"
                          onChange={(e) => setSelectedWarehouse(e.target.value)}
                        >
                          <option hidden>Select Warehouse</option>
                          {warehouse &&
                            warehouse.map((val, index) => (
                              <option key={index} value={val.id}>
                                {val.warehouseName}
                              </option>
                            ))}
                        </select>
                      </div>
                      <div className="space-y-2">
                        <p className="text-sm">Courier</p>
                        <select className="w-full bg-white p-2 rounded-lg text-xs text-gray-400 focus:ring-transparent border-0">
                          <option hidden>Select Courier</option>
                          <option value="">JNE</option>
                          <option value="">JNT</option>
                        </select>
                      </div>
                      <div className="space-y-2">
                        <p className="text-sm">Total per line/omzet</p>
                        <input
                          type="text"
                          className="w-full bg-white p-2 rounded-lg text-xs text-gray-400 focus:ring-transparent border-0"
                        />
                      </div>
                      <div className="space-y-2">
                        <p className="text-sm">Shipping Fee</p>
                        <input
                          type="text"
                          className="w-full bg-white p-2 rounded-lg text-xs text-gray-400 focus:ring-transparent border-0"
                        />
                      </div>
                      <div className="space-y-2">
                        <p className="text-sm">Subsidi by Company</p>
                        <input
                          type="text"
                          className="w-full bg-white p-2 rounded-lg text-xs text-gray-400 focus:ring-transparent border-0"
                        />
                      </div>
                      <div className="space-y-2">
                        <p className="text-sm">Total + Ongkir</p>
                        <input
                          type="text"
                          className="w-full bg-white p-2 rounded-lg text-xs text-gray-400 focus:ring-transparent border-0"
                        />
                      </div>
                      <div className="space-y-2">
                        <p className="text-sm">Resi</p>
                        <input
                          type="text"
                          className="w-full bg-white p-2 rounded-lg text-xs text-gray-400 focus:ring-transparent border-0"
                          onChange={(e) => setResi(e.target.value)}
                        />
                      </div>
                      <div className="space-y-2">
                        <p className="text-sm">Keterangan Retur</p>
                        <input
                          type="text"
                          className="w-full bg-white p-2 rounded-lg text-xs text-gray-400 focus:ring-transparent border-0"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {/*bagin: Button Save */}
                <div className="flex flex-row justify-center md:justify-end gap-5 py-5">
                  <Link
                    to={`/view-customer/${id}`}
                    className="text-gray-400 rounded-lg bg-gray-100 hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-200 bg-gray-200 font-medium text-sm px-5 py-2.5 text-center"
                  >
                    Cancel
                  </Link>
                  <button
                    type="submit"
                    className="text-white rounded-lg bg-[#4FC29B] hover:bg-[#4FC29B] focus:ring-4 focus:outline-none focus:ring-[#4FC29B] font-medium text-sm px-5 py-2.5 text-center"
                  // onClick={postData}
                  >
                    Save Changes
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* end: Main */}
        </div>
      </div>
    </>
  );
}

export default EditDetailOrder;
