import "aos/dist/aos.css";
import React, { Fragment, useEffect, useState } from "react";
import AddCS from "../../Components/Partials/Modal/AddCS";
import SidebarComunication from "../../Components/Sidebar/SidebarComunication";
import CardTemplate from "../../Components/Partials/Card/CardTemplate";
import arrowLeft from "../../Assets/icon/comunication/arrowLeft.svg";
import leveling from "../../Assets/icon/Leveling.png";
import leveling1 from "../../Assets/icon/Leveling-1.png";
import leveling2 from "../../Assets/icon/Leveling-2.png";
import leveling3 from "../../Assets/icon/Leveling-3.png";
import {
  createLeveling,
  deleteLeveling,
  getLeveling,
  getNewCustomer,
  updateLeveling,
} from "../../Network/Lib/leveling";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { PlusIcon, UserAddIcon } from "@heroicons/react/solid";
import { Menu, Transition } from "@headlessui/react";
import { Link, useNavigate } from "react-router-dom";
import { logout } from "../../Network/Lib/auth";

function Leveling() {
  const navigate = useNavigate();
  const [navSideBar, setNavSideBar] = useState(false);
  const [modalAdd, setModalAdd] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);

  const [data, setData] = useState([]);
  const [dataNewCustomer, setDataNewCustomer] = useState([]);

  const [name, setName] = useState([]);
  const [minOrder, setMinOrder] = useState([]);
  const [editId, setEditId] = useState([]);
  const [editName, setEditName] = useState([]);
  const [editMinOrder, setEditMinOrder] = useState([]);
  const [deleteId, setDeleteId] = useState([]);

  const [msg, setMsg] = useState("");

  const postData = async () => {
    if (name != "" && minOrder != "") {
      const form = {
        levelingName: name,
        minOrder: minOrder,
      };
      console.log(form);
      try {
        console.log(form);
        const response = await createLeveling(form)
          .then(async (response) => {
            console.log(response);
          })
          .catch((e) => {
            console.log(e.response);
          });
      } catch (error) {}
      setName("");
      setMsg("");
      inAwait();
      setModalAdd(false);
    } else {
      setMsg("Level Name or Minimal Order can't be empty");
      setModalAdd(true);
    }
  };

  const postDataEdit = async () => {
    if (editName != "" && editMinOrder != "") {
      const form = {
        levelingName: editName,
        minOrder: editMinOrder,
      };
      console.log(form);
      try {
        console.log(form);
        const response = await updateLeveling(editId, form)
          .then(async (response) => {
            console.log(response);
          })
          .catch((e) => {
            console.log(e.response);
          });
      } catch (error) {}
      setName("");
      setMsg("");
      inAwait();
      setModalEdit(false);
    } else {
      setMsg("Level name can't be empty");
      setModalEdit(true);
    }
  };

  const deleteData = async (id) => {
    const response = await deleteLeveling(id).then(async (response) => {
      console.log(response);
    });
    inAwait();
  };

  console.log(editId);
  console.log(editName);

  const inAwait = () => {
    getLeveling().then((response) => {
      console.log(response.data.data);
      setData(response.data.data);
    });
    getNewCustomer(null).then((response) => {
      console.log(response.data.data);
      setDataNewCustomer(response.data.data);
    });
  };
  useEffect(() => {
    inAwait();
  }, []);

  const signOut = () => {
    logout();
    navigate("/login");
  };
  return (
    <>
      <div className="bg-gradient-to-r from-[#54DAA1] to-[#2DCE89]">
        <div className="relative">
          <aside
            className={
              navSideBar === true
                ? "w-24 h-screen p-5 fixed top-0 left-0 right-0 z-10"
                : "w-3/12 h-screen p-5 fixed top-0 left-0 right-0 z-10"
            }
            aria-label="Sidebar"
          >
            <button
              className="absolute bottom-96 right-1 w-10 h-10 bg-gradient-to-r from-[#54DAA1] to-[#2DCE89] rounded-full shadow-lg flex justify-center items-center"
              onClick={() => setNavSideBar(!navSideBar)}
            >
              {navSideBar ? (
                <svg
                  width="9"
                  height="16"
                  viewBox="0 0 9 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 14.3333L7.66667 7.66668L1 1.00001"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              ) : (
                <svg
                  width="10"
                  height="16"
                  viewBox="0 0 10 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.33325 14.6667L1.66659 7.99999L8.33325 1.33332"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              )}
            </button>
            <SidebarComunication navSideBar={navSideBar} />
          </aside>
        </div>
        <div
          className={
            navSideBar === true
              ? "ml-24 h-72 w-11/12 p-5 space-y-5"
              : "ml-auto h-72 w-9/12 p-5 space-y-5"
          }
        >
          {/* Header */}
          <div className="flex flex-row justify-between items-center">
            <div className="text-white space-y-2">
              <div className="flex flex-row space-x-3">
                <p className="text-sm">Pages</p>
                <p className="text-sm">/</p>
                <a href="/communication" className="text-sm">
                  Communication
                </a>
                <p className="text-sm">/</p>
                <a href="/leveling" className="text-sm">
                  Leveling
                </a>
              </div>
              <p className="font-semibold">Leveling</p>
            </div>
            <div className="flex flex-row items-center gap-5">
              <a href="#" className="flex items-center gap-2">
                <svg
                  width="21"
                  height="20"
                  viewBox="0 0 21 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.4938 2.52235C12.7336 1.70165 11.6719 1.24969 10.5 1.24969C9.32191 1.24969 8.25668 1.69891 7.50004 2.51454C6.73519 3.33915 6.36254 4.45985 6.45004 5.67001C6.62348 8.05751 8.44027 9.99969 10.5 9.99969C12.5598 9.99969 14.3735 8.0579 14.5496 5.67079C14.6383 4.47157 14.2633 3.35321 13.4938 2.52235Z"
                    fill="white"
                  />
                  <path
                    d="M17.3752 18.7488H3.62522C3.44525 18.7511 3.26701 18.7133 3.10349 18.6381C2.93997 18.5629 2.79526 18.4522 2.67991 18.314C2.426 18.0105 2.32366 17.596 2.39944 17.1769C2.72913 15.348 3.75804 13.8117 5.37522 12.7332C6.81194 11.7757 8.63186 11.2488 10.5002 11.2488C12.3686 11.2488 14.1885 11.7761 15.6252 12.7332C17.2424 13.8113 18.2713 15.3476 18.601 17.1765C18.6768 17.5957 18.5744 18.0101 18.3205 18.3136C18.2052 18.4519 18.0605 18.5626 17.897 18.6379C17.7335 18.7132 17.5552 18.7511 17.3752 18.7488Z"
                    fill="white"
                  />
                </svg>
                <p className="text-white">{localStorage.getItem("nameUser")}</p>
              </a>
              <button onClick={signOut} className="bg-white p-3 rounded-lg">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.5625 2.8125H2.1875V17.8125H6.5625M13.4375 5.9375L17.8125 10.3125L13.4375 14.6875M7.1875 10.3125H17.8125"
                    stroke="#EF4444"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            </div>
          </div>
          {/* start: Main */}

          <div className="grid grid-cols-12 gap-5">
            <div className="col-span-12">
              <div className="bg-white shadow-lg h-screen rounded-lg p-10">
                <div className="flex flex-row justify-between">
                  <div className="flex items-center justify-center gap-3">
                    <a href="/communication">
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.11261 0.862514L0.862615 7.11251C0.748814 7.23139 0.659608 7.37157 0.600115 7.52501C0.475092 7.82934 0.475092 8.17069 0.600115 8.47501C0.659608 8.62845 0.748814 8.76863 0.862615 8.88751L7.11261 15.1375C7.22916 15.2541 7.36753 15.3465 7.5198 15.4096C7.67208 15.4727 7.83529 15.5051 8.00011 15.5051C8.33299 15.5051 8.65224 15.3729 8.88761 15.1375C9.12299 14.9021 9.25523 14.5829 9.25523 14.25C9.25523 13.9171 9.12299 13.5979 8.88761 13.3625L4.76261 9.25001H14.2501C14.5816 9.25001 14.8996 9.11832 15.134 8.8839C15.3684 8.64948 15.5001 8.33153 15.5001 8.00001C15.5001 7.66849 15.3684 7.35055 15.134 7.11613C14.8996 6.88171 14.5816 6.75001 14.2501 6.75001H4.76261L8.88761 2.63751C9.00478 2.52131 9.09777 2.38306 9.16123 2.23073C9.22469 2.07841 9.25736 1.91503 9.25736 1.75001C9.25736 1.585 9.22469 1.42162 9.16123 1.26929C9.09777 1.11697 9.00478 0.978718 8.88761 0.862514C8.77141 0.745353 8.63316 0.652359 8.48084 0.588898C8.32851 0.525437 8.16513 0.492765 8.00011 0.492765C7.8351 0.492765 7.67172 0.525437 7.51939 0.588898C7.36707 0.652359 7.22882 0.745353 7.11261 0.862514Z"
                          fill="#A1A1AA"
                        />
                      </svg>
                    </a>
                    <h1 className="text-lg font-bold">Leveling</h1>
                  </div>
                  <div className="flex flex-row gap-3">
                    <button
                      onClick={() => setModalAdd(true)}
                      className="bg-green-400 hover:bg-green-300 text-white rounded-lg px-3 py-2 font-medium flex flex-row gap-3 flex items-center"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="currentColor"
                        className="bi bi-plus-lg"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fillRule="evenodd"
                          d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"
                        />
                      </svg>
                      <span>Create Leveling</span>
                    </button>
                  </div>
                </div>
                <div className="grid md:grid-cols-3 lg:grid-cols-3 lg:grid-flow-row gap-5 justify-center items-start md:items-center py-5">
                  <div className="shadow-lg p-5 rounded-xl bg-white">
                    <div className="flex items-start gap-3">
                      <div className="flex flex-col justify-between w-64">
                        <div
                          onClick={() =>
                            navigate("/detail-leveling", {
                              state: {id:null},
                            })
                          }
                          className="cursor-pointer"
                        >
                          <h1 className="text-black font-bold text-xl">
                            New Customer
                          </h1>
                          <p className="text-gray-400 text-sm">{dataNewCustomer?.length} Customers</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  {data != null
                    ? data.map((val, index) => (
                        <div
                          key={index}
                          className="shadow-lg p-5 rounded-xl bg-white"
                        >
                          <div className="flex items-start gap-3">
                            <div className="flex flex-col justify-between w-64">
                              <div
                                onClick={() =>
                                  navigate("/detail-leveling", {
                                    state: { id: val.id },
                                  })
                                }
                                className="cursor-pointer"
                              >
                                <h1 className="text-black font-bold text-xl">
                                  {val.levelingName}
                                </h1>
                                <p className="text-gray-400 text-sm">
                                  {val.counCustomer} Customers
                                </p>
                              </div>
                            </div>

                            <Menu
                              as="div"
                              className="relative inline-block text-left"
                            >
                              <div>
                                <Menu.Button className="inline-flex justify-center w-full rounded-md px-4 py-2 bg-white text-sm font-medium text-gray-400">
                                  <MoreVertIcon></MoreVertIcon>
                                </Menu.Button>
                              </div>

                              <Transition
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                              >
                                <Menu.Items className="z-50 origin-top-right absolute right-0 mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                  <div className="py-1">
                                    <Menu.Item>
                                      {({ active }) => (
                                        <a
                                          href="#"
                                          onClick={() => {
                                            setModalEdit(true);
                                            setEditId(val.id);
                                            setEditName(val.levelingName);
                                            setEditMinOrder(val.minOrder);
                                          }}
                                          className={
                                            active
                                              ? "bg-gray-100 text-gray-900 block px-4 py-2 text-sm"
                                              : "text-gray-700 block px-4 py-2 text-sm"
                                          }
                                        >
                                          <div className="inline-flex justify-center content-center text-gray-400">
                                            Edit
                                          </div>
                                        </a>
                                      )}
                                    </Menu.Item>
                                    <Menu.Item>
                                      {({ active }) => (
                                        <a
                                          href="#"
                                          onClick={() => {
                                            deleteData(val.id);
                                          }}
                                          className={
                                            active
                                              ? "bg-gray-100 text-gray-900 block px-4 py-2 text-sm"
                                              : "text-gray-700 block px-4 py-2 text-sm"
                                          }
                                        >
                                          <div className="inline-flex justify-center content-center text-gray-400">
                                            Delete
                                          </div>
                                        </a>
                                      )}
                                    </Menu.Item>
                                  </div>
                                </Menu.Items>
                              </Transition>
                            </Menu>
                          </div>
                        </div>
                      ))
                    : null}
                </div>
              </div>
            </div>
          </div>

          {/* end: Main */}
        </div>
        {modalAdd ? (
          <>
            <div className="bg-black bg-opacity-75 overflow-y-auto overflow-x-hidden fixed flex justify-center top-0 left-0 right-0 bottom-0 z-50 w-full md:inset-0 h-modal md:h-full">
              {/* <div className="relative w-full max-w-2xl h-full md:h-auto"> */}
              {/* ini yang ditambahin */}
              <div className="relative w-full max-w-lg h-full md:h-auto mt-10">
                {/* ini yang ditambahin */}
                <div className="relative bg-[#F5F8FA] rounded-lg shadow dark:bg-gray-700">
                  <div className="flex justify-between items-start p-4 rounded-t dark:border-gray-600">
                    <h3 className="text-xl font-semibold">Add Leveling</h3>
                    <button
                      type="button"
                      className="bg-transparent rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                      onClick={() => setModalAdd(false)}
                    >
                      <svg
                        className="w-5 h-5"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                    </button>
                  </div>
                  {/* <form onSubmit={postData}> */}

                  {msg != "" ? (
                    <div
                      className="p-3 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800 my-3"
                      role="alert"
                    >
                      {msg}
                    </div>
                  ) : null}
                  <div className="p-6 space-y-6 items-center">
                    <div className="">
                      <label className="block tracking-wide text-gray-700 text-xs font-bold mb-2">
                        Level Name
                      </label>
                      <input
                        type="text"
                        className="block appearance-none w-full border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>
                    <div className="">
                      <label className="block tracking-wide text-gray-700 text-xs font-bold mb-2">
                        Minimal Order
                      </label>
                      <input
                        type="number"
                        className="block appearance-none w-full border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        onChange={(e) => setMinOrder(e.target.value)}
                      />
                    </div>

                    <div className="mt-10 flex flex-row justify-end">
                      <button
                        className="py-3 px-6 rounded-xl bg-gray-100 hover:bg-gray-300 text-gray-400 hover:text-gray-600 font-medium"
                        onClick={() => setModalAdd(false)}
                      >
                        Cancel
                      </button>
                      <button
                        className="py-3 px-6 rounded-xl bg-green-400 hover:bg-green-500 text-white font-semibold ml-2"
                        onClick={postData}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                  {/* </form> */}
                </div>
              </div>
            </div>
          </>
        ) : null}
        {modalEdit ? (
          <>
            <div className="bg-black bg-opacity-75 overflow-y-auto overflow-x-hidden fixed flex justify-center top-0 left-0 right-0 bottom-0 z-50 w-full md:inset-0 h-modal md:h-full">
              {/* <div className="relative w-full max-w-2xl h-full md:h-auto"> */}
              {/* ini yang ditambahin */}
              <div className="relative w-full max-w-lg h-full md:h-auto mt-10">
                {/* ini yang ditambahin */}
                <div className="relative bg-[#F5F8FA] rounded-lg shadow dark:bg-gray-700">
                  <div className="flex justify-between items-start p-4 rounded-t dark:border-gray-600">
                    <h3 className="text-xl font-semibold">Edit Leveling</h3>
                    <button
                      type="button"
                      className="bg-transparent rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                      onClick={() => setModalEdit(false)}
                    >
                      <svg
                        className="w-5 h-5"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                    </button>
                  </div>
                  {/* <form onSubmit={postData}> */}
                  {msg != "" ? (
                    <div
                      className="p-3 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800 my-3"
                      role="alert"
                    >
                      {msg}
                    </div>
                  ) : null}
                  <div className="p-6 space-y-6 items-center">
                    <div className="">
                      <label className="block tracking-wide text-gray-700 text-xs font-bold mb-2">
                        Level Name
                      </label>
                      <input
                        type="text"
                        className="block appearance-none w-full border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        value={editName}
                        onChange={(e) => setEditName(e.target.value)}
                      />
                    </div>
                    <div className="">
                      <label className="block tracking-wide text-gray-700 text-xs font-bold mb-2">
                        Minimal Order
                      </label>
                      <input
                        type="number"
                        className="block appearance-none w-full border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        value={editMinOrder}
                        onChange={(e) => setEditMinOrder(e.target.value)}
                      />
                    </div>

                    <div className="mt-10 flex flex-row justify-end">
                      <button
                        className="py-3 px-6 rounded-xl bg-gray-100 hover:bg-gray-300 text-gray-400 hover:text-gray-600 font-medium"
                        onClick={() => setModalEdit(false)}
                      >
                        Cancel
                      </button>
                      <button
                        className="py-3 px-6 rounded-xl bg-green-400 hover:bg-green-500 text-white font-semibold ml-2"
                        onClick={postDataEdit}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                  {/* </form> */}
                </div>
              </div>
            </div>
          </>
        ) : null}
      </div>
    </>
  );
}

export default Leveling;
