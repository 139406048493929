import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Input from "../Components/Partials/Input/Input";
import api from "../Network/apiClient";
import { authenticate, getNameUser, getUser } from "../Network/Lib/auth";
import logo from "../Assets/img/logo.png";
import { useCookies } from "react-cookie";

function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [msg, setMsg] = useState("");
  const navigate = useNavigate();
  const [cookie, setCookie] = useCookies(["user"]);

  const [typePw, setTypePw] = useState("password");
  const [nameUser, setNameUser] = useState('')

  const handlePw = () => {
    if (typePw == "password") {
      setTypePw("text");
    } else if (typePw == "text") {
      setTypePw("password");
    }
  };
  // console.log("username", username)
  // console.log("password", password)
  const Auth = async (e) => {
    e.preventDefault();
    if (username == "") {
      setMsg("Username Can't be empty");
    } else if (password == "") {
      setMsg("Password Can't be empty");
    }
    else {
      setMsg("")
      await authenticate({ username: username, password: password })
        .then((response) => {
          console.log(response);
          getUser().then((response)=>{
            localStorage.setItem("role", response.data.name);
          })
          getNameUser().then((response) => {
            console.log(response.data)
            // setNameUser(response.data)
            localStorage.setItem("nameUser", response.data);
          })
          localStorage.setItem("token", response.data.accessToken);
          localStorage.setItem("refreshToken", response.data.refreshToken);
          setCookie(
            "set-cookie",
            `${localStorage.getItem("refreshToken")}`,
            { path: "/" }
          );

          // api.axiosClient.interceptors.request.use(function (config) {
          //   config.headers.Authorization = `Bearer ${response.data.accessToken}`;
          //   return config;
          // });
          // console.log(localStorage.getItem('token'))
          console.log(response.data);
          navigate("/dashboard");
        })
        .catch((e) => {
          console.log(e.response);
          if (e.response.data.msg) {
            setMsg(e.response.data.msg);
          }
          else if (e.response.data.message) {
            setMsg(e.response.data.message);
          }
        });
    }
  };

  // console.log(msg);
  return (
    <div className="flex flex-col md:flex-row">
      <div className="bg-login-img h-96 bg-cover md:w-1/2 md:h-screen "></div>
      <div className="md:p-14 p-5 md:w-1/2 flex flex-col items-center gap-10">
        <div className="self-end">
          <img className="md:h-28 h-20" src={logo} />
        </div>
        <div className="w-1/2 space-y-3">
          <h1 className="text-2xl font-bold">Login to using CRM</h1>
          {msg != "" ? (
            <div
              className="p-3 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800"
              role="alert"
            >
              {msg}
            </div>
          ) : null}
          <form onSubmit={Auth} className="box">
            <div className="space-y-3">
              <div className="relative">
                <input
                  type="text"
                  id="username"
                  className="block rounded-t-lg px-2.5 pb-2.5 pt-5 w-full text-sm text-gray-900 border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  onChange={(e) => setUsername(e.target.value)}
                />
                <label
                  htmlFor="username"
                  className="absolute flex gap-3 items-center text-sm text-gray-400 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-2.5 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4"
                >
                  <svg
                    width="18"
                    height="19"
                    viewBox="0 0 18 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17.1212 15.3969C16.679 14.3496 16.0374 13.3984 15.2321 12.5961C14.4292 11.7915 13.4781 11.15 12.4313 10.707C12.4219 10.7023 12.4126 10.7 12.4032 10.6953C13.8633 9.64062 14.8126 7.92266 14.8126 5.98438C14.8126 2.77344 12.211 0.171875 9.00006 0.171875C5.78912 0.171875 3.18756 2.77344 3.18756 5.98438C3.18756 7.92266 4.13678 9.64062 5.59693 10.6977C5.58756 10.7023 5.57818 10.7047 5.56881 10.7094C4.51881 11.1523 3.57662 11.7875 2.76803 12.5984C1.96344 13.4013 1.32194 14.3524 0.878965 15.3992C0.443782 16.424 0.209079 17.5228 0.187559 18.6359C0.186933 18.661 0.191321 18.6858 0.200463 18.7091C0.209605 18.7324 0.223317 18.7537 0.240791 18.7716C0.258264 18.7895 0.279146 18.8037 0.302206 18.8134C0.325265 18.8231 0.350036 18.8281 0.375059 18.8281H1.78131C1.88443 18.8281 1.96646 18.7461 1.96881 18.6453C2.01568 16.8359 2.74225 15.1414 4.02662 13.857C5.35553 12.5281 7.12037 11.7969 9.00006 11.7969C10.8797 11.7969 12.6446 12.5281 13.9735 13.857C15.2579 15.1414 15.9844 16.8359 16.0313 18.6453C16.0337 18.7484 16.1157 18.8281 16.2188 18.8281H17.6251C17.6501 18.8281 17.6749 18.8231 17.6979 18.8134C17.721 18.8037 17.7419 18.7895 17.7593 18.7716C17.7768 18.7537 17.7905 18.7324 17.7997 18.7091C17.8088 18.6858 17.8132 18.661 17.8126 18.6359C17.7891 17.5156 17.5571 16.4258 17.1212 15.3969ZM9.00006 10.0156C7.92428 10.0156 6.91178 9.59609 6.15006 8.83438C5.38834 8.07266 4.96881 7.06016 4.96881 5.98438C4.96881 4.90859 5.38834 3.89609 6.15006 3.13437C6.91178 2.37266 7.92428 1.95312 9.00006 1.95312C10.0758 1.95312 11.0883 2.37266 11.8501 3.13437C12.6118 3.89609 13.0313 4.90859 13.0313 5.98438C13.0313 7.06016 12.6118 8.07266 11.8501 8.83438C11.0883 9.59609 10.0758 10.0156 9.00006 10.0156Z"
                      fill="#A1A1AA"
                    />
                  </svg>
                  Username
                </label>
              </div>
              <div className="relative">
                <input
                  type={typePw}
                  // id="password"
                  className="block rounded-t-lg px-2.5 pb-2.5 pt-5 w-full text-sm text-gray-900 border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  onChange={(e) => setPassword(e.target.value)}
                />
                <a
                  href="#"
                  className="absolute right-5 bottom-4"
                  onClick={handlePw}
                >
                  {typePw == "password" ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-eye-slash"
                      viewBox="0 0 16 16"
                    >
                      <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z" />
                      <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z" />
                      <path d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z" />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-eye"
                      viewBox="0 0 16 16"
                    >
                      <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                      <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                    </svg>
                  )}
                </a>
                <label
                  htmlFor="password"
                  className="absolute flex gap-3 items-center text-sm text-gray-400 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-2.5 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4"
                >
                  <svg
                    width="16"
                    height="21"
                    viewBox="0 0 16 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13 6.5V5.5C13 2.743 10.757 0.5 8 0.5C5.243 0.5 3 2.743 3 5.5V8.5H2C0.897 8.5 0 9.397 0 10.5V18.5C0 19.603 0.897 20.5 2 20.5H14C15.103 20.5 16 19.603 16 18.5V10.5C16 9.397 15.103 8.5 14 8.5H5V5.5C5 3.846 6.346 2.5 8 2.5C9.654 2.5 11 3.846 11 5.5V6.5H13ZM14 10.5L14.002 18.5H2V10.5H14Z"
                      fill="#A1A1AA"
                    />
                  </svg>
                  Password
                </label>
              </div>
              <Link
                to="/forgot-password"
                className="flex justify-end p-2 text-gray-400 text-sm hover:text-gray-500"
              >
                Forget Password?
              </Link>
              <button className="text-white bg-gradient-to-r from-green-1 to-green-2 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-3 text-center mr-2 mb-2 w-full">
                Login
              </button>
              <h1 className="text-center p-2 text-gray-400 text-sm">
                Don’t have an account?{" "}
                <Link
                  to="/create-account"
                  className="text-green-1 hover:text-green-2"
                >
                  Sign Up
                </Link>
              </h1>
            </div>
          </form>
        </div>
        {/* <div className='flex flex-col'>
                    <h1 className='font-bold text-xl text-center pb-10'>
                        Customer Relationship Management
                    </h1>
                    <div>
                        <h1 className='font-bold text-2xl'>Hello,</h1>
                        <h1 className='text-red-600 font-bold text-2xl'>Welcome Back!</h1>
                        <div className='py-10 flex flex-col'>
                            <form onSubmit={Auth} className='box'>
                                <p className='has-text-centered'>{msg}</p>
                                <Input height={'mb-5'} title={'Username'} icon={<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"></path>} placeholder={'Username'} type={'text'} value={username} onChange={(e) => setUsername(e.target.value)}/>
                                <Input title={'Password'} icon={<path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z'></path>} placeholder={'Password'} type={'password'} value={password} onChange={(e) => setPassword(e.target.value)}/>
                                <Link to='/' className='flex justify-end p-2 font-semibold text-gray-500 text-sm hover:text-blue-600'>Forget Password?</Link>
                                <div className='mt-4'>
                                    <button className="text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-3 text-center mr-2 mb-2 w-full">Login</button>
                                </div>
                                <h1 className='text-center p-2 font-semibold text-gray-500 text-sm'>Don’t have an account? <Link to='/create-account' className='text-blue-600'> Sign Up</Link></h1>
                            </form>
                        </div>
                    </div>
                    <h1 className='font-bold text-gray-500 text-center'>Powered By <span className='text-cyan-500'>POWER<span className='text-black'>KERTO</span></span></h1>
                </div> */}
      </div>
    </div>
  );
}

export default Login;
