import React from "react";
// import { useEffect, useState } from "react";
// import { Line } from "react-chartjs-2";
// import { useNavigate } from "react-router-dom";
import Navbar from "../Components/Navbar/Navbar";
// import CardInfo from "../Components/Partials/Card/CardInfo";
// import ClosingGrowthTable from "../Components/Partials/Table/ClosingGrowthTable";
// import AddCustomer from "../Components/Partials/Modal/AddCustomer";
// import api from "../Network/apiClient";
// import { getToken } from "../Network/Lib/auth";
// import { countContact } from "../Network/Lib/contact";
// import { countCS } from "../Network/Lib/cs";
// import { countLead } from "../Network/Lib/lead";
// import { countProduct } from "../Network/Lib/product";
import TableDataCustomer from "../Components/Partials/Table/TableDataCustomer";
import AddCustomerDashboard from "../Components/Partials/Modal/AddCustomerDashboard";
import FilterDataCustomer from "../Components/Partials/Modal/FilterDataCustomer";
import AddBroadcast from "../Components/Partials/Modal/AddBroadcast";
import TablePromotion from "../Components/Partials/Table/TablePromotion";
import CreatePromo from "../Components/Partials/Modal/CreatePromo";

// const state = {
//   labels: [
//     "Jan",
//     "Feb",
//     "Mar",
//     "Apr",
//     "May",
//     "Jun",
//     "Jul",
//     "Aug",
//     "Sept",
//     "Oct",
//     "Nov",
//     "Des",
//   ],
//   datasets: [
//     {
//       label: "Closing Rate",
//       backgroundColor: "rgba(75,192,192,1)",
//       borderColor: "rgba(0,0,0,1)",
//       borderWidth: 1,
//       data: [65, 59, 80, 81, 56, 77, 44, 84, 100, 91, 10, 15],
//     },
//   ],
// };

function Promotion() {

  return (
    <div>
      <Navbar />
      <div className="px-10 py-20">
        <div className="p-10 space-y-6 shadow-lg bg-[#F5F8FA] rounded-md mt-10">
          <div className="flex flex-col md:flex-row justify-between items-start md:items-center">
            <div className="flex flex-col">
              <h1 className="text-xl font-bold">List Promotion</h1>
              <h1 className="text-gray-500 font-semibold">500 Data</h1>
            </div>
            <div className="flex flex-col md:flex-row items-start md:items-center gap-2">
              <CreatePromo />
              <div className="flex flex-row rounded-lg bg-gray-50 border border-gray-300 mt-1">
                <input
                  type="text"
                  id="table-search"
                  className="bg-transparent border-none text-gray-700 leading-tight focus:outline-none focus:ring-transparent"
                  placeholder="Search"
                />
                <div className="flex items-center pr-3 pointer-events-none">
                  <svg
                    className="w-5 h-5 text-gray-500 dark:text-gray-400"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </div>
              </div>
            </div>
          </div>
          <TablePromotion />
        </div>
      </div>
    </div>
  );
}

export default Promotion;
