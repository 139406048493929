import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import RegisterImage from "../Assets/img/RegisterImage.png";
import Input from "../Components/Partials/Input/Input";
import { register } from "../Network/Lib/auth";
import logo from "../Assets/img/logo.png";
import success from "../Assets/img/register.gif";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

function Register() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [msg, setMsg] = useState("");
  const [company, setCompany] = useState("");
  const [companyCode, setCompanyCode] = useState("");
  const navigate = useNavigate();

  const Register = async (e) => {
    e.preventDefault();

    register({
      name: name,
      email: email,
      username: username,
      password: password,
      phone: phone,
      company: company,
      companyCode: companyCode,
    })
      .then((response) => {
        console.log(response);
        sethidden(true);
        // navigate("/");
        setTimeout(() => {
          navigate("/login");
        }, 1000);
      })
      .catch((e) => {
        console.log(e.response);
        if (e.response) {
          setMsg(e.response.data.message);
        }
      });
  };
  const [hidden, sethidden] = useState(false);
  const [typePw, setTypePw] = useState("password");

  const handlePw = () => {
    if (typePw == "password") {
      setTypePw("text");
    } else if (typePw == "text") {
      setTypePw("password");
    }
  };

  return (
    <div>
      {hidden ? (
        <div className="h-screen flex flex-col justify-center items-center">
          <img src={success} className="w-96" />
          <h1 className="text-2xl font-bold text-blue-2">Congratulation !</h1>
          <p className="text-gray-500" s>
            Your account has been created successfully
          </p>
        </div>
      ) : (
        <div className="flex flex-col md:flex-row">
          <div className="md:w-1/2 md:h-screen flex flex-col justify-center px-20 gap-3">
            <h1 className="text-2xl font-bold">Create New Account</h1>
            {msg != "" ? (
              <div
                className="p-3 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800"
                role="alert"
              >
                {msg}
              </div>
            ) : null}
            <form onSubmit={Register} className="box">
              <div className="space-y-3">
                <div className="relative">
                  <input
                    type="text"
                    className="block rounded-t-lg px-2.5 pb-2.5 pt-5 w-full text-sm text-gray-900 border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                    onChange={(e) => setName(e.target.value)}
                  />
                  <label
                    htmlFor="Fullname"
                    className="absolute flex gap-3 items-center text-sm text-gray-400 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-2.5 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4"
                  >
                    <svg
                      width="18"
                      height="19"
                      viewBox="0 0 18 19"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M17.1212 15.3969C16.679 14.3496 16.0374 13.3984 15.2321 12.5961C14.4292 11.7915 13.4781 11.15 12.4313 10.707C12.4219 10.7023 12.4126 10.7 12.4032 10.6953C13.8633 9.64062 14.8126 7.92266 14.8126 5.98438C14.8126 2.77344 12.211 0.171875 9.00006 0.171875C5.78912 0.171875 3.18756 2.77344 3.18756 5.98438C3.18756 7.92266 4.13678 9.64062 5.59693 10.6977C5.58756 10.7023 5.57818 10.7047 5.56881 10.7094C4.51881 11.1523 3.57662 11.7875 2.76803 12.5984C1.96344 13.4013 1.32194 14.3524 0.878965 15.3992C0.443782 16.424 0.209079 17.5228 0.187559 18.6359C0.186933 18.661 0.191321 18.6858 0.200463 18.7091C0.209605 18.7324 0.223317 18.7537 0.240791 18.7716C0.258264 18.7895 0.279146 18.8037 0.302206 18.8134C0.325265 18.8231 0.350036 18.8281 0.375059 18.8281H1.78131C1.88443 18.8281 1.96646 18.7461 1.96881 18.6453C2.01568 16.8359 2.74225 15.1414 4.02662 13.857C5.35553 12.5281 7.12037 11.7969 9.00006 11.7969C10.8797 11.7969 12.6446 12.5281 13.9735 13.857C15.2579 15.1414 15.9844 16.8359 16.0313 18.6453C16.0337 18.7484 16.1157 18.8281 16.2188 18.8281H17.6251C17.6501 18.8281 17.6749 18.8231 17.6979 18.8134C17.721 18.8037 17.7419 18.7895 17.7593 18.7716C17.7768 18.7537 17.7905 18.7324 17.7997 18.7091C17.8088 18.6858 17.8132 18.661 17.8126 18.6359C17.7891 17.5156 17.5571 16.4258 17.1212 15.3969ZM9.00006 10.0156C7.92428 10.0156 6.91178 9.59609 6.15006 8.83438C5.38834 8.07266 4.96881 7.06016 4.96881 5.98438C4.96881 4.90859 5.38834 3.89609 6.15006 3.13437C6.91178 2.37266 7.92428 1.95312 9.00006 1.95312C10.0758 1.95312 11.0883 2.37266 11.8501 3.13437C12.6118 3.89609 13.0313 4.90859 13.0313 5.98438C13.0313 7.06016 12.6118 8.07266 11.8501 8.83438C11.0883 9.59609 10.0758 10.0156 9.00006 10.0156Z"
                        fill="#A1A1AA"
                      />
                    </svg>
                    Full Name
                  </label>
                </div>
                <div className="relative">
                  <input
                    type="text"
                    className="block rounded-t-lg px-2.5 pb-2.5 pt-5 w-full text-sm text-gray-900 border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                  <label
                    htmlFor="username"
                    className="absolute flex gap-3 items-center text-sm text-gray-400 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-2.5 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4"
                  >
                    <svg
                      width="18"
                      height="19"
                      viewBox="0 0 18 19"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M17.1212 15.3969C16.679 14.3496 16.0374 13.3984 15.2321 12.5961C14.4292 11.7915 13.4781 11.15 12.4313 10.707C12.4219 10.7023 12.4126 10.7 12.4032 10.6953C13.8633 9.64062 14.8126 7.92266 14.8126 5.98438C14.8126 2.77344 12.211 0.171875 9.00006 0.171875C5.78912 0.171875 3.18756 2.77344 3.18756 5.98438C3.18756 7.92266 4.13678 9.64062 5.59693 10.6977C5.58756 10.7023 5.57818 10.7047 5.56881 10.7094C4.51881 11.1523 3.57662 11.7875 2.76803 12.5984C1.96344 13.4013 1.32194 14.3524 0.878965 15.3992C0.443782 16.424 0.209079 17.5228 0.187559 18.6359C0.186933 18.661 0.191321 18.6858 0.200463 18.7091C0.209605 18.7324 0.223317 18.7537 0.240791 18.7716C0.258264 18.7895 0.279146 18.8037 0.302206 18.8134C0.325265 18.8231 0.350036 18.8281 0.375059 18.8281H1.78131C1.88443 18.8281 1.96646 18.7461 1.96881 18.6453C2.01568 16.8359 2.74225 15.1414 4.02662 13.857C5.35553 12.5281 7.12037 11.7969 9.00006 11.7969C10.8797 11.7969 12.6446 12.5281 13.9735 13.857C15.2579 15.1414 15.9844 16.8359 16.0313 18.6453C16.0337 18.7484 16.1157 18.8281 16.2188 18.8281H17.6251C17.6501 18.8281 17.6749 18.8231 17.6979 18.8134C17.721 18.8037 17.7419 18.7895 17.7593 18.7716C17.7768 18.7537 17.7905 18.7324 17.7997 18.7091C17.8088 18.6858 17.8132 18.661 17.8126 18.6359C17.7891 17.5156 17.5571 16.4258 17.1212 15.3969ZM9.00006 10.0156C7.92428 10.0156 6.91178 9.59609 6.15006 8.83438C5.38834 8.07266 4.96881 7.06016 4.96881 5.98438C4.96881 4.90859 5.38834 3.89609 6.15006 3.13437C6.91178 2.37266 7.92428 1.95312 9.00006 1.95312C10.0758 1.95312 11.0883 2.37266 11.8501 3.13437C12.6118 3.89609 13.0313 4.90859 13.0313 5.98438C13.0313 7.06016 12.6118 8.07266 11.8501 8.83438C11.0883 9.59609 10.0758 10.0156 9.00006 10.0156Z"
                        fill="#A1A1AA"
                      />
                    </svg>
                    Username
                  </label>
                </div>
                <div className="relative">
                  <input
                    type="email"
                    className="block rounded-t-lg px-2.5 pb-2.5 pt-5 w-full text-sm text-gray-900 border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <label
                    htmlFor="email"
                    className="absolute flex gap-3 items-center text-sm text-gray-400 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-2.5 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4"
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.35713 4.28564H18.6428C19.3544 4.2856 20.039 4.55783 20.5562 5.04651C21.0734 5.53518 21.384 6.20324 21.4243 6.91364L21.4286 7.07136V16.9285C21.4286 17.64 21.1564 18.3246 20.6677 18.8418C20.179 19.359 19.511 19.6696 18.8006 19.7099L18.6428 19.7142H5.35713C4.64558 19.7143 3.96099 19.442 3.44378 18.9534C2.92658 18.4647 2.61598 17.7966 2.5757 17.0862L2.57141 16.9285V7.07136C2.57136 6.35981 2.8436 5.67522 3.33227 5.15802C3.82094 4.64082 4.48901 4.33021 5.19941 4.28993L5.35713 4.28564H18.6428H5.35713ZM20.1428 9.34707L12.2966 13.4271C12.2206 13.4666 12.1374 13.4905 12.052 13.4974C11.9667 13.5043 11.8808 13.4941 11.7994 13.4674L11.7034 13.4271L3.85713 9.34707V16.9285C3.85714 17.305 3.9987 17.6676 4.25371 17.9446C4.50872 18.2215 4.85852 18.3924 5.2337 18.4234L5.35713 18.4285H18.6428C19.0194 18.4285 19.3822 18.2868 19.6592 18.0316C19.9361 17.7764 20.1069 17.4264 20.1377 17.0511L20.1428 16.9285V9.34707V9.34707ZM18.6428 5.57136H5.35713C4.98068 5.57137 4.618 5.71293 4.34108 5.96794C4.06416 6.22295 3.89324 6.57276 3.86227 6.94793L3.85713 7.07136V7.89764L12 12.1319L20.1428 7.89764V7.07136C20.1428 6.69477 20.0011 6.33198 19.7459 6.05504C19.4908 5.7781 19.1407 5.60727 18.7654 5.5765L18.6428 5.57136Z"
                        fill="#A1A1AA"
                      />
                    </svg>
                    Email
                  </label>
                </div>
                <div className="relative">
                  <input
                    type="text"
                    className="block rounded-t-lg px-2.5 pb-2.5 pt-5 w-full text-sm text-gray-900 border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                    minLength="10"
                    maxLength="13"
                    pattern="\d*"
                    onChange={(e) => setPhone(e.target.value)}
                  />
                  <label
                    htmlFor="text"
                    className="absolute flex gap-3 items-center text-sm text-gray-400 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-2.5 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4"
                  >
                    <svg
                      width="20"
                      height="21"
                      viewBox="0 0 20 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M17.5001 20.25H17.3726C2.63506 19.4025 0.542564 6.9675 0.250064 3.1725C0.226495 2.87744 0.26138 2.58062 0.35272 2.29906C0.444059 2.0175 0.590058 1.75673 0.782352 1.53169C0.974645 1.30665 1.20945 1.12177 1.47332 0.98763C1.73719 0.853493 2.02493 0.772741 2.32006 0.750001H6.45256C6.75298 0.74971 7.04657 0.839633 7.29529 1.00812C7.54401 1.17661 7.73641 1.4159 7.84756 1.695L8.98756 4.5C9.09733 4.77266 9.12457 5.07156 9.0659 5.35957C9.00723 5.64758 8.86524 5.912 8.65756 6.12L7.06006 7.7325C7.3096 9.15056 7.9887 10.4578 9.00549 11.4772C10.0223 12.4967 11.3277 13.1792 12.7451 13.4325L14.3726 11.82C14.5837 11.6146 14.8506 11.4759 15.14 11.4213C15.4294 11.3666 15.7286 11.3983 16.0001 11.5125L18.8276 12.645C19.1025 12.7597 19.337 12.9536 19.5013 13.202C19.6656 13.4504 19.7522 13.7422 19.7501 14.04V18C19.7501 18.5967 19.513 19.169 19.0911 19.591C18.6691 20.0129 18.0968 20.25 17.5001 20.25ZM2.50006 2.25C2.30115 2.25 2.11039 2.32902 1.96973 2.46967C1.82908 2.61032 1.75006 2.80109 1.75006 3V3.06C2.09506 7.5 4.30756 18 17.4551 18.75C17.5536 18.7561 17.6524 18.7427 17.7457 18.7105C17.839 18.6783 17.9251 18.6281 17.9989 18.5626C18.0728 18.4971 18.133 18.4176 18.1761 18.3288C18.2192 18.24 18.2443 18.1436 18.2501 18.045V14.04L15.4226 12.9075L13.2701 15.045L12.9101 15C6.38506 14.1825 5.50006 7.6575 5.50006 7.59L5.45506 7.23L7.58506 5.0775L6.46006 2.25H2.50006Z"
                        fill="#A1A1AA"
                      />
                    </svg>
                    Phone
                  </label>
                </div>
                <div className="relative">
                  <input
                    type={typePw}
                    className="block rounded-t-lg px-2.5 pb-2.5 pt-5 w-full text-sm text-gray-900 border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <a
                    href="#"
                    className="absolute right-5 bottom-4"
                    onClick={handlePw}
                  >
                    {typePw == "password" ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-eye-slash"
                        viewBox="0 0 16 16"
                      >
                        <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z" />
                        <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z" />
                        <path d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z" />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-eye"
                        viewBox="0 0 16 16"
                      >
                        <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                        <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                      </svg>
                    )}
                  </a>
                  <label
                    htmlFor="password"
                    className="absolute flex gap-3 items-center text-sm text-gray-400 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-2.5 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4"
                  >
                    <svg
                      width="16"
                      height="21"
                      viewBox="0 0 16 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13 6.5V5.5C13 2.743 10.757 0.5 8 0.5C5.243 0.5 3 2.743 3 5.5V8.5H2C0.897 8.5 0 9.397 0 10.5V18.5C0 19.603 0.897 20.5 2 20.5H14C15.103 20.5 16 19.603 16 18.5V10.5C16 9.397 15.103 8.5 14 8.5H5V5.5C5 3.846 6.346 2.5 8 2.5C9.654 2.5 11 3.846 11 5.5V6.5H13ZM14 10.5L14.002 18.5H2V10.5H14Z"
                        fill="#A1A1AA"
                      />
                    </svg>
                    Password
                  </label>
                </div>
                <div className="relative">
                  <input
                    type="text"
                    className="block rounded-t-lg px-2.5 pb-2.5 pt-5 w-full text-sm text-gray-900 border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                    onChange={(e) => setCompany(e.target.value)}
                  />
                  <label
                    htmlFor="company"
                    className="absolute flex gap-3 items-center text-sm text-gray-400 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-2.5 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4"
                  >
                    <svg
                      width="20"
                      height="21"
                      viewBox="0 0 20 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M17 19.5V3.5C17 2.96957 16.7893 2.46086 16.4142 2.08579C16.0391 1.71071 15.5304 1.5 15 1.5H5C4.46957 1.5 3.96086 1.71071 3.58579 2.08579C3.21071 2.46086 3 2.96957 3 3.5V19.5M17 19.5H19M17 19.5H12M3 19.5H1M3 19.5H8M7 5.5H8M7 9.5H8M12 5.5H13M12 9.5H13M8 19.5V14.5C8 14.2348 8.10536 13.9804 8.29289 13.7929C8.48043 13.6054 8.73478 13.5 9 13.5H11C11.2652 13.5 11.5196 13.6054 11.7071 13.7929C11.8946 13.9804 12 14.2348 12 14.5V19.5M8 19.5H12"
                        stroke="#A1A1AA"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    Company
                  </label>
                </div>
                <div className="relative">
                  <input
                    type="text"
                    className="block rounded-t-lg px-2.5 pb-2.5 pt-5 w-full text-sm text-gray-900 border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                    onChange={(e) => setCompanyCode(e.target.value)}
                  />
                  <label
                    htmlFor="company"
                    className="absolute flex gap-3 items-center text-sm text-gray-400 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-2.5 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4"
                  >
                    <svg
                      width="18"
                      height="21"
                      viewBox="0 0 18 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.00001 21L2.67301 13.5383C2.58509 13.4262 2.49809 13.3135 2.41201 13.2C1.33124 11.7763 0.747389 10.0374 0.750009 8.25C0.750009 6.06196 1.6192 3.96354 3.16638 2.41637C4.71355 0.869194 6.81197 0 9.00001 0C11.188 0 13.2865 0.869194 14.8336 2.41637C16.3808 3.96354 17.25 6.06196 17.25 8.25C17.2526 10.0366 16.669 11.7747 15.5888 13.1978L15.588 13.2C15.588 13.2 15.363 13.4955 15.3293 13.5353L9.00001 21ZM3.60901 12.2963C3.61051 12.2963 3.78451 12.5272 3.82426 12.5767L9.00001 18.681L14.1825 12.5685C14.2155 12.5272 14.391 12.2948 14.3918 12.294C15.2746 11.1308 15.7517 9.71027 15.75 8.25C15.75 6.45979 15.0388 4.7429 13.773 3.47703C12.5071 2.21116 10.7902 1.5 9.00001 1.5C7.2098 1.5 5.49291 2.21116 4.22704 3.47703C2.96117 4.7429 2.25001 6.45979 2.25001 8.25C2.24844 9.71118 2.72609 11.1326 3.60976 12.2963H3.60901Z"
                        fill="#A1A1AA"
                      />
                      <path
                        d="M12.75 12H11.25V6H6.75V12H5.25V6C5.2504 5.6023 5.40856 5.221 5.68978 4.93978C5.971 4.65856 6.3523 4.5004 6.75 4.5H11.25C11.6477 4.5004 12.029 4.65856 12.3102 4.93978C12.5914 5.221 12.7496 5.6023 12.75 6V12Z"
                        fill="#A1A1AA"
                      />
                      <path
                        d="M8.25 10.5H9.75V12H8.25V10.5ZM8.25 7.5H9.75V9H8.25V7.5Z"
                        fill="#A1A1AA"
                      />
                    </svg>
                    Company Code
                  </label>
                  <p className="mt-2 text-xs text-gray-400 dark:text-gray-400">
                    *3 Characters (Ex. ABC)
                  </p>
                </div>
                <button className="text-white bg-gradient-to-r from-green-1 to-green-2 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-3 text-center mr-2 mb-2 w-full">
                  Create Account
                </button>
              </div>
            </form>
          </div>
          <div className="bg-register-img h-96 bg-cover md:w-1/2 md:h-screen md:block hidden">
            <div className="flex flex-row justify-end p-10">
              <img className="md:h-28 h-20" src={logo} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Register;
