import React, { useState, Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon, PlusIcon } from "@heroicons/react/solid";
import Profile from "../../../Assets/img/Profile.jpg";
import { createProduct } from "../../../Network/Lib/product";
import { useNavigate } from "react-router-dom";
import CurrencyFormat from "react-currency-format";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function AddProduct({ onFinishCreate }) {
  const [showAddSingleProduct, setShowAddSingleProduct] = useState(false);
  const [showAddImportProduct, setShowAddImportProduct] = useState(false);

  const [productName, setProductName] = useState("");
  const [productCode, setProductCode] = useState("");
  const [productPrice, setProductPrice] = useState(0);
  const [productImage, setProductImage] = useState([]);
  const [productUnit, setProductUnit] = useState([]);

  const [msg, setMsg] = useState("");

  // const navigate = useNavigate();
  // console.log(productName);
  // console.log(productCode);
  // console.log(productPrice);

  let base64code = "";

  const onLoad = (fileString) => {
    base64code = fileString;
  };

  console.log(base64code)

  const getBase64 = (file) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      onLoad(reader.result);
      // console.log(reader.result);
    };
  };

  const onSubmitAddProduct = async () => {
    const err = [];
    if (productName == "") {
      err.push("Product Name");
    }
    if (productCode == "") {
      err.push("Product Code");
    }
    if (productUnit == "") {
      err.push("Product Unit");
    }
    if (productPrice == "" || productPrice == "0") {
      err.push("Product Price");
    }
    if (err.length > 0) {
      setMsg(err);
    } else {
      const form = {
        name: productName,
        code: productCode,
        unit: productUnit,
        price: productPrice,
        image: base64code,
      };
      console.log(form);
      await createProduct(form)
        .then((response) => {
          setShowAddSingleProduct(false);
          // setProductName("");
          // setProductCode("");
          // setProductPrice(0);
          // setProductImage(null);
          onFinishCreate();
        })
        .catch((e) => {
          console.log(e.response);
          if (e.response) {
            setMsg(e.response.data.message);

            console.log(e.response.data);
          }
        });
    }
  };

  // console.log(productPrice);

  // var CurrencyFormat = require("react-currency-format");
  return (
    <>
      {/* <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-green-400 text-sm font-medium text-white hover:bg-green-500 focus:outline-none">
            <PlusIcon className="mr-4 h-5 w-5" aria-hidden="true" />
            Add Product
          </Menu.Button>
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="origin-top-right absolute right-0 mt-2 w-fit rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="py-1">
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={() => setShowAddSingleProduct(true)}
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "block px-4 py-2 text-sm"
                    )}
                  >
                    <div className="inline-flex justify-center content-center ">
                      Single Product
                    </div>
                  </button>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={() => setShowAddImportProduct(true)}
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "block px-4 py-2 text-sm"
                    )}
                  >
                    <div className="inline-flex justify-center content-center">
                      Import Product
                    </div>
                  </button>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu> */}
      <button
        type="button"
        className="bg-green-400 hover:bg-green-500 text-white rounded-lg px-3 py-2 font-medium flex flex-row gap-3 flex items-center"
        onClick={() => setShowAddSingleProduct(true)}
      >
        <PlusIcon className="mr-4 h-5 w-5" aria-hidden="true" />
        Add Product
      </button>

      {showAddSingleProduct ? (
        <>
          <div className="bg-black bg-opacity-75 overflow-y-auto overflow-x-hidden fixed flex justify-center top-0 left-0 right-0 bottom-0 z-50 w-full md:inset-0 h-modal md:h-full">
            {/* <div className="relative w-full max-w-2xl h-full md:h-auto"> */}
            {/* ini yang ditambahin */}
            <div className="relative fixed w-full max-w-2xl h-full md:h-auto my-5">
              {/* ini yang ditambahin */}
              <div className="relative bg-[#F5F8FA] rounded-lg shadow dark:bg-gray-700">
                <div className="flex justify-between items-start p-4 dark:border-gray-600">
                  <h3 className="text-xl font-semibold px-4">Add Product</h3>
                  <button
                    type="button"
                    className="rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                    onClick={() => setShowAddSingleProduct(false)}
                  >
                    <svg
                      className="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </button>
                </div>
                {msg != "" ? (
                  <div
                    className="p-3 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800 my-3"
                    role="alert"
                  >
                    <ul class="list-disc list-inside">
                      {msg.map((val) => (
                        <li>{val} Can't be empty</li>
                      ))}
                    </ul>
                  </div>
                ) : null}
                <div className="p-6 space-y-6 items-center">
                  <div className="px-10">
                    <label className="block mb-2 text-sm font-medium text-gray-900">
                      Product Name
                    </label>
                    <input
                      type="text"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full"
                      placeholder="Product Name"
                      onChange={(e) => setProductName(e.target.value)}
                    />
                  </div>
                  <div className="px-10">
                    <label className="block mb-2 text-sm font-medium text-gray-900">
                      Code
                    </label>
                    <input
                      type="text"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full"
                      placeholder="Ex. ETA"
                      onChange={(e) => setProductCode(e.target.value)}
                    />
                  </div>
                  <div className="px-10">
                    <label className="block mb-2 text-sm font-medium text-gray-900">
                      Price
                    </label>
                    {/* <input
                      type="text"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full"
                      placeholder="Rp"
                      onChange={(e) => setProductPrice(e.target.value)}
                    /> */}
                    <CurrencyFormat
                      thousandSeparator={true}
                      prefix={"Rp. "}
                      placeholder="Rp. 0"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full"
                      onValueChange={(values) => {
                        const { formattedValue, value } = values;
                        setProductPrice(value)
                      }}
                    />
                  </div>
                  <div className="px-10">
                    <label className="block mb-2 text-sm font-medium text-gray-900">
                      Unit
                    </label>
                    <select
                      placeholder="Select Gender ( Male; Female )"
                      className="bg-white rounded border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      // value={setGender}
                      onChange={(e) => setProductUnit(e.target.value)}
                    >
                      <option hidden>Select Unit</option>
                      <option value="box">Box</option>
                      <option value="bottle">Bottle</option>
                      <option value="pack">Pack</option>
                    </select>
                  </div>
                  <div className="px-10">
                    <label className="block mb-2 text-sm font-medium text-gray-900">
                      Image
                    </label>
                    <input
                      type="file"
                      className="bg-gray-100 border border-white text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full"
                      onChange={(e) => {
                        // setProductImage(e.target.files[0]);
                        getBase64(e.target.files[0]);
                        // console.log(e.target.files[0]);
                      }}
                    />
                  </div>
                  <div className="mt-10 flex flex-row justify-end gap-3">
                    <button
                      className="py-2 px-6 text-sm rounded-xl bg-gray-100 hover:bg-gray-200 text-gray-400 hover:text-gray-600"
                      onClick={() => setShowAddSingleProduct(false)}
                    >
                      Cancel
                    </button>
                    <button
                      onClick={onSubmitAddProduct}
                      className="py-2 px-6 text-sm rounded-xl bg-green-500 hover:bg-green-600 text-white"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
      {showAddImportProduct ? (
        <>
          <div className="bg-black bg-opacity-75 overflow-y-auto overflow-x-hidden fixed flex justify-center top-0 left-0 right-0 bottom-0 z-50 w-full md:inset-0 h-modal md:h-full">
            {/* <div className="relative w-full max-w-2xl h-full md:h-auto"> */}
            {/* ini yang ditambahin */}
            <div className="relative fixed w-full max-w-md h-full md:h-auto my-20">
              {/* ini yang ditambahin */}
              <div className="relative bg-[#F5F8FA] rounded-lg shadow dark:bg-gray-700">
                <div className="flex justify-between items-start p-4 dark:border-gray-600">
                  <h3 className="text-xl font-semibold">Import Product</h3>
                  <button
                    type="button"
                    className="rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                    onClick={() => setShowAddImportProduct(false)}
                  >
                    <svg
                      className="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </button>
                </div>
                <div className="p-6 space-y-6 items-center">
                  <div className="px-10">
                    <label className="block mb-2 text-sm font-medium text-gray-900">
                      Upload File
                    </label>
                    <input
                      type="file"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full"
                      placeholder="Product Name"
                    />
                  </div>
                  <div className="px-10 text-sm text-gray-400 space-y-3">
                    <p className="flex gap-2">
                      <span>1.</span>
                      <span>
                        Prepare a CSV or Excel file of your product using our
                        template. Download our{" "}
                        <a
                          href="#"
                          className="text-cyan-500 hover:text-cyan-600"
                        >
                          CSV template
                        </a>{" "}
                        or{" "}
                        <a
                          href="#"
                          className="text-cyan-500 hover:text-cyan-600"
                        >
                          Excel Template
                        </a>
                        .
                      </span>
                    </p>
                    <p className="flex gap-2">
                      <span>2.</span>
                      <span>
                        Upload the CSV or XLS file from step 1 you’ve filled to
                        adding multiple product to list.
                      </span>
                    </p>
                  </div>
                  <div className="mt-10 flex flex-row justify-end gap-3">
                    <button
                      className="py-2 px-6 text-sm rounded-xl bg-gray-100 hover:bg-gray-200 text-gray-400 hover:text-gray-600"
                      onClick={() => setShowAddImportProduct(false)}
                    >
                      Cancel
                    </button>
                    <button className="py-2 px-6 text-sm rounded-xl bg-green-500 hover:bg-green-600 text-white">
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
}

export default AddProduct;
