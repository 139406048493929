import React, { useState, Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon, PlusIcon } from "@heroicons/react/solid";
import Profile from "../../../Assets/img/Profile.jpg";
import { pointCreated } from "../../../Network/Lib/point";
import { useNavigate } from "react-router-dom";
import CurrencyFormat from "react-currency-format";


function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function AddPoint({ onFinishCreate }) {
  const [showRule, setShowRule] = useState(false);
  

  const [minPrice, setMinPrice] = useState();
  const [maxPrice, setMaxPrice] = useState();
  const [point, setPoint] = useState();
  const [isUnlimited, setIsUnlimited] = useState(false);
  const [msg, setMsg] = useState("");


  // const [isChecked, setIsChecked] = useState(false);

  const handleOnChange = () => {
    setIsUnlimited(!isUnlimited);
  };
  const onAddPoint = async () => {
    const err = [];
    if (minPrice == "") {
      err.push("Min Price");
    }
    if (maxPrice == "") {
      err.push("Max Price");
    }
    if (point == "") {
      err.push("Point");
    }
    // if (isUnlimited == false || isUnlimited == true) {
    //   err.push("Is Unlimited");
    // }
    if (err.length > 0) {
      setMsg(err);
    } else {
    const form = {
      minPrice : minPrice,
      maxPrice : maxPrice,
      point : point,
      isUnlimited : isUnlimited,
    };
    console.log(form);
    await pointCreated(form)
      .then((response) => {
        setShowRule(false);
        // setProductName("");
        // setProductCode("");
        // setProductPrice(0);
        // setProductImage(null);
        onFinishCreate();
      })
      .catch((e) => {
        console.log(e.response);
        if (e.response) {
          setMsg(e.response.data.message);

          console.log(e.response.data);
        }
      });}
  };

  // console.log(productPrice);

  // var CurrencyFormat = require("react-currency-format");
  return (
    <>
    
      <div className="relative inline-block text-left">
        <button
            onClick={() => setShowRule(true)}
            className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-green-400 text-sm font-medium text-white hover:bg-green-500 focus:outline-none"
          >
            <PlusIcon className="mr-4 h-5 w-5" aria-hidden="true" />
            Create Rule
          </button>

      </div>
      {showRule ? (
        <>
          <div className="bg-black bg-opacity-75 overflow-y-auto overflow-x-hidden fixed flex justify-center top-0 left-0 right-0 bottom-0 z-50 w-full md:inset-0 h-modal md:h-full">
            {/* <div className="relative w-full max-w-2xl h-full md:h-auto"> */}
            {/* ini yang ditambahin */}
            <div className="relative fixed w-full max-w-2xl h-full md:h-auto my-5">
              {/* ini yang ditambahin */}
              <div className="relative bg-[#F5F8FA] rounded-lg shadow dark:bg-gray-700">
                <div className="flex justify-between items-start p-6 dark:border-gray-600">
                  <h3 className="text-xl font-semibold px-4">Create Rule</h3>
                 
                </div>
                {/* {msg != "" ? (
                  <div
                    className="p-3 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800 my-3"
                    role="alert"
                  >
                    <ul class="list-disc list-inside">
                      {msg.map((val) => (
                        <li>{val} Can't be empty</li>
                      ))}
                    </ul>
                  </div>
                ) : null} */}
                <div className="p-6 space-y-6 items-center">
                  
                <div className="lg:px-10 px-5">
                  <label className="block mb-2 text-sm font-medium text-gray-900">
                  Rule
                  </label>
                  <div className="grid lg:grid-cols-12 grid-cols-8 lg:gap-5 gap-2">
                    <div className="lg:col-span-4 col-span-3">
                      <div className="">
                      <CurrencyFormat
                          type="text"
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-green-500 focus:border-green-500 block w-full"
                          placeholder="Min. order"
                          // onChange={(e) => setMinPrice(e.target.value)}
                          thousandSeparator={true}
                          onValueChange={(values) => {
                            const { formattedValue, value } = values;
                            setMinPrice(value)
                          }}
                        />
                      </div>
                    </div>
                    <div className="lg:col-span-1 col-span-2 text-center">
                      <div className="py-2">
                        <label className="block mb-2 text-sm font-medium text-gray-900">
                         -
                        </label>
                       
                      </div>
                    </div>
                    <div className="lg:col-span-4 col-span-3">
                      <div className="">
                       
                      <CurrencyFormat
                          type="text"
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full"
                          placeholder="Max. order"
                          // onChange={(e) => setMaxPrice(e.target.value)}
                          thousandSeparator={true}
                          onValueChange={(values) => {
                            const { formattedValue, value } = values;
                            setMaxPrice(value)
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-span-3">
                      <div className="py-2">
                        <div className="flex items-center mb-4">
                            <input id="default-checkbox" type="checkbox"  className="w-4 h-4 text-green-600 bg-gray-100 border-gray-300 rounded focus:ring-green-500 dark:focus:ring-green-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" 
                            // onChange={(e) => setIsUnlimited(e.target.value)}
                            checked={isUnlimited}
                            onChange={handleOnChange}
                            />
                            <label for="default-checkbox" className="ml-2 text-sm font-medium text-green-500 dark:text-gray-300">Unlimited</label>
                        </div>
                        
                      </div>
                    </div>


                  </div>

                </div>
                <div className="lg:px-10 px-5">
                  <div className="">
                      <label className="block mb-2 text-sm font-medium text-gray-900">
                      Points
                      </label>
                       
                      <CurrencyFormat
                         type="text"
                         className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full"
                         placeholder=""
                        //  onChange={(e) => setPoint(e.target.value)}
                        thousandSeparator={true}
                        onValueChange={(values) => {
                          const { formattedValue, value } = values;
                          setPoint(value)
                        }}
                       />
                     </div>

                </div>
                
                  <div className="mt-10 flex flex-row justify-end gap-3">
                    <button
                      className="py-2 px-6 text-sm rounded-xl bg-gray-100 hover:bg-gray-200 text-gray-400 hover:text-gray-600"
                      onClick={() => setShowRule(false)}
                    >
                      Cancel
                    </button>
                    <button
                       onClick={onAddPoint}
                      className="py-2 px-6 text-sm rounded-xl bg-green-500 hover:bg-green-600 text-white"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    
    </>
  );
}

export default AddPoint;
