import api from "../apiClient";

export function createPromo(data) {
  return api.axiosClient.post("/promo/create", data);
}

export function updatePromo(id,data) {
  return api.axiosClient.put(`/promo/update/${id}`, data);
}

export function getPromo(){
  return api.axiosClient.get(`/promo/get`)
}

export function deletePromo(id) {
  return api.axiosClient.delete(`/promo/delete/${id}`);
}