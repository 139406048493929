const crossSelling = {
  labels: [
    "Jan",
    "Feb",
    "March",
    "April",
    "May",
    "June",
    "July",
    "Augst",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ],
  datasets: [
    {
      label: "FRE",
      backgroundColor: "#F94144",
      borderWidth: 1,
      data: [65, 59, 80, 81, 56, 77],
    },
    {
      label: "ETA",
      backgroundColor: "#DFDFDF",
      borderWidth: 1,
      data: [65, 59, 80, 81, 56, 77],
    },
    {
      label: "GIZ",
      backgroundColor: "#90BE6D",
      borderWidth: 1,
      data: [65, 59, 80, 81, 56, 77],
    },
    {
      label: "RUB",
      backgroundColor: "#2D9CDB",
      borderWidth: 1,
      data: [65, 59, 80, 81, 56, 77],
    },
    {
      label: "GEN",
      backgroundColor: "#88A5AD",
      borderWidth: 1,
      data: [65, 59, 80, 81, 56, 77],
    },
    {
      label: "NUT",
      backgroundColor: "#E4B07B",
      borderWidth: 1,
      data: [65, 59, 80, 81, 56, 77],
    },
    {
      label: "WEH",
      backgroundColor: "#FFD233",
      borderWidth: 1,
      data: [65, 59, 80, 81, 56, 77],
    },
    {
      label: "KUT",
      backgroundColor: "#DFDFDF",
      borderWidth: 1,
      data: [65, 59, 80, 81, 56, 77],
    },
    {
      label: "BRI",
      backgroundColor: "#FFB573",
      borderWidth: 1,
      data: [65, 59, 80, 81, 56, 77],
    },
    {
      label: "OPT",
      backgroundColor: "#61D9FF",
      borderWidth: 1,
      data: [65, 59, 80, 81, 56, 77],
    },
    {
      label: "ROY",
      backgroundColor: "#A42727",
      borderWidth: 1,
      data: [65, 59, 80, 81, 56, 77],
    },
    {
      label: "DEE",
      backgroundColor: "#166880",
      borderWidth: 1,
      data: [65, 59, 80, 81, 56, 77],
    },
    {
      label: "OTH",
      backgroundColor: "#DF2BAD",
      borderWidth: 1,
      data: [65, 59, 80, 81, 56, 77],
    },
    {
      label: "VIS",
      backgroundColor: "#7B61FF",
      borderWidth: 1,
      data: [65, 59, 80, 81, 56, 77],
    },
  ],
};

export default crossSelling;
