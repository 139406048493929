import React from "react";
import Profile from "../../../Assets/img/Profile.jpg";

function ListChat({ ContactName, PhoneNumber, Times, MessageChat, onClick }) {
  return (
    <button
      className="w-full flex flex-col justify-center items-center py-2 border-b"
      typeof="submit"
      onClick={onClick}
    >
      <div className="w-full flex items-center justify-between">
        <div className="flex items-center gap-1">
          <img src={Profile} className="w-10 h-10 rounded-full shadow-sm" />
          <div>
            <div className="flex flex-row gap-3">
              <p className="text-sm font-medium text-left truncate">{ContactName}</p>
              {/* <p className="text-xs font-thin text-gray-600">{PhoneNumber}</p> */}
            </div>
            {/* <p className="text-xs font-thin text-left">{MessageChat}</p> */}
            <p className="text-xs font-thin text-left">{PhoneNumber}</p>
          </div>
        </div>

        <div>
          <div className="flex flex-col items-center space-y-2 rounded-full">
            <p className="text-xs text-green-600 text-center">{Times}</p>
            <p className="bg-green-400 w-4 h-4 rounded-full text-xs text-gray-600">2</p>
          </div>
        </div>
      </div>
    </button>
  );
}

export default ListChat;
