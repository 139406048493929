import React, { useEffect } from "react";

const TableFooter = ({ range, setPage, page, slice }) => {
  useEffect(() => {
    if (slice.length < 1 && page !== 1) {
      setPage(page - 1);
    }
  }, [slice, page, setPage]);
  return (
    <div className="inline-flex mt-2 xs:mt-0">
      {range.map((el, index) => (
        <button
          key={index}
          className={`${"py-2 px-4 mr-2 text-sm font-medium text-black bg-white rounded-xl hover:bg-gray-900 hover:text-white"} ${
            page === el ? "bg-gray-900 text-white" : "bg-white text-black"
          }`}
          onClick={() => setPage(el)}
        >
          {el}
        </button>
      ))}
    </div>
  );
};

export default TableFooter;
