import React, { useState } from "react";
import { Link } from "react-router-dom";
import Profile from "../../../Assets/img/Profile.jpg";
import Delete from "../../../Assets/img/Delete-data.png";
import archive from "../../../Assets/img/archive.png";
import logout from "../../../Assets/img/logout.png";
import arrowNext from "../../../Assets/icon/comunication/arrowNext.svg";

function CardWhatsapp({ names, phone, statusWa }) {
  const [showDelete, setShowDelete] = useState(false);
  const [showArchive, setShowArchive] = useState(false);
  const [showLogOut, setShowLogOut] = useState(false);

  return (
    <div className="h-52 w-auto p-3 flex justify-center items-center bg-white rounded-2xl shadow-lg border border-gray-50">
      <div className="flex flex-col items-center">
        <div>
          {/* <img
            className="mb-3 w-24 h-24 rounded-full shadow-lg"
            src={Profile}
            alt="Bonnie image"
          /> */}
        </div>
        <div className="flex justify-between w-full">
          <div className="flex flex-col gap-2">
            <h5 className="mb-1 text-xl font-medium text-gray-900">{names}</h5>
            <span className="text-sm text-gray-500">{phone}</span>
            <span className="text-sm text-[#2DCE89] font-semibold">
              {statusWa}
            </span>
          </div>
          <Link to="/overview-cs">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.546 20.25C9.546 20.4489 9.62502 20.6397 9.76567 20.7803C9.90632 20.921 10.0971 21 10.296 21H20.25C20.8467 21 21.419 20.7629 21.841 20.341C22.2629 19.919 22.5 19.3467 22.5 18.75V3.75C22.5 3.15326 22.2629 2.58097 21.841 2.15901C21.419 1.73705 20.8467 1.5 20.25 1.5H5.25C4.65326 1.5 4.08097 1.73705 3.65901 2.15901C3.23705 2.58097 3 3.15326 3 3.75V13.704C3 13.9029 3.07902 14.0937 3.21967 14.2343C3.36032 14.375 3.55109 14.454 3.75 14.454C3.94891 14.454 4.13968 14.375 4.28033 14.2343C4.42098 14.0937 4.5 13.9029 4.5 13.704V3.75C4.5 3.55109 4.57902 3.36032 4.71967 3.21967C4.86032 3.07902 5.05109 3 5.25 3H20.25C20.4489 3 20.6397 3.07902 20.7803 3.21967C20.921 3.36032 21 3.55109 21 3.75V18.75C21 18.9489 20.921 19.1397 20.7803 19.2803C20.6397 19.421 20.4489 19.5 20.25 19.5H10.296C10.0971 19.5 9.90632 19.579 9.76567 19.7197C9.62502 19.8603 9.546 20.0511 9.546 20.25Z"
                fill="url(#paint0_linear_3426_4806)"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.5 8.25C16.5 8.05109 16.421 7.86032 16.2803 7.71967C16.1396 7.57902 15.9489 7.5 15.75 7.5H8.24997C8.05106 7.5 7.86029 7.57902 7.71964 7.71967C7.57899 7.86032 7.49997 8.05109 7.49997 8.25C7.49997 8.44891 7.57899 8.63968 7.71964 8.78033C7.86029 8.92098 8.05106 9 8.24997 9H13.9395L1.71897 21.219C1.57814 21.3598 1.49902 21.5508 1.49902 21.75C1.49902 21.9492 1.57814 22.1402 1.71897 22.281C1.8598 22.4218 2.05081 22.5009 2.24997 22.5009C2.44913 22.5009 2.64014 22.4218 2.78097 22.281L15 10.0605V15.75C15 15.9489 15.079 16.1397 15.2196 16.2803C15.3603 16.421 15.5511 16.5 15.75 16.5C15.9489 16.5 16.1396 16.421 16.2803 16.2803C16.421 16.1397 16.5 15.9489 16.5 15.75V8.25Z"
                fill="url(#paint1_linear_3426_4806)"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_3426_4806"
                  x1="22.5"
                  y1="1.5"
                  x2="-0.187287"
                  y2="16.0199"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#2DCECC" />
                  <stop offset="1" stopColor="#2DCE89" />
                </linearGradient>
                <linearGradient
                  id="paint1_linear_3426_4806"
                  x1="16.5"
                  y1="7.5"
                  x2="-0.952891"
                  y2="18.6698"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#2DCECC" />
                  <stop offset="1" stopColor="#2DCE89" />
                </linearGradient>
              </defs>
            </svg>
          </Link>
        </div>

        <div className="flex mt-4 space-x-3 lg:mt-6">
          <a
            href="/crm-chat"
            target="_blank"
            className="shadow-lg flex items-center justify-center text-white bg-gradient-to-r from-[#3AAF85] to-[#55C79E] hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm p-3 text-center"
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 30 30"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M25.1999 8.55C21.5999 3 14.2499 1.35 8.54991 4.8C2.99991 8.25 1.19991 15.75 4.79991 21.3L5.09991 21.75L3.89991 26.25L8.39991 25.05L8.84991 25.35C10.7999 26.4 12.8999 27 14.9999 27C17.2499 27 19.4999 26.4 21.4499 25.2C26.9999 21.6 28.6499 14.25 25.1999 8.55ZM22.0499 20.1C21.4499 21 20.6999 21.6 19.6499 21.75C19.0499 21.75 18.2999 22.05 15.2999 20.85C12.7499 19.65 10.6499 17.7 9.14991 15.45C8.24991 14.4 7.79991 13.05 7.64991 11.7C7.64991 10.5 8.09991 9.45 8.84991 8.7C9.14991 8.4 9.44991 8.25 9.74991 8.25H10.4999C10.7999 8.25 11.0999 8.25 11.2499 8.85C11.5499 9.6 12.2999 11.4 12.2999 11.55C12.4499 11.7 12.4499 12 12.2999 12.15C12.4499 12.45 12.2999 12.75 12.1499 12.9C11.9999 13.05 11.8499 13.35 11.6999 13.5C11.3999 13.65 11.2499 13.95 11.3999 14.25C11.9999 15.15 12.7499 16.05 13.4999 16.8C14.3999 17.55 15.2999 18.15 16.3499 18.6C16.6499 18.75 16.9499 18.75 17.0999 18.45C17.2499 18.15 17.9999 17.4 18.2999 17.1C18.5999 16.8 18.7499 16.8 19.0499 16.95L21.4499 18.15C21.7499 18.3 22.0499 18.45 22.1999 18.6C22.3499 19.05 22.3499 19.65 22.0499 20.1Z"
                fill="white"
              />
            </svg>
          </a>
          <Link
            to="#"
            onClick={() => setShowArchive(true)}
            className="shadow-lg flex items-center justify-center p-3 text-white bg-gradient-to-r from-purple-500 via-purple-600 to-purple-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-purple-300 font-medium rounded-lg"
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.4708 3.528L12.4715 1.52866C12.4097 1.46663 12.3362 1.41744 12.2553 1.38392C12.1744 1.3504 12.0877 1.3332 12.0002 1.33333H4.00016C3.9126 1.3332 3.82588 1.3504 3.74499 1.38392C3.6641 1.41744 3.59063 1.46663 3.52883 1.52866L1.5295 3.528C1.46721 3.58978 1.41781 3.66332 1.38416 3.74434C1.35052 3.82536 1.3333 3.91226 1.3335 4V12.6667C1.3335 13.402 1.9315 14 2.66683 14H13.3335C14.0688 14 14.6668 13.402 14.6668 12.6667V4C14.667 3.91226 14.6498 3.82536 14.6162 3.74434C14.5825 3.66332 14.5331 3.58978 14.4708 3.528ZM4.27616 2.66666H11.7242L12.3908 3.33333H3.6095L4.27616 2.66666ZM2.66683 12.6667V4.66666H13.3335L13.3348 12.6667H2.66683Z"
                fill="white"
              />
              <path
                d="M9.33317 6H6.6665V8H4.6665L7.99984 11.3333L11.3332 8H9.33317V6Z"
                fill="white"
              />
            </svg>
          </Link>
          <Link
            to="#"
            onClick={() => setShowLogOut(true)}
            className="shadow-lg flex items-center justify-center p-3 text-white bg-gradient-to-r from-[#EC0C38] via-[#EC0C38] to-[#F5365C] hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm"
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9 8C9 8.55313 8.55313 9 8 9C7.44688 9 7 8.55313 7 8V1C7 0.447812 7.44688 0 8 0C8.55313 0 9 0.447812 9 1V8ZM2.5 8C2.5 11.0375 4.9625 13.5 8 13.5C11.0375 13.5 13.5 11.0375 13.5 8C13.5 6.3 12.7281 4.77812 11.5156 3.76875C11.0906 3.41562 11.0312 2.78531 11.3844 2.36063C11.7375 1.93594 12.3687 1.87813 12.7937 2.23125C14.4437 3.60625 15.5 5.68125 15.5 7.97188C15.5 12.1406 12.1406 15.5 8 15.5C3.85938 15.5 0.5 12.1406 0.5 7.97188C0.5 5.68125 1.55469 3.60625 3.20625 2.23125C3.63125 1.87813 4.2625 1.93594 4.61562 2.36063C4.94062 2.78531 4.90938 3.41562 4.48438 3.76875C3.27187 4.77812 2.5 6.3 2.5 8Z"
                fill="white"
              />
            </svg>
          </Link>
          <Link
            to="#"
            onClick={() => setShowDelete(true)}
            className="shadow-lg flex items-center justify-center p-3 text-white bg-black hover:bg-gray-900 focus:ring-4 focus:outline-none focus:ring-orange-300 font-medium rounded-lg text-sm"
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.625 2.875H5.5C5.56875 2.875 5.625 2.81875 5.625 2.75V2.875H10.375V2.75C10.375 2.81875 10.4312 2.875 10.5 2.875H10.375V4H11.5V2.75C11.5 2.19844 11.0516 1.75 10.5 1.75H5.5C4.94844 1.75 4.5 2.19844 4.5 2.75V4H5.625V2.875ZM13.5 4H2.5C2.22344 4 2 4.22344 2 4.5V5C2 5.06875 2.05625 5.125 2.125 5.125H3.06875L3.45469 13.2969C3.47969 13.8297 3.92031 14.25 4.45312 14.25H11.5469C12.0813 14.25 12.5203 13.8313 12.5453 13.2969L12.9312 5.125H13.875C13.9438 5.125 14 5.06875 14 5V4.5C14 4.22344 13.7766 4 13.5 4ZM11.4266 13.125H4.57344L4.19531 5.125H11.8047L11.4266 13.125Z"
                fill="white"
              />
            </svg>
          </Link>
        </div>
      </div>
      {showDelete ? (
        <>
          <div className="bg-black bg-opacity-75 overflow-y-auto overflow-x-hidden fixed flex justify-center top-0 left-0 right-0 bottom-0 z-50 w-full md:inset-0 md:h-full">
            {/* <div className="relative w-full max-w-2xl h-full md:h-auto"> */}
            {/* ini yang ditambahin */}
            <div className="fixed w-full max-w-xl md:h-auto mt-40">
              {/* ini yang ditambahin */}
              <div className="relative bg-[#F5F8FA] rounded-lg shadow dark:bg-gray-700">
                <div className="p-6 space-y-6 items-center">
                  <div className="flex flex-row justify-between items-start gap-5">
                    <div className="flex flex-row gap-5">
                      <img src={Delete} className="w-100 object-cover" />
                      <div className="flex flex-col">
                        <h1 className="font-bold">Delete WhatsApp?</h1>
                        <p className="font-semibold text-gray-400">
                          WhatsApp will be permanently deleted
                        </p>
                        <div className="mt-10 flex flex-row justify-start gap-3">
                          <button className="text-sm py-2 px-5 rounded-xl text-white bg-green-400 hover:bg-green-500 font-semibold">
                            Yes
                          </button>
                          <button
                            className="text-sm px-3 rounded-xl text-gray-400 hover:text-gray-600 bg-gray-100 hover:bg-gray-200 font-semibold"
                            onClick={() => setShowDelete(false)}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                    <button
                      type="button"
                      className="bg-transparent hover:bg-gray-100 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                      onClick={() => setShowDelete(false)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="#A1A5B7"
                        className="bi bi-x-circle"
                        viewBox="0 0 16 16"
                      >
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
      {showArchive ? (
        <>
          <div className="bg-black bg-opacity-75 overflow-y-auto overflow-x-hidden fixed flex justify-center top-0 left-0 right-0 bottom-0 z-50 w-full md:inset-0 md:h-full">
            {/* <div className="relative w-full max-w-2xl h-full md:h-auto"> */}
            {/* ini yang ditambahin */}
            <div className="fixed w-full max-w-xl md:h-auto mt-40">
              {/* ini yang ditambahin */}
              <div className="relative bg-[#F5F8FA] rounded-lg shadow dark:bg-gray-700">
                <div className="p-6 space-y-6 items-center">
                  <div className="flex flex-row justify-between items-start gap-5">
                    <div className="flex flex-row gap-5">
                      <img src={archive} className="w-100 object-cover" />
                      <div className="flex flex-col">
                        <h1 className="font-bold">Archive WhatsApp?</h1>
                        <p className="font-semibold text-gray-400">
                          WhatsApp will be move to archive
                        </p>
                        <div className="mt-10 flex flex-row justify-start gap-3">
                          <button className="text-sm py-2 px-5 rounded-xl text-white bg-green-400 hover:bg-green-500 font-semibold">
                            Yes
                          </button>
                          <button
                            className="text-sm px-3 rounded-xl text-gray-400 hover:text-gray-600 bg-gray-100 hover:bg-gray-200 font-semibold"
                            onClick={() => setShowArchive(false)}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                    <button
                      type="button"
                      className="bg-transparent hover:bg-gray-100 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                      onClick={() => setShowArchive(false)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="#A1A5B7"
                        className="bi bi-x-circle"
                        viewBox="0 0 16 16"
                      >
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
      {showLogOut ? (
        <>
          <div className="bg-black bg-opacity-75 overflow-y-auto overflow-x-hidden fixed flex justify-center top-0 left-0 right-0 bottom-0 z-50 w-full md:inset-0 md:h-full">
            {/* <div className="relative w-full max-w-2xl h-full md:h-auto"> */}
            {/* ini yang ditambahin */}
            <div className="fixed w-full max-w-xl md:h-auto mt-40">
              {/* ini yang ditambahin */}
              <div className="relative bg-[#F5F8FA] rounded-lg shadow dark:bg-gray-700">
                <div className="p-6 space-y-6 items-center">
                  <div className="flex flex-row justify-between items-start gap-5">
                    <div className="flex flex-row gap-5">
                      <img src={logout} className="w-100 object-cover" />
                      <div className="flex flex-col">
                        <h1 className="font-bold">Logout WhatsApp?</h1>
                        <p className="font-semibold text-gray-400">
                          You will be logged out WhatsApp
                        </p>
                        <div className="mt-10 flex flex-row justify-start gap-3">
                          <button className="text-sm py-2 px-5 rounded-xl text-white bg-green-400 hover:bg-green-500 font-semibold">
                            Yes
                          </button>
                          <button
                            className="text-sm px-3 rounded-xl text-gray-400 hover:text-gray-600 bg-gray-100 hover:bg-gray-200 font-semibold"
                            onClick={() => setShowLogOut(false)}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                    <button
                      type="button"
                      className="bg-transparent hover:bg-gray-100 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                      onClick={() => setShowLogOut(false)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="#A1A5B7"
                        className="bi bi-x-circle"
                        viewBox="0 0 16 16"
                      >
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
}

export default CardWhatsapp;
