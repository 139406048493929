import React, { useState, Fragment, useEffect } from "react";
import { Menu, Transition } from "@headlessui/react";
import { PlusIcon } from "@heroicons/react/solid";
import { useNavigate } from "react-router-dom";
import {
  getLeader,
  getSpv,
  registerCS,
  registerLeader,
  registerSupervisor,
} from "../../../Network/Lib/team";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function AddMember({ onFinishCreate }) {
  const[refreshApi, setRefreshApi] = useState(false)
  const navigate = useNavigate();
  const [addLeader, setAddLeader] = useState(false);
  const [addSupervisor, setAddSupervisor] = useState(false);
  const [addCS, setAddCS] = useState(false);

  const [dataleader, setDataLeader] = useState([]);
  const [dataspv, setDataSpv] = useState([]);

  const [msg, setMsg] = useState([]);
  const [msgSpv, setMsgSpv] = useState([]);
  const [msgCS, setMsgCS] = useState([]);

  const [spvLeader, setSpvLeader] = useState("");
  const [nameLeader, setNameLeader] = useState("");
  const [usernameLeader, setUsernameLeader] = useState("");
  const [emailLeader, setEmailLeader] = useState("");
  const [phoneLeader, setPhoneLeader] = useState("");
  const [passwordLeader, setPasswordLeader] = useState("");

  const [nameSpv, setNameSpv] = useState("");
  const [usernameSpv, setUsernameSpv] = useState("");
  const [emailSpv, setEmailSpv] = useState("");
  const [phoneSpv, setPhoneSpv] = useState("");
  const [passwordSpv, setPasswordSpv] = useState("");

  const [leaderCS, setLeaderCS] = useState("");
  const [spvCS, setSpvCS] = useState("");
  const [nameCS, setNameCS] = useState("");
  const [usernameCS, setUsernameCS] = useState("");
  const [emailCS, setEmailCS] = useState("");
  const [phoneCS, setPhoneCS] = useState("");
  const [passwordCS, setPasswordCS] = useState("");

  useEffect(() => {
    getLeader().then((response) => {
      setDataLeader(response.data.data);
      // console.log(response.data.data);
    });
    getSpv().then((response) => {
      setDataSpv(response.data.data);
      console.log("ini spv", response.data.data);
    });
    setRefreshApi(false);
  }, [refreshApi]);

  const postDataSpv = async () => {
    const err = [];
    if (nameSpv == "") {
      err.push("Name Can't be empty");
    }
    if (usernameSpv == "") {
      err.push("Username Can't be empty");
    }
    if (emailSpv == "") {
      err.push("Email Can't be empty");
    }
    if (phoneSpv == "") {
      err.push("Phone Can't be empty");
    }
    if (passwordSpv == "") {
      err.push("Password Can't be empty");
    }
    if (err.length > 0) {
      setMsgSpv(err);
    } else {
      const form = {
        name: nameSpv,
        email: emailSpv,
        phone: phoneSpv,
        username: usernameSpv,
        password: passwordSpv,
      };
      console.log(form);
      try {
        const response = await registerSupervisor(form)
          .then(async (response) => {
            console.log(response);
            setRefreshApi(true)
            setMsg([])
            onFinishCreate();
            setAddSupervisor(false);
          })
          .catch((e) => {
            if (e.response) {
              console.log(e.response.data.message);
              err.push(e.response.data.message);
              setMsgSpv(err);
            }
          });
      } catch (error) {
        console.log(error);
      }
    }
  };

  const postDataLeader = async () => {
    const err = [];
    if (spvLeader == "") {
      err.push("SPV Can't be empty");
    }
    if (nameLeader == "") {
      err.push("Name Can't be empty");
    }
    if (usernameLeader == "") {
      err.push("Username Can't be empty");
    }
    if (emailLeader == "") {
      err.push("Email Can't be empty");
    }
    if (phoneLeader == "") {
      err.push("Phone Can't be empty");
    }
    if (passwordLeader == "") {
      err.push("Password Can't be empty");
    }
    if (err.length > 0) {
      setMsg(err);
    } else {
      const form = {
        name: nameLeader,
        email: emailLeader,
        phone: phoneLeader,
        username: usernameLeader,
        password: passwordLeader,
        supervisorId: spvLeader
      };
      console.log(form);
      try {
        const response = await registerLeader(form)
          .then(async (response) => {
            console.log(response);
            setRefreshApi(true)
            setMsg([])
            onFinishCreate();
            setAddLeader(false);
          })
          .catch((e) => {
            if (e.response) {
              console.log(e.response.data.message);
              err.push(e.response.data.message);
              setMsg(err);
            }
          });
      } catch (error) {
        console.log(error);
      }
    }
  };

  const postDataCS = async () => {
    const err = [];
    if (leaderCS == "") {
      err.push("Leader Can't be empty");
    }
    if (nameCS == "") {
      err.push("Name Can't be empty");
    }
    if (usernameCS == "") {
      err.push("Username Can't be empty");
    }
    if (emailCS == "") {
      err.push("Email Can't be empty");
    }
    if (phoneCS == "") {
      err.push("Phone Can't be empty");
    }
    if (passwordCS == "") {
      err.push("Password Can't be empty");
    }
    if (err.length > 0) {
      setMsgCS(err);
    } else {
      const form = {
        name: nameCS,
        email: emailCS,
        phone: phoneCS,
        username: usernameCS,
        password: passwordCS,
        supervisorId: spvCS,
        leaderId: leaderCS,
      };
      console.log(form);
      try {
        const response = await registerCS(form)
          .then(async (response) => {
            console.log(response);
            setRefreshApi(true)
            setMsg([])
            onFinishCreate();
            setAddCS(false);
          })
          .catch((e) => {
            if (e.response) {
              console.log(e.response.data.message);
              err.push(e.response.data.message);
              setMsgCS(err);
            }
          });
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <>
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-green-400 text-sm font-medium text-white hover:bg-green-500 focus:outline-none">
            <PlusIcon className="mr-4 h-5 w-5" aria-hidden="true" />
            Add Member
          </Menu.Button>
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="z-20 origin-top-right absolute right-0 mt-2 w-fit rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="py-1">
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={() => setAddSupervisor(true)}
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "block px-4 py-2 text-sm w-full"
                    )}
                  >
                    Add Supervisor
                  </button>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={() => setAddLeader(true)}
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "block px-4 py-2 text-sm w-full"
                    )}
                  >
                    Add Leader
                  </button>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={() => setAddCS(true)}
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "block px-4 py-2 text-sm w-full"
                    )}
                  >
                    Add Customer Service
                  </button>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
      {addSupervisor ? (
        <>
          <div className="bg-black bg-opacity-75 overflow-y-auto overflow-x-hidden fixed flex justify-center top-0 left-0 right-0 bottom-0 z-50 w-full md:inset-0 h-modal md:h-full">
            {/* <div className="relative w-full max-w-2xl h-full md:h-auto"> */}
            {/* ini yang ditambahin */}
            <div className="relative fixed w-full max-w-2xl h-full md:h-auto my-5">
              {/* ini yang ditambahin */}
              <div className="relative bg-[#F5F8FA] rounded-lg shadow dark:bg-gray-700">
                <div className="flex justify-between items-start p-4 rounded-t border-b dark:border-gray-600">
                  <div className="flex flex-col">
                    <h3 className="text-xl font-semibold dark:text-white">
                      Add Supervisor
                    </h3>
                  </div>
                  <button
                    type="button"
                    className="bg-transparent hover:bg-gray-100 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                    onClick={() => setAddSupervisor(false)}
                  >
                    <svg
                      className="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </button>
                </div>
                <div className="p-6 space-y-6 items-center">
                  {msgSpv != "" ? (
                    <div
                      className="p-3 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800 my-3"
                      role="alert"
                    >
                      <ul class="list-disc list-inside">
                        {msgSpv.map((val) => (
                          <li>{val} Can't be empty</li>
                        ))}
                      </ul>
                    </div>
                  ) : null}
                  <div className="">
                    <label
                      className="block text-gray-700 font-bold mb-2"
                      for="grid-state"
                    >
                      Full Name
                    </label>
                    <input
                      type="text"
                      className="bg-white border border-white text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full"
                      placeholder="Full Name"
                      onChange={(e) => setNameSpv(e.target.value)}
                    />
                  </div>
                  <div className="">
                    <label
                      className="block text-gray-700 font-bold mb-2"
                      for="grid-state"
                    >
                      Username
                    </label>
                    <input
                      type="text"
                      className="bg-white border border-white text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full"
                      placeholder="Username"
                      onChange={(e) => setUsernameSpv(e.target.value)}
                    />
                  </div>
                  <div className="">
                    <label
                      className="block text-gray-700 font-bold mb-2"
                      for="grid-state"
                    >
                      Email
                    </label>
                    <input
                      type="text"
                      className="bg-white border border-white text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full"
                      placeholder="Email"
                      onChange={(e) => setEmailSpv(e.target.value)}
                    />
                  </div>
                  <div className="">
                    <label
                      className="block text-gray-700 font-bold mb-2"
                      for="grid-state"
                    >
                      Phone
                    </label>
                    <input
                      type="text"
                      className="bg-white border border-white text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full"
                      placeholder="Phone"
                      onChange={(e) => setPhoneSpv(e.target.value)}
                    />
                  </div>
                  <div className="">
                    <label
                      className="block text-gray-700 font-bold mb-2"
                      for="grid-state"
                    >
                      Password
                    </label>
                    <input
                      type="password"
                      className="bg-white border border-white text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full"
                      placeholder="Password"
                      onChange={(e) => setPasswordSpv(e.target.value)}
                    />
                  </div>

                  <div className="mt-10 flex flex-row justify-end">
                    <button
                      className="px-3 rounded-xl text-gray-400 hover:text-gray-600 font-semibold"
                      onClick={() => setAddSupervisor(false)}
                    >
                      Cancel
                    </button>
                    <button
                      className="py-2 px-6 rounded-md text-white bg-green-400 hover:bg-green-500 font-semibold ml-2"
                      onClick={postDataSpv}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
      {addLeader ? (
        <>
          <div className="bg-black bg-opacity-75 overflow-y-auto overflow-x-hidden fixed flex justify-center top-0 left-0 right-0 bottom-0 z-50 w-full md:inset-0 h-modal md:h-full">
            {/* <div className="relative w-full max-w-2xl h-full md:h-auto"> */}
            {/* ini yang ditambahin */}
            <div className="relative fixed w-full max-w-2xl h-full md:h-auto my-5">
              {/* ini yang ditambahin */}
              <div className="relative bg-[#F5F8FA] rounded-lg shadow dark:bg-gray-700">
                <div className="flex justify-between items-start p-4 rounded-t border-b dark:border-gray-600">
                  <div className="flex flex-col">
                    <h3 className="text-xl font-semibold dark:text-white">
                      Add Leader
                    </h3>
                  </div>
                  <button
                    type="button"
                    className="bg-transparent hover:bg-gray-100 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                    onClick={() => setAddLeader(false)}
                  >
                    <svg
                      className="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </button>
                </div>
                <div className="p-6 space-y-6 items-center">
                  {msg != "" ? (
                    <div
                      className="p-3 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800 my-3"
                      role="alert"
                    >
                      <ul class="list-disc list-inside">
                        {msg.map((val) => (
                          <li>{val}</li>
                        ))}
                      </ul>
                    </div>
                  ) : null}

                  <div className="">
                    <label
                      className="block text-gray-700 font-bold mb-2"
                      for="grid-state"
                    >
                      Supervisor
                    </label>
                    <div className="relative">
                      <select
                        className="block appearance-none w-full bg-white border border-white text-gray-400 font-medium py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-white"
                        // value={selectedTemplate}
                        onChange={(e) => setSpvLeader(e.target.value)}
                      >
                        <option hidden>Select Supervisor</option>
                        {dataspv &&
                          dataspv.map((val, index) => (
                            <option key={index} value={val.id}>
                              {val.name}
                            </option>
                          ))}
                      </select>
                      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"></div>
                    </div>
                  </div>
                  <div className="">
                    <label
                      className="block text-gray-700 font-bold mb-2"
                      for="grid-state"
                    >
                      Full Name
                    </label>
                    <input
                      type="text"
                      className="bg-white border border-white text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full"
                      placeholder="Full Name"
                      onChange={(e) => setNameLeader(e.target.value)}
                    />
                  </div>
                  <div className="">
                    <label
                      className="block text-gray-700 font-bold mb-2"
                      for="grid-state"
                    >
                      Username
                    </label>
                    <input
                      type="text"
                      className="bg-white border border-white text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full"
                      placeholder="Username"
                      onChange={(e) => setUsernameLeader(e.target.value)}
                    />
                  </div>
                  <div className="">
                    <label
                      className="block text-gray-700 font-bold mb-2"
                      for="grid-state"
                    >
                      Email
                    </label>
                    <input
                      type="text"
                      className="bg-white border border-white text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full"
                      placeholder="Email"
                      onChange={(e) => setEmailLeader(e.target.value)}
                    />
                  </div>
                  <div className="">
                    <label
                      className="block text-gray-700 font-bold mb-2"
                      for="grid-state"
                    >
                      Phone
                    </label>
                    <input
                      type="text"
                      className="bg-white border border-white text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full"
                      placeholder="Phone"
                      onChange={(e) => setPhoneLeader(e.target.value)}
                    />
                  </div>
                  <div className="">
                    <label
                      className="block text-gray-700 font-bold mb-2"
                      for="grid-state"
                    >
                      Password
                    </label>
                    <input
                      type="password"
                      className="bg-white border border-white text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full"
                      placeholder="Password"
                      onChange={(e) => setPasswordLeader(e.target.value)}
                    />
                  </div>

                  <div className="mt-10 flex flex-row justify-end">
                    <button
                      className="px-3 rounded-xl text-gray-400 hover:text-gray-600 font-semibold"
                      onClick={() => setAddLeader(false)}
                    >
                      Cancel
                    </button>
                    <button
                      className="py-2 px-6 rounded-md text-white bg-green-400 hover:bg-green-500 font-semibold ml-2"
                      onClick={postDataLeader}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
      {addCS ? (
        <>
          <div className="bg-black bg-opacity-75 overflow-y-auto overflow-x-hidden fixed flex justify-center top-0 left-0 right-0 bottom-0 z-50 w-full md:inset-0 h-modal md:h-full">
            {/* <div className="relative w-full max-w-2xl h-full md:h-auto"> */}
            {/* ini yang ditambahin */}
            <div className="relative fixed w-full max-w-2xl h-full md:h-auto my-5">
              {/* ini yang ditambahin */}
              <div className="relative bg-[#F5F8FA] rounded-lg shadow dark:bg-gray-700">
                <div className="flex justify-between items-start p-4 rounded-t border-b dark:border-gray-600">
                  <div className="flex flex-col">
                    <h3 className="text-xl font-semibold dark:text-white">
                      Add Customer Service
                    </h3>
                  </div>
                  <button
                    type="button"
                    className="bg-transparent hover:bg-gray-100 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                    onClick={() => setAddCS(false)}
                  >
                    <svg
                      className="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </button>
                </div>
                <div className="p-6 space-y-6 items-center">
                  {msgCS != "" ? (
                    <div
                      className="p-3 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800 my-3"
                      role="alert"
                    >
                      <ul class="list-disc list-inside">
                        {msgCS.map((val) => (
                          <li>{val} Can't be empty</li>
                        ))}
                      </ul>
                    </div>
                  ) : null}
                  <div className="">
                    <label
                      className="block text-gray-700 font-bold mb-2"
                      for="grid-state"
                    >
                      Leader
                    </label>
                    <div className="relative">
                      <select
                        className="block appearance-none w-full bg-white border border-white text-gray-400 font-medium py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-white"
                        // value={selectedTemplate}
                        onChange={(e) => setLeaderCS(e.target.value)}
                      >
                        <option hidden>Select Leader</option>
                        {dataleader &&
                          dataleader.map((val, index) => (
                            <option key={index} value={val.id}>
                              {val.name}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                  <div className="">
                    <label
                      className="block text-gray-700 font-bold mb-2"
                      for="grid-state"
                    >
                      Supervisor
                    </label>
                    <div className="relative">
                      <select
                        className="block appearance-none w-full bg-white border border-white text-gray-400 font-medium py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-white"
                        // value={selectedTemplate}
                        onChange={(e) => setSpvCS(e.target.value)}
                      >
                        <option hidden>Select Leader</option>
                        {dataspv &&
                          dataspv.map((val, index) => (
                            <option key={index} value={val.id}>
                              {val.name}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                  <div className="">
                    <label
                      className="block text-gray-700 font-bold mb-2"
                      for="grid-state"
                    >
                      Full Name
                    </label>
                    <input
                      type="text"
                      className="bg-white border border-white text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full"
                      placeholder="Full Name"
                      onChange={(e) => setNameCS(e.target.value)}
                    />
                  </div>
                  <div className="">
                    <label
                      className="block text-gray-700 font-bold mb-2"
                      for="grid-state"
                    >
                      Username
                    </label>
                    <input
                      type="text"
                      className="bg-white border border-white text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full"
                      placeholder="Username"
                      onChange={(e) => setUsernameCS(e.target.value)}
                    />
                  </div>
                  <div className="">
                    <label
                      className="block text-gray-700 font-bold mb-2"
                      for="grid-state"
                    >
                      Email
                    </label>
                    <input
                      type="text"
                      className="bg-white border border-white text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full"
                      placeholder="Email"
                      onChange={(e) => setEmailCS(e.target.value)}
                    />
                  </div>
                  <div className="">
                    <label
                      className="block text-gray-700 font-bold mb-2"
                      for="grid-state"
                    >
                      Phone
                    </label>
                    <input
                      type="text"
                      className="bg-white border border-white text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full"
                      placeholder="Phone"
                      onChange={(e) => setPhoneCS(e.target.value)}
                    />
                  </div>
                  <div className="">
                    <label
                      className="block text-gray-700 font-bold mb-2"
                      for="grid-state"
                    >
                      Password
                    </label>
                    <input
                      type="password"
                      className="bg-white border border-white text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full"
                      placeholder="Password"
                      onChange={(e) => setPasswordCS(e.target.value)}
                    />
                  </div>

                  <div className="mt-10 flex flex-row justify-end">
                    <button
                      className="px-3 rounded-xl text-gray-400 hover:text-gray-600 font-semibold"
                      onClick={() => setAddCS(false)}
                    >
                      Cancel
                    </button>
                    <button
                      className="py-2 px-6 rounded-md text-white bg-green-400 hover:bg-green-500 font-semibold ml-2"
                      onClick={postDataCS}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
}

export default AddMember;
