import React from "react";

class Repeater extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formValues: [{ button: "", action: "" }],
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(i, e) {
    let formValues = this.state.formValues;
    formValues[i][e.target.name] = e.target.value;
    this.setState({ formValues });
  }

  addFormFields() {
    this.setState({
      formValues: [...this.state.formValues, { button: "", action: "" }],
    });
  }

  removeFormFields(i) {
    let formValues = this.state.formValues;
    formValues.splice(i, 1);
    this.setState({ formValues });
  }

  handleSubmit(event) {
    event.preventDefault();
    alert(JSON.stringify(this.state.formValues));
  }

  render() {
    return (
      <>
        <form onSubmit={this.handleSubmit}>
          {this.state.formValues.map((element, index) => (
            <div className="form-inline" key={index}>
              <div className="flex flex-row justify-between">
                <p className="text-sm font-medium text-gray-900">Button 1</p>
                {index ? (
                  <button
                    type="button"
                    className="bg-red-500 hover:bg-red-700 text-white font-bold px-2 rounded"
                    onClick={() => this.removeFormFields(index)}
                  >
                    X
                  </button>
                ) : null}
              </div>
              <div className="flex flex-col gap-5 my-5">
                <input
                  type="text"
                  className="bg-gray-50 border border-white text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full"
                  name="button"
                  value={element.button || ""}
                  onChange={(e) => this.handleChange(index, e)}
                  placeholder="Text Button"
                />
                <textarea
                  className="bg-gray-50 border border-white text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full"
                  name="action"
                  value={element.action || ""}
                  onChange={(e) => this.handleChange(index, e)}
                  placeholder="Text Action"
                ></textarea>
              </div>
            </div>
          ))}
          <div className="button-section">
            <button
              className="border border-dashed border-blue-600 px-3 text-blue-600"
              type="button"
              onClick={() => this.addFormFields()}
            >
              + Add Interactive Button
            </button>
          </div>
          <input type="submit" />
        </form>
      </>
    );
  }
}
export default Repeater;
