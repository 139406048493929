import React, { useState, Fragment, useEffect } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon, PlusIcon } from "@heroicons/react/solid";
import Profile from "../../../Assets/img/Profile.jpg";
import { useNavigate } from "react-router-dom";
import { getTeam } from "../../../Network/Lib/team";
import { createPromo } from "../../../Network/Lib/promo";
import CurrencyFormat from "react-currency-format";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function AddPromo({ onFinishCreate }) {
  const [showAddSinglePromo, setShowAddSinglePromo] = useState(false);
  const [showAddMulitiplePromo, setShowAddMulitiplePromo] = useState(false);

  const [dataTeam, setDataTeam] = useState([]);

  const [teamId, setTeamId] = useState([]);
  const [name, setName] = useState([]);
  const [terms, setTerms] = useState([]);
  const [promoNominal, setPromoNominal] = useState([]);
  const [minPurchase, setMinPurchase] = useState([]);
  const [minTransaction, setMinTransaction] = useState([]);
  const [start, setStart] = useState([]);
  const [end, setEnd] = useState([]);

  useEffect(() => {
    getTeam().then((response) => {
      setDataTeam(response.data.data);
      console.log(response.data.data);
    });
  }, []);

  const navigate = useNavigate();

  const onSubmitAddPromo = async () => {
    let form = {
      promoName: name,
      terms: terms,
      promoNominal: promoNominal,
      minPurchase: minPurchase,
      minTransaction: minTransaction,
      startDate: start,
      endDate: end,
      teamId: teamId,
    };

    console.log(form);

    await createPromo(form)
      .then((response) => {
        console.log(response);
        onFinishCreate();
        setShowAddSinglePromo(false);
      })
      .catch((e) => {
        console.log(e.response);
        setShowAddSinglePromo(false);
      });
  };

  return (
    <>
      {/* <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-green-400 text-sm font-medium text-white hover:bg-green-500 focus:outline-none">
            <PlusIcon className="mr-4 h-5 w-5" aria-hidden="true" />
            Create Promo
          </Menu.Button>
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="origin-top-right absolute right-0 mt-2 w-fit rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="py-1">
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={() => setShowAddSinglePromo(true)}
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "block px-4 py-2 text-sm"
                    )}
                  >
                    <div className="inline-flex justify-center content-center ">
                      Single Promo
                    </div>
                  </button>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={() => setShowAddMulitiplePromo(true)}
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "block px-4 py-2 text-sm"
                    )}
                  >
                    <div className="inline-flex justify-center content-center">
                      Multiple Promo
                    </div>
                  </button>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu> */}
      
      <button
        type="button"
        className="bg-green-400 hover:bg-green-500 text-white rounded-lg px-3 py-2 font-medium flex flex-row gap-3 flex items-center"
        onClick={() => setShowAddSinglePromo(true)}
      >
        <PlusIcon className="mr-4 h-5 w-5" aria-hidden="true" />
        Create Promo
      </button>
      {showAddSinglePromo ? (
        <>
          <div className="bg-black bg-opacity-75 overflow-y-auto overflow-x-hidden fixed flex justify-center top-0 left-0 right-0 bottom-0 z-50 w-full md:inset-0 h-modal md:h-full">
            {/* <div className="relative w-full max-w-2xl h-full md:h-auto"> */}
            {/* ini yang ditambahin */}
            <div className="relative fixed w-full max-w-2xl h-full md:h-auto my-5">
              {/* ini yang ditambahin */}
              <div className="relative bg-[#F5F8FA] rounded-lg shadow dark:bg-gray-700">
                <div className="flex justify-between items-start p-4 dark:border-gray-600">
                  <h3 className="text-xl font-semibold px-4">
                    Create Single Promo
                  </h3>
                  <button
                    type="button"
                    className="rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                    onClick={() => setShowAddSinglePromo(false)}
                  >
                    <svg
                      className="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </button>
                </div>
                <div className="p-6 space-y-6 items-center">
                  <div className="px-10">
                    <label className="block mb-2 text-sm font-medium text-gray-900">
                      Team
                    </label>
                    <div className="mt-2">
                      <select
                        className="bg-white rounded-lg border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                        onChange={(e) => setTeamId(e.target.value)}
                      >
                        <option hidden>Select Team</option>
                        {dataTeam &&
                          dataTeam.map((val, index) => (
                            <option value={val.id} key={index}>
                              {val.teamName}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                  <div className="px-10">
                    <label className="block mb-2 text-sm font-medium text-gray-900">
                      Promo Name
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        className="bg-white border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full"
                        placeholder="Promo Name"
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="px-10">
                    <label className="block mb-2 text-sm font-medium text-gray-900">
                      Terms
                    </label>
                    <div className="mt-2">
                      <textarea
                        placeholder="Write text message here"
                        className="bg-white rounded-lg border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                        rows={5}
                        onChange={(e) => setTerms(e.target.value)}
                      ></textarea>
                    </div>
                  </div>
                  <div className="px-10">
                    <label className="block mb-2 text-sm font-medium text-gray-900">
                      Max. Promo Nominal
                    </label>
                    <div className="mt-2">
                      {/* <input
                        type="number"
                        className="bg-white border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full"
                        placeholder="Rp"
                        onChange={(e) => setPromoNominal(e.target.value)}
                      /> */}
                      <CurrencyFormat
                        thousandSeparator={true}
                        prefix={"Rp. "}
                        placeholder="Rp. 0"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full"
                        onValueChange={(values) => {
                          const { formattedValue, value } = values;
                          setPromoNominal(value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="px-10">
                    <label className="block mb-2 text-sm font-medium text-gray-900">
                      Minimal Purchase
                    </label>
                    <div className="grid grid-cols-12 gap-3">
                      <div className="col-span-6">
                        <div className="mt-2">
                          <input
                            type="text"
                            className="bg-white border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full"
                            placeholder="Min. Order"
                            onChange={(e) => setMinPurchase(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-span-6">
                        <div className="mt-2">
                          {/* <input
                            type="text"
                            className="bg-white border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full"
                            placeholder="Min. Transaction (Rp)"
                            onChange={(e) => setMinTransaction(e.target.value)}
                          /> */}
                          <CurrencyFormat
                            thousandSeparator={true}
                            prefix={"Rp. "}
                            placeholder="Min. Transaction (Rp)"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full"
                            onValueChange={(values) => {
                              const { formattedValue, value } = values;
                              setMinTransaction(value);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="px-10">
                    
                    <label className="block mb-2 text-sm font-medium text-gray-900">
                    Tag
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        className="bg-white border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full"
                        placeholder="Tag"
                       
                      />
                    </div>
                  </div> */}
                  <div className="px-10">
                    <label className="block mb-2 text-sm font-medium text-gray-900">
                      Timeline
                    </label>
                    <div className="grid grid-cols-2 gap-3">
                      <div className="mt-2">
                        <input
                          type="date"
                          className="bg-white border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full"
                          onChange={(e) => setStart(e.target.value)}
                        />
                      </div>
                      <div className="mt-2">
                        <input
                          type="date"
                          className="bg-white border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full"
                          onChange={(e) => setEnd(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  {/* <div className="px-10">
                    
                    <label className="block mb-2 text-sm font-medium text-gray-900">
                    Promo Unit Price
                    </label>
                    <div className="mt-2">
                      <input
                        type="number"
                        className="bg-white border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full"
                        placeholder="Rp"
                       
                      />
                    </div>
                  </div> */}

                  <div className="mt-10 flex flex-row justify-end gap-3">
                    <button
                      className="py-2 px-6 text-sm rounded-xl bg-gray-100 hover:bg-gray-200 text-gray-400 hover:text-gray-600"
                      onClick={() => setShowAddSinglePromo(false)}
                    >
                      Cancel
                    </button>
                    <button
                      className="py-2 px-6 text-sm rounded-xl bg-green-500 hover:bg-green-600 text-white"
                      onClick={onSubmitAddPromo}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
      {showAddMulitiplePromo ? (
        <>
          <div className="bg-black bg-opacity-75 overflow-y-auto overflow-x-hidden fixed flex justify-center top-0 left-0 right-0 bottom-0 z-50 w-full md:inset-0 h-modal md:h-full">
            {/* <div className="relative w-full max-w-2xl h-full md:h-auto"> */}
            {/* ini yang ditambahin */}
            <div className="relative fixed w-full max-w-md h-full md:h-auto my-20">
              {/* ini yang ditambahin */}
              <div className="relative bg-[#F5F8FA] rounded-lg shadow dark:bg-gray-700">
                <div className="flex justify-between items-start p-4 dark:border-gray-600">
                  <h3 className="text-xl font-semibold">Add Multiple Promo</h3>
                  <button
                    type="button"
                    className="rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                    onClick={() => setShowAddMulitiplePromo(false)}
                  >
                    <svg
                      className="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </button>
                </div>
                <div className="p-4 space-y-6 items-center">
                  <div className="px-10">
                    <input
                      type="file"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full"
                      placeholder="Product Name"
                    />
                  </div>
                  <div className="px-10 text-sm text-gray-400 space-y-3">
                    <p className="flex gap-2">
                      <span>1.</span>
                      <span>
                        <a
                          href="#"
                          className="text-cyan-500 hover:text-cyan-600"
                        >
                          Download Template.
                        </a>{" "}
                      </span>
                    </p>
                    <p className="flex gap-2">
                      <span>2.</span>
                      <span>
                        Prepare a CSV or Excel file of your template using our
                        template.
                      </span>
                    </p>
                    <p className="flex gap-2">
                      <span>3.</span>
                      <span>
                        Upload the CSV or XLS file from step 1 you’ve filled to
                        adding multiple template to list.
                      </span>
                    </p>
                  </div>
                  <div className="mt-10 flex flex-row justify-end gap-3">
                    <button
                      className="py-2 px-6 text-sm rounded-xl bg-gray-100 hover:bg-gray-200 text-gray-400 hover:text-gray-600"
                      onClick={() => setShowAddMulitiplePromo(false)}
                    >
                      Cancel
                    </button>
                    <button className="py-2 px-6 text-sm rounded-xl bg-green-500 hover:bg-green-600 text-white">
                      Upload File
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
}

export default AddPromo;
