import React, { Component, useState } from "react";
import { Bar } from "react-chartjs-2";
import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import achievement from "../../../Data/Reporting/achievement";
import {
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@heroicons/react/solid";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import omzetTeam from "../../../Data/Reporting/omzetTeam";
import omzetProduct from "../../../Data/Reporting/omzetProduct";
import ClosingRatePerProduct from "../../../Data/Reporting/ClosingRatePerProduct";

{
  /*Vertical Bar Chart*/
}
// const state = {
//     labels: [
//       "Jan",
//       "Feb",
//       "Mar",
//       "Apr",
//       "May",
//       "Jun",
//       "Jul",
//       "Aug",
//       "Sept",
//       "Ockt",
//       "Nov",
//       "Des",
//     ],
//     datasets: [
//       {
//         label: "Name_Product",
//         backgroundColor: "rgba(75,192,192,1)",
//         borderColor: "rgba(0,0,0,1)",
//         borderWidth: 1,
//         data: [65, 59, 80, 81, 56, 77, 44, 84, 100, 91, 10, 15],
//       },
//       {
//         label: "Name_Product",
//         backgroundColor: "rgba(237,76,92,1)",
//         borderColor: "rgba(0,0,0,1)",
//         borderWidth: 1,
//         data: [65, 59, 80, 81, 56, 77, 44, 84, 100, 91, 10, 15],
//       },
//     ],
//   };
{
  /*Vertical Bar Chart*/
}

{
  /*Horizontal Bar Chart*/
}
//  const options_horizontal = {
//     indexAxis: 'y',
//         elements: {
//             bar: {
//             borderWidth: 2,
//             },
//         },
//         responsive: true,
//         plugins: {
//             legend: {
//             position: 'right',
//             },
//             title: {
//             display: true,
//             text: 'Chart.js Horizontal Bar Chart',
//             },
//         },
//   };

// const state_tree = {
//     labels: [
//         "Jan",
//         "Feb",
//         "Mar",
//         "Apr",
//         "May",
//         "Jun",
//         "Jul",
//         "Aug",
//         "Sept",
//         "Ockt",
//         "Nov",
//         "Des",
//       ],
//     datasets: [
//       {
//         label: 'Dataset 1',
//         data: [65, 59, 80, 81, 56, 77, 44, 84, 100, 91, 10, 15],
//         backgroundColor: 'rgb(255, 99, 132)',
//       },
//       {
//         label: 'Dataset 2',
//         data: [65, 59, 80, 81, 56, 77, 44, 84, 100, 91, 10, 15],
//         backgroundColor: 'rgb(75, 192, 192)',
//       },
//       {
//         label: 'Dataset 3',
//         data: [65, 59, 80, 81, 56, 77, 44, 84, 100, 91, 10, 15],
//         backgroundColor: 'rgb(53, 162, 235)',
//       },
//     ],
//   };
{
  /*Horizontal Bar Chart*/
}

{
  /*Stacked Bar Chart*/
}

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function ChartClosingRatePerProduct() {
  return (
    <>
      <div className="border-2 rounded-lg m-1">
        <div className="flex flex-row justify-between px-10 mt-5">
          <div>
            <h1 className="font-bold text-lg">Closing Rate per Product</h1>
            <h1 className="text-gray-400">All Product</h1>
          </div>

          <div className="flex flex-col items-end">
            <div className="relative w-fit">
              <select className="block appearance-none w-full bg-gray-100 border border-gray-200 text-xs text-gray-700 py-2 px-2 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
                <option hidden>Select Product</option>
                <option>Etawaku Platinum</option>
                <option>Gizidat</option>
                <option>Rube</option>
                <option>Freshmag</option>
                <option>Fresh Vision</option>
                <option>Nutriflekes</option>
                <option>Weight Herba</option>
                <option>Generos</option>
                <option>New Briswa</option>
                <option>Optimax</option>
              </select>
            </div>
            <div className="flex flex-col md:flex-row md:items-center items-start mx-5">
              <input
                type={"date"}
                className="text-xs border-t-transparent border-r-transparent border-l-transparent"
              />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-dash"
                viewBox="0 0 16 16"
              >
                <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z" />
              </svg>
              <input
                type={"date"}
                className="text-xs border-t-transparent border-r-transparent border-l-transparent"
              />
            </div>
          </div>
        </div>
        <div className="border-b mt-5"></div>
        <div className="px-16 py-10">
          <Bar
            options={{
              plugins: {
                title: {
                  display: true,
                },
              },
              responsive: true,
              scales: {
                x: {
                  stacked: true,
                },
                y: {
                  stacked: true,
                },
              },
            }}
            data={ClosingRatePerProduct}
          />
        </div>
      </div>
    </>
  );
}

export default ChartClosingRatePerProduct;
