import React, { Component, useState } from "react";
import { Bar } from "react-chartjs-2";
import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/solid";
import omzet from "../../../../Data/Product/omzet";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function ChartOmzet() {
  return (
    <>
      <div className="shadow-md rounded-lg m-1">
        <div className="flex flex-row justify-between px-10 mt-5">
          <div>
            <h1 className="font-bold text-lg">Omzet</h1>
            <h1 className="text-gray-400">Total Omset Rp 500.000.000</h1>
          </div>
        </div>
        <div className="border-b mt-5"></div>
        <div className="px-16 py-10">
          <Bar
            options={{
              plugins: {
                title: {
                  display: true,
                },
              },
              responsive: true,
              scales: {
                x: {
                  stacked: true,
                },
                y: {
                  stacked: true,
                },
              },
            }}
            data={omzet}
          />
        </div>
      </div>
    </>
  );
}

export default ChartOmzet;
