import React, { Component, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Sidebar from "../../Components/Sidebar/Sidebar";
import "aos/dist/aos.css";
import AddProduct from "../../Components/Partials/Modal/AddProduct";
import people from "../../Assets/img/product.png";
import { PencilIcon, TrashIcon } from "@heroicons/react/solid";
import Delete from "../../Assets/img/Delete-data.png";
import Man from "../../Assets/img/Man.png";
import Woman from "../../Assets/img/Woman.png";
import Single from "../../Assets/img/Single.png";
import Married from "../../Assets/img/Married.png";
import Divorced from "../../Assets/img/Divorced.png";
import { getProducts } from "../../Network/Lib/boProduct";
import { getAllProduct, deleteProduct } from "../../Network/Lib/product";
import ChartRevenue from "../../Components/Partials/Chart/New/ChartRevenue";
import DetailChartRevenue from "../../Components/Partials/Chart/New/DetailChartRevenue";
import { logout } from "../../Network/Lib/auth";
import { getOmset, getRevenue, getGender, detailGender } from "../../Network/Lib/dashboard";

function SalesByGender() {
  const navigate = useNavigate();
  const [navSideBar, setNavSideBar] = useState(false);

  const [showDelete, setShowDelete] = useState(false);
  const [showEditProduct, setShowEditProduct] = useState(false);
  const [showImage, setShowImage] = useState(false);
  const [imageUrl, setImageUrl] = useState("");

  const [bmxProducts, setBmxProducts] = useState([]);
  const [crmProducts, setCrmProducts] = useState([]);
 

  const [genderData, setGenderData] = useState([]);
  const [detail, setDetail] = useState([]);

  const [selectedGender, setSelectedGender] = useState([]);

  const [product, setProduct] = useState([]);
  const [marital, setMarital] = useState([]);
  const [age, setAge] = useState([]);
  
  const fetchApi = async () => {
    const genderData = await getGender();
    console.log("gender: ", genderData.data);
    setGenderData(genderData.data.data);
    // setLenght(dailyData.data);
    // setMaleData(maleData.data.male)
    // setFemaleData(femaleData.data.female)
  };
  
  useEffect(() => {
    fetchApi();
  }, []);

  const getGen = async(gender) =>{
    // const selectedCity = await getDistrict(city);
    const detail = await detailGender(gender);
    // console.log(detail.data.detail,'city_detail');
    // // console.log(detail.data.detail.gender.gender['Male'], 'male');
    // setCityDetail(detail.data.detail.product);
    // setGender(detail.data.detail.gender)
    // setMaritalDetail(detail.data.detail.marital)
    setProduct(detail.data.detail.product)
    // setGender(detail.data.detail.gender)
    setAge(detail.data.detail.age)
    setMarital(detail.data.detail.marital)

  }

  useEffect(() => {

    if(selectedGender.length > 0) {
     getGen(selectedGender.join(','));
      console.log(selectedGender)
    }else {
     getGen('null');

    }
  }, [selectedGender]);
  

  const signOut =()=>{
    logout();
    navigate('/login')
  }

  return (
    <>
      <div className="bg-gradient-to-r from-[#54DAA1] to-[#2DCE89]">
        <div className="relative">
          <aside
            className={
              navSideBar === true
                ? "w-24 h-screen p-5 fixed top-0 left-0 right-0 z-10"
                : "w-3/12 h-screen p-5 fixed top-0 left-0 right-0 z-10"
            }
            aria-label="Sidebar"
          >
            <button
              className="absolute top-72 right-1 w-10 h-10 bg-gradient-to-r from-[#54DAA1] to-[#2DCE89] rounded-full shadow-lg flex justify-center items-center"
              onClick={() => setNavSideBar(!navSideBar)}
            >
              {navSideBar ? (
                <svg
                  width="9"
                  height="16"
                  viewBox="0 0 9 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 14.3333L7.66667 7.66668L1 1.00001"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              ) : (
                <svg
                  width="10"
                  height="16"
                  viewBox="0 0 10 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.33325 14.6667L1.66659 7.99999L8.33325 1.33332"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              )}
            </button>
            <Sidebar navSideBar={navSideBar} />
          </aside>
        </div>
        <div
          className={
            navSideBar === true
              ? "ml-24 h-72 w-11/12 p-5 space-y-5"
              : "ml-auto h-72 w-9/12 p-5 space-y-5"
          }
        >
          {/* Header */}
          <div className="flex flex-row justify-between items-center">
            <div className="text-white space-y-2">
              <div className="flex flex-row space-x-3">
                <p className="text-sm">Pages</p>
                <p className="text-sm">/</p>
                <Link to="/Dashboard" className="text-sm">
                  Dashboard
                </Link>
                <p className="text-sm">/</p>
                <Link to="#" className="text-sm">
                  Gender
                </Link>
              </div>
              <p className="font-semibold">Gender</p>
            </div>
            <div className="flex flex-row items-center gap-5">
              <Link to="#" className="flex items-center gap-2">
                <svg
                  width="21"
                  height="20"
                  viewBox="0 0 21 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.4938 2.52235C12.7336 1.70165 11.6719 1.24969 10.5 1.24969C9.32191 1.24969 8.25668 1.69891 7.50004 2.51454C6.73519 3.33915 6.36254 4.45985 6.45004 5.67001C6.62348 8.05751 8.44027 9.99969 10.5 9.99969C12.5598 9.99969 14.3735 8.0579 14.5496 5.67079C14.6383 4.47157 14.2633 3.35321 13.4938 2.52235Z"
                    fill="white"
                  />
                  <path
                    d="M17.3752 18.7488H3.62522C3.44525 18.7511 3.26701 18.7133 3.10349 18.6381C2.93997 18.5629 2.79526 18.4522 2.67991 18.314C2.426 18.0105 2.32366 17.596 2.39944 17.1769C2.72913 15.348 3.75804 13.8117 5.37522 12.7332C6.81194 11.7757 8.63186 11.2488 10.5002 11.2488C12.3686 11.2488 14.1885 11.7761 15.6252 12.7332C17.2424 13.8113 18.2713 15.3476 18.601 17.1765C18.6768 17.5957 18.5744 18.0101 18.3205 18.3136C18.2052 18.4519 18.0605 18.5626 17.897 18.6379C17.7335 18.7132 17.5552 18.7511 17.3752 18.7488Z"
                    fill="white"
                  />
                </svg>
                <p className="text-white">{localStorage.getItem("nameUser")}</p>
              </Link>
              <button onClick={signOut} className="bg-white p-3 rounded-lg">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.5625 2.8125H2.1875V17.8125H6.5625M13.4375 5.9375L17.8125 10.3125L13.4375 14.6875M7.1875 10.3125H17.8125"
                      stroke="#EF4444"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
            </div>
          </div>

          {/* start: Main */}
          <div className="pb-5 space-y-5">
            <div className="grid grid-cols-2 gap-2">
              <div className="bg-white shadow-lg rounded-lg p-6">
                <div className="flex flex-col md:flex-row justify-between items-start md:items-center gap-3">
                  <h1 className="text-lg font-semibold">Sales by Gender</h1>
                </div>
                <div className="mt-5">
                  <div className="overflow-x-auto h-screen">
                    <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                      <thead className="text-xs text-gray-600 uppercase dark:text-gray-400">
                        <tr>
                          <th scope="col" className="py-3 px-6">
                            Gender
                          </th>
                          <th scope="col" className="py-3 px-6">
                            Sales
                          </th>
                          <th scope="col" className="py-3 px-6">
                            Precentage
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {genderData != null ? (
                            genderData?.map((data, i) => {
                            return [
                                <tr className="bg-white">
                                  <td scope="row" className="py-4 px-6">
                                    <div className="flex flex-row gap-5 items-center">
                                      <input
                                        type="checkbox"
                                        value={data.gender}
                                         name={data.gender}
                                         onChange={async (e) =>{
                                            let gender = selectedGender;
                                            if(e.target.checked){
                                              gender.push(e.target.name);
                                            }else{
                                              gender = gender.filter((data) => data != e.target.name);

                                            }
                                            let newGender = [...gender];
                                            setSelectedGender(newGender);

                                            // console.log(newMarital);

                                          }}
                                        className="w-5 h-5 text-blue-600 bg-gray-100 rounded-full border-gray-300 focus:ring-white dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                      />
                                      <p>{data.gender}</p>
                                    </div>
                                  </td>
                                  <td className="py-4 px-6 uppercase">{data.totalSales}</td>
                                  <td className="py-4 px-6">{data.percent}</td>
                                </tr>
                                
                                ];
                            })
                          ) : (
                          
                          <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                           <td scope="row" colSpan={4} className="py-4 px-6 text-center bg-gray-50">
                              Data Not Found
                            </td>
                         </tr>
                        )}
                       
                        {/* <tr className="bg-white">
                          <td scope="row" className="py-4 px-6">
                            <div className="flex flex-row gap-5 items-center">
                              <input
                                type="checkbox"
                                value=""
                                className="w-5 h-5 text-blue-600 bg-gray-100 rounded-full border-gray-300 focus:ring-white dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                              />
                              <p>Male</p>
                            </div>
                          </td>
                          <td className="py-4 px-6 uppercase">680</td>
                          <td className="py-4 px-6">40%</td>
                        </tr>
                        <tr className="bg-white">
                          <td scope="row" className="py-4 px-6">
                            <div className="flex flex-row gap-5 items-center">
                              <input
                                type="checkbox"
                                value=""
                                className="w-5 h-5 text-blue-600 bg-gray-100 rounded-full border-gray-300 focus:ring-white dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                              />
                              <p>Female</p>
                            </div>
                          </td>
                          <td className="py-4 px-6 uppercase">680</td>
                          <td className="py-4 px-6">40%</td>
                        </tr> */}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="bg-white shadow-lg rounded-lg p-6">
                <div className="flex flex-col md:flex-row justify-between items-start md:items-center gap-3">
                  <h1 className="text-lg font-semibold">Details</h1>
                </div>

                {selectedGender.length > 0 ? (

                  <div className="p-2">
                    <h1 className="font-semibold">Product</h1>
                    <div className="bg-gray-50 rounded-lg h-72 p-5 text-gray-600 space-y-3 overflow-y-auto">
                      {product != null ?(
                        product?.map((data, i) => {
                          return [
                            <div className="grid grid-cols-2 justify-between">
                            <p>{data.product}</p>
                            <p className="justify-self-end">{data.percent}</p>
                          </div>
                          ];
                        })
                      ):(

                        <div className="grid grid-cols-2 justify-between">
                          
                        </div>
                      )}
                    
                    </div>
                  </div>
                  ):(
                  <div className="p-2">
                  <h1 className="font-semibold">Product</h1>
                  <div className="bg-gray-50 rounded-lg h-72 p-5 text-gray-600 space-y-3 overflow-y-auto">
                    {product != null ?(
                        product?.map((data, i) => {
                          return [
                            <div className="grid grid-cols-2 justify-between">
                            <p>{data.product}</p>
                            <p className="justify-self-end">{data.percent}</p>
                          </div>
                          ];
                        })
                      ):(

                        <div className="grid grid-cols-2 justify-between">
                          
                        </div>
                    )}
                  </div>
                  </div>

                  )
                }
                {selectedGender.length > 0 ? (
                  <div className="p-2">
                    <h1 className="font-semibold">Marital Status</h1>
                    <div className="flex lg:flex-row flex-col bg-gray-50 rounded-lg p-5 gap-10">
                    

                      {marital != null ?(
                        marital?.map((data, i) => {
                          return [
                          
                              <div className="flex flex-row gap-10">
                                {data.marital_status =='Single' &&  (
                                  <div className="flex flex-row gap-3 items-center">
                                    <div>
                                      <img src={Single} />
                                    </div>
                                    <div className="text-orange-500">
                                      <h3 className="text-xl">{data.percent}</h3>
                                      <p className="text-sm">Single</p>
                                    </div>
                                  </div>
                                 )
                                }
                                {data.marital_status =='Married' &&  (
                                  <div className="flex flex-row gap-3 items-center">
                                    <div>
                                      <img src={Married} />
                                    </div>
                                    <div className="text-teal-500">
                                      <h3 className="text-xl">{data.percent}</h3>
                                      <p className="text-sm">Married</p>
                                    </div>
                                  </div>
                                  )
                                }
                                {data.marital_status =='Divorced' &&  (
                                <div className="flex flex-row gap-3 items-center">
                                  <div>
                                    <img src={Divorced} />
                                  </div>
                                  <div className="text-red-500">
                                    <h3 className="text-xl">{data.percent}</h3>
                                    <p className="text-sm">Divorced</p>
                                  </div>
                                </div> 
                                  )
                                }
                                
                            
                              </div>

                          
                          ];
                        })
                        ):(
                        <div className="flex flex-row gap-10">
                            
                        </div>

                    
                      )}
                    </div>
                  </div>
                  ):(
                    <div className="p-2">
                      <h1 className="font-semibold">Marital Status</h1>
                      <div className="flex lg:flex-row flex-col bg-gray-50 rounded-lg p-5 gap-10">
                        {marital != null ?(
                          marital?.map((data, i) => {
                            return [
                            
                                <div className="flex flex-row gap-10">
                                  {data.marital_status =='Single' &&  (
                                    <div className="flex flex-row gap-3 items-center">
                                      <div>
                                        <img src={Single} />
                                      </div>
                                      <div className="text-orange-500">
                                        <h3 className="text-xl">{data.percent}</h3>
                                        <p className="text-sm">Single</p>
                                      </div>
                                    </div>
                                  )
                                  }
                                  {data.marital_status =='Married' &&  (
                                    <div className="flex flex-row gap-3 items-center">
                                      <div>
                                        <img src={Married} />
                                      </div>
                                      <div className="text-teal-500">
                                        <h3 className="text-xl">{data.percent}</h3>
                                        <p className="text-sm">Married</p>
                                      </div>
                                    </div>
                                    )
                                  }
                                  {data.marital_status =='Divorced' &&  (
                                  <div className="flex flex-row gap-3 items-center">
                                    <div>
                                      <img src={Divorced} />
                                    </div>
                                    <div className="text-red-500">
                                      <h3 className="text-xl">{data.percent}</h3>
                                      <p className="text-sm">Divorced</p>
                                    </div>
                                  </div> 
                                    )
                                  }
                                  
                              
                                </div>

                            
                            ];
                          })
                          ):(
                          <div className="flex flex-row gap-10">
                              
                          </div>
                        )}
                    </div>
                  </div>
                  )
                }

                {selectedGender.length > 0 ? (

                  <div className="p-2">
                    <h1 className="font-semibold">Age</h1>
                    <div className="flex lg:flex-row flex-col rounded-lg py-5 gap-5">
                      {age != null ?(
                        age?.map((data, i) => {
                          return [
                          <div className="flex lg:flex-row flex-col gap-5">
                            {data.age == '16-20' &&  (
                            <div className="text-orange-300 font-bold p-3 bg-gray-50">
                              <h3 className="text-xl">{data.percent}</h3>
                              <p className="text-xs">16-20</p>
                            </div>
                            )
                            }
                            {data.age == '21-25' &&  (
                            <div className="text-orange-300 font-bold p-3 bg-gray-50">
                              <h3 className="text-xl">{data.percent}</h3>
                              <p className="text-xs">21-25</p>
                            </div>
                            )
                            }
                            {data.age == '26-30' &&  (
                            <div className="text-orange-400 font-bold p-3 bg-gray-50">
                              <h3 className="text-xl">{data.percent}</h3>
                              <p className="text-xs">26-30</p>
                            </div>
                              )
                            }
                            {data.age == '31-35' &&  (
                            <div className="text-orange-600 font-bold p-3 bg-gray-50">
                              <h3 className="text-xl">{data.percent}</h3>
                              <p className="text-xs">31-35</p>
                            </div>
                              )
                            }
                            {data.age == '36-40' &&  (
                            <div className="text-orange-700 font-bold p-3 bg-gray-50">
                              <h3 className="text-xl">{data.percent}</h3>
                              <p className="text-xs">36-40</p>
                            </div>
                              )
                            }
                            {data.age == '40+' &&  (
                            <div className="text-orange-900 font-bold p-3 bg-gray-50">
                              <h3 className="text-xl">{data.percent}</h3>
                              <p className="text-sm">40+</p>
                            </div>
                              )
                            }
                          </div>
                          ];
                        })
                        ):(
                        <div className="flex lg:flex-row flex-col gap-5">
                          
                        </div>


                      )}
                    </div>
                  </div>
                  ):(
                    <div className="p-2">
                    <h1 className="font-semibold">Age</h1>
                    <div className="flex lg:flex-row flex-col rounded-lg py-5 gap-5">
                      {age != null ?(
                        age?.map((data, i) => {
                          return [
                          <div className="flex lg:flex-row flex-col gap-5">
                            {data.age == '16-20' &&  (
                            <div className="text-orange-300 font-bold p-3 bg-gray-50">
                              <h3 className="text-xl">{data.percent}</h3>
                              <p className="text-xs">16-20</p>
                            </div>
                            )
                            }
                            {data.age == '21-25' &&  (
                            <div className="text-orange-300 font-bold p-3 bg-gray-50">
                              <h3 className="text-xl">{data.percent}</h3>
                              <p className="text-xs">21-25</p>
                            </div>
                            )
                            }
                            {data.age == '26-30' &&  (
                            <div className="text-orange-400 font-bold p-3 bg-gray-50">
                              <h3 className="text-xl">{data.percent}</h3>
                              <p className="text-xs">26-30</p>
                            </div>
                              )
                            }
                            {data.age == '31-35' &&  (
                            <div className="text-orange-600 font-bold p-3 bg-gray-50">
                              <h3 className="text-xl">{data.percent}</h3>
                              <p className="text-xs">31-35</p>
                            </div>
                              )
                            }
                            {data.age == '36-40' &&  (
                            <div className="text-orange-700 font-bold p-3 bg-gray-50">
                              <h3 className="text-xl">{data.percent}</h3>
                              <p className="text-xs">36-40</p>
                            </div>
                              )
                            }
                            {data.age == '40+' &&  (
                            <div className="text-orange-900 font-bold p-3 bg-gray-50">
                              <h3 className="text-xl">{data.percent}</h3>
                              <p className="text-sm">40+</p>
                            </div>
                              )
                            }
                          </div>
                          ];
                        })
                        ):(
                        <div className="flex lg:flex-row flex-col gap-5">
                          
                        </div>


                      )}
                    </div>
                  </div>

                  )
                }
              </div>
            </div>
          </div>
          {/* end: Main */}
        </div>
       
      </div>
    </>
  );
}

export default SalesByGender;
