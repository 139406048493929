import React, { useState, useEffect } from "react";
import AddIcon from "@mui/icons-material/Add";
import Select from "react-select";
import {
  createTeam,
  createTeamDetail,
  getCS,
  getLeader,
  getSpv,
} from "../../../Network/Lib/team";

function AddTeam({ onFinishCreate }) {
  const [showInput, setShowInput] = useState(false);

  const [name, setName] = useState("");
  const [leader, setLeader] = useState("");
  const [selectLeader, onSelectLeader] = useState("");
  const [selectSpv, onSelectSpv] = useState("");
  const [selectCS, onSelectCS] = useState([]);
  const [spv, setSpv] = useState("");
  const [cs, setCS] = useState("");

  const [msg, setMsg] = useState([]);

  // console.log(selectLeader);
  // console.log(selectSpv);
  // console.log(selectCS);

  const postData = async () => {
    const err = [];
    if (name == "") {
      err.push("Name Can't be empty");
    }
    if (err.length > 0) {
      setMsg(err);
    } else {
      const form = {
        teamName: name,
      };
      console.log(form);
      try {
        const response = await createTeam(form)
          .then(async (response) => {
            let teamId = response.data.data.id;
            const formDetailLeader = {
              teamId: teamId,
              userId: selectLeader,
            };
            const formDetailSpv = {
              teamId: teamId,
              userId: selectSpv,
            };
            try {
              const responseLeader = await createTeamDetail(formDetailLeader)
                .then(async (response) => {
                  console.log(response.data.data);
                })
                .catch((e) => {
                  if (e.response) {
                    console.log(e.response.data.message);
                    err.push(e.response.data.message);
                    setMsg(err);
                  }
                });
              const responseSpv = await createTeamDetail(formDetailSpv)
                .then(async (response) => {
                  console.log(response.data.data);
                })
                .catch((e) => {
                  if (e.response) {
                    console.log(e.response.data.message);
                    err.push(e.response.data.message);
                    setMsg(err);
                  }
                });
            } catch (error) {
              console.log(error);
            }
            try {
              selectCS.map(async (val) => {
                const formDetailCS = {
                  teamId: teamId,
                  userId: val.value,
                };
                console.log(formDetailCS);
                const responseCS = await createTeamDetail(formDetailCS)
                  .then(async (response) => {
                    console.log(response.data.data);

                    setShowInput(false);
                    onFinishCreate();
                  })
                  .catch((e) => {
                    if (e.response) {
                      console.log(e.response.data.message);
                      err.push(e.response.data.message);
                      setMsg(err);
                    }
                  });
              });
            } catch (error) {
              console.log(error);
            }
          })
          .catch((e) => {
            if (e.response) {
              console.log(e.response.data.message);
              err.push(e.response.data.message);
              setMsg(err);
            }
          });
      } catch (error) {
        console.log(error);
      }
    }
  };

  const options = [];
  // console.log(cs);
  cs &&
    cs.map((val) => {
      // console.log(val.id);
      options.push({
        value: val.id,
        label: val.name,
      });
    });

  useEffect(() => {
    getLeader().then((response) => {
      setLeader(response.data.data);
      // console.log(response.data.data);
    });
    getSpv().then((response) => {
      setSpv(response.data.data);
      // console.log(response.data.data);
    });
    getCS().then((response) => {
      setCS(response.data.getCS);
      // console.log(response.data.getCS);
    });
  }, []);

  return (
    <>
      <button
        type="button"
        className="bg-green-400 hover:bg-green-500 text-white rounded-lg px-3 py-2 font-medium flex flex-row gap-3 flex items-center"
        onClick={() => setShowInput(true)}
      >
        <AddIcon />
        <span>Add Team</span>
      </button>
      {showInput ? (
        <>
          <div className="bg-black bg-opacity-75 overflow-y-auto overflow-x-hidden fixed flex justify-center top-0 left-0 right-0 bottom-0 z-50 w-full md:inset-0 h-modal md:h-full">
            {/* <div className="relative w-full max-w-2xl h-full md:h-auto"> */}
            {/* ini yang ditambahin */}
            <div className="relative fixed w-full max-w-2xl h-full md:h-auto my-5">
              {/* ini yang ditambahin */}
              <div className="relative bg-[#F5F8FA] rounded-lg shadow dark:bg-gray-700">
                <div className="flex justify-between items-start p-4 rounded-t border-b dark:border-gray-600">
                  <div className="flex flex-col">
                    <h3 className="text-xl font-semibold dark:text-white">
                      Add Team
                    </h3>
                  </div>
                  <button
                    type="button"
                    className="bg-transparent hover:bg-gray-100 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                    onClick={() => setShowInput(false)}
                  >
                    <svg
                      className="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </button>
                </div>
                <div className="p-6 space-y-6 items-center">
                  {msg != "" ? (
                    <div
                      className="p-3 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800 my-3"
                      role="alert"
                    >
                      <ul class="list-disc list-inside">
                        {msg.map((val) => (
                          <li>{val}</li>
                        ))}
                      </ul>
                    </div>
                  ) : null}
                  <div className="">
                    <label
                      className="block text-gray-700 font-bold mb-2"
                      for="grid-state"
                    >
                      Team Name
                    </label>
                    <input
                      type="text"
                      className="bg-white border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full"
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                  <div className="">
                    <label
                      className="block text-gray-700 font-bold mb-2"
                      for="grid-state"
                    >
                      Supervisor
                    </label>
                    <div className="relative">
                      <select
                        className="block appearance-none w-full bg-white border border-gray-300 text-gray-400 font-medium py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-300"
                        // value={selectedTemplate}
                        onChange={(e) => onSelectSpv(e.target.value)}
                      >
                        <option hidden>Select Supervisor</option>
                        {spv &&
                          spv?.map((val, index) => (
                            <option value={val.id}>{val.name}</option>
                          ))}
                      </select>
                    </div>
                  </div>
                  <div className="">
                    <label
                      className="block text-gray-700 font-bold mb-2"
                      for="grid-state"
                    >
                      Leader
                    </label>
                    <div className="relative">
                      <select
                        className="block appearance-none w-full bg-white border border-gray-300 text-gray-400 font-medium py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-300"
                        onChange={(e) => onSelectLeader(e.target.value)}
                      >
                        <option hidden>Select Leader</option>
                        {leader &&
                          leader?.map((val, index) => (
                            <option value={val.id}>{val.name}</option>
                          ))}
                      </select>
                    </div>
                  </div>
                  <div className="">
                    <label
                      className="block text-gray-700 font-bold mb-2"
                      for="grid-state"
                    >
                      Customer Service
                    </label>
                    <div className="relative">
                      <Select
                        options={options}
                        isMulti
                        name="colors"
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(e) => onSelectCS(e)}
                      />
                    </div>
                  </div>

                  <div className="mt-10 flex flex-row justify-end">
                    <button
                      className="px-3 rounded-xl text-gray-400 hover:text-gray-600 font-semibold"
                      onClick={() => setShowInput(false)}
                    >
                      Cancel
                    </button>
                    <button
                      className="py-2 px-6 rounded-md text-white bg-green-400 hover:bg-green-500 font-semibold ml-2"
                      onClick={postData}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
}

export default AddTeam;
