import React,{ Component, useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import { Link } from "react-router-dom";
import { getOmset, getRevenue, getGender, getCostumerByAge, getAge } from "../../../../Network/Lib/dashboard";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}


function ChartSalesByAge() {
  const [ageA, setAgeA] = useState('');
  const [ageB, setAgeB] = useState('');
  const [ageC, setAgeC] = useState('');
  const [ageD, setAgeD] = useState('');
  const [ageE, setAgeE] = useState('');
  const [ageF, setAgeF] = useState('');
  const [ageG, setAgeG] = useState('');

  // const fetchApi = async () => {
  //   const ageA = await getCostumerByAge();
  //   const ageB = await getCostumerByAge();
  //   const ageC = await getCostumerByAge();
  //   const ageD = await getCostumerByAge();
  //   const ageE = await getCostumerByAge();
  //   const ageF = await getCostumerByAge();

  //   console.log("age: ", ageA.data.data);
   
  //   setAgeA(ageA.data.data.A.totalOrder)
  //   setAgeB(ageB.data.data.B.totalOrder)
  //   setAgeC(ageC.data.data.C.totalOrder)
  //   setAgeD(ageD.data.data.D.totalOrder)
  //   setAgeE(ageE.data.data.E.totalOrder)
  //   setAgeE(ageF.data.data.F.totalOrder)
  // };

  // useEffect(() => {
  //   fetchApi();
  // }, []);


  const fetchApi = async () => {
    const ageA = await getAge();
    const ageB = await getAge();
    const ageC = await getAge();
    const ageD = await getAge();
    const ageE = await getAge();
    const ageG = await getAge();

    console.log("AGE: ", ageA.data.data);
   
    setAgeA(ageA.data.data[0].sales)
    setAgeB(ageB.data.data[1].sales)
    setAgeC(ageC.data.data[2].sales)
    setAgeD(ageD.data.data[3].sales)
    setAgeE(ageE.data.data[4].sales)
    setAgeE(ageF.data.data[5].sales)
    setAgeE(ageG.data.data[6].sales)
  };

  useEffect(() => {
    fetchApi();
  }, []);
 
  
  const Age = {
    labels: ["15-", "16 - 20", "21-25", "26 - 30", "31 - 35", "36 - 40", "40 +"],
    datasets: [
      {
        fill: true,
        label: "Sales By Age",
        data: [ageA, ageB, ageC, ageD, ageE, ageF, ageG],
        borderColor: "#2DCE89",
        backgroundColor: "#2DCE89",
      },
    ],
  };
  return (
    <>
      <div className="bg-white shadow-lg h-full rounded-lg p-2">
        <div className="flex flex-row justify-between px-10 mt-5">
          <h1 className="font-bold text-lg">Sales By Age</h1>
          <Link to="/SalesByAge">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.01001 2C4.08001 2 2.51001 3.57 2.51001 5.5C2.51001 7.08 3.57001 8.403 5.01001 8.837V15.997C5.00901 16.176 5.03701 17.778 6.18401 18.928C6.89201 19.64 7.84001 20 9.00001 20V22L13 19L9.00001 16V18C7.17701 18 7.01601 16.466 7.01001 16V8.837C8.45001 8.403 9.51001 7.08 9.51001 5.5C9.51001 3.57 7.93901 2 6.01001 2ZM6.01001 7C5.18301 7 4.51001 6.327 4.51001 5.5C4.51001 4.673 5.18301 4 6.01001 4C6.83701 4 7.51001 4.673 7.51001 5.5C7.51001 6.327 6.83701 7 6.01001 7ZM19.01 15.163V7.997C19.005 6.391 17.933 4 15 4V2L11 5L15 8V6C16.829 6 17.001 7.539 17.01 8V15.163C15.57 15.597 14.51 16.92 14.51 18.5C14.51 20.43 16.08 22 18.01 22C19.94 22 21.51 20.43 21.51 18.5C21.51 16.92 20.45 15.597 19.01 15.163ZM18.01 20C17.183 20 16.51 19.327 16.51 18.5C16.51 17.673 17.183 17 18.01 17C18.837 17 19.51 17.673 19.51 18.5C19.51 19.327 18.837 20 18.01 20Z"
                fill="#333333"
              />
            </svg>
          </Link>
        </div>
        <div className="">
          <Bar
            options={{
              // indexAxis: "y",
              plugins: {
                title: {
                  display: true,
                },
              },
              elements: {
                bar: {
                  height: "100 px",
                }
              },
              responsive: true,
              scales: {
                x: {
                  stacked: true,
                },
                y: {
                  stacked: true,
                },
              },
            }}
            data={Age}
          />
        </div>
      </div>
    </>
  );
}

export default ChartSalesByAge;
