import React from 'react'
import xmlbuilder from "xmlbuilder";
import { getSlug } from "./Network/Lib/blog";
import { BASE_URL_A } from "./config";

function Sitemap() {

    
  const [slug, setSlug] = React.useState([])
  React.useEffect(() => {
    getSlug().then((res) => {
      console.log('slug', res.data.pagination.data)
      setSlug(res.data.pagination.data)
    })
  }, [])
  let items = [];

  let content_elements = [1, 2, 3, 4];

  slug.map((i, keyXML) => {
    return items.push({
      title: i.title,
      link: `${BASE_URL_A}/blog/${i.slug}`,
      description: i.content,
      category: "itemCategory",
      pubDate: i.updatedAt
    });
  });
  let objMain = [
    {
      channel: {
        title: "Croya Blog",
        link: "https://croya.id/Blog",
        description: "",
        item: [...items]
      }
    }
  ];

  let demo = () => {
    return [objMain, items];
  };

  console.log("Sa", demo()[1]);

  let a = xmlbuilder.create(
    { rss: demo()[0] || "" },
    { separateArrayItems: false, encoding: "utf-8" }
  );

  demo()[1].map((i, k) => {
    const { title, link, description, category, pubDate } = i;
    console.log(title, link, description, category, pubDate, k);
  });
  /*a.ele({
    number: ["one", "two", { three: { "@val": 3 } }]
  });*/

  let b = a
    .att("version", "2.0")
    .att("xmlns:image", "http://www.google.com/schemas/sitemap-image/1.1")
    .end({
      pretty: true
    });

  var builder = require("xmlbuilder");

  var root = builder
    .create("rss", {
      separateArrayItems: false,
      encoding: "utf-8"
    })
    .ele("channel");

  root.ele({
    title: "titleLR",
    description: "descriptionLR",
    link: "linkLR",
    item: [...items]
  });
  /*root.com("f(x) = x^2");
  for (var i = 1; i <= 5; i++) {
    var item = root.ele("data");
    item.att("x", i);
    item.att("y", i * i);
  }*/

  var xml = root.end({ pretty: true });
  // console.log(xml);
  return b;
}

export default Sitemap