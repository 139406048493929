import api from "../apiClient";

export function createLeveling(data) {
  return api.axiosClient.post("/leveling/create", data);
}

export function getLeveling() {
  return api.axiosClient.get("/leveling/get");
}


export function getLevelingById(id) {
  return api.axiosClient.get(`leveling/${id}`);
}
export function getNewCustomer(id) {
  return api.axiosClient.get(`customer/getCsByLevel/${id}`);
}

export function updateLeveling(id, data) {
  return api.axiosClient.put(`/leveling/update/${id}`, data);
}

export function deleteLeveling(id) {
  return api.axiosClient.delete(`/leveling/delete/${id}`);
}
