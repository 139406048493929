import React, { Component, useState, useEffect } from "react";
import Sidebar from "../../Components/Sidebar/SidebarProduct";
import "aos/dist/aos.css";
import AddProduct from "../../Components/Partials/Modal/AddProduct";
// import people from "../../Assets/img/product.png";
import { PencilIcon, TrashIcon } from "@heroicons/react/solid";
import Delete from "../../Assets/img/Delete-data.png";
import { getProducts } from "../../Network/Lib/boProduct";
import {
  getAllProduct,
  deleteProduct,
  updateProduct,
} from "../../Network/Lib/product";
import { useNavigate } from "react-router-dom";
import { logout } from "../../Network/Lib/auth";
import CurrencyFormat from "react-currency-format";
import defaultProduct from "../../Assets/img/default-product.png";

function Product() {
  const [navSideBar, setNavSideBar] = useState(false);
  const [refreshApi, setRefreshApi] = useState(false);

  const [showDelete, setShowDelete] = useState(false);
  const [showEditProduct, setShowEditProduct] = useState(false);
  const [showImage, setShowImage] = useState(false);
  const [imageUrl, setImageUrl] = useState("");

  const [editProductId, setEditProductId] = useState("");
  const [editProductName, setEditProductName] = useState("");
  const [editProductCode, setEditProductCode] = useState("");
  const [editProductPrice, setEditProductPrice] = useState(0);
  const [editProductUnit, setEditProductUnit] = useState(0);
  const [editProductImage, setEditProductImage] = useState();

  const [bmxProducts, setBmxProducts] = useState([]);
  const [crmProducts, setCrmProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    // getProducts().then((response) => {
    //   setBmxProducts(response.data);
    // });
    getAllProduct()
      .then((response) => {
        setCrmProducts(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
    setRefreshApi(false);
  }, [refreshApi]);

  const onConfirmDelete = async () => {
    await deleteProduct(crmProducts[selectedProduct].id).then((response) => {
      setRefreshApi(true);
    });
  };

  let base64code = "";

  const onLoad = (fileString) => {
    base64code = fileString;
  };

  const getBase64 = (file) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      onLoad(reader.result);
      // console.log(reader.result);
    };
  };

  const onEditProduct = (index) => {
    const product = crmProducts[index];
    setEditProductId(product.id);
    setEditProductName(product.name);
    setEditProductCode(product.code);
    setEditProductPrice(product.price);
    setEditProductUnit(product.unit);
  };

  const onUpdateProduct = async () => {
    const form = {
      name: editProductName,
      code: editProductCode,
      price: editProductPrice,
      image: base64code,
    };
    console.log(form);
    await updateProduct(editProductId, form).then((response) => {
      // setEditProductId("");
      // setEditProductName("");
      // setEditProductCode("");
      // setEditProductPrice(0);
      // setEditProductImage();
      setRefreshApi(true);
      setShowEditProduct(false);
    });
  };

  const onFinishedCreate = async () => {
    setRefreshApi(true);
  };

  const onShowProduct = (id) => {
    const params = { id: id };
    navigate("/detail-product", {
      state: params,
    });
  };

  const signOut = () => {
    logout();
    navigate("/login");
  };

  return (
    <>
      <div className="bg-gradient-to-r from-[#54DAA1] to-[#2DCE89]">
        <div className="relative">
          <aside
            className={
              navSideBar === true
                ? "w-24 h-screen p-5 fixed top-0 left-0 right-0 z-10"
                : "w-3/12 h-screen p-5 fixed top-0 left-0 right-0 z-10"
            }
            aria-label="Sidebar"
          >
            <button
              className="absolute top-72 right-1 w-10 h-10 bg-gradient-to-r from-[#54DAA1] to-[#2DCE89] rounded-full shadow-lg flex justify-center items-center"
              onClick={() => setNavSideBar(!navSideBar)}
            >
              {navSideBar ? (
                <svg
                  width="9"
                  height="16"
                  viewBox="0 0 9 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 14.3333L7.66667 7.66668L1 1.00001"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              ) : (
                <svg
                  width="10"
                  height="16"
                  viewBox="0 0 10 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.33325 14.6667L1.66659 7.99999L8.33325 1.33332"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              )}
            </button>
            <Sidebar navSideBar={navSideBar} />
          </aside>
        </div>
        <div
          className={
            navSideBar === true
              ? "ml-24 h-72 w-11/12 p-5 space-y-5"
              : "ml-auto h-72 w-9/12 p-5 space-y-5"
          }
        >
          {/* Header */}
          <div className="flex flex-row justify-between items-center">
            <div className="text-white space-y-2">
              <p className="text-sm">Pages / Product</p>
              <p className="font-semibold">Product</p>
            </div>
            <div className="flex flex-row items-center gap-5">
              <a href="#" className="flex items-center gap-2">
                <svg
                  width="21"
                  height="20"
                  viewBox="0 0 21 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.4938 2.52235C12.7336 1.70165 11.6719 1.24969 10.5 1.24969C9.32191 1.24969 8.25668 1.69891 7.50004 2.51454C6.73519 3.33915 6.36254 4.45985 6.45004 5.67001C6.62348 8.05751 8.44027 9.99969 10.5 9.99969C12.5598 9.99969 14.3735 8.0579 14.5496 5.67079C14.6383 4.47157 14.2633 3.35321 13.4938 2.52235Z"
                    fill="white"
                  />
                  <path
                    d="M17.3752 18.7488H3.62522C3.44525 18.7511 3.26701 18.7133 3.10349 18.6381C2.93997 18.5629 2.79526 18.4522 2.67991 18.314C2.426 18.0105 2.32366 17.596 2.39944 17.1769C2.72913 15.348 3.75804 13.8117 5.37522 12.7332C6.81194 11.7757 8.63186 11.2488 10.5002 11.2488C12.3686 11.2488 14.1885 11.7761 15.6252 12.7332C17.2424 13.8113 18.2713 15.3476 18.601 17.1765C18.6768 17.5957 18.5744 18.0101 18.3205 18.3136C18.2052 18.4519 18.0605 18.5626 17.897 18.6379C17.7335 18.7132 17.5552 18.7511 17.3752 18.7488Z"
                    fill="white"
                  />
                </svg>
                <p className="text-white">{localStorage.getItem("nameUser")}</p>
              </a>
              <button onClick={signOut} className="bg-white p-3 rounded-lg">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.5625 2.8125H2.1875V17.8125H6.5625M13.4375 5.9375L17.8125 10.3125L13.4375 14.6875M7.1875 10.3125H17.8125"
                    stroke="#EF4444"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            </div>
          </div>

          {/* start: Main */}
          <div className="pb-5">
            <div className="bg-white shadow-lg rounded-lg p-6">
              <div className="flex flex-col md:flex-row justify-between items-start md:items-center gap-3">
                <h1 className="text-lg font-semibold">CRM Products</h1>
                {localStorage.getItem("role") == 'Supervisor' || localStorage.getItem("role") == 'CustomerService' ?
                  null :
                  <AddProduct onFinishCreate={onFinishedCreate} />
                }
              </div>
              <div className="mt-5">
                <div className="overflow-x-auto">
                  <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-400 uppercase dark:text-gray-400">
                      <tr>
                        <th scope="col" className="py-3 px-6">
                          image
                        </th>
                        <th scope="col" className="py-3 px-6">
                          product name
                        </th>
                        <th scope="col" className="py-3 px-6">
                          code
                        </th>
                        <th scope="col" className="py-3 px-6">
                          price
                        </th>
                        {localStorage.getItem("role") == 'Supervisor' || localStorage.getItem("role") == 'CustomerService' ?
                          null :
                          <th scope="col" className="py-3 px-6">
                            action
                          </th>
                        }
                      </tr>
                    </thead>
                    <tbody>
                      {crmProducts != null ? (
                        crmProducts.map((product, index) => {
                          return (
                            <>
                              <tr className="bg-white" key={index}>
                                <th scope="row" className="py-4 px-6">
                                  <button
                                    onClick={() => {
                                      setImageUrl(
                                        product.image != null
                                          ? product.image
                                          : defaultProduct
                                      );
                                      setShowImage(true);
                                    }}
                                  >
                                    <img
                                      src={
                                        product.image != null
                                          ? product.image
                                          : defaultProduct
                                      }
                                      className="w-12 h-12"
                                      alt="product"
                                    />
                                  </button>
                                </th>
                                <td className="py-4 px-6">
                                  <button
                                    onClick={() => onShowProduct(product.id)}
                                  >
                                    {product.name}
                                  </button>
                                </td>
                                <td className="py-4 px-6 uppercase">
                                  {product.code}
                                </td>
                                <td className="py-4 px-6">
                                  Rp {parseInt(product.price).toLocaleString()}
                                </td>
                                {localStorage.getItem("role") == 'Supervisor' || localStorage.getItem("role") == 'CustomerService' ?
                                  null :
                                  <td className="py-4 px-6">
                                    <div className="flex flex-row gap-3">
                                      <button
                                        className="bg-green-500 hover:bg-green-600 p-2 rounded-lg"
                                        onClick={() => {
                                          onEditProduct(index);
                                          setShowEditProduct(true);
                                        }}
                                      >
                                        <PencilIcon
                                          className="text-white 5 w-5"
                                          aria-hidden="true"
                                        />
                                      </button>
                                      <a
                                        href="#"
                                        className="bg-red-500 hover:bg-red-600 p-2 rounded-lg"
                                        onClick={() => {
                                          setSelectedProduct(index);
                                          setShowDelete(true);
                                        }}
                                      >
                                        <TrashIcon
                                          className="text-white 5 w-5"
                                          aria-hidden="true"
                                        />
                                      </a>
                                    </div>
                                  </td>
                                }
                              </tr>
                            </>
                          );
                        })
                      ) : (
                        <tr className="bg-white">
                          <td
                            colSpan={5}
                            className="bg-gray-50 py-10 text-center"
                          >
                            Data Not Found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          {/* end: Main */}

          {/* start: Main */}
          {/* <div className="pb-5">
            <div className="bg-white shadow-lg rounded-lg p-6">
              <div className="flex flex-col md:flex-row justify-between items-start md:items-center gap-3">
                <h1 className="text-lg font-semibold">Banyumax Products</h1>
                <AddProduct />
              </div>
              <div className="mt-5">
                <div className="overflow-x-auto">
                  <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-400 uppercase dark:text-gray-400">
                      <tr>
                        <th scope="col" className="py-3 px-6">
                          image
                        </th>
                        <th scope="col" className="py-3 px-6">
                          product name
                        </th>
                        <th scope="col" className="py-3 px-6">
                          code
                        </th>
                        <th scope="col" className="py-3 px-6">
                          price
                        </th>
                        <th scope="col" className="py-3 px-6">
                          action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {bmxProducts.map((product, index) => {
                        if (
                          product.name != "Daymilk" &&
                          product.name != "Nusava"
                        ) {
                          return (
                            <>
                              <tr className="bg-white" key={index}>
                                <th scope="row" className="py-4 px-6">
                                  <button
                                    onClick={() => {
                                      setImageUrl(
                                        `https://pwkbackoffice.com/${product.image}`
                                      );
                                      setShowImage(true);
                                    }}
                                  >
                                    <img
                                      src={`https://pwkbackoffice.com/${product.image}`}
                                      className="w-12 h-12"
                                      alt="product"
                                    />
                                  </button>
                                </th>
                                <td className="py-4 px-6">
                                  <a href="/detail-product">{product.name}</a>
                                </td>
                                <td className="py-4 px-6 uppercase">
                                  {product.sku}
                                </td>
                                <td className="py-4 px-6">
                                  Rp {parseInt(product.price).toLocaleString()}
                                </td>
                                <td className="py-4 px-6">
                                </td>
                              </tr>
                            </>
                          );
                        }
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div> */}
          {/* end: Main */}
        </div>
        {/* Modal */}
        {showEditProduct ? (
          <>
            <div className="bg-black bg-opacity-75 overflow-y-auto overflow-x-hidden fixed flex justify-center top-0 left-0 right-0 bottom-0 z-50 w-full md:inset-0 h-modal md:h-full">
              {/* <div className="relative w-full max-w-2xl h-full md:h-auto"> */}
              {/* ini yang ditambahin */}
              <div className="relative w-full max-w-md h-full md:h-auto mt-10">
                {/* ini yang ditambahin */}
                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                  <div className="bg-green-400 flex justify-between items-start p-4 rounded-t border-b dark:border-gray-600">
                    <h3 className="text-xl font-semibold text-white dark:text-white">
                      Edit Product
                    </h3>
                    <button
                      type="button"
                      className="text-white bg-transparent hover:bg-green-600 hover:text-white rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                      onClick={() => setShowEditProduct(false)}
                    >
                      <svg
                        className="w-5 h-5"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                    </button>
                  </div>
                  <div className="p-6 space-y-6 items-center">
                    <div className="px-10">
                      <label className="block mb-2 text-sm font-medium text-gray-900">
                        Product Name
                      </label>
                      <input
                        type="text"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full"
                        placeholder="Product Name"
                        value={editProductName}
                        onChange={(e) => setEditProductName(e.target.value)}
                      />
                    </div>
                    <div className="px-10">
                      <label className="block mb-2 text-sm font-medium text-gray-900">
                        Code
                      </label>
                      <input
                        type="text"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full"
                        placeholder="Ex. ETA"
                        value={editProductCode}
                        onChange={(e) => setEditProductCode(e.target.value)}
                      />
                    </div>
                    <div className="px-10">
                      <label className="block mb-2 text-sm font-medium text-gray-900">
                        Price
                      </label>
                      {/* <input
                        type="text"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full"
                        placeholder="IDR"
                        value={editProductPrice}
                        onChange={(e) => setEditProductPrice(e.target.value)}
                      /> */}
                      <CurrencyFormat
                        thousandSeparator={true}
                        prefix={"Rp"}
                        // placeholder="Rp"
                        value={editProductPrice}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full"
                        onValueChange={(values) => {
                          const { formattedValue, value } = values;
                          setEditProductPrice(value);
                        }}
                      />
                    </div>
                    <div className="px-10">
                      <label className="block mb-2 text-sm font-medium text-gray-900">
                        Unit
                      </label>
                      <select
                        placeholder="Select Gender ( Male; Female )"
                        className="bg-white rounded border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      // value={setGender}
                      // onChange={(e) => setProductUnit(e.target.value)}
                      >
                        <option value={editProductUnit}>
                          {editProductUnit != null
                            ? editProductUnit
                            : "Select Unit"}
                        </option>
                        <option value="box">Box</option>
                        <option value="bottle">Bottle</option>
                        <option value="pack">Pack</option>
                      </select>
                    </div>
                    <div className="px-10">
                      <label className="block mb-2 text-sm font-medium text-gray-900">
                        Image
                      </label>
                      <input
                        type="file"
                        className="bg-gray-100 border border-white text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full"
                        onChange={(e) => {
                          // setEditProductImage(e.target.files[0]);
                          getBase64(e.target.files[0]);
                        }}
                      />
                    </div>
                    <div className="mt-10 flex flex-row justify-end gap-3">
                      <button
                        className="py-2 px-6 text-sm rounded-xl bg-gray-100 hover:bg-gray-200 text-gray-400 hover:text-gray-600"
                        onClick={() => setShowEditProduct(false)}
                      >
                        Cancel
                      </button>
                      <button
                        className="py-2 px-6 text-sm rounded-xl bg-green-500 hover:bg-green-600 text-white"
                        onClick={() => onUpdateProduct()}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : null}
        {showDelete ? (
          <>
            <div className="bg-black bg-opacity-75 overflow-y-auto overflow-x-hidden fixed flex justify-center top-0 left-0 right-0 bottom-0 z-50 w-full md:inset-0 md:h-full">
              {/* <div className="relative w-full max-w-2xl h-full md:h-auto"> */}
              {/* ini yang ditambahin */}
              <div className="fixed w-full max-w-xl md:h-auto mt-40">
                {/* ini yang ditambahin */}
                <div className="relative bg-[#F5F8FA] rounded-lg shadow dark:bg-gray-700">
                  <div className="p-6 space-y-6 items-center">
                    <div className="flex flex-row justify-between items-start gap-5">
                      <div className="flex flex-row gap-5">
                        <img src={Delete} className="w-100 object-cover" />
                        <div className="flex flex-col">
                          <h1 className="font-bold">Delete This Product?</h1>
                          <p className="font-semibold text-gray-400">
                            Data will be permanently deleted
                          </p>
                          <div className="mt-10 flex flex-row justify-start gap-3">
                            <button
                              onClick={() => {
                                onConfirmDelete();
                                setShowDelete(false);
                              }}
                              className="text-sm py-2 px-5 rounded-xl text-white bg-green-400 hover:bg-green-500 font-semibold"
                            >
                              Yes
                            </button>
                            <button
                              className="text-sm px-3 rounded-xl text-gray-400 hover:text-gray-600 bg-gray-100 hover:bg-gray-200 font-semibold"
                              onClick={() => setShowDelete(false)}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                      <button
                        type="button"
                        className="bg-transparent hover:bg-gray-100 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                        onClick={() => setShowDelete(false)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="#A1A5B7"
                          className="bi bi-x-circle"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                          <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : null}
        {showImage ? (
          <>
            <div
              onClick={() => setShowImage(false)}
              className="bg-black bg-opacity-75 overflow-y-auto overflow-x-hidden fixed flex justify-center top-0 left-0 right-0 bottom-0 z-50 w-full md:inset-0 h-full md:h-full"
            >
              {/* <div className="relative w-full max-w-2xl h-full md:h-auto"> */}
              {/* ini yang ditambahin */}
              <div className="relative w-full max-w-md h-full md:h-auto mt-10">
                {/* ini yang ditambahin */}
                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                  <div className="p-6 space-y-6 flex justify-center items-center">
                    <img
                      src={imageUrl}
                      className="md:w-80 h-auto max-w-screen w-auto"
                      alt="product"
                    />
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : null}
      </div>
    </>
  );
}

export default Product;
