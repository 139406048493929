import React, { useState } from "react";
import TableFooter from "../footer";
import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import {
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@heroicons/react/solid";
import useTable from "../useTable";
import Delete from "../../../../Assets/img/Delete-data.png";
// import styles from "./Table.module.css";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Table = ({ data, rowsPerPage }) => {
  const [page, setPage] = useState(1);
  const { slice, range } = useTable(data, page, rowsPerPage);

  const [showEditPromo, setShowEditPromo] = useState(false);
  const [showPromo, setShowPromo] = useState(false);
  const [showDelete, setShowDelete] = useState(false);

  return (
    <>
      <div className="overflow-x-auto md:h-screen">
        <table className="w-full text-sm text-left text-gray-500">
          <thead className="text-sm text-gray-400 uppercase">
            <tr className="bg-white  border-[#F5F8FA] border-b-2">
              <th className="px-6 py-3 w-4">No</th>
              <th className="px-20 py-3 w-10">Team</th>
              <th className="px-20 py-3 w-10">Promo Name</th>
              <th className="px-20 py-3 w-10">Terms</th>
              <th className="px-20 py-3 w-10">Promo Nominal</th>
              <th className="px-20 py-3 w-10">Product</th>
              <th className="px-20 py-3 w-10">Min. Purchase</th>
              <th className="px-20 py-3 w-10">Tag</th>
              <th className="px-20 py-3 w-10">Timeline</th>
              <th className="px-20 py-3 w-10">PROMO UNIT PRICE</th>
              <th className="px-20 py-3 w-10">Action</th>
            </tr>
          </thead>
          <tbody>
            {slice.map((el) => (
              <tr
                className="bg-white  border-[#F5F8FA] border-b-2"
                key={el.id}
              >
                <td className="px-6 py-3">1</td>
                <td className="px-10 py-3">{el.team}</td>
                <td className="px-10 py-3">{el.promoName}</td>
                <td className="px-10 py-3">1. Berlaku untuk semua customer
                    2. Produk Xtamina diskon harga 50% senilai Rp 137.500</td>
                <td className="px-10 py-3">Rp 10.000</td>
                <td className="px-10 py-3">
                  <div className="px-10 py-3">Weight Herba</div>
                </td>
                <td className="px-10 py-3">Rp 120.000</td>
                <td className="px-10 py-3">PROMO CROSS (SKU PRODUK) (HARGA SATUAN)</td>
                <td className="px-10 py-3"> 1 - 30 Juni 2022</td>
                <td className="px-10 py-3"> Rp 45.000</td>
              
               
                <td className="px-10 py-3">
                  <Menu as="div" className="relative inline-block text-left">
                    <div>
                      <Menu.Button className="inline-flex justify-center w-full rounded-md border border-[#F5F8FA] shadow-sm px-4 py-2 bg-[#F5F8FA] text-sm font-medium text-gray-700 hover:bg-[#F5F8FA] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-[#F5F8FA] focus:ring-[#F5F8FA]">
                        Action
                        <ChevronDownIcon
                          className="-mr-1 ml-2 h-5 w-5"
                          aria-hidden="true"
                        />
                      </Menu.Button>
                    </div>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="origin-top-right z-10 absolute right-0 mt-2 w-20 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="py-1">
                          <Menu.Item>
                            {({ active }) => (
                              <a
                                href="#"
                                onClick={() => setShowPromo(true)}
                                className={classNames(
                                  active
                                    ? "bg-gray-100 text-gray-900"
                                    : "text-gray-700",
                                  "block px-4 py-2 text-sm"
                                )}
                              >
                                View
                              </a>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                              <a
                                href="#"
                                onClick={() => setShowEditPromo(true)}
                                className={classNames(
                                  active
                                    ? "bg-gray-100 text-gray-900"
                                    : "text-gray-700",
                                  "block px-4 py-2 text-sm"
                                )}
                              >
                                Edit
                              </a>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                              <a
                                href="#"
                                onClick={() => setShowDelete(true)}
                                className={classNames(
                                  active
                                    ? "bg-gray-100 text-gray-900"
                                    : "text-gray-700",
                                  "block px-4 py-2 text-sm"
                                )}
                              >
                                Delete
                              </a>
                            )}
                          </Menu.Item>
                        </div>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="flex flex-row justify-center">
        <TableFooter
          range={range}
          slice={slice}
          setPage={setPage}
          page={page}
        />
      </div>

      {showEditPromo ? (
        <>
          <div className="bg-black bg-opacity-75 overflow-y-auto overflow-x-hidden fixed flex justify-center top-0 left-0 right-0 bottom-0 z-50 w-full md:inset-0 h-modal md:h-full">
            {/* <div className="relative w-full max-w-2xl h-full md:h-auto"> */}
            {/* ini yang ditambahin */}
            <div className="fixed inset-y-0 md:right-0 z-50 w-full max-w-2xl h-full md:h-auto overflow-y-auto md:w-1/3">
              {/* ini yang ditambahin */}
              <div className="relative bg-white min-h-screen rounded-lg shadow dark:bg-gray-700">
                <div className="bg-green-400 flex justify-between items-start p-4 rounded-t border-b dark:border-gray-600">
                  <h3 className="text-xl font-semibold text-white dark:text-white">
                    Edit Promotion
                  </h3>
                  <button
                    type="button"
                    className="text-white bg-transparent hover:bg-green-600 hover:text-white rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                    onClick={() => setShowEditPromo(false)}
                  >
                    <svg
                      className="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </button>
                </div>
                <div className="p-6 space-y-6 items-center">
                  <div className="">
                    <label
                      className="block tracking-wide text-gray-700 text-sm font-bold mb-2"
                      for="grid-state"
                    >
                      Team
                    </label>
                    <div className="relative">
                      <select
                        className="block appearance-none w-full bg-gray-100 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="grid-state"
                      >
                        <option hidden>Select Team</option>
                        <option>Team 1</option>
                        <option>Team 2</option>
                      </select>
                      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"></div>
                    </div>
                  </div>
                  <div className="">
                    <label
                      className="block tracking-wide text-gray-700 text-sm font-bold mb-2"
                      for="grid-state"
                    >
                      Promo Name
                    </label>
                    <input
                      type="text"
                      className="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full"
                      placeholder=""
                    />
                  </div>
                  <div className="">
                    <label
                      className="block tracking-wide text-gray-700 text-sm font-bold mb-2"
                      for="grid-state"
                    >
                      Promo Terms
                    </label>
                    <textarea
                      className="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full"
                      placeholder=""
                    ></textarea>
                  </div>
                  <div className="">
                    <label
                      className="block tracking-wide text-gray-700 text-sm font-bold mb-2"
                      for="grid-state"
                    >
                      Nominal Promo
                    </label>
                    <input
                      type="text"
                      className="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full"
                      placeholder=""
                    />
                  </div>
                  <div className="">
                    <label
                      className="block tracking-wide text-gray-700 text-sm font-bold mb-2"
                      for="grid-state"
                    >
                      Product
                    </label>
                    <div className="relative">
                      <select
                        className="block appearance-none w-full bg-gray-100 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="grid-state"
                      >
                        <option hidden>Select Product</option>
                        <option>Product 1</option>
                        <option>Product 2</option>
                      </select>
                      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"></div>
                    </div>
                  </div>
                  <div className="">
                    <label
                      className="block tracking-wide text-gray-700 text-sm font-bold mb-2"
                      for="grid-state"
                    >
                      Min. Purchase
                    </label>
                    <input
                      type="text"
                      className="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full"
                      placeholder=""
                    />
                  </div>
                  <div className="">
                    <label
                      className="block tracking-wide text-gray-700 text-sm font-bold mb-2"
                      for="grid-state"
                    >
                      TAG
                    </label>
                    <input
                      type="text"
                      className="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full"
                      placeholder=""
                    />
                  </div>
                  <div className="">
                    <label
                      className="block tracking-wide text-gray-700 text-sm font-bold mb-2"
                      for="grid-state"
                    >
                      Timeline
                    </label>
                    <input
                      type="text"
                      className="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full"
                      placeholder=""
                    />
                  </div>
                  <div className="">
                    <label
                      className="block tracking-wide text-gray-700 text-sm font-bold mb-2"
                      for="grid-state"
                    >
                      Promo Unit Price
                    </label>
                    <input
                      type="text"
                      className="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full"
                      placeholder=""
                    />
                  </div>
                  <div className="mt-10 flex flex-row justify-end">
                    <button
                      className="px-3 rounded-xl text-gray-400 hover:text-gray-600 font-semibold"
                      onClick={() => setShowEditPromo(false)}
                    >
                      Cancel
                    </button>
                    <button className="py-2 px-3 rounded-xl text-green-400 hover:text-green-600 font-semibold ml-2">
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
      {showPromo ? (
        <>
          <div className="bg-black bg-opacity-75 overflow-y-auto overflow-x-hidden fixed flex justify-center top-0 left-0 right-0 bottom-0 z-50 w-full md:inset-0 h-modal md:h-full">
            {/* <div className="relative w-full max-w-2xl h-full md:h-auto"> */}
            {/* ini yang ditambahin */}
            <div className="fixed inset-y-0 md:right-0 z-50 w-full max-w-2xl h-full md:h-auto overflow-y-auto md:w-1/3">
              {/* ini yang ditambahin */}
              <div className="relative bg-white min-h-screen rounded-lg shadow dark:bg-gray-700">
                <div className="bg-green-400 flex justify-between items-start p-4 rounded-t border-b dark:border-gray-600">
                  <h3 className="text-xl font-semibold text-white dark:text-white">
                    View Promotion
                  </h3>
                  <button
                    type="button"
                    className="text-white bg-transparent hover:bg-green-600 hover:text-white rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                    onClick={() => setShowPromo(false)}
                  >
                    <svg
                      className="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </button>
                </div>
                <div className="p-6 space-y-6 items-center">
                  <div className="">
                    <label
                      className="block tracking-wide text-gray-500 text-sm font-bold mb-2"
                      for="grid-state"
                    >
                      Team
                    </label>
                    <p className="text-gray-700 text-sm font-bold">
                      CRM Market Place & Acquisition 
                    </p>
                  </div>
                  <div className="">
                    <label
                      className="block tracking-wide text-gray-500 text-sm font-bold mb-2"
                      for="grid-state"
                    >
                      Promo Name
                    </label>
                    <p className="text-gray-700 text-sm font-bold">
                      Promo Admin COD
                    </p>
                  </div>
                  <div className="">
                    <label
                      className="block tracking-wide text-gray-500 text-sm font-bold mb-2"
                      for="grid-state"
                    >
                      Promo Terms
                    </label>
                    <p className="text-gray-700 text-sm font-bold">
                      1. Berlaku untuk repeat customer
                      <br />
                      2. Minimal pembelanjaan Rp 120.000 <br />
                      3. Potongan biaya admin COD up to 5k 4. Potongan biaya
                      admin COD khusus produk generos = 10k
                    </p>
                  </div>
                  <div className="">
                    <label
                      className="block tracking-wide text-gray-500 text-sm font-bold mb-2"
                      for="grid-state"
                    >
                      Nominal Promo
                    </label>
                    <p className="text-gray-800 text-sm font-bold">
                      Up to Rp 5.000
                    </p>
                  </div>
                  <div className="">
                    <label
                      className="block tracking-wide text-gray-500 text-sm font-bold mb-2"
                      for="grid-state"
                    >
                      Product
                    </label>
                    <p className="text-gray-800 text-sm font-bold">
                      All Products
                    </p>
                  </div>
                  <div className="">
                    <label
                      className="block tracking-wide text-gray-500 text-sm font-bold mb-2"
                      for="grid-state"
                    >
                      Min. Purchase
                    </label>
                    <p className="text-gray-800 text-sm font-bold">
                      Rp 120.000
                    </p>
                  </div>
                  <div className="">
                    <label
                      className="block tracking-wide text-gray-500 text-sm font-bold mb-2"
                      for="grid-state"
                    >
                      TAG
                    </label>
                    <p className="text-gray-800 text-sm font-bold">-</p>
                  </div>
                  <div className="">
                    <label
                      className="block tracking-wide text-gray-500 text-sm font-bold mb-2"
                      for="grid-state"
                    >
                      Timeline
                    </label>
                    <p className="text-gray-800 text-sm font-bold">Everyday</p>
                  </div>
                  <div className="">
                    <label
                      className="block tracking-wide text-gray-500 text-sm font-bold mb-2"
                      for="grid-state"
                    >
                      Promo Unit Price
                    </label>
                    <p className="text-gray-800 text-sm font-bold">NONE</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
      {showDelete ? (
        <>
          <div className="bg-black bg-opacity-75 overflow-y-auto overflow-x-hidden fixed flex justify-center top-0 left-0 right-0 bottom-0 z-50 w-full md:inset-0 md:h-full">
            {/* <div className="relative w-full max-w-2xl h-full md:h-auto"> */}
            {/* ini yang ditambahin */}
            <div className="fixed w-full max-w-xl md:h-auto mt-40">
              {/* ini yang ditambahin */}
              <div className="relative bg-[#F5F8FA] rounded-lg shadow dark:bg-gray-700">
                <div className="p-6 space-y-6 items-center">
                  <div className="flex flex-row justify-between items-start gap-5">
                    <div className="flex flex-row gap-5">
                      <img src={Delete} className="w-100 object-cover" />
                      <div className="flex flex-col">
                        <h1 className="font-bold">Delete this Promo ?</h1>
                        <p className="font-semibold text-gray-400">
                          Data will be deleted permanently
                        </p>
                        <div className="mt-10 flex flex-row justify-start gap-3">
                          <button className="text-sm py-2 px-5 rounded-xl text-white bg-green-400 hover:bg-green-500 font-semibold">
                            Yes
                          </button>
                          <button
                            className="text-sm px-3 rounded-xl text-gray-400 hover:text-gray-600 bg-gray-100 hover:bg-gray-200 font-semibold"
                            onClick={() => setShowDelete(false)}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                    <button
                      type="button"
                      className="bg-transparent hover:bg-gray-100 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                      onClick={() => setShowDelete(false)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="#A1A5B7"
                        className="bi bi-x-circle"
                        viewBox="0 0 16 16"
                      >
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

export default Table;
