import React, { useEffect } from "react";

const TableFooter = ({ pagination, setPage, page }) => {
  return (
    <div className="inline-flex mt-2 xs:mt-0">
      <button
        key="prevButton"
        className={`${"py-2 px-4 mr-2 text-sm font-medium text-black bg-white rounded-xl hover:bg-gray-900 hover:text-white"} ${"bg-white text-black"
        }`}
        onClick={() => setPage(page-1)}
        disabled={page <= 1}
      >
        {"<"}
      </button>
      {pagination.page_links.map((el, index) => (
        <button
          key={index}
          className={`${"py-2 px-4 mr-2 text-sm font-medium text-black bg-white rounded-xl hover:bg-gray-900 hover:text-white"} ${
            el.active ? "bg-gray-900 text-white" : "bg-white text-black"
          }`}
          onClick={() => setPage(parseInt(el.label))}
        >
          {el.label}
        </button>
      ))}
      <button
        key="nextButton"
        className={`${"py-2 px-4 mr-2 text-sm font-medium text-black bg-white rounded-xl hover:bg-gray-900 hover:text-white"} ${"bg-white text-black"
        }`}
        disabled={page === parseInt(pagination.last_page)}
        onClick={() => setPage(page+1)}
      >
        {" >"}
      </button>
    </div>
  );
};

export default TableFooter;
