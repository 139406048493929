import React, { Component, useState, useEffect} from "react";
import { Line } from "react-chartjs-2";
import { getOmset, getRevenue, detailRevenue } from "../../../../Network/Lib/dashboard";
import { Link, useNavigate, useParams } from "react-router-dom";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function DetailChartRevenue({onFiltereProduct, setSelectedFilter, omsetData}) {

  const [omset, setOmset] = useState([]);
  const [dailyData, setDailyData] = useState([]);
  const [length, setLenght] = useState('');

  const [widget, setWidget] = useState('');

  // const [onFiltereProduct, setOnFilterProduct] = useState();

  
  // const onSubmitFilter = () => {
  //   // let params = {};
 
  //   onFiltereProduct();
  //   // setShowInput(false);
  // };

  // const [show, setShow] = useState(false);
  // const [show2, setShow2] = useState(false);
  // const [show3, setShow3] = useState(false);
  // const producId = () =>{

  // }
  const { filter } = useParams();
  const { product } = useParams();

  const fetchApi = async () => {
    const dailyData = await detailRevenue(filter, product);
    console.log("month: ", dailyData.data);
    // setDailyData(dailyData.data);
    // setLenght(dailyData.data);
    
  };

  useEffect(() => {
    fetchApi();
    // console.log('dataa', onFiltereProduct)
    // detailRevenue(product).then((response) => {
    //   // setData(response.data.);
    //   console.log(response.data);
    // });
  }, [omsetData]);

  const omzetTeam = {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: [
      {
        fill: true,
        label: "revenue",
        data: dailyData,
        borderColor: "#2DCE89",
        backgroundColor: "#E5FFF4",
      },
    ],
  };

  const labels = () => {
    let arr = [];
    let currentDecade = Math.floor(parseInt(new Date().getFullYear())/10)*10
    for(const x in [...Array(omsetData.length).keys()]) {
      if(omsetData.length == 10) {
        arr.push(`${currentDecade + (parseInt(x)+1)}`)
      }else {
        arr.push(`${parseInt(x)+1}`);
      }
    }
    return arr;
  }


  const dailyOmset = {
    labels: labels(),
    datasets: [
      {
        fill: true,
        label: "Revenue",
        data: omsetData,
        borderColor: "#2DCE89",
        backgroundColor: "#E5FFF4",
      },
    ],
  };

  const omzetTeamTwo = {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: [
      {
        fill: true,
        label: "Revenue",
        data: omsetData,
        borderColor: "#2DCE89",
        backgroundColor: "#E5FFF4",
      },
    ],
  };

  const omzetTeamTree = {
    labels: labels(),
    datasets: [
      {
        fill: true,
        label: "Revenue",
        data: omsetData,
        borderColor: "#2DCE89",
        backgroundColor: "#E5FFF4",
      },
    ],
  };

  const [showhide, setShowhide]=useState('1');
  
   const handleshowhide=(event)=>{
     const getuser = event.target.value;    
     setShowhide(getuser);

   }


  return (
    <>
      <div className="flex flex-row justify-between">
        {/* {console.log('dataa', data)} */}
        <h1 className="font-bold text-lg">Revenue</h1>
        <select
            onChange={(e)=>{
              handleshowhide(e);
              if(e.target.value == 1) {
                setSelectedFilter("daily")
              }else if(e.target.value == 2) {
                setSelectedFilter("monthly")
              }else if(e.target.value == 3) {
                setSelectedFilter("yearly")
              }
            }}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-24 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        >
          
          <option value="1">Daily</option>
          <option value="2">Monthly</option>
          <option value="3">Yearly</option>
        </select>
      </div>

      {
        showhide==='1' && (
        <div className="px-10 py-5">
          <Line
            options={{
              plugins: {
                title: {
                  display: true,
                },
              },
              responsive: true,
              scales: {
                x: {
                  stacked: true,
                },
                y: {
                  stacked: true,
                },
              },
            }}
            data={dailyOmset}
          />
        </div>
        )}           

        {
        showhide==='2' && (
        <div className="px-10 py-5">
          <Line
            options={{
              plugins: {
                title: {
                  display: true,
                },
              },
              responsive: true,
              scales: {
                x: {
                  stacked: true,
                },
                y: {
                  stacked: true,
                },
              },
            }}
            
            data={omzetTeamTwo}
          />
        </div>
        ) }

        {
        showhide==='3' && (
        <div className="px-10 py-5">
          <Line
            options={{
              plugins: {
                title: {
                  display: true,
                },
              },
              responsive: true,
              scales: {
                x: {
                  stacked: true,
                },
                y: {
                  stacked: true,
                },
              },
            }}
            data={omzetTeamTree}
          />
        </div>
        ) 
      }
      
      
      
    
     
  
      
  
    </>
  );
}

export default DetailChartRevenue;
