import React from "react";

function CardDashboard({icon, title, subtitle}) {
  return (
    <div className="p-6 max-w-xs bg-white rounded-lg border border-gray-200 shadow-md hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
      <div className="flex items-center gap-3">
        <div className="bg-[#2DCE89] h-12 w-12 flex items-center justify-center rounded-lg p-2">
          <img src={icon}/>
        </div>
        <div className="flex flex-col">
          <p className="text-xs font-semibold text-gray-500 dark:text-white">
            {title}
          </p>
          <p className="font-bold text-[#24A46D] dark:text-gray-400">
            {subtitle}
          </p>
        </div>
      </div>
    </div>
  );
}

export default CardDashboard;
