import "aos/dist/aos.css";
import React, { useEffect, useState } from "react";
import SidebarComunication from "../../../Components/Sidebar/SidebarComunication";
import {
  deleteTemplate,
  getAllTemplate,
  getTemplatePagination,
  showTemplate,
  updateTemplate,
} from "../../../Network/Lib/template";
import { Link, useNavigate } from "react-router-dom";
import { logout } from "../../../Network/Lib/auth";
import Circle from "@mui/icons-material/Circle";
import Visibility from "@mui/icons-material/Visibility";
import Delete from "@mui/icons-material/Delete";
import { green, red } from "@mui/material/colors";
import DeleteModal from "../../../Assets/img/Delete-data.png";
import { getLanguage } from "../../../Network/Lib/language";
import ReactPaginate from 'react-paginate';



function Template() {
  const [navSideBar, setNavSideBar] = useState(false);
  const [refreshApi, setRefreshApi] = useState(false);

  // const [ showEditTemplate, setShowEditTemplate] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [template, setTemplate] = useState([]);
  const [selectTemplate, setSelectTemplate] = useState();

  const [lang, setLang] = useState("");
  const [region, setRegion] = useState([]);

  const navigate = useNavigate();


  // useEffect(() => {
  //   getLanguage().then((response) => {
  //       setRegion(response.data.data);
  //       console.log(response.data.data, 'aku');
  //     });
  // }, []);

  // useEffect(() => {
  //   getAllTemplate()
  //     .then((response) => {
  //       setTemplate(response.data.data);
  //     })
  //     .catch((error) => {
  //       // console.log(error);
  //     });
  //   setRefreshApi(false);
  // }, [refreshApi]);


  // useEffect(() => {
  //   Promise.all([getTemplatePagination(5, 1), getLanguage()]).then(([templateResponse, regionResponse]) => {
  //     const templates = templateResponse.data.data.map((template) => {
  //       const region = regionResponse.data.data.find((r) => r.code === template.language);
  //       return {
  //         ...template,
  //         language: region ? region.country : template.language,
  //       };
  //     });
  //     setTemplate(templates);
  //   }).catch((error) => {
  //     // console.log(error);
  //   });
  //   setRefreshApi(false);
  // }, [refreshApi]);

  // const [pageNumber, setPageNumber] = useState(0);
  // const [pageCount, setPageCount] = useState(0);

  // useEffect(() => {
  //   Promise.all([getTemplatePagination(10, pageNumber + 1), getLanguage()]).then(([templateResponse, regionResponse]) => {
  //     const templates = templateResponse.data.data.map((template) => {
  //       const region = regionResponse.data.data.find((r) => r.code === template.language);
  //       return {
  //         ...template,
  //         language: region ? region.country : template.language,
  //       };
  //     });
  //     setTemplate(templates);
  //     setPageCount(templateResponse.data.total_pages);
  //   }).catch((error) => {
  //     // console.log(error);
  //   });
  // }, [pageNumber]);

  // const handlePageClick = (data) => {
  //   setPageNumber(data.selected);
  // };

  // const [pageNumber, setPageNumber] = useState(0);
  // const [pageCount, setPageCount] = useState(0);
  // const [cachedTemplates, setCachedTemplates] = useState([]);

  // useEffect(() => {
  //   if (cachedTemplates[pageNumber]) {
  //     // Gunakan data template yang sudah di-cache jika sudah ada
  //     setTemplate(cachedTemplates[pageNumber]);
  //     return;
  //   }

  //   Promise.all([getTemplatePagination(10, pageNumber + 1), getLanguage()])
  //     .then(([templateResponse, regionResponse]) => {
  //       const templates = templateResponse.data.data.map((template) => {
  //         const region = regionResponse.data.data.find((r) => r.code === template.language);
  //         return {
  //           ...template,
  //           language: region ? region.country : template.language,
  //         };
  //       });

  //       // Simpan data template yang dimuat ke dalam cache
  //       setCachedTemplates((prevTemplates) => {
  //         const newTemplates = [...prevTemplates];
  //         newTemplates[pageNumber] = templates;
  //         return newTemplates;
  //       });

  //       setTemplate(templates);
  //       setPageCount(templateResponse.data.total_pages);
  //     })
  //     .catch((error) => {
  //       // console.log(error);
  //     });
  // }, [cachedTemplates, pageNumber]);

  // const handlePageClick = (data) => {
  //   setPageNumber(data.selected);
  // };

  const [pageNumber, setPageNumber] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [cachedTemplates, setCachedTemplates] = useState([]);
  

  useEffect(() => {
    if (cachedTemplates[pageNumber]) {
      // Gunakan data template yang sudah di-cache jika sudah ada
      setTemplate(cachedTemplates[pageNumber]);
      return;
    }
    Promise.all([getTemplatePagination(10, pageNumber + 1), getLanguage()])
      .then(([templateResponse, regionResponse]) => {
        const templates = templateResponse.data.data.map((template) => {
          const region = regionResponse.data.data.find((r) => r.code === template.language);
          return {
            ...template,
            language: region ? region.country : template.language,
          };
        });

        // Simpan data template yang dimuat ke dalam cache
        setCachedTemplates((prevTemplates) => {
          const newTemplates = [...prevTemplates];
          newTemplates[pageNumber] = templates;
          return newTemplates;
        });
        setTemplate(templates);
        setPageCount(templateResponse.data.total_pages);
      })
      .catch((error) => {
        // console.log(error);
      });
  }, [cachedTemplates, pageNumber]);

  const handlePageClick = (data) => {
    setPageNumber(data.selected);
  };





  // useEffect(() => {
  //   getLanguage().then((response) => {
  //     setRegion(response.data.data);
  //   });
  // }, []);

  // useEffect(() => {
  //   getAllTemplate()
  //     .then((response) => {
  //       setTemplate(response.data.data);
  //       const { language } = response.data.data;
  //       const countryName = region.find((obj) => obj.code === language)?.country;
  //       setLang(countryName ?? "Language not available");
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  //   setRefreshApi(false);
  // }, [refreshApi]);


  // const onUpdateTemplate = async () => {
  //   let form = new FormData()
  //   form.append('templateName', editTemplateName) 
  //   form.append('message', editMessage)

  //   await updateTemplate(form.then)((response) => {
  //     setEditTemplateName("")
  //     setEditMessage("")
  //     setRefreshApi(true)
  //     setShowEditTemplate(false)
  //   })
  // }

  //  const onShowTemplate = (id) => {
  //   const template = getAllTemplate[id]
  //   // setshowUserId(template.showUserId)
  //   setShowTemplateName(template.templateName)
  //   setShowMessage(template.message)
  //  }

  // const onShowTemplate = async () => {
  //   const params = {
  //     userId: "21a94031-408b-11ed-9809-a4c3f0a06823",
  //     showTemplateName: showTemplateName,
  //     showMessage: showMessage
  //   }
  //   await getAllTemplate (params).then((response)=>{
  //     setTemplateName("")
  //     setMessage("")
  //     setRefreshApi(true)
  //     // navigate('/View-template')
  //   })
  // }

  const onEditTemplate = (data) => {
    const params = {
      template: data,
    };
    navigate("/Edit-Template", {
      state: params,
    });
  };

  const onShowTemplate = (id) => {
    const params = { id: id };
    navigate(`/View-Template/`, {
      state: params
    })

  };

  // const onViewBroadcast = (id) => {
  //   const params = { id: id };
  //   navigate(`/View-Broadcast/${id}`);
  //   // navigate('/View-Broadcast', {
  //   //   state: params
  //   // })
  // };

  // const onConfirmDelete = async (name) => {
  //   // console.log(id);
  //   const form = {

  //     nameTemplate : name,


  //   };
  //   console.log(form);
  //   await deleteTemplate(form).then((response) => {
  //     console.log(response);
  //     setRefreshApi(true);
  //   });
  // };
  // const onConfirmDelete = (id) => {
  //   console.log(id);
  //   deleteTemplate(id).then((response) => {
  //     console.log(response);
  //     setRefreshApi(true);
  //   });
  // };

  const onConfirmDelete = async () => {
    await deleteTemplate(template[selectTemplate].id).then((response) => {
      setRefreshApi(true);
    });
    // await deleteTemplate(selectTemplate).then((response)=> {
    //   setRefreshApi(true);
    // });
  }

  const signOut = () => {
    logout();
    navigate("/login");
  };

  const myStyle = {
    color: 'gray',
    cursor: 'not-allowed',
    /* tambahkan gaya lain yang Anda inginkan di sini */
  };
  return (
    <>
      <div className="bg-gradient-to-r from-[#54DAA1] to-[#2DCE89]">
        <div className="relative">
          <aside
            className={
              navSideBar === true
                ? "w-24 h-screen p-5 fixed top-0 left-0 right-0 z-10"
                : "w-3/12 h-screen p-5 fixed top-0 left-0 right-0 z-10"
            }
            aria-label="Sidebar"
          >
            <button
              className="absolute top-72 right-1 w-10 h-10 bg-gradient-to-r from-[#54DAA1] to-[#2DCE89] rounded-full shadow-lg flex justify-center items-center"
              onClick={() => setNavSideBar(!navSideBar)}
            >
              {navSideBar ? (
                <svg
                  width="9"
                  height="16"
                  viewBox="0 0 9 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 14.3333L7.66667 7.66668L1 1.00001"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              ) : (
                <svg
                  width="10"
                  height="16"
                  viewBox="0 0 10 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.33325 14.6667L1.66659 7.99999L8.33325 1.33332"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              )}
            </button>
            <SidebarComunication navSideBar={navSideBar} />
          </aside>
        </div>
        <div
          className={
            navSideBar === true
              ? "ml-24 h-72 w-11/12 p-5 space-y-5"
              : "ml-auto h-72 w-9/12 p-5 space-y-5"
          }
        >
          {/* Header */}
          <div className="flex flex-row justify-between items-center">
            <div className="text-white space-y-2">
              <div className="flex flex-row space-x-3">
                <p className="text-sm">Pages</p>
                <p className="text-sm">/</p>
                <a href="/communication" className="text-sm">
                  Communication
                </a>
                <p className="text-sm">/</p>
                <a href="/template" className="text-sm">
                  Template
                </a>
              </div>
              <p className="font-semibold">Template</p>
            </div>
            <div className="flex flex-row items-center gap-5">
              <a href="#" className="flex items-center gap-2">
                <svg
                  width="21"
                  height="20"
                  viewBox="0 0 21 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.4938 2.52235C12.7336 1.70165 11.6719 1.24969 10.5 1.24969C9.32191 1.24969 8.25668 1.69891 7.50004 2.51454C6.73519 3.33915 6.36254 4.45985 6.45004 5.67001C6.62348 8.05751 8.44027 9.99969 10.5 9.99969C12.5598 9.99969 14.3735 8.0579 14.5496 5.67079C14.6383 4.47157 14.2633 3.35321 13.4938 2.52235Z"
                    fill="white"
                  />
                  <path
                    d="M17.3752 18.7488H3.62522C3.44525 18.7511 3.26701 18.7133 3.10349 18.6381C2.93997 18.5629 2.79526 18.4522 2.67991 18.314C2.426 18.0105 2.32366 17.596 2.39944 17.1769C2.72913 15.348 3.75804 13.8117 5.37522 12.7332C6.81194 11.7757 8.63186 11.2488 10.5002 11.2488C12.3686 11.2488 14.1885 11.7761 15.6252 12.7332C17.2424 13.8113 18.2713 15.3476 18.601 17.1765C18.6768 17.5957 18.5744 18.0101 18.3205 18.3136C18.2052 18.4519 18.0605 18.5626 17.897 18.6379C17.7335 18.7132 17.5552 18.7511 17.3752 18.7488Z"
                    fill="white"
                  />
                </svg>
                <p className="text-white">{localStorage.getItem("nameUser")}</p>
              </a>
              <button onClick={signOut} className="bg-white p-3 rounded-lg">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.5625 2.8125H2.1875V17.8125H6.5625M13.4375 5.9375L17.8125 10.3125L13.4375 14.6875M7.1875 10.3125H17.8125"
                    stroke="#EF4444"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            </div>
          </div>
          {/* start: Main */}

          <div className="grid grid-cols-12 gap-5">
            <div className="col-span-12">
              <div className="bg-white shadow-lg h-full rounded-lg p-6">
                <div className="flex flex-row justify-between">
                  <div className="flex items-center justify-center gap-3">
                    <a href="/communication">
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.11261 0.862514L0.862615 7.11251C0.748814 7.23139 0.659608 7.37157 0.600115 7.52501C0.475092 7.82934 0.475092 8.17069 0.600115 8.47501C0.659608 8.62845 0.748814 8.76863 0.862615 8.88751L7.11261 15.1375C7.22916 15.2541 7.36753 15.3465 7.5198 15.4096C7.67208 15.4727 7.83529 15.5051 8.00011 15.5051C8.33299 15.5051 8.65224 15.3729 8.88761 15.1375C9.12299 14.9021 9.25523 14.5829 9.25523 14.25C9.25523 13.9171 9.12299 13.5979 8.88761 13.3625L4.76261 9.25001H14.2501C14.5816 9.25001 14.8996 9.11832 15.134 8.8839C15.3684 8.64948 15.5001 8.33153 15.5001 8.00001C15.5001 7.66849 15.3684 7.35055 15.134 7.11613C14.8996 6.88171 14.5816 6.75001 14.2501 6.75001H4.76261L8.88761 2.63751C9.00478 2.52131 9.09777 2.38306 9.16123 2.23073C9.22469 2.07841 9.25736 1.91503 9.25736 1.75001C9.25736 1.585 9.22469 1.42162 9.16123 1.26929C9.09777 1.11697 9.00478 0.978718 8.88761 0.862514C8.77141 0.745353 8.63316 0.652359 8.48084 0.588898C8.32851 0.525437 8.16513 0.492765 8.00011 0.492765C7.8351 0.492765 7.67172 0.525437 7.51939 0.588898C7.36707 0.652359 7.22882 0.745353 7.11261 0.862514Z"
                          fill="#A1A1AA"
                        />
                      </svg>
                    </a>
                    <h1 className="text-lg font-bold">Broadcast Template</h1>
                  </div>
                  <div className="flex flex-row gap-3">
                    <div className="relative w-ful">
                      <div className="flex absolute inset-y-5 left-0 items-center pl-3 pointer-events-none">
                        <svg
                          className="w-5 h-5 text-gray-500 dark:text-gray-400"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                      </div>
                      <input
                        type="text"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Search"
                      />
                    </div>
                    <Link
                      className="bg-green-400 hover:bg-green-300 text-white rounded-lg px-3 py-2 font-medium flex flex-row gap-3 flex items-center"
                      to="/create-template"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="currentColor"
                        className="bi bi-plus-lg"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fillRule="evenodd"
                          d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"
                        />
                      </svg>
                      <span>Create Template</span>
                    </Link>
                  </div>
                </div>
                <div className="mt-5">
                  <div className="overflow-x-auto">
                    <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                      <thead className="text-xs text-gray-400 uppercase dark:text-gray-400">
                        <tr>
                          <th scope="col" className="py-3 px-6">
                            Name
                          </th>
                          <th scope="col" className="py-3 px-6">
                            Whatsapp Account
                          </th>
                          <th scope="col" className="py-3 px-6">
                            Languages
                          </th>
                          <th scope="col" className="py-3 px-6 text-end">
                            action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {template != null ? (
                          template.map((template, index) => {
                            return (
                              <>
                                <tr className="bg-white" key={template.id}>
                                  <td className="py-4 px-6">{template.name}</td>
                                  <td className="py-4 px-6">
                                    PT. Powerketo Wahyu Keprabon
                                  </td>
                                  <td className="flex flex-wrap gap-3 py-4 px-6">
                                    <div className="flex flex-wrap items-center gap-3 bg-gray-100 rounded-lg w-fit p-2">
                                      <Circle
                                        sx={{ fontSize: 10, color: green["A400"] }}
                                      />
                                      <p>
                                        {template.language}
                                      </p>
                                    </div>
                                    {/* <div className="flex flex-wrap items-center gap-3 bg-gray-100 rounded-lg w-fit p-2">
                                  <Circle
                                    sx={{ fontSize: 10, color: green["A400"] }}
                                  />
                                  <p>English</p>
                                </div> */}
                                  </td>
                                  <td className="py-4 px-6">
                                    <div className="flex flex-row gap-3">
                                      {/* <button 
                                   onClick={() => onShowTemplate(template.id)}
                                   className="flex gap-1 text-green-2 hover:text-green-600">
                                    <Visibility
                                      fontSize="small"
                                      sx={{ color: green["A400"] }}
                                    />{" "}
                                    <span>View</span>
                                  </button> */}
                                      <Link
                                        to={`/View-Template/${template.id}`}
                                        className="flex gap-1 text-green-2 hover:text-green-600">
                                        <Visibility
                                          fontSize="small"
                                          sx={{ color: green["A400"] }}
                                        />{" "}
                                        <span>View</span>
                                      </Link>
                                      <button className="flex gap-1 text-red-600 hover:text-red-800"
                                        onClick={() => {
                                          setShowDelete(true);
                                          // setSelectTemplate(template.id);
                                          setSelectTemplate(index);
                                        }


                                        }
                                      // onClick={() => 
                                      //   onConfirmDelete(template.id)

                                      // }
                                      >
                                        <Delete
                                          fontSize="small"
                                          sx={{ color: red["A400"] }}
                                        />

                                        <span>Delete</span>
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                              </>

                            );

                          })
                        ) : (
                          <tr className="bg-white">
                            <td
                              colSpan={5}
                              className="bg-gray-50 py-10 text-center"
                            >
                              Data Not Found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  <div className='flex items-center justify-center mt-[30px] gap-[5px]'>
                    <ReactPaginate
                      previousLabel={' '}
                      nextLabel={' '}
                      pageRangeDisplayed={3} // tampilkan 3 halaman
                      marginPagesDisplayed={1} // tampilkan 1 halaman margin
                      pageCount={pageCount}
                      onPageChange={handlePageClick}
                      breakLabel={<span className="mr-4">...</span>}
                    
                      containerClassName="flex items-center justify center mt-8 mb-4 space-x-10"
                      // disabledClassName="w-[40px] h-[40px] text-[12px] rounded-lg border-2 flex items-center justify-center text-white"
                      disabledClassName='my-style'
                      activeClassName="w-[40px] h-[40px] text-[12px] rounded-lg border-2 bg-gradient-to-r from-[#2DCECC] to-[#2DCE89] text-white flex items-center justify-center"
                     
                    />
                  </div>

                </div>
                {/* <div className="grid grid-rows-4 md:grid-cols-2 lg:grid-cols-2 lg:grid-flow-row gap-5 md:justify-between items-center py-5">
                  {template &&
                    template.map((template, index) => (
                      <>
                        <CardTemplate
                          key={template.id}
                          templateId={template.id}
                          Title={template.templateName}
                          Customer={template?.admin.name}
                          Date={template.createdAt}
                          Value={template.message}
                          onShowTemplate={onShowTemplate}
                          // view={"/View-Template"}
                          onEditTemplate={onEditTemplate}
                          onConfirmDelete={onConfirmDelete}
                        />
                      </>
                    ))}
                </div> */}
              </div>
            </div>
          </div>

          {/* end: Main */}
        </div>

        {showDelete ? (
          <>
            <div className="bg-black bg-opacity-75 overflow-y-auto overflow-x-hidden fixed flex justify-center top-0 left-0 right-0 bottom-0 z-50 w-full md:inset-0 md:h-full">
              {/* <div className="relative w-full max-w-2xl h-full md:h-auto"> */}
              {/* ini yang ditambahin */}
              <div className="fixed w-full max-w-xl md:h-auto mt-40">
                {/* ini yang ditambahin */}
                <div className="relative bg-[#F5F8FA] rounded-lg shadow dark:bg-gray-700">
                  <div className="p-6 space-y-6 items-center">
                    <div className="flex flex-row justify-between items-start gap-5">
                      <div className="flex flex-row gap-5">
                        <img src={DeleteModal} className="w-100 object-cover" />
                        <div className="flex flex-col">
                          <h1 className="font-bold">Delete Template?</h1>
                          <p className="font-semibold text-gray-400">
                            Data will be permanently deleted
                          </p>
                          <div className="mt-10 flex flex-row justify-start gap-3">
                            <button
                              onClick={() => {
                                // onDelete();
                                onConfirmDelete();
                                setShowDelete(false);
                              }}
                              className="text-sm py-2 px-5 rounded-xl text-white bg-green-400 hover:bg-green-500 font-semibold"
                            >
                              Yes
                            </button>
                            <button
                              className="text-sm px-3 rounded-xl text-gray-400 hover:text-gray-600 bg-gray-100 hover:bg-gray-200 font-semibold"
                              onClick={() => setShowDelete(false)}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                      <button
                        type="button"
                        className="bg-transparent hover:bg-gray-100 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                        onClick={() => setShowDelete(false)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="#A1A5B7"
                          className="bi bi-x-circle"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                          <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : null}
      </div>
    </>
  );
}

export default Template;
