import React, { Component, useState, useEffect, Fragment } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import "aos/dist/aos.css";
import { logout } from "../../../Network/Lib/auth";
import { getWhatsappBarcode, sendMediaMessage, sendMessage, testSession } from "../../../Network/Lib/whatsapp";
import SidebarWaUn from "../../../Components/Sidebar/SidebarWaUn";
import ICLoading from "../../../Assets/icon/loading.gif";

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

function SendMessage() {
    const { session, name } = useParams();
    const navigate = useNavigate();
    const [refreshApi, setRefreshApi] = useState(false);
    const [navSideBar, setNavSideBar] = useState(false);

    const [msg, setMsg] = useState([])
    const [phone, setPhone] = useState('')
    const [message, setMessage] = useState('')
    const [image, setImage] = useState('')


    const [showInput, setShowInput] = useState(false);
    const [barcode, setBarcode] = useState('')
    const [loading, setLoading] = useState(false)
    const [ready, setReady] = useState(false)

    const scanBarcode = async (phone, name, form) => {
        setLoading(true)
        try {
            getWhatsappBarcode(phone, name)
                .then(async (response) => {
                    console.log(response)
                    if (response.data.data == 'whtasapp ready, tunggu 30 detik') {
                        setLoading(false)
                        setReady(true)
                        setTimeout(async () => {
                            setLoading(false);
                            setShowInput(false)

                            try {
                                await sendMessage(form)
                                    .then(async (response) => {
                                        console.log(response)
                                        navigate('/mydevice')
                                    })
                                    .catch((e) => {
                                        console.log(e);
                                    });
                            } catch (error) {
                                console.log(error);
                            }
                        }, 30000);

                    } else {
                        setBarcode(response.data)
                        setLoading(false)
                    }
                })
                .catch((e) => {
                    if (e.response) {
                        console.log(e.response.data.message);
                    }
                });
        } catch (error) {
            console.log(error);
        }
    };
    const getBase64 = (file) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            setImage(reader.result);
            // console.log(reader.result);
        };
    };
    const postData = async () => {
        const err = [];
        if (phone == "") {
            err.push("Phone Number Can't be empty");
        }
        if (message == "") {
            err.push("Message Can't be empty");
        }
        if (err.length > 0) {
            console.log(err);
            err.push(err);
        } else {
            const form = {
                sender: session,
                number: phone,
                message: message,
                image: image
            };
            console.log(form);
            try {
                await testSession(session).then(async (res) => {
                    const status = res.data.msg
                    console.log(status)
                    if (status == true) {
                        if (image != "") {
                            try {
                                await sendMediaMessage(form)
                                    .then(async (response) => {
                                        console.log(response)
                                        navigate('/mydevice')
                                    })
                                    .catch((e) => {
                                        if (e) {
                                            console.log(e);
                                            err.push(e.response.data.message);
                                            setMsg(err);
                                        }
                                    });
                            } catch (error) {
                                console.log(error);
                            }
                        } else if (image == "") {
                            try {
                                await sendMessage(form)
                                    .then(async (response) => {
                                        console.log(response)
                                        navigate('/mydevice')
                                    })
                                    .catch((e) => {
                                        if (e) {
                                            console.log(e);
                                            err.push(e.response.data.message);
                                            setMsg(err);
                                        }
                                    });
                            } catch (error) {
                                console.log(error);
                            }
                        }
                    } else if (status == false) {
                        scanBarcode(session, name, form)
                        setShowInput(true)
                    }
                })
            } catch (error) {
                console.log(error);
            }
        }
    };

    const signOut = () => {
        logout();
        navigate("/login");
    };

    return (
        <>
            <div className="bg-gradient-to-r from-[#54DAA1] to-[#2DCE89]">
                <div className="relative">
                    <aside
                        className={
                            navSideBar === true
                                ? "w-24 h-screen p-5 fixed top-0 left-0 right-0 z-10"
                                : "w-3/12 h-screen p-5 fixed top-0 left-0 right-0 z-10"
                        }
                        aria-label="Sidebar"
                    >
                        <button
                            className="absolute top-72 right-1 w-10 h-10 bg-gradient-to-r from-[#54DAA1] to-[#2DCE89] rounded-full shadow-lg flex justify-center items-center"
                            onClick={() => setNavSideBar(!navSideBar)}
                        >
                            {navSideBar ? (
                                <svg
                                    width="9"
                                    height="16"
                                    viewBox="0 0 9 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M1 14.3333L7.66667 7.66668L1 1.00001"
                                        stroke="white"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                            ) : (
                                <svg
                                    width="10"
                                    height="16"
                                    viewBox="0 0 10 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M8.33325 14.6667L1.66659 7.99999L8.33325 1.33332"
                                        stroke="white"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                            )}
                        </button>
                        <SidebarWaUn navSideBar={navSideBar} />
                    </aside>
                </div>
                <div
                    className={
                        navSideBar === true
                            ? "ml-24 h-72 w-11/12 p-5 space-y-5"
                            : "ml-auto h-72 w-9/12 p-5 space-y-5"
                    }
                >
                    {/* Header */}
                    <div className="flex flex-row justify-between items-center">
                        <div className="text-white space-y-2">
                            <div className="flex flex-row space-x-3">
                                <p className="text-sm">Pages</p>
                                <p className="text-sm">/</p>
                                <p className="text-sm">WhatsApp</p>
                                <p className="text-sm">/</p>
                                <p className="text-sm">My Device</p>
                            </div>
                            <p className="font-semibold">My Device</p>
                        </div>
                        <div className="flex flex-row items-center gap-5">
                            <Link to="#" className="flex items-center gap-2">
                                <svg
                                    width="21"
                                    height="20"
                                    viewBox="0 0 21 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M13.4938 2.52235C12.7336 1.70165 11.6719 1.24969 10.5 1.24969C9.32191 1.24969 8.25668 1.69891 7.50004 2.51454C6.73519 3.33915 6.36254 4.45985 6.45004 5.67001C6.62348 8.05751 8.44027 9.99969 10.5 9.99969C12.5598 9.99969 14.3735 8.0579 14.5496 5.67079C14.6383 4.47157 14.2633 3.35321 13.4938 2.52235Z"
                                        fill="white"
                                    />
                                    <path
                                        d="M17.3752 18.7488H3.62522C3.44525 18.7511 3.26701 18.7133 3.10349 18.6381C2.93997 18.5629 2.79526 18.4522 2.67991 18.314C2.426 18.0105 2.32366 17.596 2.39944 17.1769C2.72913 15.348 3.75804 13.8117 5.37522 12.7332C6.81194 11.7757 8.63186 11.2488 10.5002 11.2488C12.3686 11.2488 14.1885 11.7761 15.6252 12.7332C17.2424 13.8113 18.2713 15.3476 18.601 17.1765C18.6768 17.5957 18.5744 18.0101 18.3205 18.3136C18.2052 18.4519 18.0605 18.5626 17.897 18.6379C17.7335 18.7132 17.5552 18.7511 17.3752 18.7488Z"
                                        fill="white"
                                    />
                                </svg>
                                <p className="text-white">{localStorage.getItem("nameUser")}</p>
                            </Link>
                            <button onClick={signOut} className="bg-white p-3 rounded-lg">
                                <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M6.5625 2.8125H2.1875V17.8125H6.5625M13.4375 5.9375L17.8125 10.3125L13.4375 14.6875M7.1875 10.3125H17.8125"
                                        stroke="#EF4444"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                            </button>
                        </div>
                    </div>
                    {/* start: Main */}
                    <div className="w-full">
                        <div className="">
                            <div className="bg-white min-h-screen rounded-lg px-10 py-10 sh shadow-lg">
                                <div className="flex flex-col md:flex-row justify-between ">
                                    <div className="py-2 flex flex-col">
                                        <span className="font-semibold text-lg">Broadcast</span>
                                        <span className="text-sm text-gray-400">
                                            You can create broadcast message here
                                        </span>
                                    </div>
                                    {/* <div className="flex flex-row justify-center">
                                        <div className="ml-5">
                                            <AddDevice/>
                                        </div>
                                    </div> */}
                                </div>

                                <div className="mt-5 space-y-3">
                                    <div className="bg-[#F5F8FA] rounded-[20px] p-[30px]">
                                        <h1 className="font-bold text-[#212529] mb-2">Recipient</h1>
                                        <div className="">
                                            <label
                                                className="block text-[#A1A5B7] font-semibold text-sm mb-2"
                                                for="grid-state"
                                            >
                                                Single Input
                                            </label>
                                            <input
                                                type="number"
                                                className="bg-white border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full"
                                                placeholder="Ex. 628xxxxxxx"
                                                onChange={(e) => setPhone(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="bg-[#F5F8FA] rounded-[20px] p-[30px]">
                                        <h1 className="font-bold text-[#212529] mb-2">Message</h1>
                                        <div className="mb-5">
                                            <label
                                                className="block text-[#A1A5B7] font-semibold text-sm mb-2"
                                                for="grid-state"
                                            >
                                                Image
                                            </label>
                                            <input
                                                type="file"
                                                className="bg-white border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full"
                                                onChange={(e) => {
                                                    // setProductImage(e.target.files[0]);
                                                    getBase64(e.target.files[0]);
                                                    // console.log(e.target.files[0]);
                                                }}
                                            />
                                        </div>
                                        <div className="">
                                            <label
                                                className="block text-[#A1A5B7] font-semibold text-sm mb-2"
                                                for="grid-state"
                                            >
                                                Write Message
                                            </label>
                                            <textarea
                                                type="text"
                                                className="bg-white border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full"
                                                placeholder="write message here"
                                                onChange={(e) => setMessage(e.target.value)}
                                            ></textarea>

                                        </div>
                                    </div>
                                    <div className="mt-10 flex flex-row justify-end">
                                        <button
                                            className="px-3 rounded-xl text-gray-400 hover:text-gray-600 font-semibold"
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            className="py-2 px-6 rounded-md text-white bg-green-400 hover:bg-green-500 font-semibold ml-2"
                                            onClick={postData}
                                        >
                                            Submit
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* end: Main */}
                </div>

                {showInput ? (
                    <>
                        <div className="bg-black bg-opacity-75 overflow-y-auto overflow-x-hidden fixed flex justify-center top-0 left-0 right-0 bottom-0 z-50 w-full md:inset-0 h-modal md:h-full">
                            {/* <div className="relative w-full max-w-2xl h-full md:h-auto"> */}
                            {/* ini yang ditambahin */}
                            <div className="relative fixed w-full max-w-2xl h-full md:h-auto my-5">
                                {/* ini yang ditambahin */}
                                <div className="relative bg-[#F5F8FA] rounded-lg shadow dark:bg-gray-700">
                                    <div className="p-6 space-y-6 items-center flex justify-center">
                                        <div>
                                            {loading ?
                                                <img src={ICLoading}></img>
                                                :
                                                (ready ?
                                                    <div className="py-5">
                                                        <img src={ICLoading}></img>
                                                        <p className="text-center font-bold">whtasapp ready, tunggu 30 detik</p>
                                                    </div>
                                                    : <img src={`data:image/jpeg;base64,${barcode}`}></img>)
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                ) : null}
            </div>
        </>
    );
}

export default SendMessage;
