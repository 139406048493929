import React from "react";
import { Link, useHref } from "react-router-dom";

function CardCommunication({icon, title, subtitle, href}) {
  return (
    <div className="p-6 max-w-xs bg-white rounded-xl border border-gray-200 shadow-md hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
      <div className="flex items-center gap-3">
        <Link to={href} className="bg-[#2DCE89] rounded-lg p-2 hover:bg-green-500">
          <img src={icon}/>
        </Link>
        <div className="flex flex-col">
          <p className="text-xs font-semibold text-gray-300 dark:text-white">
            {title}
          </p>
          <p className="font-bold text-[#24A46D] dark:text-gray-400">
            {subtitle}
          </p>
        </div>
      </div>
    </div>
  );
}

export default CardCommunication;
