import React, { useState } from "react";
import Navbar from "../Components/Navbar/Navbar";
import qr from "../Assets/img/qr-code.png";

const state = {
  labels: [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sept",
    "Ockt",
    "Nov",
    "Des",
  ],
  datasets: [
    {
      label: "Name_Product",
      backgroundColor: "rgba(75,192,192,1)",
      borderColor: "rgba(0,0,0,1)",
      borderWidth: 1,
      data: [65, 59, 80, 81, 56, 77, 44, 84, 100, 91, 10, 15],
    },
    {
      label: "Name_Product",
      backgroundColor: "rgba(237,76,92,1)",
      borderColor: "rgba(0,0,0,1)",
      borderWidth: 1,
      data: [65, 59, 80, 81, 56, 77, 44, 84, 100, 91, 10, 15],
    },
  ],
};

function AddWhatsapp() {
  const [count_lead, setCountLead] = useState("");
  return (
    <div>
      <Navbar />
      <div className="px-10 py-20">
        <div className="shadow-md p-3 mt-6">
          <div className="my-10 flex flex-col md:flex-row-reverse gap-4 justify-around justify-items-center">
            <div className="flex flex-col justify-items-center gap-5">
              <img src={qr} alt="qr" />
              <p className="text-center">
                Trouble logging in?
                <br />
                please{" "}
                <a
                  href="#"
                  className="font-bold text-gray-800 hover:text-black"
                >
                  Reload Server
                </a>
              </p>
            </div>
            <div>
              <h1 className="text-xl my-5">To use WhatsApp on your computer</h1>
              <div className="flex flex-row items-center gap-5 my-3">
                <div className="bg-[#00E676] p-2 rounded-lg">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="white"
                    className="bi bi-whatsapp"
                    viewBox="0 0 16 16"
                  >
                    <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z" />
                  </svg>
                </div>
                <p>1. Open WhatsApp on your phone</p>
              </div>
              <div className="flex flex-row items-center gap-5 my-3">
                <div className="bg-[#00E676] p-2 rounded-lg">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="white"
                    className="bi bi-three-dots-vertical"
                    viewBox="0 0 16 16"
                  >
                    <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                  </svg>
                </div>
                <p>2. Tap menu or setting, Select Linked Device</p>
              </div>
              <div className="flex flex-row items-center gap-5 my-3">
                <div className="bg-[#00E676] p-2 rounded-lg">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="white"
                    className="bi bi-camera"
                    viewBox="0 0 16 16"
                  >
                    <path d="M15 12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h1.172a3 3 0 0 0 2.12-.879l.83-.828A1 1 0 0 1 6.827 3h2.344a1 1 0 0 1 .707.293l.828.828A3 3 0 0 0 12.828 5H14a1 1 0 0 1 1 1v6zM2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2z" />
                    <path d="M8 11a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5zm0 1a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7zM3 6.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z" />
                  </svg>
                </div>

                <p>3. Point your phone to this screen to capture the code</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddWhatsapp;
