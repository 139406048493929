const yearly = {
  labels: [
    "2018",
    "2019",
    "2020",
    "2021",
    "2022",
  ],
  datasets: [
    {
      fill: true,
      label: "bottle",
      data: [65, 59, 80, 81, 56],
      borderColor: "rgb(0, 255, 0)",
      backgroundColor: ["rgba(79, 194, 155, 0.8)"],
    },
  ],
};

export default yearly;
