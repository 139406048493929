import React, { Component, useState } from "react";
// import Navbar from "../Components/Navbar/Navbar";
import Sidebar from "../../Components/Sidebar/SidebarReporting";
import ChartAchievement from "../../Components/Partials/Chart/ChartAchievement";
import ChartBottelPerProduct from "../../Components/Partials/Chart/ChartBottelPerProduct";
import ChartClosingPerTeam from "../../Components/Partials/Chart/ChartClosingPerTeam";
import ChartClosingRatePerProduct from "../../Components/Partials/Chart/ChartClosingRatePerProduct";
import ChartCSRanking from "../../Components/Partials/Chart/ChartCSRanking";
import ChartDatabase from "../../Components/Partials/Chart/ChartDatabase";
import ChartDetailReport from "../../Components/Partials/Chart/ChartDetailReport";
import ChartOmzet from "../../Components/Partials/Chart/ChartOmzet";
import ChartOmzetProduct from "../../Components/Partials/Chart/ChartOmzetProduct";
import ChartStatusPackage from "../../Components/Partials/Chart/ChartStatusPackage";
import ChartStatusPaket from "../../Components/Partials/Chart/ChartStatusPaket";
import { useNavigate } from "react-router-dom";
import { logout } from "../../Network/Lib/auth";

function RepotingSpv2() {
  const navigate = useNavigate();
  const [openTab, setOpenTab] = React.useState(1);
  const [navSideBar, setNavSideBar] = useState(false);
  
  const signOut =()=>{
    logout();
    navigate('/login')
  }
  return (
    <div className="bg-gradient-to-r from-[#54DAA1] to-[#2DCE89]">
        <div className="relative">
          <aside
            className={
              navSideBar === true
                ? "w-24 h-screen p-5 fixed top-0 left-0 right-0 z-10"
                : "w-3/12 h-screen p-5 fixed top-0 left-0 right-0 z-10"
            }
            aria-label="Sidebar"
          >
            <button
              className="absolute top-72 right-1 w-10 h-10 bg-gradient-to-r from-[#54DAA1] to-[#2DCE89] rounded-full shadow-lg flex justify-center items-center"
              onClick={() => setNavSideBar(!navSideBar)}
            >
              {navSideBar ? (
                <svg
                  width="9"
                  height="16"
                  viewBox="0 0 9 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 14.3333L7.66667 7.66668L1 1.00001"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              ) : (
                <svg
                  width="10"
                  height="16"
                  viewBox="0 0 10 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.33325 14.6667L1.66659 7.99999L8.33325 1.33332"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              )}
            </button>
            <Sidebar navSideBar={navSideBar} />
          </aside>
        </div>
        <div
          className={
            navSideBar === true
              ? "ml-24 h-72 w-11/12 p-5 space-y-5"
              : "ml-auto h-72 w-9/12 p-5 space-y-5"
          }
        >
          {/* Header */}
          <div className="flex flex-row justify-between items-center">
            <div className="text-white space-y-2">
              <p className="text-sm">Pages / Reporting</p>
              <p className="font-semibold">Reporting</p>
            </div>
            <div className="flex flex-row items-center gap-5">
              <a href="#" className="flex items-center gap-2">
                <svg
                  width="21"
                  height="20"
                  viewBox="0 0 21 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.4938 2.52235C12.7336 1.70165 11.6719 1.24969 10.5 1.24969C9.32191 1.24969 8.25668 1.69891 7.50004 2.51454C6.73519 3.33915 6.36254 4.45985 6.45004 5.67001C6.62348 8.05751 8.44027 9.99969 10.5 9.99969C12.5598 9.99969 14.3735 8.0579 14.5496 5.67079C14.6383 4.47157 14.2633 3.35321 13.4938 2.52235Z"
                    fill="white"
                  />
                  <path
                    d="M17.3752 18.7488H3.62522C3.44525 18.7511 3.26701 18.7133 3.10349 18.6381C2.93997 18.5629 2.79526 18.4522 2.67991 18.314C2.426 18.0105 2.32366 17.596 2.39944 17.1769C2.72913 15.348 3.75804 13.8117 5.37522 12.7332C6.81194 11.7757 8.63186 11.2488 10.5002 11.2488C12.3686 11.2488 14.1885 11.7761 15.6252 12.7332C17.2424 13.8113 18.2713 15.3476 18.601 17.1765C18.6768 17.5957 18.5744 18.0101 18.3205 18.3136C18.2052 18.4519 18.0605 18.5626 17.897 18.6379C17.7335 18.7132 17.5552 18.7511 17.3752 18.7488Z"
                    fill="white"
                  />
                </svg>
                <p className="text-white">{localStorage.getItem("nameUser")}</p>
              </a>
              <button onClick={signOut} className="bg-white p-3 rounded-lg">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.5625 2.8125H2.1875V17.8125H6.5625M13.4375 5.9375L17.8125 10.3125L13.4375 14.6875M7.1875 10.3125H17.8125"
                      stroke="#EF4444"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
            </div>
          </div>
          {/* start: Main */}
          <div className="pb-5">
            <div className="bg-white shadow-lg rounded-lg p-6">
              <div className="flex flex-col md:flex-row justify-between items-start md:items-center gap-3">
                <h1 className="text-lg font-semibold">Reporting</h1>
                {/* <AddProduct /> */}
              </div>
              <div className="py-10">
                <div className="grid md:grid-cols-2">
                  <ChartOmzet />
                  <ChartOmzetProduct />
                  <ChartClosingPerTeam />
                  <ChartBottelPerProduct />
                  <ChartClosingRatePerProduct />
                  <ChartStatusPackage />
                </div>
                {/*begin: Dashboard Reporting*/}
                <div className="w-full">
                  <div className="">
                    <ul
                      className="grid md:grid-cols-12 grid-cols-2 mb-0 list-none pt-3 pb-4"
                      role="tablist"
                    >
                      <li className="mb-2 col-span-2 text-center">
                        <a
                          className={
                            "text-xs font-bold uppercase px-5 py-3 block leading-normal " +
                            (openTab === 1
                              ? "text-green-500 bg-white shadow-lg"
                              : "text-gray-400 bg-gray-100 mr-1")
                          }
                          onClick={(e) => {
                            e.preventDefault();
                            setOpenTab(1);
                          }}
                          data-toggle="tab"
                          href="#link1"
                          role="tablist"
                        >
                          Achievement
                        </a>
                      </li>
                      <li className="mb-2 col-span-2 text-center">
                        <a
                          className={
                            "text-xs font-bold uppercase px-5 py-3 block leading-normal " +
                            (openTab === 2
                              ? "text-green-500 bg-white shadow-lg"
                              : "text-gray-400 bg-gray-100 mr-1")
                          }
                          onClick={(e) => {
                            e.preventDefault();
                            setOpenTab(2);
                          }}
                          data-toggle="tab"
                          href="#link2"
                          role="tablist"
                        >
                          status paket
                        </a>
                      </li>
                      <li className="mb-2 col-span-2 text-center">
                        <a
                          className={
                            "text-xs font-bold uppercase px-5 py-3 block leading-normal " +
                            (openTab === 3
                              ? "text-green-500 bg-white shadow-lg"
                              : "text-gray-400 bg-gray-100 mr-1")
                          }
                          onClick={(e) => {
                            e.preventDefault();
                            setOpenTab(3);
                          }}
                          data-toggle="tab"
                          href="#link3"
                          role="tablist"
                        >
                          database
                        </a>
                      </li>
                      <li className="mb-2 col-span-2 text-center">
                        <a
                          className={
                            "text-xs font-bold uppercase px-5 py-3 block leading-normal " +
                            (openTab === 4
                              ? "text-green-500 bg-white shadow-lg"
                              : "text-gray-400 bg-gray-100 mr-1")
                          }
                          onClick={(e) => {
                            e.preventDefault();
                            setOpenTab(4);
                          }}
                          data-toggle="tab"
                          href="#link4"
                          role="tablist"
                        >
                          rank cs
                        </a>
                      </li>
                      <li className="mb-2 col-span-2 text-center">
                        <a
                          className={
                            "text-xs font-bold uppercase px-5 py-3 block leading-normal " +
                            (openTab === 5
                              ? "text-green-500 bg-white shadow-lg"
                              : "text-gray-400 bg-gray-100 mr-1")
                          }
                          onClick={(e) => {
                            e.preventDefault();
                            setOpenTab(5);
                          }}
                          data-toggle="tab"
                          href="#link5"
                          role="tablist"
                        >
                          detail report
                        </a>
                      </li>
                    </ul>

                    <div className="relative">
                      <div className="grid bg-white">
                        <div className="tab-content tab-space">
                          <div
                            className={openTab === 1 ? "block" : "hidden"}
                            id="link1"
                          >
                            <ChartAchievement />
                          </div>
                        </div>
                        <div className="tab-content tab-space">
                          <div
                            className={openTab === 2 ? "block" : "hidden"}
                            id="link2"
                          >
                            <ChartStatusPaket />
                          </div>
                        </div>
                        <div className="tab-content tab-space">
                          <div
                            className={openTab === 3 ? "block" : "hidden"}
                            id="link3"
                          >
                            <ChartDatabase />
                          </div>
                        </div>
                        <div className="tab-content tab-space">
                          <div
                            className={openTab === 4 ? "block" : "hidden"}
                            id="link4"
                          >
                            <ChartCSRanking />
                          </div>
                        </div>
                        <div className="tab-content tab-space md:col-span-6">
                          <div
                            className={openTab === 5 ? "block" : "hidden"}
                            id="link5"
                          >
                            <ChartDetailReport />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* begin: End Reporting */}
              </div>
            </div>
          </div>
          {/* end: Main */}
        </div>
        {/* Modal */}
      </div>
  );
}

export default RepotingSpv2;

























// import React, { Component, useState } from "react";
// import Navbar from "../Components/Navbar/Navbar";
// import ChartAchievement from "../Components/Partials/Chart/ChartAchievement";
// import ChartBottelPerProduct from "../Components/Partials/Chart/ChartBottelPerProduct";
// import ChartClosingPerTeam from "../Components/Partials/Chart/ChartClosingPerTeam";
// import ChartClosingRatePerProduct from "../Components/Partials/Chart/ChartClosingRatePerProduct";
// import ChartCSRanking from "../Components/Partials/Chart/ChartCSRanking";
// import ChartDatabase from "../Components/Partials/Chart/ChartDatabase";
// import ChartDetailReport from "../Components/Partials/Chart/ChartDetailReport";
// import ChartOmzet from "../Components/Partials/Chart/ChartOmzet";
// import ChartOmzetProduct from "../Components/Partials/Chart/ChartOmzetProduct";
// import ChartStatusPackage from "../Components/Partials/Chart/ChartStatusPackage";
// import ChartStatusPaket from "../Components/Partials/Chart/ChartStatusPaket";

// function RepotingSpv2() {
//   const [openTab, setOpenTab] = React.useState(1);
//   return (
//     <div>
//       {/*begin: Navbar*/}
//       <Navbar />
//       {/*end: Navbar*/}
//       <div className="px-10 py-5 space-y-5">
//         {/*begin: TopBar*/}
//         <div className="mt-20">
//           <div className="flex flex-col md:flex-row justify-between items-start md:items-center gap-3">
//             <div className="flex flex-col">
//               <h1 className="text-lg font-semibold">Reporting</h1>
//               <p className="text-gray-400 text-sm font-medium flex flex-row items-center gap-2">
//                 <a href="/dashboard" className="hover:text-gray-600">
//                   Dashboard
//                 </a>
//                 <svg
//                   xmlns="http://www.w3.org/2000/svg"
//                   width="16"
//                   height="16"
//                   fill="currentColor"
//                   className="bi bi-chevron-right"
//                   viewBox="0 0 16 16"
//                 >
//                   <path
//                     fillRule="evenodd"
//                     d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
//                   />
//                 </svg>
//                 <a href="#" className="hover:text-gray-600">
//                   Reporting
//                 </a>
//               </p>
//             </div>
//             {/* <div className="flex flex-col md:flex-row gap-2">
//             <AddTemplate />
//           </div> */}
//           </div>
//         </div>
//         {/*end: TopBar*/}
//         <div className="grid md:grid-cols-2">
//             <ChartOmzet />
//             <ChartOmzetProduct />
//             <ChartClosingPerTeam />
//             <ChartBottelPerProduct />
//             <ChartClosingRatePerProduct />
//             <ChartStatusPackage />
//         </div>
//         {/*begin: Dashboard Reporting*/}
//         <div className="w-full">
//           <div className="">
//             <ul
//               className="grid md:grid-cols-12 grid-cols-2 mb-0 list-none pt-3 pb-4"
//               role="tablist"
//             >
//               <li className="mb-2 col-span-2 text-center">
//                 <a
//                   className={
//                     "text-xs font-bold uppercase px-5 py-3 block leading-normal " +
//                     (openTab === 1
//                       ? "text-green-500 bg-white shadow-lg"
//                       : "text-gray-400 bg-gray-100 mr-1")
//                   }
//                   onClick={(e) => {
//                     e.preventDefault();
//                     setOpenTab(1);
//                   }}
//                   data-toggle="tab"
//                   href="#link1"
//                   role="tablist"
//                 >
//                   Achievement
//                 </a>
//               </li>
//               <li className="mb-2 col-span-2 text-center">
//                 <a
//                   className={
//                     "text-xs font-bold uppercase px-5 py-3 block leading-normal " +
//                     (openTab === 2
//                       ? "text-green-500 bg-white shadow-lg"
//                       : "text-gray-400 bg-gray-100 mr-1")
//                   }
//                   onClick={(e) => {
//                     e.preventDefault();
//                     setOpenTab(2);
//                   }}
//                   data-toggle="tab"
//                   href="#link2"
//                   role="tablist"
//                 >
//                   status paket
//                 </a>
//               </li>
//               <li className="mb-2 col-span-2 text-center">
//                 <a
//                   className={
//                     "text-xs font-bold uppercase px-5 py-3 block leading-normal " +
//                     (openTab === 3
//                       ? "text-green-500 bg-white shadow-lg"
//                       : "text-gray-400 bg-gray-100 mr-1")
//                   }
//                   onClick={(e) => {
//                     e.preventDefault();
//                     setOpenTab(3);
//                   }}
//                   data-toggle="tab"
//                   href="#link3"
//                   role="tablist"
//                 >
//                   database
//                 </a>
//               </li>
//               <li className="mb-2 col-span-2 text-center">
//                 <a
//                   className={
//                     "text-xs font-bold uppercase px-5 py-3 block leading-normal " +
//                     (openTab === 4
//                       ? "text-green-500 bg-white shadow-lg"
//                       : "text-gray-400 bg-gray-100 mr-1")
//                   }
//                   onClick={(e) => {
//                     e.preventDefault();
//                     setOpenTab(4);
//                   }}
//                   data-toggle="tab"
//                   href="#link4"
//                   role="tablist"
//                 >
//                   rank cs
//                 </a>
//               </li>
//               <li className="mb-2 col-span-2 text-center">
//                 <a
//                   className={
//                     "text-xs font-bold uppercase px-5 py-3 block leading-normal " +
//                     (openTab === 5
//                       ? "text-green-500 bg-white shadow-lg"
//                       : "text-gray-400 bg-gray-100 mr-1")
//                   }
//                   onClick={(e) => {
//                     e.preventDefault();
//                     setOpenTab(5);
//                   }}
//                   data-toggle="tab"
//                   href="#link5"
//                   role="tablist"
//                 >
//                   detail report
//                 </a>
//               </li>
//             </ul>

//             <div className="relative">
//               <div className="grid bg-white">
//                 <div className="tab-content tab-space">
//                   <div
//                     className={openTab === 1 ? "block" : "hidden"}
//                     id="link1"
//                   >
//                     <ChartAchievement />
//                   </div>
//                 </div>
//                 <div className="tab-content tab-space">
//                   <div
//                     className={openTab === 2 ? "block" : "hidden"}
//                     id="link2"
//                   >
//                     <ChartStatusPaket />
//                   </div>
//                 </div>
//                 <div className="tab-content tab-space">
//                   <div
//                     className={openTab === 3 ? "block" : "hidden"}
//                     id="link3"
//                   >
//                     <ChartDatabase />
//                   </div>
//                 </div>
//                 <div className="tab-content tab-space">
//                   <div
//                     className={openTab === 4 ? "block" : "hidden"}
//                     id="link4"
//                   >
//                     <ChartCSRanking />
//                   </div>
//                 </div>
//                 <div className="tab-content tab-space md:col-span-6">
//                   <div
//                     className={openTab === 5 ? "block" : "hidden"}
//                     id="link5"
//                   >
//                     <ChartDetailReport />
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//         {/*begin: End Reporting*/}
//       </div>
//     </div>
//   );
// }

// export default RepotingSpv2;
