import "aos/dist/aos.css";
import React, { useEffect, useState } from "react";
import AddCS from "../../Components/Partials/Modal/AddCS";
import SidebarComunication from "../../Components/Sidebar/SidebarComunication";
import CardTemplate from "../../Components/Partials/Card/CardTemplate";
import arrowLeft from "../../Assets/icon/comunication/arrowLeft.svg";
import leveling from "../../Assets/icon/Leveling.png";
import leveling1 from "../../Assets/icon/Leveling-1.png";
import leveling2 from "../../Assets/icon/Leveling-2.png";
import leveling3 from "../../Assets/icon/Leveling-3.png";
import TableLeveling from "../../Components/Partials/Table/TableLeveling";
import { logout } from "../../Network/Lib/auth";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getLevelingById, getNewCustomer } from "../../Network/Lib/leveling";

function DetailLeveling() {
  const navigate = useNavigate();
  const [navSideBar, setNavSideBar] = useState(false);

  const location = useLocation();
  const id = location.state.id;
  const [data, setData] = useState();

  console.log(id);
  useEffect(() => {
    id != null
      ? getLevelingById(id).then((response) => {
          console.log(response.data.data[0]);
          setData(response.data.data[0]);
        })
      : getNewCustomer(id).then((response) => {
          console.log(response.data.data);
          setData(response.data.data);
        });
  }, []);

  const getAge = (dateString) => {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };

  const signOut = () => {
    logout();
    navigate("/login");
  };
  return (
    <>
      <div className="bg-gradient-to-r from-[#54DAA1] to-[#2DCE89]">
        <div className="relative">
          <aside
            className={
              navSideBar === true
                ? "w-24 h-screen p-5 fixed top-0 left-0 right-0 z-10"
                : "w-3/12 h-screen p-5 fixed top-0 left-0 right-0 z-10"
            }
            aria-label="Sidebar"
          >
            <button
              className="absolute bottom-96 right-1 w-10 h-10 bg-gradient-to-r from-[#54DAA1] to-[#2DCE89] rounded-full shadow-lg flex justify-center items-center"
              onClick={() => setNavSideBar(!navSideBar)}
            >
              {navSideBar ? (
                <svg
                  width="9"
                  height="16"
                  viewBox="0 0 9 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 14.3333L7.66667 7.66668L1 1.00001"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              ) : (
                <svg
                  width="10"
                  height="16"
                  viewBox="0 0 10 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.33325 14.6667L1.66659 7.99999L8.33325 1.33332"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              )}
            </button>
            <SidebarComunication navSideBar={navSideBar} />
          </aside>
        </div>
        <div
          className={
            navSideBar === true
              ? "ml-24 h-72 w-11/12 p-5 space-y-5"
              : "ml-auto h-72 w-9/12 p-5 space-y-5"
          }
        >
          {/* Header */}
          <div className="flex flex-row justify-between items-center">
            <div className="text-white space-y-2">
              <div className="flex flex-row space-x-3">
                <p className="text-sm">Pages</p>
                <p className="text-sm">/</p>
                <a href="/communication" className="text-sm">
                  Communication
                </a>
                <p className="text-sm">/</p>
                <a href="/leveling" className="text-sm">
                  Leveling
                </a>
                <p className="text-sm">/</p>
                <a href="/leveling" className="text-sm">
                  Detail Leveling
                </a>
              </div>
              <p className="font-semibold">Detail Leveling</p>
            </div>
            <div className="flex flex-row items-center gap-5">
              <a href="#" className="flex items-center gap-2">
                <svg
                  width="21"
                  height="20"
                  viewBox="0 0 21 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.4938 2.52235C12.7336 1.70165 11.6719 1.24969 10.5 1.24969C9.32191 1.24969 8.25668 1.69891 7.50004 2.51454C6.73519 3.33915 6.36254 4.45985 6.45004 5.67001C6.62348 8.05751 8.44027 9.99969 10.5 9.99969C12.5598 9.99969 14.3735 8.0579 14.5496 5.67079C14.6383 4.47157 14.2633 3.35321 13.4938 2.52235Z"
                    fill="white"
                  />
                  <path
                    d="M17.3752 18.7488H3.62522C3.44525 18.7511 3.26701 18.7133 3.10349 18.6381C2.93997 18.5629 2.79526 18.4522 2.67991 18.314C2.426 18.0105 2.32366 17.596 2.39944 17.1769C2.72913 15.348 3.75804 13.8117 5.37522 12.7332C6.81194 11.7757 8.63186 11.2488 10.5002 11.2488C12.3686 11.2488 14.1885 11.7761 15.6252 12.7332C17.2424 13.8113 18.2713 15.3476 18.601 17.1765C18.6768 17.5957 18.5744 18.0101 18.3205 18.3136C18.2052 18.4519 18.0605 18.5626 17.897 18.6379C17.7335 18.7132 17.5552 18.7511 17.3752 18.7488Z"
                    fill="white"
                  />
                </svg>
                <p className="text-white">{localStorage.getItem("nameUser")}</p>
              </a>
              <button onClick={signOut} className="bg-white p-3 rounded-lg">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.5625 2.8125H2.1875V17.8125H6.5625M13.4375 5.9375L17.8125 10.3125L13.4375 14.6875M7.1875 10.3125H17.8125"
                    stroke="#EF4444"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            </div>
          </div>
          {/* start: Main */}

          <div className="grid grid-cols-12 gap-5">
            <div className="col-span-12">
              <div className="bg-white shadow-lg h-full rounded-lg p-10">
                <div className="flex flex-row justify-between">
                  <div className="flex items-center justify-center gap-3">
                    <a href="/leveling">
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.11261 0.862514L0.862615 7.11251C0.748814 7.23139 0.659608 7.37157 0.600115 7.52501C0.475092 7.82934 0.475092 8.17069 0.600115 8.47501C0.659608 8.62845 0.748814 8.76863 0.862615 8.88751L7.11261 15.1375C7.22916 15.2541 7.36753 15.3465 7.5198 15.4096C7.67208 15.4727 7.83529 15.5051 8.00011 15.5051C8.33299 15.5051 8.65224 15.3729 8.88761 15.1375C9.12299 14.9021 9.25523 14.5829 9.25523 14.25C9.25523 13.9171 9.12299 13.5979 8.88761 13.3625L4.76261 9.25001H14.2501C14.5816 9.25001 14.8996 9.11832 15.134 8.8839C15.3684 8.64948 15.5001 8.33153 15.5001 8.00001C15.5001 7.66849 15.3684 7.35055 15.134 7.11613C14.8996 6.88171 14.5816 6.75001 14.2501 6.75001H4.76261L8.88761 2.63751C9.00478 2.52131 9.09777 2.38306 9.16123 2.23073C9.22469 2.07841 9.25736 1.91503 9.25736 1.75001C9.25736 1.585 9.22469 1.42162 9.16123 1.26929C9.09777 1.11697 9.00478 0.978718 8.88761 0.862514C8.77141 0.745353 8.63316 0.652359 8.48084 0.588898C8.32851 0.525437 8.16513 0.492765 8.00011 0.492765C7.8351 0.492765 7.67172 0.525437 7.51939 0.588898C7.36707 0.652359 7.22882 0.745353 7.11261 0.862514Z"
                          fill="#A1A1AA"
                        />
                      </svg>
                    </a>
                    <h1 className="text-lg font-bold">Detail Leveling</h1>
                  </div>
                </div>
                <div className="py-10">
                  <div className="shadow-md rounded-xl p-10">
                    <div className="flex flex-col md:flex-row justify-between items-start md:items-center">
                      <div className="flex flex-col">
                        <h1 className="text-xl font-bold">
                          {id == null ? "New Customer" : data?.levelingName}
                        </h1>
                        <h1 className="text-gray-500 font-semibold">
                          {id == null ? data?.length : data?.customers?.length} data
                        </h1>
                      </div>
                      <div className="flex flex-col md:flex-row items-start md:items-center gap-2">
                        <div className="flex flex-row rounded-lg bg-gray-50 border border-gray-300 mt-1">
                          <input
                            type="text"
                            id="table-search"
                            className="appearance-none bg-transparent border-none w-full text-gray-700 leading-tight focus:outline-none focus:ring-transparent w-64"
                            placeholder="Search"
                          />
                          <div className="flex items-center pr-3 pointer-events-none">
                            <svg
                              className="w-5 h-5 text-gray-500 dark:text-gray-400"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                                clipRule="evenodd"
                              ></path>
                            </svg>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mt-5">
                      {/* <TableLeveling /> */}
                      <div className="overflow-x-auto md:h-80">
                        <table className="w-full text-sm text-left text-gray-500">
                          <thead className="text-sm text-gray-400">
                            <tr>
                              <th scope="col" className="px-6 py-3 w-52">
                                Customer
                              </th>
                              <th scope="col" className="px-6 py-3 w-52">
                                Status
                              </th>
                              <th scope="col" className="px-6 py-3 w-52">
                                Phone
                              </th>
                              <th scope="col" className="px-6 py-3 w-52">
                                Email
                              </th>
                              <th scope="col" className="px-6 py-3 w-52">
                                Gender
                              </th>
                              <th scope="col" className="px-6 py-3 w-52">
                                Age
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 w-52 truncate"
                              >
                                Marital Status
                              </th>
                              <th scope="col" className="px-6 py-3 w-52">
                                Action
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {id != null
                              ? data?.customers &&
                                data?.customers.map((val, index) => (
                                  <tr key={index} className="bg-gray-50">
                                    <td className="px-6 truncate">
                                      {val.name}
                                    </td>
                                    <td className="px-6 truncate">
                                      <p className="text-green-500 bg-green-100 px-2">
                                        {data?.levelingName}
                                      </p>
                                    </td>
                                    <td className="px-6">{val.phone}</td>
                                    <td className="px-6">{val.email}</td>
                                    <td className="px-6">{val.gender}</td>
                                    <td className="px-6">
                                      {getAge(val.birthdate)}
                                    </td>
                                    <td className="px-6">
                                      {val.marital_status}
                                    </td>
                                    <td className="px-6">
                                      <div className="flex flex-row gap-3 items-center">
                                        <Link
                                          to="/crm-chat"
                                          className="text-green-400"
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            fill="currentColor"
                                            className="bi bi-whatsapp"
                                            viewBox="0 0 16 16"
                                          >
                                            <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z" />
                                          </svg>
                                        </Link>
                                        <Link
                                          to={`/view-customer/${val.id}`}
                                          className="text-green-400"
                                        >
                                          <svg
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              d="M15 11H22V13H15V11ZM16 15H22V17H16V15ZM14 7H22V9H14V7ZM4 19H14V18C14 15.243 11.757 13 9 13H7C4.243 13 2 15.243 2 18V19H4ZM8 12C9.995 12 11.5 10.495 11.5 8.5C11.5 6.505 9.995 5 8 5C6.005 5 4.5 6.505 4.5 8.5C4.5 10.495 6.005 12 8 12Z"
                                              fill="#1A424F"
                                            />
                                          </svg>
                                        </Link>
                                      </div>
                                    </td>
                                  </tr>
                                ))
                              : data &&
                                data?.map((val, index) => (
                                  <tr key={index} className="bg-gray-50">
                                    <td className="px-6 truncate">
                                      {val.name}
                                    </td>
                                    <td className="px-6 truncate">
                                      <p className="text-green-500 bg-green-100 px-2">
                                        New Customer
                                      </p>
                                    </td>
                                    <td className="px-6">{val.phone}</td>
                                    <td className="px-6">{val.email}</td>
                                    <td className="px-6">{val.gender}</td>
                                    <td className="px-6">
                                      {getAge(val.birthdate)}
                                    </td>
                                    <td className="px-6">
                                      {val.marital_status}
                                    </td>
                                    <td className="px-6">
                                      <div className="flex flex-row gap-3 items-center">
                                        <Link
                                          to="/crm-chat"
                                          className="text-green-400"
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            fill="currentColor"
                                            className="bi bi-whatsapp"
                                            viewBox="0 0 16 16"
                                          >
                                            <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z" />
                                          </svg>
                                        </Link>
                                        <Link
                                          to={`/view-customer/${val.id}`}
                                          className="text-green-400"
                                        >
                                          <svg
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              d="M15 11H22V13H15V11ZM16 15H22V17H16V15ZM14 7H22V9H14V7ZM4 19H14V18C14 15.243 11.757 13 9 13H7C4.243 13 2 15.243 2 18V19H4ZM8 12C9.995 12 11.5 10.495 11.5 8.5C11.5 6.505 9.995 5 8 5C6.005 5 4.5 6.505 4.5 8.5C4.5 10.495 6.005 12 8 12Z"
                                              fill="#1A424F"
                                            />
                                          </svg>
                                        </Link>
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* end: Main */}
        </div>
      </div>
    </>
  );
}

export default DetailLeveling;
