import React, { useState, Fragment } from "react";
import Navbar from "../Components/Navbar/Navbar";
import AddCS from "../Components/Partials/Modal/AddCS";
import Blasting from "../Components/Partials/Table/Blasting";
import { Menu, Transition } from "@headlessui/react";
import { PlusIcon } from "@heroicons/react/solid";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function DetailBlasting() {
  const [count_lead, setCountLead] = useState("");
  return (
    <div>
      <Navbar />
      <div className="px-10 py-20">
        <div className="shadow-lg p-10">
          <div className="flex flex-col md:flex-row justify-between items-start md:items-center gap-3">
            <div className="flex flex-col">
              <h1 className="text-xl font-bold">Detail Blasting</h1>
              <h1 className="text-gray-400 font-semibold flex flex-row items-center gap-2">
                100 Data
              </h1>
            </div>
            <div className="flex flex-col md:flex-row gap-2 md:gap-5 w-full md:w-fit">
              <button
                type="button"
                className="bg-gray-100 hover:bg-gray-200 text-gray-400 rounded-lg px-3 py-2 font-medium flex flex-row gap-3 flex items-center"
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 18 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.83268 11.1673H13.166V8.66732L17.3327 12.4173L13.166 16.1673V13.6673H9.83268V11.1673ZM15.666 0.333984H2.33268C1.89065 0.333984 1.46673 0.509579 1.15417 0.82214C0.84161 1.1347 0.666016 1.55862 0.666016 2.00065V12.0007C0.666016 12.4427 0.84161 12.8666 1.15417 13.1792C1.46673 13.4917 1.89065 13.6673 2.33268 13.6673H8.16602V12.0007H2.33268V3.66732L8.99935 7.83398L15.666 3.66732V8.66732H17.3327V2.00065C17.3327 1.55862 17.1571 1.1347 16.8445 0.82214C16.532 0.509579 16.108 0.333984 15.666 0.333984V0.333984ZM8.99935 6.16732L2.33268 2.00065H15.666L8.99935 6.16732Z"
                    fill="#A1A5B7"
                  />
                </svg>

                <span>Resend Broadcast</span>
              </button>
              <Menu as="div" className="relative inline-block text-left">
                <div>
                  <Menu.Button className="inline-flex justify-start w-full bg-gray-100 hover:bg-gray-200 text-gray-400 rounded-lg p-3 font-medium flex flex-row gap-3 flex items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-funnel"
                      viewBox="0 0 16 16"
                    >
                      <path d="M1.5 1.5A.5.5 0 0 1 2 1h12a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.128.334L10 8.692V13.5a.5.5 0 0 1-.342.474l-3 1A.5.5 0 0 1 6 14.5V8.692L1.628 3.834A.5.5 0 0 1 1.5 3.5v-2zm1 .5v1.308l4.372 4.858A.5.5 0 0 1 7 8.5v5.306l2-.666V8.5a.5.5 0 0 1 .128-.334L13.5 3.308V2h-11z" />
                    </svg>
                    <span>Filter Status</span>
                  </Menu.Button>
                </div>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="origin-top-right absolute right-0 mt-2 w-40 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            href="#"
                            className={classNames(
                              active
                                ? "bg-gray-100 text-gray-900"
                                : "text-gray-700",
                              "block px-4 py-2 text-sm"
                            )}
                          >
                            <div className="inline-flex justify-center content-center">
                              Success
                            </div>
                          </a>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            href="#"
                            className={classNames(
                              active
                                ? "bg-gray-100 text-gray-900"
                                : "text-gray-700",
                              "block px-4 py-2 text-sm"
                            )}
                          >
                            <div className="inline-flex justify-center content-center">
                              Pending
                            </div>
                          </a>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            href="#"
                            className={classNames(
                              active
                                ? "bg-gray-100 text-gray-900"
                                : "text-gray-700",
                              "block px-4 py-2 text-sm"
                            )}
                          >
                            <div className="inline-flex justify-center content-center">
                              Not Sent
                            </div>
                          </a>
                        )}
                      </Menu.Item>
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
              <div className="flex flex-row rounded-lg bg-gray-50 border border-gray-300 mt-1">
                <input
                  type="text"
                  id="table-search"
                  className="appearance-none bg-transparent border-none w-full text-gray-700 leading-tight focus:outline-none focus:ring-transparent w-64"
                  placeholder="Search"
                />
                <div className="flex items-center pr-3 pointer-events-none">
                  <svg
                    className="w-5 h-5 text-gray-500 dark:text-gray-400"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-10">
            <Blasting />
          </div>
        </div>
      </div>
    </div>
  );
}

export default DetailBlasting;
