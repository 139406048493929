import api from '../apiClient'

export function getWarehouse(){
    return api.axiosClient.get('/warehouse/get')
}

export function createWarehouse(data){
    return api.axiosClient.post('/warehouse/create', data)
}

export function updateWarehouse(id, data){
    return api.axiosClient.put(`/warehouse/update/${id}`, data)
}

export function deleteWarehouse(id){
    return api.axiosClient.delete(`/warehouse/delete/${id}`)
}