import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import useCollapse from "react-collapsed";
import { getLeads } from "../../../Network/Lib/boLead";
import TableFooter from "./Footer";
import { getAllCS } from "../../../Network/Lib/customer";
import { getProvince } from "../../../Network/Lib/rajaOngkir";

function TableDataCustomer({ filter, onFilteredCustomer }) {
  return (
    <div className="">
      <Table filter={filter} onFilteredCustomer={onFilteredCustomer} />
    </div>
  );
}
function Section(props) {
  const config = {
    defaultExpanded: props.defaultExpanded || false,
    collapsedHeight: props.collapsedHeight || 0,
  };
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse(config);
  return (
    <>
      <tr className="bg-gray-50" key={props.index}>
        <td className="px-6 py-4">{props.number}</td>
        <td className="px-6 py-4">{props.name}</td>
        <td className="px-6 py-4">{props.client_whatsapp}</td>
        <td className="px-6 py-4">{props.email}</td>
        <td className="px-6 py-4">
          <div className="bg-[#E9FFF7] w-fit p-1 text-center text-[#4FC29B] rounded">
            {props.level}
          </div>
          {/* <div
                    className={
                      props.level === "Customer Pasif"
                        ? "bg-[#FFE9EB] p-1 text-center text-[#ED4C5C] rounded"
                        : "bg-[#E9FFF7] p-1 text-center text-[#4FC29B] rounded"
                    }
                  >
                    {props.level}
                  </div> */}
        </td>
        {/* <td className="px-6 py-4">
                  {new Date(props.created_at).toLocaleDateString("id-ID")}
                </td> */}
        <td className="px-6">
          <div className="flex flex-row items-center gap-5">
            <Link to={`/editSingleCustumer/${props.link}`}>
              <svg
                width="21"
                height="20"
                viewBox="0 0 21 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.8021 16.6667H5.13542C4.91441 16.6667 4.70244 16.7545 4.54616 16.9108C4.38988 17.067 4.30209 17.279 4.30209 17.5C4.30209 17.721 4.38988 17.933 4.54616 18.0893C4.70244 18.2456 4.91441 18.3333 5.13542 18.3333H16.8021C17.0231 18.3333 17.2351 18.2456 17.3913 18.0893C17.5476 17.933 17.6354 17.721 17.6354 17.5C17.6354 17.279 17.5476 17.067 17.3913 16.9108C17.2351 16.7545 17.0231 16.6667 16.8021 16.6667ZM5.13542 15H5.21042L8.68542 14.6833C9.06608 14.6454 9.42212 14.4777 9.69375 14.2083L17.1938 6.70835C17.4849 6.40082 17.6422 5.99044 17.6312 5.56713C17.6203 5.14382 17.442 4.7421 17.1354 4.45001L14.8521 2.16668C14.5541 1.88676 14.1636 1.72615 13.7549 1.71539C13.3461 1.70463 12.9477 1.84449 12.6354 2.10835L5.13542 9.60835C4.86606 9.87998 4.69834 10.236 4.66042 10.6167L4.30209 14.0917C4.29086 14.2137 4.3067 14.3368 4.34847 14.452C4.39024 14.5672 4.45692 14.6718 4.54375 14.7583C4.62162 14.8356 4.71397 14.8967 4.8155 14.9382C4.91703 14.9796 5.02575 15.0006 5.13542 15ZM13.6938 3.33335L15.9688 5.60835L14.3021 7.23335L12.0688 5.00001L13.6938 3.33335Z"
                  fill="#A1A1AA"
                />
              </svg>
            </Link>
            <a>
              <div className="rounded-full p-1" {...getToggleProps()}>
                {isExpanded ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-chevron-up"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-chevron-down"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                    />
                  </svg>
                )}
              </div>
            </a>
          </div>
        </td>
      </tr>
      <tr {...getCollapseProps()}>{props.children}</tr>
    </>
  );
}

const Table = ({ filter, onFilteredCustomer }) => {
  const [page, setPage] = useState(1);
  // const { slice, range } = useTable(data, page, rowsPerPage);
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({
    first_page: 0,
    page_links: [],
    last_page: 0,
  });

  // console.log(data)
  const [province, setProvince] = useState([]);

  useEffect(() => {
    // getProvince(1).then((response) => {
    //   console.log(response.data.data.province);
    // });

    getAllCS(filter).then((response) => {
      setData(response.data.data);
      console.log("data customer",response.data.data)
      onFilteredCustomer(response.data.data);
      // setPagination({
      //   first_page: 1,
      //   page_links: response.data.links.slice(
      //     1,
      //     response.data.links.length - 1
      //   ),
      //   last_page: response.data.last_page,
      // });
    });
  }, [page, filter]);

  const getAge = (dateString) => {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };

  const getProv = (provId) => {
    // if (provId != null) {
    //   return provId;
    // } else {
    //   return "hehe";
    // }
    return provId;
  };

  console.log(data);
  return (
    <>
      <div className="overflow-x-auto">
        <table className="w-full text-sm text-left text-gray-500">
          <thead className="text-sm text-gray-400">
            {/* <tr>
              <th className="px-6 py-3 w-32">Customer</th>
              <th className="px-6 py-3 w-32">No. WhatsApp</th>
              <th className="px-6 py-3 w-32">Email</th>
              <th className="px-6 py-3 w-32">Level</th>
              <th className="px-6 py-3 w-32">Buying Date</th>
              <th className="px-6 py-3 w-32">Action</th>
            </tr> */}
          </thead>
          {data &&
            data.map((el, index) => (
              <tbody>
                <Section
                  index={index.toString()}
                  number={index + 1}
                  name={el.name}
                  client_whatsapp={el.client_whatsapp}
                  email={el.email}
                  level={
                    el.leveling?.levelingName
                      ? el.leveling.levelingName
                      : "New Customer"
                  }
                  link={el.id}
                >
                  <td className="bg-gray-50 w-full px-6" colSpan={6}>
                    <div className="flex flex-row justify-between">
                      <div>
                        <div className="flex flex-row gap-3">
                          <div className="text-gray-400">Age</div>
                          <div className="">{getAge(el.birthdate)}</div>
                        </div>
                        <div className="flex flex-row gap-3">
                          <div className="text-gray-400">Gender</div>
                          <div className="">{el.gender}</div>
                        </div>
                        <div className="flex flex-row gap-3">
                          <div className="text-gray-400">Province</div>
                          <div className="">
                            {el.customer_addresses.length == 0
                              ? "-"
                              : getProv(
                                  el.customer_addresses.map(
                                    (prov) => prov.province
                                  )
                                )}
                          </div>
                        </div>
                        <div className="flex flex-row gap-3">
                          <div className="text-gray-400">Buying Date</div>
                          <div className="">
                            {el.latest_purchase == null
                              ? "-"
                              : new Date(el.latest_purchase).toLocaleDateString(
                                  "id-ID"
                                )}
                          </div>
                        </div>
                      </div>
                      <div className="self-end">
                        <Link
                          to={`/view-customer/${el.id}`}
                          className="text-blue-800 hover:text-blue-900 underline"
                        >
                          See detail
                        </Link>
                      </div>
                    </div>
                  </td>
                </Section>
              </tbody>
            ))}
        </table>
      </div>
      <div className="flex flex-row justify-end">
        <TableFooter pagination={pagination} setPage={setPage} page={page} />
      </div>
    </>
  );
};

export default TableDataCustomer;
