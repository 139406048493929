import React, { useState, useEffect } from "react";
import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import {
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@heroicons/react/solid";
import useCollapse from "react-collapsed";
import broadcastData from "../../../Data/broadcast";
import Delete from "../../../Assets/img/Delete-data.png";
import useTable from "../../Table/Broadcast/useTable";
import TableFooter from "../../Table/Broadcast/footer";
import { deleteBroadcast, getBroadcast } from "../../../Network/Lib/broadcast";
import { Link, useNavigate } from "react-router-dom";
import { refresh } from "aos";
import { getBroadcastUn } from "../../../Network/Lib/broadcastUn";

// function classNames(...classes) {
//   return classes.filter(Boolean).join(" ");
// }

function TableBroadcastUn({data}) {
  return (
    <div className="">
      <div className="overflow-x-auto md:h-screen">
        <table className="w-full text-sm text-left text-gray-500">
          <thead className="text-sm text-gray-400">
            <tr className="text-sm text-gray-400 bg-gray-100">
              <th scope="col" className="px-6 py-3 w-52">
                Broadcast
              </th>
              <th scope="col" className="px-6 py-3 w-52">
                Customer
              </th>
              {/* <th scope="col" className="px-6 py-3 w-52">
                Template
              </th> */}
              <th scope="col" className="px-6 py-3 w-52">
                CS CRM
              </th>
              <th scope="col" className="px-6 py-3 w-52">
                Sender
              </th>
              <th scope="col" className="px-6 py-3 w-52">
                Send Date
              </th>
              <th scope="col" className="px-6 py-3 w-52">
                Action
              </th>
            </tr>
          </thead>
          {data &&
            data.map((el, index) => (
              <tbody>
                <Section
                  key={index}
                  id={el?.id}
                  broadcast={el?.name}
                  customer={el?.phone}
                  // template={el?.message}
                  csCRM={el?.nameSession}
                  sender={el?.sender}
                  date={el?.date}
                  time={el?.time}
                  // apiRefresh={handleRefresh}
                >
                  <td className="px-6" colSpan={2}>
                    <div className="flex flex-row gap-2 pb-2">
                      <p className="text-xs border border-green-500 text-green-500 rounded-full py-1 px-3">
                        {el.success} Success
                      </p>
                      <p className="text-xs border border-blue-500 text-blue-500 rounded-full py-1 px-3">
                        {el.onProgress} On Progress
                      </p>
                      <p className="text-xs border border-red-500 text-red-500 rounded-full py-1 px-3">
                        {el.unsent} Unsent
                      </p>
                    </div>
                  </td>
                </Section>
              </tbody>
            ))}
        </table>
      </div>
      <div className="flex flex-row justify-center">
        {/* <TableFooter
          range={range}
          slice={slice}
          setPage={setPage}
          page={page}
        /> */}
      </div>

    </div>
  );
}
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function Section(props) {
  const [showDelete, setShowDelete] = useState(false);

  const config = {
    defaultExpanded: props.defaultExpanded || false,
    collapsedHeight: props.collapsedHeight || 0,
  };
  const navigate = useNavigate();

  const onViewBroadcast = (id) => {
    const params = { id: id };
    navigate(`/View-Broadcast/${id}`);
    // navigate('/View-Broadcast', {
    //   state: params
    // })
  };

  const [id, setId] = useState([]);
  const onConfirmDelete = async () => {
    console.log(id);
    await deleteBroadcast(id).then((response) => {
      console.log(response);
      props.apiRefresh()
    });
  };

  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse(config);
  return (
    <>
      <tr className="bg-white">
        <td className="px-6 py-4">
          <div className="flex flex-row gap-3">
            <a>
              <div
                className="bg-green-400 hover:bg-green-500 text-white rounded-full p-1"
                {...getToggleProps()}
              >
                {isExpanded ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-chevron-up"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-chevron-down"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                    />
                  </svg>
                )}
              </div>
            </a>
            <p>{props.broadcast}</p>
          </div>
        </td>
        <td className="px-6 py-4">{props.customer.length} Customer</td>
        {/* <td className="px-6 py-4">{props.template}</td> */}
        <td className="px-6 py-4">{props.csCRM}</td>
        <td className="px-6 py-4">{props.sender}</td>
        <td className="px-6 py-4">
          {props.date} {props.time}
        </td>
        <td className="py-4 col-span-2 text-blue-400">
          <Menu as="div" className="relative inline-block text-left">
            <div>
              <Menu.Button className="inline-flex justify-center w-full rounded-md border border-[#F5F8FA] shadow-sm px-4 py-2 bg-[#F5F8FA] text-sm font-medium text-gray-700 hover:bg-[#F5F8FA] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-[#F5F8FA] focus:ring-[#F5F8FA]">
                Action
                <ChevronDownIcon
                  className="-mr-1 ml-2 h-5 w-5"
                  aria-hidden="true"
                />
              </Menu.Button>
            </div>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="origin-top-right z-10 absolute right-0 mt-2 w-20 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <Link
                        to={`/message-history/${props.id}`}
                        className={classNames(
                          active
                            ? "bg-gray-100 text-gray-900"
                            : "text-gray-700",
                          "block px-4 py-2 text-sm"
                        )}
                      >
                        View
                      </Link>
                    )}
                  </Menu.Item>
                  {/* <Menu.Item>
                    {({ active }) => (
                      <a
                        href={`/Edit-Broadcast/${props.id}`}
                        className={classNames(
                          active
                            ? "bg-gray-100 text-gray-900"
                            : "text-gray-700",
                          "block px-4 py-2 text-sm"
                        )}
                      >
                        Edit
                      </a>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <a
                        href="#"
                        onClick={() => {
                          setShowDelete(true);
                          setId(props.id);
                        }}
                        className={classNames(
                          active
                            ? "bg-gray-100 text-gray-900"
                            : "text-gray-700",
                          "block px-4 py-2 text-sm"
                        )}
                      >
                        Delete
                      </a>
                    )}
                  </Menu.Item> */}
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        </td>
      </tr>
      <tr
        className="bg-white border-b-2 border-[#F5F8FA]"
        {...getCollapseProps()}
      >
        {props.children}
      </tr>

      {showDelete ? (
        <>
          <div className="bg-black bg-opacity-75 overflow-y-auto overflow-x-hidden fixed flex justify-center top-0 left-0 right-0 bottom-0 z-50 w-full md:inset-0 md:h-full">
            {/* <div className="relative w-full max-w-2xl h-full md:h-auto"> */}
            {/* ini yang ditambahin */}
            <div className="fixed w-full max-w-xl md:h-auto mt-40">
              {/* ini yang ditambahin */}
              <div className="relative bg-[#F5F8FA] rounded-lg shadow dark:bg-gray-700">
                <div className="p-6 space-y-6 items-center">
                  <div className="flex flex-row justify-between items-start gap-5">
                    <div className="flex flex-row gap-5">
                      <img src={Delete} className="w-100 object-cover" />
                      <div className="flex flex-col">
                        <h1 className="font-bold">Delete WhatsApp?</h1>
                        <p className="font-semibold text-gray-400">
                          WhatsApp will be permanently deleted
                        </p>
                        <div className="mt-10 flex flex-row justify-start gap-3">
                          <button
                            className="text-sm py-2 px-5 rounded-xl text-white bg-green-400 hover:bg-green-500 font-semibold"
                            onClick={() => {
                              onConfirmDelete(id);
                              setShowDelete(false);
                            }}
                          >
                            Yes
                          </button>
                          <button
                            className="text-sm px-3 rounded-xl text-gray-400 hover:text-gray-600 bg-gray-100 hover:bg-gray-200 font-semibold"
                            onClick={() => setShowDelete(false)}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                    <button
                      type="button"
                      className="bg-transparent hover:bg-gray-100 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                      onClick={() => setShowDelete(false)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="#A1A5B7"
                        className="bi bi-x-circle"
                        viewBox="0 0 16 16"
                      >
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
}

const Table = ({ apiRefresh }) => {
  const [refreshApi, setRefreshApi] = useState(false)
  const [page, setPage] = useState(1);
  // const { slice, range } = useTable(data, page, rowsPerPage);
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({
    first_page: 0,
    page_links: [],
    last_page: 0,
  });

  const inAwait = () => {
    getBroadcast().then((response) => {
      setData(response.data.data);
      console.log(response.data.data);
      // setPagination({
      //   first_page: 1,
      //   page_links: response.data.links.slice(
      //     1,
      //     response.data.links.length - 1
      //   ),
      //   last_page: response.data.last_page,
      // });
    });
  }

  useEffect(() => {
    inAwait()
    setRefreshApi(false)
    apiRefresh()
  }, [refreshApi]);

  const handleRefresh = () => {
    console.log("text")
    setRefreshApi(true)
  }

  return (
    <>
      <div className="overflow-x-auto md:h-screen">
        <table className="w-full text-sm text-left text-gray-500">
          <thead className="text-sm text-gray-400">
            <tr className="text-sm text-gray-400 bg-gray-100">
              <th scope="col" className="px-6 py-3 w-52">
                Broadcast
              </th>
              <th scope="col" className="px-6 py-3 w-52">
                Customer
              </th>
              <th scope="col" className="px-6 py-3 w-52">
                Template
              </th>
              <th scope="col" className="px-6 py-3 w-52">
                CS CRM
              </th>
              <th scope="col" className="px-6 py-3 w-52">
                Send Date
              </th>
              <th scope="col" className="px-6 py-3 w-52">
                Action
              </th>
            </tr>
          </thead>
          {data &&
            data.map((el, index) => (
              <tbody>
                <Section
                  key={index}
                  id={el?.id}
                  broadcast={el?.nameBraodcast}
                  customer={JSON.parse(el?.phone.replace(/\\/g, ""))}
                  template={el?.templateName}
                  csCRM={el?.csCRM?.name}
                  date={el?.date}
                  time={el?.time}
                  apiRefresh={handleRefresh}
                >
                  <td className="px-6" colSpan={2}>
                    <div className="flex flex-row gap-2 pb-2">
                      <p className="text-xs border border-green-500 text-green-500 rounded-full py-1 px-3">
                        {el.success} Success
                      </p>
                      <p className="text-xs border border-blue-500 text-blue-500 rounded-full py-1 px-3">
                        {el.onProgress} On Progress
                      </p>
                      <p className="text-xs border border-red-500 text-red-500 rounded-full py-1 px-3">
                        {el.unsent} Unsent
                      </p>
                    </div>
                  </td>
                </Section>
              </tbody>
            ))}
        </table>
      </div>
      <div className="flex flex-row justify-center">
        {/* <TableFooter
          range={range}
          slice={slice}
          setPage={setPage}
          page={page}
        /> */}
      </div>
    </>
  );
};
export default TableBroadcastUn;
