import SidebarComunication from "../../../Components/Sidebar/SidebarComunication";
import { logout } from "../../../Network/Lib/auth";
import {
  ArrowBack,
  ArrowDropDown,
  ArrowForward,
  ArrowLeft,
  ArrowLeftSharp,
  Assignment,
  Check,
  Close,
  EditOutlined,
  GroupsSharp,
  ImportExport,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  PostAdd,
  RateReviewSharp,
  Search,
  Summarize,
} from "@mui/icons-material";
import { Dialog, Modal } from "@mui/material";
import moment, { lang } from "moment/moment";
// import { ArrowArcLeft, ArrowLineLeft, DownloadSimple, FileArrowUp, Minus, Plus, Trash, TrashSimple } from 'phosphor-react'
import React, { useEffect, useState, useRef, createContext, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import Steeper from "../../../Components/Steps/Stepper";
import StepOne from "../../../Components/Steps/step/StepOne";
import StepTwo from "../../../Components/Steps/step/StepTwo";
import StepTree from "../../../Components/Steps/step/StepTree";
import StepFour from "../../../Components/Steps/step/StepFour";
import { createTemplate } from "../../../Network/Lib/template";
import { getLanguage } from "../../../Network/Lib/language";



const CreateTemplate = ({displaySteps}) => {
  const navigate = useNavigate();
  const [navSideBar, setNavSideBar] = useState(false);
  const [refreshApi, setRefreshApi] = useState(false);
  const [index, setIndex] = useState(1);


  // step one data
  const [haveTemplate, setHaveTemplate] = useState()
  const [isLoading, setIsLoading] = useState(false)

  // step two data
 
  const [headerType, setHeaderType] = useState();
  const [headerContent, setHeaderContent] = useState();
  const [content, setContent] = useState();
  const [footer, setFooter] = useState();
  // const [selectLang, setSelectLang] = useState();
  const [selectLang, setSelectLang] = useState('');
  const [selectedLang, setSelectedLang] = useState({});

// const [language, setLanguage] = useState([]);
// const [selectLang, setSelectLang] = useState("");


  const [name, setName] = useState();
  const [category, setCategory] = useState();
  const [language, setLanguage] = useState([]);
  const [components, setComponents] = useState();
  const [typeBody, setTypeBody] = useState();
  const [typeHeader, setTypeHeader] = useState();
  const [typeFooter, setTypeFooter] = useState();
  const [typeButtons, setTypeButtons] = useState();
  const [textBody, setTextBody] = useState();
  const [textHeader, setTextHeader] = useState();
  const [textFooter, setTextFooter] = useState();
  const [textButtons, setTextButtons] = useState();
  // const [buttons, setButtons] = useState();
  const [phone, setPhone] = useState();

  const [showhide, setShowhide]=useState('QUICK_REPLAY');
  
  const handleshowhide=(event)=>{
    const getQuick = event.target.value;    
    setShowhide(getQuick);

  }


  const [showHeader, setShowHeader]=useState('TEXT');
  
  const handleshowheader=(event)=>{
    const getHeader = event.target.value;    
    setShowHeader(getHeader);

  }



  const [buttons, setButtons] = useState([]);
  const [inputFields, setInputFields] = useState([{
      text: "",
      type: "",
      phone_number: "",
      url: "",
  }]);

  const addFields = () => {
    const newField = {
      text: "",
      type: "",
      phone_number: "",
      url: "",
    };
    setInputFields([...inputFields, newField]);
  };

  const removeFields = (index) => {
    const updatedFields = [...inputFields];
    updatedFields.splice(index, 1);
    setInputFields(updatedFields);
  };

  const handleFormChange = (index, event) => {
    const { name, value } = event.target;
    const updatedFields = [...inputFields];
    updatedFields[index][name] = value;
    setInputFields(updatedFields);
  };


  
  


  



  useEffect(() => {
    console.log([name, category, selectLang], "step2")
  }, [name, category, selectLang])

  const data = [
    {
      id: 1,
      name: "Muhammad Faizal",
      jobGrade: "M001/I-II",
      status: "Fulltime - Contract",
    },
    {
      id: 2,
      name: "Muhammad Habib",
      jobGrade: "M001/IV-VII",
      status: "Fulltime - Contract",
    },
    {
      id: 3,
      name: "Muhammad Abdan",
      jobGrade: "M001/V-VI",
      status: "Fulltime - Contract",
    },
    {
      id: 4,
      name: "Muhammad Elen",
      jobGrade: "M001/II-IV",
      status: "Fulltime - Contract",
    },
  ];

  const handleNext = () => {
    if (index === 5) {
      navigate("/payroll");
    } else {
      setIndex(index + 1);
    }
  };
  const handlePrev = () => {
    setIndex(index - 1);
  };
  const signOut = () => {
    logout();
    navigate("/login");
  };



  
 
  const [msg, setMsg] = useState("");
  const [showRule, setShowRule] = useState(false);

  


  const postData = async() => {
    setIsLoading(true);
    const err = [];
  
    if (err.length > 0) {
      setMsg(err);
    } else {
    const form = {
     
      language : selectLang,
      // language : "id",
      name : name,
      category : category,
      components : [
        {
            type:"BODY",
            text:textBody,
        },
       
        {
          type: "HEADER",
          ...(showHeader === "TEXT" && {
            format: "TEXT",
            text: textHeader,
          }),
          
        },
        
        {
            type:"FOOTER",
            text:textFooter,
        },
        {
            type:"BUTTONS",   
            buttons: inputFields.map((val) => {
              if(showhide==='PHONE_NUMBER'){
                return {
                  type: "PHONE_NUMBER",
                  text: val.text,
                  phone_number: val.phone_number,
                }

              }else if (showhide==='URL'){
                return {
                  type: "URL",
                  text: val.text,
                 
                }

              }else if (showhide==='QUICK_REPLAY'){
                return {
                  type: "QUICK_REPLY",
                  text: val.text,
                 
                }

              }
              
            

          }),
        }
      ]
     
    
    };
    // console.log(form, 'halo ya');
    await createTemplate(form)
      .then((response) => {
        // console.log(response);
        // sethidden(true);
        // setShowRule(false);
        setIsLoading(false);
        setTimeout(() => {
          navigate("/template");
        }, 1000);
       
      })
      .catch((e) => {
        // console.log(e.response);
        if (e.response) {
          setMsg(e.response.data.message);

          // console.log(e.response.data);
        }
      });
    }

  };



  
  
  const [stepp, setStepp] = useState(1);
  const [steppCom, setSteppCom] = useState(1);
  const [pop, setPop] = useState(false);
  const [lang, setLang] = useState();
 



  useEffect(() => {
    getLanguage().then((response) => {
        setLanguage(response.data.data);
        console.log(response.data.data, "adad");
      });
  }, [selectLang]);
 
  // const onSelectLanguage = async (e) => {
  //   // console.log(e.target.innerHTML, 'ajaka'
  //   setSelectLang(e.target.value);
   
  //   await getLanguage(e.target.value)
  //     .then((response) => {
  //       setLanguage(response.data.data);
  //     })
  //     .catch((e) => {});
  // };

  const onSelectLanguage = async (e) => {
    const selectedLang = language.find((lang) => lang.code === e.target.value);
    setSelectLang(e.target.value);
    setSelectedLang(selectedLang);
    await getLanguage(e.target.value)
      .then((response) => {
        setLanguage(response.data.data);
      })
      .catch((e) => {});
  };

  
  // useEffect(() => {
  //   if (selectLang) {
  //     getLanguage(selectLang)
  //       .then((response) => {
  //         setLanguage(response.data.data);
  //         console.log(response.data.data, "adad");
  //       })
  //       .catch((e) => {});
  //   }
  // }, [selectLang]);
  
  // const onSelectLanguage = (e) => {
  //   setSelectLang(e.target.value);
  // };

  return (
    <div className="bg-gradient-to-r from-[#54DAA1] to-[#2DCE89]">
      <div className="relative">
        <aside
          className={
            navSideBar === true
              ? "w-24 h-screen p-5 fixed top-0 left-0 right-0 z-10"
              : "w-3/12 h-screen p-5 fixed top-0 left-0 right-0 z-10"
          }
          aria-label="Sidebar"
        >
          <button
            className="absolute bottom-96 right-1 w-10 h-10 bg-gradient-to-r from-[#54DAA1] to-[#2DCE89] rounded-full shadow-lg flex justify-center items-center"
            onClick={() => setNavSideBar(!navSideBar)}
          >
            {navSideBar ? (
              <svg
                width="9"
                height="16"
                viewBox="0 0 9 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 14.3333L7.66667 7.66668L1 1.00001"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            ) : (
              <svg
                width="10"
                height="16"
                viewBox="0 0 10 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.33325 14.6667L1.66659 7.99999L8.33325 1.33332"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            )}
          </button>
          <SidebarComunication navSideBar={navSideBar} />
        </aside>
      </div>
      <div
        className={
          navSideBar === true
            ? "ml-24 h-72 w-11/12 p-5 space-y-5"
            : "ml-auto h-72 w-9/12 p-5 space-y-5"
        }
      >
        {/* Header */}
        <div className="flex flex-row justify-between items-center">
          <div className="text-white space-y-2">
            <div className="flex flex-row space-x-3">
              <p className="text-sm">Pages</p>
              <p className="text-sm">/</p>
              <a href="/communication" className="text-sm">
                Communication
              </a>
              <p className="text-sm">/</p>
              <a href="/template" className="text-sm">
                Template
              </a>
              <p className="text-sm">/</p>
              <a href="/View-Template" className="text-sm">
                View Template
              </a>
            </div>
            <p className="font-semibold">Create New Template</p>
           
          </div>
          <div className="flex flex-row items-center gap-5">
            <a href="#" className="flex items-center gap-2">
              <svg
                width="21"
                height="20"
                viewBox="0 0 21 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.4938 2.52235C12.7336 1.70165 11.6719 1.24969 10.5 1.24969C9.32191 1.24969 8.25668 1.69891 7.50004 2.51454C6.73519 3.33915 6.36254 4.45985 6.45004 5.67001C6.62348 8.05751 8.44027 9.99969 10.5 9.99969C12.5598 9.99969 14.3735 8.0579 14.5496 5.67079C14.6383 4.47157 14.2633 3.35321 13.4938 2.52235Z"
                  fill="white"
                />
                <path
                  d="M17.3752 18.7488H3.62522C3.44525 18.7511 3.26701 18.7133 3.10349 18.6381C2.93997 18.5629 2.79526 18.4522 2.67991 18.314C2.426 18.0105 2.32366 17.596 2.39944 17.1769C2.72913 15.348 3.75804 13.8117 5.37522 12.7332C6.81194 11.7757 8.63186 11.2488 10.5002 11.2488C12.3686 11.2488 14.1885 11.7761 15.6252 12.7332C17.2424 13.8113 18.2713 15.3476 18.601 17.1765C18.6768 17.5957 18.5744 18.0101 18.3205 18.3136C18.2052 18.4519 18.0605 18.5626 17.897 18.6379C17.7335 18.7132 17.5552 18.7511 17.3752 18.7488Z"
                  fill="white"
                />
              </svg>
              <p className="text-white">{localStorage.getItem("nameUser")}</p>
            </a>
            <button onClick={signOut} className="bg-white p-3 rounded-lg">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.5625 2.8125H2.1875V17.8125H6.5625M13.4375 5.9375L17.8125 10.3125L13.4375 14.6875M7.1875 10.3125H17.8125"
                  stroke="#EF4444"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
        </div>
        {/* start: Main */}
        <div className="bg-white shadow-lg rounded-lg p-6">
          <div className="flex flex-col">
            <div className="flex items-center gap-3">
              <a href="/template">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.11261 0.862514L0.862615 7.11251C0.748814 7.23139 0.659608 7.37157 0.600115 7.52501C0.475092 7.82934 0.475092 8.17069 0.600115 8.47501C0.659608 8.62845 0.748814 8.76863 0.862615 8.88751L7.11261 15.1375C7.22916 15.2541 7.36753 15.3465 7.5198 15.4096C7.67208 15.4727 7.83529 15.5051 8.00011 15.5051C8.33299 15.5051 8.65224 15.3729 8.88761 15.1375C9.12299 14.9021 9.25523 14.5829 9.25523 14.25C9.25523 13.9171 9.12299 13.5979 8.88761 13.3625L4.76261 9.25001H14.2501C14.5816 9.25001 14.8996 9.11832 15.134 8.8839C15.3684 8.64948 15.5001 8.33153 15.5001 8.00001C15.5001 7.66849 15.3684 7.35055 15.134 7.11613C14.8996 6.88171 14.5816 6.75001 14.2501 6.75001H4.76261L8.88761 2.63751C9.00478 2.52131 9.09777 2.38306 9.16123 2.23073C9.22469 2.07841 9.25736 1.91503 9.25736 1.75001C9.25736 1.585 9.22469 1.42162 9.16123 1.26929C9.09777 1.11697 9.00478 0.978718 8.88761 0.862514C8.77141 0.745353 8.63316 0.652359 8.48084 0.588898C8.32851 0.525437 8.16513 0.492765 8.00011 0.492765C7.8351 0.492765 7.67172 0.525437 7.51939 0.588898C7.36707 0.652359 7.22882 0.745353 7.11261 0.862514Z"
                    fill="#A1A1AA"
                  />
                </svg>
              </a>
              <h1 className="text-lg font-bold">Create New Template</h1>
            </div>
            <h1 className="text-gray-600 text-base ml-6">You can create your broadcast message template here.</h1>

          </div>

          
          {/**Steeper */}

          <div className="mx-auto md:1/2">
            <div className="container p-5 mt-5">
              <div className="p-4 flex justify-between items-center">
                
                <div className="w-full flex items-center">
                  <div className="relative flex flex-col items-center text-teal-600">
                    <div className={`rounded-full transition duration-500 ease-in-out border-2 border-green-300 h-12 w-12 flex items-center justify-center py-3  ${
                        stepp > 0
                          ? "bg-green-400 text-white font-bold border border-green-400 "
                          : ""
                      }`}
                    >
                      1
                    </div>
                   
                  </div>
                  <div className={`flex-auto border-t-2 transition duration-500 ease-in-out ${
                    stepp > 1
                    ?"border-green-400 border-2"
                    : "border-gray-300"
                  }`}></div>
                  <div className="relative flex flex-col items-center text-teal-600">
                    <div className={`rounded-full transition duration-500 ease-in-out border-2 border-green-300 h-12 w-12 flex items-center justify-center py-3  ${
                        stepp > 1
                          ? "bg-green-400 text-white font-bold border border-green-400 "
                          : ""
                      }`}
                    >
                      2
                    </div>
                   
                  </div>
                  <div className={`flex-auto border-t-2 transition duration-500 ease-in-out ${
                    stepp > 2
                    ?"border-green-400 border-2"
                    : "border-gray-300"
                  }`}></div>
                  <div className="relative flex flex-col items-center text-teal-600">
                    <div className={`rounded-full transition duration-500 ease-in-out border-2 border-green-300 h-12 w-12 flex items-center justify-center py-3  ${
                        stepp > 2
                          ? "bg-green-400 text-white font-bold border border-green-400 "
                          : ""
                      }`}
                    >
                      3
                    </div>
                   
                  </div>
                  <div className={`flex-auto border-t-2 transition duration-500 ease-in-out ${
                    stepp > 3
                    ?"border-green-400 border-2"
                    : "border-gray-300"
                  }`}></div>
                  <div className="relative flex flex-col items-center text-teal-600">
                    <div className={`rounded-full transition duration-500 ease-in-out border-2 border-green-300 h-12 w-12 flex items-center justify-center py-3  ${
                        stepp > 3
                          ? "bg-green-400 text-white font-bold border border-green-400 "
                          : ""
                      }`}
                    >
                     4
                    </div>
                   
                  </div>
                  
                </div>
              
              </div>
              
            </div>
            <div className="p-6">
               
               <div>
                  {stepp === 1 &&
                    <div className='px-2'>
                      <h1 className='text-black text-lg'>Do you already have a template before?</h1>
                      <div className='py-3'>          
                          <div class="flex items-center mb-4">
                              <input {...(haveTemplate == 1) ? {"checked": true} : {} } onChange={(e) => setHaveTemplate(e.target.value)} id="default-radio-2" type="radio" value={1} name="default-radio" class="w-4 h-4 text-green-600 bg-gray-100 border-gray-300 focus:ring-green-500 dark:focus:ring-green-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                              <label for="default-radio-2" class="ml-2 text-base text-gray-900 dark:text-gray-300">Yes, I already have a broadcast template</label>
                              
                          </div>
                          <div class="flex items-center">
                              <input {...(haveTemplate == 0) ? {"checked": true} : {} } onChange={(e) => setHaveTemplate(e.target.value)} id="default-radio-1" type="radio" value={0} name="default-radio" class="w-4 h-4 text-green-600 bg-gray-100 border-gray-300 focus:ring-green-500 dark:focus:ring-green-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                              <label for="default-radio-1" class="ml-2 text-base text-gray-900 dark:text-gray-300">No, I don't have any broadcast template before</label>
                          </div>
                      </div>
                      <div className="container flex justify-end mt-4 mb-8 gap-3">
                      
                        
                      <Link
                          to="/template"
                          className='cursor-pointer rounded-lg bg-gray-100 py-2 px-8 font-semibold capitalize text-gray-600 transition duration-200 ease-in-out hover:bg-gray-700 hover:text-white'
                        >
                          Back
                        </Link>
                      
                        <button
                          // onClick={() => handleClick("next")}
                          onClick={() => setStepp(stepp+1)}
                          className="cursor-pointer rounded-lg bg-green-500 py-2 px-8 font-semibold text-white transition duration-200 ease-in-out hover:bg-green-800 hover:text-white"
                        >
                          {/* {currentStep === steps.length ? "Submit" : "Save"} */}
                          Save
                        </button>
                      
                      </div>
                    </div>
                  }
                  {stepp === 2 &&
                    <div className='px-2'>
            
                        <div className='w-full'>
                            <div class="flex flex-col space-y-10">
                                <div>
                                    <label for="" className="block mb-2 text-base font-semibold text-gray-900 dark:text-white">WhatsApp Account</label>
                                    <select id="" placeholder='Select WhatsApp Account' className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                        <option selected>Select WhatsApp Account</option>
                                        <option value=""></option>
                                      
                                    </select>
                                </div>
                                <div>
                                    <div className='flex items-center gap-3 w-full'>
                                        <label for="" className="block mb-2 text-base font-semibold text-gray-900 dark:text-white">Message Name</label>
                                        <div className='relative'>
                                            <button type="button"  onClick={() => setPop(!pop)} >
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M11.95 18C12.3 18 12.596 17.879 12.838 17.637C13.0793 17.3957 13.2 17.1 13.2 16.75C13.2 16.4 13.0793 16.1043 12.838 15.863C12.596 15.621 12.3 15.5 11.95 15.5C11.6 15.5 11.304 15.621 11.062 15.863C10.8207 16.1043 10.7 16.4 10.7 16.75C10.7 17.1 10.8207 17.3957 11.062 17.637C11.304 17.879 11.6 18 11.95 18ZM11.05 14.15H12.9C12.9 13.6 12.9627 13.1667 13.088 12.85C13.2127 12.5333 13.5667 12.1 14.15 11.55C14.5833 11.1167 14.925 10.704 15.175 10.312C15.425 9.92067 15.55 9.45 15.55 8.9C15.55 7.96667 15.2083 7.25 14.525 6.75C13.8417 6.25 13.0333 6 12.1 6C11.15 6 10.3793 6.25 9.788 6.75C9.196 7.25 8.78333 7.85 8.55 8.55L10.2 9.2C10.2833 8.9 10.471 8.575 10.763 8.225C11.0543 7.875 11.5 7.7 12.1 7.7C12.6333 7.7 13.0333 7.84567 13.3 8.137C13.5667 8.429 13.7 8.75 13.7 9.1C13.7 9.43333 13.6 9.74567 13.4 10.037C13.2 10.329 12.95 10.6 12.65 10.85C11.9167 11.5 11.4667 11.9917 11.3 12.325C11.1333 12.6583 11.05 13.2667 11.05 14.15ZM12 22C10.6167 22 9.31667 21.7373 8.1 21.212C6.88333 20.6873 5.825 19.975 4.925 19.075C4.025 18.175 3.31267 17.1167 2.788 15.9C2.26267 14.6833 2 13.3833 2 12C2 10.6167 2.26267 9.31667 2.788 8.1C3.31267 6.88333 4.025 5.825 4.925 4.925C5.825 4.025 6.88333 3.31233 8.1 2.787C9.31667 2.26233 10.6167 2 12 2C13.3833 2 14.6833 2.26233 15.9 2.787C17.1167 3.31233 18.175 4.025 19.075 4.925C19.975 5.825 20.6873 6.88333 21.212 8.1C21.7373 9.31667 22 10.6167 22 12C22 13.3833 21.7373 14.6833 21.212 15.9C20.6873 17.1167 19.975 18.175 19.075 19.075C18.175 19.975 17.1167 20.6873 15.9 21.212C14.6833 21.7373 13.3833 22 12 22ZM12 20C14.2333 20 16.125 19.225 17.675 17.675C19.225 16.125 20 14.2333 20 12C20 9.76667 19.225 7.875 17.675 6.325C16.125 4.775 14.2333 4 12 4C9.76667 4 7.875 4.775 6.325 6.325C4.775 7.875 4 9.76667 4 12C4 14.2333 4.775 16.125 6.325 17.675C7.875 19.225 9.76667 20 12 20Z" fill="url(#paint0_linear_4921_6951)"/>
                                                    <defs>
                                                    <linearGradient id="paint0_linear_4921_6951" x1="22" y1="2" x2="-1.26901" y2="16.8922" gradientUnits="userSpaceOnUse">
                                                    <stop stop-color="#2DCECC"/>
                                                    <stop offset="0.661458" stop-color="#2DCE89"/>
                                                    </linearGradient>
                                                    </defs>
                                                </svg>
            
                                            </button>
                                            {pop && (
                                                <div className='absolute left-8 top-3'>
                                                    <div className='relative'>
                                                        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M14 1H2L12.6154 10.6923L14 13V1Z" fill="#284F5A"/>
                                                            <path d="M14 1H2L12.6154 10.6923L14 13V1Z" stroke="#284F5A"/>
                                                            <path d="M14 1H2L12.6154 10.6923L14 13V1Z" stroke="#284F5A" stroke-opacity="0.2"/>
                                                            <path d="M14 1H2L12.6154 10.6923L14 13V1Z" stroke="#284F5A" stroke-opacity="0.2"/>
                                                        </svg>
                                                        <div className='absolute -top-0 left-3'>
                                                            <div className='bg-[#284F5A] border-[#284F5A] w-96 p-4 rounded-b-xl rounded-tr-xl'>
                                                                <p className='text-white'>Message name can only include lowercase alphanumeric characters and underscores.</p>
                                                                <p className='text-white mt-5'>Example: <span className='font-bold'>shipping_update or shipping</span></p>
            
                                                            </div>
            
                                                        </div>
            
                                                    </div>
            
                                                </div>
                                            )}
            
                                        </div>
            
                                    </div>
            
                                    <input onChange={(e) => setName(e.target.value)}
                                    type="text" id="" className="bg-gray-50 lowercase border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Message Name example(template_name_01)"/>
                                </div>
                                <div>
                                    <div class="">
                                        <div class="grid lg:grid-cols-12 grid-cols-6 gap-4">
                                            <div className='lg:col-span-4 col-span-6'>
                                                <label class="cursor-pointer w-full">
                                                    <input type="radio" class="peer sr-only" 
                                                    label="Transactional" name='category'
                                                    value={'AUTHENTICATION'}
                                                    onChange={(e) => setCategory(e.target.value)}
                                                    />
                                                    <div class="w-full lg:h-40 h-full rounded-md bg-white p-6 text-gray-600 shadow-lg border-2 ring-transparent transition-all hover:shadow peer-checked:text-white peer-checked:shadow-green-400 peer-checked:bg-green-400 peer-checked:border-2">
                                                        <div class="flex items-start gap-2">
                                                            <div>
                                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M19.5 7.3125H16.3125V6.9375C16.3125 4.55625 14.3813 2.625 12 2.625C9.61875 2.625 7.6875 4.55625 7.6875 6.9375V7.3125H4.5C4.08516 7.3125 3.75 7.64766 3.75 8.0625V20.625C3.75 21.0398 4.08516 21.375 4.5 21.375H19.5C19.9148 21.375 20.25 21.0398 20.25 20.625V8.0625C20.25 7.64766 19.9148 7.3125 19.5 7.3125ZM9.375 6.9375C9.375 5.48672 10.5492 4.3125 12 4.3125C13.4508 4.3125 14.625 5.48672 14.625 6.9375V7.3125H9.375V6.9375ZM18.5625 19.6875H5.4375V9H7.6875V11.0625C7.6875 11.1656 7.77187 11.25 7.875 11.25H9.1875C9.29063 11.25 9.375 11.1656 9.375 11.0625V9H14.625V11.0625C14.625 11.1656 14.7094 11.25 14.8125 11.25H16.125C16.2281 11.25 16.3125 11.1656 16.3125 11.0625V9H18.5625V19.6875Z" fill="currentColor"/>
                                                                </svg>
            
                                                            </div>
                                                            <div class="flex flex-col gap-1">
                                                                <p class="text-lg font-semibold capitalize">Transactional</p>
                                                                <div className=''>
                                                                    <p>Send account updates, order updates, alerts and more to share important information</p>
                                                                </div>
                                                            </div>
                                                        
                                                        </div>
                                                    </div>
                                                </label>
            
                                            </div>
                                            <div className='lg:col-span-4 col-span-6'>
                                                <label class="cursor-pointer w-full">
                                                    <input type="radio" class="peer sr-only" name='category'
                                                    label="Marketing"
                                                    value={'MARKETING'}
                                                    onChange={(e) => setCategory(e.target.value)}
                                                    />
                                                    <div class="w-full lg:h-40 h-full rounded-md bg-white p-6 text-gray-600 shadow-lg border-2 ring-transparent transition-all hover:shadow peer-checked:text-white peer-checked:shadow-green-400 peer-checked:bg-green-400 peer-checked:border-2">
                                                        <div class="flex items-start gap-2">
                                                            <div>
                                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <g clip-path="url(#clip0_4921_6962)">
                                                                    <path d="M21.375 3.14999C21.1171 2.98317 20.8222 2.88224 20.5162 2.85609C20.2102 2.82993 19.9024 2.87935 19.62 2.99999L10.365 6.64499C10.1459 6.73417 9.91156 6.78002 9.675 6.77999H3.75C3.25272 6.77999 2.77581 6.97754 2.42417 7.32917C2.07254 7.6808 1.875 8.15771 1.875 8.65499V8.80499H0V13.305H1.875V13.5C1.88675 13.9894 2.08947 14.4549 2.43983 14.7968C2.7902 15.1388 3.26042 15.3301 3.75 15.33L6 20.1C6.15236 20.4212 6.39222 20.6929 6.69204 20.884C6.99187 21.075 7.33949 21.1776 7.695 21.18H8.64C9.13468 21.176 9.60775 20.9767 9.95614 20.6255C10.3045 20.2743 10.5 19.7997 10.5 19.305V15.51L19.62 19.155C19.8443 19.2443 20.0836 19.2901 20.325 19.29C20.6996 19.284 21.0643 19.1693 21.375 18.96C21.6217 18.7934 21.8252 18.5705 21.9687 18.3096C22.1121 18.0488 22.1914 17.7576 22.2 17.46V4.695C22.1986 4.38977 22.1228 4.08949 21.979 3.82025C21.8352 3.551 21.6279 3.32092 21.375 3.14999ZM8.625 8.65499V13.5H3.75V8.65499H8.625ZM8.625 19.305H7.68L5.835 15.33H8.625V19.305ZM11.055 13.725C10.8769 13.634 10.6912 13.5587 10.5 13.5V8.54999C10.6894 8.51092 10.875 8.45572 11.055 8.38499L20.325 4.695V17.415L11.055 13.725ZM22.245 9.18V12.93C22.7423 12.93 23.2192 12.7325 23.5708 12.3808C23.9225 12.0292 24.12 11.5523 24.12 11.055C24.12 10.5577 23.9225 10.0808 23.5708 9.72917C23.2192 9.37754 22.7423 9.18 22.245 9.18Z" fill="currentColor"/>
                                                                    </g>
                                                                    <defs>
                                                                    <clipPath id="clip0_4921_6962">
                                                                    <rect width="24" height="24" fill="currentColor"/>
                                                                    </clipPath>
                                                                    </defs>
                                                                </svg>
            
            
                                                            </div>
                                                            <div class="flex flex-col gap-1">
                                                                <p class="text-lg font-semibold capitalize">Marketing</p>
                                                                <div className=''>
                                                                    <p>Send promotional offers, product announcements and more to increase awareness and engagement</p>
                                                                </div>
                                                            </div>
                                                        
                                                        </div>
                                                    </div>
                                                </label>
                                            </div>
                                            <div className='lg:col-span-4 col-span-6'>
                                                <label class="cursor-pointer">
                                                    <input type="radio" class="peer sr-only" name='category' 
                                                      label="One-time Password"
                                                      value={'UTILITY'}
                                                      onChange={(e) => setCategory(e.target.value)}
                                                    />
                                                    <div class="w-full lg:h-40 h-full rounded-md bg-white p-6 text-gray-600 shadow-lg border-2 ring-transparent transition-all hover:shadow peer-checked:text-white peer-checked:shadow-green-400 peer-checked:bg-green-400 peer-checked:border-2">
                                                        <div class="flex items-start gap-2">
                                                            <div>
                                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M12 17C11.4696 17 10.9609 16.7893 10.5858 16.4142C10.2107 16.0391 10 15.5304 10 15C10 13.89 10.89 13 12 13C12.5304 13 13.0391 13.2107 13.4142 13.5858C13.7893 13.9609 14 14.4696 14 15C14 15.5304 13.7893 16.0391 13.4142 16.4142C13.0391 16.7893 12.5304 17 12 17ZM18 20V10H6V20H18ZM18 8C18.5304 8 19.0391 8.21071 19.4142 8.58579C19.7893 8.96086 20 9.46957 20 10V20C20 20.5304 19.7893 21.0391 19.4142 21.4142C19.0391 21.7893 18.5304 22 18 22H6C5.46957 22 4.96086 21.7893 4.58579 21.4142C4.21071 21.0391 4 20.5304 4 20V10C4 8.89 4.89 8 6 8H7V6C7 4.67392 7.52678 3.40215 8.46447 2.46447C9.40215 1.52678 10.6739 1 12 1C12.6566 1 13.3068 1.12933 13.9134 1.3806C14.52 1.63188 15.0712 2.00017 15.5355 2.46447C15.9998 2.92876 16.3681 3.47995 16.6194 4.08658C16.8707 4.69321 17 5.34339 17 6V8H18ZM12 3C11.2044 3 10.4413 3.31607 9.87868 3.87868C9.31607 4.44129 9 5.20435 9 6V8H15V6C15 5.20435 14.6839 4.44129 14.1213 3.87868C13.5587 3.31607 12.7956 3 12 3Z" fill="currentColor"/>
                                                                </svg>
            
            
                                                            </div>
                                                            <div class="flex flex-col gap-1">
                                                                <p class="text-lg font-semibold capitalize">One-time Password</p>
                                                                <div className=''>
                                                                    <p>Send codes that allow your customers to access their accounts</p>
                                                                </div>
                                                            </div>
                                                        
                                                        </div>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                
                                </div>
                                <div>
                                    <label for="" className="block mb-2 text-base font-semibold text-gray-900 dark:text-white">Languages</label>

                                    <select
                                      placeholder="Select Language"
                                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                      value={selectLang}
                                      onChange={onSelectLanguage}
                                    >
                                      <option hidden>Select Language</option>
                                      {language && language.map(function (value, index) {
                                        // console.log(value)
                                        return (
                                          <>
                                            <option key={index} value={value.code}>
                                              {value.country}
                                            </option>
                                          </>
                                        );
                                      })}
                                    </select>
                                    {/* <div className="relative">
                                        <Select
                                            options={options}
                                            isMulti
                                            name="colors"
                                            defaultValue={selectLang}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            onChange={(e) => setLanguage(e)}
                                        />
                                    </div>
                            */}
                                    
                                </div>
                            </div>
                              
                        </div>
            
                        <div className="container flex justify-end mt-4 mb-8 gap-3">
                        
                            <button
                                onClick={() => setStepp(stepp-1)}
                                className="cursor-pointer rounded-lg bg-gray-100 py-2 px-8 font-semibold capitalize text-gray-600 transition duration-200 ease-in-out hover:bg-gray-700 hover:text-white"
                            >
                                Back
                            </button>
                        
                        
                            <button
                              
                                onClick={() => setStepp(stepp+1)}
                                className="cursor-pointer rounded-lg bg-green-500 py-2 px-8 font-semibold text-white transition duration-200 ease-in-out hover:bg-green-800 hover:text-white"
                            >
                                
                                Save
                            </button>
                        
                        </div>
            
                    </div>
                  }
                  {stepp === 3 &&
                    <div className='px-2'>
           
                      <div className='w-full'>
                            <div class="flex flex-col space-y-10">
                               
                                <div className='grid lg:grid-cols-12 grid-cols-6 gap-3'>
                                  <div className='lg:col-span-8 col-span-6'>
                                    {/**Steeper */}
                                      <div className="w-full">
                                        <div className='grid grid-cols-12 gap-2'>
                                          <div className='lg:col-span-2 col-span-12'>
                                            <div className="container">
                                                <div className="flex lg:flex-col flex-row lg:pb-0 pb-10">
                                                  <div
                                                
                                                    className="w-full flex lg:flex-col flex-row lg:items-start items-center"
                                                  >
                                                    <div className="relative flex items-center text-teal-600">
                                                      <div
                                                        className={`rounded-full transition duration-500 ease-in-out border-2 border-green-300 h-5 w-5 flex items-center justify-center ${
                                                          steppCom > 0
                                                            ? "bg-green-400 text-white font-bold border border-green-200"
                                                            : ""
                                                        }`}
                                                        >
                                                        
                                                      </div>

                                                      <div
                                                        className={`lg:block hidden absolute -top-16  text-center mt-16 w-32 text-xs uppercase ${
                                                          steppCom > 0 ? "text-green-400 font-bold" : "text-gray-400 font-semibold"
                                                        }`}
                                                      >
                                                          Header
                                                         
                                                      </div>
                                                      <div
                                                        className={`lg:hidden block absolute top-0  text-center mt-10 -right-8 w-20 text-xs uppercase ${
                                                          steppCom > 0 ? "text-green-400 font-bold" : "text-gray-400 font-semibold"
                                                        }`}
                                                      >
                                                          Header
                                                         
                                                      </div>
                                                    </div>
                                                    <div
                                                      className={`lg:hidden block flex-auto lg:h-10 h-0 border-t-2 transition duration-500 ease-in-out  ${
                                                        steppCom > 0 ? "border-green-400" : "border-gray-300 "
                                                      }  `}
                                                    ></div>

                                                    <div className='px-2 lg:block hidden'>
                                                      <div
                                                        className={`flex-auto h-10 border-l-2 transition duration-500 ease-in-out first-letter:border-gray-300 border ${
                                                          steppCom > 0 ? "border-green-400" : "border-gray-300"
                                                        }  `}
                                                        
                                                      >
                                                      </div>

                                                    </div>
                                                  </div>

                                                  <div
                                                
                                                    className="w-full flex lg:flex-col flex-row lg:items-start items-center"
                                                  >
                                                    <div className="relative flex items-center text-teal-600">
                                                      <div
                                                        className={`rounded-full transition duration-500 ease-in-out border-2 border-green-300 h-5 w-5 flex items-center justify-center ${
                                                          steppCom > 1
                                                            ? "bg-green-400 text-white font-bold border border-green-200"
                                                            : ""
                                                        }`}
                                                        >
                                                        
                                                      </div>

                                                      <div
                                                        className={`lg:block hidden absolute -top-16  text-center mt-16 w-32 text-xs uppercase ${
                                                          steppCom > 1 ? "text-green-400 font-bold" : "text-gray-400 font-semibold"
                                                        }`}
                                                      >
                                                        
                                                          Boody
                                                          
                                                      </div>
                                                      <div
                                                        className={`lg:hidden block absolute top-0  text-center mt-10 -right-8 w-20 text-xs uppercase ${
                                                          steppCom > 1 ? "text-green-400 font-bold" : "text-gray-400 font-semibold"
                                                        }`}
                                                      >
                                                         
                                                          Boody
                                                          
                                                      </div>
                                                    </div>
                                                    <div
                                                      className={`lg:hidden block flex-auto lg:h-10 h-0 border-t-2 transition duration-500 ease-in-out  ${
                                                        steppCom > 1 ? "border-green-400" : "border-gray-300 "
                                                      }  `}
                                                    ></div>

                                                    <div className='px-2 lg:block hidden'>
                                                      <div
                                                        className={`flex-auto h-10 border-l-2 transition duration-500 ease-in-out first-letter:border-gray-300 border ${
                                                          steppCom > 1 ? "border-green-400" : "border-gray-300"
                                                        }  `}
                                                        
                                                      >
                                                      </div>

                                                    </div>
                                                  </div>

                                                  <div
                                                 
                                                    className="w-full flex lg:flex-col flex-row lg:items-start items-center"
                                                  >
                                                    <div className="relative flex items-center text-teal-600">
                                                      <div
                                                        className={`rounded-full transition duration-500 ease-in-out border-2 border-green-300 h-5 w-5 flex items-center justify-center ${
                                                          steppCom > 2
                                                            ? "bg-green-400 text-white font-bold border border-green-200"
                                                            : ""
                                                        }`}
                                                        >
                                                        
                                                      </div>

                                                      <div
                                                        className={`lg:block hidden absolute -top-16  text-center mt-16 w-32 text-xs uppercase ${
                                                          steppCom > 2 ? "text-green-400 font-bold" : "text-gray-400 font-semibold"
                                                        }`}
                                                      >
                                                        
                                                          Footer
                                                         
                                                      </div>
                                                      <div
                                                        className={`lg:hidden block absolute top-0  text-center mt-10 -right-8 w-20 text-xs uppercase ${
                                                          steppCom > 2 ? "text-green-400 font-bold" : "text-gray-400 font-semibold"
                                                        }`}
                                                      >
                                                      
                                                         
                                                          Footer
                                                         
                                                      </div>
                                                    </div>
                                                    <div
                                                      className={`lg:hidden block flex-auto lg:h-10 h-0 border-t-2 transition duration-500 ease-in-out  ${
                                                        steppCom > 2 ? "border-green-400" : "border-gray-300 "
                                                      }  `}
                                                    ></div>

                                                    <div className='px-2 lg:block hidden'>
                                                      <div
                                                        className={`flex-auto h-10 border-l-2 transition duration-500 ease-in-out first-letter:border-gray-300 border ${
                                                          steppCom > 2 ? "border-green-400" : "border-gray-300"
                                                        }  `}
                                                        
                                                      >
                                                      </div>

                                                    </div>
                                                  </div>

                                                  <div
                                                  
                                                    className="w-full flex lg:flex-col flex-row lg:items-start items-center"
                                                  >
                                                    <div className="relative flex items-center text-teal-600">
                                                      <div
                                                        className={`rounded-full transition duration-500 ease-in-out border-2 border-green-300 h-5 w-5 flex items-center justify-center ${
                                                          steppCom > 3
                                                            ? "bg-green-400 text-white font-bold border border-green-200"
                                                            : ""
                                                        }`}
                                                        >
                                                        
                                                      </div>

                                                      <div
                                                        className={`lg:block hidden absolute -top-16  text-center mt-16 w-32 text-xs uppercase ${
                                                          steppCom > 3 ? "text-green-400 font-bold" : "text-gray-400 font-semibold"
                                                        }`}
                                                      >
                                                        
                                                          Buttons
                                                      </div>
                                                      <div
                                                        className={`lg:hidden block absolute top-0  text-center mt-10 -right-8 w-20 text-xs uppercase ${
                                                          steppCom > 3 ? "text-green-400 font-bold" : "text-gray-400 font-semibold"
                                                        }`}
                                                      >
                                                     
                                                          Buttons
                                                      </div>
                                                    </div>
                                                    <div
                                                      className={`lg:hidden block flex-auto lg:h-10 h-0 border-t-2 transition duration-500 ease-in-out  ${
                                                        steppCom > 3 ? "border-green-400" : "border-gray-300 "
                                                      }  `}
                                                    ></div>

                                                    <div className='px-2 lg:block hidden'>
                                                      <div
                                                        className={`flex-auto h-10 border-l-2 transition duration-500 ease-in-out first-letter:border-gray-300 border ${
                                                          steppCom > 3 ? "border-green-400" : "border-gray-300"
                                                        }  `}
                                                        
                                                      >
                                                      </div>

                                                    </div>
                                                  </div>
                                                 
                                                </div>
                                            </div>
                                           
                                          </div>
                                          <div className='lg:col-span-10 col-span-12'>

                                            <div className="border-2 rounded-lg w-full p-5">
                                              <div>
                                               
                                                    {steppCom === 1 &&
                                                      <div className='flex flex-col space-y-5'>
                                                        <div className='flex flex-row justify-between'>
                                                          <h1 className='font-bold text-lg'>Header</h1>
                                                          <h1 className='font-semibold text-lg text-gray-400'>Optional</h1>
                                            
                                                        </div>
                                                        <div>
                                                            <label for="" className="block mb-2 text-sm font-normal text-gray-900 dark:text-white">Add a title or choose which type of media you'll use for this header</label>
                                                            <select  
                                                            // onChange={(e) => setHeaderContent(e.target.value)}
                                                              onChange={(e)=>(handleshowheader(e))}
                                                              id="" placeholder='Text' className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                                               
                                                                <option value="TEXT">Text</option>
                                                                
                                                            </select>
                                                            {/* <input value={'HEADER'} onChange={(e) => setTypeHeader(e.target.value)} hidden/> */}
                                                        </div>
                                                        {showHeader === 'TEXT' && (
                                                          <div>
                                                            <label for="message" class="block mb-2 text-sm font-normal text-gray-900 dark:text-white">Header Text</label>
                                                            <textarea id="message" rows="4" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder=""
                                                            onChange={(e) => setTextHeader(e.target.value)}></textarea>
                                                          </div>

                                                        )}
                                                        <div class="flex gap-5 justify-end mt-5">
                                                          <button  className="text-green-400">
                                                                  + Add Variable
                                                          </button>
                                                        </div>
                                                        <div className="container flex justify-end  mt-4 gap-3">
                                                          {/* <button
                                                            
                                                            className='cursor-pointer rounded-lg bg-gray-100 py-2 px-8 font-semibold capitalize text-gray-600 transition duration-200 ease-in-out hover:bg-gray-700 hover:text-white '
                                                          >
                                                            Back
                                                          </button> */}
                                                          
                                                          <button
                                                            // onClick={() => handleClick("next")}
                                                            onClick={() => setSteppCom(steppCom+1)}
                                                            className="cursor-pointer rounded-lg bg-green-500 py-2 px-8 font-semibold text-white transition duration-200 ease-in-out hover:bg-green-800 hover:text-white"
                                                          >
                                                            Next
                                                          </button>
                                                          
                                                        </div>
                                                      
                                                        
                                                      </div>
                                                    }
                                                    {steppCom === 2 &&
                                                      <div className='flex flex-col space-y-5'>
                                                        <div className='flex flex-row'>
                                                          <h1 className='font-bold text-lg'>Body</h1>

                                                        {/* <input value={'BODY'} onChange={(e) => setTypeBody(e.target.value)} hidden/> */}
                                            
                                                        </div>
                                                        <div>
                                                          <label for="message" class="block mb-2 text-sm font-normal text-gray-900 dark:text-white">Enter the text for your message in the language you've selected</label>
                                                          <div className='bg-gray-50 rounded-t-xl'>
                                                            <textarea onChange={(e) => setTextBody(e.target.value)} id="message" rows="10" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-t-xl border-gray-50 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white" placeholder="HALLO SELAMAT PAGI {{1}} "></textarea>
                                                            <div className='flex gap-4 items-center p-3'>
                                                              <button  className="text-black">
                                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                  <path d="M7 19V5H12.5C13.6333 5 14.625 5.34167 15.475 6.025C16.325 6.70833 16.75 7.58333 16.75 8.65C16.75 9.28333 16.5833 9.87067 16.25 10.412C15.9167 10.954 15.4667 11.3667 14.9 11.65V11.85C15.6 12.0833 16.1667 12.4957 16.6 13.087C17.0333 13.679 17.25 14.35 17.25 15.1C17.25 16.25 16.7877 17.1877 15.863 17.913C14.9377 18.6377 13.8667 19 12.65 19H7ZM9.65 10.7H12.3C12.8 10.7 13.2417 10.5417 13.625 10.225C14.0083 9.90833 14.2 9.50833 14.2 9.025C14.2 8.54167 14.0083 8.14167 13.625 7.825C13.2417 7.50833 12.8 7.35 12.3 7.35H9.65V10.7ZM9.65 16.6H12.5C13.0667 16.6 13.5583 16.429 13.975 16.087C14.3917 15.7457 14.6 15.3083 14.6 14.775C14.6 14.2417 14.3917 13.804 13.975 13.462C13.5583 13.1207 13.0667 12.95 12.5 12.95H9.65V16.6Z" fill="#1A424F"/>
                                                                </svg>
                                            
                                                              </button>
                                                              <button  className="text-black">
                                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                  <path d="M10 5V8H12.21L8.79 16H6V19H14V16H11.79L15.21 8H18V5H10Z" fill="#1A424F"/>
                                                                </svg>
                                            
                                                              </button>
                                                              <button  className="text-black">
                                                                <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                  <path d="M11.59 4.52C11.59 4.21 11.54 3.93 11.44 3.67C11.35 3.4 11.2 3.18 11 2.99C10.8 2.8 10.55 2.66 10.25 2.55C9.95 2.45 9.59 2.39 9.19 2.39C8.8 2.39 8.45 2.43 8.16 2.52C7.87 2.61 7.63 2.73 7.44 2.88C7.25 3.04 7.1 3.22 7 3.43C6.9 3.64 6.85 3.86 6.85 4.09C6.85 4.57 7.1 4.97 7.59 5.3C7.97 5.55 8.36 5.78 9 6H4.39C4.34 5.92 4.28 5.83 4.24 5.75C3.98 5.27 3.85 4.72 3.85 4.08C3.85 3.47 3.98 2.92 4.25 2.41C4.51 1.91 4.88 1.48 5.36 1.12C5.87677 0.751054 6.45126 0.470565 7.06 0.29C7.72 0.1 8.45 0 9.24 0C10.05 0 10.78 0.11 11.45 0.34C12.11 0.56 12.68 0.88 13.14 1.28C13.61 1.68 13.97 2.16 14.22 2.71C14.47 3.26 14.6 3.86 14.6 4.52H11.59ZM17 7H1C0.45 7 0 7.45 0 8C0 8.55 0.45 9 1 9H9.62C9.8 9.07 10.02 9.14 10.17 9.2C10.54 9.37 10.83 9.54 11.04 9.71C11.25 9.88 11.39 10.07 11.47 10.28C11.54 10.48 11.58 10.71 11.58 10.97C11.58 11.2 11.53 11.42 11.44 11.63C11.35 11.83 11.21 12.01 11.02 12.16C10.83 12.31 10.6 12.42 10.31 12.51C10.02 12.59 9.68 12.64 9.3 12.64C8.87 12.64 8.47 12.6 8.12 12.51C7.77 12.42 7.46 12.28 7.21 12.09C6.96 11.9 6.76 11.65 6.62 11.34C6.48 11.03 6.37 10.58 6.37 10.13H3.4C3.4 10.68 3.48 11.26 3.64 11.71C3.8 12.16 4.01 12.56 4.29 12.92C4.57 13.27 4.89 13.58 5.27 13.84C5.64 14.1 6.05 14.32 6.49 14.49C6.93 14.66 7.39 14.79 7.87 14.88C8.35 14.96 8.83 15.01 9.31 15.01C10.11 15.01 10.84 14.92 11.49 14.73C12.14 14.54 12.7 14.28 13.16 13.94C13.62 13.6 13.98 13.17 14.23 12.67C14.48 12.17 14.61 11.6 14.61 10.96C14.61 10.36 14.51 9.82 14.3 9.35C14.25 9.24 14.19 9.12 14.13 9.02H17C17.55 9.02 18 8.57 18 8.02V8C18 7.45 17.55 7 17 7Z" fill="#1A424F"/>
                                                                </svg>
                                            
                                                              </button>
                                                              <button  className="text-black">
                                                                <svg width="26" height="13" viewBox="0 0 26 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                  <path d="M8.625 10.6094L0.71875 7.10156V5.75781L8.625 1.80469V4.125L3.77344 6.33594L8.625 8.30469V10.6094ZM16.4141 0.414062L12.1562 12.1562H9.45312L13.7109 0.414062H16.4141ZM17.1719 8.30469L22.0234 6.33594L17.1719 4.125V1.80469L25.0781 5.75781V7.10156L17.1719 10.6094V8.30469Z" fill="#1A424F"/>
                                                                </svg>
                                            
                                                              </button>
                                                              <button  className="text-black">
                                                                    + Add Variable
                                                              </button>
                                            
                                                            </div>
                                            
                                                          </div>
                                                        
                                                        </div>
                                                        <div className="container flex justify-between mt-4 gap-3">
                                                          <button
                                                            onClick={() => setSteppCom(steppCom-1)}
                                                            className='cursor-pointer rounded-lg bg-gray-100 py-2 px-8 font-semibold capitalize text-gray-600 transition duration-200 ease-in-out hover:bg-gray-700 hover:text-white '
                                                          >
                                                            Back
                                                          </button>
                                                          
                                                          <button
                                                            // onClick={() => handleClick("next")}
                                                            onClick={() => setSteppCom(steppCom+1)}
                                                            className="cursor-pointer rounded-lg bg-green-500 py-2 px-8 font-semibold text-white transition duration-200 ease-in-out hover:bg-green-800 hover:text-white"
                                                          >
                                                            Next
                                                          </button>
                                                          
                                                        </div>
                                                      </div>
    
                                                  
                                                    }
                                                    {steppCom === 3 &&
                                                      <div className='flex flex-col space-y-5'>
                                                        <div className='flex flex-row justify-between'>
                                                          <h1 className='font-bold text-lg'>Footer</h1>
                                                          <h1 className='font-semibold text-lg text-gray-400'>Optional</h1>
                                                          {/* <input value={'FOOTER'} onChange={(e) => setTypeBody(e.target.value)} hidden/> */}
                                            
                                                        </div>

                                                        <div>
                                                          <label for="message" class="block mb-2 text-sm font-normal text-gray-900 dark:text-white">Add a short line of text to the bottom of your message template</label>
                                                          <textarea onChange={(e) => setTextFooter(e.target.value)} id="message" rows="10" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Write your thoughts here... "></textarea>
                                                        
                                                        </div>
                                                        <div className="container flex justify-between mt-4 gap-3">
                                                          <button
                                                            onClick={() => setSteppCom(steppCom-1)}
                                                            className='cursor-pointer rounded-lg bg-gray-100 py-2 px-8 font-semibold capitalize text-gray-600 transition duration-200 ease-in-out hover:bg-gray-700 hover:text-white '
                                                          >
                                                            Back
                                                          </button>
                                                          
                                                          <button
                                                            // onClick={() => handleClick("next")}
                                                            onClick={() => setSteppCom(steppCom+1)}
                                                            className="cursor-pointer rounded-lg bg-green-500 py-2 px-8 font-semibold text-white transition duration-200 ease-in-out hover:bg-green-800 hover:text-white"
                                                          >
                                                            Next
                                                          </button>
                                                          
                                                        </div>
                                                      </div>
    
                                                    }
                                                    {steppCom === 4 &&
    
                                                      <div className='flex flex-col space-y-5'>
                                                        <div className='flex flex-row justify-between'>
                                                          <h1 className='font-bold text-lg'>Buttons</h1>
                                                          <h1 className='font-semibold text-lg text-gray-400'>Optional</h1>
                                                          {/* <input value={'BUTTONS'} onChange={(e) => setTypeButtons(e.target.value)} hidden/> */}
    
                                                        </div>
                                                        <div>
                                                            <label for="" className="block mb-2 text-sm font-normal text-gray-900 dark:text-white">Create buttons that let customers respond to your message or take action</label>
                                                            <select  onChange={(e)=>(handleshowhide(e))}
                                                            id="" placeholder='Quick Reply' className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                                                {/* <option >Quick Reply</option> */}
                                                                <option value="QUICK_REPLAY">QUICK_REPLAY</option>
                                                                <option value="URL">URL</option>
                                                                <option value="PHONE_NUMBER">PHONE_NUMBER</option>
                                                                
                                                            </select>
                                                        </div>
                                                        <div>
                                                          {/* <div className=''>
                                                            <label for="message" class="block mb-2 text-sm font-normal text-gray-900 dark:text-white">Buttons Text</label>
                                                          
                                                            
                                                            
                                                          </div> */}


                                                            {showhide==='QUICK_REPLAY' && (
                                                              <>
                                                                  {inputFields.map((input, index) => {
                                                                      return (
                                                                      <div key={index}>
                                                                      
                                                                          <div>
                                                                              <div className='grid grid-cols-12 flex items-center gap-3 mb-5'>
                                                                                {/* <input value={'PHONE_NUMBER'} onChange={event => handleFormChange(index, event)} hidden/> */}
                                                                                <div className='col-span-11'>
                                                                                  <input
                                                                                    
                                                                                    name="text"
                                                                                    value={input.text || ""}
                                                                                  onChange={(e) => handleFormChange(index, e)} class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Quic Replay"/>
                                                                                </div>
                                                                               
                                                                                <div className='col-span-1'>
                                                                                
                                                                                {index ? (
                                                                                      <>
                                                                                      <button type="button"  className="text-red-500" onClick={removeFields}>
                                                                                        <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                        <path d="M5.4 13.5L8 10.9L10.6 13.5L12 12.1L9.4 9.5L12 6.9L10.6 5.5L8 8.1L5.4 5.5L4 6.9L6.6 9.5L4 12.1L5.4 13.5ZM3 18C2.45 18 1.97933 17.8043 1.588 17.413C1.196 17.021 1 16.55 1 16V3H0V1H5V0H11V1H16V3H15V16C15 16.55 14.8043 17.021 14.413 17.413C14.021 17.8043 13.55 18 13 18H3ZM13 3H3V16H13V3ZM3 3V16V3Z" fill="#EF4444"/>
                                                                                        </svg>

                                                                                      </button>
                                                                                      </>
                                                                                  ) : null}
                                                                                      
                                                                                  
                                                          
                                                                                </div>
                                                                                
                                                                              </div>
                                                                            

                                                                            </div>
                                                                      </div>
                                                                      )
                                                                  })}
                                                                  <div className='text-right'>
                                                                              
                                                                      <button onClick={() => addFields()} className="text-green-400" type="button">
                                                                        + Add Another Button
                                                                      </button>
                                                                    

                                                                    </div>
                                                              </>
                                                            )}  
                                                          {
                                                             showhide==='URL' && (
                                                              <>
                                                                  {inputFields.map((input, index) => {
                                                                      return (
                                                                      <div key={index}>
                                                                      
                                                                          <div>
                                                                              <div className='grid grid-cols-12 flex items-center gap-3 mb-5'>
                                                                                {/* <input value={'PHONE_NUMBER'} onChange={event => handleFormChange(index, event)} hidden/> */}
                                                                                <div className='col-span-11'>
                                                                                  <input
                                                                                    
                                                                                    name="url"
                                                                                    value={input.url || ""}
                                                                                  onChange={(e) => handleFormChange(index, e)} class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder=""/>
                                                                                </div>
                                                                                
                                                                                <div className='col-span-1'>
                                                                                
                                                                                {index ? (
                                                                                      <>
                                                                                      <button type="button"  className="text-red-500" onClick={removeFields}>
                                                                                        <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                        <path d="M5.4 13.5L8 10.9L10.6 13.5L12 12.1L9.4 9.5L12 6.9L10.6 5.5L8 8.1L5.4 5.5L4 6.9L6.6 9.5L4 12.1L5.4 13.5ZM3 18C2.45 18 1.97933 17.8043 1.588 17.413C1.196 17.021 1 16.55 1 16V3H0V1H5V0H11V1H16V3H15V16C15 16.55 14.8043 17.021 14.413 17.413C14.021 17.8043 13.55 18 13 18H3ZM13 3H3V16H13V3ZM3 3V16V3Z" fill="#EF4444"/>
                                                                                        </svg>

                                                                                      </button>
                                                                                      </>
                                                                                  ) : null}
                                                                                      
                                                                                  
                                                          
                                                                                </div>
                                                                                
                                                                              </div>
                                                                            

                                                                            </div>
                                                                      </div>
                                                                      )
                                                                  })}
                                                                  <div className='text-right'>
                                                                              
                                                                      <button onClick={() => addFields()} className="text-green-400" type="button">
                                                                        + Add Another Button
                                                                      </button>
                                                                    

                                                                    </div>
                                                              </>
                                                            )}   

                                                          {
                                                             showhide==='PHONE_NUMBER' && (
                                                              <>
                                                                  {inputFields.map((input, index) => {
                                                                      return (
                                                                      <div key={index}>
                                                                      
                                                                          <div>
                                                                              <div className='grid grid-cols-12 flex items-center gap-3 mb-5'>
                                                                                {/* <input value={'PHONE_NUMBER'} onChange={event => handleFormChange(index, event)} hidden/> */}
                                                                                <div className='col-span-11'>
                                                                                  <input
                                                                                    
                                                                                    name="text"
                                                                                    value={input.text || ""}
                                                                                  onChange={(e) => handleFormChange(index, e)} class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder=""/>
                                                                                </div>
                                                                                <div className='col-span-11'>
                                                                                  <input  
                                                                                    name="phone_number"
                                                                                  value={input.phone_number || ""}
                                                                                  onChange={(e) => handleFormChange(index, e)} class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="089000333888"/>
                                                                                </div>
                                                                                <div className='col-span-1'>
                                                                                
                                                                                {index ? (
                                                                                      <>
                                                                                      <button type="button"  className="text-red-500" onClick={removeFields}>
                                                                                        <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                        <path d="M5.4 13.5L8 10.9L10.6 13.5L12 12.1L9.4 9.5L12 6.9L10.6 5.5L8 8.1L5.4 5.5L4 6.9L6.6 9.5L4 12.1L5.4 13.5ZM3 18C2.45 18 1.97933 17.8043 1.588 17.413C1.196 17.021 1 16.55 1 16V3H0V1H5V0H11V1H16V3H15V16C15 16.55 14.8043 17.021 14.413 17.413C14.021 17.8043 13.55 18 13 18H3ZM13 3H3V16H13V3ZM3 3V16V3Z" fill="#EF4444"/>
                                                                                        </svg>

                                                                                      </button>
                                                                                      </>
                                                                                  ) : null}
                                                                                      
                                                                                  
                                                          
                                                                                </div>
                                                                                
                                                                              </div>
                                                                            

                                                                            </div>
                                                                      </div>
                                                                      )
                                                                  })}
                                                                  <div className='text-right'>
                                                                              
                                                                      <button onClick={() => addFields()} className="text-green-400" type="button">
                                                                        + Add Another Button
                                                                      </button>
                                                                    

                                                                    </div>
                                                              </>
                                                            )}  
    
                                                        </div>
    
                                                        <div className="container flex justify-between mt-4 gap-3">
                                                          <button
                                                            onClick={() => setSteppCom(steppCom-1)}
                                                            className='cursor-pointer rounded-lg bg-gray-100 py-2 px-8 font-semibold capitalize text-gray-600 transition duration-200 ease-in-out hover:bg-gray-700 hover:text-white '
                                                          >
                                                            Back
                                                          </button>
                                                          
                                                          <button
                                                            // onClick={() => handleClick("next")}
                                                            // onClick={() => setCurrentStep(3)}
                                                            className="cursor-pointer rounded-lg bg-green-500 py-2 px-8 font-semibold text-white transition duration-200 ease-in-out hover:bg-green-800 hover:text-white"
                                                          >
                                                            Submit
                                                          </button>
                                                          
                                                        </div>
    
                                                      
                                                      </div>
    
                                                    }
                                                  

                                              
                                                
                                              
                                              
                                              </div>
                                              
                                              
                                          
                                            </div>
                                          </div>

                                        </div>

                                      </div>
                                      {/**Steeper */}

                                  
                                  </div>
                                  <div className='lg:col-span-4 col-span-6'>
                                  
                                    <div>
                                      {steppCom === 1 &&
                                        <div className='w-full rounded-3xl bg-gray-100 p-10 space-y-3'>
                                          <div className='flex flex-col items-center space-y-10'>
                                            <h1 className='font-bold text-lg'>PREVIEW</h1>
                                            <div className='bg-white rounded-xl w-full h-52'>
                                              <div className='p-3 flex flex-col justify-between h-full'>
                                                <div className='overflow-auto'>
                                                  <div>{textHeader}</div>
                              
                                                </div>
                                                <div className='text-right'>
                                                  <h1>5:20 PM</h1>
                                                </div>
                                              </div>
                              
                                            </div>
                                          </div>
                                       
                                        </div>
                                      }
                                      {steppCom === 2 &&
                                        <div className='w-full rounded-3xl bg-gray-100 p-10 space-y-3'>
                                          <div className='flex flex-col items-center space-y-10'>
                                            <h1 className='font-bold text-lg'>PREVIEW</h1>
                                            <div className='bg-white rounded-xl w-full h-52'>
                                              <div className='p-3 flex flex-col justify-between h-full'>
                                                <div className='overflow-auto'>
                                                  <div>{textBody}</div>
                                                </div>
                                                <div className='text-right'>
                                                  <h1>5:20 PM</h1>
                                                </div>
                                              </div>
                              
                                            </div>
                                          </div>
                                      
                             
                                        </div>
                                        
                                      }
                                      {steppCom === 3 &&
                                        <div className='w-full rounded-3xl bg-gray-100 p-10 space-y-3'>
                                          <div className='flex flex-col items-center space-y-10'>
                                            <h1 className='font-bold text-lg'>PREVIEW</h1>
                                            <div className='bg-white rounded-xl w-full h-60'>
                                              <div className='p-3 flex flex-col justify-between'>
                                                <div className='flex flex-col space-y-2 px-3 h-52'>
                                                  <div className='overflow-auto'>
                                                    <div>{textBody}</div>
                                                    <div className='text-gray-500 text-base'>{textFooter}</div>
                                                  </div>
                            
                                                </div>
                                                <div className='text-right'>
                                                  <h1>5:20 PM</h1>
                                                </div>
                                              </div>
                              
                                            </div>
                                          </div>
                                        
                             
                                        </div>

                                      }
                                      {steppCom === 4 &&

                                        <div className='w-full rounded-3xl bg-gray-100 p-10 space-y-3'>
                                          <div className='flex flex-col items-center space-y-10'>
                                            <h1 className='font-bold text-lg'>PREVIEW</h1>
                                            <div className='bg-white rounded-xl w-full h-60'>
                                              <div className='p-3 flex flex-col justify-between'>
                                                <div className='flex flex-col space-y-2 px-3 h-52'>
                                                  <div className='overflow-auto'>
                                                    <div>{textBody}</div>
                                                    <div className='text-gray-500 text-base'>{textFooter}</div>
                                                  </div>

                                                </div>
                                                <div className='text-right'>
                                                  <h1>5:20 PM</h1>
                                                </div>
                                              </div>

                                            </div>
                                          </div>
                                          <div className='flex flex-col space-y-3'>
                                          {
                                            showhide==='QUICK_REPLAY' && (
                                              <>
                                                {inputFields.map((input, index) => {
                                                    return (
                                                    <div key={index} className='bg-white w-full h-12 rounded-lg flex items-center justify-center uppercase font-bold text-base'>{input.text}</div>
                                                    )
                                                })}
                                              </>

                                          )} 
                                          {
                                            showhide==='URL' && (
                                              <>
                                                {inputFields.map((input, index) => {
                                                    return (
                                                    <div key={index} className='bg-white w-full h-12 rounded-lg flex items-center justify-center uppercase font-bold text-base'>{input.url}</div>
                                                    )
                                                })}
                                              </>

                                          )}  

                                          {
                                            showhide==='PHONE_NUMBER' && (
                                              <>
                                                {inputFields.map((input, index) => {
                                                    return (
                                                      <>
                                                        <div key={index} className='bg-white w-full h-12 rounded-lg flex items-center justify-center uppercase font-bold text-base'>{input.text}</div>
                                                        <div key={index} className='bg-white w-full h-12 rounded-lg flex items-center justify-center uppercase font-bold text-base'>{input.phone_number}</div>
                                                      </>
                                                    )
                                                })}
                                              </>

                                          )}   
                                           

                                          </div>

                                        </div>

                                      }
                                    
                                    </div>

                                  </div>

                                </div>
                                
                            </div>
                              
                      </div>
          
                      <div className="container flex justify-end mt-4 mb-8 gap-3">
                        
                          <button
                              onClick={() => setStepp(stepp-1)}
                              className="cursor-pointer rounded-lg bg-gray-100 py-2 px-8 font-semibold capitalize text-gray-600 transition duration-200 ease-in-out hover:bg-gray-700 hover:text-white"
                            >
                              Back
                          </button>
                      
                      
                          <button
                              
                            
                              onClick={() => setStepp(stepp+1)} 
                              className="cursor-pointer rounded-lg bg-green-500 py-2 px-8 font-semibold text-white transition duration-200 ease-in-out hover:bg-green-800 hover:text-white"
                            >
                              
                              Save
                          </button>
                      
                      </div>
            
                    </div>
                  }

                  {stepp === 4 &&
                    <div className='px-2'>
                      <div className='flex flex-col space-y-3'>
                        <div className='grid grid-cols-12'>
                          <div className='col-span-4'>
                
                            <h1 className='font-bold'>WhatsApp Account</h1>
                
                          </div>
                          <div className='col-span-8'>
                
                            <h1 className='font-semibold text-gray-400'>: PT. Powerkerto Wahyu Keprabon</h1>
                
                          </div>
                
                        </div>
                        <div className='grid grid-cols-12'>
                          <div className='col-span-4'>
                
                            <h1 className='font-bold'>Channel ID</h1>
                
                          </div>
                          <div className='col-span-8'>
                
                            <h1 className='font-semibold text-gray-400'>: 3548</h1>
                
                          </div>
                
                        </div>
                        <div className='grid grid-cols-12'>
                          <div className='col-span-4'>
                
                            <h1 className='font-bold'>Template Name</h1>
                
                          </div>
                          <div className='col-span-8'>
                
                            <h1 className='font-semibold text-gray-400'>: {name}</h1>
                
                          </div>
                
                        </div>
                        <div className='grid grid-cols-12'>
                          <div className='col-span-4'>
                
                            <h1 className='font-bold'>Namespace</h1>
                
                          </div>
                          <div className='col-span-8'>
                
                            <h1 className='font-semibold text-gray-400'>: -</h1>
                
                          </div>
                
                        </div>
                        <div className='grid grid-cols-12'>
                          <div className='col-span-4'>
                
                            <h1 className='font-bold'>Category</h1>
                
                          </div>
                          <div className='col-span-8'>
                
                            <h1 className='font-semibold text-gray-400'>: {category}</h1>
                
                          </div>
                
                        </div>
                        <div className='flex flex-col space-y-5'>
                          <div className=''>
                
                            <h1 className='font-bold'>Language</h1>
                
                          </div>
                          <div className='flex gap-3'>
                           
                            {/* <div className='bg-gray-100 rounded-xl text-gray-700 px-5 py-3'>
                              English
                
                            </div> */}

                            <div className="bg-gradient-to-tr from-green-400 to-[#2DCECC] px-5 py-3 rounded-xl text-white">
                                  {selectedLang.country}
                            </div>
                          </div>
                          <div className='w-full border-2 rounded-xl border-green-400 p-6'>
                            <div className='flex flex-col space-y-8'>
                              <div className='flex flex-col space-y-4'>
                                <h1 className='font-bold text-lg'>Header</h1>
                                {showHeader === 'TEXT' && (
                                  <div className='flex items-center text-gray-400'>
                                   {textHeader}
                                 </div>

                                )}

                           
                                {/* <div className='flex space-x-60'>
                                  <div className='flex items-center gap-3 text-gray-400'>
                                    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M21.75 17.75V5.75C21.75 5.35218 21.592 4.97064 21.3107 4.68934C21.0294 4.40804 20.6478 4.25 20.25 4.25H3.75C3.35218 4.25 2.97064 4.40804 2.68934 4.68934C2.40804 4.97064 2.25 5.35218 2.25 5.75V19.25C2.25 19.6478 2.40804 20.0294 2.68934 20.3107C2.97064 20.592 3.35218 20.75 3.75 20.75H20.25C20.6478 20.75 21.0294 20.592 21.3107 20.3107C21.592 20.0294 21.75 19.6478 21.75 19.25V17.75ZM20.25 5.75V15.9406L17.5594 13.25C17.277 12.9717 16.8964 12.8158 16.5 12.8158C16.1036 12.8158 15.723 12.9717 15.4406 13.25L13.5 15.1906L9.30938 11C9.02698 10.7217 8.64645 10.5658 8.25 10.5658C7.85355 10.5658 7.47302 10.7217 7.19062 11L3.75 14.4406V5.75H20.25ZM20.25 19.25H3.75V16.5594L8.25 12.0594L12.4406 16.25C12.5792 16.39 12.7442 16.5012 12.9261 16.577C13.1079 16.6529 13.303 16.692 13.5 16.692C13.697 16.692 13.8921 16.6529 14.0739 16.577C14.2558 16.5012 14.4208 16.39 14.5594 16.25L16.5 14.3094L20.25 18.0594V19.25ZM13.8281 10.6719C13.7233 10.5674 13.6403 10.4432 13.584 10.3064C13.5277 10.1696 13.4991 10.0229 13.5 9.875C13.5 9.57663 13.6185 9.29048 13.8295 9.0795C14.0405 8.86853 14.3266 8.75 14.625 8.75C14.9234 8.75 15.2095 8.86853 15.4205 9.0795C15.6315 9.29048 15.75 9.57663 15.75 9.875C15.75 10.1734 15.6315 10.4595 15.4205 10.6705C15.2095 10.8815 14.9234 11 14.625 11C14.327 10.9977 14.0414 10.8801 13.8281 10.6719Z" fill="url(#paint0_linear_4925_7337)"/>
                                      <defs>
                                      <linearGradient id="paint0_linear_4925_7337" x1="21.75" y1="4.25" x2="1.4076" y2="19.6363" gradientUnits="userSpaceOnUse">
                                      <stop stop-color="#54DAA1"/>
                                      <stop offset="1" stop-color="#2DCE89"/>
                                      </linearGradient>
                                      </defs>
                                    </svg>
                
                                    Image
                
                                  </div>
                                  <div className=''>
                                    <button className='text-green-400 hover:text-green-700'>Copy media link</button>
                                  
                
                                  </div>
                
                                </div> */}
                
                              </div>
                              <div className='flex flex-col space-y-4'>
                                <h1 className='font-bold text-lg'>Content</h1>
                                <div className='flex space-x-60'>
                                  <div className='flex items-center text-gray-400'>
                                    {textBody}
                                  </div>
                              
                                </div>
                
                              </div>
                              <div className='flex flex-col space-y-4'>
                                <h1 className='font-bold text-lg'>Footer</h1>
                                <div className='flex space-x-60'>
                                  <div className='flex items-center text-gray-400'>
                                    {textFooter}
                                  </div>
                              
                                </div>
                
                              </div>
                              <dicv className='grid grid-cols-12'>
                                <div className='col-span-6'>
                                  <div className='flex flex-col space-y-4'>
                                    <h1 className='font-bold text-lg'>Button (Call To Action)</h1>
                                    <div className='flex flex-col space-y-3'>
                                      <div className='flex items-center gap-3 text-gray-400'>
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path d="M19 12C19 10.05 18.3207 8.39567 16.962 7.037C15.604 5.679 13.95 5 12 5V3C13.25 3 14.421 3.23733 15.513 3.712C16.6043 4.18733 17.5543 4.829 18.363 5.637C19.171 6.44567 19.8127 7.39567 20.288 8.487C20.7627 9.579 21 10.75 21 12H19ZM15 12C15 11.1667 14.7083 10.4583 14.125 9.875C13.5417 9.29167 12.8333 9 12 9V7C13.3833 7 14.5627 7.48733 15.538 8.462C16.5127 9.43733 17 10.6167 17 12H15ZM19.95 21C17.8 21 15.7043 20.5207 13.663 19.562C11.621 18.604 9.81267 17.3373 8.238 15.762C6.66267 14.1873 5.396 12.379 4.438 10.337C3.47933 8.29567 3 6.2 3 4.05C3 3.75 3.1 3.5 3.3 3.3C3.5 3.1 3.75 3 4.05 3H8.1C8.33333 3 8.54167 3.075 8.725 3.225C8.90833 3.375 9.01667 3.56667 9.05 3.8L9.7 7.3C9.73333 7.53333 9.72933 7.74567 9.688 7.937C9.646 8.129 9.55 8.3 9.4 8.45L6.975 10.9C7.675 12.1 8.55433 13.225 9.613 14.275C10.671 15.325 11.8333 16.2333 13.1 17L15.45 14.65C15.6 14.5 15.796 14.3873 16.038 14.312C16.2793 14.2373 16.5167 14.2167 16.75 14.25L20.2 14.95C20.4333 15 20.625 15.1123 20.775 15.287C20.925 15.4623 21 15.6667 21 15.9V19.95C21 20.25 20.9 20.5 20.7 20.7C20.5 20.9 20.25 21 19.95 21ZM6.025 9L7.675 7.35L7.25 5H5.025C5.10833 5.68333 5.225 6.35833 5.375 7.025C5.525 7.69167 5.74167 8.35 6.025 9ZM14.975 17.95C15.625 18.2333 16.2877 18.4583 16.963 18.625C17.6377 18.7917 18.3167 18.9 19 18.95V16.75L16.65 16.275L14.975 17.95Z" fill="url(#paint0_linear_4925_7349)"/>
                                          <defs>
                                          <linearGradient id="paint0_linear_4925_7349" x1="21" y1="3" x2="0.0578885" y2="16.403" gradientUnits="userSpaceOnUse">
                                          <stop stop-color="#54DAA1"/>
                                          <stop offset="1" stop-color="#2DCE89"/>
                                          </linearGradient>
                                          </defs>
                                        </svg>
                                        Customer Service (0821241411)
                
                                      </div>
                                      <div className='flex items-center gap-3 text-gray-400'>
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path d="M7 17C5.61667 17 4.43767 16.5123 3.463 15.537C2.48767 14.5623 2 13.3833 2 12C2 10.6167 2.48767 9.43733 3.463 8.462C4.43767 7.48733 5.61667 7 7 7H10C10.2833 7 10.521 7.09567 10.713 7.287C10.9043 7.479 11 7.71667 11 8C11 8.28333 10.9043 8.52067 10.713 8.712C10.521 8.904 10.2833 9 10 9H7C6.16667 9 5.45833 9.29167 4.875 9.875C4.29167 10.4583 4 11.1667 4 12C4 12.8333 4.29167 13.5417 4.875 14.125C5.45833 14.7083 6.16667 15 7 15H10C10.2833 15 10.521 15.0957 10.713 15.287C10.9043 15.479 11 15.7167 11 16C11 16.2833 10.9043 16.5207 10.713 16.712C10.521 16.904 10.2833 17 10 17H7ZM9 13C8.71667 13 8.47933 12.904 8.288 12.712C8.096 12.5207 8 12.2833 8 12C8 11.7167 8.096 11.479 8.288 11.287C8.47933 11.0957 8.71667 11 9 11H15C15.2833 11 15.521 11.0957 15.713 11.287C15.9043 11.479 16 11.7167 16 12C16 12.2833 15.9043 12.5207 15.713 12.712C15.521 12.904 15.2833 13 15 13H9ZM14 17C13.7167 17 13.4793 16.904 13.288 16.712C13.096 16.5207 13 16.2833 13 16C13 15.7167 13.096 15.479 13.288 15.287C13.4793 15.0957 13.7167 15 14 15H17C17.8333 15 18.5417 14.7083 19.125 14.125C19.7083 13.5417 20 12.8333 20 12C20 11.1667 19.7083 10.4583 19.125 9.875C18.5417 9.29167 17.8333 9 17 9H14C13.7167 9 13.4793 8.904 13.288 8.712C13.096 8.52067 13 8.28333 13 8C13 7.71667 13.096 7.479 13.288 7.287C13.4793 7.09567 13.7167 7 14 7H17C18.3833 7 19.5627 7.48733 20.538 8.462C21.5127 9.43733 22 10.6167 22 12C22 13.3833 21.5127 14.5623 20.538 15.537C19.5627 16.5123 18.3833 17 17 17H14Z" fill="url(#paint0_linear_4925_7353)"/>
                                          <defs>
                                          <linearGradient id="paint0_linear_4925_7353" x1="22" y1="7" x2="9.56822" y2="22.9127" gradientUnits="userSpaceOnUse">
                                          <stop stop-color="#54DAA1"/>
                                          <stop offset="1" stop-color="#2DCE89"/>
                                          </linearGradient>
                                          </defs>
                                        </svg>
                                        Link Produk (www.facebook.com)
                                      </div>
                                  
                                    </div>
                
                                  </div>
                
                                </div>
                                <div className='col-span-6'>
                                  <div className='flex flex-col space-y-4 border-2 border-green-400 w-52'>
                                    <h1 className='font-bold text-lg'>Button 
                                    {
                                        showhide==='QUICK_REPLAY' && (
                                          <>
                                            (Quick Reply)
                                          </>
                                    )}
                                    </h1>
                                    <div className='flex flex-col space-y-3'>
                                    {
                                      showhide==='QUICK_REPLAY' && (
                                      <>
                                        {inputFields.map((input, index) => {
                                                return (
                                            <h1 key={index} className='text-gray-400'>{input.text}</h1>
                                        
                                          )
                                        })}
                                        </>

                                    )} 
                                    </div>
                
                                  </div>
                
                                </div>
                                
                
                              </dicv>
                            </div>
                
                          </div>
                
                        </div>
                      </div>
                
                      <div className="container flex justify-end mt-4 mb-8 gap-3">
                            
                            <button
                                onClick={() => setStepp(stepp-1)}
                                className="cursor-pointer rounded-lg bg-gray-100 py-2 px-8 font-semibold capitalize text-gray-600 transition duration-200 ease-in-out hover:bg-gray-700 hover:text-white"
                            >
                                Back
                            </button>
                        
                        
                            <button
                                
                                // onClick={() => setCurrentSteps(4)}
                                onClick={postData}
                                className="cursor-pointer rounded-lg bg-green-500 py-2 px-8 font-semibold text-white transition duration-200 ease-in-out hover:bg-green-800 hover:text-white"
                            >
                                
                                {/* Submit */}
                                {isLoading ?
                                  <svg className='animate-spin text-center' width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                      <path opacity='0.1' fillRule='evenodd' clipRule='evenodd' d='M15 5.625C12.5136 5.625 10.129 6.61272 8.37087 8.37087C6.61272 10.129 5.625 12.5136 5.625 15C5.625 17.4864 6.61272 19.871 8.37087 21.6291C10.129 23.3873 12.5136 24.375 15 24.375C17.4864 24.375 19.871 23.3873 21.6291 21.6291C23.3873 19.871 24.375 17.4864 24.375 15C24.375 12.5136 23.3873 10.129 21.6291 8.37087C19.871 6.61272 17.4864 5.625 15 5.625ZM1.875 15C1.875 7.75125 7.75125 1.875 15 1.875C22.2487 1.875 28.125 7.75125 28.125 15C28.125 22.2487 22.2487 28.125 15 28.125C7.75125 28.125 1.875 22.2487 1.875 15Z' fill='black'/>
                                      <path fillRule='evenodd' clipRule='evenodd' d='M15.0001 5.62502C12.583 5.61958 10.2583 6.55314 8.51632 8.22877C8.15542 8.56246 7.67791 8.74109 7.1866 8.7262C6.69529 8.71131 6.22948 8.50408 5.88945 8.14914C5.54942 7.7942 5.36236 7.31992 5.36856 6.82843C5.37475 6.33693 5.5737 5.86752 5.92257 5.52127C8.36247 3.17684 11.6164 1.86981 15.0001 1.87502C15.4974 1.87502 15.9743 2.07256 16.3259 2.42419C16.6775 2.77582 16.8751 3.25273 16.8751 3.75002C16.8751 4.2473 16.6775 4.72421 16.3259 5.07584C15.9743 5.42747 15.4974 5.62502 15.0001 5.62502Z' fill='white'/>
                                  </svg>                                
                                  :
                                  'Submit'
                                }
                            </button>
                        
                        </div>
                    </div>
                  }

               </div>
            </div>

           

          </div>
       

        </div>

     

        {/* end: Main */}
      </div>
    </div>
  );
};

export default CreateTemplate;
