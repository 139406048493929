import React, { useState, useEffect } from "react";
import Sidebar from "../../Components/Sidebar/Sidebar";
import { useNavigate } from "react-router-dom";
import "aos/dist/aos.css";
import {
  getAllCity,
  getAllProvince,
  getAllSubdis,
} from "../../Network/Lib/rajaOngkir";
import { createCS, createCsAddress, getCustomerById, getCs } from "../../Network/Lib/customer";
import { getAllProduct } from "../../Network/Lib/product";
import { Link, useParams } from "react-router-dom";
import { createOrder, createOrderDetail } from "../../Network/Lib/order";
import { logout } from "../../Network/Lib/auth";
import { getWarehouse } from "../../Network/Lib/warehouse";
import RepeaterProduct2 from "../../Components/Partials/Input/RepeaterProduct2";
import Repeater from "../../Components/Partials/Input/Repeater";
import { getLeveling } from "../../Network/Lib/leveling";
import { getPromo } from "../../Network/Lib/promo";


function AddOrder() {
  const [navSideBar, setNavSideBar] = useState(false);
  const [refreshAPI, setRefreshApi] = useState(false);
  const navigate = useNavigate();

  const { id } = useParams();
  console.log(id);

  const [data, setData] = useState([]);
  const [name, setName] = useState([]);
  const [phone, setPhone] = useState();
  const [birthdate, setBirthdate] = useState([]);
  const [email, setEmail] = useState([]);
  const [gender, setGender] = useState([]);
  const [marital_status, setMaritalStatus] = useState([]);
  const [latest_purchase, setLatestPurchase] = useState([]);
  const [level, setLevel] = useState([]);
  const [address, setAddress] = useState([]);

  const [provinceList, setProvinceList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [subdistrictList, setSubdistrictList] = useState([]);
  const [selectedProvince, setSelectedProvince] = useState();
  const [selectedCs, setSelectedCs] = useState();
  const [selectedPromo, setSelectedPromo] = useState();
  const [selectedLeveling, setSelectedLeveling] = useState();
  const [selectedCity, setSelectedCity] = useState();
  const [selectedSubdis, setSelectedSubdis] = useState();

  // const [status, setStatus] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState();
  const [productList, setProductList] = useState([]);
  const [qty, setQty] = useState([]);
  const [payment, setPayment] = useState([]);
  const [selectedWarehouse, setSelectedWarehouse] = useState();
  const [warehouse, setWarehouse] = useState([]);
  const [csName, setCSName] = useState([]);
  const [csPhone, setCSPhone] = useState([]);
  const [campaign, setCampaign] = useState([]);
  const [promo, setPromo] = useState([]);
  // const [resi, setResi] = useState([]);
  // const [courier, setCourier] = useState([]);
  // const [note, setNote] = useState([]);
  const [reason, setReason] = useState([]);
  const [unit, setUnit] = useState([]);
  const [price, setPrice] = useState([]);
  const [costumer, setCostumer] = useState([]);
  const [phoneCs, setPhoneCs] = useState([]);

  const [msg, setMsg] = useState("");

  const [totalPrice, setTotalPrice] = useState("");
  const [shipping, setShipping] = useState("");
  const [subsidi, setSubsidi] = useState("");

  const [dataLeveling, setDataLeveling] = useState([])

  const [customerId, setCostumerId] = useState();
  const [warehouseId, setWarehouseId] = useState();
  const [promoId, setPromoId] = useState();
  const [customerServiceId, setCustomerServiceId] = useState();
  const [buyingDate, setBuyingDate] = useState();
  const [memo, setMemo] = useState();
  const [status, setStatus] = useState();
  const [paymentMethod, setPaymentMethod] = useState();
  const [note, setNote] = useState();
  const [buyingReason, setBuyingReason] = useState();
  const [channel, setChanel] = useState();
  const [adv, setAdv] = useState();
  const [category, setCategory] = useState();
  const [resi, setResi] = useState();
  const [courir, setCourir] = useState();
  const [omzet, setOmset] = useState();
  const [shippingFee, setShippingFee] = useState();
  const [subsidiCompany, setSubsidiCompany] = useState();
  const [totalCost, setTotalCost] = useState();
  const [returnReason, setReturnReason] = useState();

  // console.log(name);
  // console.log(phone);
  // console.log(birthdate);
  // console.log(email);
  // console.log(gender);
  // console.log(marital_status);
  // console.log(latest_purchase);
  // console.log(level);
  // console.log(address);
  // console.log(selectedProvince);
  // console.log(selectedCity);
  // console.log(selectedSubdis);
  // console.log(selectedProduct);
  // console.log(status);
  // console.log(selectedProduct);
  // console.log(qty);
  // console.log(payment);
  // console.log(warehouse);
  // console.log(csName);
  // console.log(csPhone);
  // console.log(campaign);
  // console.log(promo);
  // console.log(resi);
  // console.log(courier);
  // console.log(note);
  // console.log(reason);

  // const postData = async () => {
  //   // const form = {
  //   //   name: name,
  //   //   phone: phone,
  //   //   birthdate: birthdate,
  //   //   email: email,
  //   //   gender: gender,
  //   //   marital_status: marital_status,
  //   //   // latest_purchase: birthdate,
  //   //   level: level,
  //   // };
  //   // const formOrder = {
  //   //   // csId: "2b92c1d1-7322-46cf-a251-3f65ee9cd459",
  //   //   customerId: id,
  //   //   warehouseId: selectedWarehouse,
  //   //   status: status,
  //   //   // userId: "2b92c1d1-7322-46cf-a251-3f65ee9cd459",
  //   // };

  //   const formData = new FormData();

  //   // for (let i = 0; i < inputFields.length; i++) {
  //   //   formData.append("qty", inputFields[i].qty);
  //   //   formData.append("productId", inputFields[i].productId);
     
  //   // }

  //   formData.append("customerId", name);
  //   formData.append("warehouseId", selectedWarehouse);
  //   formData.append("promoId", selectedPromo);
  //   formData.append("customerServiceId", selectedCs);
  //   formData.append("buyingDate", buyingDate);
  //   formData.append("memo", memo);
  //   formData.append("status", status);
  //   formData.append("paymentMethod", paymentMethod);
  //   formData.append("buyingReason", buyingReason);
  //   formData.append("channel", channel);
  //   formData.append("adv", adv);
  //   formData.append("category", category);
  //   formData.append("resi", resi);
  //   formData.append("courir", courir);
  //   formData.append("omzet", totalPrice);
  //   formData.append("shippingFee", shipping);
  //   formData.append("subsidiCompany", subsidi);
  //   formData.append("totalCost", totalCost);
  //   formData.append("returnReason", returnReason);

  //   try {
  //     const response = await createOrder(formData)
  //     // const response = await createOrder(formOrder)
  //       .then(async (response) => {
  //         // const responseOrder = await createOrder(formData).then((responseOrder) => {
  //         // console.log(response.data.data.id);
  //         // const orderId = response.data.data.id;
  //         // for (let i = 0; i < inputFields.length; i++) {
  //         //   formData.append("qty", inputFields[i].qty);
  //         //   formData.append("productId", inputFields[i].productId);
           
  //         // }
  //         // });
          
  //         // setName("");
  //         // setPhone("");
  //         // setBirthdate("");
  //         // setEmail("");
  //         // setGender("");
  //         // setMaritalStatus("");
  //         // setLatestPurchase("");
  //         // setLevel("");

  //         // console.log(response.data.data.id);
  //         // const id = response.data.data.id;
  //         // const formAddress = {
  //         //   customerId: id,
  //         //   address: address,
  //         //   subdistrict_id: selectedSubdis,
  //         //   district_id: 0,
  //         //   city_id: selectedCity,
  //         //   province_id: selectedProvince,
  //         // };
  //         // const formOrder = {
  //         //   csId: "2b92c1d1-7322-46cf-a251-3f65ee9cd459",
  //         //   customerId: id,
  //         //   status: status,
  //         //   userId: "2b92c1d1-7322-46cf-a251-3f65ee9cd459",
  //         // };
  //         // createCsAddress(formAddress);
  //         // console.log(formOrder);
  //         // const responseOrder = await createOrder(formOrder).then((responseOrder) => {
  //         // console.log(response.data.data.id);
  //         // const orderId = response.data.data.id;
  //         // inputFields.map(async (val) => {
  //         //   const formOrderDetail = {
  //         //     orderId: orderId,
  //         //     productId: val.productId,
  //         //     qty: val.qty,
  //         //   };
  //         //   console.log(formOrderDetail);
  //         //   const response2 = await createOrderDetail(formOrderDetail)
  //         //     .then(async (response) => {
  //         //       console.log(response);
  //         //       // navigate(`/view-customer/${id}`);
  //         //     })
  //         //     .catch((e) => {
  //         //       if (e.response) {
  //         //         setMsg("order Detail :" + e.response.data.message);
  //         //       }
  //         //     });
  //         // });
  //         // });

  //         const responseOrder = await createOrder(formData).then((responseOrder) => {
  //           console.log(response.data.data.id);
  //           const orderId = response.data.data.id;

  //           const formDataDetail = new FormData();

  //           for (let i = 0; i < inputFields.length; i++) {
  //             formDataDetail.append("orderId", inputFields[i].orderId);
  //             formDataDetail.append("qty", inputFields[i].qty);
  //             formDataDetail.append("productId", inputFields[i].productId);
            
  //           }
  //             const response2 = await createOrderDetail(formDataDetail)
  //               .then(async (response) => {
  //                 console.log(response);
  //                 // navigate(`/view-customer/${id}`);
  //               })
  //               .catch((e) => {
  //                 if (e.response) {
  //                   setMsg("order Detail :" + e.response.data.message);
  //                 }
  //             });
  //         });

          
  //         // console.log(response);
  //         navigate(`/view-customer/${id}`);
  //       })
  //       .catch((e) => {
  //         if (e.response) {
  //           setMsg(e.response.data.message);
  //           console.log(e.response.data);
  //         }
  //       });
  //     // console.log(response).catch((e) => {
  //     //   console.log(e.response);
  //     // });
  //   } catch (error) { }
  // };

  // const postData = async () => {
  //   const formData = new FormData();
  
  //   formData.append("customerId", id);
  //   formData.append("warehouseId", selectedWarehouse);
  //   formData.append("promoId", selectedPromo);
  //   formData.append("customerServiceId", selectedCs);
  //   formData.append("buyingDate", buyingDate);
  //   formData.append("memo", selectedLeveling);
  //   formData.append("status", status);
  //   formData.append("transactionNumber", '123142424');
  //   formData.append("paymentMethod", paymentMethod);
  //   formData.append("buyingReason", buyingReason);
  //   formData.append("channel", channel);
  //   formData.append("adv", adv);
  //   formData.append("note", note);
  //   formData.append("category", category);
  //   formData.append("resi", resi);
  //   formData.append("courir", courir);
  //   formData.append("omzet", totalPrice);
  //   formData.append("shippingFee", shipping);
  //   formData.append("subsidiCompany", subsidi);
  //   formData.append("totalCost", totalCost);
  //   formData.append("returnReason", returnReason);
  
  //   try {
  //     const response = await createOrder(formData)
  //     // const orderId = response.data.data.id;
  //     // const formDataDetail = new FormData();
  //     // for (let i = 0; i < inputFields.length; i++) {
  //     //   // formDataDetail.append("orderId", orderId);
  //     //   formDataDetail.append("qty", inputFields[i].qty);
  //     //   formDataDetail.append("productId", inputFields[i].selectedProduct );
  //     // }
  //     // const response2 = await createOrderDetail(formDataDetail);

  //     // console.log(orderId, 'aku');
  //     navigate(`/view-customer/${id}`);
  //   } catch (e) {
  //     // if (e.response) {
  //     //   setMsg(e.response.data.message);
  //     //   console.log(e.response.data);
  //     // }
  //      console.log(e.response);
  //   }
  // };

  const postData = async () => {
    const err = [];
    // if (name == "") {
    //   err.push("Name Can't be empty");
    // }
    // if (phone == "") {
    //   err.push("Phone Can't be empty");
    // }
    // if (birthdate == "") {
    //   err.push("Birthdate Can't be empty");
    // }
    // if (email == "") {
    //   err.push("Email Can't be empty");
    // }
    // if (gender == "") {
    //   err.push("Gender Can't be empty");
    // }
    // if (marital_status == "") {
    //   err.push("Marital Status Can't be empty");
    // }
    // if (level == "") {
    //   err.push("Level Can't be empty");
    // }
    // if (address == "") {
    //   err.push("Address Can't be empty");
    // }
    // if (selectedSubdis == "") {
    //   err.push("Subdistrict Can't be empty");
    // }
    // if (selectedCity == "") {
    //   err.push("City Can't be empty");
    // }
    // if (selectedProvince == "") {
    //   err.push("Province Can't be empty");
    // }
    if (err.length > 0) {
      setMsg(err);
    } else {

      const formOrder = {
        customerId : id,
        warehouseId : selectedWarehouse,
        promoId : selectedPromo,
        customerServiceId : selectedCs,
        // customerServiceId : '7be3d3b6-fc49-4066-9199-613ff52c098f',
        buyingDate : buyingDate,
        memo : "new customer",
        status : status,
        transactionNumber : '123142424',
        paymentMethod : paymentMethod,
        buyingReason : buyingReason,
        channel : channel,
        adv : adv,
        note : note,
        category : category,
        resi : resi,
        courir : courir,
        omzet : totalPrice,
        shippingFee : shipping,
        subsidiCompany : subsidi,
        totalCost : totalCost,
        returnReason : returnReason,
      };
      console.log(formOrder);
      try {
        const response = await createOrder(formOrder)
        // console.log(response.data.data.id, 'asssa')
        .then(async (response) => {
          
            const orderId = response.data.data.id;

            
            inputFields.map(async (val) => {
              const formOrderDetail = {
                  orderId: orderId,
                  productId: val.productId,
                  qty: val.qty,
              };
           

            const response2 = await createOrderDetail(formOrderDetail)
              .then(async (response) => {
                // console.log(response);
                // navigate(`/view-customer/${id}`);
              })
              .catch((e) => {
                if (e.response) {
                  // console.log(e.response.data.message);
                  err.push(e.response.data.message);
                  setMsg(err);
                }
              });
            });
            navigate(`/view-customer/${id}`);
           })
          .catch((e) => {
            if (e.response) {
              // console.log(e.response.data.message);
              err.push(e.response.data.message);
              setMsg(err);
            }
          });
      } catch (error) {}
    }
  };

  

  useEffect(() => {
    getAllProvince().then((response) => {
      setProvinceList(response.data.data);
    });
    getAllProduct().then((response) => {
      setProductList(response.data.data);
      // console.log(response.data.data, "anta");
    });
    getWarehouse().then((response) => {
      // console.log(response.data.data);
      setWarehouse(response.data.data);
    });
    getCustomerById(id).then((response) => {
      setName(response.data.data[0].name);
      // console.log(response.data.data[0].name);
    });
    getLeveling().then((response) => {
      // console.log("leveling", response.data.data[0]);
      setMemo(response.data.data);
    });

    getCs().then((response) => {
      // console.log("cs", response.data.getCS[0])
      setCostumer(response.data.getCS);
      // setCostumer(response)

    });
    getPromo().then((response)=> {
      // console.log('promo1', response.data)
      setPromo(response.data)

    });

    setRefreshApi(false);
  }, [refreshAPI]);

  const onSelectProvince = async (e) => {
    setSelectedProvince(e.target.value);
    await getAllCity(e.target.value)
      .then((response) => {
        setCityList(response.data.data);
      })
      .catch((e) => { });
  };

  const onSelectCity = async (e) => {
    setSelectedCity(e.target.value);
    await getAllSubdis(e.target.value)
      .then((response) => {
        setSubdistrictList(response.data.data);
      })
      .catch((e) => { });
  };


  const onSelectCS = async (e) => {
    setSelectedCs(e.target.value);
    await getCs(e.target.value)
      .then((response) => {
        setCostumer(response.data.getCS);
        setPhoneCs(response.data.getCS[0].phone)
      })
      .catch((e) => { });
  };

  const onSelectPromo = async (e) => {
    setSelectedPromo(e.target.value);
    await getPromo(e.target.value)
      .then((response) => {
        setPromo(response.data);
       
      })
      .catch((e) => { });
  };

  const onSelectLeveling = async (e) => {
    setSelectedLeveling(e.target.value);
    await getLeveling(e.target.value)
      .then((response) => {
        setMemo(response.data.data);
       
      })
      .catch((e) => { });
  };

  const onSelectSubdis = async (e) => {
    setSelectedSubdis(e.target.value);
  };

  const onSelectProduct = async (e) => {
    setSelectedProduct(e.target.value);
    await getAllProduct(e.target.value)
      .then((response) => {
        setProductList(response.data.data);
        // console.log(response);
        setUnit(response.data.data.unit);
        setPrice(response.data.data.price);
      })
      .catch((e) => { });
  };



  const signOut = () => {
    logout();
    navigate("/login");
  };

  // console.log(warehouse);

  // const [product, setProduct] = useState({
  //   formValues: [{ qty: "", productId: "" }],
  // });

  // const handleChange = (i, e) => {
  //   let formValues = product.formValues;
  //   formValues[i][e.target.name] = e.target.value;
  //   setProduct({ formValues });
  //   // console.log(formValues)
  // };

  // const addFormFields = () => {
  //   setProduct({
  //     formValues: [...product.formValues, { qty: "", productId: "" }],
  //   });
  // };

  // const removeFormFields = (i) => {
  //   let formValues = product.formValues;
  //   formValues.splice(i, 1);
  //   setProduct({ formValues });
  // };

  // const handleSubmit = (event) => {
  //   event.preventDefault();
  //   alert(JSON.stringify(product.formValues));
  // };

  const [inputFields, setInputFields] = useState([{
    qty: "",
    productId: "",
    unit: "",
    price: "",
    total:"",
  }]);

  const [defaultUnit, setDefaultUnit] = useState("");
  const [defaultPrice, setDefaultPrice] = useState("");

  // const onSelectProduct = async (e, index) => {
  //   const productId = e.target.value;

  //   // fetch product data based on productId
  //   await getAllProduct(productId)
  //     .then((response) => {
  //       const productData = response.data.data;

  //       // set default unit and price for new field based on selected product
  //       if (index === inputFields.length) {
  //         setDefaultUnit(productData.unit);
  //         setDefaultPrice(productData.price);
  //       }

  //       // update input field with product data
  //       const updatedFields = [...inputFields];
  //       updatedFields[index] = {
  //         ...updatedFields[index],
  //         productId,
  //         unit: productData.unit,
  //         price: productData.price,
  //       };
  //       setInputFields(updatedFields);
  //     })
  //     .catch((e) => {});
  // };

  const addFields = () => {
    const newField = {
      qty: "",
      productId: "",
      unit: defaultUnit,
      price: defaultPrice,
      total: "",
    };
    setInputFields([...inputFields, newField]);
  };

  

  const removeFields = (index) => {
    const updatedFields = [...inputFields];
    updatedFields.splice(index, 1);
    setInputFields(updatedFields);
  };

  // const handleFormChange = (index, event) => {
  //   const { name, value } = event.target;
  //   const updatedFields = [...inputFields];
  //   updatedFields[index] = {
  //     ...updatedFields[index],[name]: value,
  //     // total: updatedFields[index].qty * updatedFields[index].price, //
  //   };

    
  //   updatedFields[index]['total'] = parseInt(updatedFields[index].qty) * price // Calculate the total value by multiplying 'qty' with 'price'
    
  //   // let total_price = updatedFields[index]['total'] +  updatedFields[index]['total'];
  //   // setTotalPrice(total_price)
  //   // Total(index, updatedFields)

  //   // console.log(total_price, 'totala')


  //   setInputFields(updatedFields);
  // };

  // const Total = (updatedFields, index) =>{
  //   let total_price = updatedFields[index]['total'] +  updatedFields[index]['total'];
  //   setTotalPrice(total_price)
  // }

  
  // const handleFormChange = (index, event) => {
  //   const { name, value } = event.target;
  //   // const updatedFields = [...inputFields];
  //   // updatedFields[index] = {
  //   //   ...updatedFields[index],
  //   //   [name]: value,
  //   // };

  //   const updatedFields = [...inputFields];
  //   updatedFields[index][name] = value;
  
  // // tambahkan properti id produk yang dipilih ke inputFields
  //   // const selectedIndex = event.target.children[event.target.selectedIndex].getAttribute("data-index");
  //   updatedFields[index]["id"] = productList.id;

  //   updatedFields[index]['total'] = parseInt(updatedFields[index].qty) * parseInt(price); // Calculate the total value by multiplying 'qty' with 'price'
  //   setInputFields(updatedFields);
  //   Total(updatedFields);
  // };

  const handleFormChange = (index, event) => {
    const { name, value } = event.target;
    const updatedFields = [...inputFields];
    updatedFields[index][name] = value;
    // updatedFields[index]['id'] = event.target.value; // set 'id' to the selected 'productId'
    updatedFields[index]['total'] = parseInt(updatedFields[index].qty) * parseInt(price); // Calculate the total value by multiplying 'qty' with 'price'
    setInputFields(updatedFields);
    Total(updatedFields);
  };
  
  
  // const Total = (inputFields) =>{
  //   const totalPrice = inputFields.reduce((acc, curr) => acc + (parseInt(curr.total) || 0), 0); // Sum up the 'total' property of all input fields
  //   setTotalPrice(totalPrice);
    
  //   const totalA = (totalPrice - shipping - subsidi);
    
  //   console.log(totalA, 'total anggur')
  // }
  const handleShippingChange = (event) => {
    setShipping(event.target.value);
    Total(inputFields, event.target.value, subsidi);
  };
  
  const handleSubsidiChange = (event) => {
    setSubsidi(event.target.value);
    Total(inputFields, shipping, event.target.value);
  };

  
  const Total = (inputFields, shipping, subsidi) => {
    const totalPrice = inputFields.reduce((acc, curr) => acc + (parseInt(curr.total) || 0), 0); // Sum up the 'total' property of all input fields
    setTotalPrice(totalPrice);
    
    const totalA = (totalPrice - parseInt(shipping || 0) - parseInt(subsidi || 0));
    
    // console.log(totalA, 'total anggur');
  };
  
  


  // constTotal = 
  // const handleFormChange = (index, event) => {
  //   const values = [...inputFields];
  //   const { name, value } = event.target;
  
  //   // Set the value to null if the input field is the first or the second one and it's empty
  //   if ((name === "productId" || name === "qty") && index === 0 && !value) {
  //     values[index][name] = null;
  //   } else {
  //     values[index][name] = value;
  //   }
  
  //   setInputFields(values);
  // };
  

//   const [inputFields, setInputFields] = useState([{ 
        
//     qty: "", 
//     productId: ""



//  }])

//  const addFields = () => {
//     let newfield = {
       
//       qty: "", 
//       productId: "",
//       unit: "",
//       price: "",
    
//     }
//     setInputFields([...inputFields, newfield])
//     setUnit('');
//     setPrice('');
// }
//  const removeFields = (index) => {
//     let data = [...inputFields];
//     data.splice(index, 1)
//     setInputFields(data)
// }
// const handleFormChange = (index, event) => {
//     let data = [...inputFields];
   
//     console.log(data, 'handleFormChange')
//     setInputFields(data);


    
// }



  

  // console.log(product.formValues);
  return (
    <>
      <div className="bg-gradient-to-r from-[#54DAA1] to-[#2DCE89]">
        <div className="relative">
          <aside
            className={
              navSideBar === true
                ? "w-24 h-screen p-5 fixed top-0 left-0 right-0 z-10"
                : "w-3/12 h-screen p-5 fixed top-0 left-0 right-0 z-10"
            }
            aria-label="Sidebar"
          >
            <button
              className="absolute top-72 right-1 w-10 h-10 bg-gradient-to-r from-[#54DAA1] to-[#2DCE89] rounded-full shadow-lg flex justify-center items-center"
              onClick={() => setNavSideBar(!navSideBar)}
            >
              {navSideBar ? (
                <svg
                  width="9"
                  height="16"
                  viewBox="0 0 9 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 14.3333L7.66667 7.66668L1 1.00001"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              ) : (
                <svg
                  width="10"
                  height="16"
                  viewBox="0 0 10 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.33325 14.6667L1.66659 7.99999L8.33325 1.33332"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              )}
            </button>
            <Sidebar navSideBar={navSideBar} />
          </aside>
        </div>
        <div
          className={
            navSideBar === true
              ? "ml-24 h-72 w-11/12 p-5 space-y-5"
              : "ml-auto h-72 w-9/12 p-5 space-y-5"
          }
        >
          {/* Header */}
          <div className="flex flex-row justify-between items-center">
            <div className="text-white space-y-2">
              <div className="flex flex-row space-x-3">
                <p className="text-sm">Pages</p>
                <p className="text-sm">/</p>
                <a href="/Dashboard" className="text-sm">
                  Dashboard
                </a>
                <p className="text-sm">/</p>
                <a href="#" className="text-sm">
                  View Customer
                </a>
                <p className="text-sm">/</p>
                <a href="#" className="text-sm">
                  Add Order
                </a>
              </div>
              <p className="font-semibold">View Customer</p>
            </div>
            <div className="flex flex-row items-center gap-5">
              <a href="#" className="flex items-center gap-2">
                <svg
                  width="21"
                  height="20"
                  viewBox="0 0 21 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.4938 2.52235C12.7336 1.70165 11.6719 1.24969 10.5 1.24969C9.32191 1.24969 8.25668 1.69891 7.50004 2.51454C6.73519 3.33915 6.36254 4.45985 6.45004 5.67001C6.62348 8.05751 8.44027 9.99969 10.5 9.99969C12.5598 9.99969 14.3735 8.0579 14.5496 5.67079C14.6383 4.47157 14.2633 3.35321 13.4938 2.52235Z"
                    fill="white"
                  />
                  <path
                    d="M17.3752 18.7488H3.62522C3.44525 18.7511 3.26701 18.7133 3.10349 18.6381C2.93997 18.5629 2.79526 18.4522 2.67991 18.314C2.426 18.0105 2.32366 17.596 2.39944 17.1769C2.72913 15.348 3.75804 13.8117 5.37522 12.7332C6.81194 11.7757 8.63186 11.2488 10.5002 11.2488C12.3686 11.2488 14.1885 11.7761 15.6252 12.7332C17.2424 13.8113 18.2713 15.3476 18.601 17.1765C18.6768 17.5957 18.5744 18.0101 18.3205 18.3136C18.2052 18.4519 18.0605 18.5626 17.897 18.6379C17.7335 18.7132 17.5552 18.7511 17.3752 18.7488Z"
                    fill="white"
                  />
                </svg>
                <p className="text-white">{localStorage.getItem("nameUser")}</p>
              </a>
              <button onClick={signOut} className="bg-white p-3 rounded-lg">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.5625 2.8125H2.1875V17.8125H6.5625M13.4375 5.9375L17.8125 10.3125L13.4375 14.6875M7.1875 10.3125H17.8125"
                    stroke="#EF4444"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            </div>
          </div>
          {/* start: Main */}
          <div className="pb-5">
            <div className="bg-white shadow-lg rounded-lg p-6">
              <div className="flex flex-row justify-between">
                <div className="flex items-center justify-center gap-3">
                  <h1 className="text-lg font-bold">Add Order</h1>
                </div>
              </div>

              {/* start: Data Order */}
              {/* <div className="bg-gray-50 rounded-lg p-5 my-5">
                <div className="py-2">
                  <span className="font-medium text-lg">Data Order</span>
                </div>
                {msg != "" ? (
                  <div
                    className="p-3 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800 my-3"
                    role="alert"
                  >
                    {msg}
                  </div>
                ) : null}
                <div className="grid grid-cols-6 md:grid-cols-12 gap-5 mt-5">
                  <div className="col-span-6">
                    <span className="text-sm font-bold text-gray-400">
                      Status
                    </span>
                    <div className="mt-3.5">
                      <select
                        placeholder="Select Province"
                        className="bg-white rounded-lg border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                        onChange={(e) => setStatus(e.target.value)}
                      >
                        <option hidden>Select Status</option>
                        <option value="processing">Processing</option>
                        <option value="closing">Closing</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-span-6">
                    <span className="text-sm font-bold text-gray-400">
                      Product
                    </span>
                    <div className="mt-3.5">
                      <select
                        placeholder="Select Province"
                        className="bg-white rounded-lg border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                        value={selectedProduct}
                        onChange={onSelectProduct}
                      >
                        <option hidden>Select Product</option>
                        {productList &&
                          productList.map(function (value, index) {
                            return (
                              <>
                                <option key={index} value={value.id}>
                                  {value.name}
                                </option>
                              </>
                            );
                          })}
                      </select>
                    </div>
                  </div>
                  <div className="col-span-6">
                    <span className="text-sm font-bold text-gray-400">
                      Quantity
                    </span>
                    <div className="mt-3.5">
                      <input
                        type="number"
                        min={1}
                        className="bg-white rounded-lg border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                        onChange={(e) => setQty(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-span-6">
                    <span className="text-sm font-bold text-gray-400">
                      Payment Method
                    </span>
                    <div className="mt-3.5">
                      <select
                        placeholder="Select Payment Method"
                        className="bg-white rounded-lg border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                        onChange={(e) => setPayment(e.target.value)}
                      >
                        <option hidden>Select Payment Method</option>
                        <option value="cash">Cash</option>
                        <option value="non cash">Non Cash</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-span-6">
                    <span className="text-sm font-bold text-gray-400">
                      Warehouse
                    </span>
                    <div className="mt-3.5">
                      <select
                        placeholder="Select Warehouse"
                        className="bg-white rounded-lg border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                        onChange={(e) => setSelectedWarehouse(e.target.value)}
                      >
                        <option hidden>Select Warehouse</option>
                        {warehouse && warehouse.map((val, index) => (
                          <option key={index} value={val.id}>
                            {val.warehouseName}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-span-6">
                    <span className="text-sm font-bold text-gray-400">
                      CS Acquisition (Name)
                    </span>
                    <div className="mt-3.5">
                      <select
                        placeholder="Select CS Acquisition  Name"
                        className="bg-white rounded-lg border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                        onChange={(e) => setCSName(e.target.value)}
                      >
                        <option hidden>Select CS Acquisition Name</option>
                        <option value="1">Cs 1</option>
                        <option value="2">Cs 2</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-span-6">
                    <span className="text-sm font-bold text-gray-400">
                      CS Acquisition (Phone)
                    </span>
                    <div className="mt-3.5">
                      <input
                        // readOnly
                        placeholder="Auto Filed"
                        type="text"
                        className="bg-gray-200 rounded-lg border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                        onChange={(e) => setCSPhone(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-span-6">
                    <span className="text-sm font-bold text-gray-400">
                      Campaign
                    </span>
                    <div className="mt-3.5">
                      <select
                        placeholder="Select Campaign"
                        className="bg-white rounded-lg border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                        onChange={(e) => setCampaign(e.target.value)}
                      >
                        <option hidden>Select Campaign</option>
                        <option value="1">Campaign 1</option>
                        <option value="2">Campaign 2</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-span-6">
                    <span className="text-sm font-bold text-gray-400">
                      Promo
                    </span>
                    <div className="mt-3.5">
                      <select
                        placeholder="Select Promo"
                        className="bg-white rounded-lg border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                        onChange={(e) => setPromo(e.target.value)}
                      >
                        <option hidden>Select Promo</option>
                        <option value="1">Promo 1</option>
                        <option value="2">Promo 2</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-span-6">
                    <span className="text-sm font-bold text-gray-400">
                      Resi
                    </span>
                    <div className="mt-3.5">
                      <input
                        type="text"
                        className="bg-white rounded-lg border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                        onChange={(e) => setResi(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-span-6">
                    <span className="text-sm font-bold text-gray-400">
                      Courier
                    </span>
                    <div className="mt-3.5">
                      <select
                        placeholder="Select Courier"
                        className="bg-white rounded-lg border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                        onChange={(e) => setCourier(e.target.value)}
                      >
                        <option hidden>Select Courier</option>
                        <option value="1">Courier 1</option>
                        <option value="2">Courier 2</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-span-6">
                    <span className="text-sm font-bold text-gray-400">
                      Note
                    </span>
                    <div className="mt-3.5">
                      <input
                        type="text"
                        className="bg-white rounded-lg border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                        onChange={(e) => setNote(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-span-6">
                    <span className="text-sm font-bold text-gray-400">
                      Date
                    </span>
                    <div className="mt-3.5">
                      <input
                        type="date"
                        className="bg-white rounded-lg border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                        onChange={(e) => setLatestPurchase(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-span-6">
                    <span className="text-sm font-bold text-gray-400">
                      Buying Reason
                    </span>
                    <div className="mt-3.5">
                      <input
                        type="text"
                        className="bg-white rounded-lg border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                        onChange={(e) => setReason(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div> */}
              <div className="">
                <div className="py-10 space-y-5">
                  {msg != "" ? (
                    <div
                      className="p-3 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800 my-3"
                      role="alert"
                    >
                      {msg}
                    </div>
                  ) : null}
                  <div className="grid md:grid-cols-5 grid-cols-2 gap-3 bg-gray-50 p-5 rounded-lg">
                    <div className="space-y-2">
                      <p className="text-sm">Buying Date</p>
                      <input
                        type="date"
                        onChange={(e) => setBuyingDate(e.target.value)}
                        className="w-full bg-white p-2 rounded-lg text-xs text-gray-400 focus:ring-transparent border-0"
                      />
                    </div>
                    <div className="space-y-2">
                      <p className="text-sm">Transaction Number</p>
                      <input
                        type="text"
                        className="w-full bg-white p-2 rounded-lg text-xs text-gray-400 focus:ring-transparent border-0"
                      />
                    </div>
                    <div className="space-y-2">
                      <p className="text-sm">Customer</p>
                      <input
                        type="text"
                        className="w-full bg-white p-2 rounded-lg text-xs text-gray-400 focus:ring-transparent border-0"
                        value={name}
                        readOnly
                      />
                    </div>
                    <div className="space-y-2">
                      <p className="text-sm">Status by Today</p>
                      <select
                        className="w-full bg-white p-2 rounded-lg text-xs text-gray-400 focus:ring-transparent border-0"
                        onChange={(e) => setStatus(e.target.value)}
                      >
                        <option hidden>Select Status</option>
                        <option value="closing">Paid</option>
                        <option value="processing">Unpaid</option>
                      </select>
                    </div>
                    <div className="space-y-2">
                      <p className="text-sm">Memo</p>
                      <select 
                      value={selectedLeveling}
                      onChange={onSelectLeveling}
                      className="w-full bg-white p-2 rounded-lg text-xs text-gray-400 focus:ring-transparent border-0">
                        <option hidden>Select Memo</option>
                        {/* {memo && memo.map((val, index) =>
                          <option key={index} value={val.id}>
                            {val.levelingName}
                          </option>
                        )} */}
                        {memo ? memo.map((val, index) =>
                          <option key={index} value={val.levelingName}>
                            {val.levelingName}
                          </option>
                        ) : null}

                      </select>
                    </div>
                  </div>
                  <div className="bg-gray-50 p-5 rounded-lg space-y-3">
                    <div className="space-y-3">
                      {/* {product.formValues.map((element, index) => ( */}
                      {inputFields.map((input, index) => {
                        return (
                        <>
                          <div className="grid grid-cols-5">
                            <div
                              className="col-span-4 grid md:grid-cols-4 gap-3"
                              key={index}
                            >
                              <div className="space-y-2">
                                <p className="text-sm">Product</p>
                                <select
                                  className="w-full bg-white p-2 rounded-lg text-xs text-gray-400 focus:ring-transparent border-0"
                                  name="productId"
                                  onChange={(e) => {
                                    // console.log(e.target.value, 'ass')
                                    // onSelectProduct,
                                    // console.log(input.productId)
                                    handleFormChange(index, e); 
                                    let selectedIndex = e.target.children[e.target.selectedIndex].getAttribute('data-index')
                                    setUnit(productList[selectedIndex].unit)
                                    setPrice(productList[selectedIndex].price)
                                   
                                  }}
                                  value={input.productId || ""}
                                  // value={selectedProduct}
                                  
                                >

                                  <option hidden value="">Select Product</option>
                                  {/* {productList &&
                                    productList.map(function (value, index) {
                                      return (
                                        <>
                                          <option key={index} data-index={index} value={value.id}>
                                            {value.name}
                                          </option>
                                        </>
                                      );
                                    })} */}
                                    {productList ? productList.map(function (value, index) {
                                      return (
                                        <option key={index} data-index={index} value={value.id}>
                                          {value.name}
                                        </option>
                                      );
                                    }) : null}

                                </select>
                              </div>
                              <div className="space-y-2">
                                <p className="text-sm">Quantity</p>
                                <input
                                  type="number"
                                  name="qty"
                                  value={input.qty || ""}
                                  onChange={(e) => handleFormChange(index, e)}
                                  className="w-full bg-white p-2 rounded-lg text-xs text-gray-400 focus:ring-transparent border-0"
                                />
                              </div>
                              <div className="space-y-2">
                                <p className="text-sm">Unit</p>
                                {/* <p className="w-full bg-gray-100 p-2 rounded-lg text-xs text-gray-400">
                               
                                </p> */}
                                <input
                                    type="text"
                                    value={input.productId ? unit : ''}
                                    className="w-full bg-gray-100 p-2 rounded-lg text-xs text-gray-400 focus:ring-transparent border-0"
                                    readOnly
                                  />
                              </div>
                              <div className="space-y-2">
                                <p className="text-sm">Price</p>
                                {/* <p className="w-full bg-gray-100 p-2 rounded-lg text-xs text-gray-400">
                                  Rp 100.000
                                </p> */}
                                 <input
                                    type="text"
                                    value={input.productId ? price : ''}
                                    className="w-full bg-gray-100 p-2 rounded-lg text-xs text-gray-400 focus:ring-transparent border-0"
                                    readOnly
                                  />
                              </div>
                            </div>
                            <div className="col-span-1">
                              {index ? (
                                <>
                                  <button
                                    type="button"
                                    className="bg-red-500 hover:bg-red-700 text-white font-bold px-2 mt-8 rounded"
                                    // onClick={() => removeFormFields(index)}
                                    onClick={removeFields}
                                  >
                                    X
                                  </button>
                                </>
                              ) : null}
                            </div>
                          </div>
                        </>
                      // ))}
                      )
                      })}
                      <div className="button-section mt-5">
                        <button
                          className="bg-[#E7FFF7] text-[#4FC29B] rounded py-2 px-3 text-sm"
                          type="button"
                          // onClick={() => addFormFields()}
                          onClick={() => addFields()}
                        >
                          + Add Product
                        </button>
                      </div>
                    </div>
                    <div className="grid md:grid-cols-5 grid-cols-2 gap-3 ">
                      <div className="space-y-2">
                        <p className="text-sm">Payment</p>
                        <select
                          placeholder="Select Payment Method"
                          className="w-full bg-white p-2 rounded-lg text-xs text-gray-400 focus:ring-transparent border-0"
                          onChange={(e) => setPaymentMethod(e.target.value)}
                        >
                          <option hidden>Select Payment Method</option>
                          <option value="transfer">Transfer</option>
                          <option value="cod">COD</option>
                        </select>
                      </div>
                      <div className="space-y-2">
                        <p className="text-sm">Note</p>
                        <input
                          type="text"
                          onChange={(e) => setNote(e.target.value)}
                          className="w-full bg-white p-2 rounded-lg text-xs text-gray-400 focus:ring-transparent border-0"
                        />
                      </div>
                      <div className="space-y-2">
                        <p className="text-sm">Buying Reason</p>
                        <input
                          type="text"
                          onChange={(e) => setBuyingReason(e.target.value)}
                          className="w-full bg-white p-2 rounded-lg text-xs text-gray-400 focus:ring-transparent border-0"
                        />
                      </div>
                      <div className="space-y-2">
                        <p className="text-sm">Promo</p>
                        <select className="w-full bg-white p-2 rounded-lg text-xs text-gray-400 focus:ring-transparent border-0"
                           value={selectedPromo}
                           onChange={onSelectPromo}
                        >
                          <option hidden>Select Promo</option>
                       
                            {promo && Array.isArray(promo) && promo.map(function (value, index) {
                                return (
                                    <option key={index} value={value.id}>
                                        {value.promoName}
                                    </option>
                                );
                              }) || null}

                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="grid md:grid-cols-5 grid-cols-2 gap-3 bg-gray-50 p-5 rounded-lg">
                    <div className="space-y-2">
                      <p className="text-sm">Customer Service Name</p>
                      <select className="w-full bg-white p-2 rounded-lg text-xs text-gray-400 focus:ring-transparent border-0"
                         value={selectedCs}
                         onChange={(e) => {
                           console.log(e.target.value);
                           onSelectCS(e);
                           let selectedIndex = e.target.children[e.target.selectedIndex].getAttribute('data-index')
                            // console.log(constumer[selectedIndex])
                            setPhoneCs(costumer[selectedIndex].phone)
                         }
                          
                          
                        }
                         
                      >
                        <option hidden>Select Customer Service</option>
                        {/* {costumer && costumer.map(function (value, index) {
                          // console.log(value)
                          return (
                            <>
                              <option key={index} data-index={index} value={value.id}>
                                {value.name}
                              </option>
                            </>
                          );
                        })} */}

                      {/* {costumer && costumer.map(function (value, index) {
                          // console.log(value)
                          return (
                            <>
                              <option key={index} data-index={index} value={value.id}>
                                {value.name}
                              </option>
                            </>
                          );
                        })} */}
                        {costumer && costumer.map(function (value, index) {
                          return (
                            <option key={index} data-index={index} value={value.idCsTable}>
                              {value.name}
                            </option>
                          );
                        })}
                        {!costumer && <option disabled>Pilihan tidak tersedia</option>}

                      </select>
                    </div>
                    <div className="space-y-2">
                      <p className="text-sm">Customer Service Phone</p>
                      <input
                        value={phoneCs}
                        type="text"
                        className="w-full bg-white p-2 rounded-lg text-xs text-gray-400 focus:ring-transparent border-0"
                        // placeholder="auto fill"
                        readonly
                      />
                    </div>
                    <div className="space-y-2">
                      <p className="text-sm">Channel</p>
                      <select
                        onChange={(e) => setChanel(e.target.value)}
                       className="w-full bg-white p-2 rounded-lg text-xs text-gray-400 focus:ring-transparent border-0">
                        <option hidden>Select Channel</option>
                        <option value="Whatsapp">Whatsapp</option>
                        <option value="Marketplace">Marketplace</option>
                        <option value="Other">Other</option>
                      </select>
                    </div>
                    <div className="space-y-2">
                      <p className="text-sm">ADV</p>
                      
                      <select 
                        onChange={(e) => setAdv(e.target.value)} 
                      className="w-full bg-white p-2 rounded-lg text-xs text-gray-400 focus:ring-transparent border-0">
                        <option hidden>Select ADV</option>
                        <option value="CRM">CRM</option>
                        <option value="Marketplace">Marketplace</option>
                      </select>
                    </div>
                    <div className="space-y-2">
                      <p className="text-sm">Category</p>
                      <select
                      
                        onChange={(e) => setCategory(e.target.value)} 
                      className="w-full bg-white p-2 rounded-lg text-xs text-gray-400 focus:ring-transparent border-0">
                        <option hidden>Select Category</option>
                        <option value="Retail">Retail</option>
                        <option value="CRM">CRM</option>
                      </select>
                    </div>
                  </div>
                  <div className="grid md:grid-cols-5 grid-cols-2 gap-3 bg-gray-50 p-5 rounded-lg">
                    <div className="space-y-2">
                      <p className="text-sm">Warehouse</p>
                      <select
                        placeholder="Select Warehouse"
                        className="w-full bg-white p-2 rounded-lg text-xs text-gray-400 focus:ring-transparent border-0"
                        onChange={(e) => setSelectedWarehouse(e.target.value)}
                      >
                        <option hidden>Select Warehouse</option>
                        {warehouse &&
                          warehouse.map((val, index) => (
                            <option key={index} value={val.id}>
                              {val.warehouseName}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div className="space-y-2">
                      <p className="text-sm">Courier</p>
                      <select onChange={(e) => setCourir(e.target.value)}  
                        className="w-full bg-white p-2 rounded-lg text-xs text-gray-400 focus:ring-transparent border-0">
                        <option hidden>Select Courier</option>
                        <option value="jne">JNE</option>
                        <option value="jnt">JNT</option>
                        <option value="pos">POS</option>
                        <option value="tiki">tiki</option>
                        <option value="rpx">rpx</option>
                        <option value="pandu">pandu</option>
                        <option value="wahana">wahana</option>
                        <option value="sicepat">sicepat</option>
                        <option value="jnt">jnt</option>
                        <option value="pahala">pahala</option>
                        <option value="sap">sap</option>
                        <option value="jet">jet</option>
                        <option value="indah">indah</option>
                        <option value="dse">dse</option>
                        <option value="slis">slis</option>
                        <option value="first">first</option>
                        <option value="ncs">ncs</option>
                        <option value="star">star</option>
                        <option value="ninja">ninja</option>
                        <option value="lion">lion</option>
                        <option value="idl">idl</option>
                        <option value="sentral">sentral</option>
                        <option value="anteraja">anteraja</option>
                        <option value="jtl">jtl</option>

                   
                      </select>
                    </div>
                    <div className="space-y-2">
                      <p className="text-sm">Total per line/omzet</p>
                      <input
                        type="text"
                        value={totalPrice}
                        className="w-full bg-white p-2 rounded-lg text-xs text-gray-400 focus:ring-transparent border-0"
                        readOnly
                      />
                    </div>
                    <div className="space-y-2">
                      <p className="text-sm">Shipping Fee</p>
                      <input
                        type="text"
                        // onChange={(e) => setShipping(e.target.value)}
                        value={shipping}
                        onChange={handleShippingChange}

                        className="w-full bg-white p-2 rounded-lg text-xs text-gray-400 focus:ring-transparent border-0"
                      />
                    </div>
                    <div className="space-y-2">
                      <p className="text-sm">Subsidi by Company</p>
                      <input
                        type="text"
                        // onChange={(e) => setSubsidi(e.target.value)}
                        value={subsidi}
                        onChange={handleSubsidiChange}
                        
                        className="w-full bg-white p-2 rounded-lg text-xs text-gray-400 focus:ring-transparent border-0"
                      />
                    </div>
                    <div className="space-y-2">
                      <p className="text-sm">Total + Ongkir</p>
                      <input
                        type="text"
                        onChange={(e) => setTotalCost(e.target.value)} 
                        className="w-full bg-white p-2 rounded-lg text-xs text-gray-400 focus:ring-transparent border-0"
                      />
                    </div>
                    <div className="space-y-2">
                      <p className="text-sm">Resi</p>
                      <input
                        type="text"
                        className="w-full bg-white p-2 rounded-lg text-xs text-gray-400 focus:ring-transparent border-0"
                        onChange={(e) => setResi(e.target.value)}
                      />
                    </div>
                    <div className="space-y-2">
                      <p className="text-sm">Keterangan Retur</p>
                      <input
                        type="text"
                        onChange={(e) => setReturnReason(e.target.value)} 
                        className="w-full bg-white p-2 rounded-lg text-xs text-gray-400 focus:ring-transparent border-0"
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* end: Data Order */}
              {/*bagin: Button Save */}
              <div className="flex flex-row justify-center md:justify-end gap-5 py-5">
                <Link
                  to={`/view-customer/${id}`}
                  className="text-gray-400 rounded-lg bg-gray-100 hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-200 bg-gray-200 font-medium text-sm px-5 py-2.5 text-center"
                >
                  Cancel
                </Link>
                <button
                  type="submit"
                  className="text-white rounded-lg bg-[#4FC29B] hover:bg-[#4FC29B] focus:ring-4 focus:outline-none focus:ring-[#4FC29B] font-medium text-sm px-5 py-2.5 text-center"
                  onClick={postData}
                >
                  Save
                </button>
              </div>
              {/*end: Button Save */}
            </div>
          </div>
          {/* end: Main */}
        </div>
      </div>
    </>
  );
}

export default AddOrder;
