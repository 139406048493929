import "aos/dist/aos.css";
import React, { Fragment, useEffect, useState } from "react";
import AddCS from "../../Components/Partials/Modal/AddCS";
import Sidebar from "../../Components/Sidebar/SidebarWarehouse";
import CardTemplate from "../../Components/Partials/Card/CardTemplate";
import arrowLeft from "../../Assets/icon/comunication/arrowLeft.svg";
import TableBroadcast from "../../Components/Partials/Table/TableBroadcast";
import { Link, useNavigate } from "react-router-dom";
import TableWarehouse from "../../Components/Partials/Table/TableWarehouse";
import AddIcon from "@mui/icons-material/Add";
import {
  createWarehouse,
  deleteWarehouse,
  getWarehouse,
  updateWarehouse,
} from "../../Network/Lib/warehouse";
import { logout } from "../../Network/Lib/auth";
import {
  getAllCity,
  getAllProvince,
  getAllSubdis,
} from "../../Network/Lib/rajaOngkir";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/solid";
import Delete from "../../Assets/img/Delete-data.png";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function Warehouse() {
  const navigate = useNavigate();
  const [refreshApi, setRefreshApi] = useState(false);

  const [navSideBar, setNavSideBar] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showEdit, setShowEdit] = useState(false);

  const [data, setData] = useState([]);

  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [provinceList, setProvinceList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [subdistrictList, setSubdistrictList] = useState([]);
  const [selectedProvince, Edit] = useState();
  const [selectedCity, setSelectedCity] = useState();
  const [selectedSubdis, setSelectedSubdis] = useState();

  const [id, setId] = useState("");
  const [editName, setEditName] = useState("");
  const [editAddress, setEditAddress] = useState("");
  const [editSelectedProvince, setEditSelectedProvince] = useState("");
  const [editSelectedCity, setEditSelectedCity] = useState("");
  const [editSelectedSubdis, setEditSelectedSubdis] = useState("");

  const [msg, setMsg] = useState("");

  useEffect(() => {
    getWarehouse().then((response) => {
      // console.log(response.data.data);
      setData(response.data.data);
    });
    getAllProvince().then((response) => {
      setProvinceList(response.data.data);
    });
    setRefreshApi(false);
  }, [refreshApi]);

  const onSelectProvince = async (e) => {
    Edit(e.target.value);
    await getAllCity(e.target.value)
      .then((response) => {
        setCityList(response.data.data);
      })
      .catch((e) => {});
  };

  const onSelectCity = async (e) => {
    setSelectedCity(e.target.value);
    await getAllSubdis(e.target.value)
      .then((response) => {
        setSubdistrictList(response.data.data);
      })
      .catch((e) => {});
  };

  const onSelectSubdis = async (e) => {
    setSelectedSubdis(e.target.value);
  };

  const onEditSelectProvince = async (e) => {
    setEditSelectedProvince(e.target.value);
    console.log("value", e.target.value);
    await getAllCity(e.target.value)
      .then((response) => {
        setCityList(response.data.data);
      })
      .catch((e) => {});
  };

  const onEditLoadProvince = async (e) => {
    console.log(e);
    setEditSelectedProvince(e);
    await getAllCity(e)
      .then((response) => {
        setCityList(response.data.data);
      })
      .catch((e) => {});
  };

  const onEditSelectCity = async (e) => {
    setEditSelectedCity(e.target.value);
    await getAllSubdis(e.target.value)
      .then((response) => {
        setSubdistrictList(response.data.data);
      })
      .catch((e) => {});
  };
  const onEditLoadCity = async (e) => {
    setEditSelectedCity(e);
    console.log(e);
    await getAllSubdis(e)
      .then((response) => {
        setSubdistrictList(response.data.data);
      })
      .catch((e) => {});
  };
  const onEditSelectSubdis = async (e) => {
    setEditSelectedSubdis(e.target.value);
  };

  const postData = async () => {
    const form = {
      warehouseName: name,
      address: address,
      provinceId: selectedProvince,
      cityId: selectedCity,
      subdistrictId: selectedSubdis,
    };
    console.log(form);
    try {
      await createWarehouse(form)
        .then((response) => {
          setRefreshApi(true);
          console.log(response);
          setShowAdd(false);
        })
        .catch((e) => {
          if (e.response) {
            setMsg(e.response.data.message);
            console.log(e.response.data);
          }
        });
    } catch (error) {}
  };

  const postEditData = async () => {
    const form = {
      warehouseName: editName,
      address: editAddress,
      provinceId: editSelectedProvince,
      cityId: editSelectedCity,
      subdistrictId: editSelectedSubdis,
    };
    console.log(form);
    try {
      await updateWarehouse(id, form)
        .then((response) => {
          setRefreshApi(true);
          console.log(response);
          setShowEdit(false);
        })
        .catch((e) => {
          if (e.response) {
            setMsg(e.response.data.message);
            console.log(e.response.data);
          }
        });
    } catch (error) {}
  };
  const deleteData = async () => {
    try {
      const response = await deleteWarehouse(id).then(async (response) => {
        console.log(response);
        setRefreshApi(true)
        setShowDelete(false);
      });
      console.log(response).catch((e) => {
        console.log(e.response);
      });
    } catch (error) {}
  };

  const signOut = () => {
    logout();
    navigate("/login");
  };
  return (
    <>
      <div className="bg-gradient-to-r from-[#54DAA1] to-[#2DCE89]">
        <div className="relative">
          <aside
            className={
              navSideBar === true
                ? "w-24 h-screen p-5 fixed top-0 left-0 right-0 z-10"
                : "w-3/12 h-screen p-5 fixed top-0 left-0 right-0 z-10"
            }
            aria-label="Sidebar"
          >
            <button
              className="absolute bottom-96 right-1 w-10 h-10 bg-gradient-to-r from-[#54DAA1] to-[#2DCE89] rounded-full shadow-lg flex justify-center items-center"
              onClick={() => setNavSideBar(!navSideBar)}
            >
              {navSideBar ? (
                <svg
                  width="9"
                  height="16"
                  viewBox="0 0 9 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 14.3333L7.66667 7.66668L1 1.00001"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              ) : (
                <svg
                  width="10"
                  height="16"
                  viewBox="0 0 10 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.33325 14.6667L1.66659 7.99999L8.33325 1.33332"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              )}
            </button>
            <Sidebar navSideBar={navSideBar} />
          </aside>
        </div>
        <div
          className={
            navSideBar === true
              ? "ml-24 h-72 w-11/12 p-5 space-y-5"
              : "ml-auto h-72 w-9/12 p-5 space-y-5"
          }
        >
          {/* Header */}
          <div className="flex flex-row justify-between items-center">
            <div className="text-white space-y-2">
              <div className="flex flex-row space-x-3">
                <p className="text-sm">Pages</p>
                <p className="text-sm">/</p>
                <a href="/communication" className="text-sm">
                  Communication
                </a>
                <p className="text-sm">/</p>
                <a href="/Broadcast" className="text-sm">
                  Broadcast
                </a>
              </div>
              <p className="font-semibold">Broadcast</p>
            </div>
            <div className="flex flex-row items-center gap-5">
              <a href="#" className="flex items-center gap-2">
                <svg
                  width="21"
                  height="20"
                  viewBox="0 0 21 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.4938 2.52235C12.7336 1.70165 11.6719 1.24969 10.5 1.24969C9.32191 1.24969 8.25668 1.69891 7.50004 2.51454C6.73519 3.33915 6.36254 4.45985 6.45004 5.67001C6.62348 8.05751 8.44027 9.99969 10.5 9.99969C12.5598 9.99969 14.3735 8.0579 14.5496 5.67079C14.6383 4.47157 14.2633 3.35321 13.4938 2.52235Z"
                    fill="white"
                  />
                  <path
                    d="M17.3752 18.7488H3.62522C3.44525 18.7511 3.26701 18.7133 3.10349 18.6381C2.93997 18.5629 2.79526 18.4522 2.67991 18.314C2.426 18.0105 2.32366 17.596 2.39944 17.1769C2.72913 15.348 3.75804 13.8117 5.37522 12.7332C6.81194 11.7757 8.63186 11.2488 10.5002 11.2488C12.3686 11.2488 14.1885 11.7761 15.6252 12.7332C17.2424 13.8113 18.2713 15.3476 18.601 17.1765C18.6768 17.5957 18.5744 18.0101 18.3205 18.3136C18.2052 18.4519 18.0605 18.5626 17.897 18.6379C17.7335 18.7132 17.5552 18.7511 17.3752 18.7488Z"
                    fill="white"
                  />
                </svg>
                <p className="text-white">{localStorage.getItem("nameUser")}</p>
              </a>
              <button onClick={signOut} className="bg-white p-3 rounded-lg">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.5625 2.8125H2.1875V17.8125H6.5625M13.4375 5.9375L17.8125 10.3125L13.4375 14.6875M7.1875 10.3125H17.8125"
                    stroke="#EF4444"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            </div>
          </div>
          {/* start: Main */}

          <div className="grid grid-cols-12 gap-5">
            <div className="col-span-12">
              <div className="bg-white shadow-lg h-full rounded-lg p-10">
                {/* TableBroadcast */}
                <div className="">
                  <div className="flex flex-col md:flex-row justify-between ">
                    <div className="py-2 flex flex-col">
                      <span className="font-semibold text-lg">Warehouse</span>
                      <span className="text-sm text-gray-400">
                        {data?.length} data
                      </span>
                    </div>
                    <div className="flex flex-row justify-between">
                      <div className="">
                        <a
                          href="#"
                          onClick={() => setShowAdd(true)}
                          className="flex flex-row justify-center text-white rounded-lg bg-[#4FC29B] hover:bg-[#4FC29B] focus:ring-4 focus:outline-none focus:ring-[#4FC29B] font-medium text-sm px-5 py-2.5 text-center"
                        >
                          <AddIcon fontSize="small"></AddIcon>
                          <span className="ml-5 text-white hidden md:block">
                            Add Warehouse
                          </span>
                        </a>
                      </div>
                      {/* <div className="ml-5">
                        <div className="relative w-ful">
                          <div className="flex absolute inset-y-5 left-0 items-center pl-3 pointer-events-none">
                            <svg
                              className="w-5 h-5 text-gray-500 dark:text-gray-400"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                                clipRule="evenodd"
                              ></path>
                            </svg>
                          </div>
                          <input
                            type="text"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder="Search"
                          />
                        </div>
                      </div> */}
                    </div>
                  </div>
                  <div className="">
                    {/* <TableWarehouse /> */}
                    <div className="overflow-x-auto md:h-screen">
                      <table className="w-full text-sm text-left text-gray-500">
                        <thead className="text-sm text-gray-400">
                          <tr className="text-sm text-gray-400 bg-gray-100">
                            <th scope="col" className="px-6 py-3 w-52">
                              Name
                            </th>
                            <th scope="col" className="px-6 py-3 w-52">
                              Province
                            </th>
                            <th scope="col" className="px-6 py-3 w-52">
                              City
                            </th>
                            <th scope="col" className="px-6 py-3 w-52">
                              Districts
                            </th>
                            <th scope="col" className="px-6 py-3 w-52">
                              Action
                            </th>
                          </tr>
                        </thead>
                        {data &&
                          data.map((val, index) => (
                            <tbody>
                              <tr key={index} className="bg-white">
                                <td className="px-6 py-4">
                                  {val.warehouseName}
                                </td>
                                <td className="px-6 py-4">{val.province}</td>
                                <td className="px-6 py-4">{val.city}</td>
                                <td className="px-6 py-4">{val.subdistrict}</td>
                                <td className="py-4 col-span-2 text-blue-400">
                                  <Menu
                                    as="div"
                                    className="relative inline-block text-left"
                                  >
                                    <div>
                                      <Menu.Button className="inline-flex justify-center w-full rounded-md border border-[#F5F8FA] shadow-sm px-4 py-2 bg-[#F5F8FA] text-sm font-medium text-gray-700 hover:bg-[#F5F8FA] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-[#F5F8FA] focus:ring-[#F5F8FA]">
                                        Action
                                        <ChevronDownIcon
                                          className="-mr-1 ml-2 h-5 w-5"
                                          aria-hidden="true"
                                        />
                                      </Menu.Button>
                                    </div>

                                    <Transition
                                      as={Fragment}
                                      enter="transition ease-out duration-100"
                                      enterFrom="transform opacity-0 scale-95"
                                      enterTo="transform opacity-100 scale-100"
                                      leave="transition ease-in duration-75"
                                      leaveFrom="transform opacity-100 scale-100"
                                      leaveTo="transform opacity-0 scale-95"
                                    >
                                      <Menu.Items className="origin-top-right z-10 absolute right-0 mt-2 w-20 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                        <div className="py-1">
                                          <Menu.Item>
                                            {({ active }) => (
                                              <a
                                                href="#"
                                                onClick={() => {
                                                  setShowEdit(true);
                                                  setId(val.id);
                                                  setEditName(
                                                    val.warehouseName
                                                  );
                                                  setEditAddress(val.address);
                                                  onEditLoadProvince(
                                                    val.provinceId
                                                  );
                                                  onEditLoadCity(val.cityId);
                                                  setEditSelectedSubdis(
                                                    val.subdistrictId
                                                  );
                                                }}
                                                className={classNames(
                                                  active
                                                    ? "bg-gray-100 text-gray-900"
                                                    : "text-gray-700",
                                                  "block px-4 py-2 text-sm"
                                                )}
                                              >
                                                Edit
                                              </a>
                                            )}
                                          </Menu.Item>
                                          <Menu.Item>
                                            {({ active }) => (
                                              <a
                                                href="#"
                                                onClick={() => {
                                                  setShowDelete(true);
                                                  setId(val.id);
                                                }}
                                                className={classNames(
                                                  active
                                                    ? "bg-gray-100 text-gray-900"
                                                    : "text-gray-700",
                                                  "block px-4 py-2 text-sm"
                                                )}
                                              >
                                                Delete
                                              </a>
                                            )}
                                          </Menu.Item>
                                        </div>
                                      </Menu.Items>
                                    </Transition>
                                  </Menu>
                                </td>
                              </tr>
                            </tbody>
                          ))}
                      </table>
                    </div>
                  </div>
                </div>
                {/* TableBroadcast */}
              </div>
            </div>
          </div>

          {/* end: Main */}
        </div>
      </div>

      {showAdd ? (
        <>
          <div className="bg-black bg-opacity-75 overflow-y-auto overflow-x-hidden fixed flex justify-center top-0 left-0 right-0 bottom-0 z-50 w-full md:inset-0 h-modal md:h-full">
            {/* <div className="relative w-full max-w-2xl h-full md:h-auto"> */}
            {/* ini yang ditambahin */}
            <div className="relative w-full max-w-lg h-full md:h-auto mt-10">
              {/* ini yang ditambahin */}
              <div className="relative bg-[#F5F8FA] rounded-lg shadow dark:bg-gray-700">
                <div className="flex justify-between items-start p-4 rounded-t dark:border-gray-600">
                  <h3 className="text-xl font-semibold">Add Warehouse</h3>
                  <button
                    type="button"
                    className="bg-transparent rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                    onClick={() => setShowAdd(false)}
                  >
                    <svg
                      className="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </button>
                </div>
                {msg != "" ? (
                  <div
                    className="p-3 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800 my-3"
                    role="alert"
                  >
                    {msg}
                  </div>
                ) : null}
                <div className="p-6 space-y-6 items-center">
                  <div className="">
                    <label className="block tracking-wide text-gray-700 text-xs font-bold mb-2">
                      Warehouse Name
                    </label>
                    <input
                      className="block appearance-none w-full border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      type="text"
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                  <h3 className="text-black font-semibold">Location</h3>
                  <div className="">
                    <label className="block tracking-wide text-gray-700 text-xs font-bold mb-2">
                      Address
                    </label>
                    <textarea
                      className="block appearance-none w-full border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      type="text"
                      onChange={(e) => setAddress(e.target.value)}
                      rows="2"
                    ></textarea>
                  </div>
                  <div className="grid md:grid-cols-2 grid-cols-1 gap-3">
                    <div className="">
                      <label className="block tracking-wide text-gray-700 text-xs font-bold mb-2">
                        Province
                      </label>
                      <div className="relative">
                        <select
                          placeholder="Select Province"
                          className="bg-white rounded-lg border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                          value={selectedProvince}
                          onChange={onSelectProvince}
                        >
                          <option hidden>Select Province</option>
                          {provinceList.map(function (value, index) {
                            return (
                              <>
                                <option key={index} value={value.province_id}>
                                  Provinsi {value.province}
                                </option>
                              </>
                            );
                          })}
                        </select>
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"></div>
                      </div>
                    </div>
                    <div className="">
                      <label className="block tracking-wide text-gray-700 text-xs font-bold mb-2">
                        City
                      </label>
                      <div className="relative">
                        {cityList.length == 0 ? (
                          <select
                            placeholder="Select City"
                            className="bg-white rounded-lg border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                            value={selectedCity}
                            onChange={onSelectCity}
                            disabled
                          >
                            <option hidden>Select City</option>
                            {cityList.map(function (value, index) {
                              return (
                                <>
                                  <option key={index} value={value.city_id}>
                                    {value.type} {value.city_name}
                                  </option>
                                </>
                              );
                            })}
                          </select>
                        ) : (
                          <select
                            placeholder="Select City"
                            className="bg-white rounded-lg border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                            value={selectedCity}
                            onChange={onSelectCity}
                          >
                            <option hidden>Select City</option>
                            {cityList.map(function (value, index) {
                              return (
                                <>
                                  <option key={index} value={value.city_id}>
                                    {value.type} {value.city_name}
                                  </option>
                                </>
                              );
                            })}
                          </select>
                        )}
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"></div>
                      </div>
                    </div>
                    <div className="">
                      <label className="block tracking-wide text-gray-700 text-xs font-bold mb-2">
                        Districts
                      </label>
                      <div className="relative">
                        {subdistrictList.length == 0 ? (
                          <select
                            placeholder="Select Subdistrict"
                            className="bg-white rounded-lg border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                            value={selectedSubdis}
                            onChange={onSelectSubdis}
                            disabled
                          >
                            <option hidden>Select Subdistrict</option>
                            {subdistrictList.map(function (value, index) {
                              return (
                                <>
                                  <option
                                    key={index}
                                    value={value.subdistrict_id}
                                  >
                                    {value.subdistrict_name}
                                  </option>
                                </>
                              );
                            })}
                          </select>
                        ) : (
                          <select
                            placeholder="Select Subdistrict"
                            className="bg-white rounded-lg border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                            value={selectedSubdis}
                            onChange={onSelectSubdis}
                          >
                            <option hidden>Select Subdistrict</option>
                            {subdistrictList.map(function (value, index) {
                              return (
                                <>
                                  <option
                                    key={index}
                                    value={value.subdistrict_id}
                                  >
                                    {value.subdistrict_name}
                                  </option>
                                </>
                              );
                            })}
                          </select>
                        )}
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"></div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-10 flex flex-row justify-end">
                    <button
                      className="py-3 px-6 rounded-xl bg-gray-100 hover:bg-gray-300 text-gray-400 hover:text-gray-600 font-medium"
                      onClick={() => setShowAdd(false)}
                    >
                      Cancel
                    </button>
                    <button
                      className="py-3 px-6 rounded-xl bg-green-400 hover:bg-green-500 text-white font-semibold ml-2"
                      onClick={postData}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
      {showEdit ? (
        <>
          <div className="bg-black bg-opacity-75 overflow-y-auto overflow-x-hidden fixed flex justify-center top-0 left-0 right-0 bottom-0 z-50 w-full md:inset-0 h-modal md:h-full">
            {/* <div className="relative w-full max-w-2xl h-full md:h-auto"> */}
            {/* ini yang ditambahin */}
            <div className="relative w-full max-w-lg h-full md:h-auto mt-10">
              {/* ini yang ditambahin */}
              <div className="relative bg-[#F5F8FA] rounded-lg shadow dark:bg-gray-700">
                <div className="flex justify-between items-start p-4 rounded-t dark:border-gray-600">
                  <h3 className="text-xl font-semibold">Edit Warehouse</h3>
                  <button
                    type="button"
                    className="bg-transparent rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                    onClick={() => setShowEdit(false)}
                  >
                    <svg
                      className="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </button>
                </div>
                <div className="p-6 space-y-6 items-center">
                  <div className="">
                    <label className="block tracking-wide text-gray-700 text-xs font-bold mb-2">
                      Warehouse Name
                    </label>
                    <input
                      className="block appearance-none w-full border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      type="text"
                      value={editName}
                      onChange={(e) => setEditName(e.target.value)}
                    />
                  </div>
                  <h3 className="text-black font-semibold">Location</h3>
                  <div className="">
                    <label
                      className="block tracking-wide text-gray-700 text-xs font-bold mb-2"
                      for="grid-state"
                    >
                      Address
                    </label>
                    <textarea
                      className="block appearance-none w-full border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      type="text"
                      value={editAddress}
                      onChange={(e) => setEditAddress(e.target.value)}
                      rows="2"
                    ></textarea>
                  </div>
                  <div className="grid md:grid-cols-2 grid-cols-1 gap-3">
                    <div className="">
                      <label
                        className="block tracking-wide text-gray-700 text-xs font-bold mb-2"
                        for="grid-state"
                      >
                        Province
                      </label>
                      <div className="relative">
                        <select
                          placeholder="Select Province"
                          className="bg-white rounded-lg border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                          value={editSelectedProvince}
                          onChange={onEditSelectProvince}
                        >
                          <option hidden>Select Province</option>
                          {provinceList.map(function (value, index) {
                            return (
                              <>
                                <option key={index} value={value.province_id}>
                                  Provinsi {value.province}
                                </option>
                              </>
                            );
                          })}
                        </select>
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"></div>
                      </div>
                    </div>
                    <div className="">
                      <label
                        className="block tracking-wide text-gray-700 text-xs font-bold mb-2"
                        for="grid-state"
                      >
                        City
                      </label>
                      <div className="relative">
                        <select
                          placeholder="Select City"
                          className="bg-white rounded-lg border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                          value={editSelectedCity}
                          onChange={onEditSelectCity}
                        >
                          <option hidden>Select City</option>
                          {cityList.map(function (value, index) {
                            return (
                              <>
                                <option key={index} value={value.city_id}>
                                  {value.type} {value.city_name}
                                </option>
                              </>
                            );
                          })}
                        </select>
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"></div>
                      </div>
                    </div>
                    <div className="">
                      <label
                        className="block tracking-wide text-gray-700 text-xs font-bold mb-2"
                        for="grid-state"
                      >
                        Districts
                      </label>
                      <div className="relative">
                        <select
                          placeholder="Select Subdistrict"
                          className="bg-white rounded-lg border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                          value={editSelectedSubdis}
                          onChange={onEditSelectSubdis}
                        >
                          <option hidden>Select Subdistrict</option>
                          {subdistrictList.map(function (value, index) {
                            return (
                              <>
                                <option
                                  key={index}
                                  value={value.subdistrict_id}
                                >
                                  {value.subdistrict_name}
                                </option>
                              </>
                            );
                          })}
                        </select>
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"></div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-10 flex flex-row justify-end">
                    <button
                      className="py-3 px-6 rounded-xl bg-gray-100 hover:bg-gray-300 text-gray-400 hover:text-gray-600 font-medium"
                      onClick={() => setShowEdit(false)}
                    >
                      Cancel
                    </button>
                    <button
                      className="py-3 px-6 rounded-xl bg-green-400 hover:bg-green-500 text-white font-semibold ml-2"
                      onClick={postEditData}
                    >
                      Save Changes
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}

      {showDelete ? (
        <>
          <div className="bg-black bg-opacity-75 overflow-y-auto overflow-x-hidden fixed flex justify-center top-0 left-0 right-0 bottom-0 z-50 w-full md:inset-0 md:h-full">
            {/* <div className="relative w-full max-w-2xl h-full md:h-auto"> */}
            {/* ini yang ditambahin */}
            <div className="fixed w-full max-w-xl md:h-auto mt-40">
              {/* ini yang ditambahin */}
              <div className="relative bg-[#F5F8FA] rounded-lg shadow dark:bg-gray-700">
                <div className="p-6 space-y-6 items-center">
                  <div className="flex flex-row justify-between items-start gap-5">
                    <div className="flex flex-row gap-5">
                      <img src={Delete} className="w-100 object-cover" />
                      <div className="flex flex-col">
                        <h1 className="font-bold">Delete Data?</h1>
                        <p className="font-semibold text-gray-400">
                          Data will be permanently deleted
                        </p>
                        <div className="mt-10 flex flex-row justify-start gap-3">
                          <button
                            className="text-sm py-2 px-5 rounded-xl text-white bg-green-400 hover:bg-green-500 font-semibold"
                            onClick={deleteData}
                          >
                            Yes
                          </button>
                          <button
                            className="text-sm px-3 rounded-xl text-gray-400 hover:text-gray-600 bg-gray-100 hover:bg-gray-200 font-semibold"
                            onClick={() => setShowDelete(false)}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                    <button
                      type="button"
                      className="bg-transparent hover:bg-gray-100 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                      onClick={() => setShowDelete(false)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="#A1A5B7"
                        className="bi bi-x-circle"
                        viewBox="0 0 16 16"
                      >
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
}

export default Warehouse;
