import React from "react";

class RepeaterComplaint extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formValues: [{ button: "", action: "" }],
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(i, e) {
    let formValues = this.state.formValues;
    formValues[i][e.target.name] = e.target.value;
    this.setState({ formValues });
  }

  addFormFields() {
    this.setState({
      formValues: [...this.state.formValues, { button: "", action: "" }],
    });
  }

  removeFormFields(i) {
    let formValues = this.state.formValues;
    formValues.splice(i, 1);
    this.setState({ formValues });
  }

  handleSubmit(event) {
    event.preventDefault();
    alert(JSON.stringify(this.state.formValues));
  }

  render() {
    return (
      <>
        <form onSubmit={this.handleSubmit}>
          {this.state.formValues.map((element, index) => (
            <div className="form-inline" key={index}>
              <div className="bg-white p-3 my-3 rounded-lg shadow-md">
                <div className="flex flex-row justify-between">
                  <h1 className="font-bold text-gray-600">
                    Complaint Component {index+1}
                  </h1>
                  {index ? (
                    <button
                      type="button"
                      className="bg-red-500 hover:bg-red-700 text-white font-bold px-2 rounded"
                      onClick={() => this.removeFormFields(index)}
                    >
                      X
                    </button>
                  ) : null}
                </div>
                <div className="p-3">
                  <label
                    className="block tracking-wide text-gray-400 text-sm font-semibold mb-2"
                    for="grid-state"
                  >
                    Complaint Analyst
                  </label>
                  <div className="relative">
                    <select className="block appearance-none w-full bg-gray-100 border border-white text-gray-400 font-medium py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-white">
                      <option hidden>Select Complaint Analyst</option>
                      <option>1</option>
                      <option>2</option>
                    </select>
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"></div>
                  </div>
                </div>
                <div className="p-3">
                  <label
                    className="block tracking-wide text-gray-400 text-sm font-semibold mb-2"
                    for="grid-state"
                  >
                    Complaint Classification
                  </label>
                  <div className="relative">
                    <select className="block appearance-none w-full bg-gray-100 border border-white text-gray-400 font-medium py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-white">
                      <option hidden>Select Complaint Analyst</option>
                      <option>1</option>
                      <option>2</option>
                    </select>
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"></div>
                  </div>
                </div>
                <div className="p-3">
                  <label
                    className="block tracking-wide text-gray-400 text-sm font-semibold mb-2"
                    for="grid-state"
                  >
                    Description
                  </label>
                  <div className="relative">
                    <textarea
                      type="text"
                      className="bg-gray-100 border border-white text-gray-700 text-sm font-medium rounded focus:ring-blue-500 focus:border-blue-500 block w-full"
                      placeholder="Write description here"
                      rows={5}
                    ></textarea>
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"></div>
                  </div>
                </div>
              </div>
            </div>
          ))}
          <div className="button-section">
            <button
              type="button"
              className="flex flex-row items-center gap-3 text-cyan-600 hover:text-cyan-700 font-medium"
              onClick={() => this.addFormFields()}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-plus-lg"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"
                />
              </svg>
              <span>Add Complaint Component</span>
            </button>
          </div>
        </form>
      </>
    );
  }
}
export default RepeaterComplaint;
