import React, { useState, useEffect } from "react";
import Profile from "../../../Assets/img/Profile.jpg";

function TableCS() {
  return (
    <div className="">
      <div className="overflow-x-auto md:h-80">
        <table className="w-full text-sm text-left text-gray-500">
          <thead className="text-sm text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3 w-36">
                Advertiser
              </th>
              <th scope="col" className="px-6 py-3 w-32">
                Operator
              </th>
              <th scope="col" className="px-6 py-3 w-32">
                Customer
              </th>
              <th scope="col" className="px-6 py-3 w-32">
                No. WhatsApp
              </th>
              <th scope="col" className="px-6 py-3 w-32">
                Product
              </th>
              <th scope="col" className="px-6 py-3 w-52">
                Date/Time
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="bg-white border-b">
              <td className="px-6 py-4"><p className="truncate">Rifan Tri Yulianto</p></td>
              <td className="px-6 py-4"><p className="truncate">Nurhayatun</p></td>
              <td className="px-6 py-4"><p className="truncate">Aspen Rosser</p></td>
              <td className="px-6 py-4"><p className="truncate">62821213131313</p></td>
              <td className="px-6 py-4"><p className="truncate">Freshmag</p></td>
              <td className="px-6 py-4"><p className="truncate">2022-01-12 09:59:27</p></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default TableCS;
