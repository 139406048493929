import api from '../apiClient'

export function createTemplate(data){
    return api.axiosClient.post('/template/create', data)
}

export function getAllTemplate(){
    return api.axiosClient.get('/template')
}

export function getTemplatePagination(limit, page){
    return api.axiosClient.get(`/template/limit=${limit}&page=${page}`)
}

export function getOne(id){
    return api.axiosClient.get(`/template/id=${id}`)
}

export function showTemplate(id) {
    return api.axiosClient.post(`/template/show`, {id:id})
}

export function deleteTemplate(id){
    return api.axiosClient.delete(`/template/delete/${id}`)
}


export function updateTemplate(data){
    return api.axiosClient.put(`/template/update/${data.id}`,data.data)
}