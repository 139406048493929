import "aos/dist/aos.css";
import React, { useState } from "react";
import IconCam from "../../Assets/icon/comunication/icon_cam.png";
import IconPoin from "../../Assets/icon/comunication/icon_poin.png";
import IconWa from "../../Assets/icon/comunication/icon_wa.png";
import IconQr from "../../Assets/icon/comunication/icon_qr.png";
import SidebarComunication from "../../Components/Sidebar/SidebarComunication";
import { logout } from "../../Network/Lib/auth";
import { useNavigate } from "react-router-dom";


function AddWhatsApp() {
  const navigate = useNavigate();
    const [navSideBar, setNavSideBar] = useState(false);
    
  const signOut =()=>{
    logout();
    navigate('/login')
  }
  return (
    <>
      <div className="bg-gradient-to-r from-[#54DAA1] to-[#2DCE89]">
        <div className="relative">
          <aside
            className={
              navSideBar === true
                ? "w-24 h-screen p-5 fixed top-0 left-0 right-0 z-10"
                : "w-3/12 h-screen p-5 fixed top-0 left-0 right-0 z-10"
            }
            aria-label="Sidebar"
          >
            <button className="absolute bottom-96 right-1 w-10 h-10 bg-gradient-to-r from-[#54DAA1] to-[#2DCE89] rounded-full shadow-lg flex justify-center items-center" onClick={() => setNavSideBar(!navSideBar)}>
              {navSideBar ? 
                <svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 14.3333L7.66667 7.66668L1 1.00001" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
              :
                <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8.33325 14.6667L1.66659 7.99999L8.33325 1.33332" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
              }


            </button>
            <SidebarComunication navSideBar={navSideBar}/>
          </aside>
        </div>
        <div className={navSideBar === true ? "ml-24 h-72 w-11/12 p-5 space-y-5" : "ml-auto h-72 w-9/12 p-5 space-y-5"}>
          {/* Header */}
          <div className="flex flex-row justify-between items-center">
            <div className="text-white space-y-2">
              <div className="flex flex-row space-x-3">
                <p className="text-sm">Pages</p>
                <p className="text-sm">/</p>
                <a href="/communication" className="text-sm">Communication</a>
                <p className="text-sm">/</p> 
                <a href="/loyalty" className="text-sm">Detail Product</a>
              </div>
              <p className="font-semibold">Detail Product</p>
            </div>
            <div className="flex flex-row items-center gap-5">
              <a href="#" className="flex items-center gap-2">
                <svg
                  width="21"
                  height="20"
                  viewBox="0 0 21 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.4938 2.52235C12.7336 1.70165 11.6719 1.24969 10.5 1.24969C9.32191 1.24969 8.25668 1.69891 7.50004 2.51454C6.73519 3.33915 6.36254 4.45985 6.45004 5.67001C6.62348 8.05751 8.44027 9.99969 10.5 9.99969C12.5598 9.99969 14.3735 8.0579 14.5496 5.67079C14.6383 4.47157 14.2633 3.35321 13.4938 2.52235Z"
                    fill="white"
                  />
                  <path
                    d="M17.3752 18.7488H3.62522C3.44525 18.7511 3.26701 18.7133 3.10349 18.6381C2.93997 18.5629 2.79526 18.4522 2.67991 18.314C2.426 18.0105 2.32366 17.596 2.39944 17.1769C2.72913 15.348 3.75804 13.8117 5.37522 12.7332C6.81194 11.7757 8.63186 11.2488 10.5002 11.2488C12.3686 11.2488 14.1885 11.7761 15.6252 12.7332C17.2424 13.8113 18.2713 15.3476 18.601 17.1765C18.6768 17.5957 18.5744 18.0101 18.3205 18.3136C18.2052 18.4519 18.0605 18.5626 17.897 18.6379C17.7335 18.7132 17.5552 18.7511 17.3752 18.7488Z"
                    fill="white"
                  />
                </svg>
                <p className="text-white">{localStorage.getItem("nameUser")}</p>
              </a>
              <a href="#" className="bg-white p-3 rounded-lg">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.1907 13.3321C17.1259 13.2539 17.0622 13.1758 16.9997 13.1004C16.1403 12.061 15.6204 11.4336 15.6204 8.49104C15.6204 6.9676 15.2559 5.7176 14.5376 4.7801C14.0079 4.08752 13.2919 3.56213 12.3481 3.17385C12.336 3.1671 12.3251 3.15823 12.3161 3.14768C11.9766 2.01096 11.0477 1.24963 10.0001 1.24963C8.95242 1.24963 8.0239 2.01096 7.68445 3.14651C7.67539 3.15667 7.6647 3.16526 7.65281 3.1719C5.45046 4.07854 4.38015 5.81799 4.38015 8.48987C4.38015 11.4336 3.86101 12.061 3.00086 13.0992C2.93836 13.1746 2.87468 13.2512 2.80984 13.3309C2.64234 13.5329 2.53622 13.7786 2.50403 14.0391C2.47184 14.2995 2.51493 14.5637 2.6282 14.8004C2.86922 15.3082 3.38289 15.6235 3.96922 15.6235H16.0352C16.6188 15.6235 17.129 15.3086 17.3708 14.8031C17.4845 14.5664 17.528 14.302 17.4961 14.0412C17.4642 13.7805 17.3582 13.5344 17.1907 13.3321V13.3321Z"
                    fill="url(#paint0_linear_2897_3826)"
                  />
                  <path
                    d="M9.99977 18.7499C10.5642 18.7495 11.1181 18.5963 11.6025 18.3065C12.0869 18.0168 12.4839 17.6013 12.7513 17.1042C12.7639 17.0804 12.7701 17.0537 12.7694 17.0268C12.7686 16.9999 12.7609 16.9736 12.747 16.9505C12.7331 16.9274 12.7134 16.9083 12.69 16.8951C12.6665 16.8819 12.64 16.8749 12.613 16.8749H7.38727C7.36028 16.8748 7.33374 16.8817 7.31021 16.895C7.28669 16.9082 7.26698 16.9272 7.25302 16.9503C7.23905 16.9734 7.23131 16.9997 7.23053 17.0267C7.22976 17.0537 7.23598 17.0804 7.2486 17.1042C7.51599 17.6013 7.91291 18.0167 8.39727 18.3064C8.88163 18.5962 9.43537 18.7494 9.99977 18.7499Z"
                    fill="url(#paint1_linear_2897_3826)"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_2897_3826"
                      x1="17.5063"
                      y1="1.24963"
                      x2="0.489125"
                      y2="12.6247"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#2DCECC" />
                      <stop offset="1" stopColor="#2DCE89" />
                    </linearGradient>
                    <linearGradient
                      id="paint1_linear_2897_3826"
                      x1="12.7695"
                      y1="16.8749"
                      x2="10.7837"
                      y2="20.6293"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#2DCECC" />
                      <stop offset="1" stopColor="#2DCE89" />
                    </linearGradient>
                  </defs>
                </svg>
              </a>
            </div>
          </div>
          {/* start: Main */}
       
          <div className="grid grid-cols-12 gap-5">
            <div className="col-span-12">
              <div className="bg-white shadow-lg h-[100%] rounded-lg p-10">
                <div className="flex flex-row justify-between">
                    <div className="flex items-center justify-center gap-3">
                        <a href="/communication">
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.11261 0.862514L0.862615 7.11251C0.748814 7.23139 0.659608 7.37157 0.600115 7.52501C0.475092 7.82934 0.475092 8.17069 0.600115 8.47501C0.659608 8.62845 0.748814 8.76863 0.862615 8.88751L7.11261 15.1375C7.22916 15.2541 7.36753 15.3465 7.5198 15.4096C7.67208 15.4727 7.83529 15.5051 8.00011 15.5051C8.33299 15.5051 8.65224 15.3729 8.88761 15.1375C9.12299 14.9021 9.25523 14.5829 9.25523 14.25C9.25523 13.9171 9.12299 13.5979 8.88761 13.3625L4.76261 9.25001H14.2501C14.5816 9.25001 14.8996 9.11832 15.134 8.8839C15.3684 8.64948 15.5001 8.33153 15.5001 8.00001C15.5001 7.66849 15.3684 7.35055 15.134 7.11613C14.8996 6.88171 14.5816 6.75001 14.2501 6.75001H4.76261L8.88761 2.63751C9.00478 2.52131 9.09777 2.38306 9.16123 2.23073C9.22469 2.07841 9.25736 1.91503 9.25736 1.75001C9.25736 1.585 9.22469 1.42162 9.16123 1.26929C9.09777 1.11697 9.00478 0.978718 8.88761 0.862514C8.77141 0.745353 8.63316 0.652359 8.48084 0.588898C8.32851 0.525437 8.16513 0.492765 8.00011 0.492765C7.8351 0.492765 7.67172 0.525437 7.51939 0.588898C7.36707 0.652359 7.22882 0.745353 7.11261 0.862514Z" fill="#A1A1AA"/>
                            </svg>
                        </a>  
                        <h1 className="text-lg font-bold">Add WhatsApp</h1>
                    </div>
                   
                </div>
                <div>
                    <div className="shadow-md rounded-lg my-5">
                      <div className="flex flex-row justify-between p-10">
                        <div className="flex flex-col gap-3">
                          <h1 className="text-lg">To use WhatsApp on your computer</h1>
                          <div className="flex flex-row items-center gap-5">
                            <img src={IconWa}/>
                            <h1>1. Open WhatsApp on your phone</h1>
                          </div>
                          <div className="flex flex-row items-center gap-5">
                            <img src={IconPoin}/>
                            <h1>2. Tap menu or setting, Select Linked Device</h1>
                          </div>
                          <div className="flex flex-row items-center gap-5">
                            <img src={IconCam}/>
                            <h1>3. Point your phone to this screen to capture the code</h1>
                          </div>
                        </div>
                        <div>
                          <img src={IconQr} className="w-52 h-52"/>
                        </div>

                      </div>
                    
                    </div>
                </div>       
              </div>
              
            </div>
          </div>
        
          {/* end: Main */}
        </div>
      </div>
    </>
  );
}

export default AddWhatsApp;
