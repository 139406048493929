const closingPerTeam = {
  labels: [
    "11-07-2022", 
    "12-07-2022", 
    "13-07-2022", 
    "14-07-2022", 
    "15-07-2022", 
    "16-07-2022", 
    "17-07-2022", 
  ],
  datasets: [
    {
      fill: true,
      label: "Team",
      data: [65, 59, 80, 81, 56, 77, 44],
      borderColor: "rgb(0, 255, 0)",
      backgroundColor: [
        "rgba(94, 98, 120, 0.8)",
        "rgba(94, 98, 120, 0.8)",
        "rgba(94, 98, 120, 0.8)",
        "rgba(79, 194, 155, 0.8)",
        "rgba(94, 98, 120, 0.8)",
        "rgba(94, 98, 120, 0.8)",
        "rgba(94, 98, 120, 0.8)",
      ]
    },
  ],
};

export default closingPerTeam;
