import React, { useState } from "react";
import Navbar from "../Components/Navbar/Navbar";
import Profile from "../Assets/img/Profile.jpg";
import { Bar } from "react-chartjs-2";
import stats from "../Data/stats";
import ChartOmzet from "../Components/Partials/Chart/Product/ChartOmzet";
import ChartOmzetProduct from "../Components/Partials/Chart/ChartOmzetProduct";
import ChartStatusPackage from "../Components/Partials/Chart/ChartStatusPackage";
import ChartClosingPerTeam from "../Components/Partials/Chart/ChartClosingPerTeam";
import ChartBottelPerProduct from "../Components/Partials/Chart/ChartBottelPerProduct";
import ChartClosingRatePerProduct from "../Components/Partials/Chart/ChartClosingRatePerProduct";
import ChartClosing from "../Components/Partials/Chart/Product/ChartClosing";
import ChartBottle from "../Components/Partials/Chart/Product/ChartBottle";
import ChartRatioBottle from "../Components/Partials/Chart/Product/ChartRatioBottle";
import ChartClosingRate from "../Components/Partials/Chart/Product/ChartClosingRate";
import ChartCrossSelling from "../Components/Partials/Chart/Product/ChartCrossSelling";
import ChartTotalDatabase from "../Components/Partials/Chart/Product/ChartTotalDatabase";
import ChartCustomerSatisfaction from "../Components/Partials/Chart/Product/ChartCustomerSatisfaction";
import ChartCustomerComplain from "../Components/Partials/Chart/Product/ChartCustomerComplain";

function OverviewProduct() {
  const [count_lead, setCountLead] = useState("");
  return (
    <div>
      <Navbar />
      <div className="px-10 py-5">
        <div className="mt-20 space-y-5">
          <div className="flex flex-col md:flex-row justify-between items-start md:items-center gap-3">
            <div className="flex flex-col">
              <h1 className="text-lg font-semibold">Overview Product</h1>
              <p className="text-gray-400 text-sm font-medium flex flex-row items-center gap-2">
                <a href="/product" className="hover:text-gray-600">
                  Product
                </a>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-chevron-right"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                  />
                </svg>
                <a href="#" className="hover:text-gray-600">
                  Overview Product
                </a>
              </p>
            </div>
          </div>
          <div className="shadow-md rounded-lg p-5">
            <div className="flex flex-row justify-between gap-5 mb-5">
              <div className="flex flex-col md:flex-row gap-5">
                <img src={Profile} alt="qr" className="rounded-md w-24 h-24" />
                <div className="flex flex-col gap-3 ">
                  <p className="font-bold text-lg">Gizidat</p>
                  <div className="">
                    <div className="flex flex-row gap-5">
                      <div className="border-dashed border-2 py-1 px-3 rounded-lg">
                        <p className="font-bold text-lg text-start text-black">
                          GIZ-1
                        </p>
                        <p className="font-medium text-start text-gray-400">
                          SKU
                        </p>
                      </div>
                      <div className="border-dashed border-2 py-1 px-3 rounded-lg">
                        <p className="font-bold text-lg text-start text-black">
                          Rp 150.000
                        </p>
                        <p className="font-medium text-start text-gray-400">
                          Price
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="grid md:grid-cols-2">
            <ChartOmzet />
            <ChartClosing />
            <ChartBottle />
            <ChartRatioBottle />
            <ChartClosingRate />
            <ChartCrossSelling />
            <ChartTotalDatabase />
            <ChartCustomerSatisfaction />
            <ChartCustomerComplain />
          </div>
        </div>
      </div>
    </div>
  );
}

export default OverviewProduct;
