import React, { Component, useState } from "react";
import Navbar from "../Components/Navbar/Navbar";
import ChartOmzet from "../Components/Partials/Chart/ChartOmzet";

function RepotingSpv() {
  const [openTab, setOpenTab] = React.useState(1);
  return (
    <div>
      {/*begin: Navbar*/}
      <Navbar />
      {/*end: Navbar*/}
      <div className="px-10 py-5">
        {/*begin: TopBar*/}
        <div className="mt-20">
          <div className="flex flex-col md:flex-row justify-between items-start md:items-center gap-3">
            <div className="flex flex-col">
              <h1 className="text-lg font-semibold">Reporting</h1>
              <p className="text-gray-400 text-sm font-medium flex flex-row items-center gap-2">
                <a href="/dashboard" className="hover:text-gray-600">
                  Dashboard
                </a>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-chevron-right"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                  />
                </svg>
                <a href="#" className="hover:text-gray-600">
                  Reporting
                </a>
              </p>
            </div>
            {/* <div className="flex flex-col md:flex-row gap-2">
            <AddTemplate />
          </div> */}
          </div>
        </div>
        {/*end: TopBar*/}
        {/*begin: Dashboard Reporting*/}
        <div className="w-full">
          <div className="">
            <ul
              className="grid md:grid-cols-12 grid-cols-2 mb-0 list-none pt-3 pb-4"
              role="tablist"
            >
              <li className="mb-2 col-span-2 text-center">
                <a
                  className={
                    "text-xs font-bold uppercase px-5 py-3 block leading-normal " +
                    (openTab === 1
                      ? "text-green-500 bg-white shadow-lg"
                      : "text-gray-400 bg-gray-100 mr-1")
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    setOpenTab(1);
                  }}
                  data-toggle="tab"
                  href="#link1"
                  role="tablist"
                >
                  REVEUNE TEAM
                </a>
              </li>
              <li className="mb-2 col-span-2 text-center">
                <a
                  className={
                    "text-xs font-bold uppercase px-5 py-3 block leading-normal " +
                    (openTab === 2
                      ? "text-green-500 bg-white shadow-lg"
                      : "text-gray-400 bg-gray-100 mr-1")
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    setOpenTab(2);
                  }}
                  data-toggle="tab"
                  href="#link2"
                  role="tablist"
                >
                  Leveling Customer
                </a>
              </li>
              <li className="mb-2 col-span-2 text-center">
                <a
                  className={
                    "text-xs font-bold uppercase px-5 py-3 block leading-normal " +
                    (openTab === 3
                      ? "text-green-500 bg-white shadow-lg"
                      : "text-gray-400 bg-gray-100 mr-1")
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    setOpenTab(3);
                  }}
                  data-toggle="tab"
                  href="#link3"
                  role="tablist"
                >
                  Loyalty Program
                </a>
              </li>
              <li className="mb-2 col-span-2 text-center">
                <a
                  className={
                    "text-xs font-bold uppercase px-5 py-3 block leading-normal " +
                    (openTab === 4
                      ? "text-green-500 bg-white shadow-lg"
                      : "text-gray-400 bg-gray-100 mr-1")
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    setOpenTab(4);
                  }}
                  data-toggle="tab"
                  href="#link4"
                  role="tablist"
                >
                  Lost (Retur)
                </a>
              </li>
              <li className="mb-2 col-span-2 text-center">
                <a
                  className={
                    "text-xs font-bold uppercase px-5 py-3 block leading-normal " +
                    (openTab === 5
                      ? "text-green-500 bg-white shadow-lg"
                      : "text-gray-400 bg-gray-100 mr-1")
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    setOpenTab(5);
                  }}
                  data-toggle="tab"
                  href="#link5"
                  role="tablist"
                >
                  Customer Satisfacation
                </a>
              </li>
              <li className="mb-2 col-span-2 text-center">
                <a
                  className={
                    "text-xs font-bold uppercase px-5 py-3 block leading-normal " +
                    (openTab === 6
                      ? "text-green-500 bg-white shadow-lg"
                      : "text-gray-400 bg-gray-100")
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    setOpenTab(6);
                  }}
                  data-toggle="tab"
                  href="#link6"
                  role="tablist"
                >
                  CS Performance
                </a>
              </li>
            </ul>

            <div className="relative">
              <div className="grid md:grid-cols-12 bg-white">
                <div className="tab-content tab-space md:col-span-6">
                  <div
                    className={openTab === 1 ? "block" : "hidden"}
                    id="link1"
                  >
                    <ChartOmzet />
                  </div>
                </div>
                <div className="tab-content tab-space md:col-span-6">
                  <div
                    className={openTab === 1 ? "block" : "hidden"}
                    id="link1"
                  >
                    <ChartOmzet />
                  </div>
                </div>
                <div className="tab-content tab-space md:col-span-6">
                  <div
                    className={openTab === 1 ? "block" : "hidden"}
                    id="link1"
                  >
                    <ChartOmzet />
                  </div>
                </div>
                <div className="tab-content tab-space md:col-span-6">
                  <div
                    className={openTab === 1 ? "block" : "hidden"}
                    id="link1"
                  >
                    <ChartOmzet />
                  </div>
                </div>
                <div className="tab-content tab-space md:col-span-6">
                  <div
                    className={openTab === 2 ? "block" : "hidden"}
                    id="link2"
                  >
                    <ChartOmzet />
                  </div>
                </div>
                <div className="tab-content tab-space md:col-span-6">
                  <div
                    className={openTab === 2 ? "block" : "hidden"}
                    id="link2"
                  >
                    <ChartOmzet />
                  </div>
                </div>
                <div className="tab-content tab-space md:col-span-6">
                  <div
                    className={openTab === 3 ? "block" : "hidden"}
                    id="link3"
                  >
                    <ChartOmzet />
                  </div>
                </div>
                <div className="tab-content tab-space md:col-span-6">
                  <div
                    className={openTab === 3 ? "block" : "hidden"}
                    id="link3"
                  >
                    <ChartOmzet />
                  </div>
                </div>
                <div className="tab-content tab-space md:col-span-6">
                  <div
                    className={openTab === 3 ? "block" : "hidden"}
                    id="link3"
                  >
                    <ChartOmzet />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*begin: End Reporting*/}
      </div>
    </div>
  );
}

export default RepotingSpv;
