import api from '../apiClient'

export function getAllProvince() {
    return api.axiosClient.get(`/region/provinces`)
}

export function getProvince(id) {
    return api.axiosClient.get(`/region/province/${id}`)
}

export function getAllCity(id) {
    return api.axiosClient.get(`/region/cities/${id}`)
}

export function getCity(id) {
    return api.axiosClient.get(`/region/city/${id}`)
}

export function getAllSubdis(id) {
    return api.axiosClient.get(`/region/subdistricts/${id}`)
}

export function getSubdis(id) {
    return api.axiosClient.get(`/region/subdistrict/${id}`)
}
