const closing = {
  labels: [
    "Jan",
    "Feb",
    "March",
    "April",
    "May",
    "June",
    "July",
    "Augst",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ],
  datasets: [
    {
      fill: true,
      label: "closing",
      data: [65, 59, 80, 81, 56, 77, 44, 0, 0, 0, 0, 0],
      borderColor: "rgb(0, 255, 0)",
      backgroundColor: ["rgba(79, 194, 155, 0.8)"],
    },
  ],
};

export default closing;
