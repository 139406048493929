import "aos/dist/aos.css";
import React, { useEffect, useState } from "react";
// import SidebarComunication from "../../Components/Sidebar/SidebarComunication";
import TableLeveling from "../../Components/Partials/Table/TableLeveling";
import Sidebar from "../../Components/Sidebar/Sidebar";
import { Link, useNavigate, useParams } from "react-router-dom";
import dataa from "../../Assets/svg/Data.svg";
import pens from "../../Assets/svg/pens.svg";
import { getOrderById } from "../../Network/Lib/order";
import { logout } from "../../Network/Lib/auth";
import { getCustomerById } from "../../Network/Lib/customer";
// import
// pens.svg

function DetailOrder() {
  const navigate = useNavigate();
  const [navSideBar, setNavSideBar] = useState(false);

  const { id } = useParams();
  // console.log(id);
  const [data, setData] = useState([]);
  const [customer, setCustomer] = useState([]);

  useEffect(() => {
    getOrderById(id).then((response) => {
      setData(response.data.data);
      console.log(response.data.data[0]);
    });
    getCustomerById(id).then((response) => {
      setCustomer(response.data.data[0].name);
    });
  }, []);

  const signOut = () => {
    logout();
    navigate("/login");
  };

  return (
    <>
      <div className="bg-gradient-to-r from-[#54DAA1] to-[#2DCE89]">
        <div className="relative">
          <aside
            className={
              navSideBar === true
                ? "w-24 h-screen p-5 fixed top-0 left-0 right-0 z-10"
                : "w-3/12 h-screen p-5 fixed top-0 left-0 right-0 z-10"
            }
            aria-label="Sidebar"
          >
            <button
              className="absolute bottom-96 right-1 w-10 h-10 bg-gradient-to-r from-[#54DAA1] to-[#2DCE89] rounded-full shadow-lg flex justify-center items-center"
              onClick={() => setNavSideBar(!navSideBar)}
            >
              {navSideBar ? (
                <svg
                  width="9"
                  height="16"
                  viewBox="0 0 9 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 14.3333L7.66667 7.66668L1 1.00001"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              ) : (
                <svg
                  width="10"
                  height="16"
                  viewBox="0 0 10 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.33325 14.6667L1.66659 7.99999L8.33325 1.33332"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              )}
            </button>
            <Sidebar navSideBar={navSideBar} />
          </aside>
        </div>
        <div
          className={
            navSideBar === true
              ? "ml-24 h-72 w-11/12 p-5 space-y-5"
              : "ml-auto h-72 w-9/12 p-5 space-y-5"
          }
        >
          {/* Header */}
          <div className="flex flex-row justify-between items-center">
            <div className="text-white space-y-2">
              <div className="flex flex-row space-x-3">
                <p className="text-sm">Pages</p>
                <p className="text-sm">/</p>
                <a href="/dashboard" className="text-sm">
                  Dashboard
                </a>
                <p className="text-sm">/</p>
                <a href="/view-customer/:id" className="text-sm">
                  View Customer
                </a>
                <p className="text-sm">/</p>
                <a href="/detail-order" className="text-sm">
                  Detail Order
                </a>
              </div>
              <p className="font-semibold">Detail Order</p>
            </div>
            <div className="flex flex-row items-center gap-5">
              <a href="#" className="flex items-center gap-2">
                <svg
                  width="21"
                  height="20"
                  viewBox="0 0 21 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.4938 2.52235C12.7336 1.70165 11.6719 1.24969 10.5 1.24969C9.32191 1.24969 8.25668 1.69891 7.50004 2.51454C6.73519 3.33915 6.36254 4.45985 6.45004 5.67001C6.62348 8.05751 8.44027 9.99969 10.5 9.99969C12.5598 9.99969 14.3735 8.0579 14.5496 5.67079C14.6383 4.47157 14.2633 3.35321 13.4938 2.52235Z"
                    fill="white"
                  />
                  <path
                    d="M17.3752 18.7488H3.62522C3.44525 18.7511 3.26701 18.7133 3.10349 18.6381C2.93997 18.5629 2.79526 18.4522 2.67991 18.314C2.426 18.0105 2.32366 17.596 2.39944 17.1769C2.72913 15.348 3.75804 13.8117 5.37522 12.7332C6.81194 11.7757 8.63186 11.2488 10.5002 11.2488C12.3686 11.2488 14.1885 11.7761 15.6252 12.7332C17.2424 13.8113 18.2713 15.3476 18.601 17.1765C18.6768 17.5957 18.5744 18.0101 18.3205 18.3136C18.2052 18.4519 18.0605 18.5626 17.897 18.6379C17.7335 18.7132 17.5552 18.7511 17.3752 18.7488Z"
                    fill="white"
                  />
                </svg>
                <p className="text-white">{localStorage.getItem("nameUser")}</p>
              </a>
              <button onClick={signOut} className="bg-white p-3 rounded-lg">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.5625 2.8125H2.1875V17.8125H6.5625M13.4375 5.9375L17.8125 10.3125L13.4375 14.6875M7.1875 10.3125H17.8125"
                    stroke="#EF4444"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            </div>
          </div>
          {/* start: Main */}

          <div className="grid grid-cols-12 gap-5">
            <div className="col-span-12">
              <div className="bg-white shadow-lg h-full rounded-lg p-10">
                <div className="flex flex-row justify-between">
                  <div className="flex items-center justify-center gap-3">
                    <h1 className="text-lg font-bold">Detail Order</h1>
                    <Link
                      to="/edit-detail-order"
                      className="flex flex-row justify-center items-center text-white rounded-lg bg-[#54DAA1] hover:bg-[#4FC29B] focus:ring-4 focus:outline-none focus:ring-[#4FC29B] font-medium text-sm px-4 py-2.5 text-center"
                    >
                      <span className="text-white hidden md:block px-2">
                        Edit
                      </span>
                      <img src={pens} className="w-5 h-5" />
                    </Link>
                  </div>
                </div>
                <div className="py-10 space-y-5">
                  <div className="grid md:grid-cols-5 grid-cols-2 gap-3 bg-gray-50 p-5 rounded-lg">
                    <div className="space-y-2">
                      <p className="text-sm">Buying Date</p>
                      <input
                        type="date"
                        className="w-full bg-white p-2 rounded-lg text-xs text-gray-400 focus:ring-transparent border-0"
                        readOnly
                      />
                    </div>
                    <div className="space-y-2">
                      <p className="text-sm">Transaction Number</p>
                      <input
                        type="text"
                        className="w-full bg-white p-2 rounded-lg text-xs text-gray-400 focus:ring-transparent border-0"
                        placeholder="ETO.CRM.C/22/VIII-479"
                        readOnly
                      />
                    </div>
                    <div className="space-y-2">
                      <p className="text-sm">Customer</p>
                      <input
                        type="text"
                        className="w-full bg-white p-2 rounded-lg text-xs text-gray-400 focus:ring-transparent border-0"
                        placeholder="Name"
                        readOnly
                      />
                    </div>
                    <div className="space-y-2">
                      <p className="text-sm">Status by Today</p>
                      <input
                        type="text"
                        className="w-full bg-white p-2 rounded-lg text-xs text-gray-400 focus:ring-transparent border-0"
                        placeholder="Paid"
                        readOnly
                      />
                    </div>
                    <div className="space-y-2">
                      <p className="text-sm">Memo</p>
                      <input
                        type="text"
                        className="w-full bg-white p-2 rounded-lg text-xs text-gray-400 focus:ring-transparent border-0"
                        placeholder="RO 1"
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="bg-gray-50 p-5 rounded-lg space-y-3">
                    <div className="space-y-3">
                      <div className="grid grid-cols-5">
                        <div className="col-span-4 grid md:grid-cols-4 gap-3">
                          <div className="space-y-2">
                            <p className="text-sm">Product</p>
                            <input
                              type="text"
                              className="w-full bg-white p-2 rounded-lg text-xs text-gray-400 focus:ring-transparent border-0"
                              placeholder="Freshmag"
                              value="Freshmag"
                              readOnly
                            />
                          </div>
                          <div className="space-y-2">
                            <p className="text-sm">Quantity</p>
                            <input
                              type="text"
                              className="w-full bg-white p-2 rounded-lg text-xs text-gray-400 focus:ring-transparent border-0"
                              placeholder="10"
                              value="10"
                              readOnly
                            />
                          </div>
                          <div className="space-y-2">
                            <p className="text-sm">Unit</p>
                            <input
                              type="text"
                              className="w-full bg-white p-2 rounded-lg text-xs text-gray-400 focus:ring-transparent border-0"
                              placeholder="box"
                              value="box"
                              readOnly
                            />
                          </div>
                          <div className="space-y-2">
                            <p className="text-sm">Price</p>
                            <input
                              type="text"
                              className="w-full bg-white p-2 rounded-lg text-xs text-gray-400 focus:ring-transparent border-0"
                              placeholder="Rp. "
                              value="Rp. 100.000"
                              readOnly
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="grid md:grid-cols-5 grid-cols-2 gap-3 ">
                      <div className="space-y-2">
                        <p className="text-sm">Payment</p>
                        <input
                          type="text"
                          className="w-full bg-white p-2 rounded-lg text-xs text-gray-400 focus:ring-transparent border-0"
                          placeholder="Transfer"
                          value="Transfer"
                          readOnly
                        />
                      </div>
                      <div className="space-y-2">
                        <p className="text-sm">Note</p>
                        <input
                          type="text"
                          className="w-full bg-white p-2 rounded-lg text-xs text-gray-400 focus:ring-transparent border-0"
                          placeholder="Note"
                          value="Note"
                          readOnly
                        />
                      </div>
                      <div className="space-y-2">
                        <p className="text-sm">Buying Reason</p>
                        <input
                          type="text"
                          className="w-full bg-white p-2 rounded-lg text-xs text-gray-400 focus:ring-transparent border-0"
                          placeholder="Buying Reason"
                          value="Buying Reason"
                          readOnly
                        />
                      </div>
                      <div className="space-y-2">
                        <p className="text-sm">Promo</p>
                        <input
                          type="text"
                          className="w-full bg-white p-2 rounded-lg text-xs text-gray-400 focus:ring-transparent border-0"
                          placeholder="Promo A"
                          value="Promo A"
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <div className="grid md:grid-cols-5 grid-cols-2 gap-3 bg-gray-50 p-5 rounded-lg">
                    <div className="space-y-2">
                      <p className="text-sm">Customer Service Name</p>
                      <input
                        type="text"
                        className="w-full bg-white p-2 rounded-lg text-xs text-gray-400 focus:ring-transparent border-0"
                        placeholder="Hutari Amelia"
                        value="Hutari Amelia"
                        readOnly
                      />
                    </div>
                    <div className="space-y-2">
                      <p className="text-sm">Customer Service Phone</p>
                      <input
                        type="text"
                        className="w-full bg-white p-2 rounded-lg text-xs text-gray-400 focus:ring-transparent border-0"
                        placeholder="+62812345678"
                        value="+62812345678"
                        readOnly
                      />
                    </div>
                    <div className="space-y-2">
                      <p className="text-sm">Channel</p>
                      <input
                        type="text"
                        className="w-full bg-white p-2 rounded-lg text-xs text-gray-400 focus:ring-transparent border-0"
                        placeholder="Whatsapp"
                        value="Whatsapp"
                        readOnly
                      />
                    </div>
                    <div className="space-y-2">
                      <p className="text-sm">ADV</p>
                      <input
                        type="text"
                        className="w-full bg-white p-2 rounded-lg text-xs text-gray-400 focus:ring-transparent border-0"
                        placeholder="CRM"
                        value="CRM"
                        readOnly
                      />
                    </div>
                    <div className="space-y-2">
                      <p className="text-sm">Category</p>
                      <input
                        type="text"
                        className="w-full bg-white p-2 rounded-lg text-xs text-gray-400 focus:ring-transparent border-0"
                        placeholder="CRM"
                        value="CRM"
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="grid md:grid-cols-5 grid-cols-2 gap-3 bg-gray-50 p-5 rounded-lg">
                    <div className="space-y-2">
                      <p className="text-sm">Warehouse</p>
                      <input
                        type="text"
                        className="w-full bg-white p-2 rounded-lg text-xs text-gray-400 focus:ring-transparent border-0"
                        placeholder="Tandes"
                        value="Tandes"
                        readOnly
                      />
                    </div>
                    <div className="space-y-2">
                      <p className="text-sm">Courier</p>
                      <input
                        type="text"
                        className="w-full bg-white p-2 rounded-lg text-xs text-gray-400 focus:ring-transparent border-0"
                        placeholder="JNE"
                        value="JNE"
                        readOnly
                      />
                    </div>
                    <div className="space-y-2">
                      <p className="text-sm">Total per line/omzet</p>
                      <input
                        type="text"
                        className="w-full bg-white p-2 rounded-lg text-xs text-gray-400 focus:ring-transparent border-0"
                        placeholder="Rp. 100.000"
                        value="Rp. 100.000"
                        readOnly
                      />
                    </div>
                    <div className="space-y-2">
                      <p className="text-sm">Shipping Fee</p>
                      <input
                        type="text"
                        className="w-full bg-white p-2 rounded-lg text-xs text-gray-400 focus:ring-transparent border-0"
                        placeholder="Rp. 1.000"
                        value="Rp. 1.000"
                        readOnly
                      />
                    </div>
                    <div className="space-y-2">
                      <p className="text-sm">Subsidi by Company</p>
                      <input
                        type="text"
                        className="w-full bg-white p-2 rounded-lg text-xs text-gray-400 focus:ring-transparent border-0"
                        placeholder="Rp. 1.000"
                        value="Rp. 1.000"
                        readOnly
                      />
                    </div>
                    <div className="space-y-2">
                      <p className="text-sm">Total + Ongkir</p>
                      <input
                        type="text"
                        className="w-full bg-white p-2 rounded-lg text-xs text-gray-400 focus:ring-transparent border-0"
                        placeholder="Rp. 100.000"
                        value="Rp. 100.000"
                        readOnly
                      />
                    </div>
                    <div className="space-y-2">
                      <p className="text-sm">Resi</p>
                      <input
                        type="text"
                        className="w-full bg-white p-2 rounded-lg text-xs text-gray-400 focus:ring-transparent border-0"
                        placeholder="JNE-2131"
                        value="JNE-2131"
                        readOnly
                      />
                    </div>
                    <div className="space-y-2">
                      <p className="text-sm">Keterangan Retur</p>
                      <input
                        type="text"
                        className="w-full bg-white p-2 rounded-lg text-xs text-gray-400 focus:ring-transparent border-0"
                        placeholder="-"
                        value="-"
                        readOnly
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* end: Main */}
        </div>
      </div>
    </>
  );
}

export default DetailOrder;
