import { ArrowCircleRightOutlined, ArrowLeft, ArrowRight, ExpandLess, ExpandMore, PlayCircleOutline } from '@mui/icons-material';
import { Box, Drawer } from '@mui/material';
import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import logo from "../../Assets/img/logo.png";
import LogoCrm from "../../Assets/img/LogoCrm.png";
import bg from "../../Assets/img/LandingPage/bg-9.png";
import icon1 from "../../Assets/img/LandingPage/icon/1.png";
import icon2 from "../../Assets/img/LandingPage/icon/2.png";
import icon3 from "../../Assets/img/LandingPage/icon/3.png";
import icon4 from "../../Assets/img/LandingPage/icon/4.png";
import icon5 from "../../Assets/img/LandingPage/icon/5.png";
import icon6 from "../../Assets/img/LandingPage/icon/6.png";
import icon7 from "../../Assets/img/LandingPage/icon/7.png";
import icon8 from "../../Assets/img/LandingPage/icon/8.png";
import icon9 from "../../Assets/img/LandingPage/icon/9.png";
import lp1 from "../../Assets/svg/lp1.svg";
import lp2 from "../../Assets/svg/lp2.svg";
import Check1 from "../../Assets/svg/Check1.svg";
import mitra1 from "../../Assets/img/LandingPage/mitra/1.png";
import mitra2 from "../../Assets/img/LandingPage/mitra/2.png";
import mitra3 from "../../Assets/img/LandingPage/mitra/3.png";
import mitra4 from "../../Assets/img/LandingPage/mitra/4.png";
import mitra5 from "../../Assets/img/LandingPage/mitra/5.png";
import mitra6 from "../../Assets/img/LandingPage/mitra/6.png";
import logoWhite from "../../Assets/img/logo-white.png";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import CallIcon from '@mui/icons-material/Call';
import MailIcon from '@mui/icons-material/Mail';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';

function TermService() {
    const pathname = window.location.pathname;
    // useEffect(() => {
    //   if (window.localStorage.getItem("users") != null) {
    //     window.location.href = "/";
    //   }
    // }, []);

    const [state, setState] = useState(false);

    return (
        <div>
          
            {/* Content */}
            {/* section1 */}
            <div className="h-full">
              <div className="px-20">
                <div className="flex flex-col justify-center text-center gap-5 w-full pt-[100px]">
                  <h1 className="text-5xl font-bold text-crm-1 text-center">
                    Term of Services
                  </h1>
                </div>
                <div className="flex flex-col py-10">
                  
                  <div className="mb-12">
                    <p className="text-gray-800 text-lg font-medium">
                    Welcome to Croya, a web application that provides a platform for customer relationship management. By using our platform, you agree to these terms and conditions. Please read them carefully before using our platform.
                    </p> 

                  </div>
                 
                  <div className="mb-12">
                    <p className="text-gray-800 text-xl font-bold">
                      Use of Platform:
                    </p>
                    <p className="text-gray-800 text-lg font-medium">
                      You may use our platform only for lawful purposes and in accordance with these terms and conditions. You agree not to use our platform:
                      In any way that violates any applicable federal, state, local, or international law or regulation
                      For the purpose of exploiting, harming, or attempting to exploit or harm minors in any way by exposing them to inappropriate content, asking for personally identifiable information, or otherwise
                      To transmit, or procure the sending of, any advertising or promotional material, including any "junk mail," "chain letter," "spam," or any other similar solicitation
                      To impersonate or attempt to impersonate Croya, a Croya employee, another user, or any other person or entity
                      To engage in any other conduct that restricts or inhibits anyone's use or enjoyment of our platform, or which, as determined by us, may harm Croya or users of our platform, or expose them to liability
                    </p> 

                  </div>
                  <div className="mb-12">
                    <p className="text-gray-800 text-xl font-bold">
                      User Accounts:
                    </p>
                    <p className="text-gray-800 text-lg font-medium">
                      To use our platform, you may be required to register for an account. You agree to provide accurate, current, and complete information during the registration process and to update such information as necessary to ensure that it remains accurate, current, and complete. You are responsible for safeguarding your account information, including your username and password.
                    </p> 

                  </div>
                  <div className="mb-12">
                    <p className="text-gray-800 text-xl font-bold">
                      User Content:
                    </p>
                    <p className="text-gray-800 text-lg font-medium">
                      Our platform allows you to upload and share content, including text, images, and other materials ("User Content"). You retain all rights in, and are solely responsible for, the User Content you post on our platform. You grant us a non-exclusive, transferable, sub-licensable, royalty-free, worldwide license to use any User Content that you post on or in connection with our platform. You agree not to post any User Content that:
                    </p> 
                    <p className="text-gray-800 text-lg font-medium mt-10">
                      Is defamatory, obscene, pornographic, abusive, or otherwise offensive or illegal
                      Infringes on any intellectual property rights of others, including patents, trademarks, trade secrets, copyrights, or other proprietary rights
                      Violates any right of privacy or publicity
                      Contains viruses, trojan horses, worms, time bombs, or other computer programming routines that are intended to damage, interfere with, intercept, or expropriate any system, data, or personal information
                      We reserve the right to remove any User Content that violates these terms and conditions or is otherwise objectionable.
                    </p> 

                  </div>
                  <div className="mb-12">
                    <p className="text-gray-800 text-xl font-bold">
                      Intellectual Property:
                    </p>
                    <p className="text-gray-800 text-lg font-medium">
                      The platform and its entire contents, features, and functionality (including but not limited to all information, software, text, displays, images, video, and audio, and the design, selection, and arrangement thereof), are owned by Croya, its licensors, or other providers of such material and are protected by United States and international copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws.
                    </p> 

                  </div>
                  <div className="mb-12">
                    <p className="text-gray-800 text-xl font-bold">
                      Limitation of Liability:
                    </p>
                    <p className="text-gray-800 text-lg font-medium">
                    In no event shall Croya, its affiliates, or their respective officers, directors, employees, agents, licensors, or service providers be liable for any damages (including, without limitation, damages for loss of data or profit, or due to business interruption) arising out of the use or inability to use our platform.
                    </p> 

                  </div>
                  <div className="mb-12">
                    <p className="text-gray-800 text-xl font-bold">
                    Indemnification:
                    </p>
                    <p className="text-gray-800 text-lg font-medium">
                    You agree to indemnify, defend, and hold harmless Croya, its affiliates, and their respective officers, directors, employees, agents, licensors, and service providers from and against any claims, liabilities, damages, judgments, awards, losses, costs, expenses
                    </p> 

                  </div>
        
                </div>
              </div>
            </div>
            {/* section1 */}
            <div className="border bg-crm-4 text-white">
                <div className="lg:mx-16 mx-8 grid lg:grid-cols-12 grid-cols-6 lg:gap-0 gap-5 my-20">
                    <div className="lg:col-span-3 col-span-6">
                        <img src={logoWhite} className="lg:h-36 h-30" />
                        {/* <div className="flex flex-row gap-3 items-center">
                <InstagramLogo className="h-5 w-5 text-blue-6"></InstagramLogo>
                <p className="font-bold">CRM</p>
              </div> */}
                        {/* <div className="flex flex-row gap-3 items-center">
                <InstagramLogo className="h-5 w-5 text-blue-6"></InstagramLogo>
                <p className="font-thin text-sm lg:pr-5">
                  Customer relationship management is an integrated information
                  system used to plan, schedule and control presales and
                  postsales activities.
                </p>
              </div> */}
                    </div>
                    <div className="lg:col-span-3 col-span-6">
                        <div className="flex flex-col gap-2">
                            <a className="font-bold">Office</a>
                            <a className="flex flex-row gap-2">
                                <LocationOnIcon></LocationOnIcon>
                                <p>
                                    Gg. Satria II, Sokayasa, RT04/RW01, Kec. Purwokerto Timur, Kabupaten Banyumas, Jawa Tengah 53146
                                </p>
                            </a>
                            <a className="flex flex-row gap-2">
                                <CallIcon></CallIcon>
                                <p>
                                    083766863242
                                </p>
                            </a>
                            <a className="flex flex-row gap-2">
                                <MailIcon></MailIcon>
                                <p>
                                    info.crm@gmail.com
                                </p>
                            </a>
                        </div>
                    </div>
                    <div className="lg:col-span-2 col-span-3">
                        <div className="flex flex-col gap-2">
                            <a className="font-bold">Features</a>
                            <a>Schedule Broadcast</a>
                            <a>Reporting and Tracking</a>
                            <a>Database Optimization</a>

                        </div>
                    </div>
                    <div className="lg:col-span-2 col-span-3">
                        <div className="flex flex-col gap-2">
                            <a className="font-bold">Company</a>
                            <a href="/about">About Us</a>
                            <a href="/privacy-police">Privacy Policy</a>
                            <a href="/term-of-service">Term of Service</a>
                            <a href="/contact-us">Contact Us</a>
                        </div>
                    </div>
                    <div className="lg:col-span-2 col-span-3">
                        <div className="flex flex-col gap-2">
                            <a className="font-bold">Social Media</a>
                            <div className="flex gap-4">
                                <LinkedInIcon></LinkedInIcon>
                                <InstagramIcon></InstagramIcon>
                                <FacebookIcon></FacebookIcon>
                                <TwitterIcon></TwitterIcon>

                            </div>
                        </div>
                    </div>

                </div>
                <div className="bg-blue-4 text-white text-center p-2">
                    © 2022 Customer Relationship Management
                </div>
            </div>
            {/* Content */}
        </div>
    )
}

export default TermService





