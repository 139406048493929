import React, { Component, useState, useEffect } from "react";
import Sidebar from "../../Components/Sidebar/Sidebar";
import "aos/dist/aos.css";
import TableOrderHistory from "../../Components/Partials/Table/TableOrderHistory";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  getCustomerAddressById,
  getCustomerById,
} from "../../Network/Lib/customer";
import { getOrderById } from "../../Network/Lib/order";
import { logout } from "../../Network/Lib/auth";

function ViewCustomer() {
  const navigate = useNavigate();
  const [navSideBar, setNavSideBar] = useState(false);
  const [data, setData] = useState([]);
  const [address, setAddress] = useState([]);
  const [dataOrder, setDataOrder] = useState([]);
  const { id } = useParams();
  console.log(id);

  useEffect(() => {
    getCustomerById(id).then((response) => {
      setData(response.data.data[0]);
      // console.log(response.data.data);
    });
    getCustomerAddressById(id).then((response) => {
      // console.log(response.data.data[0].address)
      setAddress(response.data.data[0].address);
    });
    // getOrderById(id).then((response) => {
    //   console.log(response.data.data);
    //   setDataOrder(response.data.data);
    // });
  }, []);

  // console.log(data.name)

  const getAge = (dateString) => {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };

  
  const signOut =()=>{
    logout();
    navigate('/login')
  }

  return (
    <>
      <div className="bg-gradient-to-r from-[#54DAA1] to-[#2DCE89]">
        <div className="relative">
          <aside
            className={
              navSideBar === true
                ? "w-24 h-screen p-5 fixed top-0 left-0 right-0 z-10"
                : "w-3/12 h-screen p-5 fixed top-0 left-0 right-0 z-10"
            }
            aria-label="Sidebar"
          >
            <button
              className="absolute top-72 right-1 w-10 h-10 bg-gradient-to-r from-[#54DAA1] to-[#2DCE89] rounded-full shadow-lg flex justify-center items-center"
              onClick={() => setNavSideBar(!navSideBar)}
            >
              {navSideBar ? (
                <svg
                  width="9"
                  height="16"
                  viewBox="0 0 9 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 14.3333L7.66667 7.66668L1 1.00001"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              ) : (
                <svg
                  width="10"
                  height="16"
                  viewBox="0 0 10 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.33325 14.6667L1.66659 7.99999L8.33325 1.33332"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              )}
            </button>
            <Sidebar navSideBar={navSideBar} />
          </aside>
        </div>
        <div
          className={
            navSideBar === true
              ? "ml-24 h-72 w-11/12 p-5 space-y-5"
              : "ml-auto h-72 w-9/12 p-5 space-y-5"
          }
        >
          {/* Header */}
          <div className="flex flex-row justify-between items-center">
            <div className="text-white space-y-2">
              <div className="flex flex-row space-x-3">
                <p className="text-sm">Pages</p>
                <p className="text-sm">/</p>
                <a href="/Dashboard" className="text-sm">
                  Dashboard
                </a>
                <p className="text-sm">/</p>
                <a href="#" className="text-sm">
                  View Customer
                </a>
              </div>
              <p className="font-semibold">View Customer</p>
            </div>
            <div className="flex flex-row items-center gap-5">
              <a href="#" className="flex items-center gap-2">
                <svg
                  width="21"
                  height="20"
                  viewBox="0 0 21 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.4938 2.52235C12.7336 1.70165 11.6719 1.24969 10.5 1.24969C9.32191 1.24969 8.25668 1.69891 7.50004 2.51454C6.73519 3.33915 6.36254 4.45985 6.45004 5.67001C6.62348 8.05751 8.44027 9.99969 10.5 9.99969C12.5598 9.99969 14.3735 8.0579 14.5496 5.67079C14.6383 4.47157 14.2633 3.35321 13.4938 2.52235Z"
                    fill="white"
                  />
                  <path
                    d="M17.3752 18.7488H3.62522C3.44525 18.7511 3.26701 18.7133 3.10349 18.6381C2.93997 18.5629 2.79526 18.4522 2.67991 18.314C2.426 18.0105 2.32366 17.596 2.39944 17.1769C2.72913 15.348 3.75804 13.8117 5.37522 12.7332C6.81194 11.7757 8.63186 11.2488 10.5002 11.2488C12.3686 11.2488 14.1885 11.7761 15.6252 12.7332C17.2424 13.8113 18.2713 15.3476 18.601 17.1765C18.6768 17.5957 18.5744 18.0101 18.3205 18.3136C18.2052 18.4519 18.0605 18.5626 17.897 18.6379C17.7335 18.7132 17.5552 18.7511 17.3752 18.7488Z"
                    fill="white"
                  />
                </svg>
                <p className="text-white">{localStorage.getItem("nameUser")}</p>
              </a>
              <button onClick={signOut} className="bg-white p-3 rounded-lg">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.5625 2.8125H2.1875V17.8125H6.5625M13.4375 5.9375L17.8125 10.3125L13.4375 14.6875M7.1875 10.3125H17.8125"
                      stroke="#EF4444"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
            </div>
          </div>
          {/* start: Main */}
          <div className="pb-5">
            <div className="bg-white shadow-lg rounded-lg p-6">
              <div className="flex flex-row justify-between">
                <div className="flex items-center justify-center gap-3">
                  <h1 className="text-lg font-bold">View Customer</h1>
                </div>
              </div>
              <div className="py-5 space-y-5">
                <div className="shadow-md rounded-lg p-5">
                  <div className="flex flex-col md:flex-row justify-between gap-5 mb-5">
                    <div className="flex flex-col gap-5">
                      <div className="flex flex-col md:flex-row gap-5">
                        <p className="font-bold">{data.name}</p>
                        <p className="bg-[#E9FFF7] py-1 px-2 text-sm text-center text-[#4FC29B] rounded w-fit">
                          {data.leveling ? data.leveling.levelingName:"New Customer"}
                        </p>
                      </div>
                      <div className="flex flex-col md:flex-row gap-5 md:items-center items-start text-sm">
                        <div className="flex flex-row items-center gap-2 bg-gray-100 py-1 px-2 text-center text-gray-400 rounded">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-whatsapp"
                            viewBox="0 0 16 16"
                          >
                            <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z" />
                          </svg>
                          <p>{data.phone}</p>
                        </div>
                        <div className="flex flex-row items-center gap-2 bg-gray-100 py-1 px-2 text-center text-gray-400 rounded">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-at"
                            viewBox="0 0 16 16"
                          >
                            <path d="M13.106 7.222c0-2.967-2.249-5.032-5.482-5.032-3.35 0-5.646 2.318-5.646 5.702 0 3.493 2.235 5.708 5.762 5.708.862 0 1.689-.123 2.304-.335v-.862c-.43.199-1.354.328-2.29.328-2.926 0-4.813-1.88-4.813-4.798 0-2.844 1.921-4.881 4.594-4.881 2.735 0 4.608 1.688 4.608 4.156 0 1.682-.554 2.769-1.416 2.769-.492 0-.772-.28-.772-.76V5.206H8.923v.834h-.11c-.266-.595-.881-.964-1.6-.964-1.4 0-2.378 1.162-2.378 2.823 0 1.737.957 2.906 2.379 2.906.8 0 1.415-.39 1.709-1.087h.11c.081.67.703 1.148 1.503 1.148 1.572 0 2.57-1.415 2.57-3.643zm-7.177.704c0-1.197.54-1.907 1.456-1.907.93 0 1.524.738 1.524 1.907S8.308 9.84 7.371 9.84c-.895 0-1.442-.725-1.442-1.914z" />
                          </svg>
                          <p>{data.email}</p>
                        </div>
                        <div className="flex flex-row items-center gap-2 bg-gray-100 py-1 px-2 text-center text-gray-400 rounded">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-gender-ambiguous"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fillRule="evenodd"
                              d="M11.5 1a.5.5 0 0 1 0-1h4a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V1.707l-3.45 3.45A4 4 0 0 1 8.5 10.97V13H10a.5.5 0 0 1 0 1H8.5v1.5a.5.5 0 0 1-1 0V14H6a.5.5 0 0 1 0-1h1.5v-2.03a4 4 0 1 1 3.471-6.648L14.293 1H11.5zm-.997 4.346a3 3 0 1 0-5.006 3.309 3 3 0 0 0 5.006-3.31z"
                            />
                          </svg>
                          <p>{data.gender}</p>
                        </div>
                        <div className="flex flex-row items-center gap-2 bg-gray-100 py-1 px-2 text-center text-gray-400 rounded">
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M10 8.33333C10.9592 8.33333 11.6667 7.62667 11.6667 6.66667C11.6667 5.70667 10.9592 5 10 5C9.04169 5 8.33335 5.70667 8.33335 6.66667C8.33335 7.62667 9.04169 8.33333 10 8.33333ZM10 9.16667C8.15919 9.16667 6.66669 10.51 6.66669 12.1667V12.4883H13.3334V12.1667C13.3334 10.51 11.8409 9.16667 10 9.16667Z"
                              fill="#A1A5B7"
                            />
                            <path
                              d="M15.8333 1.66669H4.16667C3.2475 1.66669 2.5 2.41419 2.5 3.33335V14.1667C2.5 15.0859 3.2475 15.8334 4.16667 15.8334H7.5L10 18.3334L12.5 15.8334H15.8333C16.7525 15.8334 17.5 15.0859 17.5 14.1667V3.33335C17.5 2.41419 16.7525 1.66669 15.8333 1.66669ZM11.6667 14.1667L10 15.8334L8.33333 14.1667H4.16667V3.33335H15.8333L15.835 14.1667H11.6667Z"
                              fill="#A1A5B7"
                            />
                          </svg>
                          <p>{getAge(data.birthdate)}</p>
                        </div>
                        <div className="flex flex-row items-center gap-2 bg-gray-100 py-1 px-2 text-center text-gray-400 rounded">
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M9.99998 8.33335L6.66665 3.66669L7.99998 1.66669H12L13.3333 3.66669L9.99998 8.33335ZM12.9166 5.66669L11.9166 7.08335C13.75 7.83335 15 9.58335 15 11.6667C15 12.9928 14.4732 14.2645 13.5355 15.2022C12.5978 16.1399 11.3261 16.6667 9.99998 16.6667C8.6739 16.6667 7.40213 16.1399 6.46445 15.2022C5.52676 14.2645 4.99998 12.9928 4.99998 11.6667C4.99998 9.58335 6.24998 7.83335 8.08331 7.08335L7.08331 5.66669C4.83331 6.75002 3.33331 9.00002 3.33331 11.6667C3.33331 13.4348 4.03569 15.1305 5.28593 16.3807C6.53618 17.631 8.23187 18.3334 9.99998 18.3334C11.7681 18.3334 13.4638 17.631 14.714 16.3807C15.9643 15.1305 16.6666 13.4348 16.6666 11.6667C16.6666 9.00002 15.1666 6.75002 12.9166 5.66669Z"
                              fill="#A1A5B7"
                            />
                          </svg>
                          <p>{data.marital_status}</p>
                        </div>
                      </div>
                      <div className="flex flex-row md:items-center text-sm gap-2 bg-gray-100 w-fit py-1 px-2 text-start md:text-center text-gray-400 rounded">
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M13.54 3.36C12.6064 2.42615 11.3453 1.89338 10.025 1.875H9.975C8.65455 1.89311 7.39327 2.42571 6.45949 3.35949C5.5257 4.29328 4.99311 5.55455 4.975 6.875C4.95875 7.8125 5.2225 8.73375 5.7325 9.52125L9.6675 17.5H10.3337L14.2675 9.52125C14.7787 8.73375 15.0425 7.8125 15.025 6.875C15.0066 5.55466 14.4738 4.29358 13.54 3.36ZM9.90625 3.125L10.0087 3.1375L10.1012 3.125C11.0832 3.16777 12.0115 3.58508 12.6952 4.29116C13.379 4.99724 13.7663 5.93843 13.7775 6.92125C13.7871 7.61879 13.5834 8.30261 13.1937 8.88125L13.1687 8.92375L13.1475 8.9675L10 15.3488L6.85375 8.97375L6.8325 8.925L6.8075 8.8825C6.41785 8.30386 6.21418 7.62004 6.22375 6.9225C6.23441 5.93854 6.62215 4.99616 7.30706 4.28962C7.99197 3.58309 8.92184 3.16624 9.905 3.125H9.90625ZM10.6712 5.83625C10.5348 5.74498 10.3816 5.68149 10.2206 5.6494C10.0596 5.61731 9.89379 5.61726 9.73274 5.64924C9.57168 5.68121 9.41851 5.7446 9.28195 5.83578C9.1454 5.92695 9.02814 6.04413 8.93687 6.18062C8.8456 6.31712 8.78211 6.47025 8.75002 6.63128C8.71794 6.79231 8.71788 6.95808 8.74986 7.11913C8.78184 7.28019 8.84522 7.43336 8.9364 7.56992C9.02757 7.70647 9.14475 7.82373 9.28125 7.915C9.55691 8.09933 9.8945 8.1666 10.2198 8.10201C10.545 8.03743 10.8313 7.84628 11.0156 7.57062C11.1999 7.29497 11.2672 6.95737 11.2026 6.63212C11.1381 6.30686 10.9469 6.02058 10.6712 5.83625ZM8.5875 4.79625C8.86024 4.60453 9.16857 4.46929 9.49435 4.3985C9.82013 4.32771 10.1568 4.3228 10.4845 4.38405C10.8122 4.4453 11.1243 4.57147 11.4026 4.75515C11.6808 4.93883 11.9195 5.1763 12.1045 5.45359C12.2896 5.73088 12.4174 6.04239 12.4803 6.36978C12.5432 6.69718 12.54 7.03385 12.4708 7.35999C12.4017 7.68612 12.268 7.99513 12.0777 8.26884C11.8873 8.54254 11.6442 8.77542 11.3625 8.95375C10.8111 9.30283 10.1451 9.42248 9.5067 9.28714C8.8683 9.15179 8.30813 8.77219 7.94584 8.2294C7.58354 7.68661 7.44785 7.02368 7.56775 6.3822C7.68764 5.74071 8.05361 5.17154 8.5875 4.79625Z"
                            fill="#A1A5B7"
                          />
                        </svg>

                        <p>{address}</p>
                      </div>
                    </div>
                    <div>
                      <Link
                        to={`/editSingleCustumer/${id}`}
                        className="bg-white text-sm hover:bg-green-500 border border-green-500 text-green-500 hover:text-white rounded-lg px-3 py-2 font-medium flex flex-row gap-3"
                      >
                        <div className="flex flex-row items-center gap-5">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-pencil-square"
                            viewBox="0 0 16 16"
                          >
                            <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                            <path
                              fillRule="evenodd"
                              d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                            />
                          </svg>
                          <span>Edit Profile Customer</span>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="shadow-md rounded-lg p-5">
                  <div className="flex flex-col md:flex-row justify-between items-start md:items-center gap-5 mb-5">
                    <h1 className="text-lg font-bold">Order History</h1>
                    <div className="flex gap-2">
                      <Link
                        to={`/add-order/${data.id}`}
                        className="border border-green-500 text-green-500 rounded-lg px-3 py-2 font-medium flex flex-row gap-3 flex items-center"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-plus-lg"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fillRule="evenodd"
                            d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"
                          />
                        </svg>
                        <span className="text-sm">Add Order</span>
                      </Link>
                      <a
                        href="/crm-chat"
                        className="bg-gradient-to-r from-[#54DAA1] to-[#2DCE89] hover:from-[#2DCE89] hover:to-[#54DAA1] text-white rounded-lg px-3 py-2 font-medium flex flex-row gap-3 flex items-center"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-whatsapp"
                          viewBox="0 0 16 16"
                        >
                          <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z" />
                        </svg>
                        <span className="text-sm">Send Message</span>
                      </a>
                    </div>
                  </div>
                  <TableOrderHistory />
                </div>
              </div>
            </div>
          </div>
          {/* end: Main */}
        </div>
      </div>
    </>
  );
}

export default ViewCustomer;
