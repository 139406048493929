import React from "react";
import MultiselectReact from "./MultiSelectSearch";

class RepeaterLeadSource extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formValues: [{ button: "", action: "" }],
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(i, e) {
    let formValues = this.state.formValues;
    formValues[i][e.target.name] = e.target.value;
    this.setState({ formValues });
  }

  addFormFields() {
    this.setState({
      formValues: [...this.state.formValues, { button: "", action: "" }],
    });
  }

  removeFormFields(i) {
    let formValues = this.state.formValues;
    formValues.splice(i, 1);
    this.setState({ formValues });
  }

  handleSubmit(event) {
    event.preventDefault();
    alert(JSON.stringify(this.state.formValues));
  }

  render() {
    return (
      <>
        <form onSubmit={this.handleSubmit}>
          {this.state.formValues.map((element, index) => (
            <div className="form-inline" key={index}>
              <div className="flex flex-row justify-between">
                <p className="text-sm font-medium text-gray-900">
                  Source {index + 1}
                </p>
                {index ? (
                  <button
                    type="button"
                    className="bg-red-500 hover:bg-red-700 text-white font-bold px-2 rounded"
                    onClick={() => this.removeFormFields(index)}
                  >
                    X
                  </button>
                ) : null}
              </div>
              <div className="flex flex-col gap-5 my-5">
                <div className="relative">
                  <select
                    className="block appearance-none w-full border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="grid-state"
                    value={element.button || ""}
                    onChange={(e) => this.handleChange(index, e)}
                  >
                    <option>Product</option>
                    <option>1</option>
                    <option>2</option>
                  </select>
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"></div>
                </div>
                {/* <div className="relative">
                  <select
                    className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="grid-state"
                    value={element.button || ""}
                    onChange={(e) => this.handleChange(index, e)}
                  >
                    <option>Customer Service Acquisition </option>
                    <option>CS 1</option>
                    <option>CS 2</option>
                  </select>
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"></div>
                </div>
                <div className="flex flex-col gap-5">
                  <div className="flex flex-row">
                    <label className="text-gray-500 font-medium w-52">
                      <input
                        className="mr-2 leading-tight focus:ring-transparent"
                        type="checkbox"
                        value={element.button || ""}
                        onChange={(e) => this.handleChange(index, e)}
                      />
                      <span className="text-sm">Paityn Rhiel Madsen</span>
                    </label>
                    <label className="text-gray-500 font-medium w-52">
                      <input
                        className="mr-2 leading-tight focus:ring-transparent"
                        type="checkbox"
                        value={element.button || ""}
                        onChange={(e) => this.handleChange(index, e)}
                      />
                      <span className="text-sm">Desirae Donin</span>
                    </label>
                  </div>
                  <div className="flex flex-row">
                    <label className="text-gray-500 font-medium w-52">
                      <input
                        className="mr-2 leading-tight focus:ring-transparent"
                        type="checkbox"
                        value={element.button || ""}
                        onChange={(e) => this.handleChange(index, e)}
                      />
                      <span className="text-sm">Haylie Curtis</span>
                    </label>
                    <label className="text-gray-500 font-medium w-52">
                      <input
                        className="mr-2 leading-tight focus:ring-transparent"
                        type="checkbox"
                        value={element.button || ""}
                        onChange={(e) => this.handleChange(index, e)}
                      />
                      <span className="text-sm">Kaylynn Kenter</span>
                    </label>
                  </div>
                  <div className="flex flex-row">
                    <label className="text-gray-500 font-medium w-52">
                      <input
                        className="mr-2 leading-tight focus:ring-transparent"
                        type="checkbox"
                        value={element.button || ""}
                        onChange={(e) => this.handleChange(index, e)}
                      />
                      <span className="text-sm">Kaiya Herwitz</span>
                    </label>
                    <label className="text-gray-500 font-medium w-52">
                      <input
                        className="mr-2 leading-tight focus:ring-transparent"
                        type="checkbox"
                        value={element.button || ""}
                        onChange={(e) => this.handleChange(index, e)}
                      />
                      <span className="text-sm">Anika Kenter</span>
                    </label>
                  </div>
                </div> */}
                <div className="bg-white border text-gray-700 rounded">
                  <MultiselectReact/>
                </div>
              </div>
            </div>
          ))}
          <div className="button-section">
            <button
              className="border border-dashed border-black rounded py-2 px-3 text-dark"
              type="button"
              onClick={() => this.addFormFields()}
            >
              + Add Lead Source
            </button>
          </div>
        </form>
      </>
    );
  }
}
export default RepeaterLeadSource;
