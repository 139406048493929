import React, { useState } from "react";
import Input from "../Input/Input";
import RepeaterLeadSource from "../Input/RepeaterLeadSource";
function EditLeadSource({}) {
  const [showInput, setShowInput] = useState(false);

  return (
    <>
      <button
        type="button"
        className="bg-gray-100 hover:bg-gray-200 text-green-400 text-sm rounded-lg px-3 py-2 font-medium flex flex-row gap-3"
        onClick={() => setShowInput(true)}
      >
        <span>Edit Lead Source</span>
      </button>
      {showInput ? (
        <>
          <div className="bg-black bg-opacity-75 overflow-y-auto overflow-x-hidden fixed flex justify-center top-0 left-0 right-0 bottom-0 z-50 w-full md:inset-0 h-modal md:h-full">
            {/* <div className="relative w-full max-w-2xl h-full md:h-auto"> */}
            {/* ini yang ditambahin */}
            <div className="relative fixed w-full max-w-2xl h-full md:h-auto my-5">
              {/* ini yang ditambahin */}
              <div className="relative bg-[#F5F8FA] rounded-lg shadow dark:bg-gray-700">
                <div className="flex justify-between items-start p-4 dark:border-gray-600">
                  <h3 className="text-xl font-semibold">
                    Edit Lead Source
                  </h3>
                  <button
                    type="button"
                    className="rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                    onClick={() => setShowInput(false)}
                  >
                    <svg
                      className="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </button>
                </div>
                <div className="p-6 space-y-6 items-center">
                  <div className="my-3">
                    <h1 className="font-bold">Lead Source</h1>
                  </div>
                  <div className="">
                    <RepeaterLeadSource />
                  </div>

                  <div className="mt-10 flex flex-row justify-end">
                    <button
                      className="px-3 rounded-xl text-gray-400 hover:text-gray-600 font-semibold"
                      onClick={() => setShowInput(false)}
                    >
                      Cancel
                    </button>
                    <button className="py-2 px-5 rounded-md text-white bg-green-400 hover:bg-green-500 font-semibold ml-2">
                      Save Change
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
}

export default EditLeadSource;
