import api from '../apiClient'

export function getSlug() {
    return api.axiosClient.get('/get-slug')
}
export function getListBlog() {
    return api.axiosClient.get('/get-blog')
}
export function getDetailBlog(slug) {
    return api.axiosClient.get(`/get-blog/${slug}`)
}