const Q1 = {
  labels: [
    "January",
    "February",
    "March",
    "May",
  ],
  datasets: [
    {
      fill: true,
      label: "bottle",
      data: [65, 59, 80, 81],
      borderColor: "rgb(0, 255, 0)",
      backgroundColor: ["rgba(79, 194, 155, 0.8)"],
    },
  ],
};

export default Q1;
