import api from "../apiClient";

export function getHeader() {
  return api.axiosClient.get("/totalHeader");
}
export function getOmset() {
  return api.axiosClient.get("/totalOmset");
}
export function getOmsetProduct() {
  return api.axiosClient.get("/totalOmsetProduk");
}
export function getClosing() {
  return api.axiosClient.get("/terjual");
}
export function getTotalOrder() {
  return api.axiosClient.get("/totalOrder");
}
export function getRevenue() {
  return api.axiosClient.get("/revenue/filter=monthly/product=null");
}
export function detailRevenue(filter, product) {
  return api.axiosClient.get(`/revenue/filter=${filter}/product=${product}`);
}
export function getGender() {
  return api.axiosClient.get("byGender/gender=null");
}
export function detailGender(gender) {
  return api.axiosClient.get(`byGender/gender=${gender}`);
}
export function getMartial() {
  return api.axiosClient.get("/byMarital/status=null");
}
export function detailMarital(status) {
  return api.axiosClient.get(`/byMarital/status=${status}`);
}
export function getCountCs() {
  return api.axiosClient.get("/customer/countCS");
}
export function getCostumerByAge() {
  return api.axiosClient.get("/customerByAge");
}
export function getDistrict() {
  return api.axiosClient.get("/byDistrict/district=null");
}
export function detailDistrict(city) {
  return api.axiosClient.get(`/byDistrict/district=${city}`);
}

export function getAge() {
  return api.axiosClient.get("/byAge/age=null-null");
}

export function detailAge(age1) {
  return api.axiosClient.get(`/byAge/age=${age1}`);
}




