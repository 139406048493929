import api from "../apiClient";

export function pointCreated(data) {
  return api.axiosClient.post("/pointSetting/create", data);
}

export function pointGet() {
    return api.axiosClient.get("/pointSetting/get");
}
export function pointDelete(id) {
    return api.axiosClient.delete(`pointSetting/delete/${id}`);
}
export function pointUpdate(id, data) {
    return api.axiosClient.put(`pointSetting/update/${id}`, data);
}


//   /pointSetting/update/84aa9efa-6cc4-40bd-8507-8e16911b3d25